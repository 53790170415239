import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleMakeScreen,
  createVehicleMake,
  editVehicleMake,
  fetchVehicleMakeById,
  uploadImage
} from "../../../../redux/actions/vehicles/vehicleMake";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleMakeComponent from "../../../../component/Vehicles/VehicleMake/CreateVehicleMake";
import { useParams } from "react-router-dom";

const CreateVehicleMakeContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleMake,
    editVehicleMake,
    isLoading,
    isVehicleMakeCreateSuccess,
    isVehicleMakeCreateError,
    isVehicleMakeDetailSuccess,
    isVehicleMakeDetailError,
    isVehicleMakeEditSuccess,
    isVehicleMakeEditError,
    isEdit,
    isView,
    initCreateVehicleMakeScreen,
    formFieldValueMap,
    fetchVehicleMakeById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleMakeScreen();
   if (isEdit) {
    await fetchVehicleMakeById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleMakeProps = {
    createVehicleMake,
    initCreateVehicleMakeScreen,
    editVehicleMake,
    isLoading,
    isEdit,
    isVehicleMakeDetailSuccess,
    isVehicleMakeCreateSuccess,
    isVehicleMakeEditSuccess,
    isVehicleMakeEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
  };
  const getToastProps = () => {
    if (isVehicleMakeCreateSuccess || isVehicleMakeEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleMake Updated Successfully"
        : "VehicleMake Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleMakeCreateError ||
      isVehicleMakeDetailError ||
      isVehicleMakeEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Make";
      if (isVehicleMakeEditError) {
        toastTitle = "Error while updating Vehicle Make";
      } else if (isVehicleMakeDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleMakeComponent {...createVehicleMakeProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleMakeReducer(state, "isLoading"),
   
    isVehicleMakeCreateSuccess: selectFromVehicleMakeReducer(
      state,
      "isVehicleMakeCreateSuccess"
    ),
    isVehicleMakeCreateError: selectFromVehicleMakeReducer(
      state,
      "isVehicleMakeCreateError"
    ),
    isVehicleMakeDetailSuccess: selectFromVehicleMakeReducer(
      state,
      "isVehicleMakeDetailSuccess"
    ),
    isVehicleMakeDetailError: selectFromVehicleMakeReducer(
      state,
      "isVehicleMakeDetailError"
    ),
    isVehicleMakeEditSuccess: selectFromVehicleMakeReducer(
      state,
      "isVehicleMakeEditSuccess"
    ),
    isVehicleMakeEditError: selectFromVehicleMakeReducer(
      state,
      "isVehicleMakeEditError"
    ),
    imageUploadResponse: selectFromVehicleMakeReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleMakeReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleMakeReducer(
      state,
      "isUploadImageError"
    ),
    formFieldValueMap: selectFromVehicleMakeReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleMakeScreen: () => dispatch(initCreateVehicleMakeScreen()),
    createVehicleMake: (payload) => dispatch(createVehicleMake(payload)),
    editVehicleMake: (payload) => dispatch(editVehicleMake(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleMakeById: (payload) =>
      dispatch(fetchVehicleMakeById(payload)),
  };
};

const selectFromVehicleMakeReducer = (state, path) => {
  return selectVehicleMakeReducer(state)[path];
};

const selectVehicleMakeReducer = ({ vehicleMakeReducer }) => {
  return vehicleMakeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleMakeContainer);
