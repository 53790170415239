export const basicDetail =
[
  {
    name:"title",
     "rule": {
        "required": true
      },
    label:"Vehicle Status",
    type:"text"
  },
  {
    name:"description",
     "rule": {
        "required": true
      },
    label:"Description",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
