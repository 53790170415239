import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleStereoType,
    initializeVehicleStereoTypeScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleStereoType
  } from "../../../redux/actions/vehicles/vehicleStereoType";
import VehicleStereoType from '../../../component/Vehicles/VehicleStereoType';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleStereoTypeScreen(props) {
    const {
        fetchVehicleStereoType,
        initVehicleStereoTypeScreen,
        showPageLevelLoader,
        vehicleStereoTypeListview,
        paginationInfo,
        exportVehicleStereoType,
        isVehicleStereoTypeExportError,
        isVehicleStereoTypeExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleStereoTypeScreen();
        fetchVehicleStereoType({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleStereoType(props);
      };
      const handleCreateVehicleStereoType =() =>{
      navigate(APP_URLS.VEHICLE_STEREOTYPE.CREATE);
    }
      const editVehicleStereoTypeRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_STEREOTYPE.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleStereoType = {
  handleCreateVehicleStereoType,
  exportVehicleStereoType,
        isVehicleStereoTypeExportError,
        isVehicleStereoTypeExportSuccess,
  editVehicleStereoTypeRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleStereoTypes: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleStereoTypeListview?.columns,
    rows: vehicleStereoTypeListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleStereoType
    {...vehicleStereoType}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleStereoTypeScreen: () => dispatch(initializeVehicleStereoTypeScreen()),
      fetchVehicleStereoType: (payload) => dispatch(fetchVehicleStereoType(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleStereoType: (payload) => dispatch(exportVehicleStereoType(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleStereoTypeCreateSuccess: selectFromVehicleStereoTypeReducer(
        state,
        "isVehicleStereoTypeCreateSuccess"
      ),
      vehicleStereoTypeListview: selectFromVehicleStereoTypeReducer(
        state,
        "vehicleStereoTypeListview"
      ),
      showPageLevelLoader: selectFromVehicleStereoTypeReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleStereoTypeReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleStereoTypeReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleStereoTypeReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleStereoTypeExportError: selectFromVehicleStereoTypeReducer(
        state,
        "isVehicleStereoTypeExportError"
      ),
      isVehicleStereoTypeExportSuccess: selectFromVehicleStereoTypeReducer(
        state,
        "isVehicleStereoTypeExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleStereoTypeReducer = (state, path) => {
  return selectVehicleStereoTypeReducer(state)[path];
};

const selectVehicleStereoTypeReducer = ({ vehicleStereoTypeReducer }) => {
  return vehicleStereoTypeReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleStereoTypeScreen);
