//import { post, put } from "axios";
import axios from 'axios';
import { getApiAsyn, baseUrl, getApiAsynarrayBuffer } from "../../../PostAPI";
import { handleAPIError, APIError } from "../../../common/errorHandler";
import { API_URL } from "../../../../utils/constant";
import { extractData } from "../../../../utils";
import { getDecoratedUrl } from "../../../common/urlService";



const { post, put, get } = axios;
export const fetchVehicleTransmissionType = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.VEHICLE_TRANSMISSIONTYPE.GET,
    payload,
  });
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchVehicleModelDropdown = async (payload) => {
  const url = API_URL.VEHICLE_TRANSMISSIONTYPE.MODELDROPDOWN;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
export const fetchCategory = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.VEHICLE_TRANSMISSIONTYPE.GETCATEGORY,
    payload,
  });
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



export const multipartDataUploadImage = (url, payload) => {
 
  const formPayload = new FormData();
  formPayload.append("file", payload?.file[0]);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: localStorage.getItem("token"),
    },
  };
  return post(baseUrl + url, formPayload, config);
};



export const upladImageData = async (payload) => {
  const url = API_URL.UPLOAD_IMAGE.replace(
    ":id",
    payload.id
  );
  try {
    let result = await multipartDataUploadImage(
      url,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
const dataUploadApi = (url, data, isEdit = false) => {
  // const formPayload = new FormData();
  // // data part of multipart data
  // const json = JSON.stringify(data);
  // const blobData = new Blob([json], {
  //   type: 'application/json',
  // });
  // formPayload.append('data', blobData);

  // // file part of multipart data
  // files?.forEach((file) => {
  //   formPayload.append('file', file);
  // });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };

  if (isEdit) {
    return post(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createVehicleTransmissionType = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.VEHICLE_TRANSMISSIONTYPE.CREATE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editVehicleTransmissionType = async (payload) => {
  const url = API_URL.VEHICLE_TRANSMISSIONTYPE.EDIT.replace(":id", payload?.id);

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};





const dataExportApi = (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };
    return get(baseUrl + url, data, config);
};

export const exportVehicleTransmissionTypeRecord = async (payload) => {
  const url = API_URL.VEHICLE_TRANSMISSIONTYPE.EXPORT;
  try {
    let result = await getApiAsynarrayBuffer(url, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};




export const fetchVehicleTransmissionTypeById = async (payload) => {
  const url = API_URL.VEHICLE_TRANSMISSIONTYPE.GET_BY_ID.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
