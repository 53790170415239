import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
 import { locationServices } from "../../../services";
import { goBack } from "../../../utils/gotoback";
import {
  FETCH_LOCATION_INIT, 
  FETCH_LOCATION_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_LOCATION_SCREEN,
  CREATE_LOCATION,
  EDIT_LOCATION,
  TOAST,
  FETCH_CATEGORY_LIST,
  FETCH_LOCATION_DETAIL,
  EXPORT_LOCATION,
  LOCATIONDD
} from "../../types/location.type";
export const initializeLocationScreen = () => ({ type: FETCH_LOCATION_INIT });

export const locationFetchStart = (payload) => ({
  type: FETCH_LOCATION_LIST.START,
  payload,
});

export const locationFetchSuccess = (payload) => ({
  type:FETCH_LOCATION_LIST.SUCCESS,
  payload,
});

export const locationFetchError = (payload) => ({
  type: FETCH_LOCATION_LIST.ERROR,
  payload,
});

export const fetchLocation = (payload) => {
  return (dispatch) => {
    dispatch(locationFetchStart(payload));
    locationServices.fetchLocation(payload).then((data) => {
      if (!data.isError) {
        dispatch(locationFetchSuccess(data));
      } else {
        dispatch(locationFetchError(data));
      }
    });
  };
};


export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const locationDetailFetchStart = (payload) => ({
  type: FETCH_LOCATION_DETAIL.START,
  payload,
});

export const locationDetailFetchSuccess = (payload) => ({
  type: FETCH_LOCATION_DETAIL.SUCCESS,
  payload,
});

export const locationDetailFetchError = (payload) => ({
  type: FETCH_LOCATION_DETAIL.ERROR,
  payload,
});

export const fetchLocationById = (payload) => {
  return (dispatch) => {
    dispatch(locationDetailFetchStart(payload));

    locationServices
      .fetchLocationById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(locationDetailFetchSuccess(data));
        } else {
          dispatch(locationDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/

export const createLocationScreenInitStart = (payload) => ({
  type: INIT_CREATE_LOCATION_SCREEN,
  payload,
});

export const initCreateLocationScreen = (payload) => {
  return (dispatch) => {
    dispatch(createLocationScreenInitStart());
  };
};


export const createLocationStart = () => ({
  type: CREATE_LOCATION.START,
});

export const createLocationSuccess = (payload) => ({
  type: CREATE_LOCATION.SUCCESS,
  payload,
});

export const createLocationError = (payload) => ({
  type: CREATE_LOCATION.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createLocation = (payload) => {
  return (dispatch) => {
    dispatch(createLocationStart());
    locationServices.createLocation(payload).then((data) => {
      if (!data.isError) {
        dispatch(createLocationSuccess());
        goBack();
      } else {
        dispatch(createLocationError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editLocationStart = (payload) => ({
  type: EDIT_LOCATION.START,
  payload
});

export const editLocationSuccess = (payload) => ({
  type: EDIT_LOCATION.SUCCESS,
  payload,
});

export const editLocationError = (payload) => ({
  type: EDIT_LOCATION.ERROR,
  payload,
});

export const editLocation = (payload) => {
  return (dispatch) => {
    dispatch(editLocationStart());
    locationServices.editLocation(payload).then((data) => {
      if (!data.isError) {
        dispatch(editLocationSuccess());
        goBack();
      } else {
        dispatch(editLocationError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportLocationRecordStart = (payload) => ({
  type: EXPORT_LOCATION.START,
  payload
});

export const exportLocationRecordSuccess = (payload) => ({
  type: EXPORT_LOCATION.SUCCESS,
  payload,
});

export const exportLocationRecordError = (payload) => ({
  type: EXPORT_LOCATION.ERROR,
  payload,
});

export const exportLocation = (payload) => {
  return (dispatch) => {
    dispatch(exportLocationRecordStart());
    locationServices.exportLocationRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportLocationRecordSuccess(data));
      } else {
        dispatch(exportLocationRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};




export const categoryFetchStart = (payload) => ({
  type: FETCH_CATEGORY_LIST.START,
  payload,
});

export const categoryFetchSuccess = (payload) => ({
  type:FETCH_CATEGORY_LIST.SUCCESS,
  payload,
});

export const categoryFetchError = (payload) => ({
  type: FETCH_CATEGORY_LIST.ERROR,
  payload,
});

export const fetchCategory = (payload) => {
  return (dispatch) => {
    dispatch(categoryFetchStart(payload));
    locationServices.fetchCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(categoryFetchSuccess(data));
      } else {
        dispatch(categoryFetchError(data));
      }
    });
  };
};



// DD
export const locationDDFetchStart = (payload) => ({
  type: LOCATIONDD.START,
  payload,
});

export const locationDDFetchSuccess = (payload) => ({
  type:LOCATIONDD.SUCCESS,
  payload,
});

export const locationDDFetchError = (payload) => ({
  type: LOCATIONDD.ERROR,
  payload,
});

export const fetchLocationDD = (payload) => {
  return (dispatch) => {
    dispatch(locationDDFetchStart(payload));
    locationServices.fetchLocationDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(locationDDFetchSuccess(data));
      } else {
        dispatch(locationDDFetchError(data));
      }
    });
  };
};

//END DD