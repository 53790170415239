//import { post, put } from "axios";
import axios from 'axios';
import { getApiAsyn, baseUrl, getApiAsynarrayBuffer } from "../../PostAPI";
import { handleAPIError, APIError } from "../../common/errorHandler";
import { API_URL } from "../../../utils/constant";
import { extractData } from "../../../utils";
import { getDecoratedUrl } from "../../common/urlService";


const { post, put, get } = axios;

const dataUploadApi = (url, data, isEdit = false) => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };

  if (isEdit) {
    return post(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createPassword = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.changepassword}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
