

export const FETCH_VEHICLE_DEALERSHIPS_LIST = {
    START: 'FETCH_VEHICLE_DEALERSHIPS_LIST_START',
    SUCCESS: 'FETCH_VEHICLE_DEALERSHIPS_LIST_SUCCESS',
    ERROR: 'FETCH_VEHICLE_DEALERSHIPS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_VEHICLE_DEALERSHIPS_INIT="FETCH_VEHICLE_DEALERSHIPS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_VEHICLE_DEALERSHIPS_SCREEN =
'INIT_CREATE_VEHICLE_DEALERSHIPS_SCREEN';

export const CREATE_VEHICLE_DEALERSHIPS = {
START: 'CREATE_VEHICLE_DEALERSHIPS_START',
SUCCESS: 'CREATE_VEHICLE_DEALERSHIPS_SUCCESS',
ERROR: 'CREATE_VEHICLE_DEALERSHIPS_ERROR',
};

export const EDIT_VEHICLE_DEALERSHIPS = {
START: 'EDIT_VEHICLE_DEALERSHIPS_START',
SUCCESS: 'EDIT_VEHICLE_DEALERSHIPS_SUCCESS',
ERROR: 'EDIT_VEHICLE_DEALERSHIPS_ERROR',
};

export const FETCH_VEHICLE_DEALERSHIPS_DETAIL = {
  START: 'FETCH_VEHICLE_DEALERSHIPS_DETAIL_START',
  SUCCESS: 'FETCH_VEHICLE_DEALERSHIPS_DETAIL_SUCCESS',
  ERROR: 'FETCH_VEHICLE_DEALERSHIPS_DETAIL_ERROR',
};
export const EXPORT_VEHICLE_DEALERSHIPS = {
  START: 'EXPORT_VEHICLE_DEALERSHIPS_START',
  SUCCESS: 'EXPORT_VEHICLE_DEALERSHIPS_SUCCESS',
  ERROR: 'EXPORT_VEHICLE_DEALERSHIPS_ERROR',
};
export const VEHICLE_DEALERSHIPSDD = {
  START: 'VEHICLE_DEALERSHIPSDD_START',
  SUCCESS: 'VEHICLE_DEALERSHIPSDD_SUCCESS',
  ERROR: 'VEHICLE_DEALERSHIPSDD_ERROR',
};