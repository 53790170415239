

export const FETCH_SETTINGS_PAYNOWOPTIONS_LIST = {
    START: 'FETCH_SETTINGS_PAYNOWOPTIONS_LIST_START',
    SUCCESS: 'FETCH_SETTINGS_PAYNOWOPTIONS_LIST_SUCCESS',
    ERROR: 'FETCH_SETTINGS_PAYNOWOPTIONS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_SETTINGS_PAYNOWOPTIONS_INIT="FETCH_SETTINGS_PAYNOWOPTIONS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_SETTINGS_PAYNOWOPTIONS_SCREEN =
'INIT_CREATE_SETTINGS_PAYNOWOPTIONS_SCREEN';

export const CREATE_SETTINGS_PAYNOWOPTIONS = {
START: 'CREATE_SETTINGS_PAYNOWOPTIONS_START',
SUCCESS: 'CREATE_SETTINGS_PAYNOWOPTIONS_SUCCESS',
ERROR: 'CREATE_SETTINGS_PAYNOWOPTIONS_ERROR',
};

export const EDIT_SETTINGS_PAYNOWOPTIONS = {
START: 'EDIT_SETTINGS_PAYNOWOPTIONS_START',
SUCCESS: 'EDIT_SETTINGS_PAYNOWOPTIONS_SUCCESS',
ERROR: 'EDIT_SETTINGS_PAYNOWOPTIONS_ERROR',
};

export const FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL = {
  START: 'FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL_START',
  SUCCESS: 'FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL_SUCCESS',
  ERROR: 'FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL_ERROR',
};
export const EXPORT_SETTINGS_PAYNOWOPTIONS = {
  START: 'EXPORT_SETTINGS_PAYNOWOPTIONS_START',
  SUCCESS: 'EXPORT_SETTINGS_PAYNOWOPTIONS_SUCCESS',
  ERROR: 'EXPORT_SETTINGS_PAYNOWOPTIONS_ERROR',
};
export const SETTINGS_PAYNOWOPTIONSDD = {
  START: 'SETTINGS_PAYNOWOPTIONSDD_START',
  SUCCESS: 'SETTINGS_PAYNOWOPTIONSDD_SUCCESS',
  ERROR: 'SETTINGS_PAYNOWOPTIONSDD_ERROR',
};
export const SETTINGS_PAYNOWOPTIONSDELETE = {
  START: 'SETTINGS_PAYNOWOPTIONSDELETE_START',
  SUCCESS: 'SETTINGS_PAYNOWOPTIONSDELETE_SUCCESS',
  ERROR: 'SETTINGS_PAYNOWOPTIONSDELETE_ERROR',
};

