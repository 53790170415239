

export const FETCH_RATEADMIN_RATEMIRRORS_LIST = {
    START: 'FETCH_RATEADMIN_RATEMIRRORS_LIST_START',
    SUCCESS: 'FETCH_RATEADMIN_RATEMIRRORS_LIST_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_RATEMIRRORS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RATEADMIN_RATEMIRRORS_INIT="FETCH_RATEADMIN_RATEMIRRORS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RATEADMIN_RATEMIRRORS_SCREEN =
'INIT_CREATE_RATEADMIN_RATEMIRRORS_SCREEN';

export const CREATE_RATEADMIN_RATEMIRRORS = {
START: 'CREATE_RATEADMIN_RATEMIRRORS_START',
SUCCESS: 'CREATE_RATEADMIN_RATEMIRRORS_SUCCESS',
ERROR: 'CREATE_RATEADMIN_RATEMIRRORS_ERROR',
};

export const EDIT_RATEADMIN_RATEMIRRORS = {
START: 'EDIT_RATEADMIN_RATEMIRRORS_START',
SUCCESS: 'EDIT_RATEADMIN_RATEMIRRORS_SUCCESS',
ERROR: 'EDIT_RATEADMIN_RATEMIRRORS_ERROR',
};

export const FETCH_RATEADMIN_RATEMIRRORS_DETAIL = {
  START: 'FETCH_RATEADMIN_RATEMIRRORS_DETAIL_START',
  SUCCESS: 'FETCH_RATEADMIN_RATEMIRRORS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RATEADMIN_RATEMIRRORS_DETAIL_ERROR',
};
export const EXPORT_RATEADMIN_RATEMIRRORS = {
  START: 'EXPORT_RATEADMIN_RATEMIRRORS_START',
  SUCCESS: 'EXPORT_RATEADMIN_RATEMIRRORS_SUCCESS',
  ERROR: 'EXPORT_RATEADMIN_RATEMIRRORS_ERROR',
};
export const RATEADMIN_RATEMIRRORSDD = {
  START: 'RATEADMIN_RATEMIRRORSDD_START',
  SUCCESS: 'RATEADMIN_RATEMIRRORSDD_SUCCESS',
  ERROR: 'RATEADMIN_RATEMIRRORSDD_ERROR',
};
export const RATEADMIN_RATEMIRRORSDELETE = {
  START: 'RATEADMIN_RATEMIRRORSDELETE_START',
  SUCCESS: 'RATEADMIN_RATEMIRRORSDELETE_SUCCESS',
  ERROR: 'RATEADMIN_RATEMIRRORSDELETE_ERROR',
};

