import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleStereoTypeServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_STEREOTYPE_INIT, 
  FETCH_VEHICLE_STEREOTYPE_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_STEREOTYPE_SCREEN,
  CREATE_VEHICLE_STEREOTYPE,
  EDIT_VEHICLE_STEREOTYPE,
  TOAST,
  FETCH_VEHICLE_STEREOTYPE_DETAIL,
  EXPORT_VEHICLE_STEREOTYPE,
  VEHICLE_MODELDD
} from "../../../types/vehiclestereotype.type";
export const initializeVehicleStereoTypeScreen = () => ({ type: FETCH_VEHICLE_STEREOTYPE_INIT });

export const vehicleStereoTypeFetchStart = (payload) => ({
  type: FETCH_VEHICLE_STEREOTYPE_LIST.START,
  payload,
});

export const vehicleStereoTypeFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_STEREOTYPE_LIST.SUCCESS,
  payload,
});

export const vehicleStereoTypeFetchError = (payload) => ({
  type: FETCH_VEHICLE_STEREOTYPE_LIST.ERROR,
  payload,
});

export const fetchVehicleStereoType = (payload) => {
  return (dispatch) => {
    dispatch(vehicleStereoTypeFetchStart(payload));
    vehicleStereoTypeServices.fetchVehicleStereoType(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleStereoTypeFetchSuccess(data));
      } else {
        dispatch(vehicleStereoTypeFetchError(data));
      }
    });
  };
};



// DD
export const vehicleModelDDFetchStart = (payload) => ({
  type: VEHICLE_MODELDD.START,
  payload,
});

export const vehicleModelDDFetchSuccess = (payload) => ({
  type:VEHICLE_MODELDD.SUCCESS,
  payload,
});

export const vehicleModelDDFetchError = (payload) => ({
  type: VEHICLE_MODELDD.ERROR,
  payload,
});

export const fetchVehicleModelDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleModelDDFetchStart(payload));
    vehicleStereoTypeServices.fetchVehicleModelDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleModelDDFetchSuccess(data));
      } else {
        dispatch(vehicleModelDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleStereoTypeDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_STEREOTYPE_DETAIL.START,
  payload,
});

export const vehicleStereoTypeDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_STEREOTYPE_DETAIL.SUCCESS,
  payload,
});

export const vehicleStereoTypeDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_STEREOTYPE_DETAIL.ERROR,
  payload,
});

export const fetchVehicleStereoTypeById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleStereoTypeDetailFetchStart(payload));

    vehicleStereoTypeServices
      .fetchVehicleStereoTypeById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleStereoTypeDetailFetchSuccess(data));
        } else {
          dispatch(vehicleStereoTypeDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleStereoTypeServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleStereoTypeScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_STEREOTYPE_SCREEN,
  payload,
});

export const initCreateVehicleStereoTypeScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleStereoTypeScreenInitStart());
  };
};


export const createVehicleStereoTypeStart = () => ({
  type: CREATE_VEHICLE_STEREOTYPE.START,
});

export const createVehicleStereoTypeSuccess = (payload) => ({
  type: CREATE_VEHICLE_STEREOTYPE.SUCCESS,
  payload,
});

export const createVehicleStereoTypeError = (payload) => ({
  type: CREATE_VEHICLE_STEREOTYPE.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleStereoType = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleStereoTypeStart());
    vehicleStereoTypeServices.createVehicleStereoType(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleStereoTypeSuccess());
        goBack();
      } else {
        dispatch(createVehicleStereoTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleStereoTypeStart = (payload) => ({
  type: EDIT_VEHICLE_STEREOTYPE.START,
  payload
});

export const editVehicleStereoTypeSuccess = (payload) => ({
  type: EDIT_VEHICLE_STEREOTYPE.SUCCESS,
  payload,
});

export const editVehicleStereoTypeError = (payload) => ({
  type: EDIT_VEHICLE_STEREOTYPE.ERROR,
  payload,
});

export const editVehicleStereoType = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleStereoTypeStart());
    vehicleStereoTypeServices.editVehicleStereoType(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleStereoTypeSuccess());
        goBack();
      } else {
        dispatch(editVehicleStereoTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleStereoTypeRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_STEREOTYPE.START,
  payload
});

export const exportVehicleStereoTypeRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_STEREOTYPE.SUCCESS,
  payload,
});

export const exportVehicleStereoTypeRecordError = (payload) => ({
  type: EXPORT_VEHICLE_STEREOTYPE.ERROR,
  payload,
});

export const exportVehicleStereoType = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleStereoTypeRecordStart());
    vehicleStereoTypeServices.exportVehicleStereoTypeRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleStereoTypeRecordSuccess(data));
      } else {
        dispatch(exportVehicleStereoTypeRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

