import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminRateMirrorsScreen,
  createRateAdminRateMirrors,
  editRateAdminRateMirrors,
  fetchRateAdminRateMirrorsById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminRateMirrors";

import {
  fetchRateAdminNetworkDD,
} from "../../../../redux/actions/RateAdmin/rateadminRates";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import {
  fetchLocationDD,
  fetchCategory
} from "../../../../redux/actions/location";
import {
  fetchRateAdminRateCodeDD
} from "../../../../redux/actions/RateAdmin/rateadminRateCode";

import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminRateMirrorsComponent from "../../../../component/RateAdmin/RateAdminRateMirrors/CreateRateAdminRateMirrors";
import { useParams } from "react-router-dom";

const CreateRateAdminRateMirrorsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminRateMirrors,
    editRateAdminRateMirrors,
    isLoading,
    isRateAdminRateMirrorsCreateSuccess,
    isRateAdminRateMirrorsCreateError,
    isRateAdminRateMirrorsDetailSuccess,
    isRateAdminRateMirrorsDetailError,
    isRateAdminRateMirrorsEditSuccess,
    isRateAdminRateMirrorsEditError,
    isEdit,
    isView,
    initCreateRateAdminRateMirrorsScreen,
    formFieldValueMap,
    fetchRateAdminRateMirrorsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        RateCodeDD,
        vehicleCategoryDD,
        fetchVehicleCategoryDD,
        fetchLocationDD,
  fetchCategory,

        fetchRateAdminRateCodeDD,
        fetchRateAdminNetworkDD,
        RateNetworkDD,
        categoryDD
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    await fetchVehicleCategoryDD();
    await fetchLocationDD();
    await fetchCategory();
    await fetchRateAdminRateCodeDD();
    await fetchRateAdminNetworkDD();
    initCreateRateAdminRateMirrorsScreen();
   if (isEdit) {
    await fetchRateAdminRateMirrorsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminRateMirrorsProps = {
    createRateAdminRateMirrors,
    initCreateRateAdminRateMirrorsScreen,
    editRateAdminRateMirrors,
    isLoading,
    isEdit,
    isRateAdminRateMirrorsDetailSuccess,
    isRateAdminRateMirrorsCreateSuccess,
    isRateAdminRateMirrorsEditSuccess,
    isRateAdminRateMirrorsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        RateCodeDD,
        vehicleCategoryDD,
        RateNetworkDD,
        categoryDD
  };
  const getToastProps = () => {
    if (isRateAdminRateMirrorsCreateSuccess || isRateAdminRateMirrorsEditSuccess) {
      const toastTitle = isEdit
        ? "Rate Mirrors Updated Successfully"
        : "Rate Mirrors Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminRateMirrorsCreateError ||
      isRateAdminRateMirrorsDetailError ||
      isRateAdminRateMirrorsEditError
    ) {
      let toastTitle = "Error while Creating Rate Plan";
      if (isRateAdminRateMirrorsEditError) {
        toastTitle = "Error while updating Rate Plan";
      } else if (isRateAdminRateMirrorsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminRateMirrorsComponent {...createRateAdminRateMirrorsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminRateMirrorsReducer(state, "isLoading"),
   
    isRateAdminRateMirrorsCreateSuccess: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsCreateSuccess"
    ),
    isRateAdminRateMirrorsCreateError: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsCreateError"
    ),
    isRateAdminRateMirrorsDetailSuccess: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsDetailSuccess"
    ),
    isRateAdminRateMirrorsDetailError: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsDetailError"
    ),
    isRateAdminRateMirrorsEditSuccess: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsEditSuccess"
    ),
    isRateAdminRateMirrorsEditError: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsEditError"
    ),
    imageUploadResponse: selectFromRateAdminRateMirrorsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminRateMirrorsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminRateMirrorsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminRateMirrorsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminRateMirrorsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminRateMirrorsReducer(
      state,
      "isVehicleModelDDError"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    categoryDD: selectFromLocationReducer(
      state,
      "categoryDD"
    ),
    
    RateCodeDD: selectFromRateCodeReducer(
      state,
      "RateCodeDD"
    ),
    RateNetworkDD: selectFromRateAdminRatesReducer(
      state,
      "RateNetworkDD"
    ),
    formFieldValueMap: selectFromRateAdminRateMirrorsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminRateMirrorsScreen: () => dispatch(initCreateRateAdminRateMirrorsScreen()),
    createRateAdminRateMirrors: (payload) => dispatch(createRateAdminRateMirrors(payload)),
    editRateAdminRateMirrors: (payload) => dispatch(editRateAdminRateMirrors(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchCategory: (payload) => dispatch(fetchCategory(payload)),
    fetchRateAdminRateCodeDD: (payload) => dispatch(fetchRateAdminRateCodeDD(payload)),
    fetchRateAdminNetworkDD: (payload) => dispatch(fetchRateAdminNetworkDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminRateMirrorsById: (payload) =>
      dispatch(fetchRateAdminRateMirrorsById(payload)),

  };
};

const selectFromRateAdminRateMirrorsReducer = (state, path) => {
  return selectRateAdminRateMirrorsReducer(state)[path];
};

const selectRateAdminRateMirrorsReducer = ({ rateadminRateMirrorsReducer }) => {
  return rateadminRateMirrorsReducer;
};
//For Network Rate form
const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//RateCode
const selectFromRateCodeReducer = (state, path) => {
  return selectRateCodeReducer(state)[path];
};
const selectRateCodeReducer = ({ rateadminRateCodeReducer }) => {
  return rateadminRateCodeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminRateMirrorsContainer);
