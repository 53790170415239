import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleMakeServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_MAKE_INIT, 
  FETCH_VEHICLE_MAKE_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_MAKE_SCREEN,
  CREATE_VEHICLE_MAKE,
  EDIT_VEHICLE_MAKE,
  TOAST,
  FETCH_VEHICLE_MAKE_DETAIL,
  EXPORT_VEHICLE_MAKE,
  VEHICLE_MAKEDD
  
} from "../../../types/vehiclemake.type";
export const initializeVehicleMakeScreen = () => ({ type: FETCH_VEHICLE_MAKE_INIT });

export const vehicleMakeFetchStart = (payload) => ({
  type: FETCH_VEHICLE_MAKE_LIST.START,
  payload,
});

export const vehicleMakeFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_MAKE_LIST.SUCCESS,
  payload,
});

export const vehicleMakeFetchError = (payload) => ({
  type: FETCH_VEHICLE_MAKE_LIST.ERROR,
  payload,
});

export const fetchVehicleMake = (payload) => {
  return (dispatch) => {
    dispatch(vehicleMakeFetchStart(payload));
    vehicleMakeServices.fetchVehicleMake(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleMakeFetchSuccess(data));
      } else {
        dispatch(vehicleMakeFetchError(data));
      }
    });
  };
};


export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleMakeDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_MAKE_DETAIL.START,
  payload,
});

export const vehicleMakeDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_MAKE_DETAIL.SUCCESS,
  payload,
});

export const vehicleMakeDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_MAKE_DETAIL.ERROR,
  payload,
});

export const fetchVehicleMakeById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleMakeDetailFetchStart(payload));

    vehicleMakeServices
      .fetchVehicleMakeById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleMakeDetailFetchSuccess(data));
        } else {
          dispatch(vehicleMakeDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleMakeServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleMakeScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_MAKE_SCREEN,
  payload,
});

export const initCreateVehicleMakeScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleMakeScreenInitStart());
  };
};


export const createVehicleMakeStart = () => ({
  type: CREATE_VEHICLE_MAKE.START,
});

export const createVehicleMakeSuccess = (payload) => ({
  type: CREATE_VEHICLE_MAKE.SUCCESS,
  payload,
});

export const createVehicleMakeError = (payload) => ({
  type: CREATE_VEHICLE_MAKE.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleMake = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleMakeStart());
    vehicleMakeServices.createVehicleMake(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleMakeSuccess());
        goBack();
      } else {
        dispatch(createVehicleMakeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleMakeStart = (payload) => ({
  type: EDIT_VEHICLE_MAKE.START,
  payload
});

export const editVehicleMakeSuccess = (payload) => ({
  type: EDIT_VEHICLE_MAKE.SUCCESS,
  payload,
});

export const editVehicleMakeError = (payload) => ({
  type: EDIT_VEHICLE_MAKE.ERROR,
  payload,
});

export const editVehicleMake = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleMakeStart());
    vehicleMakeServices.editVehicleMake(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleMakeSuccess());
        goBack();
      } else {
        dispatch(editVehicleMakeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleMakeRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_MAKE.START,
  payload
});

export const exportVehicleMakeRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_MAKE.SUCCESS,
  payload,
});

export const exportVehicleMakeRecordError = (payload) => ({
  type: EXPORT_VEHICLE_MAKE.ERROR,
  payload,
});

export const exportVehicleMake = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleMakeRecordStart());
    vehicleMakeServices.exportVehicleMakeRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleMakeRecordSuccess(data));
      } else {
        dispatch(exportVehicleMakeRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

// DD
export const vehicleMakeDDFetchStart = (payload) => ({
  type: VEHICLE_MAKEDD.START,
  payload,
});

export const vehicleMakeDDFetchSuccess = (payload) => ({
  type:VEHICLE_MAKEDD.SUCCESS,
  payload,
});

export const vehicleMakeDDFetchError = (payload) => ({
  type: VEHICLE_MAKEDD.ERROR,
  payload,
});

export const fetchVehicleMakeDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleMakeDDFetchStart(payload));
    vehicleMakeServices.fetchVehicleMakeDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleMakeDDFetchSuccess(data));
      } else {
        dispatch(vehicleMakeDDFetchError(data));
      }
    });
  };
};

//END DD