import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateReservationsBookingsbyLocationReportScreen,
  createReservationsBookingsbyLocationReport,
  editReservationsBookingsbyLocationReport,
  fetchReservationsBookingsbyLocationReportById,
  uploadImage,
} from "../../../../redux/actions/Reservations/ReservationsBookingsbyLocationReport";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateReservationsBookingsbyLocationReportComponent from "../../../../component/Reservations/ReservationsBookingsbyLocationReport/CreateReservationsBookingsbyLocationReport";
import { useParams } from "react-router-dom";

const CreateReservationsBookingsbyLocationReportContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createReservationsBookingsbyLocationReport,
    editReservationsBookingsbyLocationReport,
    isLoading,
    isReservationsBookingsbyLocationReportCreateSuccess,
    isReservationsBookingsbyLocationReportCreateError,
    isReservationsBookingsbyLocationReportDetailSuccess,
    isReservationsBookingsbyLocationReportDetailError,
    isReservationsBookingsbyLocationReportEditSuccess,
    isReservationsBookingsbyLocationReportEditError,
    isEdit,
    isView,
    initCreateReservationsBookingsbyLocationReportScreen,
    formFieldValueMap,
    fetchReservationsBookingsbyLocationReportById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        fetchLocationDD,
        locationDD,
        fetchVehicleCategoryDD,
vehicleCategoryDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateReservationsBookingsbyLocationReportScreen();
    await fetchLocationDD();
    await fetchVehicleCategoryDD();
   if (isEdit) {
    await fetchReservationsBookingsbyLocationReportById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createReservationsBookingsbyLocationReportProps = {
    createReservationsBookingsbyLocationReport,
    initCreateReservationsBookingsbyLocationReportScreen,
    editReservationsBookingsbyLocationReport,
    isLoading,
    isEdit,
    isReservationsBookingsbyLocationReportDetailSuccess,
    isReservationsBookingsbyLocationReportCreateSuccess,
    isReservationsBookingsbyLocationReportEditSuccess,
    isReservationsBookingsbyLocationReportEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
  };
  const getToastProps = () => {
    if (isReservationsBookingsbyLocationReportCreateSuccess || isReservationsBookingsbyLocationReportEditSuccess) {
      const toastTitle = isEdit
        ? "Bookings by Location Report Updated Successfully"
        : "Bookings by Location Report Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isReservationsBookingsbyLocationReportCreateError ||
      isReservationsBookingsbyLocationReportDetailError ||
      isReservationsBookingsbyLocationReportEditError
    ) {
      let toastTitle = "Error while Creating Reservations";
      if (isReservationsBookingsbyLocationReportEditError) {
        toastTitle = "Error while updating  Reservations";
      } else if (isReservationsBookingsbyLocationReportDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateReservationsBookingsbyLocationReportComponent {...createReservationsBookingsbyLocationReportProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromReservationsBookingsbyLocationReportReducer(state, "isLoading"),
   
    isReservationsBookingsbyLocationReportCreateSuccess: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isReservationsBookingsbyLocationReportCreateSuccess"
    ),
    isReservationsBookingsbyLocationReportCreateError: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isReservationsBookingsbyLocationReportCreateError"
    ),
    isReservationsBookingsbyLocationReportDetailSuccess: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isReservationsBookingsbyLocationReportDetailSuccess"
    ),
    isReservationsBookingsbyLocationReportDetailError: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isReservationsBookingsbyLocationReportDetailError"
    ),
    isReservationsBookingsbyLocationReportEditSuccess: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isReservationsBookingsbyLocationReportEditSuccess"
    ),
    isReservationsBookingsbyLocationReportEditError: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isReservationsBookingsbyLocationReportEditError"
    ),
    imageUploadResponse: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromReservationsBookingsbyLocationReportReducer(
      state,
      "isVehicleModelDDError"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    formFieldValueMap: selectFromReservationsBookingsbyLocationReportReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateReservationsBookingsbyLocationReportScreen: () => dispatch(initCreateReservationsBookingsbyLocationReportScreen()),
    createReservationsBookingsbyLocationReport: (payload) => dispatch(createReservationsBookingsbyLocationReport(payload)),
    editReservationsBookingsbyLocationReport: (payload) => dispatch(editReservationsBookingsbyLocationReport(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchReservationsBookingsbyLocationReportById: (payload) =>
      dispatch(fetchReservationsBookingsbyLocationReportById(payload)),

  };
};

const selectFromReservationsBookingsbyLocationReportReducer = (state, path) => {
  return selectReservationsBookingsbyLocationReportReducer(state)[path];
};

const selectReservationsBookingsbyLocationReportReducer = ({ ReservationsBookingsbyLocationReportReducer }) => {
  return ReservationsBookingsbyLocationReportReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservationsBookingsbyLocationReportContainer);
