import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateReservationsDetailReportScreen,
  createReservationsDetailReport,
  editReservationsDetailReport,
  fetchReservationsDetailReportById,
  uploadImage,
} from "../../../../redux/actions/Reservations/ReservationsDetailReport";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateReservationsDetailReportComponent from "../../../../component/Reservations/ReservationsDetailReport/CreateReservationsDetailReport";
import { useParams } from "react-router-dom";

const CreateReservationsDetailReportContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createReservationsDetailReport,
    editReservationsDetailReport,
    isLoading,
    isReservationsDetailReportCreateSuccess,
    isReservationsDetailReportCreateError,
    isReservationsDetailReportDetailSuccess,
    isReservationsDetailReportDetailError,
    isReservationsDetailReportEditSuccess,
    isReservationsDetailReportEditError,
    isEdit,
    isView,
    initCreateReservationsDetailReportScreen,
    formFieldValueMap,
    fetchReservationsDetailReportById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        fetchLocationDD,
        locationDD,
        fetchVehicleCategoryDD,
vehicleCategoryDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateReservationsDetailReportScreen();
    await fetchLocationDD();
    await fetchVehicleCategoryDD();
   if (isEdit) {
    await fetchReservationsDetailReportById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createReservationsDetailReportProps = {
    createReservationsDetailReport,
    initCreateReservationsDetailReportScreen,
    editReservationsDetailReport,
    isLoading,
    isEdit,
    isReservationsDetailReportDetailSuccess,
    isReservationsDetailReportCreateSuccess,
    isReservationsDetailReportEditSuccess,
    isReservationsDetailReportEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
  };
  const getToastProps = () => {
    if (isReservationsDetailReportCreateSuccess || isReservationsDetailReportEditSuccess) {
      const toastTitle = isEdit
        ? "Reservations Detail Report Updated Successfully"
        : "Reservations Detail Report Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isReservationsDetailReportCreateError ||
      isReservationsDetailReportDetailError ||
      isReservationsDetailReportEditError
    ) {
      let toastTitle = "Error while Creating Reservations";
      if (isReservationsDetailReportEditError) {
        toastTitle = "Error while updating  Reservations";
      } else if (isReservationsDetailReportDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateReservationsDetailReportComponent {...createReservationsDetailReportProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromReservationsDetailReportReducer(state, "isLoading"),
   
    isReservationsDetailReportCreateSuccess: selectFromReservationsDetailReportReducer(
      state,
      "isReservationsDetailReportCreateSuccess"
    ),
    isReservationsDetailReportCreateError: selectFromReservationsDetailReportReducer(
      state,
      "isReservationsDetailReportCreateError"
    ),
    isReservationsDetailReportDetailSuccess: selectFromReservationsDetailReportReducer(
      state,
      "isReservationsDetailReportDetailSuccess"
    ),
    isReservationsDetailReportDetailError: selectFromReservationsDetailReportReducer(
      state,
      "isReservationsDetailReportDetailError"
    ),
    isReservationsDetailReportEditSuccess: selectFromReservationsDetailReportReducer(
      state,
      "isReservationsDetailReportEditSuccess"
    ),
    isReservationsDetailReportEditError: selectFromReservationsDetailReportReducer(
      state,
      "isReservationsDetailReportEditError"
    ),
    imageUploadResponse: selectFromReservationsDetailReportReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromReservationsDetailReportReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromReservationsDetailReportReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromReservationsDetailReportReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromReservationsDetailReportReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromReservationsDetailReportReducer(
      state,
      "isVehicleModelDDError"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    formFieldValueMap: selectFromReservationsDetailReportReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateReservationsDetailReportScreen: () => dispatch(initCreateReservationsDetailReportScreen()),
    createReservationsDetailReport: (payload) => dispatch(createReservationsDetailReport(payload)),
    editReservationsDetailReport: (payload) => dispatch(editReservationsDetailReport(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchReservationsDetailReportById: (payload) =>
      dispatch(fetchReservationsDetailReportById(payload)),

  };
};

const selectFromReservationsDetailReportReducer = (state, path) => {
  return selectReservationsDetailReportReducer(state)[path];
};

const selectReservationsDetailReportReducer = ({ ReservationsDetailReportReducer }) => {
  return ReservationsDetailReportReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservationsDetailReportContainer);
