

export const FETCH_VEHICLE_BODYSHOP_LIST = {
    START: 'FETCH_VEHICLE_BODYSHOP_LIST_START',
    SUCCESS: 'FETCH_VEHICLE_BODYSHOP_LIST_SUCCESS',
    ERROR: 'FETCH_VEHICLE_BODYSHOP_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_VEHICLE_BODYSHOP_INIT="FETCH_VEHICLE_BODYSHOP_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_VEHICLE_BODYSHOP_SCREEN =
'INIT_CREATE_VEHICLE_BODYSHOP_SCREEN';

export const CREATE_VEHICLE_BODYSHOP = {
START: 'CREATE_VEHICLE_BODYSHOP_START',
SUCCESS: 'CREATE_VEHICLE_BODYSHOP_SUCCESS',
ERROR: 'CREATE_VEHICLE_BODYSHOP_ERROR',
};

export const EDIT_VEHICLE_BODYSHOP = {
START: 'EDIT_VEHICLE_BODYSHOP_START',
SUCCESS: 'EDIT_VEHICLE_BODYSHOP_SUCCESS',
ERROR: 'EDIT_VEHICLE_BODYSHOP_ERROR',
};

export const FETCH_VEHICLE_BODYSHOP_DETAIL = {
  START: 'FETCH_VEHICLE_BODYSHOP_DETAIL_START',
  SUCCESS: 'FETCH_VEHICLE_BODYSHOP_DETAIL_SUCCESS',
  ERROR: 'FETCH_VEHICLE_BODYSHOP_DETAIL_ERROR',
};
export const EXPORT_VEHICLE_BODYSHOP = {
  START: 'EXPORT_VEHICLE_BODYSHOP_START',
  SUCCESS: 'EXPORT_VEHICLE_BODYSHOP_SUCCESS',
  ERROR: 'EXPORT_VEHICLE_BODYSHOP_ERROR',
};
export const VEHICLE_BODYSHOPDD = {
  START: 'VEHICLE_BODYSHOPDD_START',
  SUCCESS: 'VEHICLE_BODYSHOPDD_SUCCESS',
  ERROR: 'VEHICLE_BODYSHOPDD_ERROR',
};