import { createTheme } from '@mui/material/styles';

// dark theme
const theme = createTheme({
	palette: {
		primary: {
			main: '#000000',
			contrastText: '#390099'
		},
		background: {
			default: '#89fc00',
			topheaderbg:"#343434",
			menubg:"#000000",
			menuhoverbg:"#ededed",
			bluebg:"#1b75d0",
			bg:"#000000"
		},
		text: {
			primary: '#390099',
			topheadertext:"#ffffff",
			black:"#000000",
			menutext:"#fff",
			menuhovertxt:"#000",
			kpititle:"#fff",
			link:"#1769aa"
		}
	}
});

export default theme;