import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { RentersLogInteractionsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_LOGINTERACTIONS_INIT, 
  FETCH_RENTERS_LOGINTERACTIONS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RENTERS_LOGINTERACTIONS_SCREEN,
  CREATE_RENTERS_LOGINTERACTIONS,
  EDIT_RENTERS_LOGINTERACTIONS,
  TOAST,
  FETCH_RENTERS_LOGINTERACTIONS_DETAIL,
  EXPORT_RENTERS_LOGINTERACTIONS,
  RENTERS_LOGINTERACTIONSDD,
  RENTERS_LOGINTERACTIONSDELETE
} from "../../../types/Renters/LogInteractions.type";
export const initializeRentersLogInteractionsScreen = () => ({ type: FETCH_RENTERS_LOGINTERACTIONS_INIT });

export const RentersLogInteractionsFetchStart = (payload) => ({
  type: FETCH_RENTERS_LOGINTERACTIONS_LIST.START,
  payload,
});

export const RentersLogInteractionsFetchSuccess = (payload) => ({
  type:FETCH_RENTERS_LOGINTERACTIONS_LIST.SUCCESS,
  payload,
});

export const RentersLogInteractionsFetchError = (payload) => ({
  type: FETCH_RENTERS_LOGINTERACTIONS_LIST.ERROR,
  payload,
});

export const fetchRentersLogInteractions = (payload) => {
  return (dispatch) => {
    dispatch(RentersLogInteractionsFetchStart(payload));
    RentersLogInteractionsServices.fetchRentersLogInteractions(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersLogInteractionsFetchSuccess(data));
      } else {
        dispatch(RentersLogInteractionsFetchError(data));
      }
    });
  };
};



// DD
export const RentersLogInteractionsDDFetchStart = (payload) => ({
  type: RENTERS_LOGINTERACTIONSDD.START,
  payload,
});

export const RentersLogInteractionsDDFetchSuccess = (payload) => ({
  type:RENTERS_LOGINTERACTIONSDD.SUCCESS,
  payload,
});

export const RentersLogInteractionsDDFetchError = (payload) => ({
  type: RENTERS_LOGINTERACTIONSDD.ERROR,
  payload,
});

export const fetchRentersLogInteractionsDD = (payload) => {
  return (dispatch) => {
    dispatch(RentersLogInteractionsDDFetchStart(payload));
    RentersLogInteractionsServices.fetchRentersLogInteractionsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersLogInteractionsDDFetchSuccess(data));
      } else {
        dispatch(RentersLogInteractionsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const RentersLogInteractionsDetailFetchStart = (payload) => ({
  type: FETCH_RENTERS_LOGINTERACTIONS_DETAIL.START,
  payload,
});

export const RentersLogInteractionsDetailFetchSuccess = (payload) => ({
  type: FETCH_RENTERS_LOGINTERACTIONS_DETAIL.SUCCESS,
  payload,
});

export const RentersLogInteractionsDetailFetchError = (payload) => ({
  type: FETCH_RENTERS_LOGINTERACTIONS_DETAIL.ERROR,
  payload,
});

export const fetchRentersLogInteractionsById = (payload) => {
  return (dispatch) => {
    dispatch(RentersLogInteractionsDetailFetchStart(payload));

    RentersLogInteractionsServices
      .fetchRentersLogInteractionsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersLogInteractionsDetailFetchSuccess(data));
        } else {
          dispatch(RentersLogInteractionsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    RentersLogInteractionsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRentersLogInteractionsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RENTERS_LOGINTERACTIONS_SCREEN,
  payload,
});

export const initCreateRentersLogInteractionsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRentersLogInteractionsScreenInitStart());
  };
};


export const createRentersLogInteractionsStart = () => ({
  type: CREATE_RENTERS_LOGINTERACTIONS.START,
});

export const createRentersLogInteractionsSuccess = (payload) => ({
  type: CREATE_RENTERS_LOGINTERACTIONS.SUCCESS,
  payload,
});

export const createRentersLogInteractionsError = (payload) => ({
  type: CREATE_RENTERS_LOGINTERACTIONS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRentersLogInteractions = (payload) => {
  return (dispatch) => {
    dispatch(createRentersLogInteractionsStart());
    RentersLogInteractionsServices.createRentersLogInteractions(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRentersLogInteractionsSuccess());
        goBack();
      } else {
        dispatch(createRentersLogInteractionsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRentersLogInteractionsStart = (payload) => ({
  type: EDIT_RENTERS_LOGINTERACTIONS.START,
  payload
});

export const editRentersLogInteractionsSuccess = (payload) => ({
  type: EDIT_RENTERS_LOGINTERACTIONS.SUCCESS,
  payload,
});

export const editRentersLogInteractionsError = (payload) => ({
  type: EDIT_RENTERS_LOGINTERACTIONS.ERROR,
  payload,
});

export const editRentersLogInteractions = (payload) => {
  return (dispatch) => {
    dispatch(editRentersLogInteractionsStart());
    RentersLogInteractionsServices.editRentersLogInteractions(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRentersLogInteractionsSuccess());
        goBack();
      } else {
        dispatch(editRentersLogInteractionsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRentersLogInteractionsRecordStart = (payload) => ({
  type: EXPORT_RENTERS_LOGINTERACTIONS.START,
  payload
});

export const exportRentersLogInteractionsRecordSuccess = (payload) => ({
  type: EXPORT_RENTERS_LOGINTERACTIONS.SUCCESS,
  payload,
});

export const exportRentersLogInteractionsRecordError = (payload) => ({
  type: EXPORT_RENTERS_LOGINTERACTIONS.ERROR,
  payload,
});

export const exportRentersLogInteractions = (payload) => {
  return (dispatch) => {
    dispatch(exportRentersLogInteractionsRecordStart());
    RentersLogInteractionsServices.exportRentersLogInteractionsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRentersLogInteractionsRecordSuccess(data));
      } else {
        dispatch(exportRentersLogInteractionsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const RentersLogInteractionsDeleteStart = (payload) => ({
  type: RENTERS_LOGINTERACTIONSDELETE.START,
  payload,
});

export const RentersLogInteractionsDeleteSuccess = (payload) => ({
  type: RENTERS_LOGINTERACTIONSDELETE.SUCCESS,
  payload,
});

export const RentersLogInteractionsDeleteError = (payload) => ({
  type: RENTERS_LOGINTERACTIONSDELETE.ERROR,
  payload,
});

export const deleteRentersLogInteractions = (payload) => {
  return (dispatch) => {
    dispatch(RentersLogInteractionsDeleteStart(payload));

    RentersLogInteractionsServices
      .deleteRentersLogInteractions(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersLogInteractionsDeleteSuccess(data));
          dispatch(fetchRentersLogInteractions(payload?.params));
        } else {
          dispatch(RentersLogInteractionsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/