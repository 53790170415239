import React from 'react';
import "./style.css"
const FileUpload = ({ register, onChange, error, rules }) => {
    return (
        <div className='fileupload'>
            <label htmlFor="file">Upload File:</label>
            <input
                type="file"
                {...register('file', {
                    required: rules,
                    onChange: (e) => onChange(e)
                })}
            />
            {error && <span>This field is required</span>}
        </div>
    );
};

export default FileUpload;
