import React, { useEffect, useMemo, useState } from "react";
import { useForm} from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import {  basicDetail, editMode, statusDD, typeDD} from "../CreateSettingsPaynowOptions/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledMultiSelecttWithFloatingLabel from "../../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { SettingsSettingWeekendsBreadcrumb } from "../../SettingsSettingWeekends/CreateSettingsSettingWeekends/constant";
import { hourslist } from "../../../Location/CreateLocation/constant";
import { formatDatesHyphan } from "../../../../utils";
const CreateSettingsPaynowOptionsComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateSettingsPaynowOptionsScreen,
    createSettingsPaynowOptions,
    editSettingsPaynowOptions,
    isLoading,
    isSettingsPaynowOptionsCreateSuccess,
    isSettingsPaynowOptionsCreateError,
    isSettingsPaynowOptionsDetailSuccess,
    isSettingsPaynowOptionsDetailError,
    isSettingsPaynowOptionsEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
    locationDD,
    categoryListview
  } = props;
 

  const classes = useStyles()
 
  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
const [categoryData, setCategoryData]=useState({})
  useEffect(() => {
    let newData = categoryListview;
    newData=  newData.map(item=>({
      ...item,
      paynow:"1",
      isChecked:false,
      paylater:"0",
      payOption:""
    }))

    setCategoryData(newData)
  }, [categoryListview]);
  
  const handleCheckbox = (e, i) => {
    let {name, value, checked} = e.target;
      console.log(name, value, checked)
      const updatedData = categoryData.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item }
      ))
      setCategoryData(updatedData)
  }

  const handleRadio = (e, i) => {
    let {name, value, checked} = e.target;
    const updatedData = categoryData.map((item, index) => (
      i === index ? { ...item, payOption: value } : { ...item }
    ))
    console.log("updatedData", updatedData)
    setCategoryData(updatedData)
  }
   const navigateToSettingsPaynowOptionsScreen = () => {
    window.location.replace("/#"+APP_URLS.SETTINGS_PAYNOWOPTIONS.HOME)
  };
  useEffect(() => {
    
    if (isSettingsPaynowOptionsCreateSuccess || isSettingsPaynowOptionsEditSuccess) {
      reset();
    }
  }, [isSettingsPaynowOptionsCreateSuccess]);
  useEffect(() => {
    if (isSettingsPaynowOptionsDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
      let categoryD = formFieldValueMap.payOptionCategoryList;
      
    
      const updatedWeekDaysData = categoryData.map(item => {
        const matchedItem = categoryD.find(weekendItem => weekendItem.categoryId === item.id);
        return {
          ...item,
          isChecked: !!matchedItem ? true : item.isChecked,
          payOption: matchedItem ? matchedItem.payOption : item.payOption,
        };
      });
      console.log(updatedWeekDaysData,"updatedWeekDaysData")
      setCategoryData(updatedWeekDaysData)
    }
  }, [isSettingsPaynowOptionsDetailSuccess]);
  useEffect(() => {
    initCreateSettingsPaynowOptionsScreen();
  }, []);
  
  const onSubmit = (data) => {

    const nData = categoryData.filter(item=> item.isChecked===true);
    let wday=[];
    nData.map(item=>(
      wday.push({
        categoryId:item.id,
        payOption:item.payOption
      })
    ));
   
    const stringifiedData = {
      id:data.id,
      endDate:formatDatesHyphan(data.endDate),
    startDate:formatDatesHyphan(data.startDate),
    startTime:data.startTime,
    endTime:data.endTime,
    locationId:data.locationId,
    modBy:data.modBy,
    modTime:data.modTime,
    status:1,
    payOptionCategoryList:wday,
    userId:localStorage.getItem("userId")
    }

      isEdit
        ? editSettingsPaynowOptions({ id: data.id, data: stringifiedData })
        : createSettingsPaynowOptions(stringifiedData);
    
  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
};

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Paynow Options</h4>
          </div>
          <div className="buttonarea">
          <Button
            tooltip={"Back to Paynow Options"}
            tooltipOptions={{ position: "left" }}
            label={"Back to Paynow Options"}
            // icon={"pi pi-arrow-left"}
            className={`p-button `}
            style={{ borderRadius: "10px" }}
            onClick={navigateToSettingsPaynowOptionsScreen}
          />
        </div>
        </div>
      </div>
    );
  };
 
  return (
    <Container maxWidth="lg">
      <Breadcrumb list={SettingsSettingWeekendsBreadcrumb } />
      <EzToast {...getToastProps()} /> 
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
         
              <Grid container spacing={2}>
              {basicDetail?.map((item) => (
               item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
      id={item.name}
      name={item.name}
      {...register(item.name)}
      showTime={false}
      maxDate={new Date(2090, 0, 1)}
      dateFormat="yy/mm/dd" 
      control={control}
      isError={errors[item.name]}
      errorMsg={getFormErrorMessage(item.name)}
      label={item.label}
      rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
    />
                  </Grid>
                )
                :
                  item.name === "locationId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel 
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={locationDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :
                    item.name === "startTime" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={hourslist}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :

                    item.name === "endTime" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={hourslist }
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :

                    
                          (
                            (<Grid item xs={3} key={item.name}>
                              <EzControlledInputWithFloatingLabel
                                name={item?.name}
                                control={control}
                                label={item?.label}
                                type={item?.type}
                                rules={item?.rule}
                                isError={errors[item?.name]}
                                errorMsg={getFormErrorMessage(item?.name)}
                              />
                            </Grid>))
              ))}
              </Grid>
            

              <Grid container>
              <Grid container className="catcontbox">
              <Grid container  xs={6} >
              <Grid item xs={6}>Category</Grid>
              <Grid item xs={6}>Payment Option</Grid>
              </Grid>
              <Grid container xs={6} >
              <Grid item xs={6}>Category</Grid>
              <Grid item xs={6}>Payment Option</Grid>
              </Grid>
              </Grid>
              {categoryData && categoryData.length>0 && categoryData.map((item,index)=>(
                <Grid container className="catboxoption" xs={6} key={item.id}>
                
                  <Grid item xs={6} key={item.id}>
                            <EzControlledCheckBoxWithLabel
                              name={item.title}
                              value={item.isChecked}
                              id={item.id}
                              control={control}
                              isError={errors[item.title]}
                              label={item.title}
                              labelClassName={'ml-2'}
                              onChange={(e)=>handleCheckbox(e, index)}
                            />
                          </Grid>
                          <Grid xs={6} container spacing={2}>
                          <Grid item alignItems={"center"} textAlign={"center"}>
                  <input checked={item.payOption==item?.paynow} type="radio" onChange={(e)=>handleRadio(e, index)} name={"payOption_"+index} value={item?.paynow} /> Pay Now
                </Grid>
                <Grid item alignItems={"center"} textAlign={"center"} >
                  <input checked={item.payOption==item?.paylater} type="radio" onChange={(e)=>handleRadio(e, index)} name={"payOption_"+index} value={item?.paylater} /> Pay Later
                </Grid>
                          </Grid>
                  </Grid>
              ))}
              </Grid>
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Update" : "Save"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
            {/* {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
} */}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateSettingsPaynowOptionsComponent;
