import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST, 
  TOAST, 
  FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_INIT,
  FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL,
  EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS,
  CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS,
  EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS,
  INIT_CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SCREEN,
  RESERVATIONS_CRXREZPOWERRESERVATIONSDD,
  RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Reservations/CRXRezpowerReservations.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    ReservationsCRXRezpowerReservationsListview: [],
    ReservationsCRXRezpowerReservationsDD:[],
    isReservationsCRXRezpowerReservationsDDSuccess: false,
    isReservationsCRXRezpowerReservationsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isReservationsCRXRezpowerReservationsCreateSuccess: false,
    isReservationsCRXRezpowerReservationsCreateError: false,
    isReservationsCRXRezpowerReservationsDetailSuccess: false,
    isReservationsCRXRezpowerReservationsDetailError: false,
    isReservationsCRXRezpowerReservationsEditSuccess: false,
    isReservationsCRXRezpowerReservationsEditError: false,
    isReservationsCRXRezpowerReservationsExportSuccess: false,
    isReservationsCRXRezpowerReservationsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isReservationsCRXRezpowerReservationsDeleteSuccess: false,
    isReservationsCRXRezpowerReservationsDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Reservation Number",
        dataKey: "reservationNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      
     
      {
        colLabel: "Reservation ID",
        dataKey: "reservationID",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Email",
        dataKey: "email",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Pickup Date and Time",
        dataKey: "pickupDateandTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Dropoff Date and Time",
        dataKey: "dropoffDateandTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Total",
        dataKey: "total",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const ReservationsCRXRezpowerReservationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL.START:
      case EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST.START:
      case CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS.START:
        case RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE.START:
        case EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS.START:
          case RESERVATIONS_CRXREZPOWERRESERVATIONSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          ReservationsCRXRezpowerReservationsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isReservationsCRXRezpowerReservationsCreateSuccess: false,
          isReservationsCRXRezpowerReservationsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          ReservationsCRXRezpowerReservationsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          ReservationsCRXRezpowerReservationsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isReservationsCRXRezpowerReservationsDeletionSuccess: false,
          isReservationsCRXRezpowerReservationsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReservationsCRXRezpowerReservationsDetailSuccess: true,
      };
    }
    case FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsCRXRezpowerReservationsDetailSuccess: false,
        isReservationsCRXRezpowerReservationsDetailError: true,
      };
    }

    //DD
    case RESERVATIONS_CRXREZPOWERRESERVATIONSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        ReservationsCRXRezpowerReservationsDD:temp,
        isReservationsCRXRezpowerReservationsDDSuccess: true,
        isReservationsCRXRezpowerReservationsDDError: false,
      };
    }
    case RESERVATIONS_CRXREZPOWERRESERVATIONSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsCRXRezpowerReservationsDDSuccess: false,
        isReservationsCRXRezpowerReservationsDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isReservationsCRXRezpowerReservationsDeleteSuccess: false,
    isReservationsCRXRezpowerReservationsDeleteError: false,
  };
}
case RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isReservationsCRXRezpowerReservationsDeleteSuccess: true,
    isReservationsCRXRezpowerReservationsDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isReservationsCRXRezpowerReservationsDeleteSuccess: false,
    isReservationsCRXRezpowerReservationsDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isReservationsCRXRezpowerReservationsExportSuccess: false,
    isReservationsCRXRezpowerReservationsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"CRX-rezpower-reservations.xls");
   return {
    ...state,
    isReservationsCRXRezpowerReservationsExportSuccess: true,
    isReservationsCRXRezpowerReservationsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsCRXRezpowerReservationsEditSuccess: false,
    isReservationsCRXRezpowerReservationsEditError: true,
  };
}

case EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsCRXRezpowerReservationsEditSuccess: true,
    isReservationsCRXRezpowerReservationsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsCRXRezpowerReservationsCreateSuccess: false,
    isReservationsCRXRezpowerReservationsCreateError: true,
  };
}
case CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsCRXRezpowerReservationsCreateSuccess: true,
    isReservationsCRXRezpowerReservationsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isReservationsCRXRezpowerReservationsCreateSuccess: false,
          isReservationsCRXRezpowerReservationsCreateError: false,
          isReservationsCRXRezpowerReservationsEditError: false,
          isReservationsCRXRezpowerReservationsEditSuccess: false,
          isReservationsCRXRezpowerReservationsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  