

export const FETCH_RATEADMIN_BLACKOUTS_LIST = {
    START: 'FETCH_RATEADMIN_BLACKOUTS_LIST_START',
    SUCCESS: 'FETCH_RATEADMIN_BLACKOUTS_LIST_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_BLACKOUTS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RATEADMIN_BLACKOUTS_INIT="FETCH_RATEADMIN_BLACKOUTS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RATEADMIN_BLACKOUTS_SCREEN =
'INIT_CREATE_RATEADMIN_BLACKOUTS_SCREEN';

export const CREATE_RATEADMIN_BLACKOUTS = {
START: 'CREATE_RATEADMIN_BLACKOUTS_START',
SUCCESS: 'CREATE_RATEADMIN_BLACKOUTS_SUCCESS',
ERROR: 'CREATE_RATEADMIN_BLACKOUTS_ERROR',
};

export const EDIT_RATEADMIN_BLACKOUTS = {
START: 'EDIT_RATEADMIN_BLACKOUTS_START',
SUCCESS: 'EDIT_RATEADMIN_BLACKOUTS_SUCCESS',
ERROR: 'EDIT_RATEADMIN_BLACKOUTS_ERROR',
};

export const FETCH_RATEADMIN_BLACKOUTS_DETAIL = {
  START: 'FETCH_RATEADMIN_BLACKOUTS_DETAIL_START',
  SUCCESS: 'FETCH_RATEADMIN_BLACKOUTS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RATEADMIN_BLACKOUTS_DETAIL_ERROR',
};
export const EXPORT_RATEADMIN_BLACKOUTS = {
  START: 'EXPORT_RATEADMIN_BLACKOUTS_START',
  SUCCESS: 'EXPORT_RATEADMIN_BLACKOUTS_SUCCESS',
  ERROR: 'EXPORT_RATEADMIN_BLACKOUTS_ERROR',
};
export const RATEADMIN_BLACKOUTSDD = {
  START: 'RATEADMIN_BLACKOUTSDD_START',
  SUCCESS: 'RATEADMIN_BLACKOUTSDD_SUCCESS',
  ERROR: 'RATEADMIN_BLACKOUTSDD_ERROR',
};