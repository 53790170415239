import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminLorAdjustmentsScreen,
  createRateAdminLorAdjustments,
  editRateAdminLorAdjustments,
  fetchRateAdminLorAdjustmentsById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminLorAdjustments";
import {
  fetchRateAdminRatePlanDD
} from "../../../../redux/actions/RateAdmin/rateadminRatePlan";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminLorAdjustmentsComponent from "../../../../component/RateAdmin/RateAdminLorAdjustments/CreateRateAdminLorAdjustments";
import { useParams } from "react-router-dom";

const CreateRateAdminLorAdjustmentsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminLorAdjustments,
    editRateAdminLorAdjustments,
    isLoading,
    isRateAdminLorAdjustmentsCreateSuccess,
    isRateAdminLorAdjustmentsCreateError,
    isRateAdminLorAdjustmentsDetailSuccess,
    isRateAdminLorAdjustmentsDetailError,
    isRateAdminLorAdjustmentsEditSuccess,
    isRateAdminLorAdjustmentsEditError,
    isEdit,
    RatePlanDD,
    isView,
    initCreateRateAdminLorAdjustmentsScreen,
    formFieldValueMap,
    fetchRateAdminLorAdjustmentsById,
    uploadImage,
    fetchRateAdminRatePlanDD,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminLorAdjustmentsScreen();
    await fetchRateAdminRatePlanDD()
   if (isEdit) {
    await fetchRateAdminLorAdjustmentsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminLorAdjustmentsProps = {
    createRateAdminLorAdjustments,
    initCreateRateAdminLorAdjustmentsScreen,
    editRateAdminLorAdjustments,
    isLoading,
    isEdit,
    isRateAdminLorAdjustmentsDetailSuccess,
    isRateAdminLorAdjustmentsCreateSuccess,
    isRateAdminLorAdjustmentsEditSuccess,
    isRateAdminLorAdjustmentsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    RatePlanDD,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRateAdminLorAdjustmentsCreateSuccess || isRateAdminLorAdjustmentsEditSuccess) {
      const toastTitle = isEdit
        ? "Lor Adjustments Updated Successfully"
        : "Lor Adjustments Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminLorAdjustmentsCreateError ||
      isRateAdminLorAdjustmentsDetailError ||
      isRateAdminLorAdjustmentsEditError
    ) {
      let toastTitle = "Error while Creating Lor Adjustments";
      if (isRateAdminLorAdjustmentsEditError) {
        toastTitle = "Error while updating  Lor Adjustments";
      } else if (isRateAdminLorAdjustmentsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminLorAdjustmentsComponent {...createRateAdminLorAdjustmentsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminLorAdjustmentsReducer(state, "isLoading"),
   
    isRateAdminLorAdjustmentsCreateSuccess: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isRateAdminLorAdjustmentsCreateSuccess"
    ),
    isRateAdminLorAdjustmentsCreateError: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isRateAdminLorAdjustmentsCreateError"
    ),
    isRateAdminLorAdjustmentsDetailSuccess: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isRateAdminLorAdjustmentsDetailSuccess"
    ),
    isRateAdminLorAdjustmentsDetailError: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isRateAdminLorAdjustmentsDetailError"
    ),
    isRateAdminLorAdjustmentsEditSuccess: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isRateAdminLorAdjustmentsEditSuccess"
    ),
    isRateAdminLorAdjustmentsEditError: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isRateAdminLorAdjustmentsEditError"
    ),
    imageUploadResponse: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminLorAdjustmentsReducer(
      state,
      "isVehicleModelDDError"
    ),
    RatePlanDD: selectFromRateAdminRatePlanReducer(
      state,
      "RatePlanDD"
    ),
    isRatePlanDDDDSuccess: selectFromRateAdminRatePlanReducer(
      state,
      "isRatePlanDDDDSuccess"
    ),
    isRatePlanDDDDError: selectFromRateAdminRatePlanReducer(
      state,
      "isRatePlanDDDDError"
    ),
    formFieldValueMap: selectFromRateAdminLorAdjustmentsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminLorAdjustmentsScreen: () => dispatch(initCreateRateAdminLorAdjustmentsScreen()),
    createRateAdminLorAdjustments: (payload) => dispatch(createRateAdminLorAdjustments(payload)),
    editRateAdminLorAdjustments: (payload) => dispatch(editRateAdminLorAdjustments(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchRateAdminRatePlanDD: (payload) => dispatch(fetchRateAdminRatePlanDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminLorAdjustmentsById: (payload) =>
      dispatch(fetchRateAdminLorAdjustmentsById(payload)),

  };
};

const selectFromRateAdminLorAdjustmentsReducer = (state, path) => {
  return selectRateAdminLorAdjustmentsReducer(state)[path];
};

const selectRateAdminLorAdjustmentsReducer = ({ rateadminLorAdjustmentsReducer }) => {
  return rateadminLorAdjustmentsReducer;
};
//Rate Plan 
const selectFromRateAdminRatePlanReducer = (state, path) => {
  return selectRateAdminRatePlanReducer(state)[path];
};

const selectRateAdminRatePlanReducer = ({ rateadminRatePlanReducer }) => {
  return rateadminRatePlanReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminLorAdjustmentsContainer);
