import React, { useEffect, useMemo, useState } from "react";
import { useForm} from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { RateAdminBreadcrumb, RateTypeDD, basicDetail, columns, editMode, rateAdjustment, rateExtraDaily, rateExtraMonthly, rateExtraWeekend, rateExtraWeekly, rateTest, statusDD,} from "../CreateRateAdminRates/constant"
import { Box, Container, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel"
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import { additionbaselor } from "../../../../utils/common";
import { formatDates, formatDatesHyphan } from "../../../../utils";
import EzDialog from "../../../../common/EzDialog";
import { useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { hourslist } from "../../../Location/CreateLocation/constant";
const CreateRateAdminRatesComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRateAdminRatesScreen,
    createRateAdminRates,
    createRateAdminRateAdjustment,
    editRateAdminRates,
    isLoading,
    isRateAdminRatesCreateSuccess,
    isRateAdminRateAdjSuccess,
    isRateAdminRatesCreateError,

    isRateAdminRateAdjListSuccess,
    isRateAdminRateAdjListError,
    isRateAdminRatesDetailSuccess,
    isRateAdminRatesDetailError,
    isRateAdminRatesEditSuccess,
    isEdit,
    RateCodeDD,
    locationDD,
    vehicleCategoryDD,
    isView,
    formFieldValueMap,
    rateAdjustmentlist,
    formFieldValueMap2,
    RateOptionsDD,
    RateNetworkDD,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
    RateTest,
    TestRateAdminRates
  } = props;
  console.log("check props of compoemnt", props);
 

  const classes = useStyles()
 
  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

  const {
    handleSubmit: handleSubmit2,
    control:control2,
    reset: resetForm2,
    formState: { errors:errors2, isDirty:isDirty2 },
} = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap2;
    }, [formFieldValueMap2]),
  });
  let formFieldValueMap3={
    endDate:"",
    endTime:"",
    rateId:"",
    startDate:"",
    startTime:""
  }
  const {
    handleSubmit: handleSubmit3,
    control:control3,
    reset: resetForm3,
    formState: { errors:errors3, isDirty:isDirty3 },
} = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap3;
    }, [formFieldValueMap3]),
  });
  const handleCreateRateAdminRates = () => {
    navigate(APP_URLS.RATEADMIN_RATES.CREATE);
  }
const [breakdown, setBreakdown]= useState(false);
 const dailybaseRate = watch("dailybaseRate");
 const dailylor1st = watch("dailylor1st");
 const dailylor2nd = watch("dailylor2nd");
 const dailylor3rd = watch("dailylor3rd");
 const dailylor4th = watch("dailylor4th");
 const dailylor5th = watch("dailylor5th");
 const dailylor6th = watch("dailylor6th");

 const weekendbaseRate = watch("weekendbaseRate");
 const weekendlor1st = watch("weekendlor1st");
 const weekendlor2nd = watch("weekendlor2nd");
 const weekendlor3rd = watch("weekendlor3rd");
 const weekendlor4th = watch("weekendlor4th");
 const weekendlor5th = watch("weekendlor5th");
 const weekendlor6th = watch("weekendlor6th");

 const weeklybaseRate = watch("weeklybaseRate");
 const weeklylor1st = watch("weeklylor1st");
 const weeklylor2nd = watch("weeklylor2nd");
 const weeklylor3rd = watch("weeklylor3rd");
 const weeklylor4th = watch("weeklylor4th");



 const [showDialog, setShowDialog] = useState(false);
 const [rateAdjustmentList, setRateAdjustmentList] = useState([]);
 const onDialogHide = () => {
  setShowDialog(false);
}
const showRateAdjustment = () => {
  setShowDialog(true);
}

const [showTestDialog, setShowTestDialog] = useState(false);
const onDialogTestHide = () => {
  setShowTestDialog(false);
}
const showRateTest = () => {
  setShowTestDialog(true);
}

   const navigateToRateAdminRatesScreen = () => {
    window.location.replace("/#"+APP_URLS.RATEADMIN_RATES.HOME)
  };
  useEffect(() => {
    
    if (isRateAdminRatesCreateSuccess || isRateAdminRatesEditSuccess) {
      reset();
    }
  }, [isRateAdminRatesCreateSuccess]);
  useEffect(() => {
    initCreateRateAdminRatesScreen();
  }, []);

 

  const { id } = useParams();
  const onRateSubmit =(data) =>{
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    newData["rateId"] = id;
let stringifiedData={
   ...newData
}
    isEdit
    && createRateAdminRateAdjustment( stringifiedData )
  }
  const onRateTestSubmit =(data) =>{
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    newData["rateId"] = id;
    newData["startDate"] = formatDatesHyphan(data?.startDate);
    newData["endDate"] = formatDatesHyphan(data?.endDate);
    
let stringifiedData={
   ...newData
}
    isEdit
    && TestRateAdminRates( stringifiedData )
  }
  useEffect(() => {
    if (isRateAdminRatesDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isRateAdminRatesDetailSuccess]);
  useEffect(() => {
    if (isRateAdminRateAdjListSuccess) {

      const recordsArray = Object.entries(formFieldValueMap2).map(([key, value]) => {
        return { key, value };
      });
      setRateAdjustmentList(recordsArray);
      resetForm2({
        ...formFieldValueMap2,
      });
    }
  }, [isRateAdminRateAdjListSuccess]);

  const copyRateAdminRatesRoute = () => {
    const path = generatePath(APP_URLS.RATEADMIN_RATES.COPY, {
      id,
    });
    navigate(path);
  };


  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    let rateBaseDTO =[
      {
        "fkRatePlanId": 1,
      "id": null || isEdit && data?.dailybaseId,
      "miles": data?.dailybaseRate,
      "rate": data?.dailybaseMiles
      },
      {
        "fkRatePlanId": 2,
      "id": null || isEdit && data?.weekendbaseId,
      "miles": data?.weekendbaseRate,
      "rate": data?.weekendbaseMiles
      },
      {
        "fkRatePlanId": 3,
      "id": null || isEdit && data?.weeklybaseId,
      "miles": data?.weeklybaseRate,
      "rate": data?.weeklybaseMiles
      },
      {
        "fkRatePlanId": 4,
      "id": null || isEdit && data?.monthlybaseId,
      "miles": data?.monthlybaseRate,
      "rate": data?.monthlybaseMiles
      }
    ]

    let rateExtraDayDTOS =[
      {
        "fkRatePlanId": 1,
        "id": null || isEdit && data?.dailyextraId,
        "miles": data?.dailyextraRate,
        "rate": data?.dailyextraMiles,
        "status": 0
      },
      {
        "fkRatePlanId": 2,
        "id": null || isEdit && data?.weekendextraId,
        "miles": data?.weekendextraRate,
        "rate": data?.weekendextraMiles,
        "status": 0
      },
      {
        "fkRatePlanId": 3,
        "id": null || isEdit && data?.weeklyextraId,
        "miles": data?.weeklyextraRate,
        "rate": data?.weeklyextraMiles,
        "status": 0
      },
      {
        "fkRatePlanId": 4,
        "id": null || isEdit && data?.monthlyextraId,
        "miles": data?.monthlyextraRate,
        "rate": data?.monthlyextraMiles,
        "status": 0
      }
    ]
    let rateExtraHourDTOS =[
      {
        "fkRatePlanId": 1,
        "id": null || isEdit && data?.dailyhoursId,
        "rate": data?.dailyhoursRate
      },
      {
        "fkRatePlanId": 2,
        "id": null || isEdit && data?.weekendhoursId,
        "rate": data?.weekendhoursRate
      },
      {
        "fkRatePlanId": 3,
        "id": null || isEdit && data?.weeklyhoursId,
        "rate": data?.weeklyhoursRate
      },
      {
        "fkRatePlanId": 4,
        "id": null || isEdit && data?.monthlyhoursId,
        "rate": data?.monthlyhoursRate
      }
    ]
    let rateLorAdjustmentDTOS=[
{
"fkLorId": 1,
"id": null || isEdit && data?.dailylor1stId,
"rate": data?.dailylor1st
},
{
"fkLorId": 2,
"id": null || isEdit && data?.dailylor2ndId,
"rate": data?.dailylor2nd
},
{
"fkLorId": 3,
"id": null || isEdit && data?.dailylor3rdId,
"rate": data?.dailylor3rd
},
{
"fkLorId": 4,
"id": null || isEdit && data?.dailylor4thId,
"rate": data?.dailylor4th
},
{
"fkLorId": 5,
"id": null || isEdit && data?.dailylor5thId,
"rate": data?.dailylor5th
},
{
"fkLorId": 6,
"id": null || isEdit && data?.dailylor6thId,
"rate": data?.dailylor6th
},
{
"fkLorId": 7,
"id": null || isEdit && data?.weekendlor1stId,
"rate": data?.weekendlor1st
},
{
  "fkLorId": 8,
  "id": null || isEdit && data?.weekendlor2ndId,
  "rate": data?.weekendlor2nd
  },
  {
    "fkLorId": 9,
    "id": null || isEdit && data?.weekendlor3rdId,
    "rate": data?.weekendlor3rd
    },
    {
      "fkLorId": 10,
      "id": null || isEdit && data?.weekendlor4thId,
      "rate": data?.weekendlor4th
      },
      {
        "fkLorId": 11,
        "id": null || isEdit && data?.weekendlor5thId,
        "rate": data?.weekendlor5th
        },
        {
          "fkLorId": 12,
          "id": null || isEdit && data?.weekendlor6thId,
          "rate": data?.weekendlor6th
          },
  
  {
    "fkLorId": 13,
    "id": null || isEdit && data?.weeklylor1stId,
    "rate": data?.weeklylor1st
    },
    {
      "fkLorId": 14,
      "id": null || isEdit && data?.weeklylor2ndId,
      "rate": data?.weeklylor2nd
      },
      {
        "fkLorId": 15,
        "id": null || isEdit && data?.weeklylor3rdId,
        "rate": data?.weeklylor3rd
        },
        {
          "fkLorId": 16,
          "id": null || isEdit && data?.weeklylor4thId,
          "rate": data?.weeklylor4th
          }
    ]
    
    const stringifiedData = {
      id:isEdit ? data?.id : null,
      status:1,
      rateOption:data?.rateOption,
      extraMiles:data?.extraMiles,
      startDate:formatDatesHyphan(data?.startDate),
      endDate:formatDatesHyphan(data?.endDate),
      fkCategory:data?.fkCategory,
      fkLocationId:data?.fkLocationId,
      fkNetworkId:data?.fkNetworkId,
      fkRateGroup:data?.fkRateGroup,
      fkRateTypeId:data?.fkRateTypeId,
      userId:localStorage.getItem("userId"),
      rateBaseDTO:rateBaseDTO,
      rateExtraDayDTOS:rateExtraDayDTOS,
      rateExtraHourDTOS:rateExtraHourDTOS,
      rateLorAdjustmentDTOS:rateLorAdjustmentDTOS
    }
      isEdit
        ? editRateAdminRates({ id: data.id, data: stringifiedData })
        : createRateAdminRates(stringifiedData);
    
  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
};

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  
  const getFormErrorMessage2 = (name) => {
    return (
      errors2[name] && <small className="p-error">{errors2[name].message}</small>
    );
  };
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">{isEdit ? "Update Rate" :"Create Rate"}</h4>
          </div>
          <div className="buttonarea">

          {isEdit &&
          (<>
<Button
            tooltip={"Rate Test"}
            tooltipOptions={{ position: "left" }}
            label={"Rate Test"}
            className={`p-button black-button`}
            style={{ borderRadius: "10px" }}
            onClick={showRateTest}
          />

         <Button
            tooltip={"Rate Adjustment"}
            tooltipOptions={{ position: "left" }}
            label={"Rate Adjustment"}
            className={`p-button`}
            style={{ borderRadius: "10px" }}
            onClick={showRateAdjustment}
          />
          </>
        
        
        )}
          <Button
            tooltip={"Back to Rates"}
            tooltipOptions={{ position: "left" }}
            label={"Back to Rates"}
            // icon={"pi pi-arrow-left"}
            className={`p-button `}
            style={{ borderRadius: "10px" }}
            onClick={navigateToRateAdminRatesScreen}
          />
        </div>
        </div>
      </div>
    );
  };
 
  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RateAdminBreadcrumb} />
      <EzToast {...getToastProps()} /> 
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
         
              <Grid container spacing={2}>
                {basicDetail?.map((item) => (
                  item.name === "rateOption" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={RateOptionsDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ):item.name === "fkRateGroup" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={RateCodeDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                  :item.name === "fkCategory" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={vehicleCategoryDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                  :item.name === "fkRateTypeId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={RateTypeDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )

                  :item.type === "date" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledCalendarWithFloatingLabel
        id={item.name}
        name={item.name}
        {...register(item.name)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        dateFormat="yy/mm/dd" 
        control={control}
        isError={errors[item.name]}
        errorMsg={getFormErrorMessage(item.name)}
        label={item.label}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      />
                    </Grid>
                  )
                  :item.name === "fkLocationId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={locationDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                  :item.name === "fkNetworkId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={RateNetworkDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                  :(
                  (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
                ))}

              </Grid>
           <Grid>
            <table className="tablerate">
              <tr>
                <th>Rate</th>
                <th colSpan={"2"}>Base</th>
                <th colSpan={"2"}>Extra Day	</th>
                <th>Hour</th>
                <th colSpan={"6"}>LOR Adjustments</th>
              </tr>
              <tr>
                <td>Plan</td>
                <td>Rate</td>
                <td>Miles</td>
                <td>Rate</td>
                <td>Miles</td>
                <td>Rate</td>
                <td>1st</td>
                <td>2nd</td>
                <td>3rd</td>
                <td>4th</td>
                <td>5th</td>
                <td>6th</td>
              </tr>
              <tr>
              {rateExtraDaily?.map((item) => (
                item.name!==undefined ?(
                <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                     {item.notify!==undefined ? <span className="red">{item.notify}</span>:""}
                 
                     {item.name ==="dailylor1st" ?  <span className="red">{additionbaselor(dailybaseRate, dailylor1st)}</span> 
                     : item.name ==="dailylor2nd" ?  <span className="red">{additionbaselor(dailybaseRate, dailylor2nd)}</span>
                     : item.name ==="dailylor3rd" ?  <span className="red">{additionbaselor(dailybaseRate, dailylor3rd)}</span>
                     :item.name ==="dailylor4th" ?  <span className="red">{additionbaselor(dailybaseRate, dailylor4th)}</span>
                     :item.name ==="dailylor5th" ?  <span className="red">{additionbaselor(dailybaseRate, dailylor5th)}</span>
                     : item.name ==="dailylor6th" ?  <span className="red">{additionbaselor(dailybaseRate, dailylor6th)}</span>:""}
                     
               </td>)
               :(<td>{item.label}</td>)
                ))}
 </tr>

 <tr>
              {rateExtraWeekend ?.map((item) => (
                item.name!==undefined ?(
                <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                       {item.notify!==undefined ? <span className="red">{item.notify}</span>:""}
                 
                 {item.name ==="weekendlor1st" ?  <span className="red">{additionbaselor(weekendbaseRate, weekendlor1st)}</span> 
                 : item.name ==="weekendlor2nd" ?  <span className="red">{additionbaselor(weekendbaseRate, weekendlor2nd)}</span>
                 : item.name ==="weekendlor3rd" ?  <span className="red">{additionbaselor(weekendbaseRate, weekendlor3rd)}</span>
                 :item.name ==="weekendlor4th" ?  <span className="red">{additionbaselor(weekendbaseRate, weekendlor4th)}</span>
                 :item.name ==="weekendlor5th" ?  <span className="red">{additionbaselor(weekendbaseRate, weekendlor5th)}</span>
                 : item.name ==="weekendlor6th" ?  <span className="red">{additionbaselor(weekendbaseRate, weekendlor6th)}</span>:""}
                 
               </td>)
               :(<td>{item.label}</td>)
                ))}
 </tr>
 <tr>
              {rateExtraWeekly?.map((item) => (
                item.name!==undefined ?(
                <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />

{item.notify!==undefined ? <span className="red">{item.notify}</span>:""}
                 
                 {item.name ==="weeklylor1st" ?  <span className="red">{additionbaselor(weeklybaseRate, weeklylor1st)}</span> 
                 : item.name ==="weeklylor2nd" ?  <span className="red">{additionbaselor(weeklybaseRate, weeklylor2nd)}</span>
                 : item.name ==="weeklylor3rd" ?  <span className="red">{additionbaselor(weeklybaseRate, weeklylor3rd)}</span>
                 :item.name ==="weeklylor4th" ?  <span className="red">{additionbaselor(weeklybaseRate, weeklylor4th)}</span>:""}
                 
               </td>)
               :(<td>{item.label}</td>)
                ))}
 </tr>
 <tr>
              {rateExtraMonthly?.map((item) => (
                item.name!==undefined ?(
                <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
               </td>)
               :(<td>{item.label}</td>)
                ))}
 </tr>
 <tr>
 <td colSpan={'6'}>Extra miles rate for all rate plans:	</td>
  <td colSpan={'6'}> 
  <EzControlledInputWithFloatingLabel
                        name={'extraMiles'}
                        control={control}
                        //label={item?.label}
                        type={"text"}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        isError={errors['extraMiles']}
                        errorMsg={getFormErrorMessage('extraMiles')}
                      />
  </td>
 </tr>
            </table>
           </Grid>

            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Update" : "Save"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
            {/* {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
} */}
          </form>
        </div>
      </div>
<div>
{rateAdjustmentlist.length > 0 && (
<Box id="printable">
  <Typography variant="h6" mt={"20px"}>Rate Adjustments  </Typography>
<DataTable value={rateAdjustmentlist}>
                <Column field="locationTitle" header="Location"></Column>
                <Column field="categoryTitle" header="Category"></Column>
                <Column field="networkTitle" header="Network"></Column>
                <Column field="startDate" header="Start Date"></Column>
                <Column field="endDate" header="End Date"></Column>
                <Column field="lor" header="LOR"></Column>
                <Column field="adjust" header="Adjust"></Column>
                <Column field="created By" header="Created By"></Column>
            </DataTable>             
              </Box>
)}
</div>

      {showDialog && (
              <EzDialog onHide={onDialogHide} maximizable={false} width={"50vw"} height={"275px"} header={"Increase Rate by %age"}>
                <form onSubmit={handleSubmit2(onRateSubmit)} className="p-fluid">
                <Grid container spacing={2} paddingTop={"20px"}>
               
                {rateAdjustment?.map((item) => (
   <Grid item xs={6} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control2}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors2[item?.name]}
                        errorMsg={getFormErrorMessage2(item?.name)}
                      />
                    </Grid>
))}
                </Grid>
                <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="button"
                        label={ "Cancel"}
                        className="mt-2 p-button-raised"
                        onClick={onDialogHide}
                      />
                    </div>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                         type="submit"
                        label={ "Save"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
                </form>
                </EzDialog>

      )}



{showTestDialog && (
              <EzDialog onHide={onDialogTestHide} maximizable={false} width={"50vw"} height={"275px"} header={"Rate Test"}>
                
                <form onSubmit={handleSubmit3(onRateTestSubmit)} className="p-fluid">
                <Grid container spacing={2} paddingTop={"20px"}>
               
                {rateTest?.map((item) => (
item.type === "date" ? (
  <Grid item xs={6} key={item.name}>
    <EzControlledCalendarWithFloatingLabel
id={item.name}
name={item.name}
{...register(item.name)}
showTime={false}
maxDate={new Date(2090, 0, 1)}
dateFormat="yy/mm/dd" 
control={control3}
isError={errors3[item.name]}
errorMsg={getFormErrorMessage(item.name)}
label={item.label}
rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
/>
  </Grid>
):(
            
               <Grid item xs={6} key={item.name}>
                     <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control3}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={errors3[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>)
))}
                </Grid>
{RateTest?.totalRate!==undefined &&(
                <Grid container spacing={2} paddingTop={"20px"} className="testcont">
                  <Typography className="p">
                  Total Rate -  ${RateTest?.totalRate} <span onClick={()=>setBreakdown(!breakdown)}>View Breakdown</span>
                  </Typography>
                  {breakdown===true &&(
                  <Table className="ratetest">
                    <TableBody>
                    <TableRow>
                    <TableCell>Before Taxes</TableCell>
                    <TableCell>$ {RateTest?.beforeTax}
                      <br/>
                      {RateTest?.beforeTaxDays}
                    </TableCell>
                    </TableRow>
                   
                    <TableRow>
                    <TableCell>Fees</TableCell>
                    <TableCell>${RateTest?.fees}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Underage driver fee</TableCell>
                    <TableCell>${RateTest?.underageDriverFee}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Airport Facility Charge</TableCell>
                    <TableCell>${RateTest?.airportFacilityCharge}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Concession Fee</TableCell>
                    <TableCell>${RateTest?.concessionFee}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>After hour fees</TableCell>
                    <TableCell>${RateTest?.afterHourFees}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Estimated Taxes</TableCell>
                    <TableCell>${RateTest?.estimatedTaxes}</TableCell>
                    </TableRow>
                   
                    <TableRow>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>${RateTest?.totalAmount}</TableCell>
                    </TableRow>
                    </TableBody>
                  </Table>)}
                  <Typography color={"red"} fontSize={"12px"}>
                  Please note that this rate simulation does not check for blackouts, rate mirrors, underage fee and category existing for the location.
                  </Typography>
                </Grid>)}
                <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="button"
                        label={ "Cancel"}
                        className="mt-2 p-button-raised"
                        onClick={onDialogTestHide}
                      />
                    </div>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                         type="submit"
                        label={ "Check Rates"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
                </form>
                </EzDialog>

      )}
    </Container>
  );
};

export default CreateRateAdminRatesComponent;
