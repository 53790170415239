

export const FETCH_RENTERS_REGISTEREDRENTERS_LIST = {
    START: 'FETCH_RENTERS_REGISTEREDRENTERS_LIST_START',
    SUCCESS: 'FETCH_RENTERS_REGISTEREDRENTERS_LIST_SUCCESS',
    ERROR: 'FETCH_RENTERS_REGISTEREDRENTERS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RENTERS_REGISTEREDRENTERS_INIT="FETCH_RENTERS_REGISTEREDRENTERS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RENTERS_REGISTEREDRENTERS_SCREEN =
'INIT_CREATE_RENTERS_REGISTEREDRENTERS_SCREEN';

export const CREATE_RENTERS_REGISTEREDRENTERS = {
START: 'CREATE_RENTERS_REGISTEREDRENTERS_START',
SUCCESS: 'CREATE_RENTERS_REGISTEREDRENTERS_SUCCESS',
ERROR: 'CREATE_RENTERS_REGISTEREDRENTERS_ERROR',
};

export const EDIT_RENTERS_REGISTEREDRENTERS = {
START: 'EDIT_RENTERS_REGISTEREDRENTERS_START',
SUCCESS: 'EDIT_RENTERS_REGISTEREDRENTERS_SUCCESS',
ERROR: 'EDIT_RENTERS_REGISTEREDRENTERS_ERROR',
};

export const FETCH_RENTERS_REGISTEREDRENTERS_DETAIL = {
  START: 'FETCH_RENTERS_REGISTEREDRENTERS_DETAIL_START',
  SUCCESS: 'FETCH_RENTERS_REGISTEREDRENTERS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RENTERS_REGISTEREDRENTERS_DETAIL_ERROR',
};
export const EXPORT_RENTERS_REGISTEREDRENTERS = {
  START: 'EXPORT_RENTERS_REGISTEREDRENTERS_START',
  SUCCESS: 'EXPORT_RENTERS_REGISTEREDRENTERS_SUCCESS',
  ERROR: 'EXPORT_RENTERS_REGISTEREDRENTERS_ERROR',
};
export const RENTERS_REGISTEREDRENTERSDD = {
  START: 'RENTERS_REGISTEREDRENTERSDD_START',
  SUCCESS: 'RENTERS_REGISTEREDRENTERSDD_SUCCESS',
  ERROR: 'RENTERS_REGISTEREDRENTERSDD_ERROR',
};
export const RENTERS_REGISTEREDRENTERSDELETE = {
  START: 'RENTERS_REGISTEREDRENTERSDELETE_START',
  SUCCESS: 'RENTERS_REGISTEREDRENTERSDELETE_SUCCESS',
  ERROR: 'RENTERS_REGISTEREDRENTERSDELETE_ERROR',
};


export const FETCH_RENTERS_REGISTEREDRENTERS_VIEW_BY_LIST = {
  START: 'FETCH_RENTERS_REGISTEREDRENTERS_VIEW_BY_LIST_START',
  SUCCESS: 'FETCH_RENTERS_REGISTEREDRENTERS_VIEW_BY_LIST_SUCCESS',
  ERROR: 'FETCH_RENTERS_REGISTEREDRENTERS_VIEW_BY_LIST_ERROR',
};