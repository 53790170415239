import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { SettingsSettingWeekendsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_SETTINGS_SETTINGWEEKENDS_INIT, 
  FETCH_SETTINGS_SETTINGWEEKENDS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_SETTINGS_SETTINGWEEKENDS_SCREEN,
  CREATE_SETTINGS_SETTINGWEEKENDS,
  EDIT_SETTINGS_SETTINGWEEKENDS,
  TOAST,
  FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL,
  EXPORT_SETTINGS_SETTINGWEEKENDS,
  SETTINGS_SETTINGWEEKENDSDD,
  SETTINGS_SETTINGWEEKENDSDELETE
} from "../../../types/Settings/SettingWeekends.type";
export const initializeSettingsSettingWeekendsScreen = () => ({ type: FETCH_SETTINGS_SETTINGWEEKENDS_INIT });

export const SettingsSettingWeekendsFetchStart = (payload) => ({
  type: FETCH_SETTINGS_SETTINGWEEKENDS_LIST.START,
  payload,
});

export const SettingsSettingWeekendsFetchSuccess = (payload) => ({
  type:FETCH_SETTINGS_SETTINGWEEKENDS_LIST.SUCCESS,
  payload,
});

export const SettingsSettingWeekendsFetchError = (payload) => ({
  type: FETCH_SETTINGS_SETTINGWEEKENDS_LIST.ERROR,
  payload,
});

export const fetchSettingsSettingWeekends = (payload) => {
  return (dispatch) => {
    dispatch(SettingsSettingWeekendsFetchStart(payload));
    SettingsSettingWeekendsServices.fetchSettingsSettingWeekends(payload).then((data) => {
      if (!data.isError) {
        dispatch(SettingsSettingWeekendsFetchSuccess(data));
      } else {
        dispatch(SettingsSettingWeekendsFetchError(data));
      }
    });
  };
};



// DD
export const SettingsSettingWeekendsDDFetchStart = (payload) => ({
  type: SETTINGS_SETTINGWEEKENDSDD.START,
  payload,
});

export const SettingsSettingWeekendsDDFetchSuccess = (payload) => ({
  type:SETTINGS_SETTINGWEEKENDSDD.SUCCESS,
  payload,
});

export const SettingsSettingWeekendsDDFetchError = (payload) => ({
  type: SETTINGS_SETTINGWEEKENDSDD.ERROR,
  payload,
});

export const fetchSettingsSettingWeekendsDD = (payload) => {
  return (dispatch) => {
    dispatch(SettingsSettingWeekendsDDFetchStart(payload));
    SettingsSettingWeekendsServices.fetchSettingsSettingWeekendsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(SettingsSettingWeekendsDDFetchSuccess(data));
      } else {
        dispatch(SettingsSettingWeekendsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const SettingsSettingWeekendsDetailFetchStart = (payload) => ({
  type: FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL.START,
  payload,
});

export const SettingsSettingWeekendsDetailFetchSuccess = (payload) => ({
  type: FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL.SUCCESS,
  payload,
});

export const SettingsSettingWeekendsDetailFetchError = (payload) => ({
  type: FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL.ERROR,
  payload,
});

export const fetchSettingsSettingWeekendsById = (payload) => {
  return (dispatch) => {
    dispatch(SettingsSettingWeekendsDetailFetchStart(payload));

    SettingsSettingWeekendsServices
      .fetchSettingsSettingWeekendsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(SettingsSettingWeekendsDetailFetchSuccess(data));
        } else {
          dispatch(SettingsSettingWeekendsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    SettingsSettingWeekendsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createSettingsSettingWeekendsScreenInitStart = (payload) => ({
  type: INIT_CREATE_SETTINGS_SETTINGWEEKENDS_SCREEN,
  payload,
});

export const initCreateSettingsSettingWeekendsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createSettingsSettingWeekendsScreenInitStart());
  };
};


export const createSettingsSettingWeekendsStart = () => ({
  type: CREATE_SETTINGS_SETTINGWEEKENDS.START,
});

export const createSettingsSettingWeekendsSuccess = (payload) => ({
  type: CREATE_SETTINGS_SETTINGWEEKENDS.SUCCESS,
  payload,
});

export const createSettingsSettingWeekendsError = (payload) => ({
  type: CREATE_SETTINGS_SETTINGWEEKENDS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createSettingsSettingWeekends = (payload) => {
  return (dispatch) => {
    dispatch(createSettingsSettingWeekendsStart());
    SettingsSettingWeekendsServices.createSettingsSettingWeekends(payload).then((data) => {
      if (!data.isError) {
        dispatch(createSettingsSettingWeekendsSuccess());
        goBack();
      } else {
        dispatch(createSettingsSettingWeekendsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editSettingsSettingWeekendsStart = (payload) => ({
  type: EDIT_SETTINGS_SETTINGWEEKENDS.START,
  payload
});

export const editSettingsSettingWeekendsSuccess = (payload) => ({
  type: EDIT_SETTINGS_SETTINGWEEKENDS.SUCCESS,
  payload,
});

export const editSettingsSettingWeekendsError = (payload) => ({
  type: EDIT_SETTINGS_SETTINGWEEKENDS.ERROR,
  payload,
});

export const editSettingsSettingWeekends = (payload) => {
  return (dispatch) => {
    dispatch(editSettingsSettingWeekendsStart());
    SettingsSettingWeekendsServices.editSettingsSettingWeekends(payload).then((data) => {
      if (!data.isError) {
        dispatch(editSettingsSettingWeekendsSuccess());
        goBack();
      } else {
        dispatch(editSettingsSettingWeekendsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportSettingsSettingWeekendsRecordStart = (payload) => ({
  type: EXPORT_SETTINGS_SETTINGWEEKENDS.START,
  payload
});

export const exportSettingsSettingWeekendsRecordSuccess = (payload) => ({
  type: EXPORT_SETTINGS_SETTINGWEEKENDS.SUCCESS,
  payload,
});

export const exportSettingsSettingWeekendsRecordError = (payload) => ({
  type: EXPORT_SETTINGS_SETTINGWEEKENDS.ERROR,
  payload,
});

export const exportSettingsSettingWeekends = (payload) => {
  return (dispatch) => {
    dispatch(exportSettingsSettingWeekendsRecordStart());
    SettingsSettingWeekendsServices.exportSettingsSettingWeekendsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportSettingsSettingWeekendsRecordSuccess(data));
      } else {
        dispatch(exportSettingsSettingWeekendsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const SettingsSettingWeekendsDeleteStart = (payload) => ({
  type: SETTINGS_SETTINGWEEKENDSDELETE.START,
  payload,
});

export const SettingsSettingWeekendsDeleteSuccess = (payload) => ({
  type: SETTINGS_SETTINGWEEKENDSDELETE.SUCCESS,
  payload,
});

export const SettingsSettingWeekendsDeleteError = (payload) => ({
  type: SETTINGS_SETTINGWEEKENDSDELETE.ERROR,
  payload,
});

export const deleteSettingsSettingWeekends = (payload) => {
  return (dispatch) => {
    dispatch(SettingsSettingWeekendsDeleteStart(payload));

    SettingsSettingWeekendsServices
      .deleteSettingsSettingWeekends(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(SettingsSettingWeekendsDeleteSuccess(data));
          dispatch(fetchSettingsSettingWeekends(payload?.params));
        } else {
          dispatch(SettingsSettingWeekendsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/