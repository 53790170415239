import React, { useEffect, useMemo, useState } from "react";
import { useForm, useFieldArray} from "react-hook-form";
import { FORM_FIELDS_NAME, YesNoDrop, afterHourFeeStatusDrop, airportChargeStatusDrop, concessionFeeTypeDrop, editMode, hourslist, policyBreakdownlist, statusDrop } from "./constant";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Col, Row } from "react-bootstrap";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import EzControlledCheckBoxWithLabel from "../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { goBack } from "../../../utils/gotoback";
import { loadingShow } from "../../../services/PostAPI";
import Breadcrumb from "../../../common/Breadcrumb";
import { useStyles } from '../style';
import { basicDetail, hoursSetting, freesCharges, locationExtraHoursSetting } from "../CreateLocation/constant"
import { Box, Container, Grid, Step, StepButton, Stepper, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import EzControlledInputAreaWithFloatingLabel from "../../../common/EzForm/EzControlledInputArea/WithFloatingLabel"
import EzControlledEditorFloatingLabel from "../../../common/EzForm/EzControlledEditor/WithFloatingLabel";
import EzControlledCalendarWithFloatingLabel from "../../../common/EzForm/EzControlledCalendar/WithFloatingLabel"
import EzDialog from "../../../common/EzDialog"
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../utils/constant";
const steps = ['Basic Details', 'Hours Settings', 'Fees/Charges', 'Vehicle Categories', 'Policy & Shuttle Hours'];

const CreateLocationComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateLocationScreen,
    createLocation,
    editLocation,
    fetchCategory,
    isLoading,
    isCreateSuccess,
    isCreateError,
    isDetailSuccess,
    isDetailError,
    isEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    categoryListview
  } = props;
  console.log("check props of compoemnt", props);
 

  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });


  const locationName = watch('locationName');
  const address = watch('address');
  const pin = watch('pin');
  const contact = watch('contact');
  const country = watch('country');
  const breadcrumbD = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/location", label: "Location" },
    { path: "#", label: locationName },
  ]
  const anyAirportLocation = watch('anyAirportLocation');
  const status = watch('status');
  const [showDialog, setShowDialog] = useState(false);
  const [vehicleCategory, setVehicleCategory] = React.useState([]);
  // Step code
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(false);
  const [isCatogary, setIsCatogary] = useState(false);
 

  const { fields, append, update } = useFieldArray({
    control,
    name: "locationExtraHourRequests"
  });
  const watchFieldArray = watch("locationExtraHourRequests");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  const handleRemoveClick = (i) => {
    const updatedData = controlledFields.map((item, index) => (
      i === index ? { ...item, status: false } : { ...item }
    ))
    update(i, { status: false, ...updatedData[i] })
  }

//Adding Item
const handleAddClick = () => {
  append({
    id: 0,
    status: true,
    startDate: '',
    endDate: '',
    startHour: '',
    endHour: '',
    afterEndHour: '',
  });
};


  const renderstartHour = (index, id) => {
    const starthours = errors?.locationExtraHourRequests?.[index]?.startHour;
    return (
      <EzControlledDropDowntWithFloatingLabel
      id={id}
      name={`locationExtraHourRequests.${index}.startHour`}
                        control={control}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={starthours}
                        errorMsg={getFormErrorMessage(starthours)}
                        label={"Start Hour"}
                        labelClassName={"ml-2"}
                      />
    );
  };
  const renderendHour = (index, id) => {
    const endHour = errors?.locationExtraHourRequests?.[index]?.endHour;
    return (
      <EzControlledDropDowntWithFloatingLabel
      id={id}
      name={`locationExtraHourRequests.${index}.endHour`}
                        control={control}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={endHour}
                        errorMsg={getFormErrorMessage(endHour)}
                        label={"End Hour"}
                        labelClassName={"ml-2"}
                      />
    );
  };
  
  const renderafterEndHour = (index, id) => {
    const afterEndHour = errors?.locationExtraHourRequests?.[index]?.afterEndHour;
    return (
      <EzControlledDropDowntWithFloatingLabel
      id={id}
      name={`locationExtraHourRequests.${index}.afterEndHour`}
                        control={control}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={afterEndHour}
                        errorMsg={getFormErrorMessage(afterEndHour)}
                        label={"After End Hour"}
                        labelClassName={"ml-2"}
                      />
    );
  };
  const renderstartDate = (index, id) => {
    const DDstartDate = errors?.locationExtraHourRequests?.[index]?.startDate;
    return (
      <EzControlledCalendarWithFloatingLabel
        id={id}
        name={`locationExtraHourRequests.${index}.startDate`}
        {...register(`locationExtraHourRequests.${index}.startDate`)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        control={control}
        isError={DDstartDate}
        errorMsg={getFormErrorMessage(DDstartDate)}
        label={'Start Date'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      />
    );
  };
  const renderendDate = (index, id) => {
    const EEendDate = errors?.locationExtraHourRequests?.[index]?.endDate;
    return (
      <EzControlledCalendarWithFloatingLabel
        id={id}
        name={`locationExtraHourRequests.${index}.endDate`}
        {...register(`locationExtraHourRequests.${index}.endDate`)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        control={control}
        isError={EEendDate}
        errorMsg={getFormErrorMessage(EEendDate)}
        label={'End Date'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      />
    );
  };
  const navigateToLocationScreen = () => {
    //navigate(APP_URLS.LOCATION.HOME);
    window.location.replace("/#"+APP_URLS.LOCATION.HOME)
  };
  const onDialogHide = () => {
    setShowDialog(false);
  }
  const policyBrakdownModal = () => {
    setShowDialog(true);
  }
  const totalSteps = () => {
    return steps.length - 1;
  };
  const markChecked = (arr1, arr2)=> {
    return arr1.map(item1 => {
        let matched = arr2.some(item2 => item2 === item1.id);
        if (matched) {
            return { ...item1, checked: true, value:true };
        }
        return item1;
    });
}
useEffect(() => {
  setTimeout(() =>     setIsCatogary(false)
  , 1000);
}, [isCatogary]);
  useEffect(() => {
    let newData = categoryListview !== undefined && categoryListview.map(item => ({
      name: "category_" + item.id,
      value:false,
      id: item.id,
      title: item.title
    }))
    setVehicleCategory(newData);
  }, [categoryListview]);

  useEffect(() => {
    if (isDetailSuccess) {
      const {categoryId} =formFieldValueMap;
      let newData = markChecked(vehicleCategory, categoryId);
      setVehicleCategory(newData)
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isDetailSuccess, categoryListview]);
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setCompleted(false)
    setVehicleCategory(prevItems =>
      prevItems.map(item =>
        item.name === name ? { ...item, checked: checked, value:checked } : item
      )
    );
  }
  const handleNext = () => {
    const length = Object.keys(errors).length;
    const checkedObjects = vehicleCategory.filter(obj => obj.checked);
    const objlen = checkedObjects.length;
    const newActiveStep = activeStep + 1;
    if (activeStep === 0) {
      if (locationName === "" || locationName === undefined
        || address === "" || address === undefined
        || pin === "" || pin === undefined
        || status === "" || status === undefined
        || contact === "" || contact === undefined
        || country === "" || country === undefined
        || anyAirportLocation === "" || anyAirportLocation === undefined
      ) {
        return
      }
    }
    else if (activeStep === 1 && length > 0) {
      return
    }
    else if (activeStep === 2 && length > 0) {
      return
    }
    else if (activeStep === 3 && objlen <= 0) {    
      return setIsCatogary(true)
    }
    else if (activeStep === 4 && length > 0) {
      return
    }
    else if (totalSteps() === newActiveStep) {
      setCompleted(true)
    }
   
    setActiveStep(newActiveStep);
    
  };

  const handleBack = () => {
    if ((activeStep - 1) != totalSteps()) {
      setCompleted(false)
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    if (isEdit === true) {
      if (step != totalSteps()) {
        setCompleted(false)
      }
      else {
        setCompleted(true)
      }
      setActiveStep(step);
    }
  };

  const getToastProps = () => {
      const toastTitle = "Please select category from list";
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: isCatogary,
      };
  };



  //End Step code

  const removeKeysWithWord = (obj, word) => {
    let result = {};
    for (let key in obj) {
      if (!key.toLowerCase().includes(word.toLowerCase())) {
        result[key] = obj[key];
      }
    }
    return result;
  }
  const onSubmit = (data) => {
    let locationExtraHourRequests = data?.locationExtraHourRequests;
    locationExtraHourRequests = locationExtraHourRequests.filter(item => item.status !== false);
    let newData = removeKeysWithWord(data, "category_");
    let filterData = vehicleCategory.filter(item => item.checked === true);
    let catagoryList = [];
    filterData = filterData.map(item => (
      catagoryList.push(item.id)
    ))
    newData["userId"] = localStorage.getItem("userId");
    const stringifiedData = {
      ...newData,
      categoryId: catagoryList,
      locationExtraHourRequests:locationExtraHourRequests
    }
    console.log(stringifiedData, "stringifiedData")
    if (activeStep === 4) {
      isEdit
        ? editLocation({ id: data.id, data: stringifiedData })
        : createLocation(stringifiedData);
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div className="ml-3">
            <h4>Create Location</h4>
          </div>
          <Button
            tooltip={"Back to Location"}
            tooltipOptions={{ position: "left" }}
            label={"Back to Location"}
            // icon={"pi pi-arrow-left"}
            className={`p-button `}
            style={{ borderRadius: "10px" }}
            onClick={navigateToLocationScreen}
          />
        </div>
      </div>
    );
  };
 
  return (
    <Container maxWidth="lg">
      <Breadcrumb list={breadcrumbD} />
      <EzToast {...getToastProps()} /> 
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <Stepper className={classes.mainstep} nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton active="ac" color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Grid container spacing={2}>
                {basicDetail?.map((item) => (
                  item.name === "anyAirportLocation" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={YesNoDrop}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) : item.name === "status" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={statusDrop}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :

                   item.name === "weekHourStart" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :
                   item.name === "weekHourEnd" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :

                   item.name === "weekEndHourStart" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :

                   item.name === "weekEndHourEnd" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={hourslist}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :



                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>)
                ))}
              </Grid>
            )}

            {activeStep === 1 && (
              <Grid container>
                <Grid container xs={6} spacing={2} mr={"20px"}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Monday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index < 3 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container xs={6} spacing={2}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Tuesday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index > 2 && index < 6 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container xs={6} spacing={2} mr={"20px"}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Wednesday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index > 5 && index < 9 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container xs={6} spacing={2}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Thursday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index > 8 && index < 12 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container xs={6} spacing={2} mr={"20px"}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Friday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index > 11 && index < 15 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container xs={6} spacing={2}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Saturday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index > 14 && index < 18 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container xs={6} spacing={2}>
                  <Typography width={"100%"} ml={"20px"} mb={"25px"} fontWeight={"bold"}>Sunday</Typography>
                  {hoursSetting?.map((item, index) => (
                    index > 17 && (
                      <>
                        <Grid className={classes.pt0} item xs={4} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={hourslist}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      </>)
                  ))}
                </Grid>
                <Grid container spacing={2}  padding={"0px 25px"}>
                <div className="addition">
  <Typography>Extra Hour Settings</Typography>
  <div style={{ margin: '0px', maxWidth: '70px' }} className="addbtn">
<Button
                      icon={'pi pi-plus'}
                      type="button"
                      onClick={handleAddClick}
                     
                      className="mt-2 p-button-raised"
                    />
                    </div>
                    </div>
  <Grid container marginTop={"30px"}>
  {controlledFields.map((field, index) => {
                  return (
                    field.status !== false ?
<Grid container spacing={2} key={`item${index}`} marginTop={"20px"}>
<Grid className={classes.pt0} item xs={2}>
{renderstartDate(index, field.id)}
</Grid>
<Grid className={classes.pt0} item xs={2}>
{renderendDate(index, field.id)}
</Grid>
<Grid className={classes.pt0} item xs={2}>
{renderstartHour(index, field.id)}
</Grid>
<Grid className={classes.pt0} item xs={2}>
{renderendHour(index, field.id)}
</Grid>
<Grid className={classes.pt0} item xs={2}>
{renderafterEndHour(index, field.id)}
</Grid>
<Grid className={classes.pt0} item xs={2} display={"flex"}>

                    <div style={{ margin: '0px 0px 0px 10px', maxWidth: '70px' }}>
                    <Button
                      icon={'pi pi-minus'}
                      type="button"
                      onClick={()=>handleRemoveClick(index)}
                     
                      className="mt-2 p-button-raised"
                    /></div>
                    </Grid>
</Grid>
:null)
})}

</Grid>
</Grid>

              </Grid>
            )}

            {activeStep === 2 && (
              <Grid container spacing={2}>
                {freesCharges?.map((item) => (
                  item.name === "afterHourFeeStatus" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={afterHourFeeStatusDrop}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) : item.name === "concessionFeeType" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={concessionFeeTypeDrop}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                    : item.name === "airportChargeStatus" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={airportChargeStatusDrop}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )
                      : (
                        <Grid item xs={3} key={item.name}>
                          <EzControlledInputWithFloatingLabel
                            name={item?.name}
                            control={control}
                            label={item?.label}
                            rules={item?.rule}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                          />
                        </Grid>
                      )))}
              </Grid>
            )}
            {activeStep === 3 && (
              <Grid container spacing={2}>
                {vehicleCategory.length > 0 && vehicleCategory?.map((item) => (
                  <Grid item xs={3} key={item.id}>
                    <EzControlledCheckBoxWithLabel
                      name={item.name}
                      value={item.value}
                      id={item.name}
                      control={control}
                      isError={errors[item.title]}
                      label={item.title}
                      labelClassName={'ml-2'}
                      onChange={handleCheckbox}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {activeStep === 4 && (
              <Grid container spacing={2}>
                <Grid item xs={8} >

                  {/* <Typography>Policy</Typography> */}
                  <EzControlledEditorFloatingLabel
                    name={"locationPolicy"}
                    control={control}
                    label={"Policy"}
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors["locationPolicy"]}
                    errorMsg={getFormErrorMessage("locationPolicy")}
                  />
                  <div style={{ margin: "15px 0px", maxWidth: "180px" }}>
                    <Button onClick={policyBrakdownModal} type="button" width={"100px"}>Policy Breakdown</Button>
                  </div>
                </Grid>

                <Grid item xs={4} >
                  <EzControlledInputAreaWithFloatingLabel
                    type="textarea"
                    name={'shuttleHours'}
                    control={control}
                    label={'Shuttle Hours'}
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['shuttleHours']}
                    errorMsg={getFormErrorMessage('shuttleHours')}
                  />
                </Grid>
              </Grid>
            )}
            {showDialog && (
              <EzDialog onHide={onDialogHide} maximizable={false} width={"70vw"} header={"Policy Breakdown"}>
                <Grid container spacing={2} paddingTop={"20px"}>

                  {policyBreakdownlist?.map((item) => (
                    <Grid item xs={4} >
                      <EzControlledInputAreaWithFloatingLabel
                        type="textarea"
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>
                  ))}
                </Grid>

              </EzDialog>)}
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                  <Button
                    color="inherit"
                    className="p-btn-danger p-button-outlined mt-2"
                    disabled={activeStep === 0}
                    onClick={handleBack}

                  >
                    Back
                  </Button>
                </div>

                {completed ? (
                  <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Update" : "Save"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
                ) : (
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button onClick={handleNext} >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            </div>

            {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateLocationComponent;
