import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminNetworksScreen,
  createRateAdminNetworks,
  editRateAdminNetworks,
  fetchRateAdminNetworksById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminNetworks";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminNetworksComponent from "../../../../component/RateAdmin/RateAdminNetworks/CreateRateAdminNetworks";
import { useParams } from "react-router-dom";

const CreateRateAdminNetworksContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminNetworks,
    editRateAdminNetworks,
    isLoading,
    isRateAdminNetworksCreateSuccess,
    isRateAdminNetworksCreateError,
    isRateAdminNetworksDetailSuccess,
    isRateAdminNetworksDetailError,
    isRateAdminNetworksEditSuccess,
    isRateAdminNetworksEditError,
    isEdit,
    isView,
    initCreateRateAdminNetworksScreen,
    formFieldValueMap,
    fetchRateAdminNetworksById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
        fetchVehicleCategoryDD,
        fetchLocationDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    await fetchVehicleCategoryDD();
    await fetchLocationDD();
    initCreateRateAdminNetworksScreen();
   if (isEdit) {
    await fetchRateAdminNetworksById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminNetworksProps = {
    createRateAdminNetworks,
    initCreateRateAdminNetworksScreen,
    editRateAdminNetworks,
    isLoading,
    isEdit,
    isRateAdminNetworksDetailSuccess,
    isRateAdminNetworksCreateSuccess,
    isRateAdminNetworksEditSuccess,
    isRateAdminNetworksEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
  };
  const getToastProps = () => {
    if (isRateAdminNetworksCreateSuccess || isRateAdminNetworksEditSuccess) {
      const toastTitle = isEdit
        ? "Networks Updated Successfully"
        : "Networks Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminNetworksCreateError ||
      isRateAdminNetworksDetailError ||
      isRateAdminNetworksEditError
    ) {
      let toastTitle = "Error while Creating Rate Plan";
      if (isRateAdminNetworksEditError) {
        toastTitle = "Error while updating Rate Plan";
      } else if (isRateAdminNetworksDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminNetworksComponent {...createRateAdminNetworksProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminNetworksReducer(state, "isLoading"),
   
    isRateAdminNetworksCreateSuccess: selectFromRateAdminNetworksReducer(
      state,
      "isRateAdminNetworksCreateSuccess"
    ),
    isRateAdminNetworksCreateError: selectFromRateAdminNetworksReducer(
      state,
      "isRateAdminNetworksCreateError"
    ),
    isRateAdminNetworksDetailSuccess: selectFromRateAdminNetworksReducer(
      state,
      "isRateAdminNetworksDetailSuccess"
    ),
    isRateAdminNetworksDetailError: selectFromRateAdminNetworksReducer(
      state,
      "isRateAdminNetworksDetailError"
    ),
    isRateAdminNetworksEditSuccess: selectFromRateAdminNetworksReducer(
      state,
      "isRateAdminNetworksEditSuccess"
    ),
    isRateAdminNetworksEditError: selectFromRateAdminNetworksReducer(
      state,
      "isRateAdminNetworksEditError"
    ),
    imageUploadResponse: selectFromRateAdminNetworksReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminNetworksReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminNetworksReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminNetworksReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminNetworksReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminNetworksReducer(
      state,
      "isVehicleModelDDError"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    formFieldValueMap: selectFromRateAdminNetworksReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminNetworksScreen: () => dispatch(initCreateRateAdminNetworksScreen()),
    createRateAdminNetworks: (payload) => dispatch(createRateAdminNetworks(payload)),
    editRateAdminNetworks: (payload) => dispatch(editRateAdminNetworks(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminNetworksById: (payload) =>
      dispatch(fetchRateAdminNetworksById(payload)),

  };
};

const selectFromRateAdminNetworksReducer = (state, path) => {
  return selectRateAdminNetworksReducer(state)[path];
};

const selectRateAdminNetworksReducer = ({ rateadminNetworksReducer }) => {
  return rateadminNetworksReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminNetworksContainer);
