

export const FETCH_RENTERS_RENTERSINFO_LIST = {
    START: 'FETCH_RENTERS_RENTERSINFO_LIST_START',
    SUCCESS: 'FETCH_RENTERS_RENTERSINFO_LIST_SUCCESS',
    ERROR: 'FETCH_RENTERS_RENTERSINFO_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RENTERS_RENTERSINFO_INIT="FETCH_RENTERS_RENTERSINFO_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RENTERS_RENTERSINFO_SCREEN =
'INIT_CREATE_RENTERS_RENTERSINFO_SCREEN';

export const CREATE_RENTERS_RENTERSINFO = {
START: 'CREATE_RENTERS_RENTERSINFO_START',
SUCCESS: 'CREATE_RENTERS_RENTERSINFO_SUCCESS',
ERROR: 'CREATE_RENTERS_RENTERSINFO_ERROR',
};

export const EDIT_RENTERS_RENTERSINFO = {
START: 'EDIT_RENTERS_RENTERSINFO_START',
SUCCESS: 'EDIT_RENTERS_RENTERSINFO_SUCCESS',
ERROR: 'EDIT_RENTERS_RENTERSINFO_ERROR',
};

export const FETCH_RENTERS_RENTERSINFO_DETAIL = {
  START: 'FETCH_RENTERS_RENTERSINFO_DETAIL_START',
  SUCCESS: 'FETCH_RENTERS_RENTERSINFO_DETAIL_SUCCESS',
  ERROR: 'FETCH_RENTERS_RENTERSINFO_DETAIL_ERROR',
};
export const EXPORT_RENTERS_RENTERSINFO = {
  START: 'EXPORT_RENTERS_RENTERSINFO_START',
  SUCCESS: 'EXPORT_RENTERS_RENTERSINFO_SUCCESS',
  ERROR: 'EXPORT_RENTERS_RENTERSINFO_ERROR',
};
export const RENTERS_RENTERSINFODD = {
  START: 'RENTERS_RENTERSINFODD_START',
  SUCCESS: 'RENTERS_RENTERSINFODD_SUCCESS',
  ERROR: 'RENTERS_RENTERSINFODD_ERROR',
};
export const RENTERS_RENTERSINFODELETE = {
  START: 'RENTERS_RENTERSINFODELETE_START',
  SUCCESS: 'RENTERS_RENTERSINFODELETE_SUCCESS',
  ERROR: 'RENTERS_RENTERSINFODELETE_ERROR',
};

export const EXPORTPDF_RENTERS_RENTERSINFO = {
  START: 'EXPORTPDF_RENTERS_RENTERSINFO_START',
  SUCCESS: 'EXPORTPDF_RENTERS_RENTERSINFO_SUCCESS',
  ERROR: 'EXPORTPDF_RENTERS_RENTERSINFO_ERROR',
};
export const EXPORTCSV_RENTERS_RENTERSINFO = {
  START: 'EXPORTCSV_RENTERS_RENTERSINFO_START',
  SUCCESS: 'EXPORTCSV_RENTERS_RENTERSINFO_SUCCESS',
  ERROR: 'EXPORTCSV_RENTERS_RENTERSINFO_ERROR',
};