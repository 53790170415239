import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATECODE_LIST, 
  TOAST, 
  FETCH_RATEADMIN_RATECODE_INIT,
  FETCH_RATEADMIN_RATECODE_DETAIL,
  EXPORT_RATEADMIN_RATECODE,
  CREATE_RATEADMIN_RATECODE,
  EDIT_RATEADMIN_RATECODE,
  INIT_CREATE_RATEADMIN_RATECODE_SCREEN,
  RATEADMIN_RATECODEDD
} from "../../../types/rateadminratecode.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    rateAdminRateCodeListview: [],
    RateCodeDD:[],
    isRateAdminRateCodeDDSuccess: false,
    isRateAdminRateCodeDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRateAdminRateCodeCreateSuccess: false,
    isRateAdminRateCodeCreateError: false,
    isRateAdminRateCodeDetailSuccess: false,
    isRateAdminRateCodeDetailError: false,
    isRateAdminRateCodeEditSuccess: false,
    isRateAdminRateCodeEditError: false,
    isRateAdminRateCodeExportSuccess: false,
    isRateAdminRateCodeExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminRateCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_RATECODE_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_RATECODE_DETAIL.START:
      case EXPORT_RATEADMIN_RATECODE.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_RATECODE_LIST.START:
      case CREATE_RATEADMIN_RATECODE.START:
        case EDIT_RATEADMIN_RATECODE.START:
          case RATEADMIN_RATECODEDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_RATECODE_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          rateAdminRateCodeListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminRateCodeCreateSuccess: false,
          isRateAdminRateCodeCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          rateAdminRateCodeListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_RATECODE_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminRateCodeListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminRateCodeDeletionSuccess: false,
          israteadminRateCodeDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_RATECODE_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRateAdminRateCodeDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_RATECODE_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateCodeDetailSuccess: false,
        isRateAdminRateCodeDetailError: true,
      };
    }

    //DD
    case RATEADMIN_RATECODEDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RateCodeDD:temp,
        isRateAdminRateCodeDDSuccess: true,
        isRateAdminRateCodeDDError: false,
      };
    }
    case RATEADMIN_RATECODEDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateCodeDDSuccess: false,
        isRateAdminRateCodeDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RATEADMIN_RATECODE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminRateCodeExportSuccess: false,
    isRateAdminRateCodeExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_RATECODE.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"rate-code.xls");
   return {
    ...state,
    isRateAdminRateCodeExportSuccess: true,
    isRateAdminRateCodeExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_RATECODE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateCodeEditSuccess: false,
    isRateAdminRateCodeEditError: true,
  };
}

case EDIT_RATEADMIN_RATECODE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateCodeEditSuccess: true,
    isRateAdminRateCodeEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_RATECODE_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_RATECODE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateCodeCreateSuccess: false,
    isRateAdminRateCodeCreateError: true,
  };
}
case CREATE_RATEADMIN_RATECODE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateCodeCreateSuccess: true,
    isRateAdminRateCodeCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminRateCodeCreateSuccess: false,
          isRateAdminRateCodeCreateError: false,
          isRateAdminRateCodeEditError: false,
          isRateAdminRateCodeEditSuccess: false,
          isRateAdminRateCodeDetailError: false
        };
      }
      default:
        return state;
    }
  };
  