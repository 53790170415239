import React, { useEffect, useRef } from "react";
import EzTable from "../../common/EzTable";
import { EzToast, TOAST_SEVERITY } from "../../common/EzToast";
import EzNotFound from "../../common/EzNotFound";
import { Box, Container } from "@mui/material";
import Breadcrumb from "../../common/Breadcrumb";
import { Button } from "primereact/button";
import { useStyles } from './style';
import { APP_URLS } from "../../utils/constant";
const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const Location = (props) => {
  const classes = useStyles()
  const {
    fetchLocations,
    tableData,
    setDeleteConfirmationPopupVisibility,
    exportLocation,
    isLocationExportError,
    isLocationExportSuccess,
    isPageLevelError,
    paginationInfo,
    isLoading,
    screenPermissions,
    handleCreateLocation,
    editLocationRoute
  } = props;
  const breadcrumbD = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/location", label: "Location" },
  ]


  const convertToTSV = (data) => {
    const headers = data.columns.map(item => item.colLabel).join('\t');
    //const headers = Object.keys(data.rows[0]).join('\t');
    const rows = data.rows.map(row => Object.values(row).join('\t')).join('\n');
    return `${headers}\n${rows}`;
  };

  const copyToClipboard = () => {
    const csv = convertToTSV(tableData);
    navigator.clipboard.writeText(csv)
      .then(() => alert('Data copied to clipboard!'))
      .catch(err => alert('Failed to copy data to clipboard: ', err));
  };
  const tableRef = useRef();
  const handlePrint = () => {
    const printContents = tableRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative">
        <div className="" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <div className="ml-3">
              <h4>Location</h4>
            </div>
            {/* <Button
             label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
             icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
             className={`p-button-text p-button-secondary`}
             onClick={() => {
               toggleFilterState(!areFiltersVisible);
             }}
           /> */}
          </div>
          <div className={classes.buttonmargin}>
            <Button
              label={"Copy"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={copyToClipboard}
            />
            <Button
              label={"Excel"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={exportLocation}
            />
            <Button
              label={"Print"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={handlePrint}
            />
            <Button
              label={"Create Location"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={handleCreateLocation}
            />
          </div>
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderComplianceRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderComplianceRecords() && !shouldRenderFullPageError();
  const filters = {
    global: { value: null },
  };


  const tableProps = {
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Location found.",
    filters,
    loadLazyData: fetchLocations,
    loading: isLoading,
    showMoreActions: false,
    sortField: null,
    setDeleteConfirmationPopupVisibility,
    onEditRecord: editLocationRoute
  };
  return (
    <>
      <Container maxWidth="lg">
        <Breadcrumb list={breadcrumbD} />

        <div className="containerbox" className={classes.bg}>
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderComplianceRecords() && (
              <Box ref={tableRef} id="printable">
                <EzTable {...tableProps} />
              </Box>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No Location records to show"}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
export default Location;

