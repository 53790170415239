import React, { useEffect, useMemo, useState } from "react";
import { useForm} from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { SettingsSettingWeekendsBreadcrumb, basicDetail, editMode, statusDD, weekDays} from "../CreateSettingsSettingWeekends/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { formatDatesHyphan } from "../../../../utils";

const CreateSettingsSettingWeekendsComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateSettingsSettingWeekendsScreen,
    createSettingsSettingWeekends,
    editSettingsSettingWeekends,
    isLoading,
    isSettingsSettingWeekendsCreateSuccess,
    isSettingsSettingWeekendsCreateError,
    isSettingsSettingWeekendsDetailSuccess,
    isSettingsSettingWeekendsDetailError,
    isSettingsSettingWeekendsEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  } = props;
  console.log("check props of compoemnt", props);
 

  const classes = useStyles()
 
  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const [weekDaysData, setWeekDaysData]=useState(weekDays)
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const updatedWeekDaysData = weekDaysData.map(item => ({
      ...item,
      isChecked: item.name === name ? checked : item.isChecked,
    }));
    setWeekDaysData(updatedWeekDaysData);
  };
  

   const navigateToSettingsSettingWeekendsScreen = () => {
    window.location.replace("/#"+APP_URLS.SETTINGS_SETTINGWEEKENDS.HOME)
  };
  useEffect(() => {
    
    if (isSettingsSettingWeekendsCreateSuccess || isSettingsSettingWeekendsEditSuccess) {
      reset();
    }
  }, [isSettingsSettingWeekendsCreateSuccess]);
  useEffect(() => {
    if (isSettingsSettingWeekendsDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
      let weekendDaysString = formFieldValueMap?.weekendDays;
      const weekendDaysArray = weekendDaysString.split(',').map(Number);
      const updatedWeekDaysData = weekDaysData.map(item => ({
        ...item,
        isChecked: weekendDaysArray.includes(item.value) ? true : item.isChecked,
      }));
      setWeekDaysData(updatedWeekDaysData);
    }
  }, [isSettingsSettingWeekendsDetailSuccess]);
  useEffect(() => {
    initCreateSettingsSettingWeekendsScreen();
  }, []);
  
  const onSubmit = (data) => {

    const nData = weekDaysData.filter(item=> item.isChecked===true);
    let wday=[];
    nData.map(item=>(
      wday.push(item.value)
    ));
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    
    
    const stringifiedData = {
      id:data.id,
      endDate:formatDatesHyphan(data.endDate),
    startDate:formatDatesHyphan(data.startDate),
    modBy:data.modBy,
    modTime:data.modTime,
    status:data.status,
    weekendDays:wday.toString(),
    userId:localStorage.getItem("userId")
    }

      isEdit
        ? editSettingsSettingWeekends({ id: data.id, data: stringifiedData })
        : createSettingsSettingWeekends(stringifiedData);
    
  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
};

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Setting Weekends</h4>
          </div>
          <div className="buttonarea">
          <Button
            tooltip={"Back to Setting Weekends"}
            tooltipOptions={{ position: "left" }}
            label={"Back to Setting Weekends"}
            // icon={"pi pi-arrow-left"}
            className={`p-button `}
            style={{ borderRadius: "10px" }}
            onClick={navigateToSettingsSettingWeekendsScreen}
          />
        </div>
        </div>
      </div>
    );
  };
 
  return (
    <Container maxWidth="lg">
      <Breadcrumb list={SettingsSettingWeekendsBreadcrumb} />
      <EzToast {...getToastProps()} /> 
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
         
              <Grid container spacing={2}>
                {basicDetail?.map((item) => (
                  item.name === "status" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={statusDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ):

                  item.type === "date" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledCalendarWithFloatingLabel
        id={item.name}
        name={item.name}
        {...register(item.name)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        dateFormat="yy/mm/dd" 
        control={control}
        isError={errors[item.name]}
        errorMsg={getFormErrorMessage(item.name)}
        label={item.label}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
                    </Grid>
                  )
                  :
                  item.name === "modelId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={vehicleModelDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                  
                  
                  
                  :(
                  (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
                ))}

              </Grid>
              <Grid container spacing={2}>
           {weekDaysData?.map((item) => (
                 
                    <Grid item key={item.name}>
                      <EzControlledCheckBoxWithLabel
                      name={item.name}
                      value={item.isChecked}
                      id={item.name}
                      control={control}
                      isError={errors[item.label]}
                      label={item.label}
                      labelClassName={'ml-2'}
                      onChange={handleCheckbox}
                    />
                     
                    </Grid>
                  ))}
           </Grid>

            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Update" : "Save"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
            {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateSettingsSettingWeekendsComponent;
