import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { RentersRevenueAgreementsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_REVENUEAGREEMENTS_INIT, 
  FETCH_RENTERS_REVENUEAGREEMENTS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RENTERS_REVENUEAGREEMENTS_SCREEN,
  CREATE_RENTERS_REVENUEAGREEMENTS,
  EDIT_RENTERS_REVENUEAGREEMENTS,
  TOAST,
  FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL,
  EXPORT_RENTERS_REVENUEAGREEMENTS,
  RENTERS_REVENUEAGREEMENTSDD,
  RENTERS_REVENUEAGREEMENTSDELETE,
  RENTERS_REVENUEAGREEMENTSEXISTINGDATA
} from "../../../types/Renters/RevenueAgreements.type";
export const initializeRentersRevenueAgreementsScreen = () => ({ type: FETCH_RENTERS_REVENUEAGREEMENTS_INIT });

export const RentersRevenueAgreementsFetchStart = (payload) => ({
  type: FETCH_RENTERS_REVENUEAGREEMENTS_LIST.START,
  payload,
});

export const RentersRevenueAgreementsFetchSuccess = (payload) => ({
  type:FETCH_RENTERS_REVENUEAGREEMENTS_LIST.SUCCESS,
  payload,
});

export const RentersRevenueAgreementsFetchError = (payload) => ({
  type: FETCH_RENTERS_REVENUEAGREEMENTS_LIST.ERROR,
  payload,
});

export const fetchRentersRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(RentersRevenueAgreementsFetchStart(payload));
    RentersRevenueAgreementsServices.fetchRentersRevenueAgreements(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRevenueAgreementsFetchSuccess(data));
      } else {
        dispatch(RentersRevenueAgreementsFetchError(data));
      }
    });
  };
};



// DD
export const RentersRevenueAgreementsDDFetchStart = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSDD.START,
  payload,
});

export const RentersRevenueAgreementsDDFetchSuccess = (payload) => ({
  type:RENTERS_REVENUEAGREEMENTSDD.SUCCESS,
  payload,
});

export const RentersRevenueAgreementsDDFetchError = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSDD.ERROR,
  payload,
});

export const fetchRentersRevenueAgreementsDD = (payload) => {
  return (dispatch) => {
    dispatch(RentersRevenueAgreementsDDFetchStart(payload));
    RentersRevenueAgreementsServices.fetchRentersRevenueAgreementsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRevenueAgreementsDDFetchSuccess(data));
      } else {
        dispatch(RentersRevenueAgreementsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const RentersRevenueAgreementsDetailFetchStart = (payload) => ({
  type: FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL.START,
  payload,
});

export const RentersRevenueAgreementsDetailFetchSuccess = (payload) => ({
  type: FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL.SUCCESS,
  payload,
});

export const RentersRevenueAgreementsDetailFetchError = (payload) => ({
  type: FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL.ERROR,
  payload,
});

export const fetchRentersRevenueAgreementsById = (payload) => {
  return (dispatch) => {
    dispatch(RentersRevenueAgreementsDetailFetchStart(payload));

    RentersRevenueAgreementsServices
      .fetchRentersRevenueAgreementsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersRevenueAgreementsDetailFetchSuccess(data));
        } else {
          dispatch(RentersRevenueAgreementsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    RentersRevenueAgreementsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRentersRevenueAgreementsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RENTERS_REVENUEAGREEMENTS_SCREEN,
  payload,
});

export const initCreateRentersRevenueAgreementsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRentersRevenueAgreementsScreenInitStart());
  };
};


export const createRentersRevenueAgreementsStart = () => ({
  type: CREATE_RENTERS_REVENUEAGREEMENTS.START,
});

export const createRentersRevenueAgreementsSuccess = (payload) => ({
  type: CREATE_RENTERS_REVENUEAGREEMENTS.SUCCESS,
  payload,
});

export const createRentersRevenueAgreementsError = (payload) => ({
  type: CREATE_RENTERS_REVENUEAGREEMENTS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRentersRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(createRentersRevenueAgreementsStart());
    RentersRevenueAgreementsServices.createRentersRevenueAgreements(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRentersRevenueAgreementsSuccess());
        goBack();
      } else {
        dispatch(createRentersRevenueAgreementsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRentersRevenueAgreementsStart = (payload) => ({
  type: EDIT_RENTERS_REVENUEAGREEMENTS.START,
  payload
});

export const editRentersRevenueAgreementsSuccess = (payload) => ({
  type: EDIT_RENTERS_REVENUEAGREEMENTS.SUCCESS,
  payload,
});

export const editRentersRevenueAgreementsError = (payload) => ({
  type: EDIT_RENTERS_REVENUEAGREEMENTS.ERROR,
  payload,
});

export const editRentersRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(editRentersRevenueAgreementsStart());
    RentersRevenueAgreementsServices.editRentersRevenueAgreements(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRentersRevenueAgreementsSuccess());
        goBack();
      } else {
        dispatch(editRentersRevenueAgreementsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRentersRevenueAgreementsRecordStart = (payload) => ({
  type: EXPORT_RENTERS_REVENUEAGREEMENTS.START,
  payload
});

export const exportRentersRevenueAgreementsRecordSuccess = (payload) => ({
  type: EXPORT_RENTERS_REVENUEAGREEMENTS.SUCCESS,
  payload,
});

export const exportRentersRevenueAgreementsRecordError = (payload) => ({
  type: EXPORT_RENTERS_REVENUEAGREEMENTS.ERROR,
  payload,
});

export const exportRentersRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(exportRentersRevenueAgreementsRecordStart());
    RentersRevenueAgreementsServices.exportRentersRevenueAgreementsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRentersRevenueAgreementsRecordSuccess(data));
      } else {
        dispatch(exportRentersRevenueAgreementsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const RentersRevenueAgreementsDeleteStart = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSDELETE.START,
  payload,
});

export const RentersRevenueAgreementsDeleteSuccess = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSDELETE.SUCCESS,
  payload,
});

export const RentersRevenueAgreementsDeleteError = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSDELETE.ERROR,
  payload,
});

export const deleteRentersRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(RentersRevenueAgreementsDeleteStart(payload));

    RentersRevenueAgreementsServices
      .deleteRentersRevenueAgreements(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersRevenueAgreementsDeleteSuccess(data));
          dispatch(fetchRentersRevenueAgreements(payload?.params));
        } else {
          dispatch(RentersRevenueAgreementsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/


// ExistingData
export const RentersRevenueAgreementsExistingDataFetchStart = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSEXISTINGDATA.START,
  payload,
});

export const RentersRevenueAgreementsExistingDataFetchSuccess = (payload) => ({
  type:RENTERS_REVENUEAGREEMENTSEXISTINGDATA.SUCCESS,
  payload,
});

export const RentersRevenueAgreementsExistingDataFetchError = (payload) => ({
  type: RENTERS_REVENUEAGREEMENTSEXISTINGDATA.ERROR,
  payload,
});

export const fetchRentersRevenueAgreementsExistingData = (payload) => {
  return (dispatch) => {
    dispatch(RentersRevenueAgreementsExistingDataFetchStart(payload));
    RentersRevenueAgreementsServices.fetchRentersExistingData(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRevenueAgreementsExistingDataFetchSuccess(data));
      } else {
        dispatch(RentersRevenueAgreementsExistingDataFetchError(data));
      }
    });
  };
};

//END ExistingData