import React from 'react';
import { InputTextarea  } from 'primereact/inputtextarea';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

const EzControlledInputAreaWithFloatingLabel = (props) => {
  const {
    control,
    name,
    disabled,
    type,
    rules,
    labelClassName,
    onChange,
    label,
    errorMsg,
    isError,
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className="p-float-label">
        <Controller
          name={name}
          control={control}
          rules={rules}
          
          render={({ field, fieldState }) => (
            <InputTextarea
              type={type}
              id={field.name}
              name={field.name}
              onChange={onChange}
              disabled={disabled}
              {...field}
              className={classNames({
                'p-invalid': fieldState.invalid,
              })}
            />
          )}
        />

<label htmlFor={name} className={getLabelClassName()}>
          {rules?.required ? `${label}*` : label}
        </label>
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledInputAreaWithFloatingLabel.defaultProps = {
  labelClassName: '',
  disabled: false,
  type: 'text',
};

export default EzControlledInputAreaWithFloatingLabel;
