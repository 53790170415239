import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleTransmissionType,
    initializeVehicleTransmissionTypeScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleTransmissionType
  } from "../../../redux/actions/vehicles/vehicleTransmissionType";
import VehicleTransmissionType from '../../../component/Vehicles/VehicleTransmissionType';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleTransmissionTypeScreen(props) {
    const {
        fetchVehicleTransmissionType,
        initVehicleTransmissionTypeScreen,
        showPageLevelLoader,
        vehicleTransmissionTypeListview,
        paginationInfo,
        exportVehicleTransmissionType,
        isVehicleTransmissionTypeExportError,
        isVehicleTransmissionTypeExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleTransmissionTypeScreen();
        fetchVehicleTransmissionType({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleTransmissionType(props);
      };
      const handleCreateVehicleTransmissionType =() =>{
      navigate(APP_URLS.VEHICLE_TRANSMISSIONTYPE.CREATE);
    }
      const editVehicleTransmissionTypeRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_TRANSMISSIONTYPE.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleTransmissionType = {
  handleCreateVehicleTransmissionType,
  exportVehicleTransmissionType,
        isVehicleTransmissionTypeExportError,
        isVehicleTransmissionTypeExportSuccess,
  editVehicleTransmissionTypeRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleTransmissionTypes: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleTransmissionTypeListview?.columns,
    rows: vehicleTransmissionTypeListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleTransmissionType
    {...vehicleTransmissionType}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleTransmissionTypeScreen: () => dispatch(initializeVehicleTransmissionTypeScreen()),
      fetchVehicleTransmissionType: (payload) => dispatch(fetchVehicleTransmissionType(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleTransmissionType: (payload) => dispatch(exportVehicleTransmissionType(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleTransmissionTypeCreateSuccess: selectFromVehicleTransmissionTypeReducer(
        state,
        "isVehicleTransmissionTypeCreateSuccess"
      ),
      vehicleTransmissionTypeListview: selectFromVehicleTransmissionTypeReducer(
        state,
        "vehicleTransmissionTypeListview"
      ),
      showPageLevelLoader: selectFromVehicleTransmissionTypeReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleTransmissionTypeReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleTransmissionTypeReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleTransmissionTypeReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleTransmissionTypeExportError: selectFromVehicleTransmissionTypeReducer(
        state,
        "isVehicleTransmissionTypeExportError"
      ),
      isVehicleTransmissionTypeExportSuccess: selectFromVehicleTransmissionTypeReducer(
        state,
        "isVehicleTransmissionTypeExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleTransmissionTypeReducer = (state, path) => {
  return selectVehicleTransmissionTypeReducer(state)[path];
};

const selectVehicleTransmissionTypeReducer = ({ vehicleTransmissionTypeReducer }) => {
  return vehicleTransmissionTypeReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleTransmissionTypeScreen);
