

export const FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST = {
    START: 'FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST_START',
    SUCCESS: 'FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_INIT="FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SCREEN =
'INIT_CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SCREEN';

export const CREATE_RATEADMIN_EXTRAPRODUCTCHARGE = {
START: 'CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_START',
SUCCESS: 'CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SUCCESS',
ERROR: 'CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_ERROR',
};

export const EDIT_RATEADMIN_EXTRAPRODUCTCHARGE = {
START: 'EDIT_RATEADMIN_EXTRAPRODUCTCHARGE_START',
SUCCESS: 'EDIT_RATEADMIN_EXTRAPRODUCTCHARGE_SUCCESS',
ERROR: 'EDIT_RATEADMIN_EXTRAPRODUCTCHARGE_ERROR',
};

export const FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL = {
  START: 'FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL_START',
  SUCCESS: 'FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL_SUCCESS',
  ERROR: 'FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL_ERROR',
};
export const EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE = {
  START: 'EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE_START',
  SUCCESS: 'EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE_SUCCESS',
  ERROR: 'EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE_ERROR',
};
export const RATEADMIN_EXTRAPRODUCTCHARGEDD = {
  START: 'RATEADMIN_EXTRAPRODUCTCHARGEDD_START',
  SUCCESS: 'RATEADMIN_EXTRAPRODUCTCHARGEDD_SUCCESS',
  ERROR: 'RATEADMIN_EXTRAPRODUCTCHARGEDD_ERROR',
};
export const RATEADMIN_RATEOPTIONDD = {
  START: 'RATEADMIN_RATEOPTIONDD_START',
  SUCCESS: 'RATEADMIN_RATEOPTIONDD_SUCCESS',
  ERROR: 'RATEADMIN_RATEOPTIONDD_ERROR',
};
export const RATEADMIN_RATENETWORKDD = {
  START: 'RATEADMIN_RATENETWORKDD_START',
  SUCCESS: 'RATEADMIN_RATENETWORKDD_SUCCESS',
  ERROR: 'RATEADMIN_RATENETWORKDD_ERROR',
};

  export const RATEADMIN_EXTRAPRODUCTCHARGEDELETE = {
    START: 'RATEADMIN_EXTRAPRODUCTCHARGEDELETE_START',
    SUCCESS: 'RATEADMIN_EXTRAPRODUCTCHARGEDELETE_SUCCESS',
    ERROR: 'RATEADMIN_EXTRAPRODUCTCHARGEDELETE_ERROR',
  };