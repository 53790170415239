export const basicDetail =
[
  {
    name:"fkLocationFrom",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"dropdown"
  },
  {
    name:"fkNetworkFrom",
     "rule": {
        "required": false
      },
    label:"Network",
    type:"dropdown"
  },
  {
    name:"fkRateGroupFrom",
     "rule": {
        "required": true
      },
    label:"Rate Code",
    type:"dropdown"
  },
  {
    name:"fkVehicleClassFrom",
     "rule": {
        "required": false
      },
    label:"Classes",
    type:"dropdown"
  },
  {
    name:"fkRateTypeIdFrom",
     "rule": {
        "required": false
      },
    label:"Rate Type From",
    type:"dropdown"
  },
  
]
export const basicDetailTo =
[
  {
    name:"fkLocationTo",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"dropdown"
  },
  {
    name:"fkNetworkTo",
     "rule": {
        "required": false
      },
    label:"Network",
    type:"dropdown"
  },
  {
    name:"fkRateGroupTo",
     "rule": {
        "required": true
      },
    label:"Rate Code",
    type:"dropdown"
  },
  {
    name:"fkVehicleClassTo",
     "rule": {
        "required": false
      },
    label:"Classes",
    type:"dropdown"
  },
  {
    name:"fkRateTypeIdTo",
     "rule": {
        "required": false
      },
    label:"Rate Type From",
    type:"dropdown"
  },
  {
    name:"percentage",
     "rule": {
        "required": false
      },
    label:"Percentage",
    type:"text"
  },
  {
    name:"flatRate",
     "rule": {
        "required": false
      },
    label:"Flat Rate",
    type:"text"
  },
  {
    name:"notes",
     "rule": {
        "required": false
      },
    label:"Notes",
    type:"text"
  },
]
export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const rateTypeFromDD =
[
  { id:1, name:"Exclusive"},
  { id:2, name:"Inclusive"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
