import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchRateAdminRates,
  initializeRateAdminRatesScreen,
  setDeleteConfirmationPopupVisibility,
  exportRateAdminRates,
  fetchRateAdminRatesDD,
  fetchRateAdminNetworkDD,
  fetchRateAdminRateOptionsDD,
  deleteRateAdminRates
} from "../../../redux/actions/RateAdmin/rateadminRates";
import {
  fetchLocationDD
} from "../../../redux/actions/location";
import {
  fetchRateAdminRateCodeDD
} from "../../../redux/actions/RateAdmin/rateadminRateCode";
import {
  fetchVehicleCategoryDD
} from "../../../redux/actions/vehicles/vehicleCategory";
import RateAdminRates from '../../../component/RateAdmin/RateAdminRates';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
import { RateTypeDD } from '../../../component/RateAdmin/RateAdminRates/CreateRateAdminRates/constant';
function RateAdminRatesScreen(props) {
  const {
    fetchRateAdminRates,
    initRateAdminRatesScreen,
    showPageLevelLoader,
    rateAdminRatesListview,
    paginationInfo,
    exportRateAdminRates,
    isRateAdminRatesExportError,
    isRateAdminRatesExportSuccess,
    fetchRateAdminRatesDD,
    deleteRateAdminRates,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    isRateAdminRatesDeleteSuccess,
    isRateAdminRatesDeleteError,
    fetchLocationDD,
    locationDD,
    fetchRateAdminRateCodeDD,
    RateCodeDD,
    RatesDD,
    fetchVehicleCategoryDD,
    vehicleCategoryDD,
    fetchRateAdminNetworkDD,
    fetchRateAdminRateOptionsDD,
    RateOptionsDD,
    RateNetworkDD,
    isLoading,
  } = props;
  console.log("i",RateOptionsDD)
  const navigate = useNavigate();

  const callAPIasync = async () => {
    initRateAdminRatesScreen();
    
    await fetchRateAdminNetworkDD();
    await fetchRateAdminRateOptionsDD();
    await fetchRateAdminRatesDD();
    await fetchRateAdminRateCodeDD();
    await fetchLocationDD();
    await fetchVehicleCategoryDD();
    await fetchRateAdminRates({ sortField: 'id' });
   
  }


  useEffect(() => {
    callAPIasync()
  }, []);
  const loadLazyData = (props) => {
    fetchRateAdminRates(props);
  };
  const handleCreateRateAdminRates = () => {
    navigate(APP_URLS.RATEADMIN_RATES.CREATE);
  }
  const editRateAdminRatesRoute = (id) => {
    const path = generatePath(APP_URLS.RATEADMIN_RATES.EDIT, {
      id,
    });
    navigate(path);
  };
  const copyRateAdminRatesRoute = (id) => {
    const path = generatePath(APP_URLS.RATEADMIN_RATES.COPY, {
      id,
    });
    navigate(path);
  };
  let nColumns = rateAdminRatesListview?.columns;
  if (nColumns && nColumns.length > 0) {
    let locDD = [];
    let rateCodeDD = [];
    let cateDD = [];
    let optDD = [];
    let netDD = [];
    let rtTypeDD=[];
    locationDD.map(item => (
      locDD.push({ label: item.name, value: item.id })
    ))
    RateCodeDD.map(item => (
      rateCodeDD.push({ label: item.name, value: item.id })
    ))
    vehicleCategoryDD.map(item => (
      cateDD.push({ label: item.name, value: item.id })
    ))

    
    RateOptionsDD.map(item => (
      optDD.push({ label: item.name, value: item.id })
    ))
    RateNetworkDD.map(item => (
      netDD.push({ label: item.name, value: item.id })
    ))
    RateTypeDD.map(item => (
      rtTypeDD.push({ label: item.name, value: item.id })
    ))
    nColumns = nColumns.map(item => ({
      ...item,
      ...(item.dataKey === "rateCodeTitle" ? { extraProps: { ...item.extraProps, options: rateCodeDD } } : {}),
      ...(item.dataKey === "locationTitle" ? { extraProps: { ...item.extraProps, options: locDD } } : {}),
      ...(item.dataKey === "networkTitle" ? { extraProps: { ...item.extraProps, options: netDD } } : {}),
      ...(item.dataKey === "categoryTitle" ? { extraProps: { ...item.extraProps, options: cateDD } } : {}),
      ...(item.dataKey === "rateOptionTitle" ? { extraProps: { ...item.extraProps, options: optDD } } : {}),
      ...(item.dataKey === "rateType" ? { extraProps: { ...item.extraProps, options: rtTypeDD } } : {})
    }));
  }
  const rateAdminRates = {
    handleCreateRateAdminRates,
    exportRateAdminRates,
    isRateAdminRatesExportError,
    isRateAdminRatesExportSuccess,
    deleteRateAdminRates,
    isRateAdminRatesDeleteSuccess,
    isRateAdminRatesDeleteError,
    editRateAdminRatesRoute,
    copyRateAdminRatesRoute,
    ...companyData,
    screenPermissions: companyData?.screenPermissions,
    setDeleteConfirmationPopupVisibility,
    shouldOpenDeleteConfirmationPopup,
    fetchRateAdminRatess: loadLazyData,
    ...companyData,
    tableData: {
      columns: nColumns,//rateAdminRatesListview?.columns,
      rows: rateAdminRatesListview?.rows,
    },
    paginationInfo: {
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };

  if (showPageLevelLoader) {
    loadingShow("block");
    return null;
  }
  loadingShow("none");
  return (
    <div>
      <RateAdminRates
        {...rateAdminRates}
      />
    </div>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    initRateAdminRatesScreen: () => dispatch(initializeRateAdminRatesScreen()),
    fetchRateAdminRates: (payload) => dispatch(fetchRateAdminRates(payload)),
    fetchRateAdminRatesDD: (payload) => dispatch(fetchRateAdminRatesDD(payload)),
    fetchRateAdminRateCodeDD: (payload) => dispatch(fetchRateAdminRateCodeDD(payload)),
    fetchRateAdminRateOptionsDD: (payload) => dispatch(fetchRateAdminRateOptionsDD(payload)),
    fetchRateAdminNetworkDD: (payload) => dispatch(fetchRateAdminNetworkDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    deleteRateAdminRates: (payload) => dispatch(deleteRateAdminRates(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    exportRateAdminRates: (payload) => dispatch(exportRateAdminRates(payload)),
  };
};


//vehicleCagegoryReducer
const mapStateToProps = (state, ownProps) => {
  return {

    isRateAdminRatesCreateSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesCreateSuccess"
    ),
    shouldOpenDeleteConfirmationPopup: selectFromRateAdminRatesReducer(
      state,
      "shouldOpenDeleteConfirmationPopup"
    ),
    
    rateAdminRatesListview: selectFromRateAdminRatesReducer(
      state,
      "rateAdminRatesListview"
    ),
    showPageLevelLoader: selectFromRateAdminRatesReducer(
      state,
      "showPageLevelLoader"
    ),
    paginationInfo: selectFromRateAdminRatesReducer(
      state,
      "paginationInfo"
    ),

    isRateAdminRatesDeleteSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesDeleteSuccess"
    ),
    isRateAdminRatesDeleteError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesDeleteError"
    ),
    isPageLevelError: selectFromRateAdminRatesReducer(
      state,
      "isPageLevelError"
    ),
    isFetchingGridData: selectFromRateAdminRatesReducer(
      state,
      "isFetchingGridData"
    ),
    isRateAdminRatesExportError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesExportError"
    ),
    RateOptionsDD: selectFromRateAdminRatesReducer(
      state,
      "RateOptionsDD"
    ),
    RatesDD: selectFromRateAdminRatesReducer(
      state,
      "RatesDD"
    ),
    RateNetworkDD: selectFromRateAdminRatesReducer(
      state,
      "RateNetworkDD"
    ),
    isRateAdminRatesExportSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesExportSuccess"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    RateCodeDD: selectFromRateCodeReducer(
      state,
      "RateCodeDD"
    ),
    ...ownProps,
  };
};

const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};

//Location
const selectFromRateCodeReducer = (state, path) => {
  return selectRateCodeReducer(state)[path];
};
const selectRateCodeReducer = ({ rateadminRateCodeReducer }) => {
  return rateadminRateCodeReducer;
};

//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};

//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
export default connect(mapStateToProps, mapDispatchToProps)(RateAdminRatesScreen);
