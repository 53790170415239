

export const FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST = {
    START: 'FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST_START',
    SUCCESS: 'FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST_SUCCESS',
    ERROR: 'FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_SETTINGS_PAYNOWDISCOUNTS_INIT="FETCH_SETTINGS_PAYNOWDISCOUNTS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_SETTINGS_PAYNOWDISCOUNTS_SCREEN =
'INIT_CREATE_SETTINGS_PAYNOWDISCOUNTS_SCREEN';

export const CREATE_SETTINGS_PAYNOWDISCOUNTS = {
START: 'CREATE_SETTINGS_PAYNOWDISCOUNTS_START',
SUCCESS: 'CREATE_SETTINGS_PAYNOWDISCOUNTS_SUCCESS',
ERROR: 'CREATE_SETTINGS_PAYNOWDISCOUNTS_ERROR',
};

export const EDIT_SETTINGS_PAYNOWDISCOUNTS = {
START: 'EDIT_SETTINGS_PAYNOWDISCOUNTS_START',
SUCCESS: 'EDIT_SETTINGS_PAYNOWDISCOUNTS_SUCCESS',
ERROR: 'EDIT_SETTINGS_PAYNOWDISCOUNTS_ERROR',
};

export const FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL = {
  START: 'FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL_START',
  SUCCESS: 'FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL_SUCCESS',
  ERROR: 'FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL_ERROR',
};
export const EXPORT_SETTINGS_PAYNOWDISCOUNTS = {
  START: 'EXPORT_SETTINGS_PAYNOWDISCOUNTS_START',
  SUCCESS: 'EXPORT_SETTINGS_PAYNOWDISCOUNTS_SUCCESS',
  ERROR: 'EXPORT_SETTINGS_PAYNOWDISCOUNTS_ERROR',
};
export const SETTINGS_PAYNOWDISCOUNTSDD = {
  START: 'SETTINGS_PAYNOWDISCOUNTSDD_START',
  SUCCESS: 'SETTINGS_PAYNOWDISCOUNTSDD_SUCCESS',
  ERROR: 'SETTINGS_PAYNOWDISCOUNTSDD_ERROR',
};
export const SETTINGS_PAYNOWDISCOUNTSDELETE = {
  START: 'SETTINGS_PAYNOWDISCOUNTSDELETE_START',
  SUCCESS: 'SETTINGS_PAYNOWDISCOUNTSDELETE_SUCCESS',
  ERROR: 'SETTINGS_PAYNOWDISCOUNTSDELETE_ERROR',
};

