import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleTransmissionTypeServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_TRANSMISSIONTYPE_INIT, 
  FETCH_VEHICLE_TRANSMISSIONTYPE_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_TRANSMISSIONTYPE_SCREEN,
  CREATE_VEHICLE_TRANSMISSIONTYPE,
  EDIT_VEHICLE_TRANSMISSIONTYPE,
  TOAST,
  FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL,
  EXPORT_VEHICLE_TRANSMISSIONTYPE,
  VEHICLE_MODELDD
} from "../../../types/vehicletransmissiontype.type";
export const initializeVehicleTransmissionTypeScreen = () => ({ type: FETCH_VEHICLE_TRANSMISSIONTYPE_INIT });

export const vehicleTransmissionTypeFetchStart = (payload) => ({
  type: FETCH_VEHICLE_TRANSMISSIONTYPE_LIST.START,
  payload,
});

export const vehicleTransmissionTypeFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_TRANSMISSIONTYPE_LIST.SUCCESS,
  payload,
});

export const vehicleTransmissionTypeFetchError = (payload) => ({
  type: FETCH_VEHICLE_TRANSMISSIONTYPE_LIST.ERROR,
  payload,
});

export const fetchVehicleTransmissionType = (payload) => {
  return (dispatch) => {
    dispatch(vehicleTransmissionTypeFetchStart(payload));
    vehicleTransmissionTypeServices.fetchVehicleTransmissionType(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleTransmissionTypeFetchSuccess(data));
      } else {
        dispatch(vehicleTransmissionTypeFetchError(data));
      }
    });
  };
};



// DD
export const vehicleModelDDFetchStart = (payload) => ({
  type: VEHICLE_MODELDD.START,
  payload,
});

export const vehicleModelDDFetchSuccess = (payload) => ({
  type:VEHICLE_MODELDD.SUCCESS,
  payload,
});

export const vehicleModelDDFetchError = (payload) => ({
  type: VEHICLE_MODELDD.ERROR,
  payload,
});

export const fetchVehicleModelDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleModelDDFetchStart(payload));
    vehicleTransmissionTypeServices.fetchVehicleModelDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleModelDDFetchSuccess(data));
      } else {
        dispatch(vehicleModelDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleTransmissionTypeDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL.START,
  payload,
});

export const vehicleTransmissionTypeDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL.SUCCESS,
  payload,
});

export const vehicleTransmissionTypeDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL.ERROR,
  payload,
});

export const fetchVehicleTransmissionTypeById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleTransmissionTypeDetailFetchStart(payload));

    vehicleTransmissionTypeServices
      .fetchVehicleTransmissionTypeById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleTransmissionTypeDetailFetchSuccess(data));
        } else {
          dispatch(vehicleTransmissionTypeDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleTransmissionTypeServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleTransmissionTypeScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_TRANSMISSIONTYPE_SCREEN,
  payload,
});

export const initCreateVehicleTransmissionTypeScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleTransmissionTypeScreenInitStart());
  };
};


export const createVehicleTransmissionTypeStart = () => ({
  type: CREATE_VEHICLE_TRANSMISSIONTYPE.START,
});

export const createVehicleTransmissionTypeSuccess = (payload) => ({
  type: CREATE_VEHICLE_TRANSMISSIONTYPE.SUCCESS,
  payload,
});

export const createVehicleTransmissionTypeError = (payload) => ({
  type: CREATE_VEHICLE_TRANSMISSIONTYPE.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleTransmissionType = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleTransmissionTypeStart());
    vehicleTransmissionTypeServices.createVehicleTransmissionType(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleTransmissionTypeSuccess());
        goBack();
      } else {
        dispatch(createVehicleTransmissionTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleTransmissionTypeStart = (payload) => ({
  type: EDIT_VEHICLE_TRANSMISSIONTYPE.START,
  payload
});

export const editVehicleTransmissionTypeSuccess = (payload) => ({
  type: EDIT_VEHICLE_TRANSMISSIONTYPE.SUCCESS,
  payload,
});

export const editVehicleTransmissionTypeError = (payload) => ({
  type: EDIT_VEHICLE_TRANSMISSIONTYPE.ERROR,
  payload,
});

export const editVehicleTransmissionType = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleTransmissionTypeStart());
    vehicleTransmissionTypeServices.editVehicleTransmissionType(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleTransmissionTypeSuccess());
        goBack();
      } else {
        dispatch(editVehicleTransmissionTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleTransmissionTypeRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_TRANSMISSIONTYPE.START,
  payload
});

export const exportVehicleTransmissionTypeRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_TRANSMISSIONTYPE.SUCCESS,
  payload,
});

export const exportVehicleTransmissionTypeRecordError = (payload) => ({
  type: EXPORT_VEHICLE_TRANSMISSIONTYPE.ERROR,
  payload,
});

export const exportVehicleTransmissionType = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleTransmissionTypeRecordStart());
    vehicleTransmissionTypeServices.exportVehicleTransmissionTypeRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleTransmissionTypeRecordSuccess(data));
      } else {
        dispatch(exportVehicleTransmissionTypeRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

