import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleDealershipVehicleDealServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_INIT, 
  FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL_SCREEN,
  CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL,
  EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL,
  TOAST,
  FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL,
  EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL,
  VEHICLE_OPERATIONDD
} from "../../../types/vehicledealershipvehicledeal.type";
export const initializeVehicleDealershipVehicleDealScreen = () => ({ type: FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_INIT });

export const vehicleDealershipVehicleDealFetchStart = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST.START,
  payload,
});

export const vehicleDealershipVehicleDealFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST.SUCCESS,
  payload,
});

export const vehicleDealershipVehicleDealFetchError = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST.ERROR,
  payload,
});

export const fetchVehicleDealershipVehicleDeal = (payload) => {
  return (dispatch) => {
    dispatch(vehicleDealershipVehicleDealFetchStart(payload));
    vehicleDealershipVehicleDealServices.fetchVehicleDealershipVehicleDeal(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleDealershipVehicleDealFetchSuccess(data));
      } else {
        dispatch(vehicleDealershipVehicleDealFetchError(data));
      }
    });
  };
};



// DD
export const vehicleOperationDDFetchStart = (payload) => ({
  type: VEHICLE_OPERATIONDD.START,
  payload,
});

export const vehicleOperationDDFetchSuccess = (payload) => ({
  type:VEHICLE_OPERATIONDD.SUCCESS,
  payload,
});

export const vehicleOperationDDFetchError = (payload) => ({
  type: VEHICLE_OPERATIONDD.ERROR,
  payload,
});

export const fetchVehicleOperationDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleOperationDDFetchStart(payload));
    vehicleDealershipVehicleDealServices.fetchVehicleOperationDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleOperationDDFetchSuccess(data));
      } else {
        dispatch(vehicleOperationDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleDealershipVehicleDealDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL.START,
  payload,
});

export const vehicleDealershipVehicleDealDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL.SUCCESS,
  payload,
});

export const vehicleDealershipVehicleDealDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL.ERROR,
  payload,
});

export const fetchVehicleDealershipVehicleDealById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleDealershipVehicleDealDetailFetchStart(payload));

    vehicleDealershipVehicleDealServices
      .fetchVehicleDealershipVehicleDealById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleDealershipVehicleDealDetailFetchSuccess(data));
        } else {
          dispatch(vehicleDealershipVehicleDealDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleDealershipVehicleDealServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleDealershipVehicleDealScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL_SCREEN,
  payload,
});

export const initCreateVehicleDealershipVehicleDealScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleDealershipVehicleDealScreenInitStart());
  };
};


export const createVehicleDealershipVehicleDealStart = () => ({
  type: CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL.START,
});

export const createVehicleDealershipVehicleDealSuccess = (payload) => ({
  type: CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL.SUCCESS,
  payload,
});

export const createVehicleDealershipVehicleDealError = (payload) => ({
  type: CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleDealershipVehicleDeal = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleDealershipVehicleDealStart());
    vehicleDealershipVehicleDealServices.createVehicleDealershipVehicleDeal(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleDealershipVehicleDealSuccess());
        goBack();
      } else {
        dispatch(createVehicleDealershipVehicleDealError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleDealershipVehicleDealStart = (payload) => ({
  type: EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL.START,
  payload
});

export const editVehicleDealershipVehicleDealSuccess = (payload) => ({
  type: EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL.SUCCESS,
  payload,
});

export const editVehicleDealershipVehicleDealError = (payload) => ({
  type: EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL.ERROR,
  payload,
});

export const editVehicleDealershipVehicleDeal = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleDealershipVehicleDealStart());
    vehicleDealershipVehicleDealServices.editVehicleDealershipVehicleDeal(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleDealershipVehicleDealSuccess());
        goBack();
      } else {
        dispatch(editVehicleDealershipVehicleDealError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleDealershipVehicleDealRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL.START,
  payload
});

export const exportVehicleDealershipVehicleDealRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL.SUCCESS,
  payload,
});

export const exportVehicleDealershipVehicleDealRecordError = (payload) => ({
  type: EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL.ERROR,
  payload,
});

export const exportVehicleDealershipVehicleDeal = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleDealershipVehicleDealRecordStart());
    vehicleDealershipVehicleDealServices.exportVehicleDealershipVehicleDealRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleDealershipVehicleDealRecordSuccess(data));
      } else {
        dispatch(exportVehicleDealershipVehicleDealRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

