export const basicDetail =
[
  {
    name:"title",
     "rule": {
        "required": true
      },
    label:"Title",
    type:"text"
  },
  {
    name:"url",
     "rule": {
        "required": true
      },
    label:"URL",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  },
  
  {
    name:"locationId",
     "rule": {
        "required": false
      },
    label:"Location",
    type:"dropdown"
  },
  {
    name:"vehicleCategoryId",
     "rule": {
        "required": false
      },
    label:"Vehicle Category",
    type:"dropdown"
  },
  {
    name:"inclusiveRate",
     "rule": {
        "required": false
      },
    label:"Does this network offers INCLUSIVE rates?",
    type:"checkbox"
  },
  {
    name:"applicable",
     "rule": {
        "required": false
      },
    label:"Applicable",
    type:"dropdown"
  },
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const AppliesDD =
[
  { id:1, name:"Applies Per Day"},
  { id:2, name:"Applies By Percentage"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

  