export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

export const basicDetail =
[
  {
    name:"title",
     "rule": {
        "required": true
      },
    label:"Vehicle Class",
    type:"text"
  },{
    name:"slug",
     "rule": {
        "required": true
      },
    label:"Slug",
    type:"text"
  },{
    name:"tag",
     "rule": {
        "required": true
      },
    label:"Tag",
    type:"text"
  },{
    name:"code",
     "rule": {
        "required": false
      },
    label:"Car Class Code",
    type:"text"
  },{
    name:"freeMileage",
     "rule": {
        "required": true
      },
    label:"Free Mileage",
    type:"number"
  },{
    name:"maximumMileage",
     "rule": {
        "required": false
      },
    label:"Maximum Mileage",
    type:"number"
  },{
    name:"extraMileageRate",
     "rule": {
        "required": true
      },
    label:"Extra Mileage Rate per KM",
    type:"number"
  },{
    name:"description",
     "rule": {
        "required": true
      },
    label:"Description",
    type:"text"
  },{
    name:"passenger",
     "rule": {
        "required": true
      },
    label:"Passenger",
    type:"dropdown"
  },{
    name:"luggage",
     "rule": {
        "required": true
      },
    label:"Luggage",
    type:"dropdown"
  },{
    name:"depositAmount",
     "rule": {
        "required": false
      },
    label:"Deposit Amount",
    type:"number"
  },{
    name:"doors",
     "rule": {
        "required": true
      },
    label:"Doors",
    type:"dropdown"
  },{
    name:"transmission",
     "rule": {
        "required": true
      },
    label:"Transmission",
    type:"dropdown"
  },{
    name:"stereo",
     "rule": {
        "required": true
      },
    label:"Stereo",
    type:"dropdown"
  },{
    name:"ac",
     "rule": {
        "required": true
      },
    label:"AC",
    type:"dropdown"
  },{
    name:"extraFeatures",
     "rule": {
        "required": false
      },
    label:"Extra Features",
    type:"text"
  },{
    name:"details",
     "rule": {
        "required": true
      },
    label:"Details",
    type:"text"
  },{
    name:"keywords",
     "rule": {
        "required": false
      },
    label:"Keywords",
    type:"text"
  },{
    name:"confirmationRequired",
     "rule": {
        "required": false
      },
    label:"Confirmation Required",
    type:"dropdown"
  },
  {
    name:"maxStockPercent",
     "rule": {
        "required": false
      },
    label:"Max Stock Percent",
    type:"number"
  },{
    name:"inquiry",
     "rule": {
        "required": true
      },
    label:"Inquiry Only",
    type:"dropdown"
  },{
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  },
  // {
  //   name:"imgUrl",
  //    "rule": {
  //       "required": false
  //     },
  //   label:"Upload Picture",
  //   type:"file"
  // }
]

export const passengerDD =[
  {"id":1, "name": "1"},
  {"id": 2, "name": "2"},
  {"id":3, "name": "3"},
  {"id":4, "name": "4"},
  {"id":5, "name": "5"},
  {"id":6, "name": "6"},
  {"id":7, "name": "7"},
  {"id":8, "name": "8"},
  {"id":9, "name": "9"},
  {"id":10, "name": "10"},
  {"id":11, "name": "11"},
  {"id":12, "name": "12"}
];
export const luggageDD =[
  {"id":1, "name": "1"},
  {"id": 2, "name": "2"},
  {"id":3, "name": "3"},
  {"id":4, "name": "4"},
  {"id":5, "name": "5"},
  {"id":6, "name": "6"},
  {"id":7, "name": "7"},
  {"id":8, "name": "8"},
  {"id":9, "name": "9"},
  {"id":10, "name": "10"}
];

export const doorsDD =[
  {"id":1, "name": "1"},
  {"id": 2, "name": "2"},
  {"id":3, "name": "3"},
  {"id":4, "name": "4"},
  {"id":5, "name": "5"},
  {"id":6, "name": "6"}
];
export const confirmationRequiredDD =
[
  { id:1, name:"Yes"},
  { id:0, name:"No"}
];
export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const inquiryDD =
[
  { id:1, name:"Yes"},
  { id:0, name:"No"}
]
export const transmissionDD =[
  { id:1, name:"A/T"},
  { id:2, name:"S/A"},
  { id:3, name:"M"}
]
export const stereoDD =[
  { id:1, name:"A/M, F/M, CD Player"},
  { id:2, name:"A/M, F/M, Cassette"},
  { id:3, name:"A/M, F/M"},
  { id:4, name:"Bluetooth"}
]
export const actypeDD =[
  { id:1, name:"No AC"},
  { id:2, name:"AC"},
  { id:3, name:"Dual AC"}
]