import React, { useEffect, useRef, useState } from "react";
import EzTable from "../../../common/EzTable";
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import EzNotFound from "../../../common/EzNotFound";
import { Box, Container } from "@mui/material";
import Breadcrumb from "../../../common/Breadcrumb";
import { Button } from "primereact/button";
import { useStyles } from './style';
import { RateAdminBreadcrumb } from "./CreateRateAdminRateTypes/constant";

const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const RateAdminRateTypes = (props) => {
  const classes = useStyles()
  const {
    fetchRateAdminRateTypess,
    tableData,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminRateTypes,
    isPageLevelError,
    paginationInfo,
    isLoading,
    screenPermissions,
    handleCreateRateAdminRateTypes,
    editRateAdminRateTypesRoute
  } = props;

const [isCopy, setIsCopy] = useState(false)
  const convertToTSV = (data) => {
    const headers = data.columns.map(item => item.colLabel).join('\t');
    //const headers = Object.keys(data.rows[0]).join('\t');
    const rows = data.rows.map(row => Object.values(row).join('\t')).join('\n');
    return `${headers}\n${rows}`;
  };

  const copyToClipboard = () => {
    const csv = convertToTSV(tableData);
    navigator.clipboard.writeText(csv)
      .then(() => 
        setIsCopy(true)
      )
      .catch(err => 
       setIsCopy(true) 
      );
  };
  useEffect(() => {
    setTimeout(() =>     setIsCopy(false)
    , 1000);
  }, [isCopy]);
  const tableRef = useRef();
  const handlePrint = () => {
    const printContents = tableRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const renderPageHeaderSection = () => {
    return (
    

<div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Rate Types</h4>
          </div>
          <div className="buttonarea">
            {/* <Button
             label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
             icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
             className={`p-button-text p-button-secondary`}
             onClick={() => {
               toggleFilterState(!areFiltersVisible);
             }}
           /> */}
          <Button
              label={"Copy"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={copyToClipboard}
            />
            <Button
              label={"Excel"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={exportRateAdminRateTypes}
            />
            <Button
              label={"Print"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={handlePrint}
            />
            <Button
              label={"Create Rate Types"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={handleCreateRateAdminRateTypes}
            />
        </div>
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderComplianceRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderComplianceRecords() && !shouldRenderFullPageError();
  const filters = {
    global: { value: null },
  };

  const getToastProps = () => {
  
    let toastTitle = ""
    if (
      isCopy
    ) {
      let toastTitle = "Data copied to clipboard!";
      if(!isCopy) {
        toastTitle = "Error while Data copied to clipboard!";
      } 

      return {
        severity: isCopy ? TOAST_SEVERITY.SUCCESS : TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const tableProps = {
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Rate Types found.",
    filters,
    loadLazyData: fetchRateAdminRateTypess,
    loading: isLoading,
    showMoreActions: false,
    sortField: null,
    setDeleteConfirmationPopupVisibility,
    onEditRecord: editRateAdminRateTypesRoute
  };
  return (
    <>
      <Container maxWidth="lg">
      <Breadcrumb list={RateAdminBreadcrumb } />
        <EzToast {...getToastProps()} />
        <div className="containerbox" className={classes.bg}>
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderComplianceRecords() && (
              <Box ref={tableRef} id="printable">
                <EzTable {...tableProps} />
              </Box>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No Rate Types records to show"}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
export default RateAdminRateTypes;

