

export const FETCH_RESERVATIONS_DETAILREPORT_LIST = {
    START: 'FETCH_RESERVATIONS_DETAILREPORT_LIST_START',
    SUCCESS: 'FETCH_RESERVATIONS_DETAILREPORT_LIST_SUCCESS',
    ERROR: 'FETCH_RESERVATIONS_DETAILREPORT_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RESERVATIONS_DETAILREPORT_INIT="FETCH_RESERVATIONS_DETAILREPORT_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RESERVATIONS_DETAILREPORT_SCREEN =
'INIT_CREATE_RESERVATIONS_DETAILREPORT_SCREEN';

export const CREATE_RESERVATIONS_DETAILREPORT = {
START: 'CREATE_RESERVATIONS_DETAILREPORT_START',
SUCCESS: 'CREATE_RESERVATIONS_DETAILREPORT_SUCCESS',
ERROR: 'CREATE_RESERVATIONS_DETAILREPORT_ERROR',
};

export const EDIT_RESERVATIONS_DETAILREPORT = {
START: 'EDIT_RESERVATIONS_DETAILREPORT_START',
SUCCESS: 'EDIT_RESERVATIONS_DETAILREPORT_SUCCESS',
ERROR: 'EDIT_RESERVATIONS_DETAILREPORT_ERROR',
};

export const FETCH_RESERVATIONS_DETAILREPORT_DETAIL = {
  START: 'FETCH_RESERVATIONS_DETAILREPORT_DETAIL_START',
  SUCCESS: 'FETCH_RESERVATIONS_DETAILREPORT_DETAIL_SUCCESS',
  ERROR: 'FETCH_RESERVATIONS_DETAILREPORT_DETAIL_ERROR',
};
export const EXPORT_RESERVATIONS_DETAILREPORT = {
  START: 'EXPORT_RESERVATIONS_DETAILREPORT_START',
  SUCCESS: 'EXPORT_RESERVATIONS_DETAILREPORT_SUCCESS',
  ERROR: 'EXPORT_RESERVATIONS_DETAILREPORT_ERROR',
};
export const RESERVATIONS_DETAILREPORTDD = {
  START: 'RESERVATIONS_DETAILREPORTDD_START',
  SUCCESS: 'RESERVATIONS_DETAILREPORTDD_SUCCESS',
  ERROR: 'RESERVATIONS_DETAILREPORTDD_ERROR',
};
export const RESERVATIONS_DETAILREPORTDELETE = {
  START: 'RESERVATIONS_DETAILREPORTDELETE_START',
  SUCCESS: 'RESERVATIONS_DETAILREPORTDELETE_SUCCESS',
  ERROR: 'RESERVATIONS_DETAILREPORTDELETE_ERROR',
};

