import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminExtraProductCategoryScreen,
  createRateAdminExtraProductCategory,
  editRateAdminExtraProductCategory,
  fetchRateAdminExtraProductCategoryById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminExtraProductCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminExtraProductCategoryComponent from "../../../../component/RateAdmin/RateAdminExtraProductCategory/CreateRateAdminExtraProductCategory";
import { useParams } from "react-router-dom";

const CreateRateAdminExtraProductCategoryContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminExtraProductCategory,
    editRateAdminExtraProductCategory,
    isLoading,
    isRateAdminExtraProductCategoryCreateSuccess,
    isRateAdminExtraProductCategoryCreateError,
    isRateAdminExtraProductCategoryDetailSuccess,
    isRateAdminExtraProductCategoryDetailError,
    isRateAdminExtraProductCategoryEditSuccess,
    isRateAdminExtraProductCategoryEditError,
    isEdit,
    isView,
    initCreateRateAdminExtraProductCategoryScreen,
    formFieldValueMap,
    fetchRateAdminExtraProductCategoryById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminExtraProductCategoryScreen();
   if (isEdit) {
    await fetchRateAdminExtraProductCategoryById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminExtraProductCategoryProps = {
    createRateAdminExtraProductCategory,
    initCreateRateAdminExtraProductCategoryScreen,
    editRateAdminExtraProductCategory,
    isLoading,
    isEdit,
    isRateAdminExtraProductCategoryDetailSuccess,
    isRateAdminExtraProductCategoryCreateSuccess,
    isRateAdminExtraProductCategoryEditSuccess,
    isRateAdminExtraProductCategoryEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRateAdminExtraProductCategoryCreateSuccess || isRateAdminExtraProductCategoryEditSuccess) {
      const toastTitle = isEdit
        ? "Extra Product Category Updated Successfully"
        : "ExtraProduct Category Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminExtraProductCategoryCreateError ||
      isRateAdminExtraProductCategoryDetailError ||
      isRateAdminExtraProductCategoryEditError
    ) {
      let toastTitle = "Error while Creating Rate Types";
      if (isRateAdminExtraProductCategoryEditError) {
        toastTitle = "Error while updating  Rate Types";
      } else if (isRateAdminExtraProductCategoryDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminExtraProductCategoryComponent {...createRateAdminExtraProductCategoryProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminExtraProductCategoryReducer(state, "isLoading"),
   
    isRateAdminExtraProductCategoryCreateSuccess: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isRateAdminExtraProductCategoryCreateSuccess"
    ),
    isRateAdminExtraProductCategoryCreateError: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isRateAdminExtraProductCategoryCreateError"
    ),
    isRateAdminExtraProductCategoryDetailSuccess: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isRateAdminExtraProductCategoryDetailSuccess"
    ),
    isRateAdminExtraProductCategoryDetailError: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isRateAdminExtraProductCategoryDetailError"
    ),
    isRateAdminExtraProductCategoryEditSuccess: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isRateAdminExtraProductCategoryEditSuccess"
    ),
    isRateAdminExtraProductCategoryEditError: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isRateAdminExtraProductCategoryEditError"
    ),
    imageUploadResponse: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRateAdminExtraProductCategoryReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminExtraProductCategoryScreen: () => dispatch(initCreateRateAdminExtraProductCategoryScreen()),
    createRateAdminExtraProductCategory: (payload) => dispatch(createRateAdminExtraProductCategory(payload)),
    editRateAdminExtraProductCategory: (payload) => dispatch(editRateAdminExtraProductCategory(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminExtraProductCategoryById: (payload) =>
      dispatch(fetchRateAdminExtraProductCategoryById(payload)),

  };
};

const selectFromRateAdminExtraProductCategoryReducer = (state, path) => {
  return selectRateAdminExtraProductCategoryReducer(state)[path];
};

const selectRateAdminExtraProductCategoryReducer = ({ rateadminExtraProductCategoryReducer }) => {
  return rateadminExtraProductCategoryReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminExtraProductCategoryContainer);
