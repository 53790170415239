import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleMake,
    initializeVehicleMakeScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleMake
  } from "../../../redux/actions/vehicles/vehicleMake";
import VehicleMake from '../../../component/Vehicles/VehicleMake';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleMakeScreen(props) {
    const {
        fetchVehicleMake,
        initVehicleMakeScreen,
        showPageLevelLoader,
        vehicleMakeListview,
        paginationInfo,
        exportVehicleMake,
        isVehicleMakeExportError,
        isVehicleMakeExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleMakeScreen();
        fetchVehicleMake({ sortField: 'id'});
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleMake(props);
      };
      const handleCreateVehicleMake =() =>{
      navigate(APP_URLS.VEHICLE_MAKE.CREATE);
    }
      const editVehicleMakeRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_MAKE.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleMake = {
  handleCreateVehicleMake,
  exportVehicleMake,
        isVehicleMakeExportError,
        isVehicleMakeExportSuccess,
  editVehicleMakeRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleMakes: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleMakeListview?.columns,
    rows: vehicleMakeListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleMake
    {...vehicleMake}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleMakeScreen: () => dispatch(initializeVehicleMakeScreen()),
      fetchVehicleMake: (payload) => dispatch(fetchVehicleMake(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleMake: (payload) => dispatch(exportVehicleMake(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleMakeCreateSuccess: selectFromVehicleMakeReducer(
        state,
        "isVehicleMakeCreateSuccess"
      ),
      vehicleMakeListview: selectFromVehicleMakeReducer(
        state,
        "vehicleMakeListview"
      ),
      showPageLevelLoader: selectFromVehicleMakeReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleMakeReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleMakeReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleMakeReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleMakeExportError: selectFromVehicleMakeReducer(
        state,
        "isVehicleMakeExportError"
      ),
      isVehicleMakeExportSuccess: selectFromVehicleMakeReducer(
        state,
        "isVehicleMakeExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleMakeReducer = (state, path) => {
  return selectVehicleMakeReducer(state)[path];
};

const selectVehicleMakeReducer = ({ vehicleMakeReducer }) => {
  return vehicleMakeReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleMakeScreen);
