import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminRateMirrors,
    initializeRateAdminRateMirrorsScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminRateMirrors,
    deleteRateMirrorsAdminRateMirrors
  } from "../../../redux/actions/RateAdmin/rateadminRateMirrors";
import RateAdminRateMirrors from '../../../component/RateAdmin/RateAdminRateMirrors';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminRateMirrorsScreen(props) {
    const {
        fetchRateAdminRateMirrors,
        initRateAdminRateMirrorsScreen,
        showPageLevelLoader,
        rateAdminRateMirrorsListview,
        paginationInfo,
        exportRateAdminRateMirrors,
        isRateAdminRateMirrorsExportError,
        isRateAdminRateMirrorsExportSuccess,
        isLoading,
        deleteRateMirrorsAdminRateMirrors,
        setDeleteConfirmationPopupVisibility,
        isRateAdminRateMirrorsDeleteSuccess,
    isRateAdminRateMirrorsDeleteError,
    shouldOpenDeleteConfirmationPopup
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminRateMirrorsScreen();
        fetchRateAdminRateMirrors({ sortField: 'id', userId:localStorage.getItem("userId")  });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newPyload={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRateAdminRateMirrors(newPyload);
      };
      const handleCreateRateAdminRateMirrors =() =>{
      navigate(APP_URLS.RATEADMIN_RATEMIRRORS.CREATE);
    }
      const editRateAdminRateMirrorsRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_RATEMIRRORS.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminRateMirrors = {
  handleCreateRateAdminRateMirrors,
  deleteRateMirrorsAdminRateMirrors,
  isRateAdminRateMirrorsDeleteSuccess,
    isRateAdminRateMirrorsDeleteError,
    shouldOpenDeleteConfirmationPopup,
  exportRateAdminRateMirrors,
        isRateAdminRateMirrorsExportError,
        isRateAdminRateMirrorsExportSuccess,
  editRateAdminRateMirrorsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminRateMirrorss: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminRateMirrorsListview?.columns,
    rows: rateAdminRateMirrorsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,

};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminRateMirrors
    {...rateAdminRateMirrors}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminRateMirrorsScreen: () => dispatch(initializeRateAdminRateMirrorsScreen()),
      fetchRateAdminRateMirrors: (payload) => dispatch(fetchRateAdminRateMirrors(payload)),
      deleteRateMirrorsAdminRateMirrors: (payload) => dispatch(deleteRateMirrorsAdminRateMirrors(payload)),
      exportRateAdminRateMirrors: (payload) => dispatch(exportRateAdminRateMirrors(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminRateMirrorsCreateSuccess: selectFromRateAdminRateMirrorsReducer(
        state,
        "isRateAdminRateMirrorsCreateSuccess"
      ),
      rateAdminRateMirrorsListview: selectFromRateAdminRateMirrorsReducer(
        state,
        "rateAdminRateMirrorsListview"
      ),
      showPageLevelLoader: selectFromRateAdminRateMirrorsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminRateMirrorsReducer(
        state,
        "paginationInfo"
      ),
      shouldOpenDeleteConfirmationPopup:selectFromRateAdminRateMirrorsReducer(
        state,
        "shouldOpenDeleteConfirmationPopup"
      ),
      isPageLevelError: selectFromRateAdminRateMirrorsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminRateMirrorsReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminRateMirrorsExportError: selectFromRateAdminRateMirrorsReducer(
        state,
        "isRateAdminRateMirrorsExportError"
      ),
    isRateAdminRateMirrorsDeleteSuccess: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsDeleteSuccess"
    ),
    isRateAdminRateMirrorsDeleteError: selectFromRateAdminRateMirrorsReducer(
      state,
      "isRateAdminRateMirrorsDeleteError"
    ),

      isRateAdminRateMirrorsExportSuccess: selectFromRateAdminRateMirrorsReducer(
        state,
        "isRateAdminRateMirrorsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRateAdminRateMirrorsReducer = (state, path) => {
  return selectRateAdminRateMirrorsReducer(state)[path];
};

const selectRateAdminRateMirrorsReducer = ({ rateadminRateMirrorsReducer }) => {
  return rateadminRateMirrorsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RateAdminRateMirrorsScreen);
