import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchRateAdminExtraProductCharge,
  initializeRateAdminExtraProductChargeScreen,
  setDeleteConfirmationPopupVisibility,
  exportRateAdminExtraProductCharge,
  deleteRateAdminExtraProductCharge
} from "../../../redux/actions/RateAdmin/rateadminExtraProductCharge";
import RateAdminExtraProductCharge from '../../../component/RateAdmin/RateAdminExtraProductCharge';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminExtraProductChargeScreen(props) {
  const {
    fetchRateAdminExtraProductCharge,
    initRateAdminExtraProductChargeScreen,
    showPageLevelLoader,
    rateAdminExtraProductChargeListview,
    paginationInfo,
    exportRateAdminExtraProductCharge,
    isRateAdminExtraProductChargeExportError,
    isRateAdminExtraProductChargeExportSuccess,
    deleteRateAdminExtraProductCharge,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    isRateAdminExtraProductChargeDeleteSuccess,
    isRateAdminExtraProductChargeDeleteError,
    isLoading,
  } = props;

  const navigate = useNavigate();

  const callAPIasync = async () => {
    initRateAdminExtraProductChargeScreen();
    await fetchRateAdminExtraProductCharge({ sortField: 'id' });
   
  }


  useEffect(() => {
    callAPIasync()
  }, []);
  const loadLazyData = (props) => {
    fetchRateAdminExtraProductCharge(props);
  };
  const handleCreateRateAdminExtraProductCharge = () => {
    navigate(APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.CREATE);
  }
  const editRateAdminExtraProductChargeRoute = (id) => {
    const path = generatePath(APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.EDIT, {
      id,
    });
    navigate(path);
  };
  const copyRateAdminExtraProductChargeRoute = (id) => {
    const path = generatePath(APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.COPY, {
      id,
    });
    navigate(path);
  };
  
  const rateAdminExtraProductCharge = {
    handleCreateRateAdminExtraProductCharge,
    exportRateAdminExtraProductCharge,
    isRateAdminExtraProductChargeExportError,
    isRateAdminExtraProductChargeExportSuccess,
    deleteRateAdminExtraProductCharge,
    isRateAdminExtraProductChargeDeleteSuccess,
    isRateAdminExtraProductChargeDeleteError,
    editRateAdminExtraProductChargeRoute,
    copyRateAdminExtraProductChargeRoute,
    ...companyData,
    screenPermissions: companyData?.screenPermissions,
    setDeleteConfirmationPopupVisibility,
    shouldOpenDeleteConfirmationPopup,
    fetchRateAdminExtraProductCharges: loadLazyData,
    ...companyData,
    tableData: {
      columns: rateAdminExtraProductChargeListview?.columns,
      rows: rateAdminExtraProductChargeListview?.rows,
    },
    paginationInfo: {
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };

  if (showPageLevelLoader) {
    loadingShow("block");
    return null;
  }
  loadingShow("none");
  return (
    <div>
      <RateAdminExtraProductCharge
        {...rateAdminExtraProductCharge}
      />
    </div>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    initRateAdminExtraProductChargeScreen: () => dispatch(initializeRateAdminExtraProductChargeScreen()),
    fetchRateAdminExtraProductCharge: (payload) => dispatch(fetchRateAdminExtraProductCharge(payload)),
    deleteRateAdminExtraProductCharge: (payload) => dispatch(deleteRateAdminExtraProductCharge(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    exportRateAdminExtraProductCharge: (payload) => dispatch(exportRateAdminExtraProductCharge(payload)),
  };
};


//vehicleCagegoryReducer
const mapStateToProps = (state, ownProps) => {
  return {

    isRateAdminExtraProductChargeCreateSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeCreateSuccess"
    ),
    shouldOpenDeleteConfirmationPopup: selectFromRateAdminExtraProductChargeReducer(
      state,
      "shouldOpenDeleteConfirmationPopup"
    ),
    
    rateAdminExtraProductChargeListview: selectFromRateAdminExtraProductChargeReducer(
      state,
      "rateAdminExtraProductChargeListview"
    ),
    showPageLevelLoader: selectFromRateAdminExtraProductChargeReducer(
      state,
      "showPageLevelLoader"
    ),
    paginationInfo: selectFromRateAdminExtraProductChargeReducer(
      state,
      "paginationInfo"
    ),

    isRateAdminExtraProductChargeDeleteSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeDeleteSuccess"
    ),
    isRateAdminExtraProductChargeDeleteError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeDeleteError"
    ),
    isPageLevelError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isPageLevelError"
    ),
    isFetchingGridData: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isFetchingGridData"
    ),
    isRateAdminExtraProductChargeExportError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeExportError"
    ),
    
 
    isRateAdminExtraProductChargeExportSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeExportSuccess"
    ),
    ...ownProps,
  };
};

const selectFromRateAdminExtraProductChargeReducer = (state, path) => {
  return selectRateAdminExtraProductChargeReducer(state)[path];
};

const selectRateAdminExtraProductChargeReducer = ({ rateadminExtraProductChargeReducer }) => {
  return rateadminExtraProductChargeReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(RateAdminExtraProductChargeScreen);
