import React from 'react'
import {  Routes, Route } from "react-router-dom";
import Login from '../container/Login';
import Dashboard from '../container/DashboardScreen';
import LocationScreen from '../container/LocationScreen';
import NotFound from '../NotFound';
import CreateLocationContainer from '../container/LocationScreen/CreateLocation';
import { APP_URLS } from '../utils/constant';
import VehicleCategoryScreen from '../container/Vehicles/VehicleCategoryScreen';
import CreateVehicleCategory from '../container/Vehicles/VehicleCategoryScreen/CreateVehicleCategory';
import VehicleMakeScreen from '../container/Vehicles/VehicleMakeScreen';
import CreateVehicleMake from '../container/Vehicles/VehicleMakeScreen/CreateVehicleMake';

import VehicleModelScreen from '../container/Vehicles/VehicleModelScreen';
import CreateVehicleModel from '../container/Vehicles/VehicleModelScreen/CreateVehicleModel';
import VehicleTrimScreen from '../container/Vehicles/VehicleTrimScreen';
import CreateVehicleTrim from '../container/Vehicles/VehicleTrimScreen/CreateVehicleTrim';
import VehicleTransmissionTypeScreen from '../container/Vehicles/VehicleTransmissionTypeScreen';
import CreateVehicleTransmissionType from '../container/Vehicles/VehicleTransmissionTypeScreen/CreateVehicleTransmissionType';
import VehicleAcTypeScreen from '../container/Vehicles/VehicleAcTypeScreen';
import CreateVehicleAcType from '../container/Vehicles/VehicleAcTypeScreen/CreateVehicleAcType';
import VehicleStereoTypeScreen from '../container/Vehicles/VehicleStereoTypeScreen';
import CreateVehicleStereoType from '../container/Vehicles/VehicleStereoTypeScreen/CreateVehicleStereoType';
import ResetPassword from '../container/Login/Reset';
import CreatePasswordContainer from '../container/ProfileScreen/CreateChangePassword';
import VehicleVehicleStatusScreen from '../container/Vehicles/VehicleVehicleStatusScreen';
import CreateVehicleVehicleStatus from '../container/Vehicles/VehicleVehicleStatusScreen/CreateVehicleVehicleStatus';
import VehicleVehicleOperationsScreen from '../container/Vehicles/VehicleVehicleOperationsScreen';
import CreateVehicleVehicleOperations from '../container/Vehicles/VehicleVehicleOperationsScreen/CreateVehicleVehicleOperations';
import VehicleDealershipsScreen from '../container/Vehicles/VehicleDealershipsScreen';
import CreateVehicleDealerships from '../container/Vehicles/VehicleDealershipsScreen/CreateVehicleDealerships';
import VehicleBodyShopScreen from '../container/Vehicles/VehicleBodyShopScreen';
import CreateVehicleBodyShop from '../container/Vehicles/VehicleBodyShopScreen/CreateVehicleBodyShop';
import VehicleDealershipVehicleDealScreen from '../container/Vehicles/VehicleDealershipVehicleDealScreen';
import CreateVehicleDealershipVehicleDeal from '../container/Vehicles/VehicleDealershipVehicleDealScreen/CreateVehicleDealershipVehicleDeal';
import RateAdminRateCodeScreen from '../container/RateAdminScreen/RateAdminRateCodeScreen';
import CreateRateAdminRateCode from '../container/RateAdminScreen/RateAdminRateCodeScreen/CreateRateAdminRateCode';
import RateAdminRatePlanScreen from '../container/RateAdminScreen/RateAdminRatePlanScreen';
import CreateRateAdminRatePlan from '../container/RateAdminScreen/RateAdminRatePlanScreen/CreateRateAdminRatePlan';
import RateAdminRateTypesScreen from '../container/RateAdminScreen/RateAdminRateTypesScreen';
import CreateRateAdminRateTypes from '../container/RateAdminScreen/RateAdminRateTypesScreen/CreateRateAdminRateTypes';
import RateAdminLorAdjustmentsScreen from '../container/RateAdminScreen/RateAdminLorAdjustmentsScreen';
import CreateRateAdminLorAdjustments from '../container/RateAdminScreen/RateAdminLorAdjustmentsScreen/CreateRateAdminLorAdjustments';
import CreateRateAdminRates from '../container/RateAdminScreen/RateAdminRatesScreen/CreateRateAdminRates';
import RateAdminRatesScreen from '../container/RateAdminScreen/RateAdminRatesScreen';
import RateAdminExtraProductCategoryScreen from '../container/RateAdminScreen/RateAdminExtraProductCategoryScreen';
import CreateRateAdminExtraProductCategory from '../container/RateAdminScreen/RateAdminExtraProductCategoryScreen/CreateRateAdminExtraProductCategory';
import RateAdminExtraProductChargeScreen from '../container/RateAdminScreen/RateAdminExtraProductChargeScreen';
import CreateRateAdminExtraProductCharge from '../container/RateAdminScreen/RateAdminExtraProductChargeScreen/CreateRateAdminExtraProductCharge';
import RateAdminNetworksScreen from '../container/RateAdminScreen/RateAdminNetworksScreen';
import CreateRateAdminNetworks from '../container/RateAdminScreen/RateAdminNetworksScreen/CreateRateAdminNetworks';
import RateAdminBlackoutsScreen from '../container/RateAdminScreen/RateAdminBlackoutsScreen';
import CreateRateAdminBlackouts from '../container/RateAdminScreen/RateAdminBlackoutsScreen/CreateRateAdminBlackouts';
import RateAdminRateMirrorsScreen from '../container/RateAdminScreen/RateAdminRateMirrorsScreen';
import CreateRateAdminRateMirrors from '../container/RateAdminScreen/RateAdminRateMirrorsScreen/CreateRateAdminRateMirrors';
import HolidaysScreen from '../container/HolidaysScreen';
import CreateHolidays from '../container/HolidaysScreen/CreateHolidays';
import SettingsSettingWeekendsScreen from '../container/Settings/SettingsSettingWeekendsScreen';
import CreateSettingsSettingWeekends from '../container/Settings/SettingsSettingWeekendsScreen/CreateSettingsSettingWeekends';
import SettingsPaynowDiscountsScreen from '../container/Settings/SettingsPaynowDiscountsScreen';
import CreateSettingsPaynowDiscounts from '../container/Settings/SettingsPaynowDiscountsScreen/CreateSettingsPaynowDiscounts';
import SettingsPaynowOptionsScreen from '../container/Settings/SettingsPaynowOptionsScreen';
import CreateSettingsPaynowOptions from '../container/Settings/SettingsPaynowOptionsScreen/CreateSettingsPaynowOptions';
import VehicleCategoryRouter from './vehicleModule';
import RentersRentersInfoScreen from '../container/Renters/RentersRentersInfoScreen';
import CreateRentersRentersInfo from '../container/Renters/RentersRentersInfoScreen/CreateRentersRentersInfo';
import RentersRevenueAgreementsScreen from '../container/Renters/RentersRevenueAgreementsScreen';
import CreateRentersRevenueAgreements from '../container/Renters/RentersRevenueAgreementsScreen/CreateRentersRevenueAgreements';
import RentersNonRevenueAgreementsScreen from '../container/Renters/RentersNonRevenueAgreementsScreen';
import CreateRentersNonRevenueAgreements from '../container/Renters/RentersNonRevenueAgreementsScreen/CreateRentersNonRevenueAgreements';
import RentersVehicleInquiryScreen from '../container/Renters/RentersVehicleInquiryScreen';
import CreateRentersVehicleInquiry from '../container/Renters/RentersVehicleInquiryScreen/CreateRentersVehicleInquiry';
import RentersRegisteredRentersScreen from '../container/Renters/RentersRegisteredRentersScreen';
import RentersRegisteredRentersViewScreen from '../container/Renters/RentersRegisteredRentersScreen/ViewRentersRegisteredRenters';
import CreateRentersRegisteredRenters from '../container/Renters/RentersRegisteredRentersScreen/CreateRentersRegisteredRenters';
import RentersLogInteractionsScreen from '../container/Renters/RentersLogInteractionsScreen';
import CreateRentersLogInteractions from '../container/Renters/RentersLogInteractionsScreen/CreateRentersLogInteractions';
import ReservationsReservationsScreen from '../container/Reservations/ReservationsReservationsScreen';
import CreateReservationsReservations from '../container/Reservations/ReservationsReservationsScreen/CreateReservationsReservations';
import ReservationsDetailReportScreen from '../container/Reservations/ReservationsDetailReportScreen';
import CreateReservationsDetailReport from '../container/Reservations/ReservationsDetailReportScreen/CreateReservationsDetailReport';
import ReservationsDailyManifestReportScreen from '../container/Reservations/ReservationsDailyManifestReportScreen';
import CreateReservationsDailyManifestReport from '../container/Reservations/ReservationsDailyManifestReportScreen/CreateReservationsDailyManifestReport';
import ReservationsBookingsbyLocationReportScreen from '../container/Reservations/ReservationsBookingsbyLocationReportScreen';
import CreateReservationsBookingsbyLocationReport from '../container/Reservations/ReservationsBookingsbyLocationReportScreen/CreateReservationsBookingsbyLocationReport';
import ReservationsPrepaidBookingsReportScreen from '../container/Reservations/ReservationsPrepaidBookingsReportScreen';
import CreateReservationsPrepaidBookingsReport from '../container/Reservations/ReservationsPrepaidBookingsReportScreen/CreateReservationsPrepaidBookingsReport';
import ReservationsCRXRezpowerReservationsScreen from '../container/Reservations/ReservationsCRXRezpowerReservationsScreen';
import CreateReservationsCRXRezpowerReservations from '../container/Reservations/ReservationsCRXRezpowerReservationsScreen/CreateReservationsCRXRezpowerReservations';
export default function Router(props) {
  const Update = () =>{
    props.switchHeader()
  }
  const renderVehicleCategoryRoutes = (access) => (
    <Route
      path={APP_URLS.VEHICLE_CATEGORY.HOME}
      element={<VehicleCategoryRouter access={access?.invoiceMenu} />}
    />
  );
  return (
    <Routes>
         <Route exact={true} path="/" element={<Login callBack={Update}/>} />
         <Route path="/reset" element={<ResetPassword />} />
         <Route path="/dashboard" element={<Dashboard />} />
         <Route path="/location" element={<LocationScreen  />} />
         <Route path={APP_URLS.CHANGEPASSWORD} element={<CreatePasswordContainer  />} /> 

         <Route path={APP_URLS.LOCATION.CREATE} element={<CreateLocationContainer  />} /> 
         <Route path={APP_URLS.LOCATION.EDIT} element={<CreateLocationContainer isEdit={true} />} /> 
         <Route path={APP_URLS.VEHICLE_CATEGORY.HOME} element={<VehicleCategoryScreen  />} />
         <Route path={APP_URLS.VEHICLE_CATEGORY.CREATE} element={<CreateVehicleCategory  />} />
         <Route path={APP_URLS.VEHICLE_CATEGORY.EDIT} element={<CreateVehicleCategory isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_MAKE.HOME} element={<VehicleMakeScreen  />} />
         <Route path={APP_URLS.VEHICLE_MAKE.CREATE} element={<CreateVehicleMake  />} />
         <Route path={APP_URLS.VEHICLE_MAKE.EDIT} element={<CreateVehicleMake isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_MODEL.HOME} element={<VehicleModelScreen  />} />
         <Route path={APP_URLS.VEHICLE_MODEL.CREATE} element={<CreateVehicleModel  />} />
         <Route path={APP_URLS.VEHICLE_MODEL.EDIT} element={<CreateVehicleModel isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_TRIM.HOME} element={<VehicleTrimScreen  />} />
         <Route path={APP_URLS.VEHICLE_TRIM.CREATE} element={<CreateVehicleTrim  />} />
         <Route path={APP_URLS.VEHICLE_TRIM.EDIT} element={<CreateVehicleTrim isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.HOME} element={<VehicleTransmissionTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.CREATE} element={<CreateVehicleTransmissionType  />} />
         <Route path={APP_URLS.VEHICLE_TRANSMISSIONTYPE.EDIT} element={<CreateVehicleTransmissionType isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_ACTYPE.HOME} element={<VehicleAcTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_ACTYPE.CREATE} element={<CreateVehicleAcType  />} />
         <Route path={APP_URLS.VEHICLE_ACTYPE.EDIT} element={<CreateVehicleAcType isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_STEREOTYPE.HOME} element={<VehicleStereoTypeScreen  />} />
         <Route path={APP_URLS.VEHICLE_STEREOTYPE.CREATE} element={<CreateVehicleStereoType  />} />
         <Route path={APP_URLS.VEHICLE_STEREOTYPE.EDIT} element={<CreateVehicleStereoType isEdit={true} />} />


         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.HOME} element={<VehicleVehicleStatusScreen  />} />
         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.CREATE} element={<CreateVehicleVehicleStatus   />} />
         <Route path={APP_URLS.VEHICLE_VEHICLESTATUS.EDIT} element={<CreateVehicleVehicleStatus isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.HOME} element={<VehicleVehicleOperationsScreen  />} />
         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.CREATE} element={<CreateVehicleVehicleOperations   />} />
         <Route path={APP_URLS.VEHICLE_VEHICLEOPERATIONS.EDIT} element={<CreateVehicleVehicleOperations isEdit={true} />} />

         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.HOME} element={<VehicleDealershipsScreen  />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.CREATE} element={<CreateVehicleDealerships   />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPS.EDIT} element={<CreateVehicleDealerships isEdit={true} />} />
       
       
         <Route path={APP_URLS.VEHICLE_BODYSHOP.HOME} element={<VehicleBodyShopScreen  />} />
         <Route path={APP_URLS.VEHICLE_BODYSHOP.CREATE} element={<CreateVehicleBodyShop />} />
         <Route path={APP_URLS.VEHICLE_BODYSHOP.EDIT} element={<CreateVehicleBodyShop isEdit={true} />} />
       
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.HOME} element={<VehicleDealershipVehicleDealScreen />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.CREATE} element={<CreateVehicleDealershipVehicleDeal />} />
         <Route path={APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.EDIT} element={<CreateVehicleDealershipVehicleDeal isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.HOME} element={<RateAdminRateCodeScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.CREATE} element={<CreateRateAdminRateCode />} />
         <Route path={APP_URLS.RATEADMIN_RATE_CODE.EDIT} element={<CreateRateAdminRateCode isEdit={true} />} />

         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.HOME} element={<RateAdminRatePlanScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.CREATE} element={<CreateRateAdminRatePlan />} />
         <Route path={APP_URLS.RATEADMIN_RATE_PLAN.EDIT} element={<CreateRateAdminRatePlan isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.HOME} element={<RateAdminRateTypesScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.CREATE} element={<CreateRateAdminRateTypes />} />
         <Route path={APP_URLS.RATEADMIN_RATE_TYPES.EDIT} element={<CreateRateAdminRateTypes isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.HOME} element={<RateAdminLorAdjustmentsScreen />} />
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.CREATE} element={<CreateRateAdminLorAdjustments />} />
         <Route path={APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.EDIT} element={<CreateRateAdminLorAdjustments isEdit={true} />} />
       

         <Route path={APP_URLS.RATEADMIN_RATES.HOME} element={<RateAdminRatesScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATES.CREATE} element={<CreateRateAdminRates />} />
         <Route path={APP_URLS.RATEADMIN_RATES.EDIT} element={<CreateRateAdminRates isEdit={true} />} />
         <Route path={APP_URLS.RATEADMIN_RATES.COPY} element={<CreateRateAdminRates isCopy={true} />} />

         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.HOME} element={<RateAdminExtraProductCategoryScreen />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.CREATE} element={<CreateRateAdminExtraProductCategory />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.EDIT} element={<CreateRateAdminExtraProductCategory isEdit={true} />} />
         
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.HOME} element={<RateAdminExtraProductChargeScreen />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.CREATE} element={<CreateRateAdminExtraProductCharge />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.EDIT} element={<CreateRateAdminExtraProductCharge isEdit={true} />} />
         <Route path={APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.COPY} element={<CreateRateAdminExtraProductCharge isCopy={true} />} />
         
         <Route path={APP_URLS.RATEADMIN_NETWORK.HOME} element={<RateAdminNetworksScreen />} />
         <Route path={APP_URLS.RATEADMIN_NETWORK.CREATE} element={<CreateRateAdminNetworks />} />
         <Route path={APP_URLS.RATEADMIN_NETWORK.EDIT} element={<CreateRateAdminNetworks isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.HOME} element={<RateAdminBlackoutsScreen />} />
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.CREATE} element={<CreateRateAdminBlackouts />} />
         <Route path={APP_URLS.RATEADMIN_BLACKOUTS.EDIT} element={<CreateRateAdminBlackouts isEdit={true} />} />
       
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.HOME} element={<RateAdminRateMirrorsScreen />} />
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.CREATE} element={<CreateRateAdminRateMirrors />} />
         <Route path={APP_URLS.RATEADMIN_RATEMIRRORS.EDIT} element={<CreateRateAdminRateMirrors isEdit={true} />} />
        
         <Route path={APP_URLS.HOLIDAYS.HOME} element={<HolidaysScreen />} />
         <Route path={APP_URLS.HOLIDAYS.CREATE} element={<CreateHolidays />} />
         <Route path={APP_URLS.HOLIDAYS.EDIT} element={<CreateHolidays isEdit={true} />} />

         <Route path={APP_URLS.SETTINGS_SETTINGWEEKENDS.HOME} element={<SettingsSettingWeekendsScreen />} />
         <Route path={APP_URLS.SETTINGS_SETTINGWEEKENDS.CREATE} element={<CreateSettingsSettingWeekends />} />
         <Route path={APP_URLS.SETTINGS_SETTINGWEEKENDS.EDIT} element={<CreateSettingsSettingWeekends isEdit={true} />} />
        
         <Route path={APP_URLS.SETTINGS_PAYNOWDISCOUNTS.HOME} element={<SettingsPaynowDiscountsScreen />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWDISCOUNTS.CREATE} element={<CreateSettingsPaynowDiscounts />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWDISCOUNTS.EDIT} element={<CreateSettingsPaynowDiscounts isEdit={true} />} />
        
         <Route path={APP_URLS.SETTINGS_PAYNOWOPTIONS.HOME} element={<SettingsPaynowOptionsScreen />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWOPTIONS.CREATE} element={<CreateSettingsPaynowOptions />} />
         <Route path={APP_URLS.SETTINGS_PAYNOWOPTIONS.EDIT} element={<CreateSettingsPaynowOptions isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_RENTERSINFO.HOME} element={<RentersRentersInfoScreen />} />
         <Route path={APP_URLS.RENTERS_RENTERSINFO.CREATE} element={<CreateRentersRentersInfo />} />
         <Route path={APP_URLS.RENTERS_RENTERSINFO.EDIT} element={<CreateRentersRentersInfo isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_REVENUEAGREEMENTS.HOME} element={<RentersRevenueAgreementsScreen />} />
         <Route path={APP_URLS.RENTERS_REVENUEAGREEMENTS.CREATE} element={<CreateRentersRevenueAgreements />} />
         <Route path={APP_URLS.RENTERS_REVENUEAGREEMENTS.EDIT} element={<CreateRentersRevenueAgreements isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_NONREVENUEAGREEMENTS.HOME} element={<RentersNonRevenueAgreementsScreen />} />
         <Route path={APP_URLS.RENTERS_NONREVENUEAGREEMENTS.CREATE} element={<CreateRentersNonRevenueAgreements />} />
         <Route path={APP_URLS.RENTERS_NONREVENUEAGREEMENTS.EDIT} element={<CreateRentersNonRevenueAgreements isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_VEHICLEINQUIRY.HOME} element={<RentersVehicleInquiryScreen />} />
         <Route path={APP_URLS.RENTERS_VEHICLEINQUIRY.CREATE} element={<CreateRentersVehicleInquiry />} />
         <Route path={APP_URLS.RENTERS_VEHICLEINQUIRY.EDIT} element={<CreateRentersVehicleInquiry isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.HOME} element={<RentersRegisteredRentersScreen />} />
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.CREATE} element={<CreateRentersRegisteredRenters />} />
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.VIEW} element={<RentersRegisteredRentersViewScreen />} />
         <Route path={APP_URLS.RENTERS_REGISTEREDRENTERS.EDIT} element={<CreateRentersRegisteredRenters isEdit={true} />} />
        
         <Route path={APP_URLS.RENTERS_LOGINTERACTIONS.HOME} element={<RentersLogInteractionsScreen />} />
         <Route path={APP_URLS.RENTERS_LOGINTERACTIONS.CREATE} element={<CreateRentersLogInteractions />} />
         <Route path={APP_URLS.RENTERS_LOGINTERACTIONS.EDIT} element={<CreateRentersLogInteractions isEdit={true} />} />
        

         <Route path={APP_URLS.RESERVATIONS_RESERVATIONS.HOME} element={<ReservationsReservationsScreen />} />
         <Route path={APP_URLS.RESERVATIONS_RESERVATIONS.CREATE} element={<CreateReservationsReservations />} />
         <Route path={APP_URLS.RESERVATIONS_RESERVATIONS.EDIT} element={<CreateReservationsReservations isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_DETAILREPORT.HOME} element={<ReservationsDetailReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_DETAILREPORT.CREATE} element={<CreateReservationsDetailReport />} />
         <Route path={APP_URLS.RESERVATIONS_DETAILREPORT.EDIT} element={<CreateReservationsDetailReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.HOME} element={<ReservationsDailyManifestReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.CREATE} element={<CreateReservationsDailyManifestReport />} />
         <Route path={APP_URLS.RESERVATIONS_DAILYMANIFESTREPORT.EDIT} element={<CreateReservationsDailyManifestReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.HOME} element={<ReservationsBookingsbyLocationReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.CREATE} element={<CreateReservationsBookingsbyLocationReport />} />
         <Route path={APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.EDIT} element={<CreateReservationsBookingsbyLocationReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_PREPAIDBOOKINGSREPORT.HOME} element={<ReservationsPrepaidBookingsReportScreen />} />
         <Route path={APP_URLS.RESERVATIONS_PREPAIDBOOKINGSREPORT.CREATE} element={<CreateReservationsPrepaidBookingsReport />} />
         <Route path={APP_URLS.RESERVATIONS_PREPAIDBOOKINGSREPORT.EDIT} element={<CreateReservationsPrepaidBookingsReport isEdit={true} />} />
        
         <Route path={APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.HOME} element={<ReservationsCRXRezpowerReservationsScreen />} />
         <Route path={APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.CREATE} element={<CreateReservationsCRXRezpowerReservations />} />
         <Route path={APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.EDIT} element={<CreateReservationsCRXRezpowerReservations isEdit={true} />} />
        
         <Route path="*" element={ <NotFound />} />
    </Routes>
  )
}
