import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminNetworks,
    initializeRateAdminNetworksScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminNetworks
  } from "../../../redux/actions/RateAdmin/rateadminNetworks";
import RateAdminNetworks from '../../../component/RateAdmin/RateAdminNetworks';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminNetworksScreen(props) {
    const {
        fetchRateAdminNetworks,
        initRateAdminNetworksScreen,
        showPageLevelLoader,
        rateAdminNetworksListview,
        paginationInfo,
        exportRateAdminNetworks,
        isRateAdminNetworksExportError,
        isRateAdminNetworksExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminNetworksScreen();
        fetchRateAdminNetworks({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchRateAdminNetworks(props);
      };
      const handleCreateRateAdminNetworks =() =>{
      navigate(APP_URLS.RATEADMIN_NETWORK.CREATE);
    }
      const editRateAdminNetworksRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_NETWORK.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminNetworks = {
  handleCreateRateAdminNetworks,
  exportRateAdminNetworks,
        isRateAdminNetworksExportError,
        isRateAdminNetworksExportSuccess,
  editRateAdminNetworksRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminNetworkss: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminNetworksListview?.columns,
    rows: rateAdminNetworksListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminNetworks
    {...rateAdminNetworks}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminNetworksScreen: () => dispatch(initializeRateAdminNetworksScreen()),
      fetchRateAdminNetworks: (payload) => dispatch(fetchRateAdminNetworks(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRateAdminNetworks: (payload) => dispatch(exportRateAdminNetworks(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminNetworksCreateSuccess: selectFromRateAdminNetworksReducer(
        state,
        "isRateAdminNetworksCreateSuccess"
      ),
      rateAdminNetworksListview: selectFromRateAdminNetworksReducer(
        state,
        "rateAdminNetworksListview"
      ),
      showPageLevelLoader: selectFromRateAdminNetworksReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminNetworksReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRateAdminNetworksReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminNetworksReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminNetworksExportError: selectFromRateAdminNetworksReducer(
        state,
        "isRateAdminNetworksExportError"
      ),
      isRateAdminNetworksExportSuccess: selectFromRateAdminNetworksReducer(
        state,
        "isRateAdminNetworksExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRateAdminNetworksReducer = (state, path) => {
  return selectRateAdminNetworksReducer(state)[path];
};

const selectRateAdminNetworksReducer = ({ rateadminNetworksReducer }) => {
  return rateadminNetworksReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RateAdminNetworksScreen);
