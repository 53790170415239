import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import {
  initCreateHolidaysScreen,
  createHolidays,
  editHolidays,
  fetchHolidaysById,
  uploadImage,
} from "../../../redux/actions/Holidays";


import {
  fetchLocationDD,
} from "../../../redux/actions/location";

import { useEffect } from "react";
import { setIsformDirty } from "../../../redux/actions/common/form";
import CreateHolidaysComponent from "../../../component/Holidays/CreateHolidays";
import { useParams } from "react-router-dom";

const CreateHolidaysContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createHolidays,
    editHolidays,
    isLoading,
    isHolidaysCreateSuccess,
    isHolidaysCreateError,
    isHolidaysDetailSuccess,
    isHolidaysDetailError,
    isHolidaysEditSuccess,
    isHolidaysEditError,
    isEdit,
    isView,
    initCreateHolidaysScreen,
    formFieldValueMap,
    fetchHolidaysById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        locationDD,
        fetchLocationDD,
  
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    await fetchLocationDD();
    initCreateHolidaysScreen();
   if (isEdit) {
    await fetchHolidaysById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createHolidaysProps = {
    createHolidays,
    initCreateHolidaysScreen,
    editHolidays,
    isLoading,
    isEdit,
    isHolidaysDetailSuccess,
    isHolidaysCreateSuccess,
    isHolidaysEditSuccess,
    isHolidaysEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        locationDD,
       
  };
  const getToastProps = () => {
    if (isHolidaysCreateSuccess || isHolidaysEditSuccess) {
      const toastTitle = isEdit
        ? "Holidays Updated Successfully"
        : "Holidays Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isHolidaysCreateError ||
      isHolidaysDetailError ||
      isHolidaysEditError
    ) {
      let toastTitle = "Error while Creating Rate Plan";
      if (isHolidaysEditError) {
        toastTitle = "Error while updating Rate Plan";
      } else if (isHolidaysDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateHolidaysComponent {...createHolidaysProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromHolidaysReducer(state, "isLoading"),
   
    isHolidaysCreateSuccess: selectFromHolidaysReducer(
      state,
      "isHolidaysCreateSuccess"
    ),
    isHolidaysCreateError: selectFromHolidaysReducer(
      state,
      "isHolidaysCreateError"
    ),
    isHolidaysDetailSuccess: selectFromHolidaysReducer(
      state,
      "isHolidaysDetailSuccess"
    ),
    isHolidaysDetailError: selectFromHolidaysReducer(
      state,
      "isHolidaysDetailError"
    ),
    isHolidaysEditSuccess: selectFromHolidaysReducer(
      state,
      "isHolidaysEditSuccess"
    ),
    isHolidaysEditError: selectFromHolidaysReducer(
      state,
      "isHolidaysEditError"
    ),
    imageUploadResponse: selectFromHolidaysReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromHolidaysReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromHolidaysReducer(
      state,
      "isUploadImageError"
    ),
  
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
   
   
    formFieldValueMap: selectFromHolidaysReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateHolidaysScreen: () => dispatch(initCreateHolidaysScreen()),
    createHolidays: (payload) => dispatch(createHolidays(payload)),
    editHolidays: (payload) => dispatch(editHolidays(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchHolidaysById: (payload) =>
      dispatch(fetchHolidaysById(payload)),

  };
};

const selectFromHolidaysReducer = (state, path) => {
  return selectHolidaysReducer(state)[path];
};

const selectHolidaysReducer = ({ holidaysReducer }) => {
  return holidaysReducer;
};

//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateHolidaysContainer);
