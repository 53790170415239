//import { post, put } from "axios";
import axios from 'axios';
import { getApiAsyn, baseUrl, getApiAsynarrayBuffer, deleteApi } from "../../../PostAPI";
import { handleAPIError, APIError } from "../../../common/errorHandler";
import { API_URL } from "../../../../utils/constant";
import { extractData } from "../../../../utils";
import { decorateWithPaginationParamsPost, decorateWithSortParamsPost, getDecoratedUrl } from "../../../common/urlService";


export const getPayloadInfo = (res, key) => {
  if(res && res.length>0){
  return res.find((item) => item.filterField === key)?.query;
  }
};
const { post, put, get } = axios;



export const fetchRateAdminRateAdjustment = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.GETRATEADJUST.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
export const fetchRateAdminRates = async (payload) => {
  console.log("RATE",payload);
  const url = getDecoratedUrl({
    url: API_URL.RATEADMIN_RATES.GET,
    payload,
  });
  let sortdata = decorateWithSortParamsPost(payload);
  if(sortdata===""){
    sortdata="id,desc"
  }
 
   let rateCodeId = getPayloadInfo(payload?.filters, "rateCodeTitle");
   let locationId  = getPayloadInfo(payload?.filters, "locationTitle");
   let networkId  = getPayloadInfo(payload?.filters, "networkTitle");
   let categoryId  = getPayloadInfo(payload?.filters, "categoryTitle");
   let rateOptionId  = getPayloadInfo(payload?.filters, "rateOptionTitle");
   let rateTypeId  = getPayloadInfo(payload?.filters, "rateType");
   let pageno = decorateWithPaginationParamsPost(payload);
  
  let payloads={
    paged: true,
    pageNumber: pageno?.pageNumber,
    pageSize: pageno?.pageSize,
    userId:localStorage.getItem("userId"),
    sort: sortdata,
    rateCodeId,
    locationId,
    networkId,
    categoryId,
    rateOptionId,
    rateTypeId
  }
  try {
    //let result = await getApiAsyn(url, localStorage.getItem("token"));
    let result = await dataUploadApi(
      `${API_URL.RATEADMIN_RATES.GET}`,
      payloads
    );
    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchRateAdminRatesDropdown = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.DROPDOWN;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchRateAdminNetworkDropdown = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.RATENETWORKDD;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchRateAdminRateOptionsDropdown = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.RATEOPTIONDD;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
export const multipartDataUploadImage = (url, payload) => {
 
  const formPayload = new FormData();
  formPayload.append("file", payload?.file[0]);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: localStorage.getItem("token"),
    },
  };
  return post(baseUrl + url, formPayload, config);
};



export const upladImageData = async (payload) => {
  const url = API_URL.UPLOAD_IMAGE.replace(
    ":id",
    payload.id
  );
  try {
    let result = await multipartDataUploadImage(
      url,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
const dataUploadApi = (url, data, isEdit = false) => {
  // const formPayload = new FormData();
  // // data part of multipart data
  // const json = JSON.stringify(data);
  // const blobData = new Blob([json], {
  //   type: 'application/json',
  // });
  // formPayload.append('data', blobData);

  // // file part of multipart data
  // files?.forEach((file) => {
  //   formPayload.append('file', file);
  // });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };

  if (isEdit) {
    return post(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createRateAdminRates = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.RATEADMIN_RATES.CREATE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editRateAdminRates = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.EDIT.replace(":id", payload?.id);

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



export const createRateAdminRateAdjustment = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.RATEADMIN_RATES.SAVERATEADJUST}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};


const dataExportApi = (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };
    return get(baseUrl + url, data, config);
};

export const exportRateAdminRatesRecord = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.EXPORT;
  try {
    let result = await getApiAsynarrayBuffer(url+"?userId="+ localStorage.getItem("userId"), localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};




export const fetchRateAdminRatesById = async (payload) => {
  const url = API_URL.RATEADMIN_RATES.GET_BY_ID.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};


export const deleteRateAdminRates = async (payload) => {
  try {
     const url = API_URL.RATEADMIN_RATES.DELETE.replace(
      ":id",
      payload?.id
    );

      let result = await deleteApi(url, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
}; 


export const fetchRateTest = async (payload) => {
  let url = API_URL.RATEADMIN_RATES.GETRATETEST;
  url += `?endDate=${payload?.endDate}&endTime=${payload?.endTime}&rateId=${payload?.rateId}&startDate=${payload?.startDate}&startTime=${payload?.startTime}`;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};