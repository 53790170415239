

export const FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST = {
    START: 'FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST_START',
    SUCCESS: 'FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST_SUCCESS',
    ERROR: 'FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_INIT="FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SCREEN =
'INIT_CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SCREEN';

export const CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS = {
START: 'CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_START',
SUCCESS: 'CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SUCCESS',
ERROR: 'CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_ERROR',
};

export const EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS = {
START: 'EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS_START',
SUCCESS: 'EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS_SUCCESS',
ERROR: 'EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS_ERROR',
};

export const FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL = {
  START: 'FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL_START',
  SUCCESS: 'FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL_ERROR',
};
export const EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS = {
  START: 'EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS_START',
  SUCCESS: 'EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS_SUCCESS',
  ERROR: 'EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS_ERROR',
};
export const RESERVATIONS_CRXREZPOWERRESERVATIONSDD = {
  START: 'RESERVATIONS_CRXREZPOWERRESERVATIONSDD_START',
  SUCCESS: 'RESERVATIONS_CRXREZPOWERRESERVATIONSDD_SUCCESS',
  ERROR: 'RESERVATIONS_CRXREZPOWERRESERVATIONSDD_ERROR',
};
export const RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE = {
  START: 'RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE_START',
  SUCCESS: 'RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE_SUCCESS',
  ERROR: 'RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE_ERROR',
};

