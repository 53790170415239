import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATEMIRRORS_LIST, 
  TOAST, 
  FETCH_RATEADMIN_RATEMIRRORS_INIT,
  FETCH_RATEADMIN_RATEMIRRORS_DETAIL,
  EXPORT_RATEADMIN_RATEMIRRORS,
  CREATE_RATEADMIN_RATEMIRRORS,
  EDIT_RATEADMIN_RATEMIRRORS,
  INIT_CREATE_RATEADMIN_RATEMIRRORS_SCREEN,
  RATEADMIN_RATEMIRRORSDD,
  RATEADMIN_RATEMIRRORSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/rateadminratemirrors.type";
 
  const formFieldValueMap = {
    id:null,
  fkLocationFrom: 0,
  fkLocationTo: 0,
  fkNetworkFrom: 0,
  fkNetworkTo: 0,
  fkRateGroupFrom: 0,
  fkRateGroupTo: 0,
  fkRateTypeIdFrom: 0,
  fkRateTypeIdTo: 0,
  fkVehicleClassFrom: 0,
  fkVehicleClassTo: 0,
  flatRate: 0,
  ip:"",
  modBy:"",
  modTime:"",
  notes:"",
  percentage: 0,
  status:1,
  userId: 0
  };
  
  const INITIAL_STATE = {
    rateAdminRateMirrorsListview: [],
    RateMirrorsDD:[],
    isRateAdminRateMirrorsDDSuccess: false,
    isRateAdminRateMirrorsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRateAdminRateMirrorsCreateSuccess: false,
    isRateAdminRateMirrorsCreateError: false,
    isRateAdminRateMirrorsDetailSuccess: false,
    isRateAdminRateMirrorsDetailError: false,
    isRateAdminRateMirrorsEditSuccess: false,
    isRateAdminRateMirrorsEditError: false,
    isRateAdminRateMirrorsExportSuccess: false,
    isRateAdminRateMirrorsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isRateAdminRateMirrorsDeleteSuccess: false,
    isRateAdminRateMirrorsDeleteError: false,
  };

  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        status,
        fromLocTitle,
        toLocTitle,
        fromNetworkTitle,
        toNetworkTitle,
        fromRateCode,
        toRateCode,
        fromCategoryTitle,
        toCategoryTitle,
        percentage,
        flatRate,
        notes, 
        crtBy,
        crtTime,
        modBy,
        modTime,
        fromRateType,
        toRateType
      } = item;
  
      const transformedValues = {
        id,
    status,
    fromLocTitle,
    toLocTitle,
    fromNetworkTitle,
    toNetworkTitle,
    fromRateCode,
    toRateCode,
    fromCategoryTitle,
    toCategoryTitle,
    percentage,
    flatRate,
    notes, 
    crtBy,
    crtTime,
    modBy,
    modTime,
    fromRateType,
    toRateType
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location To",
        dataKey: "toLocTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },{
        colLabel: "Network To",
        dataKey: "toNetworkTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code To",
        dataKey: "toRateCode",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Vehicle Class To",
        dataKey: "toCategoryTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Type To",
        dataKey: "toRateType",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location From",
        dataKey: "fromLocTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network From",
        dataKey: "fromNetworkTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code From",
        dataKey: "fromRateCode",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Vehicle Class From",
        dataKey: "fromCategoryTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Type From",
        dataKey: "fromRateType",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Percentage",
        dataKey: "percentage",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Flat Rate",
        dataKey: "flatRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Notes",
        dataKey: "notes",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminRateMirrorsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_RATEMIRRORS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_RATEMIRRORS_DETAIL.START:
      case EXPORT_RATEADMIN_RATEMIRRORS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_RATEMIRRORS_LIST.START:
      case CREATE_RATEADMIN_RATEMIRRORS.START:
        case EDIT_RATEADMIN_RATEMIRRORS.START:
          case RATEADMIN_RATEMIRRORSDD.START:
          case RATEADMIN_RATEMIRRORSDELETE.START:
        return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_RATEMIRRORS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          rateAdminRateMirrorsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminRateMirrorsCreateSuccess: false,
          isRateAdminRateMirrorsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          rateAdminRateMirrorsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_RATEMIRRORS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminRateMirrorsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminRateMirrorsDeletionSuccess: false,
          israteadminRateMirrorsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_RATEMIRRORS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];

              if(key==="endDate"){
                temp[key] = new Date(action.payload[key])
              }
              if(key==="startDate"){
                temp[key] = new Date(action.payload[key])
              }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRateAdminRateMirrorsDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_RATEMIRRORS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateMirrorsDetailSuccess: false,
        isRateAdminRateMirrorsDetailError: true,
      };
    }

    //DD
    case RATEADMIN_RATEMIRRORSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RateMirrorsDD:temp,
        isRateAdminRateMirrorsDDSuccess: true,
        isRateAdminRateMirrorsDDError: false,
      };
    }
    case RATEADMIN_RATEMIRRORSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateMirrorsDDSuccess: false,
        isRateAdminRateMirrorsDDError: true,
      };
    }
    //END DD
      
  //DELETE

  case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
    const shouldOpenDeleteConfirmationPopup = action.payload;
    return {
      ...state,
      shouldOpenDeleteConfirmationPopup,
      isRateAdminRateMirrorsDeleteSuccess: false,
      isRateAdminRateMirrorsDeleteError: false,
    };
  }
  case RATEADMIN_RATEMIRRORSDELETE.SUCCESS: {
    const temp = action.payload;

    return {
      ...state,
      isLoading: false,
      RatesDD:temp,
      isRateAdminRateMirrorsDeleteSuccess: true,
      isRateAdminRateMirrorsDeleteError: false,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  case RATEADMIN_RATEMIRRORSDELETE.ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      error,
      isRateAdminRatesDeleteSuccess: false,
      isRateAdminRatesDeleteError: true,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  //END DD
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RATEADMIN_RATEMIRRORS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminRateMirrorsExportSuccess: false,
    isRateAdminRateMirrorsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_RATEMIRRORS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"rate-mirrors.xls");
   return {
    ...state,
    isRateAdminRateMirrorsExportSuccess: true,
    isRateAdminRateMirrorsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_RATEMIRRORS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateMirrorsEditSuccess: false,
    isRateAdminRateMirrorsEditError: true,
  };
}

case EDIT_RATEADMIN_RATEMIRRORS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateMirrorsEditSuccess: true,
    isRateAdminRateMirrorsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_RATEMIRRORS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_RATEMIRRORS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateMirrorsCreateSuccess: false,
    isRateAdminRateMirrorsCreateError: true,
  };
}
case CREATE_RATEADMIN_RATEMIRRORS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateMirrorsCreateSuccess: true,
    isRateAdminRateMirrorsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminRateMirrorsCreateSuccess: false,
          isRateAdminRateMirrorsCreateError: false,
          isRateAdminRateMirrorsEditError: false,
          isRateAdminRateMirrorsEditSuccess: false,
          isRateAdminRateMirrorsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  