import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminRatePlanScreen,
  createRateAdminRatePlan,
  editRateAdminRatePlan,
  fetchRateAdminRatePlanById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminRatePlan";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminRatePlanComponent from "../../../../component/RateAdmin/RateAdminRatePlan/CreateRateAdminRatePlan";
import { useParams } from "react-router-dom";

const CreateRateAdminRatePlanContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminRatePlan,
    editRateAdminRatePlan,
    isLoading,
    isRateAdminRatePlanCreateSuccess,
    isRateAdminRatePlanCreateError,
    isRateAdminRatePlanDetailSuccess,
    isRateAdminRatePlanDetailError,
    isRateAdminRatePlanEditSuccess,
    isRateAdminRatePlanEditError,
    isEdit,
    isView,
    initCreateRateAdminRatePlanScreen,
    formFieldValueMap,
    fetchRateAdminRatePlanById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminRatePlanScreen();
   if (isEdit) {
    await fetchRateAdminRatePlanById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminRatePlanProps = {
    createRateAdminRatePlan,
    initCreateRateAdminRatePlanScreen,
    editRateAdminRatePlan,
    isLoading,
    isEdit,
    isRateAdminRatePlanDetailSuccess,
    isRateAdminRatePlanCreateSuccess,
    isRateAdminRatePlanEditSuccess,
    isRateAdminRatePlanEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRateAdminRatePlanCreateSuccess || isRateAdminRatePlanEditSuccess) {
      const toastTitle = isEdit
        ? "Rate Plan Updated Successfully"
        : "Rate Plan Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminRatePlanCreateError ||
      isRateAdminRatePlanDetailError ||
      isRateAdminRatePlanEditError
    ) {
      let toastTitle = "Error while Creating Rate Plan";
      if (isRateAdminRatePlanEditError) {
        toastTitle = "Error while updating Rate Plan";
      } else if (isRateAdminRatePlanDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminRatePlanComponent {...createRateAdminRatePlanProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminRatePlanReducer(state, "isLoading"),
   
    isRateAdminRatePlanCreateSuccess: selectFromRateAdminRatePlanReducer(
      state,
      "isRateAdminRatePlanCreateSuccess"
    ),
    isRateAdminRatePlanCreateError: selectFromRateAdminRatePlanReducer(
      state,
      "isRateAdminRatePlanCreateError"
    ),
    isRateAdminRatePlanDetailSuccess: selectFromRateAdminRatePlanReducer(
      state,
      "isRateAdminRatePlanDetailSuccess"
    ),
    isRateAdminRatePlanDetailError: selectFromRateAdminRatePlanReducer(
      state,
      "isRateAdminRatePlanDetailError"
    ),
    isRateAdminRatePlanEditSuccess: selectFromRateAdminRatePlanReducer(
      state,
      "isRateAdminRatePlanEditSuccess"
    ),
    isRateAdminRatePlanEditError: selectFromRateAdminRatePlanReducer(
      state,
      "isRateAdminRatePlanEditError"
    ),
    imageUploadResponse: selectFromRateAdminRatePlanReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminRatePlanReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminRatePlanReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminRatePlanReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminRatePlanReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminRatePlanReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRateAdminRatePlanReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminRatePlanScreen: () => dispatch(initCreateRateAdminRatePlanScreen()),
    createRateAdminRatePlan: (payload) => dispatch(createRateAdminRatePlan(payload)),
    editRateAdminRatePlan: (payload) => dispatch(editRateAdminRatePlan(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminRatePlanById: (payload) =>
      dispatch(fetchRateAdminRatePlanById(payload)),

  };
};

const selectFromRateAdminRatePlanReducer = (state, path) => {
  return selectRateAdminRatePlanReducer(state)[path];
};

const selectRateAdminRatePlanReducer = ({ rateadminRatePlanReducer }) => {
  return rateadminRatePlanReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminRatePlanContainer);
