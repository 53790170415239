import { Box} from '@mui/material'
import React from 'react'
import styleCss from './style.module.css'

export default function Footer() {
  return (
    <React.Fragment>
    <Box className={styleCss.copyright}>
    © {new Date().getFullYear()} Rez Revolutions. All Rights Reserved. Powered By <a className={styleCss.cp} target='_blank' href='https://www.digifyseocompany.com/'>Digify Seo Company</a>
    </Box>
    </React.Fragment>
  )
}
