

export const FETCH_VEHICLE_MODEL_LIST = {
    START: 'FETCH_VEHICLE_MODEL_LIST_START',
    SUCCESS: 'FETCH_VEHICLE_MODEL_LIST_SUCCESS',
    ERROR: 'FETCH_VEHICLE_MODEL_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_VEHICLE_MODEL_INIT="FETCH_VEHICLE_MODEL_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_VEHICLE_MODEL_SCREEN =
'INIT_CREATE_VEHICLE_MODEL_SCREEN';

export const CREATE_VEHICLE_MODEL = {
START: 'CREATE_VEHICLE_MODEL_START',
SUCCESS: 'CREATE_VEHICLE_MODEL_SUCCESS',
ERROR: 'CREATE_VEHICLE_MODEL_ERROR',
};

export const EDIT_VEHICLE_MODEL = {
START: 'EDIT_VEHICLE_MODEL_START',
SUCCESS: 'EDIT_VEHICLE_MODEL_SUCCESS',
ERROR: 'EDIT_VEHICLE_MODEL_ERROR',
};

export const FETCH_VEHICLE_MODEL_DETAIL = {
  START: 'FETCH_VEHICLE_MODEL_DETAIL_START',
  SUCCESS: 'FETCH_VEHICLE_MODEL_DETAIL_SUCCESS',
  ERROR: 'FETCH_VEHICLE_MODEL_DETAIL_ERROR',
};
export const EXPORT_VEHICLE_MODEL = {
  START: 'EXPORT_VEHICLE_MODEL_START',
  SUCCESS: 'EXPORT_VEHICLE_MODEL_SUCCESS',
  ERROR: 'EXPORT_VEHICLE_MODEL_ERROR',
};
export const VEHICLE_MODELDD = {
  START: 'VEHICLE_MODELDD_START',
  SUCCESS: 'VEHICLE_MODELDD_SUCCESS',
  ERROR: 'VEHICLE_MODELDD_ERROR',
};