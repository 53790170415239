export const basicDetail =
[
  {
    name:"startDate",
     "rule": {
        "required": true
      },
    label:"Start Date",
    type:"date"
  },
  {
    name:"endDate",
     "rule": {
        "required": true
      },
    label:"End Date",
    type:"date"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]
export const weekDays =
[
  {
    name:"mon",
     "rule": {
        "required": true
      },
    label:"Monday",
    type:"checkbox",
    isChecked:false,
    value:1
  },
  {
    name:"tue",
     "rule": {
        "required": true
      },
    label:"Tuesday",
    type:"checkbox",
    isChecked:false,
    value:2
  },
  {
    name:"wed",
     "rule": {
        "required": true
      },
    label:"Wednesday",
    type:"checkbox",
    isChecked:false,
    value:3
  },
  {
    name:"thu",
     "rule": {
        "required": true
      },
    label:"Thursday",
    type:"checkbox",
    isChecked:false,
    value:4
  },
  {
    name:"fri",
     "rule": {
        "required": true
      },
    label:"Friday",
    type:"checkbox",
    isChecked:false,
    value:5
  },
  {
    name:"sat",
     "rule": {
        "required": true
      },
    label:"Saturday",
    type:"checkbox",
    isChecked:false,
    value:6
  },
  {
    name:"sun",
     "rule": {
        "required": true
      },
    label:"Sunday",
    type:"checkbox",
    isChecked:false,
    value:7
  },
]
export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

  export const SettingsSettingWeekendsBreadcrumb = [
   
            {label:"Dashboard", path:"/dashboard"},
            {label:"Days of Week", path:"/days-of-week"},
            {label:"Paynow Discounts", path:"/pay-now-discount"},
            {label:"Pay Now Option", path:"/pay-now-option"},
            {label:"", path:"/pay-now-option"}

  ]