import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../utils/table/gridUtils";
import {
  TOAST,
  CHANGEPASSWORD_INIT,
  CHANGEPASSWORD
} from "../../types/changepassword.type";


  const formFieldValueMap = {
    password: "",
    confirmPassword:"",
    userId:""
  };
  
  const INITIAL_STATE = {
   
    isLoading: false,
    error: null,
    formFieldValueMap,
    isCreatePasswordSuccess: false,
    isCreatePasswordError: false,
  
  };
  

  export const changepasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CHANGEPASSWORD_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
    
      case CHANGEPASSWORD.START:
        return { ...state, isLoading: true };
  
    
case CHANGEPASSWORD.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isCreatePasswordSuccess: false,
    isCreatePasswordError: true,
  };
}
case CHANGEPASSWORD.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isCreatePasswordSuccess: true,
    isCreatePasswordError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isCreatePasswordSuccess: false,
          isCreatePasswordError: false,
        };
      }
      default:
        return state;
    }
  };
  