import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminLorAdjustments,
    initializeRateAdminLorAdjustmentsScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminLorAdjustments
  } from "../../../redux/actions/RateAdmin/rateadminLorAdjustments";

import RateAdminLorAdjustments from '../../../component/RateAdmin/RateAdminLorAdjustments';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminLorAdjustmentsScreen(props) {
    const {
        fetchRateAdminLorAdjustments,
        initRateAdminLorAdjustmentsScreen,
        showPageLevelLoader,
        rateAdminLorAdjustmentsListview,
        paginationInfo,
        exportRateAdminLorAdjustments,
        isRateAdminLorAdjustmentsExportError,
        isRateAdminLorAdjustmentsExportSuccess,
       
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminLorAdjustmentsScreen();
        fetchRateAdminLorAdjustments({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchRateAdminLorAdjustments(props);
      };
      const handleCreateRateAdminLorAdjustments =() =>{
      navigate(APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.CREATE);
    }
      const editRateAdminLorAdjustmentsRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_LOR_ADJUSTMENTS.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminLorAdjustments = {
  handleCreateRateAdminLorAdjustments,
  exportRateAdminLorAdjustments,
        isRateAdminLorAdjustmentsExportError,
        isRateAdminLorAdjustmentsExportSuccess,
  editRateAdminLorAdjustmentsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminLorAdjustmentss: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminLorAdjustmentsListview?.columns,
    rows: rateAdminLorAdjustmentsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminLorAdjustments
    {...rateAdminLorAdjustments}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminLorAdjustmentsScreen: () => dispatch(initializeRateAdminLorAdjustmentsScreen()),
      fetchRateAdminLorAdjustments: (payload) => dispatch(fetchRateAdminLorAdjustments(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRateAdminLorAdjustments: (payload) => dispatch(exportRateAdminLorAdjustments(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminLorAdjustmentsCreateSuccess: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "isRateAdminLorAdjustmentsCreateSuccess"
      ),
      rateAdminLorAdjustmentsListview: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "rateAdminLorAdjustmentsListview"
      ),
      showPageLevelLoader: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminLorAdjustmentsExportError: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "isRateAdminLorAdjustmentsExportError"
      ),
      isRateAdminLorAdjustmentsExportSuccess: selectFromRateAdminLorAdjustmentsReducer(
        state,
        "isRateAdminLorAdjustmentsExportSuccess"
      ),
     

      ...ownProps,
    };
  };

const selectFromRateAdminLorAdjustmentsReducer = (state, path) => {
  return selectRateAdminLorAdjustmentsReducer(state)[path];
};

const selectRateAdminLorAdjustmentsReducer = ({ rateadminLorAdjustmentsReducer }) => {
  return rateadminLorAdjustmentsReducer;
};



export default connect(mapStateToProps, mapDispatchToProps)(RateAdminLorAdjustmentsScreen);
