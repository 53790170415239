import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRentersRevenueAgreementsScreen,
  createRentersRevenueAgreements,
  editRentersRevenueAgreements,
  fetchRentersRevenueAgreementsById,
  uploadImage,
  fetchRentersRevenueAgreementsExistingData
} from "../../../../redux/actions/Renters/RentersRevenueAgreements";
import {
  fetchLocationDD,
  
} from "../../../../redux/actions/location";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRentersRevenueAgreementsComponent from "../../../../component/Renters/RentersRevenueAgreements/CreateRentersRevenueAgreements";
import { useParams } from "react-router-dom";

const CreateRentersRevenueAgreementsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRentersRevenueAgreements,
    editRentersRevenueAgreements,
    isLoading,
    isRentersRevenueAgreementsCreateSuccess,
    isRentersRevenueAgreementsCreateError,
    isRentersRevenueAgreementsDetailSuccess,
    isRentersRevenueAgreementsDetailError,
    isRentersRevenueAgreementsEditSuccess,
    isRentersRevenueAgreementsEditError,
    isEdit,
    isView,
    initCreateRentersRevenueAgreementsScreen,
    formFieldValueMap,
    fetchRentersRevenueAgreementsById,
    fetchRentersRevenueAgreementsExistingData,
    RentersRevenueAgreementsExistingData,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        fetchLocationDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRentersRevenueAgreementsScreen();
    await fetchLocationDD();
   if (isEdit) {
    await fetchRentersRevenueAgreementsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRentersRevenueAgreementsProps = {
    createRentersRevenueAgreements,
    initCreateRentersRevenueAgreementsScreen,
    editRentersRevenueAgreements,
    isLoading,
    isEdit,
    isRentersRevenueAgreementsDetailSuccess,
    isRentersRevenueAgreementsCreateSuccess,
    isRentersRevenueAgreementsEditSuccess,
    isRentersRevenueAgreementsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        fetchRentersRevenueAgreementsExistingData,
        RentersRevenueAgreementsExistingData,
  };
  const getToastProps = () => {
    if (isRentersRevenueAgreementsCreateSuccess || isRentersRevenueAgreementsEditSuccess) {
      const toastTitle = isEdit
        ? "Revenue Agreements Updated Successfully"
        : "Revenue Agreements Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRentersRevenueAgreementsCreateError ||
      isRentersRevenueAgreementsDetailError ||
      isRentersRevenueAgreementsEditError
    ) {
      let toastTitle = "Error while Creating Renters Info";
      if (isRentersRevenueAgreementsEditError) {
        toastTitle = "Error while updating  Renters Info";
      } else if (isRentersRevenueAgreementsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRentersRevenueAgreementsComponent {...createRentersRevenueAgreementsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRentersRevenueAgreementsReducer(state, "isLoading"),
   
    isRentersRevenueAgreementsCreateSuccess: selectFromRentersRevenueAgreementsReducer(
      state,
      "isRentersRevenueAgreementsCreateSuccess"
    ),
    RentersRevenueAgreementsExistingData: selectFromRentersRevenueAgreementsReducer(
      state,
      "RentersRevenueAgreementsExistingData"
    ),
    
    isRentersRevenueAgreementsCreateError: selectFromRentersRevenueAgreementsReducer(
      state,
      "isRentersRevenueAgreementsCreateError"
    ),
    isRentersRevenueAgreementsDetailSuccess: selectFromRentersRevenueAgreementsReducer(
      state,
      "isRentersRevenueAgreementsDetailSuccess"
    ),
    isRentersRevenueAgreementsDetailError: selectFromRentersRevenueAgreementsReducer(
      state,
      "isRentersRevenueAgreementsDetailError"
    ),
    isRentersRevenueAgreementsEditSuccess: selectFromRentersRevenueAgreementsReducer(
      state,
      "isRentersRevenueAgreementsEditSuccess"
    ),
    isRentersRevenueAgreementsEditError: selectFromRentersRevenueAgreementsReducer(
      state,
      "isRentersRevenueAgreementsEditError"
    ),
    imageUploadResponse: selectFromRentersRevenueAgreementsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRentersRevenueAgreementsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRentersRevenueAgreementsReducer(
      state,
      "isUploadImageError"
    ),

    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleModelDD: selectFromRentersRevenueAgreementsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRentersRevenueAgreementsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRentersRevenueAgreementsReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRentersRevenueAgreementsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRentersRevenueAgreementsScreen: () => dispatch(initCreateRentersRevenueAgreementsScreen()),
    createRentersRevenueAgreements: (payload) => dispatch(createRentersRevenueAgreements(payload)),
    editRentersRevenueAgreements: (payload) => dispatch(editRentersRevenueAgreements(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchRentersRevenueAgreementsExistingData:(payload) => dispatch(fetchRentersRevenueAgreementsExistingData(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRentersRevenueAgreementsById: (payload) =>
      dispatch(fetchRentersRevenueAgreementsById(payload)),

  };
};

const selectFromRentersRevenueAgreementsReducer = (state, path) => {
  return selectRentersRevenueAgreementsReducer(state)[path];
};

const selectRentersRevenueAgreementsReducer = ({ RentersRevenueAgreementsReducer }) => {
  return RentersRevenueAgreementsReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRentersRevenueAgreementsContainer);
