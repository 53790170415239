import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { basicDetail, cancelResonsDD, editMode, paymentStatusDD, statusDD, typeDD } from "../CreateRentersRegisteredRenters/constant"
import { Box, Container, Grid, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import EzControlledInputAreaWithFloatingLabel from "../../../../common/EzForm/EzControlledInputArea/WithFloatingLabel"
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { RentersRentersInfoBreadcrumb } from "../../RentersRentersInfo/CreateRentersRentersInfo/constant";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import SpeedIcon from '@mui/icons-material/Speed';
import CardICON from '../../../../images/car-door.png'
const CreateRentersRegisteredRentersComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRentersRegisteredRentersScreen,
    createRentersRegisteredRenters,
    editRentersRegisteredRenters,
    isLoading,
    isRentersRegisteredRentersCreateSuccess,
    isRentersRegisteredRentersCreateError,
    isRentersRegisteredRentersDetailSuccess,
    isRentersRegisteredRentersDetailError,
    isRentersRegisteredRentersEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

const cancellation = watch("cancellation");


  const navigateToRentersRegisteredRentersScreen = () => {
    window.location.replace("/#" + APP_URLS.RENTERS_REGISTEREDRENTERS.HOME)
  };
  useEffect(() => {

    if (isRentersRegisteredRentersCreateSuccess || isRentersRegisteredRentersEditSuccess) {
      reset();
    }
  }, [isRentersRegisteredRentersCreateSuccess]);
  useEffect(() => {
    if (isRentersRegisteredRentersDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isRentersRegisteredRentersDetailSuccess]);
  useEffect(() => {
    initCreateRentersRegisteredRentersScreen();
  }, []);

  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    const stringifiedData = {
      ...newData,
    }

    isEdit
      ? editRentersRegisteredRenters({ id: data.id, data: stringifiedData })
      : createRentersRegisteredRenters(stringifiedData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Registered Renters</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Registered Renters"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Registered Renters"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToRentersRegisteredRentersScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RentersRentersInfoBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">


            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography className="headbgblue" variant="h6">
                  Selected Vehicle Information
                  <span>Confirmed</span>
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box>
                      <img className="imgbox_renter" src="https://manager.rezrevolution.com/backend/web/images/ddcfa2f98e6278d8fd334d78689d4512-MVAR.jpg" />
                    </Box>
                    <p className="vantype">Mini-Vans</p>
                    <Box className="vandetails">
                      <ul>
                        <li>
                          <Diversity3Icon />
                          <span>7</span>
                        </li>
                        <li>
                          <BusinessCenterIcon />
                          <span>2</span>
                        </li>
                        <li>
                          <ManageAccountsIcon />
                          <span>A/T</span>
                        </li>
                        <li>
                          <img src={CardICON} />
                          <span>4</span>
                        </li>
                        <li>
                          <HeatPumpIcon />
                          <span>AC</span>
                        </li>
                        <li>
                          <SpeedIcon />
                          <span>LTD</span>
                        </li>

                      </ul>
                    </Box>
                    <Grid item xs={12} marginTop={'30px'}>
<Button type="button"className="text-center">Send confirmation Email</Button>
                    </Grid>
                    
                    <Grid item xs={12} marginTop={'30px'}>
                    <EzControlledDropDowntWithFloatingLabel
                      name='paymentStatus'
                      control={control}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={paymentStatusDD}
                      isError={errors['paymentStatus']}
                      errorMsg={getFormErrorMessage('cancellpaymentStatusation')}
                      label='Payment Status'
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                    <Grid item xs={12}>
                    <EzControlledInputAreaWithFloatingLabel
                    name='additionalNotes'
                    control={control}
                    label='Additional Notes'
                    type='text'
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['additionalNotes']}
                    errorMsg={getFormErrorMessage('additionalNotes')}
                  />
                  </Grid>

                  <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
                  </Grid>
                  <Grid item xs={7} className="boxmidright">
                    <Typography variant="h6" className="sub">
                      Total Amount<span>$226.33</span>
                    </Typography>

                    <table className="tbleforRenter">
                      <tbody>
                        <tr className="rowfont">
                          <td colSpan={2}>Before Taxes</td>
                          <td className="textr">$150</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={3}> 3 Day(s)& 0 Hour(s) @ 50</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Extras </td>
                          <td className="textr">$0</td>
                        </tr>

                        <tr className="rowfont2">
                          <td>Glass & Tire</td>
                          <td>None </td>
                          <td className="textr"></td>
                        </tr>
                        <tr className="rowfont2">
                          <td>Equipment</td>
                          <td>None </td>
                          <td className="textr"></td>
                        </tr>
                        <tr className="rowfont2">
                          <td>Out of Province</td>
                          <td>None </td>
                          <td className="textr"></td>
                        </tr>
                        <tr className="rowfont2">
                          <td>Extra Mileage</td>
                          <td>None </td>
                          <td className="textr"></td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Fees</td>
                          <td className="textr">$25.54</td>
                        </tr>

                        <tr className="rowfont">
                          <td colSpan={2}>Underage Driver Fees</td>
                          <td className="textr">$0.00</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Airport Facility Charge Total for 3</td>
                          <td className="textr">$0.00</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Concession Fee</td>
                          <td className="textr">$18.25</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>After Hour Fee</td>
                          <td className="textr">$0.0</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Estimated Taxes</td>
                          <td className="textr">$20.00</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Total amount of your booking</td>
                          <td className="textr">$175.54</td>
                        </tr>
                        <tr className="rowfont blue">
                          <td colSpan={2}>Total without extras</td>
                          <td className="textr">$174.46</td>
                        </tr>
                        <tr className="rowfont">
                          <td colSpan={2}>Pay on arrival</td>
                          <td className="textr">$00.00</td>
                        </tr>
                        <tr className="rowfont red">
                          <td colSpan={2}>Limited Kilometers</td>
                          <td className="textr">Exclusive Rates Applied</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              
              </Grid>
              <Grid item xs={4}>
                <Typography className="headbgblue" variant="h6">
                  Driver Information
                </Typography>
                <table className="tbleforRenter">
                  <tbody>
                    <tr className="rowfont">
                      <td><b>Name:</b></td>
                      <td className="textl">T B SUNIL KUMAR KANTETI</td>
                    </tr>
                    <tr className="rowfont">
                      <td><b>Email ID:</b></td>
                      <td className="textl">kanteti.sunilkumar@gmail.com</td>
                    </tr>
                    <tr className="rowfont">
                      <td><b>Contact:</b></td>
                      <td className="textl">5879686138</td>
                    </tr>
                    <tr className="rowfont">
                      <td><b>Address:</b></td>
                      <td className="textl">141 SKYVIEW BAY NE CALGARY T3N2K6 AB CANADA</td>
                    </tr>
                    <tr className="rowfont">
                      <td><b>Flight/Special:</b></td>
                      <td className="textl"></td>
                    </tr>
                  </tbody>
                </table>
                <Typography className="headbgblue" variant="h6">
                  Itinerary
                </Typography>

                <table className="tbleforRenter">
                  <tbody>
                    <tr className="rowfont">
                      <td><b>Pickup</b></td>
                      <td className="textl"><b>Dropoff</b></td>
                    </tr>
                    <tr className="rowfont">
                      <td className="textl">08/16/2024
                      12:00 pm</td>
                      <td className="textl">08/18/2024
                      08:00 pm</td>
                    </tr>
                    <tr className="rowfont">
                      <td colSpan={2}> <b>Pickup & Dropoff Location</b></td>
                    </tr>
                    <tr className="rowfont">
                      <td colSpan={2} className="textl">Toronto Airport West YYZ</td>
                    </tr>
                    <tr className="rowfont">
                      <td colSpan={2} className="textl">
                        <a href="#">Google Map Link</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Typography className="headbgblue" variant="h6">
                Cancellation
                </Typography>
                <Grid item xs={12} marginTop={'30px'}>
                    <EzControlledDropDowntWithFloatingLabel
                      name='cancellation'
                      control={control}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={cancelResonsDD}
                      isError={errors['cancellation']}
                      errorMsg={getFormErrorMessage('cancellation')}
                      label='Reason for Cancellation'
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                  {cancellation=="6" &&(
                    <Grid item xs={12}>
                    <EzControlledInputAreaWithFloatingLabel
                    name='others'
                    control={control}
                    label='Others'
                    type='text'
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['others']}
                    errorMsg={getFormErrorMessage('others')}
                  />
                  </Grid>
                  )}
                 <React.Fragment>
                  <div style={{ margin: "0px 0px 15px 0px", maxWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={"Cancel"}
                     
                      className="mt-2 p-button-raised danger"
                    />
                  </div>

                </React.Fragment>
              </Grid>
            </Grid>
          

           
            {isEdit && (<Grid className="modsection" container spacing={2}>
              {editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                  <EzControlledInputWithFloatingLabel
                    name={item?.name}
                    control={control}
                    disabled={item?.disabled}
                    label={item?.label}
                    type={item?.type}
                    rules={item?.rule}
                    isError={errors[item?.name]}
                    errorMsg={getFormErrorMessage(item?.name)}
                  />
                </Grid>
              )
              )
              }
            </Grid>)
            }
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateRentersRegisteredRentersComponent;
