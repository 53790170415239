import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminExtraProductChargeServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_INIT, 
  FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SCREEN,
  CREATE_RATEADMIN_EXTRAPRODUCTCHARGE,
  EDIT_RATEADMIN_EXTRAPRODUCTCHARGE,
  TOAST,
  FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL,
  EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE,
  RATEADMIN_EXTRAPRODUCTCHARGEDD,
  RATEADMIN_RATEOPTIONDD,
  RATEADMIN_RATENETWORKDD,
  RATEADMIN_EXTRAPRODUCTCHARGEDELETE
} from "../../../types/rateadminextraproductcharge.type";
export const initializeRateAdminExtraProductChargeScreen = () => ({ type: FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_INIT });

export const rateadminExtraProductChargeFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST.START,
  payload,
});

export const rateadminExtraProductChargeFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST.SUCCESS,
  payload,
});

export const rateadminExtraProductChargeFetchError = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST.ERROR,
  payload,
});

export const fetchRateAdminExtraProductCharge = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductChargeFetchStart(payload));
    rateAdminExtraProductChargeServices.fetchRateAdminExtraProductCharge(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminExtraProductChargeFetchSuccess(data));
      } else {
        dispatch(rateadminExtraProductChargeFetchError(data));
      }
    });
  };
};

// RATEOPTIO NDD
export const rateadminNetworkDDFetchStart = (payload) => ({
  type: RATEADMIN_RATENETWORKDD.START,
  payload,
});

export const rateadminNetworkDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATENETWORKDD.SUCCESS,
  payload,
});

export const rateadminNetworkDDFetchError = (payload) => ({
  type: RATEADMIN_RATENETWORKDD.ERROR,
  payload,
});

export const fetchRateAdminNetworkDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminNetworkDDFetchStart(payload));
    rateAdminExtraProductChargeServices.fetchRateAdminNetworkDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminNetworkDDFetchSuccess(data));
      } else {
        dispatch(rateadminNetworkDDFetchError(data));
      }
    });
  };
};

//END DD

// RATEOPTION DD
export const rateadminRateOptionsDDFetchStart = (payload) => ({
  type: RATEADMIN_RATEOPTIONDD.START,
  payload,
});

export const rateadminRateOptionsDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATEOPTIONDD.SUCCESS,
  payload,
});

export const rateadminRateOptionsDDFetchError = (payload) => ({
  type: RATEADMIN_RATEOPTIONDD.ERROR,
  payload,
});

export const fetchRateAdminRateOptionsDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateOptionsDDFetchStart(payload));
    rateAdminExtraProductChargeServices.fetchRateAdminRateOptionsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateOptionsDDFetchSuccess(data));
      } else {
        dispatch(rateadminRateOptionsDDFetchError(data));
      }
    });
  };
};
// DD RATE
export const rateadminExtraProductChargeDDFetchStart = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCHARGEDD.START,
  payload,
});

export const rateadminExtraProductChargeDDFetchSuccess = (payload) => ({
  type:RATEADMIN_EXTRAPRODUCTCHARGEDD.SUCCESS,
  payload,
});

export const rateadminExtraProductChargeDDFetchError = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCHARGEDD.ERROR,
  payload,
});

export const fetchRateAdminExtraProductChargeDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductChargeDDFetchStart(payload));
    rateAdminExtraProductChargeServices.fetchRateAdminExtraProductChargeDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminExtraProductChargeDDFetchSuccess(data));
      } else {
        dispatch(rateadminExtraProductChargeDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminExtraProductChargeDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL.START,
  payload,
});

export const rateadminExtraProductChargeDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL.SUCCESS,
  payload,
});

export const rateadminExtraProductChargeDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminExtraProductChargeById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductChargeDetailFetchStart(payload));

    rateAdminExtraProductChargeServices
      .fetchRateAdminExtraProductChargeById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminExtraProductChargeDetailFetchSuccess(data));
        } else {
          dispatch(rateadminExtraProductChargeDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/



/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminExtraProductChargeServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminExtraProductChargeScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SCREEN,
  payload,
});

export const initCreateRateAdminExtraProductChargeScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminExtraProductChargeScreenInitStart());
  };
};


export const createRateAdminExtraProductChargeStart = () => ({
  type: CREATE_RATEADMIN_EXTRAPRODUCTCHARGE.START,
});

export const createRateAdminExtraProductChargeSuccess = (payload) => ({
  type: CREATE_RATEADMIN_EXTRAPRODUCTCHARGE.SUCCESS,
  payload,
});

export const createRateAdminExtraProductChargeError = (payload) => ({
  type: CREATE_RATEADMIN_EXTRAPRODUCTCHARGE.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminExtraProductCharge = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminExtraProductChargeStart());
    rateAdminExtraProductChargeServices.createRateAdminExtraProductCharge(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminExtraProductChargeSuccess());
        goBack();
      } else {
        dispatch(createRateAdminExtraProductChargeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminExtraProductChargeStart = (payload) => ({
  type: EDIT_RATEADMIN_EXTRAPRODUCTCHARGE.START,
  payload
});

export const editRateAdminExtraProductChargeSuccess = (payload) => ({
  type: EDIT_RATEADMIN_EXTRAPRODUCTCHARGE.SUCCESS,
  payload,
});

export const editRateAdminExtraProductChargeError = (payload) => ({
  type: EDIT_RATEADMIN_EXTRAPRODUCTCHARGE.ERROR,
  payload,
});

export const editRateAdminExtraProductCharge = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminExtraProductChargeStart());
    rateAdminExtraProductChargeServices.editRateAdminExtraProductCharge(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminExtraProductChargeSuccess());
        goBack();
      } else {
        dispatch(editRateAdminExtraProductChargeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminExtraProductChargeRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE.START,
  payload
});

export const exportRateAdminExtraProductChargeRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE.SUCCESS,
  payload,
});

export const exportRateAdminExtraProductChargeRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE.ERROR,
  payload,
});

export const exportRateAdminExtraProductCharge = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminExtraProductChargeRecordStart());
    rateAdminExtraProductChargeServices.exportRateAdminExtraProductChargeRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminExtraProductChargeRecordSuccess(data));
      } else {
        dispatch(exportRateAdminExtraProductChargeRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};





/*---------------START RATE DELETE------------------*/

export const rateadminExtraProductChargeDeleteStart = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCHARGEDELETE.START,
  payload,
});

export const rateadminExtraProductChargeDeleteSuccess = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCHARGEDELETE.SUCCESS,
  payload,
});

export const rateadminExtraProductChargeDeleteError = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCHARGEDELETE.ERROR,
  payload,
});

export const deleteRateAdminExtraProductCharge = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductChargeDeleteStart(payload));

    rateAdminExtraProductChargeServices
      .deleteRateAdminExtraProductCharge(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminExtraProductChargeDeleteSuccess(data));
          dispatch(fetchRateAdminExtraProductCharge(payload?.params));
        } else {
          dispatch(rateadminExtraProductChargeDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/