import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST, 
  TOAST, 
  FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_INIT,
  FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL,
  EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT,
  CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT,
  EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT,
  INIT_CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SCREEN,
  RESERVATIONS_PREPAIDBOOKINGSREPORTDD,
  RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Reservations/PrepaidBookingsReport.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    ReservationsPrepaidBookingsReportListview: [],
    ReservationsPrepaidBookingsReportDD:[],
    isReservationsPrepaidBookingsReportDDSuccess: false,
    isReservationsPrepaidBookingsReportDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isReservationsPrepaidBookingsReportCreateSuccess: false,
    isReservationsPrepaidBookingsReportCreateError: false,
    isReservationsPrepaidBookingsReportDetailSuccess: false,
    isReservationsPrepaidBookingsReportDetailError: false,
    isReservationsPrepaidBookingsReportEditSuccess: false,
    isReservationsPrepaidBookingsReportEditError: false,
    isReservationsPrepaidBookingsReportExportSuccess: false,
    isReservationsPrepaidBookingsReportExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isReservationsPrepaidBookingsReportDeleteSuccess: false,
    isReservationsPrepaidBookingsReportDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network Booking",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Locaiton",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Vehicle",
        dataKey: "vehicle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Booking Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Pickup Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },





      {
        colLabel: "Dropoff Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Days",
        dataKey: "days",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Total",
        dataKey: "total",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Currency",
        dataKey: "currency",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Name",
        dataKey: "name",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Payment Status",
        dataKey: "paymentStatus",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Type",
        dataKey: "rateType",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const ReservationsPrepaidBookingsReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL.START:
      case EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST.START:
      case CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT.START:
        case RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE.START:
        case EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT.START:
          case RESERVATIONS_PREPAIDBOOKINGSREPORTDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          ReservationsPrepaidBookingsReportListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isReservationsPrepaidBookingsReportCreateSuccess: false,
          isReservationsPrepaidBookingsReportCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          ReservationsPrepaidBookingsReportListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          ReservationsPrepaidBookingsReportListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isReservationsPrepaidBookingsReportDeletionSuccess: false,
          isReservationsPrepaidBookingsReportDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReservationsPrepaidBookingsReportDetailSuccess: true,
      };
    }
    case FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsPrepaidBookingsReportDetailSuccess: false,
        isReservationsPrepaidBookingsReportDetailError: true,
      };
    }

    //DD
    case RESERVATIONS_PREPAIDBOOKINGSREPORTDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        ReservationsPrepaidBookingsReportDD:temp,
        isReservationsPrepaidBookingsReportDDSuccess: true,
        isReservationsPrepaidBookingsReportDDError: false,
      };
    }
    case RESERVATIONS_PREPAIDBOOKINGSREPORTDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsPrepaidBookingsReportDDSuccess: false,
        isReservationsPrepaidBookingsReportDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isReservationsPrepaidBookingsReportDeleteSuccess: false,
    isReservationsPrepaidBookingsReportDeleteError: false,
  };
}
case RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isReservationsPrepaidBookingsReportDeleteSuccess: true,
    isReservationsPrepaidBookingsReportDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isReservationsPrepaidBookingsReportDeleteSuccess: false,
    isReservationsPrepaidBookingsReportDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isReservationsPrepaidBookingsReportExportSuccess: false,
    isReservationsPrepaidBookingsReportExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"prepaid-bookings-report.xls");
   return {
    ...state,
    isReservationsPrepaidBookingsReportExportSuccess: true,
    isReservationsPrepaidBookingsReportExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsPrepaidBookingsReportEditSuccess: false,
    isReservationsPrepaidBookingsReportEditError: true,
  };
}

case EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsPrepaidBookingsReportEditSuccess: true,
    isReservationsPrepaidBookingsReportEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsPrepaidBookingsReportCreateSuccess: false,
    isReservationsPrepaidBookingsReportCreateError: true,
  };
}
case CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsPrepaidBookingsReportCreateSuccess: true,
    isReservationsPrepaidBookingsReportCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isReservationsPrepaidBookingsReportCreateSuccess: false,
          isReservationsPrepaidBookingsReportCreateError: false,
          isReservationsPrepaidBookingsReportEditError: false,
          isReservationsPrepaidBookingsReportEditSuccess: false,
          isReservationsPrepaidBookingsReportDetailError: false
        };
      }
      default:
        return state;
    }
  };
  