

export const FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST = {
    START: 'FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST_START',
    SUCCESS: 'FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_INIT="FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_SCREEN =
'INIT_CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_SCREEN';

export const CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY = {
START: 'CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_START',
SUCCESS: 'CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_SUCCESS',
ERROR: 'CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_ERROR',
};

export const EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY = {
START: 'EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY_START',
SUCCESS: 'EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY_SUCCESS',
ERROR: 'EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY_ERROR',
};

export const FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL = {
  START: 'FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL_START',
  SUCCESS: 'FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL_SUCCESS',
  ERROR: 'FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL_ERROR',
};
export const EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY = {
  START: 'EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY_START',
  SUCCESS: 'EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY_SUCCESS',
  ERROR: 'EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY_ERROR',
};
export const RATEADMIN_EXTRAPRODUCTCATEGORYDD = {
  START: 'RATEADMIN_EXTRAPRODUCTCATEGORYDD_START',
  SUCCESS: 'RATEADMIN_EXTRAPRODUCTCATEGORYDD_SUCCESS',
  ERROR: 'RATEADMIN_EXTRAPRODUCTCATEGORYDD_ERROR',
};