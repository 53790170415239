import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleAcTypeScreen,
  createVehicleAcType,
  editVehicleAcType,
  fetchVehicleAcTypeById,
  uploadImage,
  fetchVehicleModelDD
} from "../../../../redux/actions/vehicles/vehicleAcType";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleAcTypeComponent from "../../../../component/Vehicles/VehicleAcType/CreateVehicleAcType";
import { useParams } from "react-router-dom";

const CreateVehicleAcTypeContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleAcType,
    editVehicleAcType,
    isLoading,
    isVehicleAcTypeCreateSuccess,
    isVehicleAcTypeCreateError,
    isVehicleAcTypeDetailSuccess,
    isVehicleAcTypeDetailError,
    isVehicleAcTypeEditSuccess,
    isVehicleAcTypeEditError,
    isEdit,
    isView,
    initCreateVehicleAcTypeScreen,
    formFieldValueMap,
    fetchVehicleAcTypeById,
    fetchVehicleModelDD,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams();
  const callAPIasync = async() =>{
    initCreateVehicleAcTypeScreen();
    //await fetchVehicleModelDD();
   if (isEdit) {
    await fetchVehicleAcTypeById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleAcTypeProps = {
    createVehicleAcType,
    initCreateVehicleAcTypeScreen,
    editVehicleAcType,
    isLoading,
    isEdit,
    isVehicleAcTypeDetailSuccess,
    isVehicleAcTypeCreateSuccess,
    isVehicleAcTypeEditSuccess,
    isVehicleAcTypeEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isVehicleAcTypeCreateSuccess || isVehicleAcTypeEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleAcType Updated Successfully"
        : "VehicleAcType Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleAcTypeCreateError ||
      isVehicleAcTypeDetailError ||
      isVehicleAcTypeEditError
    ) {
      let toastTitle = "Error while Creating Vehicle AcType";
      if (isVehicleAcTypeEditError) {
        toastTitle = "Error while updating Vehicle AcType";
      } else if (isVehicleAcTypeDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleAcTypeComponent {...createVehicleAcTypeProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleAcTypeReducer(state, "isLoading"),
   
    isVehicleAcTypeCreateSuccess: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleAcTypeCreateSuccess"
    ),
    isVehicleAcTypeCreateError: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleAcTypeCreateError"
    ),
    isVehicleAcTypeDetailSuccess: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleAcTypeDetailSuccess"
    ),
    isVehicleAcTypeDetailError: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleAcTypeDetailError"
    ),
    isVehicleAcTypeEditSuccess: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleAcTypeEditSuccess"
    ),
    isVehicleAcTypeEditError: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleAcTypeEditError"
    ),
    imageUploadResponse: selectFromVehicleAcTypeReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleAcTypeReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleAcTypeReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromVehicleAcTypeReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleAcTypeReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleAcTypeReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleAcTypeScreen: () => dispatch(initCreateVehicleAcTypeScreen()),
    createVehicleAcType: (payload) => dispatch(createVehicleAcType(payload)),
    editVehicleAcType: (payload) => dispatch(editVehicleAcType(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleAcTypeById: (payload) =>
      dispatch(fetchVehicleAcTypeById(payload)),
    fetchVehicleModelDD: (payload) =>
      dispatch(fetchVehicleModelDD(payload)),
  };
};

const selectFromVehicleAcTypeReducer = (state, path) => {
  return selectVehicleAcTypeReducer(state)[path];
};

const selectVehicleAcTypeReducer = ({ vehicleAcTypeReducer }) => {
  return vehicleAcTypeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleAcTypeContainer);
