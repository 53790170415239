import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRentersNonRevenueAgreements,
    initializeRentersNonRevenueAgreementsScreen,
    setDeleteConfirmationPopupVisibility,
    exportRentersNonRevenueAgreements
  } from "../../../redux/actions/Renters/RentersNonRevenueAgreements";
import RentersNonRevenueAgreements from '../../../component/Renters/RentersNonRevenueAgreements';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RentersNonRevenueAgreementsScreen(props) {
    const {
        fetchRentersNonRevenueAgreements,
        initRentersNonRevenueAgreementsScreen,
        showPageLevelLoader,
        RentersNonRevenueAgreementsListview,
        paginationInfo,
        exportRentersNonRevenueAgreements,
        isRentersNonRevenueAgreementsExportError,
        isRentersNonRevenueAgreementsExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRentersNonRevenueAgreementsScreen();
        fetchRentersNonRevenueAgreements({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRentersNonRevenueAgreements(newProps);
      };
      const handleCreateRentersNonRevenueAgreements =() =>{
      navigate(APP_URLS.RENTERS_NONREVENUEAGREEMENTS.CREATE);
    }
      const editRentersNonRevenueAgreementsRoute = (id) => {
        const path = generatePath(APP_URLS.RENTERS_NONREVENUEAGREEMENT.EDIT, {
          id,
        });
        navigate(path);
      };
const RentersNonRevenueAgreementsProps = {
  handleCreateRentersNonRevenueAgreements,
  exportRentersNonRevenueAgreements,
        isRentersNonRevenueAgreementsExportError,
        isRentersNonRevenueAgreementsExportSuccess,
  editRentersNonRevenueAgreementsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRentersNonRevenueAgreementss: loadLazyData,
  ...companyData,
  tableData: {
    columns: RentersNonRevenueAgreementsListview?.columns,
    rows: RentersNonRevenueAgreementsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RentersNonRevenueAgreements
    {...RentersNonRevenueAgreementsProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRentersNonRevenueAgreementsScreen: () => dispatch(initializeRentersNonRevenueAgreementsScreen()),
      fetchRentersNonRevenueAgreements: (payload) => dispatch(fetchRentersNonRevenueAgreements(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRentersNonRevenueAgreements: (payload) => dispatch(exportRentersNonRevenueAgreements(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRentersNonRevenueAgreementsCreateSuccess: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "isRentersNonRevenueAgreementsCreateSuccess"
      ),
      RentersNonRevenueAgreementsListview: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "RentersNonRevenueAgreementsListview"
      ),
      showPageLevelLoader: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "isFetchingGridData"
      ),
      isRentersNonRevenueAgreementsExportError: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "isRentersNonRevenueAgreementsExportError"
      ),
      isRentersNonRevenueAgreementsExportSuccess: selectFromRentersNonRevenueAgreementsReducer(
        state,
        "isRentersNonRevenueAgreementsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRentersNonRevenueAgreementsReducer = (state, path) => {
  return selectRentersNonRevenueAgreementsReducer(state)[path];
};

const selectRentersNonRevenueAgreementsReducer = ({ RentersNonRevenueAgreementsReducer }) => {
  return RentersNonRevenueAgreementsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RentersNonRevenueAgreementsScreen);
