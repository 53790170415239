import PropTypes from 'prop-types';
import { Image } from 'primereact/image';

import './index.css';

const EzNotFound = (props) => {
  const { bgImgAltText, message, bgImgUrl, messageStyle, imageStyle } = props;
  if (bgImgUrl) {
    return (
      <>
        <div className={'not-found-text'} style={messageStyle}>
          {' '}
          {message}
        </div>
        <Image
          imageClassName={'img-center opacity80'}
          imageStyle={imageStyle ? imageStyle : {}}
          src={bgImgUrl}
          downloadable={false}
          alt={bgImgAltText}
        />
      </>
    );
  }
  return <div className="norecord"> {message}</div>;
};

EzNotFound.defaultProps = {
  message: 'No records to show',
  bgImgAltText: "It's all empty here..",
  bgImgUrl: '',
  messageStyle: {},
  imageStyle: {},
};

EzNotFound.propTypes = {
  message: PropTypes.string,
  bgImgAltText: PropTypes.string,
  bgImgUrl: PropTypes.string,
  messageStyle: PropTypes.object,
  imageStyle: PropTypes.object,
};

export default EzNotFound;
