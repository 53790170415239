import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleVehicleStatusScreen,
  createVehicleVehicleStatus,
  editVehicleVehicleStatus,
  fetchVehicleVehicleStatusById,
  uploadImage,
} from "../../../../redux/actions/vehicles/vehicleVehicleStatus";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleVehicleStatusComponent from "../../../../component/Vehicles/VehicleVehicleStatus/CreateVehicleVehicleStatus";
import { useParams } from "react-router-dom";

const CreateVehicleVehicleStatusContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleVehicleStatus,
    editVehicleVehicleStatus,
    isLoading,
    isVehicleVehicleStatusCreateSuccess,
    isVehicleVehicleStatusCreateError,
    isVehicleVehicleStatusDetailSuccess,
    isVehicleVehicleStatusDetailError,
    isVehicleVehicleStatusEditSuccess,
    isVehicleVehicleStatusEditError,
    isEdit,
    isView,
    initCreateVehicleVehicleStatusScreen,
    formFieldValueMap,
    fetchVehicleVehicleStatusById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleVehicleStatusScreen();
   if (isEdit) {
    await fetchVehicleVehicleStatusById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleVehicleStatusProps = {
    createVehicleVehicleStatus,
    initCreateVehicleVehicleStatusScreen,
    editVehicleVehicleStatus,
    isLoading,
    isEdit,
    isVehicleVehicleStatusDetailSuccess,
    isVehicleVehicleStatusCreateSuccess,
    isVehicleVehicleStatusEditSuccess,
    isVehicleVehicleStatusEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isVehicleVehicleStatusCreateSuccess || isVehicleVehicleStatusEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleVehicleStatus Updated Successfully"
        : "VehicleVehicleStatus Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleVehicleStatusCreateError ||
      isVehicleVehicleStatusDetailError ||
      isVehicleVehicleStatusEditError
    ) {
      let toastTitle = "Error while Creating Vehicle VehicleStatus";
      if (isVehicleVehicleStatusEditError) {
        toastTitle = "Error while updating Vehicle VehicleStatus";
      } else if (isVehicleVehicleStatusDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleVehicleStatusComponent {...createVehicleVehicleStatusProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleVehicleStatusReducer(state, "isLoading"),
   
    isVehicleVehicleStatusCreateSuccess: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleVehicleStatusCreateSuccess"
    ),
    isVehicleVehicleStatusCreateError: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleVehicleStatusCreateError"
    ),
    isVehicleVehicleStatusDetailSuccess: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleVehicleStatusDetailSuccess"
    ),
    isVehicleVehicleStatusDetailError: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleVehicleStatusDetailError"
    ),
    isVehicleVehicleStatusEditSuccess: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleVehicleStatusEditSuccess"
    ),
    isVehicleVehicleStatusEditError: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleVehicleStatusEditError"
    ),
    imageUploadResponse: selectFromVehicleVehicleStatusReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleVehicleStatusReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleVehicleStatusReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromVehicleVehicleStatusReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleVehicleStatusReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleVehicleStatusReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleVehicleStatusScreen: () => dispatch(initCreateVehicleVehicleStatusScreen()),
    createVehicleVehicleStatus: (payload) => dispatch(createVehicleVehicleStatus(payload)),
    editVehicleVehicleStatus: (payload) => dispatch(editVehicleVehicleStatus(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleVehicleStatusById: (payload) =>
      dispatch(fetchVehicleVehicleStatusById(payload)),

  };
};

const selectFromVehicleVehicleStatusReducer = (state, path) => {
  return selectVehicleVehicleStatusReducer(state)[path];
};

const selectVehicleVehicleStatusReducer = ({ vehicleVehicleStatusReducer }) => {
  return vehicleVehicleStatusReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleVehicleStatusContainer);
