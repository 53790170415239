import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleTrimServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_TRIM_INIT, 
  FETCH_VEHICLE_TRIM_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_TRIM_SCREEN,
  CREATE_VEHICLE_TRIM,
  EDIT_VEHICLE_TRIM,
  TOAST,
  FETCH_VEHICLE_TRIM_DETAIL,
  EXPORT_VEHICLE_TRIM,
  VEHICLE_TRIMDD
} from "../../../types/vehicletrim.type";
export const initializeVehicleTrimScreen = () => ({ type: FETCH_VEHICLE_TRIM_INIT });

export const vehicleTrimFetchStart = (payload) => ({
  type: FETCH_VEHICLE_TRIM_LIST.START,
  payload,
});

export const vehicleTrimFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_TRIM_LIST.SUCCESS,
  payload,
});

export const vehicleTrimFetchError = (payload) => ({
  type: FETCH_VEHICLE_TRIM_LIST.ERROR,
  payload,
});

export const fetchVehicleTrim = (payload) => {
  return (dispatch) => {
    dispatch(vehicleTrimFetchStart(payload));
    vehicleTrimServices.fetchVehicleTrim(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleTrimFetchSuccess(data));
      } else {
        dispatch(vehicleTrimFetchError(data));
      }
    });
  };
};



// DD
export const vehicleTrimDDFetchStart = (payload) => ({
  type: VEHICLE_TRIMDD.START,
  payload,
});

export const vehicleTrimDDFetchSuccess = (payload) => ({
  type:VEHICLE_TRIMDD.SUCCESS,
  payload,
});

export const vehicleTrimDDFetchError = (payload) => ({
  type: VEHICLE_TRIMDD.ERROR,
  payload,
});

export const fetchVehicleTrimDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleTrimDDFetchStart(payload));
    vehicleTrimServices.fetchVehicleTrimDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleTrimDDFetchSuccess(data));
      } else {
        dispatch(vehicleTrimDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleTrimDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_TRIM_DETAIL.START,
  payload,
});

export const vehicleTrimDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_TRIM_DETAIL.SUCCESS,
  payload,
});

export const vehicleTrimDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_TRIM_DETAIL.ERROR,
  payload,
});

export const fetchVehicleTrimById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleTrimDetailFetchStart(payload));

    vehicleTrimServices
      .fetchVehicleTrimById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleTrimDetailFetchSuccess(data));
        } else {
          dispatch(vehicleTrimDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleTrimServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleTrimScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_TRIM_SCREEN,
  payload,
});

export const initCreateVehicleTrimScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleTrimScreenInitStart());
  };
};


export const createVehicleTrimStart = () => ({
  type: CREATE_VEHICLE_TRIM.START,
});

export const createVehicleTrimSuccess = (payload) => ({
  type: CREATE_VEHICLE_TRIM.SUCCESS,
  payload,
});

export const createVehicleTrimError = (payload) => ({
  type: CREATE_VEHICLE_TRIM.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleTrim = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleTrimStart());
    vehicleTrimServices.createVehicleTrim(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleTrimSuccess());
        goBack();
      } else {
        dispatch(createVehicleTrimError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleTrimStart = (payload) => ({
  type: EDIT_VEHICLE_TRIM.START,
  payload
});

export const editVehicleTrimSuccess = (payload) => ({
  type: EDIT_VEHICLE_TRIM.SUCCESS,
  payload,
});

export const editVehicleTrimError = (payload) => ({
  type: EDIT_VEHICLE_TRIM.ERROR,
  payload,
});

export const editVehicleTrim = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleTrimStart());
    vehicleTrimServices.editVehicleTrim(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleTrimSuccess());
        goBack();
      } else {
        dispatch(editVehicleTrimError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleTrimRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_TRIM.START,
  payload
});

export const exportVehicleTrimRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_TRIM.SUCCESS,
  payload,
});

export const exportVehicleTrimRecordError = (payload) => ({
  type: EXPORT_VEHICLE_TRIM.ERROR,
  payload,
});

export const exportVehicleTrim = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleTrimRecordStart());
    vehicleTrimServices.exportVehicleTrimRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleTrimRecordSuccess(data));
      } else {
        dispatch(exportVehicleTrimRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

