

export const FETCH_VEHICLE_VEHICLEOPERATIONS_LIST = {
    START: 'FETCH_VEHICLE_VEHICLEOPERATIONS_LIST_START',
    SUCCESS: 'FETCH_VEHICLE_VEHICLEOPERATIONS_LIST_SUCCESS',
    ERROR: 'FETCH_VEHICLE_VEHICLEOPERATIONS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_VEHICLE_VEHICLEOPERATIONS_INIT="FETCH_VEHICLE_VEHICLEOPERATIONS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_VEHICLE_VEHICLEOPERATIONS_SCREEN =
'INIT_CREATE_VEHICLE_VEHICLEOPERATIONS_SCREEN';

export const CREATE_VEHICLE_VEHICLEOPERATIONS = {
START: 'CREATE_VEHICLE_VEHICLEOPERATIONS_START',
SUCCESS: 'CREATE_VEHICLE_VEHICLEOPERATIONS_SUCCESS',
ERROR: 'CREATE_VEHICLE_VEHICLEOPERATIONS_ERROR',
};

export const EDIT_VEHICLE_VEHICLEOPERATIONS = {
START: 'EDIT_VEHICLE_VEHICLEOPERATIONS_START',
SUCCESS: 'EDIT_VEHICLE_VEHICLEOPERATIONS_SUCCESS',
ERROR: 'EDIT_VEHICLE_VEHICLEOPERATIONS_ERROR',
};

export const FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL = {
  START: 'FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL_START',
  SUCCESS: 'FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL_SUCCESS',
  ERROR: 'FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL_ERROR',
};
export const EXPORT_VEHICLE_VEHICLEOPERATIONS = {
  START: 'EXPORT_VEHICLE_VEHICLEOPERATIONS_START',
  SUCCESS: 'EXPORT_VEHICLE_VEHICLEOPERATIONS_SUCCESS',
  ERROR: 'EXPORT_VEHICLE_VEHICLEOPERATIONS_ERROR',
};
export const VEHICLE_OPERATIONDD = {
  START: 'VEHICLE_OPERATIONDD_START',
  SUCCESS: 'VEHICLE_OPERATIONDD_SUCCESS',
  ERROR: 'VEHICLE_OPERATIONDD_ERROR',
};