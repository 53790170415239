import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

const EzControlledCheckBox = (props) => {
  const { control, name, customClassNames, rules, onChange, value} = props;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Checkbox
            {...field}
            checked={value}
            inputId={field.name}
            
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              field.onChange(e.checked);
            }}
            className={classNames({
              'p-invalid': fieldState.invalid,
              customClassNames,
            })}
          />
        );
      }}
    />
  );
};

EzControlledCheckBox.defaultProps = {
  customClassNames: '',
};

export default EzControlledCheckBox;
