import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleDealershipsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_DEALERSHIPS_INIT, 
  FETCH_VEHICLE_DEALERSHIPS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_DEALERSHIPS_SCREEN,
  CREATE_VEHICLE_DEALERSHIPS,
  EDIT_VEHICLE_DEALERSHIPS,
  TOAST,
  FETCH_VEHICLE_DEALERSHIPS_DETAIL,
  EXPORT_VEHICLE_DEALERSHIPS,
  VEHICLE_DEALERSHIPSDD
} from "../../../types/vehicledealerships.type";
export const initializeVehicleDealershipsScreen = () => ({ type: FETCH_VEHICLE_DEALERSHIPS_INIT });

export const vehicleDealershipsFetchStart = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPS_LIST.START,
  payload,
});

export const vehicleDealershipsFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_DEALERSHIPS_LIST.SUCCESS,
  payload,
});

export const vehicleDealershipsFetchError = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPS_LIST.ERROR,
  payload,
});

export const fetchVehicleDealerships = (payload) => {
  return (dispatch) => {
    dispatch(vehicleDealershipsFetchStart(payload));
    vehicleDealershipsServices.fetchVehicleDealerships(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleDealershipsFetchSuccess(data));
      } else {
        dispatch(vehicleDealershipsFetchError(data));
      }
    });
  };
};



// DD
export const vehicleDealershipsDDFetchStart = (payload) => ({
  type: VEHICLE_DEALERSHIPSDD.START,
  payload,
});

export const vehicleDealershipsDDFetchSuccess = (payload) => ({
  type:VEHICLE_DEALERSHIPSDD.SUCCESS,
  payload,
});

export const vehicleDealershipsDDFetchError = (payload) => ({
  type: VEHICLE_DEALERSHIPSDD.ERROR,
  payload,
});

export const fetchVehicleDealershipsDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleDealershipsDDFetchStart(payload));
    vehicleDealershipsServices.fetchVehicleDealershipsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleDealershipsDDFetchSuccess(data));
      } else {
        dispatch(vehicleDealershipsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleDealershipsDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPS_DETAIL.START,
  payload,
});

export const vehicleDealershipsDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPS_DETAIL.SUCCESS,
  payload,
});

export const vehicleDealershipsDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_DEALERSHIPS_DETAIL.ERROR,
  payload,
});

export const fetchVehicleDealershipsById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleDealershipsDetailFetchStart(payload));

    vehicleDealershipsServices
      .fetchVehicleDealershipsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleDealershipsDetailFetchSuccess(data));
        } else {
          dispatch(vehicleDealershipsDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleDealershipsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleDealershipsScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_DEALERSHIPS_SCREEN,
  payload,
});

export const initCreateVehicleDealershipsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleDealershipsScreenInitStart());
  };
};


export const createVehicleDealershipsStart = () => ({
  type: CREATE_VEHICLE_DEALERSHIPS.START,
});

export const createVehicleDealershipsSuccess = (payload) => ({
  type: CREATE_VEHICLE_DEALERSHIPS.SUCCESS,
  payload,
});

export const createVehicleDealershipsError = (payload) => ({
  type: CREATE_VEHICLE_DEALERSHIPS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleDealerships = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleDealershipsStart());
    vehicleDealershipsServices.createVehicleDealerships(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleDealershipsSuccess());
        goBack();
      } else {
        dispatch(createVehicleDealershipsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleDealershipsStart = (payload) => ({
  type: EDIT_VEHICLE_DEALERSHIPS.START,
  payload
});

export const editVehicleDealershipsSuccess = (payload) => ({
  type: EDIT_VEHICLE_DEALERSHIPS.SUCCESS,
  payload,
});

export const editVehicleDealershipsError = (payload) => ({
  type: EDIT_VEHICLE_DEALERSHIPS.ERROR,
  payload,
});

export const editVehicleDealerships = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleDealershipsStart());
    vehicleDealershipsServices.editVehicleDealerships(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleDealershipsSuccess());
        goBack();
      } else {
        dispatch(editVehicleDealershipsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleDealershipsRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_DEALERSHIPS.START,
  payload
});

export const exportVehicleDealershipsRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_DEALERSHIPS.SUCCESS,
  payload,
});

export const exportVehicleDealershipsRecordError = (payload) => ({
  type: EXPORT_VEHICLE_DEALERSHIPS.ERROR,
  payload,
});

export const exportVehicleDealerships = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleDealershipsRecordStart());
    vehicleDealershipsServices.exportVehicleDealershipsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleDealershipsRecordSuccess(data));
      } else {
        dispatch(exportVehicleDealershipsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

