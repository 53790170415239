import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRentersVehicleInquiry,
    initializeRentersVehicleInquiryScreen,
    setDeleteConfirmationPopupVisibility,
    exportRentersVehicleInquiry,
    exportPDFRentersVehicleInquiry,
    exportCSVRentersVehicleInquiry
  } from "../../../redux/actions/Renters/RentersVehicleInquiry";
import RentersVehicleInquiry from '../../../component/Renters/RentersVehicleInquiry';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RentersVehicleInquiryScreen(props) {
    const {
        fetchRentersVehicleInquiry,
        initRentersVehicleInquiryScreen,
        showPageLevelLoader,
        RentersVehicleInquiryListview,
        paginationInfo,
        exportRentersVehicleInquiry,
        isRentersVehicleInquiryExportError,
        isRentersVehicleInquiryExportSuccess,
        isLoading,
        
exportPDFRentersVehicleInquiry,
exportCSVRentersVehicleInquiry,
isRentersVehicleInquiryExportPDFError,
isRentersVehicleInquiryExportPDFSuccess,
isRentersVehicleInquiryExportCSVError,
isRentersVehicleInquiryExportCSVSuccess,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRentersVehicleInquiryScreen();
        fetchRentersVehicleInquiry({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRentersVehicleInquiry(newProps);
      };
      const handleCreateRentersVehicleInquiry =() =>{
      navigate(APP_URLS.RENTERS_VEHICLEINQUIRY.CREATE);
    }
      const editRentersVehicleInquiryRoute = (id) => {
        const path = generatePath(APP_URLS.RENTERS_VEHICLEINQUIRY.EDIT, {
          id,
        });
        navigate(path);
      };
const RentersVehicleInquiryProps = {
  handleCreateRentersVehicleInquiry,
  exportRentersVehicleInquiry,
        isRentersVehicleInquiryExportError,
        isRentersVehicleInquiryExportSuccess,
        
exportPDFRentersVehicleInquiry,
exportCSVRentersVehicleInquiry,
isRentersVehicleInquiryExportPDFError,
isRentersVehicleInquiryExportPDFSuccess,
isRentersVehicleInquiryExportCSVError,
isRentersVehicleInquiryExportCSVSuccess,
  editRentersVehicleInquiryRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRentersVehicleInquirys: loadLazyData,
  ...companyData,
  tableData: {
    columns: RentersVehicleInquiryListview?.columns,
    rows: RentersVehicleInquiryListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RentersVehicleInquiry
    {...RentersVehicleInquiryProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRentersVehicleInquiryScreen: () => dispatch(initializeRentersVehicleInquiryScreen()),
      fetchRentersVehicleInquiry: (payload) => dispatch(fetchRentersVehicleInquiry(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRentersVehicleInquiry: (payload) => dispatch(exportRentersVehicleInquiry(payload)),
      exportPDFRentersVehicleInquiry: (payload) => dispatch(exportPDFRentersVehicleInquiry(payload)),
      exportCSVRentersVehicleInquiry: (payload) => dispatch(exportCSVRentersVehicleInquiry(payload)),
     
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRentersVehicleInquiryCreateSuccess: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryCreateSuccess"
      ),
      RentersVehicleInquiryListview: selectFromRentersVehicleInquiryReducer(
        state,
        "RentersVehicleInquiryListview"
      ),
      showPageLevelLoader: selectFromRentersVehicleInquiryReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRentersVehicleInquiryReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRentersVehicleInquiryReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRentersVehicleInquiryReducer(
        state,
        "isFetchingGridData"
      ),
      isRentersVehicleInquiryExportError: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryExportError"
      ),
      isRentersVehicleInquiryExportSuccess: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryExportSuccess"
      ),
      isRentersVehicleInquiryExportPDFError: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryExportPDFError"
      ),
      isRentersVehicleInquiryExportPDFSuccess: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryExportPDFSuccess"
      ),

      isRentersVehicleInquiryExportCSVError: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryExportCSVError"
      ),
      isRentersVehicleInquiryExportCSVSuccess: selectFromRentersVehicleInquiryReducer(
        state,
        "isRentersVehicleInquiryExportCSVSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRentersVehicleInquiryReducer = (state, path) => {
  return selectRentersVehicleInquiryReducer(state)[path];
};

const selectRentersVehicleInquiryReducer = ({ RentersVehicleInquiryReducer }) => {
  return RentersVehicleInquiryReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RentersVehicleInquiryScreen);
