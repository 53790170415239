import React from 'react';
import { Editor } from 'primereact/editor';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

const EzControlledEditorFloatingLabel = (props) => {
  const {
    control,
    name,
    disabled,
    type,
    rules,
    labelClassName,
    onChange,
    label,
    errorMsg,
    isError,
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className="p-float-label">
      
        <Controller
          name={name}
          control={control}
          rules={rules}
          
          render={({ field, fieldState }) => (
            <Editor
              {...field}
              style={{ height: '320px' }}
              onTextChange={(e) => field.onChange(e.htmlValue)}
            />
          )}
        />

<label id="texeditor" htmlFor={name} className={getLabelClassName()}>
          {rules?.required ? `${label}*` : label}
        </label>
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledEditorFloatingLabel.defaultProps = {
  labelClassName: '',
  disabled: false,
  type: 'text',
};

export default EzControlledEditorFloatingLabel;
