import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_DETAILREPORT_LIST, 
  TOAST, 
  FETCH_RESERVATIONS_DETAILREPORT_INIT,
  FETCH_RESERVATIONS_DETAILREPORT_DETAIL,
  EXPORT_RESERVATIONS_DETAILREPORT,
  CREATE_RESERVATIONS_DETAILREPORT,
  EDIT_RESERVATIONS_DETAILREPORT,
  INIT_CREATE_RESERVATIONS_DETAILREPORT_SCREEN,
  RESERVATIONS_DETAILREPORTDD,
  RESERVATIONS_DETAILREPORTDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Reservations/DetailReport.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    ReservationsDetailReportListview: [],
    ReservationsDetailReportDD:[],
    isReservationsDetailReportDDSuccess: false,
    isReservationsDetailReportDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isReservationsDetailReportCreateSuccess: false,
    isReservationsDetailReportCreateError: false,
    isReservationsDetailReportDetailSuccess: false,
    isReservationsDetailReportDetailError: false,
    isReservationsDetailReportEditSuccess: false,
    isReservationsDetailReportEditError: false,
    isReservationsDetailReportExportSuccess: false,
    isReservationsDetailReportExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isReservationsDetailReportDeleteSuccess: false,
    isReservationsDetailReportDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
     
      {
        colLabel: "Booking Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Pickup Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },





      {
        colLabel: "Dropoff Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Res#",
        dataKey: "res",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network Booking",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Locaiton",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Car Class",
        dataKey: "carClass",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Name",
        dataKey: "name",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Flight Info",
        dataKey: "flightInfo",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Base Rate",
        dataKey: "baseRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Days",
        dataKey: "days",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Total Fees",
        dataKey: "totalFees",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },


      {
        colLabel: "Extras",
        dataKey: "extras",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Taxes",
        dataKey: "taxes",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Total",
        dataKey: "total",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Taxes without Extras",
        dataKey: "taxeswithoutExtras",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Total without Extras",
        dataKey: "totalwithoutExtras",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Currency",
        dataKey: "currency",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Address",
        dataKey: "address",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Phone Number",
        dataKey: "phoneNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Email Address",
        dataKey: "emailAddress",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const ReservationsDetailReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RESERVATIONS_DETAILREPORT_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RESERVATIONS_DETAILREPORT_DETAIL.START:
      case EXPORT_RESERVATIONS_DETAILREPORT.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RESERVATIONS_DETAILREPORT_LIST.START:
      case CREATE_RESERVATIONS_DETAILREPORT.START:
        case RESERVATIONS_DETAILREPORTDELETE.START:
        case EDIT_RESERVATIONS_DETAILREPORT.START:
          case RESERVATIONS_DETAILREPORTDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RESERVATIONS_DETAILREPORT_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          ReservationsDetailReportListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isReservationsDetailReportCreateSuccess: false,
          isReservationsDetailReportCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          ReservationsDetailReportListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RESERVATIONS_DETAILREPORT_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          ReservationsDetailReportListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isReservationsDetailReportDeletionSuccess: false,
          isReservationsDetailReportDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RESERVATIONS_DETAILREPORT_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReservationsDetailReportDetailSuccess: true,
      };
    }
    case FETCH_RESERVATIONS_DETAILREPORT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsDetailReportDetailSuccess: false,
        isReservationsDetailReportDetailError: true,
      };
    }

    //DD
    case RESERVATIONS_DETAILREPORTDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        ReservationsDetailReportDD:temp,
        isReservationsDetailReportDDSuccess: true,
        isReservationsDetailReportDDError: false,
      };
    }
    case RESERVATIONS_DETAILREPORTDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsDetailReportDDSuccess: false,
        isReservationsDetailReportDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isReservationsDetailReportDeleteSuccess: false,
    isReservationsDetailReportDeleteError: false,
  };
}
case RESERVATIONS_DETAILREPORTDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isReservationsDetailReportDeleteSuccess: true,
    isReservationsDetailReportDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RESERVATIONS_DETAILREPORTDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isReservationsDetailReportDeleteSuccess: false,
    isReservationsDetailReportDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RESERVATIONS_DETAILREPORT.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isReservationsDetailReportExportSuccess: false,
    isReservationsDetailReportExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RESERVATIONS_DETAILREPORT.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"reservations-detail-report.xls");
   return {
    ...state,
    isReservationsDetailReportExportSuccess: true,
    isReservationsDetailReportExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RESERVATIONS_DETAILREPORT.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsDetailReportEditSuccess: false,
    isReservationsDetailReportEditError: true,
  };
}

case EDIT_RESERVATIONS_DETAILREPORT.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsDetailReportEditSuccess: true,
    isReservationsDetailReportEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RESERVATIONS_DETAILREPORT_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RESERVATIONS_DETAILREPORT.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsDetailReportCreateSuccess: false,
    isReservationsDetailReportCreateError: true,
  };
}
case CREATE_RESERVATIONS_DETAILREPORT.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsDetailReportCreateSuccess: true,
    isReservationsDetailReportCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isReservationsDetailReportCreateSuccess: false,
          isReservationsDetailReportCreateError: false,
          isReservationsDetailReportEditError: false,
          isReservationsDetailReportEditSuccess: false,
          isReservationsDetailReportDetailError: false
        };
      }
      default:
        return state;
    }
  };
  