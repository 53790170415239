import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleDealershipsScreen,
  createVehicleDealerships,
  editVehicleDealerships,
  fetchVehicleDealershipsById,
  uploadImage,
} from "../../../../redux/actions/vehicles/vehicleDealerships";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleDealershipsComponent from "../../../../component/Vehicles/VehicleDealerships/CreateVehicleDealerships";
import { useParams } from "react-router-dom";

const CreateVehicleDealershipsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleDealerships,
    editVehicleDealerships,
    isLoading,
    isVehicleDealershipsCreateSuccess,
    isVehicleDealershipsCreateError,
    isVehicleDealershipsDetailSuccess,
    isVehicleDealershipsDetailError,
    isVehicleDealershipsEditSuccess,
    isVehicleDealershipsEditError,
    isEdit,
    isView,
    initCreateVehicleDealershipsScreen,
    formFieldValueMap,
    fetchVehicleDealershipsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
  } = props;

  const { id } = useParams();
  const callAPIasync = async() =>{
    initCreateVehicleDealershipsScreen();
   if (isEdit) {
    await fetchVehicleDealershipsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleDealershipsProps = {
    createVehicleDealerships,
    initCreateVehicleDealershipsScreen,
    editVehicleDealerships,
    isLoading,
    isEdit,
    isVehicleDealershipsDetailSuccess,
    isVehicleDealershipsCreateSuccess,
    isVehicleDealershipsEditSuccess,
    isVehicleDealershipsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
  };
  const getToastProps = () => {
    if (isVehicleDealershipsCreateSuccess || isVehicleDealershipsEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleDealerships Updated Successfully"
        : "VehicleDealerships Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleDealershipsCreateError ||
      isVehicleDealershipsDetailError ||
      isVehicleDealershipsEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Dealerships";
      if (isVehicleDealershipsEditError) {
        toastTitle = "Error while updating Vehicle Dealerships";
      } else if (isVehicleDealershipsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleDealershipsComponent {...createVehicleDealershipsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleDealershipsReducer(state, "isLoading"),
   
    isVehicleDealershipsCreateSuccess: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleDealershipsCreateSuccess"
    ),
    isVehicleDealershipsCreateError: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleDealershipsCreateError"
    ),
    isVehicleDealershipsDetailSuccess: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleDealershipsDetailSuccess"
    ),
    isVehicleDealershipsDetailError: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleDealershipsDetailError"
    ),
    isVehicleDealershipsEditSuccess: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleDealershipsEditSuccess"
    ),
    isVehicleDealershipsEditError: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleDealershipsEditError"
    ),
    imageUploadResponse: selectFromVehicleDealershipsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleDealershipsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleDealershipsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromVehicleDealershipsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleDealershipsReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleDealershipsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleDealershipsScreen: () => dispatch(initCreateVehicleDealershipsScreen()),
    createVehicleDealerships: (payload) => dispatch(createVehicleDealerships(payload)),
    editVehicleDealerships: (payload) => dispatch(editVehicleDealerships(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleDealershipsById: (payload) =>
      dispatch(fetchVehicleDealershipsById(payload)),

    
  };
};

const selectFromVehicleDealershipsReducer = (state, path) => {
  return selectVehicleDealershipsReducer(state)[path];
};

const selectVehicleDealershipsReducer = ({ vehicleDealershipsReducer }) => {
  return vehicleDealershipsReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleDealershipsContainer);
