import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleCategoryScreen,
  createVehicleCategory,
  editVehicleCategory,
  fetchVehicleCategoryById,
  uploadImage
} from "../../../../redux/actions/vehicles/vehicleCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleCategoryComponent from "../../../../component/Vehicles/VehicleCategory/CreateVehicleCategory";
import { useParams } from "react-router-dom";

const CreateVehicleCategoryContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleCategory,
    editVehicleCategory,
    isLoading,
    isVehicleCategoryCreateSuccess,
    isVehicleCategoryCreateError,
    isVehicleCategoryDetailSuccess,
    isVehicleCategoryDetailError,
    isVehicleCategoryEditSuccess,
    isVehicleCategoryEditError,
    isEdit,
    isView,
    initCreateVehicleCategoryScreen,
    formFieldValueMap,
    fetchVehicleCategoryById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleCategoryScreen();
   if (isEdit) {
    await fetchVehicleCategoryById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleCategoryProps = {
    createVehicleCategory,
    initCreateVehicleCategoryScreen,
    editVehicleCategory,
    isLoading,
    isEdit,
    isVehicleCategoryDetailSuccess,
    isVehicleCategoryCreateSuccess,
    isVehicleCategoryEditSuccess,
    isVehicleCategoryEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
  };
  const getToastProps = () => {
    if (isVehicleCategoryCreateSuccess || isVehicleCategoryEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleCategory Updated Successfully"
        : "VehicleCategory Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleCategoryCreateError ||
      isVehicleCategoryDetailError ||
      isVehicleCategoryEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Category";
      if (isVehicleCategoryEditError) {
        toastTitle = "Error while updating Vehicle Category";
      } else if (isVehicleCategoryDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleCategoryComponent {...createVehicleCategoryProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleCategoryReducer(state, "isLoading"),
   
    isVehicleCategoryCreateSuccess: selectFromVehicleCategoryReducer(
      state,
      "isVehicleCategoryCreateSuccess"
    ),
    isVehicleCategoryCreateError: selectFromVehicleCategoryReducer(
      state,
      "isVehicleCategoryCreateError"
    ),
    isVehicleCategoryDetailSuccess: selectFromVehicleCategoryReducer(
      state,
      "isVehicleCategoryDetailSuccess"
    ),
    isVehicleCategoryDetailError: selectFromVehicleCategoryReducer(
      state,
      "isVehicleCategoryDetailError"
    ),
    isVehicleCategoryEditSuccess: selectFromVehicleCategoryReducer(
      state,
      "isVehicleCategoryEditSuccess"
    ),
    isVehicleCategoryEditError: selectFromVehicleCategoryReducer(
      state,
      "isVehicleCategoryEditError"
    ),
    imageUploadResponse: selectFromVehicleCategoryReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleCategoryReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleCategoryReducer(
      state,
      "isUploadImageError"
    ),
    formFieldValueMap: selectFromVehicleCategoryReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleCategoryScreen: () => dispatch(initCreateVehicleCategoryScreen()),
    createVehicleCategory: (payload) => dispatch(createVehicleCategory(payload)),
    editVehicleCategory: (payload) => dispatch(editVehicleCategory(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleCategoryById: (payload) =>
      dispatch(fetchVehicleCategoryById(payload)),
  };
};

const selectFromVehicleCategoryReducer = (state, path) => {
  return selectVehicleCategoryReducer(state)[path];
};

const selectVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleCategoryContainer);
