//import { post, put } from "axios";
import axios from 'axios';
import { getApiAsyn, baseUrl, getApiAsynarrayBuffer } from "../../PostAPI";
import { handleAPIError, APIError } from "../../common/errorHandler";
import { API_URL } from "../../../utils/constant";
import { extractData } from "../../../utils";
import { getDecoratedUrl } from "../../common/urlService";


const { post, put, get } = axios;
export const fetchLocation = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.LOCATION.GET,
    payload,
  });
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchCategory = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.LOCATION.GETCATEGORY,
    payload,
  });
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



// // TODO (subham)- Refactor this to make it reusable logic
// const multipartDataUploadApi = (url, { files, data }, isEdit = false) => {
//   const formPayload = new FormData();
//   // data part of multipart data
//   const json = JSON.stringify(data);
//   const blobData = new Blob([json], {
//     type: "application/json",
//   });
//   formPayload.append("data", blobData);

//   // file part of multipart data
//   files?.forEach((file) => {
//     formPayload.append("file", file);
//   });

//   const config = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       authorization: localStorage.getItem("token"),
//     },
//   };

//   if (isEdit) {
//     return put(baseUrl + url, formPayload, config);
//   } else {
//     return post(baseUrl + url, formPayload, config);
//   }
// };

const dataUploadApi = (url, data, isEdit = false) => {
  // const formPayload = new FormData();
  // // data part of multipart data
  // const json = JSON.stringify(data);
  // const blobData = new Blob([json], {
  //   type: 'application/json',
  // });
  // formPayload.append('data', blobData);

  // // file part of multipart data
  // files?.forEach((file) => {
  //   formPayload.append('file', file);
  // });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };

  if (isEdit) {
    return post(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createLocation = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.LOCATION.CREATE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editLocation = async (payload) => {
  const url = API_URL.LOCATION.EDIT.replace(":id", payload?.id);

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};





const dataExportApi = (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };
    return get(baseUrl + url, data, config);
};

export const exportLocationRecord = async (payload) => {
  const url = API_URL.LOCATION.EXPORT;
  try {
    let result = await getApiAsynarrayBuffer(url, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};




export const fetchLocationById = async (payload) => {
  const url = API_URL.LOCATION.GET_BY_ID.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchLocationDropdown = async (payload) => {
  const url = API_URL.LOCATION.MODELDROPDOWN;
  try {
    let result = await getApiAsyn(url + "?userId="+ localStorage.getItem("userId"), localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};