import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleVehicleOperations,
    initializeVehicleVehicleOperationsScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleVehicleOperations
  } from "../../../redux/actions/vehicles/vehicleVehicleOperations";
import VehicleVehicleOperations from '../../../component/Vehicles/VehicleVehicleOperations';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleVehicleOperationsScreen(props) {
    const {
        fetchVehicleVehicleOperations,
        initVehicleVehicleOperationsScreen,
        showPageLevelLoader,
        vehicleVehicleOperationsListview,
        paginationInfo,
        exportVehicleVehicleOperations,
        isVehicleVehicleOperationsExportError,
        isVehicleVehicleOperationsExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleVehicleOperationsScreen();
        fetchVehicleVehicleOperations({ sortField: 'id', userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newPyload={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchVehicleVehicleOperations(newPyload);
      };
      const handleCreateVehicleVehicleOperations =() =>{
      navigate(APP_URLS.VEHICLE_VEHICLEOPERATIONS.CREATE);
    }
      const editVehicleVehicleOperationsRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_VEHICLEOPERATIONS.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleVehicleOperations = {
  handleCreateVehicleVehicleOperations,
  exportVehicleVehicleOperations,
        isVehicleVehicleOperationsExportError,
        isVehicleVehicleOperationsExportSuccess,
  editVehicleVehicleOperationsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleVehicleOperationss: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleVehicleOperationsListview?.columns,
    rows: vehicleVehicleOperationsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleVehicleOperations
    {...vehicleVehicleOperations}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleVehicleOperationsScreen: () => dispatch(initializeVehicleVehicleOperationsScreen()),
      fetchVehicleVehicleOperations: (payload) => dispatch(fetchVehicleVehicleOperations(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleVehicleOperations: (payload) => dispatch(exportVehicleVehicleOperations(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleVehicleOperationsCreateSuccess: selectFromVehicleVehicleOperationsReducer(
        state,
        "isVehicleVehicleOperationsCreateSuccess"
      ),
      vehicleVehicleOperationsListview: selectFromVehicleVehicleOperationsReducer(
        state,
        "vehicleVehicleOperationsListview"
      ),
      showPageLevelLoader: selectFromVehicleVehicleOperationsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleVehicleOperationsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleVehicleOperationsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleVehicleOperationsReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleVehicleOperationsExportError: selectFromVehicleVehicleOperationsReducer(
        state,
        "isVehicleVehicleOperationsExportError"
      ),
      isVehicleVehicleOperationsExportSuccess: selectFromVehicleVehicleOperationsReducer(
        state,
        "isVehicleVehicleOperationsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleVehicleOperationsReducer = (state, path) => {
  return selectVehicleVehicleOperationsReducer(state)[path];
};

const selectVehicleVehicleOperationsReducer = ({ vehicleVehicleOperationsReducer }) => {
  return vehicleVehicleOperationsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleVehicleOperationsScreen);
