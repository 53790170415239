import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleBodyShopServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_BODYSHOP_INIT, 
  FETCH_VEHICLE_BODYSHOP_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_BODYSHOP_SCREEN,
  CREATE_VEHICLE_BODYSHOP,
  EDIT_VEHICLE_BODYSHOP,
  TOAST,
  FETCH_VEHICLE_BODYSHOP_DETAIL,
  EXPORT_VEHICLE_BODYSHOP,
  VEHICLE_BODYSHOPDD
} from "../../../types/vehiclebodyshop.type";
export const initializeVehicleBodyShopScreen = () => ({ type: FETCH_VEHICLE_BODYSHOP_INIT });

export const vehicleBodyShopFetchStart = (payload) => ({
  type: FETCH_VEHICLE_BODYSHOP_LIST.START,
  payload,
});

export const vehicleBodyShopFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_BODYSHOP_LIST.SUCCESS,
  payload,
});

export const vehicleBodyShopFetchError = (payload) => ({
  type: FETCH_VEHICLE_BODYSHOP_LIST.ERROR,
  payload,
});

export const fetchVehicleBodyShop = (payload) => {
  return (dispatch) => {
    dispatch(vehicleBodyShopFetchStart(payload));
    vehicleBodyShopServices.fetchVehicleBodyShop(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleBodyShopFetchSuccess(data));
      } else {
        dispatch(vehicleBodyShopFetchError(data));
      }
    });
  };
};



// DD
export const vehicleBodyShopDDFetchStart = (payload) => ({
  type: VEHICLE_BODYSHOPDD.START,
  payload,
});

export const vehicleBodyShopDDFetchSuccess = (payload) => ({
  type:VEHICLE_BODYSHOPDD.SUCCESS,
  payload,
});

export const vehicleBodyShopDDFetchError = (payload) => ({
  type: VEHICLE_BODYSHOPDD.ERROR,
  payload,
});

export const fetchVehicleBodyShopDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleBodyShopDDFetchStart(payload));
    vehicleBodyShopServices.fetchVehicleBodyShopDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleBodyShopDDFetchSuccess(data));
      } else {
        dispatch(vehicleBodyShopDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleBodyShopDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_BODYSHOP_DETAIL.START,
  payload,
});

export const vehicleBodyShopDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_BODYSHOP_DETAIL.SUCCESS,
  payload,
});

export const vehicleBodyShopDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_BODYSHOP_DETAIL.ERROR,
  payload,
});

export const fetchVehicleBodyShopById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleBodyShopDetailFetchStart(payload));

    vehicleBodyShopServices
      .fetchVehicleBodyShopById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleBodyShopDetailFetchSuccess(data));
        } else {
          dispatch(vehicleBodyShopDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleBodyShopServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleBodyShopScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_BODYSHOP_SCREEN,
  payload,
});

export const initCreateVehicleBodyShopScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleBodyShopScreenInitStart());
  };
};


export const createVehicleBodyShopStart = () => ({
  type: CREATE_VEHICLE_BODYSHOP.START,
});

export const createVehicleBodyShopSuccess = (payload) => ({
  type: CREATE_VEHICLE_BODYSHOP.SUCCESS,
  payload,
});

export const createVehicleBodyShopError = (payload) => ({
  type: CREATE_VEHICLE_BODYSHOP.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleBodyShop = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleBodyShopStart());
    vehicleBodyShopServices.createVehicleBodyShop(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleBodyShopSuccess());
        goBack();
      } else {
        dispatch(createVehicleBodyShopError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleBodyShopStart = (payload) => ({
  type: EDIT_VEHICLE_BODYSHOP.START,
  payload
});

export const editVehicleBodyShopSuccess = (payload) => ({
  type: EDIT_VEHICLE_BODYSHOP.SUCCESS,
  payload,
});

export const editVehicleBodyShopError = (payload) => ({
  type: EDIT_VEHICLE_BODYSHOP.ERROR,
  payload,
});

export const editVehicleBodyShop = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleBodyShopStart());
    vehicleBodyShopServices.editVehicleBodyShop(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleBodyShopSuccess());
        goBack();
      } else {
        dispatch(editVehicleBodyShopError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleBodyShopRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_BODYSHOP.START,
  payload
});

export const exportVehicleBodyShopRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_BODYSHOP.SUCCESS,
  payload,
});

export const exportVehicleBodyShopRecordError = (payload) => ({
  type: EXPORT_VEHICLE_BODYSHOP.ERROR,
  payload,
});

export const exportVehicleBodyShop = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleBodyShopRecordStart());
    vehicleBodyShopServices.exportVehicleBodyShopRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleBodyShopRecordSuccess(data));
      } else {
        dispatch(exportVehicleBodyShopRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

