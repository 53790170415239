import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getFormBaseValidationRule } from "../../utils/form/form-utils"
import EzControlledInputWithFloatingLabel from '../../common/EzForm/EzControlledInput/WithFloatingLabel';
import { Box, Button, Typography } from '@mui/material';
import styleCss from './style.module.css'
import { postApiLoginAsyn } from "../../services/PostAPI.js"
import { Navigate } from 'react-router-dom';
import EzControlledPasswordWithFloatingLabel from '../../common/EzForm/EZControlledPassword/WithFloatingLabel';
import { useStyles } from './style';
import Logoicon from '../../images/logo.png';
import { API_URL } from '../../utils/constant.js';
import Forgot from './Forgot.js';

export default function Login(props) {
  const classes = useStyles()
  const formFieldValueMap = {
    email: "",
    password: "",
  }
  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [isForot, setIsForot] = useState(false);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const userPassword = (name, label) =>{
    return(
      <EzControlledPasswordWithFloatingLabel
      name={name}
        control={control}
        label={label}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        />
    )
  }
  const genericInput = (name, label) => {
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={label}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  const routForgot =() =>{
    setIsForot(!isForot)
  }
  const onSubmit = async (data) => {

    let sendData = {
      email: data.email,
      password: data.password,
    }
    let res = await postApiLoginAsyn(API_URL.login, sendData);
    if (res.token != null) {
      localStorage.setItem("username", res.userName);
      localStorage.setItem("token", res.token);
      localStorage.setItem("userId", res.userId);
      props.callBack();
    }
    else {
      setError(true);
      setErrorMessage("Invalid Credential");
      localStorage.removeItem("token");
      localStorage.removeItem("username")
    }
  }
  if (
    localStorage.getItem("token") !=="" &&
    localStorage.getItem("token") != null &&
    localStorage.getItem("token") !== undefined
  ) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <div id="loginwrap" className={styleCss.loginwrapper}>
      {!isForot ? (
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <Box className={styleCss.loginbox}>
          <Box className={styleCss.logoadmin}>
            <img src={Logoicon} alt='Rez Revolution' />
          </Box>
          <Box className={styleCss.logotext}>Sign In to Admin

          <Box>
          {error === true ? (
                <Box className={classes.loginerror}>
                  {errorMessage} Please try again.
                </Box>
              ) : null}
          </Box>
          </Box>
         
          <Box sx={{ width: '100%' }}>
          <label className={styleCss.user_icon}><span class="pi pi-user"></span></label>
            {genericInput("email", "Enter Username")}
          </Box>
          <Box sx={{ width: '100%' }}>
          <label className={styleCss.user_icon}><span class="pi pi-lock"></span></label>
            {userPassword("password", "Enter Password")}
          </Box>
          <Box className={classes.buttonpan}>
           
            <Button
              variant="contained"
              color="secondary"
              className={classes.loginbutton}
              type="submit">Login</Button>
         
         <Button
          onClick={routForgot}
             className={classes.forgot}
              type="button">Forgot Password</Button> 
        </Box>
        </Box>
      </form>)
      :(
    <Forgot backtologin={routForgot} />

      )}
    </div>
  )
}