import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { RentersVehicleInquiryServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_VEHICLEINQUIRY_INIT, 
  FETCH_RENTERS_VEHICLEINQUIRY_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RENTERS_VEHICLEINQUIRY_SCREEN,
  CREATE_RENTERS_VEHICLEINQUIRY,
  EDIT_RENTERS_VEHICLEINQUIRY,
  TOAST,
  FETCH_RENTERS_VEHICLEINQUIRY_DETAIL,
  EXPORT_RENTERS_VEHICLEINQUIRY,
  EXPORTPDF_RENTERS_VEHICLEINQUIRY,
  EXPORTCSV_RENTERS_VEHICLEINQUIRY,
  RENTERS_VEHICLEINQUIRYDD,
  RENTERS_VEHICLEINQUIRYDELETE
} from "../../../types/Renters/VehicleInquiry.type";
export const initializeRentersVehicleInquiryScreen = () => ({ type: FETCH_RENTERS_VEHICLEINQUIRY_INIT });

export const RentersVehicleInquiryFetchStart = (payload) => ({
  type: FETCH_RENTERS_VEHICLEINQUIRY_LIST.START,
  payload,
});

export const RentersVehicleInquiryFetchSuccess = (payload) => ({
  type:FETCH_RENTERS_VEHICLEINQUIRY_LIST.SUCCESS,
  payload,
});

export const RentersVehicleInquiryFetchError = (payload) => ({
  type: FETCH_RENTERS_VEHICLEINQUIRY_LIST.ERROR,
  payload,
});

export const fetchRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(RentersVehicleInquiryFetchStart(payload));
    RentersVehicleInquiryServices.fetchRentersVehicleInquiry(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersVehicleInquiryFetchSuccess(data));
      } else {
        dispatch(RentersVehicleInquiryFetchError(data));
      }
    });
  };
};



// DD
export const RentersVehicleInquiryDDFetchStart = (payload) => ({
  type: RENTERS_VEHICLEINQUIRYDD.START,
  payload,
});

export const RentersVehicleInquiryDDFetchSuccess = (payload) => ({
  type:RENTERS_VEHICLEINQUIRYDD.SUCCESS,
  payload,
});

export const RentersVehicleInquiryDDFetchError = (payload) => ({
  type: RENTERS_VEHICLEINQUIRYDD.ERROR,
  payload,
});

export const fetchRentersVehicleInquiryDD = (payload) => {
  return (dispatch) => {
    dispatch(RentersVehicleInquiryDDFetchStart(payload));
    RentersVehicleInquiryServices.fetchRentersVehicleInquiryDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersVehicleInquiryDDFetchSuccess(data));
      } else {
        dispatch(RentersVehicleInquiryDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const RentersVehicleInquiryDetailFetchStart = (payload) => ({
  type: FETCH_RENTERS_VEHICLEINQUIRY_DETAIL.START,
  payload,
});

export const RentersVehicleInquiryDetailFetchSuccess = (payload) => ({
  type: FETCH_RENTERS_VEHICLEINQUIRY_DETAIL.SUCCESS,
  payload,
});

export const RentersVehicleInquiryDetailFetchError = (payload) => ({
  type: FETCH_RENTERS_VEHICLEINQUIRY_DETAIL.ERROR,
  payload,
});

export const fetchRentersVehicleInquiryById = (payload) => {
  return (dispatch) => {
    dispatch(RentersVehicleInquiryDetailFetchStart(payload));

    RentersVehicleInquiryServices
      .fetchRentersVehicleInquiryById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersVehicleInquiryDetailFetchSuccess(data));
        } else {
          dispatch(RentersVehicleInquiryDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    RentersVehicleInquiryServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRentersVehicleInquiryScreenInitStart = (payload) => ({
  type: INIT_CREATE_RENTERS_VEHICLEINQUIRY_SCREEN,
  payload,
});

export const initCreateRentersVehicleInquiryScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRentersVehicleInquiryScreenInitStart());
  };
};


export const createRentersVehicleInquiryStart = () => ({
  type: CREATE_RENTERS_VEHICLEINQUIRY.START,
});

export const createRentersVehicleInquirySuccess = (payload) => ({
  type: CREATE_RENTERS_VEHICLEINQUIRY.SUCCESS,
  payload,
});

export const createRentersVehicleInquiryError = (payload) => ({
  type: CREATE_RENTERS_VEHICLEINQUIRY.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(createRentersVehicleInquiryStart());
    RentersVehicleInquiryServices.createRentersVehicleInquiry(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRentersVehicleInquirySuccess());
        goBack();
      } else {
        dispatch(createRentersVehicleInquiryError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRentersVehicleInquiryStart = (payload) => ({
  type: EDIT_RENTERS_VEHICLEINQUIRY.START,
  payload
});

export const editRentersVehicleInquirySuccess = (payload) => ({
  type: EDIT_RENTERS_VEHICLEINQUIRY.SUCCESS,
  payload,
});

export const editRentersVehicleInquiryError = (payload) => ({
  type: EDIT_RENTERS_VEHICLEINQUIRY.ERROR,
  payload,
});

export const editRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(editRentersVehicleInquiryStart());
    RentersVehicleInquiryServices.editRentersVehicleInquiry(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRentersVehicleInquirySuccess());
        goBack();
      } else {
        dispatch(editRentersVehicleInquiryError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRentersVehicleInquiryRecordStart = (payload) => ({
  type: EXPORT_RENTERS_VEHICLEINQUIRY.START,
  payload
});

export const exportRentersVehicleInquiryRecordSuccess = (payload) => ({
  type: EXPORT_RENTERS_VEHICLEINQUIRY.SUCCESS,
  payload,
});

export const exportRentersVehicleInquiryRecordError = (payload) => ({
  type: EXPORT_RENTERS_VEHICLEINQUIRY.ERROR,
  payload,
});

export const exportRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(exportRentersVehicleInquiryRecordStart());
    RentersVehicleInquiryServices.exportRentersVehicleInquiryRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRentersVehicleInquiryRecordSuccess(data));
      } else {
        dispatch(exportRentersVehicleInquiryRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const RentersVehicleInquiryDeleteStart = (payload) => ({
  type: RENTERS_VEHICLEINQUIRYDELETE.START,
  payload,
});

export const RentersVehicleInquiryDeleteSuccess = (payload) => ({
  type: RENTERS_VEHICLEINQUIRYDELETE.SUCCESS,
  payload,
});

export const RentersVehicleInquiryDeleteError = (payload) => ({
  type: RENTERS_VEHICLEINQUIRYDELETE.ERROR,
  payload,
});

export const deleteRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(RentersVehicleInquiryDeleteStart(payload));

    RentersVehicleInquiryServices
      .deleteRentersVehicleInquiry(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersVehicleInquiryDeleteSuccess(data));
          dispatch(fetchRentersVehicleInquiry(payload?.params));
        } else {
          dispatch(RentersVehicleInquiryDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/

// ExportPDF
export const exportPDFRentersVehicleInquiryRecordStart = (payload) => ({
  type: EXPORTPDF_RENTERS_VEHICLEINQUIRY.START,
  payload
});

export const exportPDFRentersVehicleInquiryRecordSuccess = (payload) => ({
  type: EXPORTPDF_RENTERS_VEHICLEINQUIRY.SUCCESS,
  payload,
});

export const exportPDFRentersVehicleInquiryRecordError = (payload) => ({
  type: EXPORTPDF_RENTERS_VEHICLEINQUIRY.ERROR,
  payload,
});

export const exportPDFRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(exportPDFRentersVehicleInquiryRecordStart());
    RentersVehicleInquiryServices.exportPDFRentersVehicleInquiryRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportPDFRentersVehicleInquiryRecordSuccess(data));
      } else {
        dispatch(exportPDFRentersVehicleInquiryRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};



// CSV
export const exportCSVRentersVehicleInquiryRecordStart = (payload) => ({
  type: EXPORTCSV_RENTERS_VEHICLEINQUIRY.START,
  payload
});

export const exportCSVRentersVehicleInquiryRecordSuccess = (payload) => ({
  type: EXPORTCSV_RENTERS_VEHICLEINQUIRY.SUCCESS,
  payload,
});

export const exportCSVRentersVehicleInquiryRecordError = (payload) => ({
  type: EXPORTCSV_RENTERS_VEHICLEINQUIRY.ERROR,
  payload,
});

export const exportCSVRentersVehicleInquiry = (payload) => {
  return (dispatch) => {
    dispatch(exportCSVRentersVehicleInquiryRecordStart());
    RentersVehicleInquiryServices.exportCSVRentersVehicleInquiryRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportCSVRentersVehicleInquiryRecordSuccess(data));
      } else {
        dispatch(exportCSVRentersVehicleInquiryRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};