import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { ReservationsPrepaidBookingsReportServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_INIT, 
  FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SCREEN,
  CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT,
  EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT,
  TOAST,
  FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL,
  EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT,
  RESERVATIONS_PREPAIDBOOKINGSREPORTDD,
  RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE
} from "../../../types/Reservations/PrepaidBookingsReport.type";
export const initializeReservationsPrepaidBookingsReportScreen = () => ({ type: FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_INIT });

export const ReservationsPrepaidBookingsReportFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST.START,
  payload,
});

export const ReservationsPrepaidBookingsReportFetchSuccess = (payload) => ({
  type:FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST.SUCCESS,
  payload,
});

export const ReservationsPrepaidBookingsReportFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST.ERROR,
  payload,
});

export const fetchReservationsPrepaidBookingsReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsPrepaidBookingsReportFetchStart(payload));
    ReservationsPrepaidBookingsReportServices.fetchReservationsPrepaidBookingsReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsPrepaidBookingsReportFetchSuccess(data));
      } else {
        dispatch(ReservationsPrepaidBookingsReportFetchError(data));
      }
    });
  };
};



// DD
export const ReservationsPrepaidBookingsReportDDFetchStart = (payload) => ({
  type: RESERVATIONS_PREPAIDBOOKINGSREPORTDD.START,
  payload,
});

export const ReservationsPrepaidBookingsReportDDFetchSuccess = (payload) => ({
  type:RESERVATIONS_PREPAIDBOOKINGSREPORTDD.SUCCESS,
  payload,
});

export const ReservationsPrepaidBookingsReportDDFetchError = (payload) => ({
  type: RESERVATIONS_PREPAIDBOOKINGSREPORTDD.ERROR,
  payload,
});

export const fetchReservationsPrepaidBookingsReportDD = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsPrepaidBookingsReportDDFetchStart(payload));
    ReservationsPrepaidBookingsReportServices.fetchReservationsPrepaidBookingsReportDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsPrepaidBookingsReportDDFetchSuccess(data));
      } else {
        dispatch(ReservationsPrepaidBookingsReportDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const ReservationsPrepaidBookingsReportDetailFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL.START,
  payload,
});

export const ReservationsPrepaidBookingsReportDetailFetchSuccess = (payload) => ({
  type: FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL.SUCCESS,
  payload,
});

export const ReservationsPrepaidBookingsReportDetailFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL.ERROR,
  payload,
});

export const fetchReservationsPrepaidBookingsReportById = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsPrepaidBookingsReportDetailFetchStart(payload));

    ReservationsPrepaidBookingsReportServices
      .fetchReservationsPrepaidBookingsReportById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsPrepaidBookingsReportDetailFetchSuccess(data));
        } else {
          dispatch(ReservationsPrepaidBookingsReportDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    ReservationsPrepaidBookingsReportServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createReservationsPrepaidBookingsReportScreenInitStart = (payload) => ({
  type: INIT_CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SCREEN,
  payload,
});

export const initCreateReservationsPrepaidBookingsReportScreen = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsPrepaidBookingsReportScreenInitStart());
  };
};


export const createReservationsPrepaidBookingsReportStart = () => ({
  type: CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT.START,
});

export const createReservationsPrepaidBookingsReportSuccess = (payload) => ({
  type: CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT.SUCCESS,
  payload,
});

export const createReservationsPrepaidBookingsReportError = (payload) => ({
  type: CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReservationsPrepaidBookingsReport = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsPrepaidBookingsReportStart());
    ReservationsPrepaidBookingsReportServices.createReservationsPrepaidBookingsReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReservationsPrepaidBookingsReportSuccess());
        goBack();
      } else {
        dispatch(createReservationsPrepaidBookingsReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editReservationsPrepaidBookingsReportStart = (payload) => ({
  type: EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT.START,
  payload
});

export const editReservationsPrepaidBookingsReportSuccess = (payload) => ({
  type: EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT.SUCCESS,
  payload,
});

export const editReservationsPrepaidBookingsReportError = (payload) => ({
  type: EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT.ERROR,
  payload,
});

export const editReservationsPrepaidBookingsReport = (payload) => {
  return (dispatch) => {
    dispatch(editReservationsPrepaidBookingsReportStart());
    ReservationsPrepaidBookingsReportServices.editReservationsPrepaidBookingsReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReservationsPrepaidBookingsReportSuccess());
        goBack();
      } else {
        dispatch(editReservationsPrepaidBookingsReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportReservationsPrepaidBookingsReportRecordStart = (payload) => ({
  type: EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT.START,
  payload
});

export const exportReservationsPrepaidBookingsReportRecordSuccess = (payload) => ({
  type: EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT.SUCCESS,
  payload,
});

export const exportReservationsPrepaidBookingsReportRecordError = (payload) => ({
  type: EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT.ERROR,
  payload,
});

export const exportReservationsPrepaidBookingsReport = (payload) => {
  return (dispatch) => {
    dispatch(exportReservationsPrepaidBookingsReportRecordStart());
    ReservationsPrepaidBookingsReportServices.exportReservationsPrepaidBookingsReportRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportReservationsPrepaidBookingsReportRecordSuccess(data));
      } else {
        dispatch(exportReservationsPrepaidBookingsReportRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const ReservationsPrepaidBookingsReportDeleteStart = (payload) => ({
  type: RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE.START,
  payload,
});

export const ReservationsPrepaidBookingsReportDeleteSuccess = (payload) => ({
  type: RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE.SUCCESS,
  payload,
});

export const ReservationsPrepaidBookingsReportDeleteError = (payload) => ({
  type: RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE.ERROR,
  payload,
});

export const deleteReservationsPrepaidBookingsReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsPrepaidBookingsReportDeleteStart(payload));

    ReservationsPrepaidBookingsReportServices
      .deleteReservationsPrepaidBookingsReport(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsPrepaidBookingsReportDeleteSuccess(data));
          dispatch(fetchReservationsPrepaidBookingsReport(payload?.params));
        } else {
          dispatch(ReservationsPrepaidBookingsReportDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/