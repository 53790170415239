import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_MAKE_LIST, 
  TOAST, 
  FETCH_VEHICLE_MAKE_INIT,
  FETCH_VEHICLE_MAKE_DETAIL,
  EXPORT_VEHICLE_MAKE,
  CREATE_VEHICLE_MAKE,
  EDIT_VEHICLE_MAKE,
  INIT_CREATE_VEHICLE_MAKE_SCREEN,
  VEHICLE_MAKEDD
} from "../../../types/vehiclemake.type";


  const formFieldValueMap = {
    id:null,
    title:"",
    crtBy:"",
    crtTime:"",
    modTime:"",
    status:1,
    userId:""

  };
  
  const INITIAL_STATE = {
    vehicleMakeListview: [],
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleMakeCreateSuccess: false,
    isVehicleMakeCreateError: false,
    isVehicleMakeDetailSuccess: false,
    isVehicleMakeDetailError: false,
    isVehicleMakeEditSuccess: false,
    isVehicleMakeEditError: false,
    isVehicleMakeExportSuccess: false,
    isVehicleMakeExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isVehicleMakeDDSuccess: false,
    isVehicleMakeDDError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        title,
        crtBy,
        crtTime,
        modTime,
        status,
        userId,
        modBy
      } = item;
  
      const transformedValues = {
        id,
        title,
        crtBy,
        crtTime,
        modTime,
        status,
        userId,
        modBy
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Make",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleMakeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_MAKE_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_MAKE_DETAIL.START:
      case EXPORT_VEHICLE_MAKE.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_MAKE_LIST.START:
      case CREATE_VEHICLE_MAKE.START:
        case EDIT_VEHICLE_MAKE.START:
          case VEHICLE_MAKEDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_MAKE_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleMakeListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleMakeCreateSuccess: false,
          isVehicleMakeCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleMakeListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_MAKE_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleMakeListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleMakeDeletionSuccess: false,
          isvehicleMakeDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_MAKE_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleMakeDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_MAKE_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleMakeDetailSuccess: false,
        isVehicleMakeDetailError: true,
      };
    }

 //DD
 case VEHICLE_MAKEDD.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    vehicleMakeDD:temp,
    isVehicleMakeDDSuccess: true,
    isVehicleMakeDDError: false,
  };
}
case VEHICLE_MAKEDD.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isVehicleMakeDDSuccess: false,
isVehicleMakeDDError: true,
  };
}
//END DD
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_MAKE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleMakeExportSuccess: false,
    isVehicleMakeExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_MAKE.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-make.xls");
   return {
    ...state,
    isVehicleMakeExportSuccess: true,
    isVehicleMakeExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_MAKE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleMakeEditSuccess: false,
    isVehicleMakeEditError: true,
  };
}

case EDIT_VEHICLE_MAKE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleMakeEditSuccess: true,
    isVehicleMakeEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_MAKE_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_MAKE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleMakeCreateSuccess: false,
    isVehicleMakeCreateError: true,
  };
}
case CREATE_VEHICLE_MAKE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleMakeCreateSuccess: true,
    isVehicleMakeCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleMakeCreateSuccess: false,
          isVehicleMakeCreateError: false,
          isVehicleMakeEditError: false,
          isVehicleMakeEditSuccess: false,
          isVehicleMakeDetailError: false
        };
      }
      default:
        return state;
    }
  };
  