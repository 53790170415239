import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../utils/table/gridUtils";
import {
  FETCH_LOCATION_LIST, 
  TOAST, 
  FETCH_LOCATION_INIT,
  FETCH_CATEGORY_LIST, 
  FETCH_LOCATION_DETAIL,
  EXPORT_LOCATION,
  CREATE_LOCATION,
  EDIT_LOCATION,
  INIT_CREATE_LOCATION_SCREEN,
  LOCATIONDD
} from "../../types/location.type";

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
const isDateField = (key) => {
  const dateFields = [START_DATE, END_DATE];
  return dateFields.includes(key);
};
  const formFieldValueMap = {
    id: null,
    address:"",
  afterHourFee:"",
  afterHourFeeStatus:"",
  airportChargeStatus:"",
  airportCode:"",
  airportConcessionFeeRate:"",
  airportFacilityCharge:"",
  airportFacilityChargeMaxDays:"",
  anyAirportLocation:"",
  categoryId:"",
  city:"",
  concessionFeeType:"",
  contact:"",
  country:"",
  crdBy:"",
  crtTime:"",
  currencyCode:"",
  energyRecoveryFee:"",
  friAfterHourEnd:"",
  friEndHour:"",
  friStartHour:"",
  gmapLink:"",
  latitude:"",
  locationName:"",
  locationPolicy:"",
  longitude:"",
  maintenanceFee:"",
  modBy:"",
  modTime:"",
  monAfterHourEnd:"",
  monEndHour:"",
  monStartHour:"",
  pin:"",
  prefixId:"",
  province:"",
  resPrefix:"",
  roadSideService:"",
  satAfterHourEnd:"",
  satEndHour:"",
  satStartHour:"",
  shuttleHours:"",
  status:"",
  sunAfterHourEnd:"",
  sunEndHour:"",
  sunStartHour:"",
  tax:"",
  taxPercent:"",
  thuAfterHourEnd:"",
  thuEndHour:"",
  thuStartHour:"",
  tueAfterHourEnd:"",
  tueEndHour:"",
  tueStartHour:"",
  underageRate:"",
  userId:"",
  username:"",
  vehicleLicenseFee:"",
  wedAfterHourEnd:"",
  wedEndHour:"",
  wedStartHour:"",
  weekEndHourEnd:"",
  weekEndHourStart:"",
  weekHourEnd:"",
  weekHourStart:"",
  workingHourStartEnd:"",
  "pcAdditionalDriver": "",
  "pcAddress": "",
  "pcAgeRequirement": "",
  "pcCancellation": "",
  "pcDeliveryReturn": "",
  "pcDisclaimer": "",
  "pcDriverLicense": "",
  "pcDrivingArea": "",
  "pcEarlyReturns": "",
  "pcExclusion": "",
  "pcFuelPolicy": "",
  "pcHrsOperations": "",
  "pcInsuranceValidity": "",
  "pcLdwCdw": "",
  "pcMileageRestriction": "",
  "pcMovingTruck": "",
  "pcNonSpecial": "",
  "pcOtherServices": "",
  "pcOutOfProvince": "",
  "pcPaymentCharge": "",
  "pcPaymentMethod": "",
  "pcPickupDirection": "",
  "pcPickupDropoff": "",
  "pcRentalDamage": "",
  "pcRentalExtension": "",
  "pcSecurityDeposit": "",
  "pcSpecialLdw": "",
  "pcStandardTerms": "",
  "pcUseOfVehicle": "",
  "pcZoomCommitment": "",


  locationExtraHourRequests: [
    {
      id: 0,
      status:true,
      [START_DATE]: "",
      [END_DATE]: "",
      startHour: '',
      endHour: '',
      afterEndHour: '',
    }
  ]
  };
  
  const INITIAL_STATE = {
    locationListview: [],
    categoryListview: [],
    categoryDD: [],
    locationDD:[],
    islocationDDSuccess: false,
    islocationDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isCreateSuccess: false,
    isCreateError: false,
    isDetailSuccess: false,
    isDetailError: false,
    isEditSuccess: false,
    isEditError: false,
    isLocationExportSuccess: false,
    isLocationExportError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        address,
        afterHourFee,
        airportChargeStatus,
        airportCode,
        airportConcessionFeeRate,
        airportFacilityCharge,
        airportFacilityChargeMaxDays,
        city,
        contact,
        country,
        crtTime,
        currencyCode,
        gmapLink,
        modTime,
        pin,
        province,
        resPrefix,
        status,
        tax,
        underageRate,
        username,
        workingHourStartEnd,
        locationName,
      } = item;
  
      const transformedValues = {
        id,
        locationName,
        address,
        afterHourFee,
        airportChargeStatus,
        airportCode,
        airportConcessionFeeRate,
        airportFacilityCharge,
        airportFacilityChargeMaxDays,
        city,
        contact,
        country,
        crtTime,
        currencyCode,
        gmapLink,
        modTime,
        pin,
        province,
        resPrefix,
        status,
        tax,
        underageRate,
        username,
        workingHourStartEnd,
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location Name",
        dataKey: "locationName",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Address",
        dataKey: "address",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "After Hour Fee",
        dataKey:'afterHourFee',
        extraProps: setColumnDefaultExtraProps({
          isSortable: true,
            shouldFilter: false,
        }),
      },
      {
        colLabel: "Airport Code",
        dataKey: "airportCode",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Airport Concession Fee Rate",
        dataKey: "airportConcessionFeeRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Airport Facility Charge",
        dataKey: "airportFacilityCharge",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Airport Facility Charge Max Days",
        dataKey: "airportFacilityChargeMaxDays",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "City",
        dataKey: "city",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Country",
        dataKey: "country",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Contact",
        dataKey: "contact",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Currency Code",
        dataKey: "currencyCode",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Pin",
        dataKey: "pin",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Province",
        dataKey: "province",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Res Prefix",
        dataKey: "resPrefix",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Tax",
        dataKey: "tax",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Underage Rate",
        dataKey: "underageRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "User Name",
        dataKey: "username",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Working Hour Start End",
        dataKey: "workingHourStartEnd",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const locationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_LOCATION_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_CATEGORY_LIST.START:
      case FETCH_LOCATION_DETAIL.START:
      case EXPORT_LOCATION.START:
      case FETCH_LOCATION_LIST.START:
      case CREATE_LOCATION.START:
        case LOCATIONDD.START:
        case EDIT_LOCATION.START:
        return { ...state, isLoading: true };
  
      case FETCH_LOCATION_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          locationListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isCreateSuccess: false,
          isCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          locationListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }

      case FETCH_CATEGORY_LIST.SUCCESS: {
        const data  = action.payload;    
        let catDD = data.map(item=>({
          id:item.id,
          name:item.title
        })) 
        let resetState = {
          locationListview: [],
          categoryListview:[],
          categoryDD:[],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isCreateSuccess: false,
          isCreateError: false,
        };
        if (!data) {
          return resetState;
        }       
        return {
          ...state,
          categoryListview: data,
          categoryDD:catDD
        };
      }

      case FETCH_CATEGORY_LIST.ERROR:
      case FETCH_LOCATION_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          locationListview: [],
          categoryListview:[],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isDeletionSuccess: false,
          isDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_LOCATION_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              if(key==="anyAirportLocation"){
                temp[key] = action.payload[key]===true ? 1 : 2;
              }
              else if(key==="status"){
                temp[key] = action.payload[key]===true ? 1 : 0;
              }
              else if(key==="afterHourFeeStatus"){
                temp[key] = action.payload[key]===true ? 1 : 2;
              }
              else if(key==="airportChargeStatus"){
                temp[key] = action.payload[key]===true ? 1 : 0;
              }
              else if(key==="locationExtraHourRequests"){
                 let lExtraHours = temp[key];
                 lExtraHours = lExtraHours.map(item =>({
                  ...item,
                  startDate:new Date(item.startDate),
                  endDate:new Date(item.endDate)
              
              }))
              temp[key]=lExtraHours
              }
              else{
             
              temp[key] = action.payload[key];
             
              }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isDetailSuccess: true,
      };
    }
    case FETCH_LOCATION_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isDetailSuccess: false,
        isDetailError: true,
      };
    }



// Export Start
case EXPORT_LOCATION.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isLocationExportSuccess: false,
    isLocationExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_LOCATION.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"location.xls");
   return {
    ...state,
    isLocationExportSuccess: true,
    isLocationExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_LOCATION.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isEditSuccess: false,
    isEditError: true,
  };
}

case EDIT_LOCATION.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isEditSuccess: true,
    isEditError: false,
  };
}

// EDIT END
//DD
case LOCATIONDD.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    locationDD:temp,
    islocationDDSuccess: true,
    islocationDDError: false,
  };
}
case LOCATIONDD.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    islocationDDSuccess: false,
    islocationDDError: true,
  };
}
//END DD
// Location Create
case INIT_CREATE_LOCATION_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_LOCATION.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isCreateSuccess: false,
    isCreateError: true,
  };
}
case CREATE_LOCATION.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isCreateSuccess: true,
    isCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isCreateSuccess: false,
          isCreateError: false,
          isEditError: false,
          isEditSuccess: false,
          isDetailError: false
        };
      }
      default:
        return state;
    }
  };
  