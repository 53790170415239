

export const FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST = {
    START: 'FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST_START',
    SUCCESS: 'FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST_SUCCESS',
    ERROR: 'FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RENTERS_NONREVENUEAGREEMENTS_INIT="FETCH_RENTERS_NONREVENUEAGREEMENTS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RENTERS_NONREVENUEAGREEMENTS_SCREEN =
'INIT_CREATE_RENTERS_NONREVENUEAGREEMENTS_SCREEN';

export const CREATE_RENTERS_NONREVENUEAGREEMENTS = {
START: 'CREATE_RENTERS_NONREVENUEAGREEMENTS_START',
SUCCESS: 'CREATE_RENTERS_NONREVENUEAGREEMENTS_SUCCESS',
ERROR: 'CREATE_RENTERS_NONREVENUEAGREEMENTS_ERROR',
};

export const EDIT_RENTERS_NONREVENUEAGREEMENTS = {
START: 'EDIT_RENTERS_NONREVENUEAGREEMENTS_START',
SUCCESS: 'EDIT_RENTERS_NONREVENUEAGREEMENTS_SUCCESS',
ERROR: 'EDIT_RENTERS_NONREVENUEAGREEMENTS_ERROR',
};

export const FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL = {
  START: 'FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL_START',
  SUCCESS: 'FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL_ERROR',
};
export const EXPORT_RENTERS_NONREVENUEAGREEMENTS = {
  START: 'EXPORT_RENTERS_NONREVENUEAGREEMENTS_START',
  SUCCESS: 'EXPORT_RENTERS_NONREVENUEAGREEMENTS_SUCCESS',
  ERROR: 'EXPORT_RENTERS_NONREVENUEAGREEMENTS_ERROR',
};
export const RENTERS_NONREVENUEAGREEMENTSDD = {
  START: 'RENTERS_NONREVENUEAGREEMENTSDD_START',
  SUCCESS: 'RENTERS_NONREVENUEAGREEMENTSDD_SUCCESS',
  ERROR: 'RENTERS_NONREVENUEAGREEMENTSDD_ERROR',
};
export const RENTERS_NONREVENUEAGREEMENTSDELETE = {
  START: 'RENTERS_NONREVENUEAGREEMENTSDELETE_START',
  SUCCESS: 'RENTERS_NONREVENUEAGREEMENTSDELETE_SUCCESS',
  ERROR: 'RENTERS_NONREVENUEAGREEMENTSDELETE_ERROR',
};

