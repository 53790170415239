import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_CATEGORY_LIST, 
  TOAST, 
  FETCH_VEHICLE_CATEGORY_INIT,
  FETCH_VEHICLE_CATEGORY_DETAIL,
  EXPORT_VEHICLE_CATEGORY,
  CREATE_VEHICLE_CATEGORY,
  EDIT_VEHICLE_CATEGORY,
  INIT_CREATE_VEHICLE_CATEGORY_SCREEN,
  VEHICLE_CATEGORYDD
} from "../../../types/vehiclecategory.type";


  const formFieldValueMap = {
   "ac": "",
  "code": "",
  "confirmationRequired": 0,
  "depositAmount": 0,
  "description": "",
  "details": "",
  "doors": "",
  "extraFeatures": "",
  "extraMileageRate": 0,
  "freeMileage":0,
  "id": null,
  "imgUrl": "",
  "inquiry": "",
  "keywords": "",
  "luggage": 1,
  "maxStockPercent": 0,
  "maximumMileage": 0,
  "passenger": 1,
  "slug": "",
  "status": "",
  "stereo": "",
  "tag": "",
  "title": "",
  "transmission": "",
  "userId": ""
  };
  
  const INITIAL_STATE = {
    vehicleCategoryListview: [],
    categoryListview: [],
    vehicleCategoryDD:[],
    isVehicleCategoryDDSuccess: false,
    isVehicleCategoryDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleCategoryCreateSuccess: false,
    isVehicleCategoryCreateError: false,
    isVehicleCategoryDetailSuccess: false,
    isVehicleCategoryDetailError: false,
    isVehicleCategoryEditSuccess: false,
    isVehicleCategoryEditError: false,
    isVehicleCategoryExportSuccess: false,
    isVehicleCategoryExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        ac,
        code,
        confirmationRequired,
        crtTime,
        depositAmount,
        doors,
        freeMileage,
        id,
        imgUrl,
        luggage,
        maxStockPercent,
        modByAlias,
        modTime,
        passenger,
        status,
        stereo,
        title,
        transmission
      } = item;
  
      const transformedValues = {
        ac,
        code,
        confirmationRequired,
        crtTime,
        depositAmount,
        doors,
        freeMileage,
        id,
        imgUrl,
        luggage,
        maxStockPercent,
        modByAlias,
        modTime,
        passenger,
        status,
        stereo,
        title,
        transmission
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Vehicle Class",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Code",
        dataKey: "code",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Free Mileage",
        dataKey:'freeMileage',
        extraProps: setColumnDefaultExtraProps({
          isSortable: true,
            shouldFilter: false,
        }),
      },
      {
        colLabel: "Passenger",
        dataKey: "passenger",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Luggage",
        dataKey: "luggage",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Doors",
        dataKey: "doors",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Transmission",
        dataKey: "transmission",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Stereo",
        dataKey: "stereo",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "AC",
        dataKey: "ac",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Deposit Amount",
        dataKey: "depositAmount",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Confirmation Required",
        dataKey: "confirmationRequired",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Max Stock %",
        dataKey: "maxStockPercent",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Image",
        dataKey: "imgUrl",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit By",
        dataKey: "modByAlias",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleCategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_CATEGORY_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_CATEGORY_DETAIL.START:
      case EXPORT_VEHICLE_CATEGORY.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_CATEGORY_LIST.START:
      case CREATE_VEHICLE_CATEGORY.START:
        case EDIT_VEHICLE_CATEGORY.START:
          case VEHICLE_CATEGORYDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_CATEGORY_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleCategoryListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleCategoryCreateSuccess: false,
          isVehicleCategoryCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleCategoryListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_CATEGORY_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleCategoryListview: [],
          categoryListview:[],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleCategoryDeletionSuccess: false,
          isvehicleCategoryDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_CATEGORY_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleCategoryDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_CATEGORY_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleCategoryDetailSuccess: false,
        isVehicleCategoryDetailError: true,
      };
    }

//DD
case VEHICLE_CATEGORYDD.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    vehicleCategoryDD:temp,
    isVehicleCategoryDDSuccess: true,
    isVehicleCategoryDDError: false,
  };
}
case VEHICLE_CATEGORYDD.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isVehicleCategoryDDSuccess: false,
isVehicleCategoryDDError: true,
  };
}
//END DD
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_CATEGORY.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleCategoryExportSuccess: false,
    isVehicleCategoryExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_CATEGORY.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-category.xls");
   return {
    ...state,
    isVehicleCategoryExportSuccess: true,
    isVehicleCategoryExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_CATEGORY.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleCategoryEditSuccess: false,
    isVehicleCategoryEditError: true,
  };
}

case EDIT_VEHICLE_CATEGORY.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleCategoryEditSuccess: true,
    isVehicleCategoryEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_CATEGORY_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_CATEGORY.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleCategoryCreateSuccess: false,
    isVehicleCategoryCreateError: true,
  };
}
case CREATE_VEHICLE_CATEGORY.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleCategoryCreateSuccess: true,
    isVehicleCategoryCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleCategoryCreateSuccess: false,
          isVehicleCategoryCreateError: false,
          isVehicleCategoryEditError: false,
          isVehicleCategoryEditSuccess: false,
          isVehicleCategoryDetailError: false
        };
      }
      default:
        return state;
    }
  };
  