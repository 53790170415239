export const basicDetail =
[
  {
    name:"name",
     "rule": {
        "required": true
      },
    label:"Dealership Name",
    type:"text"
  },
  {
    name:"contactPerson",
     "rule": {
        "required": true
      },
    label:"Contact Person",
    type:"text"
  },
  {
    name:"contactNumber",
     "rule": {
        "required": true
      },
    label:"Contact Number",
    type:"text"
  },
  {
    name:"emailId",
     "rule": {
        "required": true
      },
    label:"Email ID",
    type:"text"
  },
  {
    name:"faxNumber",
     "rule": {
        "required": true
      },
    label:"Fax Number",
    type:"text"
  },
  {
    name:"address",
     "rule": {
        "required": true
      },
    label:"Address",
    type:"text"
  },
  {
    name:"notes",
     "rule": {
        "required": true
      },
    label:"Notes",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
