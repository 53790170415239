import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchSettingsPaynowOptions,
    initializeSettingsPaynowOptionsScreen,
    setDeleteConfirmationPopupVisibility,
    exportSettingsPaynowOptions,
    deleteSettingsPaynowOptions
  } from "../../../redux/actions/Settings/SettingsPaynowOptions";
import SettingsPaynowOptions from '../../../component/Settings/SettingsPaynowOptions';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function SettingsPaynowOptionsScreen(props) {
    const {
        fetchSettingsPaynowOptions,
        initSettingsPaynowOptionsScreen,
        showPageLevelLoader,
        SettingsPaynowOptionsListview,
        paginationInfo,
        exportSettingsPaynowOptions,
        isSettingsPaynowOptionsExportError,
        isSettingsPaynowOptionsExportSuccess,
        isLoading,
        shouldOpenDeleteConfirmationPopup,
      setDeleteConfirmationPopupVisibility,
      deleteSettingsPaynowOptions
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initSettingsPaynowOptionsScreen();
        fetchSettingsPaynowOptions({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchSettingsPaynowOptions(newProps);
      };
      const handleCreateSettingsPaynowOptions =() =>{
      navigate(APP_URLS.SETTINGS_PAYNOWOPTIONS.CREATE);
    }
      const editSettingsPaynowOptionsRoute = (id) => {
        const path = generatePath(APP_URLS.SETTINGS_PAYNOWOPTIONS.EDIT, {
          id,
        });
        navigate(path);
      };
const SettingsPaynowOptionsProps = {
  handleCreateSettingsPaynowOptions,
  exportSettingsPaynowOptions,
        isSettingsPaynowOptionsExportError,
        isSettingsPaynowOptionsExportSuccess,
  editSettingsPaynowOptionsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  shouldOpenDeleteConfirmationPopup,
  fetchSettingsPaynowOptionss: loadLazyData,
  deleteSettingsPaynowOptions,
  ...companyData,
  tableData: {
    columns: SettingsPaynowOptionsListview?.columns,
    rows: SettingsPaynowOptionsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <SettingsPaynowOptions
    {...SettingsPaynowOptionsProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initSettingsPaynowOptionsScreen: () => dispatch(initializeSettingsPaynowOptionsScreen()),
      fetchSettingsPaynowOptions: (payload) => dispatch(fetchSettingsPaynowOptions(payload)),
      deleteSettingsPaynowOptions:(payload) => dispatch(deleteSettingsPaynowOptions(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportSettingsPaynowOptions: (payload) => dispatch(exportSettingsPaynowOptions(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isSettingsPaynowOptionsCreateSuccess: selectFromSettingsPaynowOptionsReducer(
        state,
        "isSettingsPaynowOptionsCreateSuccess"
      ),
      SettingsPaynowOptionsListview: selectFromSettingsPaynowOptionsReducer(
        state,
        "SettingsPaynowOptionsListview"
      ),
      showPageLevelLoader: selectFromSettingsPaynowOptionsReducer(
        state,
        "showPageLevelLoader"
      ),
      shouldOpenDeleteConfirmationPopup: selectFromSettingsPaynowOptionsReducer(
        state,
        "shouldOpenDeleteConfirmationPopup"
      ),
      paginationInfo: selectFromSettingsPaynowOptionsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromSettingsPaynowOptionsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromSettingsPaynowOptionsReducer(
        state,
        "isFetchingGridData"
      ),
      isSettingsPaynowOptionsExportError: selectFromSettingsPaynowOptionsReducer(
        state,
        "isSettingsPaynowOptionsExportError"
      ),
      isSettingsPaynowOptionsExportSuccess: selectFromSettingsPaynowOptionsReducer(
        state,
        "isSettingsPaynowOptionsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromSettingsPaynowOptionsReducer = (state, path) => {
  return selectSettingsPaynowOptionsReducer(state)[path];
};

const selectSettingsPaynowOptionsReducer = ({ SettingsPaynowOptionsReducer }) => {
  return SettingsPaynowOptionsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(SettingsPaynowOptionsScreen);
