import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminBlackoutsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_BLACKOUTS_INIT, 
  FETCH_RATEADMIN_BLACKOUTS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_BLACKOUTS_SCREEN,
  CREATE_RATEADMIN_BLACKOUTS,
  EDIT_RATEADMIN_BLACKOUTS,
  TOAST,
  FETCH_RATEADMIN_BLACKOUTS_DETAIL,
  EXPORT_RATEADMIN_BLACKOUTS,
  RATEADMIN_BLACKOUTSDD
} from "../../../types/rateadminblackouts.type";
export const initializeRateAdminBlackoutsScreen = () => ({ type: FETCH_RATEADMIN_BLACKOUTS_INIT });

export const rateadminBlackoutsFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_BLACKOUTS_LIST.START,
  payload,
});

export const rateadminBlackoutsFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_BLACKOUTS_LIST.SUCCESS,
  payload,
});

export const rateadminBlackoutsFetchError = (payload) => ({
  type: FETCH_RATEADMIN_BLACKOUTS_LIST.ERROR,
  payload,
});

export const fetchRateAdminBlackouts = (payload) => {
  return (dispatch) => {
    dispatch(rateadminBlackoutsFetchStart(payload));
    rateAdminBlackoutsServices.fetchRateAdminBlackouts(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminBlackoutsFetchSuccess(data));
      } else {
        dispatch(rateadminBlackoutsFetchError(data));
      }
    });
  };
};



// DD
export const rateadminBlackoutsDDFetchStart = (payload) => ({
  type: RATEADMIN_BLACKOUTSDD.START,
  payload,
});

export const rateadminBlackoutsDDFetchSuccess = (payload) => ({
  type:RATEADMIN_BLACKOUTSDD.SUCCESS,
  payload,
});

export const rateadminBlackoutsDDFetchError = (payload) => ({
  type: RATEADMIN_BLACKOUTSDD.ERROR,
  payload,
});

export const fetchRateAdminBlackoutsDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminBlackoutsDDFetchStart(payload));
    rateAdminBlackoutsServices.fetchRateAdminBlackoutsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminBlackoutsDDFetchSuccess(data));
      } else {
        dispatch(rateadminBlackoutsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminBlackoutsDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_BLACKOUTS_DETAIL.START,
  payload,
});

export const rateadminBlackoutsDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_BLACKOUTS_DETAIL.SUCCESS,
  payload,
});

export const rateadminBlackoutsDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_BLACKOUTS_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminBlackoutsById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminBlackoutsDetailFetchStart(payload));

    rateAdminBlackoutsServices
      .fetchRateAdminBlackoutsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminBlackoutsDetailFetchSuccess(data));
        } else {
          dispatch(rateadminBlackoutsDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminBlackoutsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminBlackoutsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_BLACKOUTS_SCREEN,
  payload,
});

export const initCreateRateAdminBlackoutsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminBlackoutsScreenInitStart());
  };
};


export const createRateAdminBlackoutsStart = () => ({
  type: CREATE_RATEADMIN_BLACKOUTS.START,
});

export const createRateAdminBlackoutsSuccess = (payload) => ({
  type: CREATE_RATEADMIN_BLACKOUTS.SUCCESS,
  payload,
});

export const createRateAdminBlackoutsError = (payload) => ({
  type: CREATE_RATEADMIN_BLACKOUTS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminBlackouts = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminBlackoutsStart());
    rateAdminBlackoutsServices.createRateAdminBlackouts(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminBlackoutsSuccess());
        goBack();
      } else {
        dispatch(createRateAdminBlackoutsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminBlackoutsStart = (payload) => ({
  type: EDIT_RATEADMIN_BLACKOUTS.START,
  payload
});

export const editRateAdminBlackoutsSuccess = (payload) => ({
  type: EDIT_RATEADMIN_BLACKOUTS.SUCCESS,
  payload,
});

export const editRateAdminBlackoutsError = (payload) => ({
  type: EDIT_RATEADMIN_BLACKOUTS.ERROR,
  payload,
});

export const editRateAdminBlackouts = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminBlackoutsStart());
    rateAdminBlackoutsServices.editRateAdminBlackouts(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminBlackoutsSuccess());
        goBack();
      } else {
        dispatch(editRateAdminBlackoutsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminBlackoutsRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_BLACKOUTS.START,
  payload
});

export const exportRateAdminBlackoutsRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_BLACKOUTS.SUCCESS,
  payload,
});

export const exportRateAdminBlackoutsRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_BLACKOUTS.ERROR,
  payload,
});

export const exportRateAdminBlackouts = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminBlackoutsRecordStart());
    rateAdminBlackoutsServices.exportRateAdminBlackoutsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminBlackoutsRecordSuccess(data));
      } else {
        dispatch(exportRateAdminBlackoutsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

