import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { SettingsPaynowOptionsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_SETTINGS_PAYNOWOPTIONS_INIT, 
  FETCH_SETTINGS_PAYNOWOPTIONS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_SETTINGS_PAYNOWOPTIONS_SCREEN,
  CREATE_SETTINGS_PAYNOWOPTIONS,
  EDIT_SETTINGS_PAYNOWOPTIONS,
  TOAST,
  FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL,
  EXPORT_SETTINGS_PAYNOWOPTIONS,
  SETTINGS_PAYNOWOPTIONSDD,
  SETTINGS_PAYNOWOPTIONSDELETE
} from "../../../types/Settings/PaynowOptions.type";
export const initializeSettingsPaynowOptionsScreen = () => ({ type: FETCH_SETTINGS_PAYNOWOPTIONS_INIT });

export const SettingsPaynowOptionsFetchStart = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWOPTIONS_LIST.START,
  payload,
});

export const SettingsPaynowOptionsFetchSuccess = (payload) => ({
  type:FETCH_SETTINGS_PAYNOWOPTIONS_LIST.SUCCESS,
  payload,
});

export const SettingsPaynowOptionsFetchError = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWOPTIONS_LIST.ERROR,
  payload,
});

export const fetchSettingsPaynowOptions = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowOptionsFetchStart(payload));
    SettingsPaynowOptionsServices.fetchSettingsPaynowOptions(payload).then((data) => {
      if (!data.isError) {
        dispatch(SettingsPaynowOptionsFetchSuccess(data));
      } else {
        dispatch(SettingsPaynowOptionsFetchError(data));
      }
    });
  };
};



// DD
export const SettingsPaynowOptionsDDFetchStart = (payload) => ({
  type: SETTINGS_PAYNOWOPTIONSDD.START,
  payload,
});

export const SettingsPaynowOptionsDDFetchSuccess = (payload) => ({
  type:SETTINGS_PAYNOWOPTIONSDD.SUCCESS,
  payload,
});

export const SettingsPaynowOptionsDDFetchError = (payload) => ({
  type: SETTINGS_PAYNOWOPTIONSDD.ERROR,
  payload,
});

export const fetchSettingsPaynowOptionsDD = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowOptionsDDFetchStart(payload));
    SettingsPaynowOptionsServices.fetchSettingsPaynowOptionsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(SettingsPaynowOptionsDDFetchSuccess(data));
      } else {
        dispatch(SettingsPaynowOptionsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const SettingsPaynowOptionsDetailFetchStart = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL.START,
  payload,
});

export const SettingsPaynowOptionsDetailFetchSuccess = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL.SUCCESS,
  payload,
});

export const SettingsPaynowOptionsDetailFetchError = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL.ERROR,
  payload,
});

export const fetchSettingsPaynowOptionsById = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowOptionsDetailFetchStart(payload));

    SettingsPaynowOptionsServices
      .fetchSettingsPaynowOptionsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(SettingsPaynowOptionsDetailFetchSuccess(data));
        } else {
          dispatch(SettingsPaynowOptionsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    SettingsPaynowOptionsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createSettingsPaynowOptionsScreenInitStart = (payload) => ({
  type: INIT_CREATE_SETTINGS_PAYNOWOPTIONS_SCREEN,
  payload,
});

export const initCreateSettingsPaynowOptionsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createSettingsPaynowOptionsScreenInitStart());
  };
};


export const createSettingsPaynowOptionsStart = () => ({
  type: CREATE_SETTINGS_PAYNOWOPTIONS.START,
});

export const createSettingsPaynowOptionsSuccess = (payload) => ({
  type: CREATE_SETTINGS_PAYNOWOPTIONS.SUCCESS,
  payload,
});

export const createSettingsPaynowOptionsError = (payload) => ({
  type: CREATE_SETTINGS_PAYNOWOPTIONS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createSettingsPaynowOptions = (payload) => {
  return (dispatch) => {
    dispatch(createSettingsPaynowOptionsStart());
    SettingsPaynowOptionsServices.createSettingsPaynowOptions(payload).then((data) => {
      if (!data.isError) {
        dispatch(createSettingsPaynowOptionsSuccess());
        goBack();
      } else {
        dispatch(createSettingsPaynowOptionsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editSettingsPaynowOptionsStart = (payload) => ({
  type: EDIT_SETTINGS_PAYNOWOPTIONS.START,
  payload
});

export const editSettingsPaynowOptionsSuccess = (payload) => ({
  type: EDIT_SETTINGS_PAYNOWOPTIONS.SUCCESS,
  payload,
});

export const editSettingsPaynowOptionsError = (payload) => ({
  type: EDIT_SETTINGS_PAYNOWOPTIONS.ERROR,
  payload,
});

export const editSettingsPaynowOptions = (payload) => {
  return (dispatch) => {
    dispatch(editSettingsPaynowOptionsStart());
    SettingsPaynowOptionsServices.editSettingsPaynowOptions(payload).then((data) => {
      if (!data.isError) {
        dispatch(editSettingsPaynowOptionsSuccess());
        goBack();
      } else {
        dispatch(editSettingsPaynowOptionsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportSettingsPaynowOptionsRecordStart = (payload) => ({
  type: EXPORT_SETTINGS_PAYNOWOPTIONS.START,
  payload
});

export const exportSettingsPaynowOptionsRecordSuccess = (payload) => ({
  type: EXPORT_SETTINGS_PAYNOWOPTIONS.SUCCESS,
  payload,
});

export const exportSettingsPaynowOptionsRecordError = (payload) => ({
  type: EXPORT_SETTINGS_PAYNOWOPTIONS.ERROR,
  payload,
});

export const exportSettingsPaynowOptions = (payload) => {
  return (dispatch) => {
    dispatch(exportSettingsPaynowOptionsRecordStart());
    SettingsPaynowOptionsServices.exportSettingsPaynowOptionsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportSettingsPaynowOptionsRecordSuccess(data));
      } else {
        dispatch(exportSettingsPaynowOptionsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const SettingsPaynowOptionsDeleteStart = (payload) => ({
  type: SETTINGS_PAYNOWOPTIONSDELETE.START,
  payload,
});

export const SettingsPaynowOptionsDeleteSuccess = (payload) => ({
  type: SETTINGS_PAYNOWOPTIONSDELETE.SUCCESS,
  payload,
});

export const SettingsPaynowOptionsDeleteError = (payload) => ({
  type: SETTINGS_PAYNOWOPTIONSDELETE.ERROR,
  payload,
});

export const deleteSettingsPaynowOptions = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowOptionsDeleteStart(payload));

    SettingsPaynowOptionsServices
      .deleteSettingsPaynowOptions(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(SettingsPaynowOptionsDeleteSuccess(data));
          console.log("BAID", payload?.params)
          let payloaddeletee = payload?.params;
          payloaddeletee["userId"] = localStorage.getItem("userId");
          dispatch(fetchSettingsPaynowOptions(payloaddeletee));
        } else {
          dispatch(SettingsPaynowOptionsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/