import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminRateCodeScreen,
  createRateAdminRateCode,
  editRateAdminRateCode,
  fetchRateAdminRateCodeById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminRateCode";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminRateCodeComponent from "../../../../component/RateAdmin/RateAdminRateCode/CreateRateAdminRateCode";
import { useParams } from "react-router-dom";

const CreateRateAdminRateCodeContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminRateCode,
    editRateAdminRateCode,
    isLoading,
    isRateAdminRateCodeCreateSuccess,
    isRateAdminRateCodeCreateError,
    isRateAdminRateCodeDetailSuccess,
    isRateAdminRateCodeDetailError,
    isRateAdminRateCodeEditSuccess,
    isRateAdminRateCodeEditError,
    isEdit,
    isView,
    initCreateRateAdminRateCodeScreen,
    formFieldValueMap,
    fetchRateAdminRateCodeById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminRateCodeScreen();
   if (isEdit) {
    await fetchRateAdminRateCodeById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminRateCodeProps = {
    createRateAdminRateCode,
    initCreateRateAdminRateCodeScreen,
    editRateAdminRateCode,
    isLoading,
    isEdit,
    isRateAdminRateCodeDetailSuccess,
    isRateAdminRateCodeCreateSuccess,
    isRateAdminRateCodeEditSuccess,
    isRateAdminRateCodeEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRateAdminRateCodeCreateSuccess || isRateAdminRateCodeEditSuccess) {
      const toastTitle = isEdit
        ? "Rate Code Updated Successfully"
        : "Rate Code Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminRateCodeCreateError ||
      isRateAdminRateCodeDetailError ||
      isRateAdminRateCodeEditError
    ) {
      let toastTitle = "Error while Creating Rate Code";
      if (isRateAdminRateCodeEditError) {
        toastTitle = "Error while updating  Rate Code";
      } else if (isRateAdminRateCodeDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminRateCodeComponent {...createRateAdminRateCodeProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminRateCodeReducer(state, "isLoading"),
   
    isRateAdminRateCodeCreateSuccess: selectFromRateAdminRateCodeReducer(
      state,
      "isRateAdminRateCodeCreateSuccess"
    ),
    isRateAdminRateCodeCreateError: selectFromRateAdminRateCodeReducer(
      state,
      "isRateAdminRateCodeCreateError"
    ),
    isRateAdminRateCodeDetailSuccess: selectFromRateAdminRateCodeReducer(
      state,
      "isRateAdminRateCodeDetailSuccess"
    ),
    isRateAdminRateCodeDetailError: selectFromRateAdminRateCodeReducer(
      state,
      "isRateAdminRateCodeDetailError"
    ),
    isRateAdminRateCodeEditSuccess: selectFromRateAdminRateCodeReducer(
      state,
      "isRateAdminRateCodeEditSuccess"
    ),
    isRateAdminRateCodeEditError: selectFromRateAdminRateCodeReducer(
      state,
      "isRateAdminRateCodeEditError"
    ),
    imageUploadResponse: selectFromRateAdminRateCodeReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminRateCodeReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminRateCodeReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminRateCodeReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminRateCodeReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminRateCodeReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRateAdminRateCodeReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminRateCodeScreen: () => dispatch(initCreateRateAdminRateCodeScreen()),
    createRateAdminRateCode: (payload) => dispatch(createRateAdminRateCode(payload)),
    editRateAdminRateCode: (payload) => dispatch(editRateAdminRateCode(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminRateCodeById: (payload) =>
      dispatch(fetchRateAdminRateCodeById(payload)),

  };
};

const selectFromRateAdminRateCodeReducer = (state, path) => {
  return selectRateAdminRateCodeReducer(state)[path];
};

const selectRateAdminRateCodeReducer = ({ rateadminRateCodeReducer }) => {
  return rateadminRateCodeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminRateCodeContainer);
