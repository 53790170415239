import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminExtraProductCategory,
    initializeRateAdminExtraProductCategoryScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminExtraProductCategory
  } from "../../../redux/actions/RateAdmin/rateadminExtraProductCategory";
import RateAdminExtraProductCategory from '../../../component/RateAdmin/RateAdminExtraProductCategory';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminExtraProductCategoryScreen(props) {
    const {
        fetchRateAdminExtraProductCategory,
        initRateAdminExtraProductCategoryScreen,
        showPageLevelLoader,
        rateAdminExtraProductCategoryListview,
        paginationInfo,
        exportRateAdminExtraProductCategory,
        isRateAdminExtraProductCategoryExportError,
        isRateAdminExtraProductCategoryExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminExtraProductCategoryScreen();
        fetchRateAdminExtraProductCategory({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchRateAdminExtraProductCategory(props);
      };
      const handleCreateRateAdminExtraProductCategory =() =>{
      navigate(APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.CREATE);
    }
      const editRateAdminExtraProductCategoryRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_EXTRAPRODUCTCATEGORY.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminExtraProductCategory = {
  handleCreateRateAdminExtraProductCategory,
  exportRateAdminExtraProductCategory,
        isRateAdminExtraProductCategoryExportError,
        isRateAdminExtraProductCategoryExportSuccess,
  editRateAdminExtraProductCategoryRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminExtraProductCategory: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminExtraProductCategoryListview?.columns,
    rows: rateAdminExtraProductCategoryListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminExtraProductCategory
    {...rateAdminExtraProductCategory}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminExtraProductCategoryScreen: () => dispatch(initializeRateAdminExtraProductCategoryScreen()),
      fetchRateAdminExtraProductCategory: (payload) => dispatch(fetchRateAdminExtraProductCategory(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRateAdminExtraProductCategory: (payload) => dispatch(exportRateAdminExtraProductCategory(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminExtraProductCategoryCreateSuccess: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "isRateAdminExtraProductCategoryCreateSuccess"
      ),
      rateAdminExtraProductCategoryListview: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "rateAdminExtraProductCategoryListview"
      ),
      showPageLevelLoader: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminExtraProductCategoryExportError: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "isRateAdminExtraProductCategoryExportError"
      ),
      isRateAdminExtraProductCategoryExportSuccess: selectFromRateAdminExtraProductCategoryReducer(
        state,
        "isRateAdminExtraProductCategoryExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRateAdminExtraProductCategoryReducer = (state, path) => {
  return selectRateAdminExtraProductCategoryReducer(state)[path];
};

const selectRateAdminExtraProductCategoryReducer = ({ rateadminExtraProductCategoryReducer }) => {
  return rateadminExtraProductCategoryReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RateAdminExtraProductCategoryScreen);
