

export const FETCH_RENTERS_LOGINTERACTIONS_LIST = {
    START: 'FETCH_RENTERS_LOGINTERACTIONS_LIST_START',
    SUCCESS: 'FETCH_RENTERS_LOGINTERACTIONS_LIST_SUCCESS',
    ERROR: 'FETCH_RENTERS_LOGINTERACTIONS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RENTERS_LOGINTERACTIONS_INIT="FETCH_RENTERS_LOGINTERACTIONS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RENTERS_LOGINTERACTIONS_SCREEN =
'INIT_CREATE_RENTERS_LOGINTERACTIONS_SCREEN';

export const CREATE_RENTERS_LOGINTERACTIONS = {
START: 'CREATE_RENTERS_LOGINTERACTIONS_START',
SUCCESS: 'CREATE_RENTERS_LOGINTERACTIONS_SUCCESS',
ERROR: 'CREATE_RENTERS_LOGINTERACTIONS_ERROR',
};

export const EDIT_RENTERS_LOGINTERACTIONS = {
START: 'EDIT_RENTERS_LOGINTERACTIONS_START',
SUCCESS: 'EDIT_RENTERS_LOGINTERACTIONS_SUCCESS',
ERROR: 'EDIT_RENTERS_LOGINTERACTIONS_ERROR',
};

export const FETCH_RENTERS_LOGINTERACTIONS_DETAIL = {
  START: 'FETCH_RENTERS_LOGINTERACTIONS_DETAIL_START',
  SUCCESS: 'FETCH_RENTERS_LOGINTERACTIONS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RENTERS_LOGINTERACTIONS_DETAIL_ERROR',
};
export const EXPORT_RENTERS_LOGINTERACTIONS = {
  START: 'EXPORT_RENTERS_LOGINTERACTIONS_START',
  SUCCESS: 'EXPORT_RENTERS_LOGINTERACTIONS_SUCCESS',
  ERROR: 'EXPORT_RENTERS_LOGINTERACTIONS_ERROR',
};
export const RENTERS_LOGINTERACTIONSDD = {
  START: 'RENTERS_LOGINTERACTIONSDD_START',
  SUCCESS: 'RENTERS_LOGINTERACTIONSDD_SUCCESS',
  ERROR: 'RENTERS_LOGINTERACTIONSDD_ERROR',
};
export const RENTERS_LOGINTERACTIONSDELETE = {
  START: 'RENTERS_LOGINTERACTIONSDELETE_START',
  SUCCESS: 'RENTERS_LOGINTERACTIONSDELETE_SUCCESS',
  ERROR: 'RENTERS_LOGINTERACTIONSDELETE_ERROR',
};

