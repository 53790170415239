import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { ReservationsDetailReportServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_DETAILREPORT_INIT, 
  FETCH_RESERVATIONS_DETAILREPORT_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RESERVATIONS_DETAILREPORT_SCREEN,
  CREATE_RESERVATIONS_DETAILREPORT,
  EDIT_RESERVATIONS_DETAILREPORT,
  TOAST,
  FETCH_RESERVATIONS_DETAILREPORT_DETAIL,
  EXPORT_RESERVATIONS_DETAILREPORT,
  RESERVATIONS_DETAILREPORTDD,
  RESERVATIONS_DETAILREPORTDELETE
} from "../../../types/Reservations/DetailReport.type";
export const initializeReservationsDetailReportScreen = () => ({ type: FETCH_RESERVATIONS_DETAILREPORT_INIT });

export const ReservationsDetailReportFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_DETAILREPORT_LIST.START,
  payload,
});

export const ReservationsDetailReportFetchSuccess = (payload) => ({
  type:FETCH_RESERVATIONS_DETAILREPORT_LIST.SUCCESS,
  payload,
});

export const ReservationsDetailReportFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_DETAILREPORT_LIST.ERROR,
  payload,
});

export const fetchReservationsDetailReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDetailReportFetchStart(payload));
    ReservationsDetailReportServices.fetchReservationsDetailReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsDetailReportFetchSuccess(data));
      } else {
        dispatch(ReservationsDetailReportFetchError(data));
      }
    });
  };
};



// DD
export const ReservationsDetailReportDDFetchStart = (payload) => ({
  type: RESERVATIONS_DETAILREPORTDD.START,
  payload,
});

export const ReservationsDetailReportDDFetchSuccess = (payload) => ({
  type:RESERVATIONS_DETAILREPORTDD.SUCCESS,
  payload,
});

export const ReservationsDetailReportDDFetchError = (payload) => ({
  type: RESERVATIONS_DETAILREPORTDD.ERROR,
  payload,
});

export const fetchReservationsDetailReportDD = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDetailReportDDFetchStart(payload));
    ReservationsDetailReportServices.fetchReservationsDetailReportDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsDetailReportDDFetchSuccess(data));
      } else {
        dispatch(ReservationsDetailReportDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const ReservationsDetailReportDetailFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_DETAILREPORT_DETAIL.START,
  payload,
});

export const ReservationsDetailReportDetailFetchSuccess = (payload) => ({
  type: FETCH_RESERVATIONS_DETAILREPORT_DETAIL.SUCCESS,
  payload,
});

export const ReservationsDetailReportDetailFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_DETAILREPORT_DETAIL.ERROR,
  payload,
});

export const fetchReservationsDetailReportById = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDetailReportDetailFetchStart(payload));

    ReservationsDetailReportServices
      .fetchReservationsDetailReportById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsDetailReportDetailFetchSuccess(data));
        } else {
          dispatch(ReservationsDetailReportDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    ReservationsDetailReportServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createReservationsDetailReportScreenInitStart = (payload) => ({
  type: INIT_CREATE_RESERVATIONS_DETAILREPORT_SCREEN,
  payload,
});

export const initCreateReservationsDetailReportScreen = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsDetailReportScreenInitStart());
  };
};


export const createReservationsDetailReportStart = () => ({
  type: CREATE_RESERVATIONS_DETAILREPORT.START,
});

export const createReservationsDetailReportSuccess = (payload) => ({
  type: CREATE_RESERVATIONS_DETAILREPORT.SUCCESS,
  payload,
});

export const createReservationsDetailReportError = (payload) => ({
  type: CREATE_RESERVATIONS_DETAILREPORT.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReservationsDetailReport = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsDetailReportStart());
    ReservationsDetailReportServices.createReservationsDetailReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReservationsDetailReportSuccess());
        goBack();
      } else {
        dispatch(createReservationsDetailReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editReservationsDetailReportStart = (payload) => ({
  type: EDIT_RESERVATIONS_DETAILREPORT.START,
  payload
});

export const editReservationsDetailReportSuccess = (payload) => ({
  type: EDIT_RESERVATIONS_DETAILREPORT.SUCCESS,
  payload,
});

export const editReservationsDetailReportError = (payload) => ({
  type: EDIT_RESERVATIONS_DETAILREPORT.ERROR,
  payload,
});

export const editReservationsDetailReport = (payload) => {
  return (dispatch) => {
    dispatch(editReservationsDetailReportStart());
    ReservationsDetailReportServices.editReservationsDetailReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReservationsDetailReportSuccess());
        goBack();
      } else {
        dispatch(editReservationsDetailReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportReservationsDetailReportRecordStart = (payload) => ({
  type: EXPORT_RESERVATIONS_DETAILREPORT.START,
  payload
});

export const exportReservationsDetailReportRecordSuccess = (payload) => ({
  type: EXPORT_RESERVATIONS_DETAILREPORT.SUCCESS,
  payload,
});

export const exportReservationsDetailReportRecordError = (payload) => ({
  type: EXPORT_RESERVATIONS_DETAILREPORT.ERROR,
  payload,
});

export const exportReservationsDetailReport = (payload) => {
  return (dispatch) => {
    dispatch(exportReservationsDetailReportRecordStart());
    ReservationsDetailReportServices.exportReservationsDetailReportRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportReservationsDetailReportRecordSuccess(data));
      } else {
        dispatch(exportReservationsDetailReportRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const ReservationsDetailReportDeleteStart = (payload) => ({
  type: RESERVATIONS_DETAILREPORTDELETE.START,
  payload,
});

export const ReservationsDetailReportDeleteSuccess = (payload) => ({
  type: RESERVATIONS_DETAILREPORTDELETE.SUCCESS,
  payload,
});

export const ReservationsDetailReportDeleteError = (payload) => ({
  type: RESERVATIONS_DETAILREPORTDELETE.ERROR,
  payload,
});

export const deleteReservationsDetailReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDetailReportDeleteStart(payload));

    ReservationsDetailReportServices
      .deleteReservationsDetailReport(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsDetailReportDeleteSuccess(data));
          dispatch(fetchReservationsDetailReport(payload?.params));
        } else {
          dispatch(ReservationsDetailReportDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/