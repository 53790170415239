import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import Breadcrumb from "../../../common/Breadcrumb";
import { useStyles } from '../style';
import { rateTypeFromDD, basicDetail, editMode, statusDD, HolidaysBreadcrumb, } from "../CreateHolidays/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import EzControlledCalendarWithFloatingLabel from "../../../common/EzForm/EzControlledCalendar/WithFloatingLabel"

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../utils/constant";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import EzControlledMultiSelecttWithFloatingLabel from "../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";
import { hourslist } from "../../Location/CreateLocation/constant";
import { formatDatesHyphan } from "../../../utils";

const CreateHolidaysComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateHolidaysScreen,
    createHolidays,
    editHolidays,
    isLoading,
    isHolidaysCreateSuccess,
    isHolidaysCreateError,
    isHolidaysDetailSuccess,
    isHolidaysDetailError,
    isHolidaysEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    locationDD,
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    resetField,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });


  const navigateToHolidaysScreen = () => {
    window.location.replace("/#" + APP_URLS.HOLIDAYS.HOME)
  };
  useEffect(() => {

    if (isHolidaysCreateSuccess || isHolidaysEditSuccess) {
      reset();
    }
  }, [isHolidaysCreateSuccess]);
  useEffect(() => {
    initCreateHolidaysScreen();
  }, []);


  useEffect(() => {
    if (isHolidaysDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
  }
  }, [isHolidaysDetailSuccess]);


  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    newData["date"] = formatDatesHyphan(data?.date)
    isEdit
      ? editHolidays({ id: data.id, data: newData })
      : createHolidays(newData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Holidays</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Holidays"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Holidays"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToHolidaysScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={HolidaysBreadcrumb } />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <Grid container spacing={2}>
              {basicDetail?.map((item) => (
               item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
      id={item.name}
      name={item.name}
      {...register(item.name)}
      showTime={false}
      maxDate={new Date(2090, 0, 1)}
      dateFormat="yy/mm/dd" 
      control={control}
      isError={errors[item.name]}
      errorMsg={getFormErrorMessage(item.name)}
      label={item.label}
      rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
    />
                  </Grid>
                )
                :
                  item.name === "locationId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledMultiSelecttWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={locationDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :
                    item.name === "hourStart" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={hourslist}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :

                    item.name === "hourEnd" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={hourslist}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :

                    
                          (
                            (<Grid item xs={3} key={item.name}>
                              <EzControlledInputWithFloatingLabel
                                name={item?.name}
                                control={control}
                                label={item?.label}
                                type={item?.type}
                                rules={item?.rule}
                                isError={errors[item?.name]}
                                errorMsg={getFormErrorMessage(item?.name)}
                              />
                            </Grid>))
              ))}

            </Grid>
          
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
            {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateHolidaysComponent;
