import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { ReservationsCRXRezpowerReservationsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_INIT, 
  FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SCREEN,
  CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS,
  EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS,
  TOAST,
  FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL,
  EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS,
  RESERVATIONS_CRXREZPOWERRESERVATIONSDD,
  RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE
} from "../../../types/Reservations/CRXRezpowerReservations.type";
export const initializeReservationsCRXRezpowerReservationsScreen = () => ({ type: FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_INIT });

export const ReservationsCRXRezpowerReservationsFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST.START,
  payload,
});

export const ReservationsCRXRezpowerReservationsFetchSuccess = (payload) => ({
  type:FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST.SUCCESS,
  payload,
});

export const ReservationsCRXRezpowerReservationsFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_LIST.ERROR,
  payload,
});

export const fetchReservationsCRXRezpowerReservations = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsCRXRezpowerReservationsFetchStart(payload));
    ReservationsCRXRezpowerReservationsServices.fetchReservationsCRXRezpowerReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsCRXRezpowerReservationsFetchSuccess(data));
      } else {
        dispatch(ReservationsCRXRezpowerReservationsFetchError(data));
      }
    });
  };
};



// DD
export const ReservationsCRXRezpowerReservationsDDFetchStart = (payload) => ({
  type: RESERVATIONS_CRXREZPOWERRESERVATIONSDD.START,
  payload,
});

export const ReservationsCRXRezpowerReservationsDDFetchSuccess = (payload) => ({
  type:RESERVATIONS_CRXREZPOWERRESERVATIONSDD.SUCCESS,
  payload,
});

export const ReservationsCRXRezpowerReservationsDDFetchError = (payload) => ({
  type: RESERVATIONS_CRXREZPOWERRESERVATIONSDD.ERROR,
  payload,
});

export const fetchReservationsCRXRezpowerReservationsDD = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsCRXRezpowerReservationsDDFetchStart(payload));
    ReservationsCRXRezpowerReservationsServices.fetchReservationsCRXRezpowerReservationsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsCRXRezpowerReservationsDDFetchSuccess(data));
      } else {
        dispatch(ReservationsCRXRezpowerReservationsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const ReservationsCRXRezpowerReservationsDetailFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL.START,
  payload,
});

export const ReservationsCRXRezpowerReservationsDetailFetchSuccess = (payload) => ({
  type: FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL.SUCCESS,
  payload,
});

export const ReservationsCRXRezpowerReservationsDetailFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_CRXREZPOWERRESERVATIONS_DETAIL.ERROR,
  payload,
});

export const fetchReservationsCRXRezpowerReservationsById = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsCRXRezpowerReservationsDetailFetchStart(payload));

    ReservationsCRXRezpowerReservationsServices
      .fetchReservationsCRXRezpowerReservationsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsCRXRezpowerReservationsDetailFetchSuccess(data));
        } else {
          dispatch(ReservationsCRXRezpowerReservationsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    ReservationsCRXRezpowerReservationsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createReservationsCRXRezpowerReservationsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS_SCREEN,
  payload,
});

export const initCreateReservationsCRXRezpowerReservationsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsCRXRezpowerReservationsScreenInitStart());
  };
};


export const createReservationsCRXRezpowerReservationsStart = () => ({
  type: CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS.START,
});

export const createReservationsCRXRezpowerReservationsSuccess = (payload) => ({
  type: CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS.SUCCESS,
  payload,
});

export const createReservationsCRXRezpowerReservationsError = (payload) => ({
  type: CREATE_RESERVATIONS_CRXREZPOWERRESERVATIONS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReservationsCRXRezpowerReservations = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsCRXRezpowerReservationsStart());
    ReservationsCRXRezpowerReservationsServices.createReservationsCRXRezpowerReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReservationsCRXRezpowerReservationsSuccess());
        goBack();
      } else {
        dispatch(createReservationsCRXRezpowerReservationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editReservationsCRXRezpowerReservationsStart = (payload) => ({
  type: EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS.START,
  payload
});

export const editReservationsCRXRezpowerReservationsSuccess = (payload) => ({
  type: EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS.SUCCESS,
  payload,
});

export const editReservationsCRXRezpowerReservationsError = (payload) => ({
  type: EDIT_RESERVATIONS_CRXREZPOWERRESERVATIONS.ERROR,
  payload,
});

export const editReservationsCRXRezpowerReservations = (payload) => {
  return (dispatch) => {
    dispatch(editReservationsCRXRezpowerReservationsStart());
    ReservationsCRXRezpowerReservationsServices.editReservationsCRXRezpowerReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReservationsCRXRezpowerReservationsSuccess());
        goBack();
      } else {
        dispatch(editReservationsCRXRezpowerReservationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportReservationsCRXRezpowerReservationsRecordStart = (payload) => ({
  type: EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS.START,
  payload
});

export const exportReservationsCRXRezpowerReservationsRecordSuccess = (payload) => ({
  type: EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS.SUCCESS,
  payload,
});

export const exportReservationsCRXRezpowerReservationsRecordError = (payload) => ({
  type: EXPORT_RESERVATIONS_CRXREZPOWERRESERVATIONS.ERROR,
  payload,
});

export const exportReservationsCRXRezpowerReservations = (payload) => {
  return (dispatch) => {
    dispatch(exportReservationsCRXRezpowerReservationsRecordStart());
    ReservationsCRXRezpowerReservationsServices.exportReservationsCRXRezpowerReservationsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportReservationsCRXRezpowerReservationsRecordSuccess(data));
      } else {
        dispatch(exportReservationsCRXRezpowerReservationsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const ReservationsCRXRezpowerReservationsDeleteStart = (payload) => ({
  type: RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE.START,
  payload,
});

export const ReservationsCRXRezpowerReservationsDeleteSuccess = (payload) => ({
  type: RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE.SUCCESS,
  payload,
});

export const ReservationsCRXRezpowerReservationsDeleteError = (payload) => ({
  type: RESERVATIONS_CRXREZPOWERRESERVATIONSDELETE.ERROR,
  payload,
});

export const deleteReservationsCRXRezpowerReservations = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsCRXRezpowerReservationsDeleteStart(payload));

    ReservationsCRXRezpowerReservationsServices
      .deleteReservationsCRXRezpowerReservations(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsCRXRezpowerReservationsDeleteSuccess(data));
          dispatch(fetchReservationsCRXRezpowerReservations(payload?.params));
        } else {
          dispatch(ReservationsCRXRezpowerReservationsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/