import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST, 
  TOAST, 
  FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_INIT,
  FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL,
  EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE,
  CREATE_RATEADMIN_EXTRAPRODUCTCHARGE,
  EDIT_RATEADMIN_EXTRAPRODUCTCHARGE,
  INIT_CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SCREEN,
  RATEADMIN_EXTRAPRODUCTCHARGEDD,
  RATEADMIN_RATENETWORKDD,
  RATEADMIN_RATEOPTIONDD,
  RATEADMIN_EXTRAPRODUCTCHARGEDELETE
} from "../../../types/rateadminextraproductcharge.type";
import { SET_DELETE_CONFIRMATION_POPUP_VISIBILITY } from "../../../types/rateadminextraproductcategory.type";

  const formFieldValueMap = {
    id:null,
    title:"",
    description: "",
    fkExtracategoryId:"",
    vehicleCategoryId:[],
    startDate:"",
  endDate: "",
  isFixed:"",
  fixedCost:"",
    status:1,
    userId:"",
    modBy:"",
    modTime:"",
    dailybaseRate:"15",
    dailyextraRate:"15",
    dailyhoursRate:"15",
  dailylor1st:"0.5",
  dailylor2nd:"0.5",
  dailylor3rd:"0.5",
  dailylor4th:"0.5",
  dailylor5th:"0.5",
  dailylor6th:"0.5",
  weekendbaseRate:"15",
  weekendextraRate:"15",
  weekendhoursRate:"15",
  weekendlor1st :"0.5",
  weekendlor2nd :"0.5",
  weekendlor3rd :"0.5",
  weekendlor4th :"0.5",
  weekendlor5th :"0.5",
  weekendlor6th :"0.5",
  weeklybaseRate:"15",
  weeklybaseMiles:"15",
  weeklyextraRate:"15",
  weeklyextraMiles:"15",
  weeklyhoursRate:"15",
  weeklylor1st :"0.5",
  weeklylor2nd :"0.5",
  weeklylor3rd :"0.5",
  weeklylor4th :"0.5",

  monthlybaseRate:"15",
  monthlyextraRate:"15",
  monthlyhoursRate:"15",
  };
  
  const INITIAL_STATE = {
    rateAdminExtraProductChargeListview: [],
    ExtraProductChargeDD:[],
    RateOptionsDD:[],
    RateNetworkDD:[],
    isRateAdminExtraProductChargeDDSuccess: false,
    isRateAdminExtraProductChargeDDError: false,
    isRateAdminRateOptionsDDSuccess: false,
    isRateAdminRateOptionsDDError: false,
    isRateAdminRateNetworkDDSuccess: false,
    isRateAdminRateNetworkDDError: false,

    isRateAdminExtraProductChargeDeleteSuccess: false,
    isRateAdminExtraProductChargeDeleteError: false,

    isLoading: false,
    error: null,
    formFieldValueMap,
  
   

    isRateAdminExtraProductChargeCreateSuccess: false,
    isRateAdminExtraProductChargeCreateError: false,
    isRateAdminExtraProductChargeDetailSuccess: false,
    isRateAdminExtraProductChargeDetailError: false,
    isRateAdminExtraProductChargeEditSuccess: false,
    isRateAdminExtraProductChargeEditError: false,
    isRateAdminExtraProductChargeExportSuccess: false,
    isRateAdminExtraProductChargeExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        extraProduct,
        category,
        startDate,
        endDate,
        dailyRate,
        fixedCost,
        monthlyRate,
        weekendRate,
        weeklyRate,
        createdBy,
        createdTime,
        modifiedBy,
        modifiedTime,
        status
      } = item;
  
      const transformedValues = {
        id,
        extraProduct,
        category,
        startDate,
        endDate,
        dailyRate,
        fixedCost,
        monthlyRate,
        weekendRate,
        weeklyRate,
        createdBy,
        createdTime,
        modifiedBy,
        modifiedTime,
        status
       
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Extra Product",
        dataKey: "extraProduct",
        extraProps: {
          isDropDownField:false,
          options: [],
          filterField: 'extraProduct',
          sortField: 'extraProduct',
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Category",
        dataKey: "category",
        extraProps: {
          filterField: 'category',
          sortField: 'category',
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          isDropDownField:true,
          options:[],
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
       
      }, 
      {
        colLabel: "Start Date",
        dataKey: "startDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          isDropDownField:false,
          options:[],
          filterField: 'startDate',
          sortField: 'startDate',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "End Date",
        dataKey: "endDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          isDropDownField:false,
          options:[],
          filterField: 'endDate',
          sortField: 'endDate',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Daily",
        dataKey: "dailyRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          isDropDownField:false,
          options:[],
          filterField: 'dailyRate',
          sortField: 'dailyRate',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Weekend",
        dataKey: "weekendRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          isDropDownField:false,
          options:[],
          filterField: 'weekendRate',
          sortField: 'weekendRate',
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
     
      {
        colLabel: "Weekly",
        dataKey: "weeklyRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Monthly",
        dataKey: "monthlyRate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Fixed Cost",
        dataKey: "fixedCost",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "createdBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "createdTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
       
      {
        colLabel: "Mod By",
        dataKey: "modifiedBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod Time",
        dataKey: "modifiedTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
    
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminExtraProductChargeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL.START:    
      case EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST.START:
        case RATEADMIN_EXTRAPRODUCTCHARGEDELETE.START:
      case CREATE_RATEADMIN_EXTRAPRODUCTCHARGE.START:
        case EDIT_RATEADMIN_EXTRAPRODUCTCHARGE.START:
          
                  return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          rateAdminExtraProductChargeListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminExtraProductChargeCreateSuccess: false,
          isRateAdminExtraProductChargeCreateError: false,
        };
        if (!action.payload) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...state,
          rateAdminExtraProductChargeListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminExtraProductChargeListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminExtraProductChargeDeletionSuccess: false,
          israteadminExtraProductChargeDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
              if(key==="endDate"){
                temp[key] = new Date(action.payload[key])
              }
              if(key==="startDate"){
                temp[key] = new Date(action.payload[key])
              }
             
              
          }
        });
console.log("temp", temp);

//BASE
        if(action.payload.rateBaseDTO.length>0){ 
          let dailyBase = action.payload?.rateBaseDTO[0];
          let weekendBase = action.payload?.rateBaseDTO[1];
          let weeklyBase = action.payload?.rateBaseDTO[2];
          let monthlyBase = action.payload?.rateBaseDTO[3];
         
       //Daily
          temp["dailybaseRate"] = dailyBase?.rate;
          temp["dailybaseId"] = dailyBase?.id;

      //Weekend
      temp["weekendbaseRate"] = weekendBase?.rate;
      temp["weekendbaseId"] = weekendBase?.id;
      //Weekly
      temp["weeklybaseRate"] = weeklyBase?.rate;
      temp["weeklybaseId"] = weeklyBase?.id;
      
      //Monthly
      temp["monthlybaseRate"] = monthlyBase?.rate;
      temp["monthlybaseId"] = monthlyBase?.id;
        }

//Extra Day	
        if(action.payload.rateExtraDayDTOS.length>0){ 
          let dailyextraRate = action.payload?.rateExtraDayDTOS[0];
          let weekendextraRate = action.payload?.rateExtraDayDTOS[1];
          let weeklyextraRate = action.payload?.rateExtraDayDTOS[2];
          let monthlyextraRate = action.payload?.rateExtraDayDTOS[3];
         
       //Daily
          temp["dailyextraRate"] = dailyextraRate?.rate;
          temp["dailyextraId"] = dailyextraRate?.id;

      //Weekend
      temp["weekendextraRate"] = weekendextraRate?.rate;
      temp["weekendextraId"] = weekendextraRate?.id;
      //Weekly
      temp["weeklyextraRate"] = weeklyextraRate?.rate;
      temp["weeklyextraId"] = weeklyextraRate?.id;
      
      //Monthly
      temp["monthlyextraRate"] = monthlyextraRate?.rate;
      temp["monthlyextraId"] = monthlyextraRate?.id;
        }

        
//Hours
if(action.payload.rateExtraHourDTOS.length>0){ 
  let dailyhoursRate = action.payload?.rateExtraHourDTOS[0];
  let weekendhoursRate = action.payload?.rateExtraHourDTOS[1];
  let weeklyhoursRate = action.payload?.rateExtraHourDTOS[2];
  let monthlyhoursRate = action.payload?.rateExtraHourDTOS[3];
 
//Daily
  temp["dailyhoursRate"] = dailyhoursRate?.rate;
  temp["dailyhoursId"] = dailyhoursRate?.id;

//Weekend
temp["weekendhoursRate"] = weekendhoursRate?.rate;
temp["weekendhoursId"] = weekendhoursRate?.id;
//Weekly
temp["weeklyhoursRate"] = weeklyhoursRate?.rate;
temp["weeklyhoursId"] = weeklyhoursRate?.id;

//Monthly
temp["monthlyhoursRate"] = monthlyhoursRate?.rate;
temp["monthlyhoursId"] = monthlyhoursRate?.id;
}


// LOR ADJ

if(action.payload.rateLorAdjustmentDTOS.length>0){ 
  let lor= action.payload.rateLorAdjustmentDTOS;

  //Daily
  temp["dailylor1st"] = lor[0]?.rate;
  temp["dailylor1stId"] = lor[0]?.id;

  temp["dailylor2nd"] = lor[1]?.rate;
  temp["dailylor2ndId"] = lor[1]?.id;

  temp["dailylor3rd"] = lor[2]?.rate;
  temp["dailylor3rdId"] = lor[2]?.id;

  temp["dailylor4th"] = lor[3]?.rate;
  temp["dailylor4thId"] = lor[3]?.id;

  temp["dailylor5th"] = lor[4]?.rate;
  temp["dailylor5thId"] = lor[4]?.id;

  temp["dailylor6th"] = lor[5]?.rate;
  temp["dailylor6thId"] = lor[5]?.id;

  //Weekend

  temp["weekendlor1st"] = lor[6]?.rate;
  temp["weekendlor1stId"] = lor[6]?.id;

  temp["weekendlor2nd"] = lor[7]?.rate;
  temp["weekendlor2ndId"] = lor[7]?.id;

  temp["weekendlor3rd"] = lor[8]?.rate;
  temp["weekendlor3rdId"] = lor[8]?.id;

  temp["weekendlor4th"] = lor[9]?.rate;
  temp["weekendlor4thId"] = lor[9]?.id;

  temp["weekendlor5th"] = lor[10]?.rate;
  temp["weekendlor5thId"] = lor[10]?.id;

  temp["weekendlor6th"] = lor[11]?.rate;
  temp["weekendlor6thId"] = lor[11]?.id;


  //Weekly

  temp["weeklylor1st"] = lor[12]?.rate;
  temp["weeklylor1stId"] = lor[12]?.id;

  temp["weeklylor2nd"] = lor[13]?.rate;
  temp["weeklylor2ndId"] = lor[13]?.id;

  temp["weeklylor3rd"] = lor[14]?.rate;
  temp["weeklylor3rdId"] = lor[14]?.id;

  temp["weeklylor4th"] = lor[15]?.rate;
  temp["weeklylor4thId"] = lor[15]?.id;
}
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRateAdminExtraProductChargeDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_EXTRAPRODUCTCHARGE_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminExtraProductChargeDetailSuccess: false,
        isRateAdminExtraProductChargeDetailError: true,
      };
    }

    //DD
   //DD
   case RATEADMIN_EXTRAPRODUCTCHARGEDD.SUCCESS: {
    const temp = action.payload;

    return {
      ...state,
      isLoading: false,
      ExtraProductChargeDD:temp,
      isRateAdminRateCodeDDSuccess: true,
      isRateAdminRateCodeDDError: false,
    };
  }
  case RATEADMIN_EXTRAPRODUCTCHARGEDD.ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      error,
      isRateAdminRateCodeDDSuccess: false,
      isRateAdminRateCodeDDError: true,
    };
  }
  //END DD
    
  //DELETE

  case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
    const shouldOpenDeleteConfirmationPopup = action.payload;
    return {
      ...state,
      shouldOpenDeleteConfirmationPopup,
      isRateAdminExtraProductChargeDeleteSuccess: false,
      isRateAdminExtraProductChargeDeleteError: false,
    };
  }
  case RATEADMIN_EXTRAPRODUCTCHARGEDELETE.SUCCESS: {
    const temp = action.payload;

    return {
      ...state,
      isLoading: false,
      ExtraProductChargeDD:temp,
      isRateAdminExtraProductChargeDeleteSuccess: true,
      isRateAdminExtraProductChargeDeleteError: false,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  case RATEADMIN_EXTRAPRODUCTCHARGEDELETE.ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      error,
      isRateAdminExtraProductChargeDeleteSuccess: false,
      isRateAdminExtraProductChargeDeleteError: true,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  //END DD
    
     //Network DD
     case RATEADMIN_RATENETWORKDD.SUCCESS: {
      const temp = action.payload;
      return {
        ...state,
        isLoading: false,
        RateNetworkDD:temp,
        isRateAdminRateNetworkDDSuccess: true,
        isRateAdminRateNetworkDDError: false,
      };
    }
    case RATEADMIN_RATENETWORKDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateNetworkDDSuccess: false,
        isRateAdminRateNetworkDDError: true,
      };
    }
    //END DD
 //OPtions DD
 case RATEADMIN_RATEOPTIONDD.SUCCESS: {
  const temp = action.payload;
  return {
    ...state,
    isLoading: false,
    RateOptionsDD:temp,
    isRateAdminRateOptionsDDSuccess: true,
    isRateAdminRateOptionsDDError: false,
  };
}
case RATEADMIN_RATEOPTIONDD.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRateAdminRateOptionsDDSuccess: false,
    isRateAdminRateOptionsDDError: true,
  };
}
//END DD

// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminExtraProductChargeExportSuccess: false,
    isRateAdminExtraProductChargeExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_EXTRAPRODUCTCHARGE.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"extra-product-charge.xls");
   return {
    ...state,
    isRateAdminExtraProductChargeExportSuccess: true,
    isRateAdminExtraProductChargeExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_EXTRAPRODUCTCHARGE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminExtraProductChargeEditSuccess: false,
    isRateAdminExtraProductChargeEditError: true,
  };
}

case EDIT_RATEADMIN_EXTRAPRODUCTCHARGE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminExtraProductChargeEditSuccess: true,
    isRateAdminExtraProductChargeEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_EXTRAPRODUCTCHARGE_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_EXTRAPRODUCTCHARGE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminExtraProductChargeCreateSuccess: false,
    isRateAdminExtraProductChargeCreateError: true,
  };
}
case CREATE_RATEADMIN_EXTRAPRODUCTCHARGE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminExtraProductChargeCreateSuccess: true,
    isRateAdminExtraProductChargeCreateError: false,
  };
}

//End
  




      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminExtraProductChargeCreateSuccess: false,
          isRateAdminExtraProductChargeCreateError: false,
          isRateAdminExtraProductChargeEditError: false,
          isRateAdminExtraProductChargeEditSuccess: false,
          isRateAdminExtraProductChargeDetailError: false,
        };
      }
      default:
        return state;
    }
  };
  