import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { APP_URLS } from "../../../utils/constant";
import {
initializePasswordScreen,
changepassword
} from "../../../redux/actions/changepassword";
import { useEffect, useState } from "react";
import { setIsformDirty } from "../../../redux/actions/common/form";
import { useNavigate, useParams } from "react-router-dom";
import CreatePasswordComponent from "../../../component/ChangePassword/CreatePassword";

const CreatePasswordContainer = (props) => {
  
  console.log("checkprops on screen ", props);
  const {
    changepassword,
    isLoading,
    formFieldValueMap,
    isCreatePasswordSuccess,
    isCreatePasswordError,
    initializePasswordScreen,
    isEdit,

  } = props;


 

  const createPasswordProps = {
    changepassword,
    isLoading,
    formFieldValueMap,
    isEdit,
    isCreatePasswordSuccess,
    initializePasswordScreen,
    isCreatePasswordError
    };
  const getToastProps = () => {
    if (isCreatePasswordSuccess ) {
      const toastTitle = "Password Updated Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isCreatePasswordError
    
    ) {
      let toastTitle = "Error while Creating Location";
  

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };
  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreatePasswordComponent {...createPasswordProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromPasswordReducer(state, "isLoading"),
   
    isCreatePasswordSuccess: selectFromPasswordReducer(
      state,
      "isCreatePasswordSuccess"
    ),
    isCreatePasswordError: selectFromPasswordReducer(
      state,
      "isPasswordCreateError"
    ),
   
    formFieldValueMap: selectFromPasswordReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializePasswordScreen: () => dispatch(initializePasswordScreen()),
    changepassword: (payload) => dispatch(changepassword(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
  };
};

const selectFromPasswordReducer = (state, path) => {
  return selectPasswordReducer(state)[path];
};

const selectPasswordReducer = ({ changepasswordReducer }) => {
  return changepasswordReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePasswordContainer);
