import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateReservationsPrepaidBookingsReportScreen,
  createReservationsPrepaidBookingsReport,
  editReservationsPrepaidBookingsReport,
  fetchReservationsPrepaidBookingsReportById,
  uploadImage,
} from "../../../../redux/actions/Reservations/ReservationsPrepaidBookingsReport";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateReservationsPrepaidBookingsReportComponent from "../../../../component/Reservations/ReservationsPrepaidBookingsReport/CreateReservationsPrepaidBookingsReport";
import { useParams } from "react-router-dom";

const CreateReservationsPrepaidBookingsReportContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createReservationsPrepaidBookingsReport,
    editReservationsPrepaidBookingsReport,
    isLoading,
    isReservationsPrepaidBookingsReportCreateSuccess,
    isReservationsPrepaidBookingsReportCreateError,
    isReservationsPrepaidBookingsReportDetailSuccess,
    isReservationsPrepaidBookingsReportDetailError,
    isReservationsPrepaidBookingsReportEditSuccess,
    isReservationsPrepaidBookingsReportEditError,
    isEdit,
    isView,
    initCreateReservationsPrepaidBookingsReportScreen,
    formFieldValueMap,
    fetchReservationsPrepaidBookingsReportById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        fetchLocationDD,
        locationDD,
        fetchVehicleCategoryDD,
vehicleCategoryDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateReservationsPrepaidBookingsReportScreen();
    await fetchLocationDD();
    await fetchVehicleCategoryDD();
   if (isEdit) {
    await fetchReservationsPrepaidBookingsReportById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createReservationsPrepaidBookingsReportProps = {
    createReservationsPrepaidBookingsReport,
    initCreateReservationsPrepaidBookingsReportScreen,
    editReservationsPrepaidBookingsReport,
    isLoading,
    isEdit,
    isReservationsPrepaidBookingsReportDetailSuccess,
    isReservationsPrepaidBookingsReportCreateSuccess,
    isReservationsPrepaidBookingsReportEditSuccess,
    isReservationsPrepaidBookingsReportEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
  };
  const getToastProps = () => {
    if (isReservationsPrepaidBookingsReportCreateSuccess || isReservationsPrepaidBookingsReportEditSuccess) {
      const toastTitle = isEdit
        ? "Prepaid Bookings Report Updated Successfully"
        : "Prepaid Bookings Report Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isReservationsPrepaidBookingsReportCreateError ||
      isReservationsPrepaidBookingsReportDetailError ||
      isReservationsPrepaidBookingsReportEditError
    ) {
      let toastTitle = "Error while Creating Reservations";
      if (isReservationsPrepaidBookingsReportEditError) {
        toastTitle = "Error while updating  Reservations";
      } else if (isReservationsPrepaidBookingsReportDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateReservationsPrepaidBookingsReportComponent {...createReservationsPrepaidBookingsReportProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromReservationsPrepaidBookingsReportReducer(state, "isLoading"),
   
    isReservationsPrepaidBookingsReportCreateSuccess: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isReservationsPrepaidBookingsReportCreateSuccess"
    ),
    isReservationsPrepaidBookingsReportCreateError: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isReservationsPrepaidBookingsReportCreateError"
    ),
    isReservationsPrepaidBookingsReportDetailSuccess: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isReservationsPrepaidBookingsReportDetailSuccess"
    ),
    isReservationsPrepaidBookingsReportDetailError: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isReservationsPrepaidBookingsReportDetailError"
    ),
    isReservationsPrepaidBookingsReportEditSuccess: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isReservationsPrepaidBookingsReportEditSuccess"
    ),
    isReservationsPrepaidBookingsReportEditError: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isReservationsPrepaidBookingsReportEditError"
    ),
    imageUploadResponse: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromReservationsPrepaidBookingsReportReducer(
      state,
      "isVehicleModelDDError"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    formFieldValueMap: selectFromReservationsPrepaidBookingsReportReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateReservationsPrepaidBookingsReportScreen: () => dispatch(initCreateReservationsPrepaidBookingsReportScreen()),
    createReservationsPrepaidBookingsReport: (payload) => dispatch(createReservationsPrepaidBookingsReport(payload)),
    editReservationsPrepaidBookingsReport: (payload) => dispatch(editReservationsPrepaidBookingsReport(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchReservationsPrepaidBookingsReportById: (payload) =>
      dispatch(fetchReservationsPrepaidBookingsReportById(payload)),

  };
};

const selectFromReservationsPrepaidBookingsReportReducer = (state, path) => {
  return selectReservationsPrepaidBookingsReportReducer(state)[path];
};

const selectReservationsPrepaidBookingsReportReducer = ({ ReservationsPrepaidBookingsReportReducer }) => {
  return ReservationsPrepaidBookingsReportReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservationsPrepaidBookingsReportContainer);
