

export const FETCH_RATEADMIN_RATECODE_LIST = {
    START: 'FETCH_RATEADMIN_RATECODE_LIST_START',
    SUCCESS: 'FETCH_RATEADMIN_RATECODE_LIST_SUCCESS',
    ERROR: 'FETCH_RATEADMIN_RATECODE_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RATEADMIN_RATECODE_INIT="FETCH_RATEADMIN_RATECODE_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RATEADMIN_RATECODE_SCREEN =
'INIT_CREATE_RATEADMIN_RATECODE_SCREEN';

export const CREATE_RATEADMIN_RATECODE = {
START: 'CREATE_RATEADMIN_RATECODE_START',
SUCCESS: 'CREATE_RATEADMIN_RATECODE_SUCCESS',
ERROR: 'CREATE_RATEADMIN_RATECODE_ERROR',
};

export const EDIT_RATEADMIN_RATECODE = {
START: 'EDIT_RATEADMIN_RATECODE_START',
SUCCESS: 'EDIT_RATEADMIN_RATECODE_SUCCESS',
ERROR: 'EDIT_RATEADMIN_RATECODE_ERROR',
};

export const FETCH_RATEADMIN_RATECODE_DETAIL = {
  START: 'FETCH_RATEADMIN_RATECODE_DETAIL_START',
  SUCCESS: 'FETCH_RATEADMIN_RATECODE_DETAIL_SUCCESS',
  ERROR: 'FETCH_RATEADMIN_RATECODE_DETAIL_ERROR',
};
export const EXPORT_RATEADMIN_RATECODE = {
  START: 'EXPORT_RATEADMIN_RATECODE_START',
  SUCCESS: 'EXPORT_RATEADMIN_RATECODE_SUCCESS',
  ERROR: 'EXPORT_RATEADMIN_RATECODE_ERROR',
};
export const RATEADMIN_RATECODEDD = {
  START: 'RATEADMIN_RATECODEDD_START',
  SUCCESS: 'RATEADMIN_RATECODEDD_SUCCESS',
  ERROR: 'RATEADMIN_RATECODEDD_ERROR',
};