import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Controller } from 'react-hook-form';

const EzControlledDropDown = (props) => {
  const {
    control,
    name,
    shouldFilter,
    filterBy,
    options,
    optionLabel,
    optionValue,
    rules,
    onChange,
    disabled
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Dropdown
          disabled={disabled}
          filter={shouldFilter}
          filterBy={filterBy || optionLabel}
          id={field.name}
          value={field.value}
          options={options}
          optionLabel={optionLabel}
          editable={true}
          optionValue={optionValue}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            field.onChange(e.value);
          }}
        />
      )}
    />
  );
};

EzControlledDropDown.defaultProps = {
  optionLabel: 'label',
  optionValue: 'value',
  shouldFilter: true,
};

export default EzControlledDropDown;
