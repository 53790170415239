export const basicDetail =
[
  
  {
    name:"title",
     "rule": {
        "required": true
      },
    label:"Extra Product",
    type:"text"
  }, {
    name:"description",
     "rule": {
        "required": true
      },
    label:"Description",
    type:"text"
  },
  {
    name:"fkExtracategoryId",
     "rule": {
        "required": true
      },
    label:"Product Category",
    type:"dropdown"
  },
  {
    name:"vehicleCategoryId",
     "rule": {
        "required": true
      },
    label:"Vehicle Category",
    type:"dropdown"
  },
  {
    name:"startDate",
     "rule": {
        "required": true
      },
    label:"From",
    type:"date"
  },
  {
    name:"endDate",
     "rule": {
        "required": true
      },
    label:"To",
    type:"date"
  },

  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]


export const rateExtraDaily = [
  {
    label:"Daily",
   
  },
  {
    name:"dailybaseRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"dailyextraRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"dailyhoursRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text",
    notify:"Effective"
  },
  {
    name:"dailylor1st",
     "rule": {
        "required": true
      },
    label:"1st",
    type:"text"
  },
  {
    name:"dailylor2nd",
     "rule": {
        "required": true
      },
    label:"2nd",
    type:"text"
  },
  {
    name:"dailylor3rd",
     "rule": {
        "required": true
      },
    label:"daily3rd",
    type:"text"
  },
  {
    name:"dailylor4th",
     "rule": {
        "required": true
      },
    label:"4th",
    type:"text"
  },
  {
    name:"dailylor5th",
     "rule": {
        "required": true
      },
    label:"5th",
    type:"text"
  },
  {
    name:"dailylor6th",
     "rule": {
        "required": true
      },
    label:"6th",
    type:"text"
  }
]



export const rateExtraWeekend = [
  {
    label:"Weekend",
   
  },
  {
    name:"weekendbaseRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"weekendextraRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"weekendhoursRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text",
    notify:"Effective"
  },
  {
    name:"weekendlor1st",
     "rule": {
        "required": true
      },
    label:"1st",
    type:"text"
  },
  {
    name:"weekendlor2nd",
     "rule": {
        "required": true
      },
    label:"2nd",
    type:"text"
  },
  {
    name:"weekendlor3rd",
     "rule": {
        "required": true
      },
    label:"3rd",
    type:"text"
  },
  {
    name:"weekendlor4th",
     "rule": {
        "required": true
      },
    label:"4th",
    type:"text"
  },
  {
    name:"weekendlor5th",
     "rule": {
        "required": true
      },
    label:"5th",
    type:"text"
  },
  {
    name:"weekendlor6th",
     "rule": {
        "required": true
      },
    label:"6th",
    type:"text"
  }
]

export const rateExtraWeekly = [
  {
    label:"Weekly",
   
  },
  {
    name:"weeklybaseRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"weeklyextraRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"weeklyhoursRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text",
    notify:"Effective"
  },
  {
    name:"weeklylor1st",
     "rule": {
        "required": true
      },
    label:"1st",
    type:"text"
  },
  {
    name:"weeklylor2nd",
     "rule": {
        "required": true
      },
    label:"2nd",
    type:"text"
  },
  {
    name:"weeklylor3rd",
     "rule": {
        "required": true
      },
    label:"3rd",
    type:"text"
  },
  {
    name:"weeklylor4th",
     "rule": {
        "required": true
      },
    label:"4th",
    type:"text"
  },
  {},
  {}
]



export const rateExtraMonthly = [
  {
    label:"Monthly",
   
  },
  {
    name:"monthlybaseRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"monthlyextraRate",
     "rule": {
        "required": false
      },
    label:"Rate",
    type:"text"
  },
  {
    name:"monthlyhoursRate",
     "rule": {
        "required": true
      },
    label:"Rate",
    type:"text",
    notify:"Effective"
  },
  {},
  {},
  {},
  {},
  {},
  {},
]
export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const RateTypeDD =
[
  { id:1, name:"Exclusive"},
  { id:2, name:"Inclusive"}
]

export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

  export const rateAdjustment = [
   {
      name:"daily",
       "rule": {
          "required": true
        },
      label:"Daily",
      type:"text"
    },{
      name:"weekEnd",
       "rule": {
          "required": true
        },
      label:"Weekend",
      type:"text"
    },
    {
      name:"weekly",
       "rule": {
          "required": true
        },
      label:"Weekly",
      type:"text"
    },
    
    {
      name:"monthly",
       "rule": {
          "required": true
        },
      label:"Monthly",
      type:"text"
    },
  ]

  export const RateAdminBreadcrumb = [
   
            {label:"Dashboard", path:"/dashboard"},
            {label:"Rate Code", path:"/rate-code"},
            {label:"Rate Plans", path:"/rate-plans"},
            {label:"Rate Types", path:"/rate-types"},
            {label:"Lor Adjustments", path:"/lor-adjustments"},
            {label:"Rates", path:"/rates"},
            {label:"Extra Product Category", path:"/extra-product-category"},
            {label:"Extra Product Charge", path:"/extra-product-charge"},
            {label:"Networks", path:"/networks"},
            {label:"Blackouts", path:"/blackouts"},
            {label:"Rate Mirror", path:"/rate-mirror"},
            {label:"", path:"/rate-mirror"}

  ]


  export const columns = [
    {
      colLabel: "ID",
      dataKey: "id",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    },
    {
      colLabel: "Location",
      dataKey: "locationTitle",
      extraProps: {
        isDropDownField:false,
        options: [],
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    },
  ]