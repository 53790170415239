import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
 import { HolidaysServices } from "../../../services";
import { goBack } from "../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../types/uploadimage.type";
import {
  FETCH_HOLIDAYS_INIT, 
  FETCH_HOLIDAYS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_HOLIDAYS_SCREEN,
  CREATE_HOLIDAYS,
  EDIT_HOLIDAYS,
  TOAST,
  FETCH_HOLIDAYS_DETAIL,
  EXPORT_HOLIDAYS,
  HOLIDAYSDD,
  HOLIDAYSDELETE
} from "../../types/Holidays/Holidays.type";
export const initializeHolidaysScreen = () => ({ type: FETCH_HOLIDAYS_INIT });

export const HolidaysFetchStart = (payload) => ({
  type: FETCH_HOLIDAYS_LIST.START,
  payload,
});

export const HolidaysFetchSuccess = (payload) => ({
  type:FETCH_HOLIDAYS_LIST.SUCCESS,
  payload,
});

export const HolidaysFetchError = (payload) => ({
  type: FETCH_HOLIDAYS_LIST.ERROR,
  payload,
});

export const fetchHolidays = (payload) => {
  return (dispatch) => {
    dispatch(HolidaysFetchStart(payload));
    HolidaysServices.fetchHolidays(payload).then((data) => {
      if (!data.isError) {
        dispatch(HolidaysFetchSuccess(data));
      } else {
        dispatch(HolidaysFetchError(data));
      }
    });
  };
};



// DD
export const HolidaysDDFetchStart = (payload) => ({
  type: HOLIDAYSDD.START,
  payload,
});

export const HolidaysDDFetchSuccess = (payload) => ({
  type:HOLIDAYSDD.SUCCESS,
  payload,
});

export const HolidaysDDFetchError = (payload) => ({
  type: HOLIDAYSDD.ERROR,
  payload,
});

export const fetchHolidaysDD = (payload) => {
  return (dispatch) => {
    dispatch(HolidaysDDFetchStart(payload));
    HolidaysServices.fetchHolidaysDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(HolidaysDDFetchSuccess(data));
      } else {
        dispatch(HolidaysDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const HolidaysDetailFetchStart = (payload) => ({
  type: FETCH_HOLIDAYS_DETAIL.START,
  payload,
});

export const HolidaysDetailFetchSuccess = (payload) => ({
  type: FETCH_HOLIDAYS_DETAIL.SUCCESS,
  payload,
});

export const HolidaysDetailFetchError = (payload) => ({
  type: FETCH_HOLIDAYS_DETAIL.ERROR,
  payload,
});

export const fetchHolidaysById = (payload) => {
  return (dispatch) => {
    dispatch(HolidaysDetailFetchStart(payload));

    HolidaysServices
      .fetchHolidaysById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(HolidaysDetailFetchSuccess(data));
        } else {
          dispatch(HolidaysDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    HolidaysServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createHolidaysScreenInitStart = (payload) => ({
  type: INIT_CREATE_HOLIDAYS_SCREEN,
  payload,
});

export const initCreateHolidaysScreen = (payload) => {
  return (dispatch) => {
    dispatch(createHolidaysScreenInitStart());
  };
};


export const createHolidaysStart = () => ({
  type: CREATE_HOLIDAYS.START,
});

export const createHolidaysSuccess = (payload) => ({
  type: CREATE_HOLIDAYS.SUCCESS,
  payload,
});

export const createHolidaysError = (payload) => ({
  type: CREATE_HOLIDAYS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createHolidays = (payload) => {
  return (dispatch) => {
    dispatch(createHolidaysStart());
    HolidaysServices.createHolidays(payload).then((data) => {
      if (!data.isError) {
        dispatch(createHolidaysSuccess());
        goBack();
      } else {
        dispatch(createHolidaysError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editHolidaysStart = (payload) => ({
  type: EDIT_HOLIDAYS.START,
  payload
});

export const editHolidaysSuccess = (payload) => ({
  type: EDIT_HOLIDAYS.SUCCESS,
  payload,
});

export const editHolidaysError = (payload) => ({
  type: EDIT_HOLIDAYS.ERROR,
  payload,
});

export const editHolidays = (payload) => {
  return (dispatch) => {
    dispatch(editHolidaysStart());
    HolidaysServices.editHolidays(payload).then((data) => {
      if (!data.isError) {
        dispatch(editHolidaysSuccess());
        goBack();
      } else {
        dispatch(editHolidaysError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportHolidaysRecordStart = (payload) => ({
  type: EXPORT_HOLIDAYS.START,
  payload
});

export const exportHolidaysRecordSuccess = (payload) => ({
  type: EXPORT_HOLIDAYS.SUCCESS,
  payload,
});

export const exportHolidaysRecordError = (payload) => ({
  type: EXPORT_HOLIDAYS.ERROR,
  payload,
});

export const exportHolidays = (payload) => {
  return (dispatch) => {
    dispatch(exportHolidaysRecordStart());
    HolidaysServices.exportHolidaysRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportHolidaysRecordSuccess(data));
      } else {
        dispatch(exportHolidaysRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const HolidaysDeleteStart = (payload) => ({
  type: HOLIDAYSDELETE.START,
  payload,
});

export const HolidaysDeleteSuccess = (payload) => ({
  type: HOLIDAYSDELETE.SUCCESS,
  payload,
});

export const HolidaysDeleteError = (payload) => ({
  type: HOLIDAYSDELETE.ERROR,
  payload,
});

export const deleteHolidays = (payload) => {
  return (dispatch) => {
    dispatch(HolidaysDeleteStart(payload));

    HolidaysServices
      .deleteHolidays(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(HolidaysDeleteSuccess(data));
          dispatch(fetchHolidays(payload?.params));
        } else {
          dispatch(HolidaysDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/