import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST, 
  TOAST, 
  FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_INIT,
  FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL,
  EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL,
  CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL,
  EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL,
  INIT_CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL_SCREEN,
  VEHICLE_OPERATIONDD
} from "../../../types/vehicledealershipvehicledeal.type";



  const formFieldValueMap = {
   "dateFrom": "",
  "dateTo": "",
  "estimatedDepreciation": "",
  "fkSupplierId": "",
  "id": "",
  "ip": "",
  "make": "",
  "modBy": "",
  "modTime": "",
  "model": "",
  "purchaseType": "",
  "status": "",
  "trim": "",
  "userId": ""
  };
  
  const INITIAL_STATE = {
    vehicleDealershipVehicleDealListview: [],
    vehicleOperationDD:[],
    isVehicleOperationDDSuccess: false,
    isVehicleOperationDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleDealershipVehicleDealCreateSuccess: false,
    isVehicleDealershipVehicleDealCreateError: false,
    isVehicleDealershipVehicleDealDetailSuccess: false,
    isVehicleDealershipVehicleDealDetailError: false,
    isVehicleDealershipVehicleDealEditSuccess: false,
    isVehicleDealershipVehicleDealEditError: false,
    isVehicleDealershipVehicleDealExportSuccess: false,
    isVehicleDealershipVehicleDealExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
        crtTime,
        id,
        modBy,
        modTime,
        dateFrom,
        dateTo,
        make,
        model,
        trim,
        fkSupplierId,
        purchaseType,
        estimatedDepreciation,
        status
      } = item;
  
      const transformedValues = {
        crtBy,
        crtTime,
        id,
        modBy,
        modTime,
        dateFrom,
        dateTo,
        make,
        model,
        trim,
        fkSupplierId,
        purchaseType,
        estimatedDepreciation,
        status
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Date From",
        dataKey: "dateFrom",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Date To",
        dataKey: "dateTo",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Make",
        dataKey: "make",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Model",
        dataKey: "model",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
  
     
      {
        colLabel: "Trim",
        dataKey: "trim",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Dealership",
        dataKey: "fkSupplierId",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Purchase Type",
        dataKey: "purchaseType",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Estimated Depreciation",
        dataKey: "estimatedDepreciation",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleDealershipVehicleDealReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL.START:
      case EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST.START:
      case CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL.START:
        case EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL.START:
          case VEHICLE_OPERATIONDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleDealershipVehicleDealListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleDealershipVehicleDealCreateSuccess: false,
          isVehicleDealershipVehicleDealCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleDealershipVehicleDealListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleDealershipVehicleDealListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleDealershipVehicleDealDeletionSuccess: false,
          isvehicleDealershipVehicleDealDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
              temp["dateFrom"] = new Date(action.payload?.dateFrom);
              temp["dateTo"] = new Date(action.payload?.dateTo);
           
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleDealershipVehicleDealDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_DEALERSHIPVEHICLEDEAL_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleDealershipVehicleDealDetailSuccess: false,
        isVehicleDealershipVehicleDealDetailError: true,
      };
    }

    //DD
    case VEHICLE_OPERATIONDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        vehicleOperationDD:temp,
        isVehicleOperationDDSuccess: true,
        isVehicleOperationDDError: false,
      };
    }
    case VEHICLE_OPERATIONDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleOperationDDSuccess: false,
    isVehicleOperationDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleDealershipVehicleDealExportSuccess: false,
    isVehicleDealershipVehicleDealExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_DEALERSHIPVEHICLEDEAL.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-make.xls");
   return {
    ...state,
    isVehicleDealershipVehicleDealExportSuccess: true,
    isVehicleDealershipVehicleDealExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleDealershipVehicleDealEditSuccess: false,
    isVehicleDealershipVehicleDealEditError: true,
  };
}

case EDIT_VEHICLE_DEALERSHIPVEHICLEDEAL.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleDealershipVehicleDealEditSuccess: true,
    isVehicleDealershipVehicleDealEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleDealershipVehicleDealCreateSuccess: false,
    isVehicleDealershipVehicleDealCreateError: true,
  };
}
case CREATE_VEHICLE_DEALERSHIPVEHICLEDEAL.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleDealershipVehicleDealCreateSuccess: true,
    isVehicleDealershipVehicleDealCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleDealershipVehicleDealCreateSuccess: false,
          isVehicleDealershipVehicleDealCreateError: false,
          isVehicleDealershipVehicleDealEditError: false,
          isVehicleDealershipVehicleDealEditSuccess: false,
          isVehicleDealershipVehicleDealDetailError: false
        };
      }
      default:
        return state;
    }
  };
  