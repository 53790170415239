import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminRateTypes,
    initializeRateAdminRateTypesScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminRateTypes
  } from "../../../redux/actions/RateAdmin/rateadminRateTypes";
import RateAdminRateTypes from '../../../component/RateAdmin/RateAdminRateTypes';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminRateTypesScreen(props) {
    const {
        fetchRateAdminRateTypes,
        initRateAdminRateTypesScreen,
        showPageLevelLoader,
        rateAdminRateTypesListview,
        paginationInfo,
        exportRateAdminRateTypes,
        isRateAdminRateTypesExportError,
        isRateAdminRateTypesExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminRateTypesScreen();
        fetchRateAdminRateTypes({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchRateAdminRateTypes(props);
      };
      const handleCreateRateAdminRateTypes =() =>{
      navigate(APP_URLS.RATEADMIN_RATE_TYPES.CREATE);
    }
      const editRateAdminRateTypesRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_RATE_TYPES.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminRateTypes = {
  handleCreateRateAdminRateTypes,
  exportRateAdminRateTypes,
        isRateAdminRateTypesExportError,
        isRateAdminRateTypesExportSuccess,
  editRateAdminRateTypesRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminRateTypess: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminRateTypesListview?.columns,
    rows: rateAdminRateTypesListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminRateTypes
    {...rateAdminRateTypes}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminRateTypesScreen: () => dispatch(initializeRateAdminRateTypesScreen()),
      fetchRateAdminRateTypes: (payload) => dispatch(fetchRateAdminRateTypes(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRateAdminRateTypes: (payload) => dispatch(exportRateAdminRateTypes(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminRateTypesCreateSuccess: selectFromRateAdminRateTypesReducer(
        state,
        "isRateAdminRateTypesCreateSuccess"
      ),
      rateAdminRateTypesListview: selectFromRateAdminRateTypesReducer(
        state,
        "rateAdminRateTypesListview"
      ),
      showPageLevelLoader: selectFromRateAdminRateTypesReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminRateTypesReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRateAdminRateTypesReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminRateTypesReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminRateTypesExportError: selectFromRateAdminRateTypesReducer(
        state,
        "isRateAdminRateTypesExportError"
      ),
      isRateAdminRateTypesExportSuccess: selectFromRateAdminRateTypesReducer(
        state,
        "isRateAdminRateTypesExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRateAdminRateTypesReducer = (state, path) => {
  return selectRateAdminRateTypesReducer(state)[path];
};

const selectRateAdminRateTypesReducer = ({ rateadminRateTypesReducer }) => {
  return rateadminRateTypesReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RateAdminRateTypesScreen);
