import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { SettingsPaynowDiscountsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_SETTINGS_PAYNOWDISCOUNTS_INIT, 
  FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_SETTINGS_PAYNOWDISCOUNTS_SCREEN,
  CREATE_SETTINGS_PAYNOWDISCOUNTS,
  EDIT_SETTINGS_PAYNOWDISCOUNTS,
  TOAST,
  FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL,
  EXPORT_SETTINGS_PAYNOWDISCOUNTS,
  SETTINGS_PAYNOWDISCOUNTSDD,
  SETTINGS_PAYNOWDISCOUNTSDELETE
} from "../../../types/Settings/PaynowDiscounts.type";
export const initializeSettingsPaynowDiscountsScreen = () => ({ type: FETCH_SETTINGS_PAYNOWDISCOUNTS_INIT });

export const SettingsPaynowDiscountsFetchStart = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST.START,
  payload,
});

export const SettingsPaynowDiscountsFetchSuccess = (payload) => ({
  type:FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST.SUCCESS,
  payload,
});

export const SettingsPaynowDiscountsFetchError = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWDISCOUNTS_LIST.ERROR,
  payload,
});

export const fetchSettingsPaynowDiscounts = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowDiscountsFetchStart(payload));
    SettingsPaynowDiscountsServices.fetchSettingsPaynowDiscounts(payload).then((data) => {
      if (!data.isError) {
        dispatch(SettingsPaynowDiscountsFetchSuccess(data));
      } else {
        dispatch(SettingsPaynowDiscountsFetchError(data));
      }
    });
  };
};



// DD
export const SettingsPaynowDiscountsDDFetchStart = (payload) => ({
  type: SETTINGS_PAYNOWDISCOUNTSDD.START,
  payload,
});

export const SettingsPaynowDiscountsDDFetchSuccess = (payload) => ({
  type:SETTINGS_PAYNOWDISCOUNTSDD.SUCCESS,
  payload,
});

export const SettingsPaynowDiscountsDDFetchError = (payload) => ({
  type: SETTINGS_PAYNOWDISCOUNTSDD.ERROR,
  payload,
});

export const fetchSettingsPaynowDiscountsDD = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowDiscountsDDFetchStart(payload));
    SettingsPaynowDiscountsServices.fetchSettingsPaynowDiscountsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(SettingsPaynowDiscountsDDFetchSuccess(data));
      } else {
        dispatch(SettingsPaynowDiscountsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const SettingsPaynowDiscountsDetailFetchStart = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL.START,
  payload,
});

export const SettingsPaynowDiscountsDetailFetchSuccess = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL.SUCCESS,
  payload,
});

export const SettingsPaynowDiscountsDetailFetchError = (payload) => ({
  type: FETCH_SETTINGS_PAYNOWDISCOUNTS_DETAIL.ERROR,
  payload,
});

export const fetchSettingsPaynowDiscountsById = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowDiscountsDetailFetchStart(payload));

    SettingsPaynowDiscountsServices
      .fetchSettingsPaynowDiscountsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(SettingsPaynowDiscountsDetailFetchSuccess(data));
        } else {
          dispatch(SettingsPaynowDiscountsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    SettingsPaynowDiscountsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createSettingsPaynowDiscountsScreenInitStart = (payload) => ({
  type: INIT_CREATE_SETTINGS_PAYNOWDISCOUNTS_SCREEN,
  payload,
});

export const initCreateSettingsPaynowDiscountsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createSettingsPaynowDiscountsScreenInitStart());
  };
};


export const createSettingsPaynowDiscountsStart = () => ({
  type: CREATE_SETTINGS_PAYNOWDISCOUNTS.START,
});

export const createSettingsPaynowDiscountsSuccess = (payload) => ({
  type: CREATE_SETTINGS_PAYNOWDISCOUNTS.SUCCESS,
  payload,
});

export const createSettingsPaynowDiscountsError = (payload) => ({
  type: CREATE_SETTINGS_PAYNOWDISCOUNTS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createSettingsPaynowDiscounts = (payload) => {
  return (dispatch) => {
    dispatch(createSettingsPaynowDiscountsStart());
    SettingsPaynowDiscountsServices.createSettingsPaynowDiscounts(payload).then((data) => {
      if (!data.isError) {
        dispatch(createSettingsPaynowDiscountsSuccess());
        goBack();
      } else {
        dispatch(createSettingsPaynowDiscountsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editSettingsPaynowDiscountsStart = (payload) => ({
  type: EDIT_SETTINGS_PAYNOWDISCOUNTS.START,
  payload
});

export const editSettingsPaynowDiscountsSuccess = (payload) => ({
  type: EDIT_SETTINGS_PAYNOWDISCOUNTS.SUCCESS,
  payload,
});

export const editSettingsPaynowDiscountsError = (payload) => ({
  type: EDIT_SETTINGS_PAYNOWDISCOUNTS.ERROR,
  payload,
});

export const editSettingsPaynowDiscounts = (payload) => {
  return (dispatch) => {
    dispatch(editSettingsPaynowDiscountsStart());
    SettingsPaynowDiscountsServices.editSettingsPaynowDiscounts(payload).then((data) => {
      if (!data.isError) {
        dispatch(editSettingsPaynowDiscountsSuccess());
        goBack();
      } else {
        dispatch(editSettingsPaynowDiscountsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportSettingsPaynowDiscountsRecordStart = (payload) => ({
  type: EXPORT_SETTINGS_PAYNOWDISCOUNTS.START,
  payload
});

export const exportSettingsPaynowDiscountsRecordSuccess = (payload) => ({
  type: EXPORT_SETTINGS_PAYNOWDISCOUNTS.SUCCESS,
  payload,
});

export const exportSettingsPaynowDiscountsRecordError = (payload) => ({
  type: EXPORT_SETTINGS_PAYNOWDISCOUNTS.ERROR,
  payload,
});

export const exportSettingsPaynowDiscounts = (payload) => {
  return (dispatch) => {
    dispatch(exportSettingsPaynowDiscountsRecordStart());
    SettingsPaynowDiscountsServices.exportSettingsPaynowDiscountsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportSettingsPaynowDiscountsRecordSuccess(data));
      } else {
        dispatch(exportSettingsPaynowDiscountsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const SettingsPaynowDiscountsDeleteStart = (payload) => ({
  type: SETTINGS_PAYNOWDISCOUNTSDELETE.START,
  payload,
});

export const SettingsPaynowDiscountsDeleteSuccess = (payload) => ({
  type: SETTINGS_PAYNOWDISCOUNTSDELETE.SUCCESS,
  payload,
});

export const SettingsPaynowDiscountsDeleteError = (payload) => ({
  type: SETTINGS_PAYNOWDISCOUNTSDELETE.ERROR,
  payload,
});

export const deleteSettingsPaynowDiscounts = (payload) => {
  return (dispatch) => {
    dispatch(SettingsPaynowDiscountsDeleteStart(payload));

    SettingsPaynowDiscountsServices
      .deleteSettingsPaynowDiscounts(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(SettingsPaynowDiscountsDeleteSuccess(data));
          dispatch(fetchSettingsPaynowDiscounts(payload?.params));
        } else {
          dispatch(SettingsPaynowDiscountsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/