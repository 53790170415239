import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    topnav:{
        background: theme.palette.background.topheaderbg
       },
      
       menubox:{
        background:theme.palette.background.menubg
       },
       menuboxtext:{
        background:theme.palette.text.menutext
       },
       menubar:{
        background:theme.palette.background.menubg,
        '& ul': {
            '& li': {
                '& a': {
                    color:theme.palette.text.menutext
                }
            }
        }
       },
       logout:{
        color:theme.palette.text.topheadertext,
        textTransform:"capitalize",
        cursor:"pointer",
        position:"relative",
        alignItems: "center",
        width: "200px",
        paddingBottom: "37px",
        '& p': {
            width:"100%",
            textAlign:"right",
            position: "absolute",
            top: "9px",
        },
        '&:hover ul': {
 display:"block"
            },
        '& ul': {
            width: "200px",
            right: "0px",
            display: "none",
            position: "absolute",
            background: "#ffff",
            top: "20px",
            zIndex: 9999,
            boxShadow:"#ddd 4px 4px 5px",
            padding: "0",
            listStyle:"none",
            
            '&::after': {
      content: '" "',
      position: 'absolute',
      right: '15px',
      top: '-10px',
      borderTop: 'none',
      borderRight: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderBottom: '15px solid #fff',
    },
            '& li': {
                padding:"7px 10px",
                borderBottom:"1px solid #ededed",
                textAlign:"left",
                color:theme.palette.text.black,
                '& a': {
                    textDecoration:"none",
                    color:theme.palette.text.black,
                }
            }
        }
       },
}));