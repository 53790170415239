import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleTrimScreen,
  createVehicleTrim,
  editVehicleTrim,
  fetchVehicleTrimById,
  uploadImage,
} from "../../../../redux/actions/vehicles/vehicleTrim";
import {
  fetchVehicleModelDD
} from "../../../../redux/actions/vehicles/vehicleModel";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleTrimComponent from "../../../../component/Vehicles/VehicleTrim/CreateVehicleTrim";
import { useParams } from "react-router-dom";

const CreateVehicleTrimContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleTrim,
    editVehicleTrim,
    isLoading,
    isVehicleTrimCreateSuccess,
    isVehicleTrimCreateError,
    isVehicleTrimDetailSuccess,
    isVehicleTrimDetailError,
    isVehicleTrimEditSuccess,
    isVehicleTrimEditError,
    isEdit,
    isView,
    initCreateVehicleTrimScreen,
    formFieldValueMap,
    fetchVehicleTrimById,
    fetchVehicleModelDD,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleTrimScreen();
    await fetchVehicleModelDD();
   if (isEdit) {
    await fetchVehicleTrimById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleTrimProps = {
    createVehicleTrim,
    initCreateVehicleTrimScreen,
    editVehicleTrim,
    isLoading,
    isEdit,
    isVehicleTrimDetailSuccess,
    isVehicleTrimCreateSuccess,
    isVehicleTrimEditSuccess,
    isVehicleTrimEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isVehicleTrimCreateSuccess || isVehicleTrimEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleTrim Updated Successfully"
        : "VehicleTrim Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleTrimCreateError ||
      isVehicleTrimDetailError ||
      isVehicleTrimEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Trim";
      if (isVehicleTrimEditError) {
        toastTitle = "Error while updating Vehicle Trim";
      } else if (isVehicleTrimDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleTrimComponent {...createVehicleTrimProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleTrimReducer(state, "isLoading"),
   
    isVehicleTrimCreateSuccess: selectFromVehicleTrimReducer(
      state,
      "isVehicleTrimCreateSuccess"
    ),
    isVehicleTrimCreateError: selectFromVehicleTrimReducer(
      state,
      "isVehicleTrimCreateError"
    ),
    isVehicleTrimDetailSuccess: selectFromVehicleTrimReducer(
      state,
      "isVehicleTrimDetailSuccess"
    ),
    isVehicleTrimDetailError: selectFromVehicleTrimReducer(
      state,
      "isVehicleTrimDetailError"
    ),
    isVehicleTrimEditSuccess: selectFromVehicleTrimReducer(
      state,
      "isVehicleTrimEditSuccess"
    ),
    isVehicleTrimEditError: selectFromVehicleTrimReducer(
      state,
      "isVehicleTrimEditError"
    ),
    imageUploadResponse: selectFromVehicleTrimReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleTrimReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleTrimReducer(
      state,
      "isUploadImageError"
    ),

    vehicleModelDD: selectFromVehicleVehicleModelReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleTrimReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleTrimReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleTrimReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleTrimScreen: () => dispatch(initCreateVehicleTrimScreen()),
    createVehicleTrim: (payload) => dispatch(createVehicleTrim(payload)),
    editVehicleTrim: (payload) => dispatch(editVehicleTrim(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleTrimById: (payload) =>
      dispatch(fetchVehicleTrimById(payload)),
    fetchVehicleModelDD: (payload) =>
      dispatch(fetchVehicleModelDD(payload)),
  };
};

const selectFromVehicleTrimReducer = (state, path) => {
  return selectVehicleTrimReducer(state)[path];
};

const selectVehicleTrimReducer = ({ vehicleTrimReducer }) => {
  return vehicleTrimReducer;
};
//Model
const selectFromVehicleVehicleModelReducer = (state, path) => {
  return selectVehicleVehicleModelReducer(state)[path];
};
const selectVehicleVehicleModelReducer = ({ vehicleModelReducer }) => {
  return vehicleModelReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleTrimContainer);
