import { Container } from '@mui/material';
import Footer from './common/Footer';
import Header from './common/Header';
import Router from './router';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
  body: {
    background:theme.palette.primary.main
  }
}));
function App(props) {
  const classes = useStyles();
 
  let token = localStorage.getItem("token");
 const [isCookiesSet, setIsCookiesSet] = useState(token);
  const switchLogin =() =>{
    let tokens = localStorage.getItem("token");
      setIsCookiesSet(tokens)
  }
 
  if(isCookiesSet !== "" && isCookiesSet != null && isCookiesSet !== undefined){
    return (
   <div className={classes.body}>
    <Header />
    
   <Router switchHeader={switchLogin}/>
   <Footer />
   </div>
  );
  }
  else{
    return (
      <Router switchHeader={switchLogin}/>
    )
  }
}

export default App;
