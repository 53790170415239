import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminRateCodeServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATECODE_INIT, 
  FETCH_RATEADMIN_RATECODE_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_RATECODE_SCREEN,
  CREATE_RATEADMIN_RATECODE,
  EDIT_RATEADMIN_RATECODE,
  TOAST,
  FETCH_RATEADMIN_RATECODE_DETAIL,
  EXPORT_RATEADMIN_RATECODE,
  RATEADMIN_RATECODEDD
} from "../../../types/rateadminratecode.type";
export const initializeRateAdminRateCodeScreen = () => ({ type: FETCH_RATEADMIN_RATECODE_INIT });

export const rateadminRateCodeFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATECODE_LIST.START,
  payload,
});

export const rateadminRateCodeFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_RATECODE_LIST.SUCCESS,
  payload,
});

export const rateadminRateCodeFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATECODE_LIST.ERROR,
  payload,
});

export const fetchRateAdminRateCode = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateCodeFetchStart(payload));
    rateAdminRateCodeServices.fetchRateAdminRateCode(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateCodeFetchSuccess(data));
      } else {
        dispatch(rateadminRateCodeFetchError(data));
      }
    });
  };
};



// DD
export const rateadminRateCodeDDFetchStart = (payload) => ({
  type: RATEADMIN_RATECODEDD.START,
  payload,
});

export const rateadminRateCodeDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATECODEDD.SUCCESS,
  payload,
});

export const rateadminRateCodeDDFetchError = (payload) => ({
  type: RATEADMIN_RATECODEDD.ERROR,
  payload,
});

export const fetchRateAdminRateCodeDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateCodeDDFetchStart(payload));
    rateAdminRateCodeServices.fetchRateAdminRateCodeDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateCodeDDFetchSuccess(data));
      } else {
        dispatch(rateadminRateCodeDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminRateCodeDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATECODE_DETAIL.START,
  payload,
});

export const rateadminRateCodeDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATECODE_DETAIL.SUCCESS,
  payload,
});

export const rateadminRateCodeDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATECODE_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminRateCodeById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateCodeDetailFetchStart(payload));

    rateAdminRateCodeServices
      .fetchRateAdminRateCodeById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateCodeDetailFetchSuccess(data));
        } else {
          dispatch(rateadminRateCodeDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminRateCodeServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminRateCodeScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_RATECODE_SCREEN,
  payload,
});

export const initCreateRateAdminRateCodeScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateCodeScreenInitStart());
  };
};


export const createRateAdminRateCodeStart = () => ({
  type: CREATE_RATEADMIN_RATECODE.START,
});

export const createRateAdminRateCodeSuccess = (payload) => ({
  type: CREATE_RATEADMIN_RATECODE.SUCCESS,
  payload,
});

export const createRateAdminRateCodeError = (payload) => ({
  type: CREATE_RATEADMIN_RATECODE.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminRateCode = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateCodeStart());
    rateAdminRateCodeServices.createRateAdminRateCode(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminRateCodeSuccess());
        goBack();
      } else {
        dispatch(createRateAdminRateCodeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminRateCodeStart = (payload) => ({
  type: EDIT_RATEADMIN_RATECODE.START,
  payload
});

export const editRateAdminRateCodeSuccess = (payload) => ({
  type: EDIT_RATEADMIN_RATECODE.SUCCESS,
  payload,
});

export const editRateAdminRateCodeError = (payload) => ({
  type: EDIT_RATEADMIN_RATECODE.ERROR,
  payload,
});

export const editRateAdminRateCode = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminRateCodeStart());
    rateAdminRateCodeServices.editRateAdminRateCode(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminRateCodeSuccess());
        goBack();
      } else {
        dispatch(editRateAdminRateCodeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminRateCodeRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_RATECODE.START,
  payload
});

export const exportRateAdminRateCodeRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_RATECODE.SUCCESS,
  payload,
});

export const exportRateAdminRateCodeRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_RATECODE.ERROR,
  payload,
});

export const exportRateAdminRateCode = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminRateCodeRecordStart());
    rateAdminRateCodeServices.exportRateAdminRateCodeRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminRateCodeRecordSuccess(data));
      } else {
        dispatch(exportRateAdminRateCodeRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

