export const basicDetail =
[
  {
    name:"title",
     "rule": {
        "required": true
      },
    label:"Trim",
    type:"text"
  },
  {
    name:"modelId",
     "rule": {
        "required": true
      },
    label:"Model",
    type:"dropdown"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  },
  {
    name:"description",
     "rule": {
        "required": true
      },
    label:"Description",
    type:"text"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]


  export const vehicleBreadcrumbD = [
    { path: "/dashboard", label: "Dashboard" },
            {label:"Vehicle Category", path:"/vehicle-category"},
            {label:"Make", path:"/vehicle-make"},
            {label:"Model", path:"/vehicle-model"},
            {label:"Trim", path:"/vehicle-trim"},
            {label:"Transmission Type", path:"/vehicle-transmission-type"},
            {label:"AC Type", path:"/vehicle-ac-type"},
            {label:"Stereo Type", path:"/vehicle-stereo-type"},
            {label:"Vehicle Status", path:"/vehicle-status"},
            {label:"Vehicle Operations", path:"/vehicle-operations"},
            {label:"Dealerships", path:"/vehicle-dealerships"},
            {label:"Body/Repair Shops", path:"/vehicle-body-shop"},
            {label:"Dealership Vehicle Deals", path:"/vehicle-dealership-vehicle-deal"},
            {label:"", path:"/vehicle-dealership-vehicle-deal"},

  ]