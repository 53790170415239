import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRentersRevenueAgreements,
    initializeRentersRevenueAgreementsScreen,
    setDeleteConfirmationPopupVisibility,
    exportRentersRevenueAgreements
  } from "../../../redux/actions/Renters/RentersRevenueAgreements";
import RentersRevenueAgreements from '../../../component/Renters/RentersRevenueAgreements';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RentersRevenueAgreementsScreen(props) {
    const {
        fetchRentersRevenueAgreements,
        initRentersRevenueAgreementsScreen,
        showPageLevelLoader,
        RentersRevenueAgreementsListview,
        paginationInfo,
        exportRentersRevenueAgreements,
        isRentersRevenueAgreementsExportError,
        isRentersRevenueAgreementsExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRentersRevenueAgreementsScreen();
        fetchRentersRevenueAgreements({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRentersRevenueAgreements(newProps);
      };
      const handleCreateRentersRevenueAgreements =() =>{
      navigate(APP_URLS.RENTERS_REVENUEAGREEMENTS.CREATE);
    }
      const editRentersRevenueAgreementsRoute = (id) => {
        const path = generatePath(APP_URLS.RENTERS_REVENUEAGREEMENTS.EDIT, {
          id,
        });
        navigate(path);
      };
const RentersRevenueAgreementsProps = {
  handleCreateRentersRevenueAgreements,
  exportRentersRevenueAgreements,
        isRentersRevenueAgreementsExportError,
        isRentersRevenueAgreementsExportSuccess,
  editRentersRevenueAgreementsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRentersRevenueAgreementss: loadLazyData,
  ...companyData,
  tableData: {
    columns: RentersRevenueAgreementsListview?.columns,
    rows: RentersRevenueAgreementsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RentersRevenueAgreements
    {...RentersRevenueAgreementsProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRentersRevenueAgreementsScreen: () => dispatch(initializeRentersRevenueAgreementsScreen()),
      fetchRentersRevenueAgreements: (payload) => dispatch(fetchRentersRevenueAgreements(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRentersRevenueAgreements: (payload) => dispatch(exportRentersRevenueAgreements(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRentersRevenueAgreementsCreateSuccess: selectFromRentersRevenueAgreementsReducer(
        state,
        "isRentersRevenueAgreementsCreateSuccess"
      ),
      RentersRevenueAgreementsListview: selectFromRentersRevenueAgreementsReducer(
        state,
        "RentersRevenueAgreementsListview"
      ),
      showPageLevelLoader: selectFromRentersRevenueAgreementsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRentersRevenueAgreementsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRentersRevenueAgreementsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRentersRevenueAgreementsReducer(
        state,
        "isFetchingGridData"
      ),
      isRentersRevenueAgreementsExportError: selectFromRentersRevenueAgreementsReducer(
        state,
        "isRentersRevenueAgreementsExportError"
      ),
      isRentersRevenueAgreementsExportSuccess: selectFromRentersRevenueAgreementsReducer(
        state,
        "isRentersRevenueAgreementsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRentersRevenueAgreementsReducer = (state, path) => {
  return selectRentersRevenueAgreementsReducer(state)[path];
};

const selectRentersRevenueAgreementsReducer = ({ RentersRevenueAgreementsReducer }) => {
  return RentersRevenueAgreementsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RentersRevenueAgreementsScreen);
