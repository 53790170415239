export const basicDetail =
[
  {
    name:"title",
     "rule": {
        "required": true
      },
    label:"Rate Type",
    type:"text"
  },
  {
    name:"description",
     "rule": {
        "required": true
      },
    label:"Description",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

  export const RateAdminBreadcrumb = [
   
            {label:"Dashboard", path:"/dashboard"},
            {label:"Rate Code", path:"/rate-code"},
            {label:"Rate Plans", path:"/rate-plans"},
            {label:"Rate Types", path:"/rate-types"},
            {label:"Lor Adjustments", path:"/lor-adjustments"},
            {label:"Rates", path:"/rates"},
            {label:"Extra Product Category", path:"/extra-product-category"},
            {label:"Extra Product Charge", path:"/extra-product-charge"},
            {label:"Networks", path:"/networks"},
            {label:"Blackouts", path:"/blackouts"},
            {label:"Rate Mirror", path:"/rate-mirror"},
            {label:"", path:"/rate-mirror"}

  ]