import { ProgressBar } from 'primereact/progressbar';

const EzProgressBar = props =>  {
  const { height, width } = props;
  return <ProgressBar mode="indeterminate"  style={{height, width}} />
}

EzProgressBar.defaultProps = {
  height: '6px',
  width: '50%'
}

export default EzProgressBar;