import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminExtraProductChargeScreen,
  createRateAdminExtraProductCharge,
  editRateAdminExtraProductCharge,
  fetchRateAdminExtraProductChargeById,
  uploadImage,
  fetchRateAdminExtraProductChargeDD,
} from "../../../../redux/actions/RateAdmin/rateadminExtraProductCharge";
import {
  fetchRateAdminExtraProductCategoryDD
} from "../../../../redux/actions/RateAdmin/rateadminExtraProductCategory";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";

import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminExtraProductChargeComponent from "../../../../component/RateAdmin/RateAdminExtraProductCharge/CreateRateAdminExtraProductCharge";
import { useParams } from "react-router-dom";

const CreateRateAdminExtraProductChargeContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminExtraProductCharge,

    editRateAdminExtraProductCharge,
    isLoading,
    isRateAdminExtraProductChargeCreateSuccess,
    isRateAdminExtraProductChargeCreateError,
    isRateAdminRateAdjSuccess,
    isRateAdminExtraProductChargeDetailSuccess,
    isRateAdminExtraProductChargeDetailError,

    isRateAdminExtraProductChargeEditSuccess,
    isRateAdminExtraProductChargeEditError,
    isEdit,
    isCopy,
    ExtraProductCategoryDD,
    isView,
    initCreateRateAdminExtraProductChargeScreen,
    formFieldValueMap,
    fetchRateAdminExtraProductChargeById,
    uploadImage,
    fetchRateAdminExtraProductCategoryDD,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        fetchVehicleCategoryDD,
    vehicleCategoryDD,

  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminExtraProductChargeScreen();
    await fetchVehicleCategoryDD();
    await fetchRateAdminExtraProductCategoryDD();
   if (isEdit || isCopy) {
    await fetchRateAdminExtraProductChargeById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminExtraProductChargeProps = {
    createRateAdminExtraProductCharge,
    initCreateRateAdminExtraProductChargeScreen,
    editRateAdminExtraProductCharge,
    isLoading,
    isEdit,
    isRateAdminExtraProductChargeDetailSuccess,
    isRateAdminExtraProductChargeCreateSuccess,
    isRateAdminExtraProductChargeEditSuccess,
    isRateAdminExtraProductChargeEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    ExtraProductCategoryDD,
    vehicleCategoryDD,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
  };
  const getToastProps = () => {
    if (isRateAdminExtraProductChargeCreateSuccess || isRateAdminExtraProductChargeEditSuccess) {
      const toastTitle = isEdit
        ? "Extra Product Charge Updated Successfully"
        : "Extra Product Charge Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (isRateAdminRateAdjSuccess) {
      const toastTitle = isEdit
        ? "Extra Product Charge Adjustment Saved Successfully"
        : "";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (
      isRateAdminExtraProductChargeCreateError ||
      isRateAdminExtraProductChargeDetailError ||
      isRateAdminExtraProductChargeEditError 
    ) {
      let toastTitle = "Error while Creating Extra Product Charge";
      if (isRateAdminExtraProductChargeEditError) {
        toastTitle = "Error while updating  Extra Product Charge";
      } else if (isRateAdminExtraProductChargeDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminExtraProductChargeComponent {...createRateAdminExtraProductChargeProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: selectFromRateAdminExtraProductChargeReducer(state, "isLoading"),
    isRateAdminExtraProductChargeCreateSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeCreateSuccess"
    ),
    isRateAdminExtraProductChargeCreateError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeCreateError"
    ),
    isRateAdminExtraProductChargeDetailSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeDetailSuccess"
    ),
    isRateAdminExtraProductChargeDetailError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeDetailError"
    ),
    isRateAdminExtraProductChargeEditSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeEditSuccess"
    ),
    isRateAdminExtraProductChargeEditError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isRateAdminExtraProductChargeEditError"
    ),
    imageUploadResponse: selectFromRateAdminExtraProductChargeReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminExtraProductChargeReducer(
      state,
      "isUploadImageError"
    ),
   
    ExtraProductCategoryDD: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "ExtraProductCategoryDD"
    ),
 
    
    isExtraProductCategoryDDDDSuccess: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isExtraProductCategoryDDDDSuccess"
    ),
    isExtraProductCategoryDDDDError: selectFromRateAdminExtraProductCategoryReducer(
      state,
      "isExtraProductCategoryDDDDError"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
 
    formFieldValueMap: selectFromRateAdminExtraProductChargeReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminExtraProductChargeScreen: () => dispatch(initCreateRateAdminExtraProductChargeScreen()),
    createRateAdminExtraProductCharge: (payload) => dispatch(createRateAdminExtraProductCharge(payload)),
    
    editRateAdminExtraProductCharge: (payload) => dispatch(editRateAdminExtraProductCharge(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchRateAdminExtraProductCategoryDD: (payload) => dispatch(fetchRateAdminExtraProductCategoryDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),

    fetchRateAdminExtraProductChargeDD: (payload) => dispatch(fetchRateAdminExtraProductChargeDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminExtraProductChargeById: (payload) => dispatch(fetchRateAdminExtraProductChargeById(payload)),
    
  };
};

const selectFromRateAdminExtraProductChargeReducer = (state, path) => {
  return selectRateAdminExtraProductChargeReducer(state)[path];
};

const selectRateAdminExtraProductChargeReducer = ({ rateadminExtraProductChargeReducer }) => {
  return rateadminExtraProductChargeReducer;
};
//Extra Product Category
const selectFromRateAdminExtraProductCategoryReducer = (state, path) => {
  return selectRateAdminExtraProductCategoryReducer(state)[path];
};

const selectRateAdminExtraProductCategoryReducer = ({ rateadminExtraProductCategoryReducer }) => {
  return rateadminExtraProductCategoryReducer;
};

//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminExtraProductChargeContainer);
