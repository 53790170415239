import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchReservationsDetailReport,
    initializeReservationsDetailReportScreen,
    setDeleteConfirmationPopupVisibility,
    exportReservationsDetailReport
  } from "../../../redux/actions/Reservations/ReservationsDetailReport";
  import {
    fetchLocationDD
  } from "../../../redux/actions/location";
  import {
    fetchRateAdminNetworkDD,
    } from "../../../redux/actions/RateAdmin/rateadminRates";
import ReservationsDetailReport from '../../../component/Reservations/ReservationsDetailReport';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function ReservationsDetailReportScreen(props) {
    const {
        fetchReservationsDetailReport,
        initReservationsDetailReportScreen,
        showPageLevelLoader,
        ReservationsDetailReportListview,
        paginationInfo,
        exportReservationsDetailReport,
        isReservationsDetailReportExportError,
        isReservationsDetailReportExportSuccess,
        isLoading,
        fetchLocationDD,
        fetchRateAdminNetworkDD,
        RateNetworkDD,
        locationDD,
      } = props;
const navigate = useNavigate ();

const callAPIasync = async () => {
  await fetchRateAdminNetworkDD();
  await fetchLocationDD();
 
 
}


useEffect(() => {
  callAPIasync()
}, []);
      useEffect(() => {
        initReservationsDetailReportScreen();
        fetchReservationsDetailReport({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchReservationsDetailReport(newProps);
      };
      const handleCreateReservationsDetailReport =() =>{
      navigate(APP_URLS.RESERVATIONS_DETAILREPORT.CREATE);
    }
      const editReservationsDetailReportRoute = (id) => {
        const path = generatePath(APP_URLS.RESERVATIONS_DETAILREPORT.EDIT, {
          id,
        });
        navigate(path);
      };
const ReservationsDetailReportProps = {
  handleCreateReservationsDetailReport,
  exportReservationsDetailReport,
        isReservationsDetailReportExportError,
        isReservationsDetailReportExportSuccess,
  editReservationsDetailReportRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchReservationsDetailReports: loadLazyData,
  ...companyData,
  tableData: {
    columns: ReservationsDetailReportListview?.columns,
    rows: ReservationsDetailReportListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
  RateNetworkDD,
        locationDD,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <ReservationsDetailReport
    {...ReservationsDetailReportProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initReservationsDetailReportScreen: () => dispatch(initializeReservationsDetailReportScreen()),
      fetchReservationsDetailReport: (payload) => dispatch(fetchReservationsDetailReport(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      fetchRateAdminNetworkDD: (payload) => dispatch(fetchRateAdminNetworkDD(payload)),
      fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
      exportReservationsDetailReport: (payload) => dispatch(exportReservationsDetailReport(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isReservationsDetailReportCreateSuccess: selectFromReservationsDetailReportReducer(
        state,
        "isReservationsDetailReportCreateSuccess"
      ),
      ReservationsDetailReportListview: selectFromReservationsDetailReportReducer(
        state,
        "ReservationsDetailReportListview"
      ),
      showPageLevelLoader: selectFromReservationsDetailReportReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromReservationsDetailReportReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromReservationsDetailReportReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromReservationsDetailReportReducer(
        state,
        "isFetchingGridData"
      ),
      RateNetworkDD: selectFromRateAdminRatesReducer(
        state,
        "RateNetworkDD"
      ),
      locationDD: selectFromLocationReducer(
        state,
        "locationDD"
      ),
      isReservationsDetailReportExportError: selectFromReservationsDetailReportReducer(
        state,
        "isReservationsDetailReportExportError"
      ),
      isReservationsDetailReportExportSuccess: selectFromReservationsDetailReportReducer(
        state,
        "isReservationsDetailReportExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromReservationsDetailReportReducer = (state, path) => {
  return selectReservationsDetailReportReducer(state)[path];
};

const selectReservationsDetailReportReducer = ({ ReservationsDetailReportReducer }) => {
  return ReservationsDetailReportReducer;
};


// For Network
const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(mapStateToProps, mapDispatchToProps)(ReservationsDetailReportScreen);
