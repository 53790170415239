import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminRatesScreen,
  createRateAdminRates,
  editRateAdminRates,
  fetchRateAdminRatesById,
  fetchRateAdminRatesRateAdjDetails,
  uploadImage,
  fetchRateAdminRatesDD,
  fetchRateAdminNetworkDD,
  fetchRateAdminRateOptionsDD,
  createRateAdminRateAdjustment,
  TestRateAdminRates
} from "../../../../redux/actions/RateAdmin/rateadminRates";
import {
  fetchRateAdminRateCodeDD
} from "../../../../redux/actions/RateAdmin/rateadminRateCode";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminRatesComponent from "../../../../component/RateAdmin/RateAdminRates/CreateRateAdminRates";
import { useParams } from "react-router-dom";

const CreateRateAdminRatesContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminRates,
    createRateAdminRateAdjustment,
    editRateAdminRates,
    isLoading,
    isRateAdminRatesCreateSuccess,
    isRateAdminRatesCreateError,
    isRateAdminRateAdjSuccess,
    isRateAdminRateAdjError,
    isRateAdminRatesDetailSuccess,
    isRateAdminRatesDetailError,
    isRateAdminRateAdjListError,
isRateAdminRateAdjListSuccess,
    isRateAdminRatesEditSuccess,
    isRateAdminRatesEditError,
    isEdit,
    isCopy,
    RateCodeDD,
    rateAdjustmentlist,
    isView,
    initCreateRateAdminRatesScreen,
    formFieldValueMap,
    formFieldValueMap2,
    fetchRateAdminRatesById,
    fetchRateAdminRatesRateAdjDetails,
    uploadImage,
    fetchRateAdminRateCodeDD,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        fetchRateAdminNetworkDD,
        fetchRateAdminRateOptionsDD,
        fetchVehicleCategoryDD,
        fetchRateAdminRatesDD,
    fetchLocationDD,
    locationDD,
    vehicleCategoryDD,
    RateOptionsDD,
    RateNetworkDD,
    RateTest,
    TestRateAdminRates
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminRatesScreen();
    await fetchVehicleCategoryDD();
    await fetchRateAdminNetworkDD();
    await fetchRateAdminRateOptionsDD();
    await fetchRateAdminRatesDD();
    await fetchRateAdminRateCodeDD();
    await fetchLocationDD();
   if (isEdit || isCopy) {
    await fetchRateAdminRatesById({id:id});
    await fetchRateAdminRatesRateAdjDetails({id:id})
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminRatesProps = {
    createRateAdminRates,
    createRateAdminRateAdjustment,
    initCreateRateAdminRatesScreen,
    editRateAdminRates,
    isLoading,
    isEdit,
    isRateAdminRateAdjListError,
isRateAdminRateAdjListSuccess,
    isRateAdminRatesDetailSuccess,
    isRateAdminRatesCreateSuccess,
    isRateAdminRateAdjSuccess,
    isRateAdminRateAdjError,
    isRateAdminRatesEditSuccess,
    isRateAdminRatesEditError,
    formFieldValueMap,
    formFieldValueMap2,
    shouldBlockNavigation,
    uploadImage,
    RateCodeDD,
    locationDD,
    rateAdjustmentlist,
    RateOptionsDD,
    RateNetworkDD,
    vehicleCategoryDD,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        RateTest,
        TestRateAdminRates
  };
  const getToastProps = () => {
    if (isRateAdminRatesCreateSuccess || isRateAdminRatesEditSuccess) {
      const toastTitle = isEdit
        ? "Rates Updated Successfully"
        : "Rates Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (isRateAdminRateAdjSuccess) {
      const toastTitle = isEdit
        ? "Rates Adjustment Saved Successfully"
        : "";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (
      isRateAdminRatesCreateError ||
      isRateAdminRatesDetailError ||
      isRateAdminRatesEditError ||
      isRateAdminRateAdjError
    ) {
      let toastTitle = "Error while Creating Rates";
      if (isRateAdminRatesEditError) {
        toastTitle = "Error while updating  Rates";
      } else if (isRateAdminRatesDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminRatesComponent {...createRateAdminRatesProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminRatesReducer(state, "isLoading"),
    isRateAdminRateAdjSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRateAdjSuccess"
    ),
    RateTest: selectFromRateAdminRatesReducer(
      state,
      "RateTest"
    ),
    
    isRateAdminRateAdjError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRateAdjError"
    ),
    isRateAdminRatesCreateSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesCreateSuccess"
    ),
    isRateAdminRatesCreateError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesCreateError"
    ),
    isRateAdminRatesDetailSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesDetailSuccess"
    ),
    isRateAdminRatesDetailError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesDetailError"
    ),
    rateAdjustmentlist: selectFromRateAdminRatesReducer(
      state,
      "rateAdjustmentlist"
    ),
    isRateAdminRateAdjListSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRateAdjListSuccess"
    ),
    isRateAdminRateAdjListError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRateAdjListError"
    ),

    isRateAdminRatesEditSuccess: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesEditSuccess"
    ),
    isRateAdminRatesEditError: selectFromRateAdminRatesReducer(
      state,
      "isRateAdminRatesEditError"
    ),
    imageUploadResponse: selectFromRateAdminRatesReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminRatesReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminRatesReducer(
      state,
      "isUploadImageError"
    ),
    RateOptionsDD: selectFromRateAdminRatesReducer(
      state,
      "RateOptionsDD"
    ),
    RateNetworkDD: selectFromRateAdminRatesReducer(
      state,
      "RateNetworkDD"
    ),

    vehicleModelDD: selectFromRateAdminRatesReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminRatesReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminRatesReducer(
      state,
      "isVehicleModelDDError"
    ),
    RateCodeDD: selectFromRateAdminRateCodeReducer(
      state,
      "RateCodeDD"
    ),
 
    
    isRateCodeDDDDSuccess: selectFromRateAdminRateCodeReducer(
      state,
      "isRateCodeDDDDSuccess"
    ),
    isRateCodeDDDDError: selectFromRateAdminRateCodeReducer(
      state,
      "isRateCodeDDDDError"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    
    formFieldValueMap2: selectFromRateAdminRatesReducer(state, "formFieldValueMap2"),
    formFieldValueMap: selectFromRateAdminRatesReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminRatesScreen: () => dispatch(initCreateRateAdminRatesScreen()),
    createRateAdminRates: (payload) => dispatch(createRateAdminRates(payload)),
    createRateAdminRateAdjustment: (payload) => dispatch(createRateAdminRateAdjustment(payload)),
    
    editRateAdminRates: (payload) => dispatch(editRateAdminRates(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchRateAdminRateCodeDD: (payload) => dispatch(fetchRateAdminRateCodeDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),

    TestRateAdminRates: (payload) => dispatch(TestRateAdminRates(payload)),

    fetchRateAdminRatesDD: (payload) => dispatch(fetchRateAdminRatesDD(payload)),
    fetchRateAdminRateOptionsDD: (payload) => dispatch(fetchRateAdminRateOptionsDD(payload)),
    fetchRateAdminNetworkDD: (payload) => dispatch(fetchRateAdminNetworkDD(payload)),

    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminRatesById: (payload) => dispatch(fetchRateAdminRatesById(payload)),
    fetchRateAdminRatesRateAdjDetails: (payload) => dispatch(fetchRateAdminRatesRateAdjDetails(payload)),
    
  };
};

const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Rate Plan 
const selectFromRateAdminRateCodeReducer = (state, path) => {
  return selectRateAdminRateCodeReducer(state)[path];
};

const selectRateAdminRateCodeReducer = ({ rateadminRateCodeReducer }) => {
  return rateadminRateCodeReducer;
};

//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminRatesContainer);
