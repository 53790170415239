

export const FETCH_RENTERS_REVENUEAGREEMENTS_LIST = {
    START: 'FETCH_RENTERS_REVENUEAGREEMENTS_LIST_START',
    SUCCESS: 'FETCH_RENTERS_REVENUEAGREEMENTS_LIST_SUCCESS',
    ERROR: 'FETCH_RENTERS_REVENUEAGREEMENTS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RENTERS_REVENUEAGREEMENTS_INIT="FETCH_RENTERS_REVENUEAGREEMENTS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RENTERS_REVENUEAGREEMENTS_SCREEN =
'INIT_CREATE_RENTERS_REVENUEAGREEMENTS_SCREEN';

export const CREATE_RENTERS_REVENUEAGREEMENTS = {
START: 'CREATE_RENTERS_REVENUEAGREEMENTS_START',
SUCCESS: 'CREATE_RENTERS_REVENUEAGREEMENTS_SUCCESS',
ERROR: 'CREATE_RENTERS_REVENUEAGREEMENTS_ERROR',
};

export const EDIT_RENTERS_REVENUEAGREEMENTS = {
START: 'EDIT_RENTERS_REVENUEAGREEMENTS_START',
SUCCESS: 'EDIT_RENTERS_REVENUEAGREEMENTS_SUCCESS',
ERROR: 'EDIT_RENTERS_REVENUEAGREEMENTS_ERROR',
};

export const FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL = {
  START: 'FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL_START',
  SUCCESS: 'FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RENTERS_REVENUEAGREEMENTS_DETAIL_ERROR',
};
export const EXPORT_RENTERS_REVENUEAGREEMENTS = {
  START: 'EXPORT_RENTERS_REVENUEAGREEMENTS_START',
  SUCCESS: 'EXPORT_RENTERS_REVENUEAGREEMENTS_SUCCESS',
  ERROR: 'EXPORT_RENTERS_REVENUEAGREEMENTS_ERROR',
};
export const RENTERS_REVENUEAGREEMENTSDD = {
  START: 'RENTERS_REVENUEAGREEMENTSDD_START',
  SUCCESS: 'RENTERS_REVENUEAGREEMENTSDD_SUCCESS',
  ERROR: 'RENTERS_REVENUEAGREEMENTSDD_ERROR',
};
export const RENTERS_REVENUEAGREEMENTSDELETE = {
  START: 'RENTERS_REVENUEAGREEMENTSDELETE_START',
  SUCCESS: 'RENTERS_REVENUEAGREEMENTSDELETE_SUCCESS',
  ERROR: 'RENTERS_REVENUEAGREEMENTSDELETE_ERROR',
};
export const RENTERS_REVENUEAGREEMENTSEXISTINGDATA = {
  START: 'RENTERS_REVENUEAGREEMENTSEXISTINGDATA_START',
  SUCCESS: 'RENTERS_REVENUEAGREEMENTSEXISTINGDATA_SUCCESS',
  ERROR: 'RENTERS_REVENUEAGREEMENTSEXISTINGDATA_ERROR',
};
