import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { AppliesDD, basicDetail, editMode, statusDD, } from "../CreateRateAdminNetworks/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import { RateAdminBreadcrumb } from "../../RateAdminRateCode/CreateRateAdminRateCode/constant";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledMultiSelecttWithFloatingLabel from "../../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";

const CreateRateAdminNetworksComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRateAdminNetworksScreen,
    createRateAdminNetworks,
    editRateAdminNetworks,
    isLoading,
    isRateAdminNetworksCreateSuccess,
    isRateAdminNetworksCreateError,
    isRateAdminNetworksDetailSuccess,
    isRateAdminNetworksDetailError,
    isRateAdminNetworksEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
    locationDD,
    vehicleCategoryDD,
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    resetField,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });


  const navigateToRateAdminNetworksScreen = () => {
    window.location.replace("/#" + APP_URLS.RATEADMIN_NETWORK.HOME)
  };
  useEffect(() => {

    if (isRateAdminNetworksCreateSuccess || isRateAdminNetworksEditSuccess) {
      reset();
    }
  }, [isRateAdminNetworksCreateSuccess]);
  useEffect(() => {
    initCreateRateAdminNetworksScreen();
  }, []);
  const [typeInsurance_1, setTypeInsurance_1] = useState("");
  const [typeInsurance_2, setTypeInsurance_2] = useState("");

  const applicable = watch("applicable");

  const handleRadio = (e) => {
    const { name, value } = e.target;
    console.log(value, "BHALU", name)
    if (name === "typeInsurance_1") {
      setTypeInsurance_1(value);
      resetField("rateDayPercentage_2_1");
      resetField("rateDayPercentage_2_2");
      if (value == "1") {
        resetField("rateDayPercentage_1_2");
      }
      if (value == "2") {
        resetField("rateDayPercentage_1_1");
      }
    }
    else {
      setTypeInsurance_2(value)
      resetField("rateDayPercentage_1_1");
      resetField("rateDayPercentage_1_2");
      if (value == "1") {
        resetField("rateDayPercentage_2_2");
      }
      if (value == "2") {
        resetField("rateDayPercentage_2_1");
      }
    }

  }

  const [inclusiveRate, setInclusiveRate] = useState(false)
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setInclusiveRate(checked)
    if (!checked) {
      resetField("applicable");
      resetField("rateDayPercentage_2_1");
      resetField("rateDayPercentage_2_2");
      resetField("rateDayPercentage_1_1");
      resetField("rateDayPercentage_1_2");
      setTypeInsurance_1("");
      setTypeInsurance_2("")

    }
  }
  useEffect(() => {
    if (isRateAdminNetworksDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
      setInclusiveRate(formFieldValueMap?.inclusiveRate === 1 ? true : false);
    
    let nval2 = formFieldValueMap?.applicable=="2" ? formFieldValueMap?.typeInsurance :"";
    setTypeInsurance_2(nval2)
    let nval = formFieldValueMap?.applicable=="1" ? formFieldValueMap?.typeInsurance :"";
    setTypeInsurance_1(nval)
  }
  }, [isRateAdminNetworksDetailSuccess]);
  
  useEffect(() => {
    resetField("rateDayPercentage_2_1");
    resetField("rateDayPercentage_2_2");
    resetField("rateDayPercentage_1_1");
    resetField("rateDayPercentage_1_2");
    resetField("typeInsurance_1");
    resetField("typeInsurance_2");
  }, [applicable]);




  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    const stringifiedData = {
      userId: localStorage.getItem("userId"),
      applicable: data?.applicable,
      inclusiveRate: inclusiveRate === true ? 1 : 0,
      locationId: data?.locationId,
      status: data?.status,
      title: data?.title,
      rateDayPercentage: data?.rateDayPercentage_2_1 || data?.rateDayPercentage_2_2 || data?.rateDayPercentage_1_1 || data?.rateDayPercentage_1_2,
      typeInsurance: typeInsurance_1 || typeInsurance_2,
      url: data?.url,
      vehicleCategoryId: data?.vehicleCategoryId,
    }
    console.log("KURA", stringifiedData)

    isEdit
      ? editRateAdminNetworks({ id: data.id, data: stringifiedData })
      : createRateAdminNetworks(stringifiedData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Networks</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Networks"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Networks"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToRateAdminNetworksScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RateAdminBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              {basicDetail?.map((item) => (
                item.name === "status" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={statusDD}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :
                  item.name === "locationId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledMultiSelecttWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={locationDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :
                    item.name === "vehicleCategoryId" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledMultiSelecttWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={vehicleCategoryDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    ) :

                      item.name === "applicable" ? (
                        <Grid item xs={3} key={item.name}>
                          <EzControlledDropDowntWithFloatingLabel
                            name={item?.name}
                            control={control}
                            disabled={!inclusiveRate}
                            rules={item?.rule}
                            optionLabel={"name"}
                            optionValue={"id"}
                            options={AppliesDD}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                            label={item?.label}
                            labelClassName={"ml-2"}
                          />
                        </Grid>
                      ) :
                        item.name === "inclusiveRate" ? (
                          <Grid item xs={3} key={item.name}>
                            <EzControlledCheckBoxWithLabel
                              name={item.name}
                              value={inclusiveRate}
                              id={item.name}
                              control={control}
                              isError={errors[item.label]}
                              label={item.label}
                              labelClassName={'ml-2'}
                              onChange={handleCheckbox}
                            />
                          </Grid>
                        ) :
                          (
                            (<Grid item xs={3} key={item.name}>
                              <EzControlledInputWithFloatingLabel
                                name={item?.name}
                                control={control}
                                label={item?.label}
                                type={item?.type}
                                rules={item?.rule}
                                isError={errors[item?.name]}
                                errorMsg={getFormErrorMessage(item?.name)}
                              />
                            </Grid>))
              ))}

            </Grid>
            {applicable == "1" && (
              <Grid container>
                <Grid item alignItems={"center"} textAlign={"center"} paddingTop={"10px"} marginRight={"10px"}>
                  <input checked={typeInsurance_1 == "1"} type="radio" onChange={handleRadio} name="typeInsurance_1" value="1" />
                </Grid>
                <Grid item xs={3}>
                  <EzControlledInputWithFloatingLabel
                    name={'rateDayPercentage_1_1'}
                    control={control}
                    label="LDW ($1500 deductible)"
                    type="text"
                    disabled={typeInsurance_1 !== "1"}
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['rateDayPercentage_1_1']}
                    errorMsg={getFormErrorMessage('rateDayPercentage_1_1')}
                  />
                </Grid>
                <Grid alignItems={"center"} paddingTop={"10px"} marginLeft={"10px"}>
                  / Day
                </Grid>
                <Grid alignItems={"center"} textAlign={"center"} item xs={2} paddingTop={"10px"}>
                  OR
                </Grid>
                <Grid item alignItems={"center"} textAlign={"center"} paddingTop={"10px"} marginRight={"10px"}>
                  <input checked={typeInsurance_1 == "2"} type="radio" onChange={handleRadio} name="typeInsurance_1" value="2" />
                </Grid>

                <Grid item xs={3}>

                  <EzControlledInputWithFloatingLabel
                    name={'rateDayPercentage_1_2'}
                    control={control}
                    label="LDW ($2500 deductible))"
                    type="text"
                    disabled={typeInsurance_1 !== "2"}
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['rateDayPercentage_1_2']}
                    errorMsg={getFormErrorMessage('rateDayPercentage_1_2')}
                  />
                </Grid>
                <Grid alignItems={"center"} paddingTop={"10px"} marginLeft={"10px"}>
                  / Day
                </Grid>
              </Grid>)}


            {applicable == "2" && (
              <Grid container>
                <Grid item alignItems={"center"} textAlign={"center"} paddingTop={"10px"} marginRight={"10px"}>
                  <input checked={typeInsurance_2 == "1" } type="radio" onChange={handleRadio} name="typeInsurance_2" value="1" />
                </Grid>
                <Grid item xs={3}>
                  <EzControlledInputWithFloatingLabel
                    name={'rateDayPercentage_2_1'}
                    control={control}
                    label="LDW ($1500 deductible)"
                    type="text"
                    disabled={typeInsurance_2 !== "1"}
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['rateDayPercentage_2_1']}
                    errorMsg={getFormErrorMessage('rateDayPercentage_2_1')}
                  />
                </Grid>
                <Grid alignItems={"center"} paddingTop={"10px"} marginLeft={"10px"}>
                  % on total
                </Grid>
                <Grid alignItems={"center"} textAlign={"center"} item xs={2} paddingTop={"10px"}>
                  OR
                </Grid>
                <Grid item alignItems={"center"} textAlign={"center"} paddingTop={"10px"} marginRight={"10px"}>
                  <input checked={typeInsurance_2 == "2" } type="radio" onChange={handleRadio} name="typeInsurance_2" value="2" />
                </Grid>

                <Grid item xs={3}>

                  <EzControlledInputWithFloatingLabel
                    name={'rateDayPercentage_2_2'}
                    control={control}
                    label="LDW ($2500 deductible)"
                    type="text"

                    disabled={typeInsurance_2 !== "2"}
                    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    isError={errors['rateDayPercentage_2_2']}
                    errorMsg={getFormErrorMessage('rateDayPercentage_2_2')}
                  />
                </Grid>
                <Grid alignItems={"center"} paddingTop={"10px"} marginLeft={"10px"}>
                  % on total
                </Grid>
              </Grid>)}
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
            {/* {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
} */}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateRateAdminNetworksComponent;
