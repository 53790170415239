export const menuList={

taskAssignment:{
    name:"Task assignment", path:"#",
        submenu:[
            {name:"Task Management System", path:"task-management-system"},
            {name:"Calendar", path:"calendar"},
            {name:"Staff Dashboard", path:"staff-dashboard"},
            {name:"Recurring to do list", path:"recurring-to-do-list"}
        ],
    },
    admin:{
        name:"Admin", path:"#",
        submenu:[
            {name:"Corporate meetings", path:"corporate-meetings"},
            {name:"Leave applications", path:"leave-applications"}
        ]
    },
    financial:{
        name:"Financial", path:"#",
        submenu:[
            {name:"Time and billing", path:"time-and-billing"},
            {name:"Vendors", path:"vendors"},
            {name:"Invoicing", path:"invoicing"},
            {name:"Wholesale Bill of Sale", path:"wholesale-bill-of-sale"},
            {name:"A/R", path:"AR"},
            {name:"Misc uploads", path:"misc-uploads"},
            {name:"Rental Checklist", path:"rental-checklist"}
        ]
    },
    communication:{
        name:"Communication", path:"#",
        submenu:[
           
            {name:"Send notifications", path:"send-notifications"},
            {name:"Send bulk email", path:"send-bulk-email"},
            {name:"Send bulk sms", path:"send-bulk-sms"},
            
        ]
    },
  
location:{
        name:"Location", path:"location",
    },
    settings:{
        name:"Settings", path:"#",
        submenu:[
            {name:"Days of Week", path:"days-of-week"},
            {name:"Pay Now Discount", path:"pay-now-discount"},
            {name:"Pay Now Option", path:"pay-now-option"},
        ]
    },
    holidays:{
        name:"Holidays", path:"holidays",
    },
    reservations:{
        name:"Reservations", path:"reservations",
    },
    maintenance:{
        name:"Maintenance", path:"#",
        submenu:[
            {name:"Maintenance Category", path:"maintenance-category"},
            {name:"Maintenance Records", path:"maintenance-records"},
        ]
    },
    claims:{
        name:"Claims", path:"#",
        submenu:[
            {name:"Claim Cost Category", path:"claim-cost-category"},
            {name:"Claims", path:"claims"},
        ]
    },
    vehicles:{
        name:"Vehicles", path:"#",
        submenu:[
            {name:"Vehicle Category", path:"vehicle-category"},
            {name:"Make", path:"vehicle-make"},
            {name:"Model", path:"vehicle-model"},
            {name:"Trim", path:"vehicle-trim"},
            {name:"Transmission Type", path:"vehicle-transmission-type"},
            {name:"AC Type", path:"vehicle-ac-type"},
            {name:"Stereo Type", path:"vehicle-stereo-type"},
            {name:"Vehicle Status", path:"vehicle-status"},
            {name:"Vehicle Operations", path:"vehicle-operations"},
            {name:"Dealerships", path:"vehicle-dealerships"},
            {name:"Body/Repair Shops", path:"vehicle-body-repair-shops"},
            {name:"Dealership Vehicle Deals", path:"vehicle-dealership-vehicle-deal"},
        ]
    },
    incident:{
        name:"Incident", path:"#",
        submenu:[
            {name:"Fault Values", path:"fault-values"},
            {name:"Incident Report", path:"incident-report"},
        ]
    },
    rateadministration:{
        name:"Rate Administration", path:"#",
        submenu:[
            {name:"Rate Code", path:"rate-code"},
            {name:"Rate Plans", path:"rate-plans"},
            {name:"Rate Types", path:"rate-types"},
            {name:"Lor Adjustments", path:"lor-adjustments"},
            {name:"Rates", path:"rates"},
            {name:"Extra Product Category", path:"extra-product-category"},
            {name:"Extra Product Charge", path:"eExtra-product-charge"},
            {name:"Networks", path:"networks"},
            {name:"Blackouts", path:"blackouts"},
            {name:"Rate Mirror", path:"rate-mirror"}
        ]
    },
    Renters:{
        name:"renters", path:"#",
        submenu:[
            {name:"Registered Renters", path:"registered-renters"},
            {name:"Renters Info", path:"renters-info"},
            {name:"Revenue Agreements", path:"revenue-agreements"},
            {name:"Non Revenue Agreements", path:"non-revenue-agreements"},
            {name:"Vehicle Inquiries", path:"vehicle-inquiries"},
            {name:"Renter Log Interactions", path:"renter-log-interactions"}
        ]
    },
    Reports:{
        name:"Reports", path:"#",
        submenu:[
            {name:"Rate Quotes", path:"rate-quotes"},
            {name:"Reservations", path:"#",
            submenu:[
                {name:"Reservations", path:"reservations"},
                {name:"Reservations Detail Report", path:"reservations-detail-report"},
                {name:"Daily Manifest Report", path:"daily-manifest-report"},
                {name:"Bookings by Location Report ", path:"bookings-by-location-report"},
                {name:"Prepaid Bookings Report", path:"prepaid-bookings-report"},
                {name:"CRX/Rezp Reservations", path:"crx-rezp-reservations"},
            ]},
            {name:"Transactions", path:"transactions"},
            {name:"Bids", path:"bids"},
            {name:"Vehicles", path:"Vehicles"},
            {name:"Calendar", path:"#", 
                submenu:[
                {name:"Calendar", path:"calendar"},
                {name:"Calendar Pickup", path:"calendar-pickup"},
                {name:"Calendar Dropoff", path:"calendar-dropoff"}
            ]},
        ]
    },
}
