import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_BLACKOUTS_LIST, 
  TOAST, 
  FETCH_RATEADMIN_BLACKOUTS_INIT,
  FETCH_RATEADMIN_BLACKOUTS_DETAIL,
  EXPORT_RATEADMIN_BLACKOUTS,
  CREATE_RATEADMIN_BLACKOUTS,
  EDIT_RATEADMIN_BLACKOUTS,
  INIT_CREATE_RATEADMIN_BLACKOUTS_SCREEN,
  RATEADMIN_BLACKOUTSDD
} from "../../../types/rateadminblackouts.type";
 
  const formFieldValueMap = {
    id:null,
    blackoutMessage:"",
    status:"",
    userId:"",
    startTime:"",
    startDate:"",
    spanoverAllowed:"",
    rateCodId:[],
    pickupAllowed:"",
    networkId:[],
    locationId:[],
    ip:"",
    categoryId:[],
    dropoffAllowed:"",
    endDate:"",
    endTime:""
  };
  
  const INITIAL_STATE = {
    rateAdminBlackoutsListview: [],
    BlackoutsDD:[],
    isRateAdminBlackoutsDDSuccess: false,
    isRateAdminBlackoutsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRateAdminBlackoutsCreateSuccess: false,
    isRateAdminBlackoutsCreateError: false,
    isRateAdminBlackoutsDetailSuccess: false,
    isRateAdminBlackoutsDetailError: false,
    isRateAdminBlackoutsEditSuccess: false,
    isRateAdminBlackoutsEditError: false,
    isRateAdminBlackoutsExportSuccess: false,
    isRateAdminBlackoutsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
      blackoutMessage,
      categoryCodes,
      crtBy,
      crtTime,
      endDate,
      endTime,
      groupTitles,
      id,
      locationTitles,
      modBy,
      modTime,
      pickupAllowed,
      spanoverAllowed,
      startDate,
      startTime,
      status
      } = item;
  
      const transformedValues = {
        blackoutMessage,
        categoryCodes,
        crtBy,
        crtTime,
        endDate,
        endTime,
        groupTitles,
        id,
        locationTitles,
        modBy,
        modTime,
        pickupAllowed,
        spanoverAllowed,
        startDate,
        startTime,
        status
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location",
        dataKey: "locationTitles",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },{
        colLabel: "Category",
        dataKey: "categoryCodes",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "groupTitles",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Start Date",
        dataKey: "startDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Start Time",
        dataKey: "startTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "End Date",
        dataKey: "endDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "End Time",
        dataKey: "endTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Pickup Allowed",
        dataKey: "pickupAllowed",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Spanover Allowed",
        dataKey: "spanoverAllowed",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Blackout Message",
        dataKey: "blackoutMessage",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminBlackoutsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_BLACKOUTS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_BLACKOUTS_DETAIL.START:
      case EXPORT_RATEADMIN_BLACKOUTS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_BLACKOUTS_LIST.START:
      case CREATE_RATEADMIN_BLACKOUTS.START:
        case EDIT_RATEADMIN_BLACKOUTS.START:
          case RATEADMIN_BLACKOUTSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_BLACKOUTS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          rateAdminBlackoutsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminBlackoutsCreateSuccess: false,
          isRateAdminBlackoutsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          rateAdminBlackoutsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_BLACKOUTS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminBlackoutsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminBlackoutsDeletionSuccess: false,
          israteadminBlackoutsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_BLACKOUTS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];

              if(key==="endDate"){
                temp[key] = new Date(action.payload[key])
              }
              if(key==="startDate"){
                temp[key] = new Date(action.payload[key])
              }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRateAdminBlackoutsDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_BLACKOUTS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminBlackoutsDetailSuccess: false,
        isRateAdminBlackoutsDetailError: true,
      };
    }

    //DD
    case RATEADMIN_BLACKOUTSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        BlackoutsDD:temp,
        isRateAdminBlackoutsDDSuccess: true,
        isRateAdminBlackoutsDDError: false,
      };
    }
    case RATEADMIN_BLACKOUTSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminBlackoutsDDSuccess: false,
        isRateAdminBlackoutsDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RATEADMIN_BLACKOUTS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminBlackoutsExportSuccess: false,
    isRateAdminBlackoutsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_BLACKOUTS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"blackouts.xls");
   return {
    ...state,
    isRateAdminBlackoutsExportSuccess: true,
    isRateAdminBlackoutsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_BLACKOUTS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminBlackoutsEditSuccess: false,
    isRateAdminBlackoutsEditError: true,
  };
}

case EDIT_RATEADMIN_BLACKOUTS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminBlackoutsEditSuccess: true,
    isRateAdminBlackoutsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_BLACKOUTS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_BLACKOUTS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminBlackoutsCreateSuccess: false,
    isRateAdminBlackoutsCreateError: true,
  };
}
case CREATE_RATEADMIN_BLACKOUTS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminBlackoutsCreateSuccess: true,
    isRateAdminBlackoutsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminBlackoutsCreateSuccess: false,
          isRateAdminBlackoutsCreateError: false,
          isRateAdminBlackoutsEditError: false,
          isRateAdminBlackoutsEditSuccess: false,
          isRateAdminBlackoutsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  