import React from 'react';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';

import EzControlledMultiSelect from '../';

const EzControlledMultiSelecttWithFloatingLabel = (props) => {
  const {
    control,
    name,
    rules,
    options,
    optionLabel,
    optionValue,
    onChange,
    labelClassName,
    label,
    id,
    filter,
    errorMsg,
    isError,
    disabled
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className="p-float-label">
        <EzControlledMultiSelect
          disabled={disabled}
          filter={filter}
          name={name}
          id={id}
          optionLabel={optionLabel}
          optionValue={optionValue}
          onChange={onChange}
          control={control}
          rules={rules}
          options={options}
        />
        <label htmlFor={name} className={getLabelClassName()}>
          {rules?.required ? `${label}*` : label}
        </label>
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledMultiSelecttWithFloatingLabel.defaultProps = {
  labelClassName: '',
  filter: false,
  optionLabel: 'label',
  optionValue: 'value',
};

EzControlledMultiSelecttWithFloatingLabel.propTypes = {
  labelClassName: PropTypes.string,
  filter: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
};

export default EzControlledMultiSelecttWithFloatingLabel;
