import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { basicDetail, editMode, statusDD, typeDD } from "../CreateRentersLogInteractions/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { RentersRentersInfoBreadcrumb } from "../../RentersRentersInfo/CreateRentersRentersInfo/constant";

const CreateRentersLogInteractionsComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRentersLogInteractionsScreen,
    createRentersLogInteractions,
    editRentersLogInteractions,
    isLoading,
    isRentersLogInteractionsCreateSuccess,
    isRentersLogInteractionsCreateError,
    isRentersLogInteractionsDetailSuccess,
    isRentersLogInteractionsDetailError,
    isRentersLogInteractionsEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });




  const navigateToRentersLogInteractionsScreen = () => {
    window.location.replace("/#" + APP_URLS.RENTERS_RENTERSINFO.HOME)
  };
  useEffect(() => {

    if (isRentersLogInteractionsCreateSuccess || isRentersLogInteractionsEditSuccess) {
      reset();
    }
  }, [isRentersLogInteractionsCreateSuccess]);
  useEffect(() => {
    if (isRentersLogInteractionsDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isRentersLogInteractionsDetailSuccess]);
  useEffect(() => {
    initCreateRentersLogInteractionsScreen();
  }, []);

  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    const stringifiedData = {
      ...newData,
    }

    isEdit
      ? editRentersLogInteractions({ id: data.id, data: stringifiedData })
      : createRentersLogInteractions(stringifiedData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Renter Log Interaction</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Renter Log Interaction"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Renter Log Interaction"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToRentersLogInteractionsScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RentersRentersInfoBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              {basicDetail?.map((item) => (
                item.name === "status" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={statusDD}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :

                  item.type === "date" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledCalendarWithFloatingLabel
                        id={item.name}
                        name={item.name}
                        {...register(item.name)}
                        showTime={true}
                        maxDate={new Date(2090, 0, 1)}
                        dateFormat="yy/mm/dd"
                        control={control}
                        isError={errors[item.name]}
                        errorMsg={getFormErrorMessage(item.name)}
                        label={item.label}
                        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                      />
                    </Grid>
                  )
                    :
                    item.name === "clientInfo" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={typeDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    ):
                    item.name === "modeofCommunication" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={typeDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    ): item.name === "interactionType" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={typeDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    ): item.name === "followedUpBy" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={typeDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )
                      : (
                        (<Grid item xs={3} key={item.name}>
                          <EzControlledInputWithFloatingLabel
                            name={item?.name}
                            control={control}
                            label={item?.label}
                            type={item?.type}
                            rules={item?.rule}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                          />
                        </Grid>))
              ))}

            </Grid>

            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
            {isEdit && (<Grid className="modsection" container spacing={2}>
              {editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                  <EzControlledInputWithFloatingLabel
                    name={item?.name}
                    control={control}
                    disabled={item?.disabled}
                    label={item?.label}
                    type={item?.type}
                    rules={item?.rule}
                    isError={errors[item?.name]}
                    errorMsg={getFormErrorMessage(item?.name)}
                  />
                </Grid>
              )
              )
              }
            </Grid>)
            }
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateRentersLogInteractionsComponent;
