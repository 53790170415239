import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminBlackouts,
    initializeRateAdminBlackoutsScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminBlackouts
  } from "../../../redux/actions/RateAdmin/rateadminBlackouts";
import RateAdminBlackouts from '../../../component/RateAdmin/RateAdminBlackouts';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminBlackoutsScreen(props) {
    const {
        fetchRateAdminBlackouts,
        initRateAdminBlackoutsScreen,
        showPageLevelLoader,
        rateAdminBlackoutsListview,
        paginationInfo,
        exportRateAdminBlackouts,
        isRateAdminBlackoutsExportError,
        isRateAdminBlackoutsExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminBlackoutsScreen();
        fetchRateAdminBlackouts({ sortField: 'id', userId:localStorage.getItem("userId")  });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newPyload={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRateAdminBlackouts(newPyload);
      };
      const handleCreateRateAdminBlackouts =() =>{
      navigate(APP_URLS.RATEADMIN_BLACKOUTS.CREATE);
    }
      const editRateAdminBlackoutsRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_BLACKOUTS.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminBlackouts = {
  handleCreateRateAdminBlackouts,
  exportRateAdminBlackouts,
        isRateAdminBlackoutsExportError,
        isRateAdminBlackoutsExportSuccess,
  editRateAdminBlackoutsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminBlackoutss: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminBlackoutsListview?.columns,
    rows: rateAdminBlackoutsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminBlackouts
    {...rateAdminBlackouts}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminBlackoutsScreen: () => dispatch(initializeRateAdminBlackoutsScreen()),
      fetchRateAdminBlackouts: (payload) => dispatch(fetchRateAdminBlackouts(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRateAdminBlackouts: (payload) => dispatch(exportRateAdminBlackouts(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminBlackoutsCreateSuccess: selectFromRateAdminBlackoutsReducer(
        state,
        "isRateAdminBlackoutsCreateSuccess"
      ),
      rateAdminBlackoutsListview: selectFromRateAdminBlackoutsReducer(
        state,
        "rateAdminBlackoutsListview"
      ),
      showPageLevelLoader: selectFromRateAdminBlackoutsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminBlackoutsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRateAdminBlackoutsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminBlackoutsReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminBlackoutsExportError: selectFromRateAdminBlackoutsReducer(
        state,
        "isRateAdminBlackoutsExportError"
      ),
      isRateAdminBlackoutsExportSuccess: selectFromRateAdminBlackoutsReducer(
        state,
        "isRateAdminBlackoutsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRateAdminBlackoutsReducer = (state, path) => {
  return selectRateAdminBlackoutsReducer(state)[path];
};

const selectRateAdminBlackoutsReducer = ({ rateadminBlackoutsReducer }) => {
  return rateadminBlackoutsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RateAdminBlackoutsScreen);
