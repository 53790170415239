export const decorateEmptyGridValues = (val) => val || '-';

export const setColumnDefaultExtraProps = (props) => {
  const {
    isSortable,
    isDateField,
    isDropDownField,
    shouldFilter,
    filterPlaceholder,
    filterField,
    sortField,
    showFilterMenu,
    hidden,
    strategy,
    options
  } = props;

  return {
    isSortable: isSortable ?? true,
    isDateField: isDateField ?? false,
    isDropDownField: isDropDownField ?? false,
    shouldFilter: shouldFilter ?? true,
    hidden: hidden ?? false,
    showFilterMenu: showFilterMenu ?? false,
    filterPlaceholder: filterPlaceholder ?? 'Search...',
    filterField,
    sortField,
    strategy,
    options
  };
};