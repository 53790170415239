import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleVehicleStatusServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_VEHICLESTATUS_INIT, 
  FETCH_VEHICLE_VEHICLESTATUS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_VEHICLESTATUS_SCREEN,
  CREATE_VEHICLE_VEHICLESTATUS,
  EDIT_VEHICLE_VEHICLESTATUS,
  TOAST,
  FETCH_VEHICLE_VEHICLESTATUS_DETAIL,
  EXPORT_VEHICLE_VEHICLESTATUS,
  VEHICLE_STATUSDD
} from "../../../types/vehiclevehiclestatus.type";
export const initializeVehicleVehicleStatusScreen = () => ({ type: FETCH_VEHICLE_VEHICLESTATUS_INIT });

export const vehicleVehicleStatusFetchStart = (payload) => ({
  type: FETCH_VEHICLE_VEHICLESTATUS_LIST.START,
  payload,
});

export const vehicleVehicleStatusFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_VEHICLESTATUS_LIST.SUCCESS,
  payload,
});

export const vehicleVehicleStatusFetchError = (payload) => ({
  type: FETCH_VEHICLE_VEHICLESTATUS_LIST.ERROR,
  payload,
});

export const fetchVehicleVehicleStatus = (payload) => {
  return (dispatch) => {
    dispatch(vehicleVehicleStatusFetchStart(payload));
    vehicleVehicleStatusServices.fetchVehicleVehicleStatus(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleVehicleStatusFetchSuccess(data));
      } else {
        dispatch(vehicleVehicleStatusFetchError(data));
      }
    });
  };
};



// DD
export const vehicleStatusDDFetchStart = (payload) => ({
  type: VEHICLE_STATUSDD.START,
  payload,
});

export const vehicleStatusDDFetchSuccess = (payload) => ({
  type:VEHICLE_STATUSDD.SUCCESS,
  payload,
});

export const vehicleStatusDDFetchError = (payload) => ({
  type: VEHICLE_STATUSDD.ERROR,
  payload,
});

export const fetchVehicleStatusDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleStatusDDFetchStart(payload));
    vehicleVehicleStatusServices.fetchVehicleStatusDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleStatusDDFetchSuccess(data));
      } else {
        dispatch(vehicleStatusDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleVehicleStatusDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_VEHICLESTATUS_DETAIL.START,
  payload,
});

export const vehicleVehicleStatusDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_VEHICLESTATUS_DETAIL.SUCCESS,
  payload,
});

export const vehicleVehicleStatusDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_VEHICLESTATUS_DETAIL.ERROR,
  payload,
});

export const fetchVehicleVehicleStatusById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleVehicleStatusDetailFetchStart(payload));

    vehicleVehicleStatusServices
      .fetchVehicleVehicleStatusById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleVehicleStatusDetailFetchSuccess(data));
        } else {
          dispatch(vehicleVehicleStatusDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleVehicleStatusServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleVehicleStatusScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_VEHICLESTATUS_SCREEN,
  payload,
});

export const initCreateVehicleVehicleStatusScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleVehicleStatusScreenInitStart());
  };
};


export const createVehicleVehicleStatusStart = () => ({
  type: CREATE_VEHICLE_VEHICLESTATUS.START,
});

export const createVehicleVehicleStatusSuccess = (payload) => ({
  type: CREATE_VEHICLE_VEHICLESTATUS.SUCCESS,
  payload,
});

export const createVehicleVehicleStatusError = (payload) => ({
  type: CREATE_VEHICLE_VEHICLESTATUS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleVehicleStatus = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleVehicleStatusStart());
    vehicleVehicleStatusServices.createVehicleVehicleStatus(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleVehicleStatusSuccess());
        goBack();
      } else {
        dispatch(createVehicleVehicleStatusError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleVehicleStatusStart = (payload) => ({
  type: EDIT_VEHICLE_VEHICLESTATUS.START,
  payload
});

export const editVehicleVehicleStatusSuccess = (payload) => ({
  type: EDIT_VEHICLE_VEHICLESTATUS.SUCCESS,
  payload,
});

export const editVehicleVehicleStatusError = (payload) => ({
  type: EDIT_VEHICLE_VEHICLESTATUS.ERROR,
  payload,
});

export const editVehicleVehicleStatus = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleVehicleStatusStart());
    vehicleVehicleStatusServices.editVehicleVehicleStatus(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleVehicleStatusSuccess());
        goBack();
      } else {
        dispatch(editVehicleVehicleStatusError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleVehicleStatusRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_VEHICLESTATUS.START,
  payload
});

export const exportVehicleVehicleStatusRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_VEHICLESTATUS.SUCCESS,
  payload,
});

export const exportVehicleVehicleStatusRecordError = (payload) => ({
  type: EXPORT_VEHICLE_VEHICLESTATUS.ERROR,
  payload,
});

export const exportVehicleVehicleStatus = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleVehicleStatusRecordStart());
    vehicleVehicleStatusServices.exportVehicleVehicleStatusRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleVehicleStatusRecordSuccess(data));
      } else {
        dispatch(exportVehicleVehicleStatusRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

