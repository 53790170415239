import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleBodyShop,
    initializeVehicleBodyShopScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleBodyShop
  } from "../../../redux/actions/vehicles/vehicleBodyShop";
import VehicleBodyShop from '../../../component/Vehicles/VehicleBodyShop';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleBodyShopScreen(props) {
    const {
        fetchVehicleBodyShop,
        initVehicleBodyShopScreen,
        showPageLevelLoader,
        vehicleBodyShopListview,
        paginationInfo,
        exportVehicleBodyShop,
        isVehicleBodyShopExportError,
        isVehicleBodyShopExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleBodyShopScreen();
        fetchVehicleBodyShop({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleBodyShop(props);
      };
      const handleCreateVehicleBodyShop =() =>{
      navigate(APP_URLS.VEHICLE_BODYSHOP.CREATE);
    }
      const editVehicleBodyShopRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_BODYSHOP.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleBodyShop = {
  handleCreateVehicleBodyShop,
  exportVehicleBodyShop,
        isVehicleBodyShopExportError,
        isVehicleBodyShopExportSuccess,
  editVehicleBodyShopRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleBodyShops: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleBodyShopListview?.columns,
    rows: vehicleBodyShopListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleBodyShop
    {...vehicleBodyShop}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleBodyShopScreen: () => dispatch(initializeVehicleBodyShopScreen()),
      fetchVehicleBodyShop: (payload) => dispatch(fetchVehicleBodyShop(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleBodyShop: (payload) => dispatch(exportVehicleBodyShop(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleBodyShopCreateSuccess: selectFromVehicleBodyShopReducer(
        state,
        "isVehicleBodyShopCreateSuccess"
      ),
      vehicleBodyShopListview: selectFromVehicleBodyShopReducer(
        state,
        "vehicleBodyShopListview"
      ),
      showPageLevelLoader: selectFromVehicleBodyShopReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleBodyShopReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleBodyShopReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleBodyShopReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleBodyShopExportError: selectFromVehicleBodyShopReducer(
        state,
        "isVehicleBodyShopExportError"
      ),
      isVehicleBodyShopExportSuccess: selectFromVehicleBodyShopReducer(
        state,
        "isVehicleBodyShopExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleBodyShopReducer = (state, path) => {
  return selectVehicleBodyShopReducer(state)[path];
};

const selectVehicleBodyShopReducer = ({ vehicleBodyShopReducer }) => {
  return vehicleBodyShopReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleBodyShopScreen);
