import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminNetworksServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_NETWORKS_INIT, 
  FETCH_RATEADMIN_NETWORKS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_NETWORKS_SCREEN,
  CREATE_RATEADMIN_NETWORKS,
  EDIT_RATEADMIN_NETWORKS,
  TOAST,
  FETCH_RATEADMIN_NETWORKS_DETAIL,
  EXPORT_RATEADMIN_NETWORKS,
  RATEADMIN_NETWORKSDD
} from "../../../types/rateadminnetworks.type";
export const initializeRateAdminNetworksScreen = () => ({ type: FETCH_RATEADMIN_NETWORKS_INIT });

export const rateadminNetworksFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_NETWORKS_LIST.START,
  payload,
});

export const rateadminNetworksFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_NETWORKS_LIST.SUCCESS,
  payload,
});

export const rateadminNetworksFetchError = (payload) => ({
  type: FETCH_RATEADMIN_NETWORKS_LIST.ERROR,
  payload,
});

export const fetchRateAdminNetworks = (payload) => {
  return (dispatch) => {
    dispatch(rateadminNetworksFetchStart(payload));
    rateAdminNetworksServices.fetchRateAdminNetworks(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminNetworksFetchSuccess(data));
      } else {
        dispatch(rateadminNetworksFetchError(data));
      }
    });
  };
};



// DD
export const rateadminNetworksDDFetchStart = (payload) => ({
  type: RATEADMIN_NETWORKSDD.START,
  payload,
});

export const rateadminNetworksDDFetchSuccess = (payload) => ({
  type:RATEADMIN_NETWORKSDD.SUCCESS,
  payload,
});

export const rateadminNetworksDDFetchError = (payload) => ({
  type: RATEADMIN_NETWORKSDD.ERROR,
  payload,
});

export const fetchRateAdminNetworksDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminNetworksDDFetchStart(payload));
    rateAdminNetworksServices.fetchRateAdminNetworksDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminNetworksDDFetchSuccess(data));
      } else {
        dispatch(rateadminNetworksDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminNetworksDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_NETWORKS_DETAIL.START,
  payload,
});

export const rateadminNetworksDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_NETWORKS_DETAIL.SUCCESS,
  payload,
});

export const rateadminNetworksDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_NETWORKS_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminNetworksById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminNetworksDetailFetchStart(payload));

    rateAdminNetworksServices
      .fetchRateAdminNetworksById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminNetworksDetailFetchSuccess(data));
        } else {
          dispatch(rateadminNetworksDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminNetworksServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminNetworksScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_NETWORKS_SCREEN,
  payload,
});

export const initCreateRateAdminNetworksScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminNetworksScreenInitStart());
  };
};


export const createRateAdminNetworksStart = () => ({
  type: CREATE_RATEADMIN_NETWORKS.START,
});

export const createRateAdminNetworksSuccess = (payload) => ({
  type: CREATE_RATEADMIN_NETWORKS.SUCCESS,
  payload,
});

export const createRateAdminNetworksError = (payload) => ({
  type: CREATE_RATEADMIN_NETWORKS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminNetworks = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminNetworksStart());
    rateAdminNetworksServices.createRateAdminNetworks(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminNetworksSuccess());
        goBack();
      } else {
        dispatch(createRateAdminNetworksError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminNetworksStart = (payload) => ({
  type: EDIT_RATEADMIN_NETWORKS.START,
  payload
});

export const editRateAdminNetworksSuccess = (payload) => ({
  type: EDIT_RATEADMIN_NETWORKS.SUCCESS,
  payload,
});

export const editRateAdminNetworksError = (payload) => ({
  type: EDIT_RATEADMIN_NETWORKS.ERROR,
  payload,
});

export const editRateAdminNetworks = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminNetworksStart());
    rateAdminNetworksServices.editRateAdminNetworks(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminNetworksSuccess());
        goBack();
      } else {
        dispatch(editRateAdminNetworksError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminNetworksRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_NETWORKS.START,
  payload
});

export const exportRateAdminNetworksRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_NETWORKS.SUCCESS,
  payload,
});

export const exportRateAdminNetworksRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_NETWORKS.ERROR,
  payload,
});

export const exportRateAdminNetworks = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminNetworksRecordStart());
    rateAdminNetworksServices.exportRateAdminNetworksRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminNetworksRecordSuccess(data));
      } else {
        dispatch(exportRateAdminNetworksRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

