import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleCategoryServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_CATEGORY_INIT, 
  FETCH_VEHICLE_CATEGORY_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_CATEGORY_SCREEN,
  CREATE_VEHICLE_CATEGORY,
  EDIT_VEHICLE_CATEGORY,
  TOAST,
  FETCH_VEHICLE_CATEGORY_DETAIL,
  EXPORT_VEHICLE_CATEGORY,
  VEHICLE_CATEGORYDD
  
} from "../../../types/vehiclecategory.type";
export const initializeVehicleCategoryScreen = () => ({ type: FETCH_VEHICLE_CATEGORY_INIT });

export const vehicleCategoryFetchStart = (payload) => ({
  type: FETCH_VEHICLE_CATEGORY_LIST.START,
  payload,
});

export const vehicleCategoryFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_CATEGORY_LIST.SUCCESS,
  payload,
});

export const vehicleCategoryFetchError = (payload) => ({
  type: FETCH_VEHICLE_CATEGORY_LIST.ERROR,
  payload,
});

export const fetchVehicleCategory = (payload) => {
  return (dispatch) => {
    dispatch(vehicleCategoryFetchStart(payload));
    vehicleCategoryServices.fetchVehicleCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleCategoryFetchSuccess(data));
      } else {
        dispatch(vehicleCategoryFetchError(data));
      }
    });
  };
};


export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleCategoryDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_CATEGORY_DETAIL.START,
  payload,
});

export const vehicleCategoryDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_CATEGORY_DETAIL.SUCCESS,
  payload,
});

export const vehicleCategoryDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_CATEGORY_DETAIL.ERROR,
  payload,
});

export const fetchVehicleCategoryById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleCategoryDetailFetchStart(payload));

    vehicleCategoryServices
      .fetchVehicleCategoryById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleCategoryDetailFetchSuccess(data));
        } else {
          dispatch(vehicleCategoryDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleCategoryServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleCategoryScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_CATEGORY_SCREEN,
  payload,
});

export const initCreateVehicleCategoryScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleCategoryScreenInitStart());
  };
};


export const createVehicleCategoryStart = () => ({
  type: CREATE_VEHICLE_CATEGORY.START,
});

export const createVehicleCategorySuccess = (payload) => ({
  type: CREATE_VEHICLE_CATEGORY.SUCCESS,
  payload,
});

export const createVehicleCategoryError = (payload) => ({
  type: CREATE_VEHICLE_CATEGORY.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleCategory = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleCategoryStart());
    vehicleCategoryServices.createVehicleCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleCategorySuccess());
        goBack();
      } else {
        dispatch(createVehicleCategoryError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleCategoryStart = (payload) => ({
  type: EDIT_VEHICLE_CATEGORY.START,
  payload
});

export const editVehicleCategorySuccess = (payload) => ({
  type: EDIT_VEHICLE_CATEGORY.SUCCESS,
  payload,
});

export const editVehicleCategoryError = (payload) => ({
  type: EDIT_VEHICLE_CATEGORY.ERROR,
  payload,
});

export const editVehicleCategory = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleCategoryStart());
    vehicleCategoryServices.editVehicleCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleCategorySuccess());
        goBack();
      } else {
        dispatch(editVehicleCategoryError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleCategoryRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_CATEGORY.START,
  payload
});

export const exportVehicleCategoryRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_CATEGORY.SUCCESS,
  payload,
});

export const exportVehicleCategoryRecordError = (payload) => ({
  type: EXPORT_VEHICLE_CATEGORY.ERROR,
  payload,
});

export const exportVehicleCategory = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleCategoryRecordStart());
    vehicleCategoryServices.exportVehicleCategoryRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleCategoryRecordSuccess(data));
      } else {
        dispatch(exportVehicleCategoryRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

// DD
export const vehicleCategoryDDFetchStart = (payload) => ({
  type: VEHICLE_CATEGORYDD.START,
  payload,
});

export const vehicleCategoryDDFetchSuccess = (payload) => ({
  type:VEHICLE_CATEGORYDD.SUCCESS,
  payload,
});

export const vehicleCategoryDDFetchError = (payload) => ({
  type: VEHICLE_CATEGORYDD.ERROR,
  payload,
});

export const fetchVehicleCategoryDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleCategoryDDFetchStart(payload));
    vehicleCategoryServices.fetchVehicleCategoryDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleCategoryDDFetchSuccess(data));
      } else {
        dispatch(vehicleCategoryDDFetchError(data));
      }
    });
  };
};

//END DD