import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_TRANSMISSIONTYPE_LIST, 
  TOAST, 
  FETCH_VEHICLE_TRANSMISSIONTYPE_INIT,
  FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL,
  EXPORT_VEHICLE_TRANSMISSIONTYPE,
  CREATE_VEHICLE_TRANSMISSIONTYPE,
  EDIT_VEHICLE_TRANSMISSIONTYPE,
  INIT_CREATE_VEHICLE_TRANSMISSIONTYPE_SCREEN,
  VEHICLE_MODELDD
} from "../../../types/vehicletransmissiontype.type";

  const formFieldValueMap = {
    id:null,
    modBy:"",
    description:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    vehicleTransmissionTypeListview: [],
    vehicleModelDD:[],
    isVehicleModelDDSuccess: false,
    isVehicleModelDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleTransmissionTypeCreateSuccess: false,
    isVehicleTransmissionTypeCreateError: false,
    isVehicleTransmissionTypeDetailSuccess: false,
    isVehicleTransmissionTypeDetailError: false,
    isVehicleTransmissionTypeEditSuccess: false,
    isVehicleTransmissionTypeEditError: false,
    isVehicleTransmissionTypeExportSuccess: false,
    isVehicleTransmissionTypeExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "TransmissionType",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleTransmissionTypeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_TRANSMISSIONTYPE_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL.START:
      case EXPORT_VEHICLE_TRANSMISSIONTYPE.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_TRANSMISSIONTYPE_LIST.START:
      case CREATE_VEHICLE_TRANSMISSIONTYPE.START:
        case EDIT_VEHICLE_TRANSMISSIONTYPE.START:
          case VEHICLE_MODELDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_TRANSMISSIONTYPE_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleTransmissionTypeListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleTransmissionTypeCreateSuccess: false,
          isVehicleTransmissionTypeCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleTransmissionTypeListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_TRANSMISSIONTYPE_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleTransmissionTypeListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleTransmissionTypeDeletionSuccess: false,
          isvehicleTransmissionTypeDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleTransmissionTypeDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_TRANSMISSIONTYPE_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleTransmissionTypeDetailSuccess: false,
        isVehicleTransmissionTypeDetailError: true,
      };
    }

    //DD
    case VEHICLE_MODELDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        vehicleModelDD:temp,
        isVehicleModelDDSuccess: true,
        isVehicleModelDDError: false,
      };
    }
    case VEHICLE_MODELDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleModelDDSuccess: false,
    isVehicleModelDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_TRANSMISSIONTYPE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleTransmissionTypeExportSuccess: false,
    isVehicleTransmissionTypeExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_TRANSMISSIONTYPE.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-transmission-type.xls");
   return {
    ...state,
    isVehicleTransmissionTypeExportSuccess: true,
    isVehicleTransmissionTypeExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_TRANSMISSIONTYPE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleTransmissionTypeEditSuccess: false,
    isVehicleTransmissionTypeEditError: true,
  };
}

case EDIT_VEHICLE_TRANSMISSIONTYPE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleTransmissionTypeEditSuccess: true,
    isVehicleTransmissionTypeEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_TRANSMISSIONTYPE_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_TRANSMISSIONTYPE.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleTransmissionTypeCreateSuccess: false,
    isVehicleTransmissionTypeCreateError: true,
  };
}
case CREATE_VEHICLE_TRANSMISSIONTYPE.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleTransmissionTypeCreateSuccess: true,
    isVehicleTransmissionTypeCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleTransmissionTypeCreateSuccess: false,
          isVehicleTransmissionTypeCreateError: false,
          isVehicleTransmissionTypeEditError: false,
          isVehicleTransmissionTypeEditSuccess: false,
          isVehicleTransmissionTypeDetailError: false
        };
      }
      default:
        return state;
    }
  };
  