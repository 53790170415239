import { useEffect, useCallback, useState } from "react";

const useContextMenu = () => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      setAnchorPoint({ x: event.clientX, y: event.clientY });
      setShow(true);
    },
    [setShow, setAnchorPoint]
  );

  const handleClick = useCallback((e) => {
   const {clientX ,clientY} = e;
   const isClickedSomeWhereElse = clientX !== anchorPoint.x || clientY !== anchorPoint.y; 
  return (show && isClickedSomeWhereElse)? setShow(false) : null}
  // eslint-disable-next-line
   , [show]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("customContextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("customContextmenu", handleContextMenu);
    };
  });
  return { anchorPoint, show };
};

export default useContextMenu;