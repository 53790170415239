import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { RateAdminBreadcrumb, RateTypeDD, basicDetail, columns, editMode, rateAdjustment, rateExtraDaily, rateExtraMonthly, rateExtraWeekend, rateExtraWeekly, statusDD, } from "../CreateRateAdminExtraProductCharge/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel"
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import { additionbaselor } from "../../../../utils/common";
import { formatDates, formatDatesHyphan } from "../../../../utils";
import { useParams } from "react-router-dom";
import EzControlledRadioButtonWithLabel from "../../../../common/EzForm/EzControlledRadioButton/WithLabel";
import { RadioButton } from "primereact/radiobutton";
import EzControlledMultiSelecttWithFloatingLabel from "../../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";

const CreateRateAdminExtraProductChargeComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRateAdminExtraProductChargeScreen,
    createRateAdminExtraProductCharge,
    editRateAdminExtraProductCharge,
    isLoading,
    isRateAdminExtraProductChargeCreateSuccess,
    isRateAdminExtraProductChargeCreateError,

    isRateAdminExtraProductChargeDetailSuccess,
    isRateAdminExtraProductChargeDetailError,
    isRateAdminExtraProductChargeEditSuccess,
    isEdit,
    ExtraProductCategoryDD,
    vehicleCategoryDD,
    isView,
    formFieldValueMap,

  } = props;
  console.log("check props of compoemnt", props);
const [fixedCostValue, setFixedCostValue] = useState("no")
const [isFixedValue, setIsFixedValue] = useState("no")

const handleRadio =(e) =>{
  const {name, value} = e.target;
  if(name==="fixedCost"){
    setFixedCostValue(value)
  }
  else{
    setIsFixedValue(value)
  }
  
}
  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const isFixed = watch("isFixed");
  const fixedCost = watch("fixedCost");
  const dailybaseRate = watch("dailybaseRate");
  const dailylor1st = watch("dailylor1st");
  const dailylor2nd = watch("dailylor2nd");
  const dailylor3rd = watch("dailylor3rd");
  const dailylor4th = watch("dailylor4th");
  const dailylor5th = watch("dailylor5th");
  const dailylor6th = watch("dailylor6th");

  const weekendbaseRate = watch("weekendbaseRate");
  const weekendlor1st = watch("weekendlor1st");
  const weekendlor2nd = watch("weekendlor2nd");
  const weekendlor3rd = watch("weekendlor3rd");
  const weekendlor4th = watch("weekendlor4th");
  const weekendlor5th = watch("weekendlor5th");
  const weekendlor6th = watch("weekendlor6th");

  const weeklybaseRate = watch("weeklybaseRate");
  const weeklylor1st = watch("weeklylor1st");
  const weeklylor2nd = watch("weeklylor2nd");
  const weeklylor3rd = watch("weeklylor3rd");
  const weeklylor4th = watch("weeklylor4th");



  const navigateToRateAdminExtraProductChargeScreen = () => {
    window.location.replace("/#" + APP_URLS.RATEADMIN_EXTRAPRODUCTCHARGE.HOME)
  };
  useEffect(() => {

    if (isRateAdminExtraProductChargeCreateSuccess || isRateAdminExtraProductChargeEditSuccess) {
      reset();
    }
  }, [isRateAdminExtraProductChargeCreateSuccess]);
  useEffect(() => {
    initCreateRateAdminExtraProductChargeScreen();
  }, []);



  useEffect(() => {
    if (isRateAdminExtraProductChargeDetailSuccess) {
    
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isRateAdminExtraProductChargeDetailSuccess, ]);


  useEffect(() => {
      setFixedCostValue(fixedCost == 0 ? "no":"yes");
      setIsFixedValue(isFixed ==0 ? "no":"yes");
  }, [fixedCost, isFixed]);



  const onSubmit = (data) => {
    console.log("CHA", data)
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    let rateBaseDTO = [
      {
        "fkRatePlanId": 1,
        "id": null || isEdit && data?.dailybaseId,
        "rate": data?.dailybaseRate
      },
      {
        "fkRatePlanId": 2,
        "id": null || isEdit && data?.weekendbaseId,
        "rate": data?.weekendbaseRate
      },
      {
        "fkRatePlanId": 3,
        "id": null || isEdit && data?.weeklybaseId,
        "rate": data?.weeklybaseRate
      },
      {
        "fkRatePlanId": 4,
        "id": null || isEdit && data?.monthlybaseId,
        "rate": data?.monthlybaseRate
      }
    ]

    let rateExtraDayDTOS = [
      {
        "fkRatePlanId": 1,
        "id": null || isEdit && data?.dailyextraId,
        "rate": data?.dailyextraRate,
        "status": 0
      },
      {
        "fkRatePlanId": 2,
        "id": null || isEdit && data?.weekendextraId,
        "rate": data?.weekendextraRate,
        "status": 0
      },
      {
        "fkRatePlanId": 3,
        "id": null || isEdit && data?.weeklyextraId,
        "rate": data?.weeklyextraRate,
        "status": 0
      },
      {
        "fkRatePlanId": 4,
        "id": null || isEdit && data?.monthlyextraId,
        "rate": data?.monthlyextraRate,
        "status": 0
      }
    ]
    let rateExtraHourDTOS = [
      {
        "fkRatePlanId": 1,
        "id": null || isEdit && data?.dailyhoursId,
        "rate": data?.dailyhoursRate
      },
      {
        "fkRatePlanId": 2,
        "id": null || isEdit && data?.weekendhoursId,
        "rate": data?.weekendhoursRate
      },
      {
        "fkRatePlanId": 3,
        "id": null || isEdit && data?.weeklyhoursId,
        "rate": data?.weeklyhoursRate
      },
      {
        "fkRatePlanId": 4,
        "id": null || isEdit && data?.monthlyhoursId,
        "rate": data?.monthlyhoursRate
      }
    ]
    let rateLorAdjustmentDTOS = [
      {
        "fkLorId": 1,
        "id": null || isEdit && data?.dailylor1stId,
        "rate": data?.dailylor1st
      },
      {
        "fkLorId": 2,
        "id": null || isEdit && data?.dailylor2ndId,
        "rate": data?.dailylor2nd
      },
      {
        "fkLorId": 3,
        "id": null || isEdit && data?.dailylor3rdId,
        "rate": data?.dailylor3rd
      },
      {
        "fkLorId": 4,
        "id": null || isEdit && data?.dailylor4thId,
        "rate": data?.dailylor4th
      },
      {
        "fkLorId": 5,
        "id": null || isEdit && data?.dailylor5thId,
        "rate": data?.dailylor5th
      },
      {
        "fkLorId": 6,
        "id": null || isEdit && data?.dailylor6thId,
        "rate": data?.dailylor6th
      },
      {
        "fkLorId": 7,
        "id": null || isEdit && data?.weekendlor1stId,
        "rate": data?.weekendlor1st
      },
      {
        "fkLorId": 8,
        "id": null || isEdit && data?.weekendlor2ndId,
        "rate": data?.weekendlor2nd
      },
      {
        "fkLorId": 9,
        "id": null || isEdit && data?.weekendlor3rdId,
        "rate": data?.weekendlor3rd
      },
      {
        "fkLorId": 10,
        "id": null || isEdit && data?.weekendlor4thId,
        "rate": data?.weekendlor4th
      },
      {
        "fkLorId": 11,
        "id": null || isEdit && data?.weekendlor5thId,
        "rate": data?.weekendlor5th
      },
      {
        "fkLorId": 12,
        "id": null || isEdit && data?.weekendlor6thId,
        "rate": data?.weekendlor6th
      },

      {
        "fkLorId": 13,
        "id": null || isEdit && data?.weeklylor1stId,
        "rate": data?.weeklylor1st
      },
      {
        "fkLorId": 14,
        "id": null || isEdit && data?.weeklylor2ndId,
        "rate": data?.weeklylor2nd
      },
      {
        "fkLorId": 15,
        "id": null || isEdit && data?.weeklylor3rdId,
        "rate": data?.weeklylor3rd
      },
      {
        "fkLorId": 16,
        "id": null || isEdit && data?.weeklylor4thId,
        "rate": data?.weeklylor4th
      }
    ]
  
    let stringifiedData = {
      id: isEdit ? data?.id : null,
      status: data?.status,
      title: data?.title,
      startDate: formatDatesHyphan(data?.startDate),
      endDate: formatDatesHyphan(data?.endDate),
      description: data?.description,
      fkExtracategoryId: data?.fkExtracategoryId,
      vehicleCategoryId: data?.vehicleCategoryId,
      fixedCost: fixedCostValue==="yes" ? 1:0,
      isFixed: isFixedValue==="yes" ? 1:0,
      userId: localStorage.getItem("userId"),
     
    }
    if(fixedCostValue==="no" && isFixedValue==="no"){
       stringifiedData = {
        id: isEdit ? data?.id : null,
        status: data?.status,
        title: data?.title,
        startDate: formatDatesHyphan(data?.startDate),
        endDate: formatDatesHyphan(data?.endDate),
        description: data?.description,
        fkExtracategoryId: data?.fkExtracategoryId,
        vehicleCategoryId: data?.vehicleCategoryId,
        fixedCost: fixedCostValue==="yes" ? 1:0,
      isFixed: isFixedValue==="yes" ? 1:0,
        userId: localStorage.getItem("userId"),
        rateBaseDTO: rateBaseDTO,
        rateExtraDayDTOS: rateExtraDayDTOS,
        rateExtraHourDTOS: rateExtraHourDTOS,
        rateLorAdjustmentDTOS: rateLorAdjustmentDTOS
      }
    }
    isEdit
      ? editRateAdminExtraProductCharge({ id: data.id, data: stringifiedData })
      : createRateAdminExtraProductCharge(stringifiedData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };



  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">{isEdit ? "Update Extra Product Charge" : "Create Extra Product Charge"}</h4>
          </div>
          <div className="buttonarea">


            <Button
              tooltip={"Back to Extra Product Charge"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Extra Product Charge"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToRateAdminExtraProductChargeScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RateAdminBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              {basicDetail?.map((item) => (
                item.name === "status" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={statusDD}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :
                  item.name === "fkExtracategoryId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={ExtraProductCategoryDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                    : item.name === "vehicleCategoryId" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledMultiSelecttWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={vehicleCategoryDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )

                      : item.type === "date" ? (
                        <Grid item xs={3} key={item.name}>
                          <EzControlledCalendarWithFloatingLabel
                            id={item.name}
                            name={item.name}
                            {...register(item.name)}
                            showTime={false}
                            maxDate={new Date(2090, 0, 1)}
                            dateFormat="yy/mm/dd"
                            control={control}
                            isError={errors[item.name]}
                            errorMsg={getFormErrorMessage(item.name)}
                            label={item.label}
                            rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                          />
                        </Grid>
                      )

                        : (
                          (<Grid item xs={3} key={item.name}>
                            <EzControlledInputWithFloatingLabel
                              name={item?.name}
                              control={control}
                              label={item?.label}
                              type={item?.type}
                              rules={item?.rule}
                              isError={errors[item?.name]}
                              errorMsg={getFormErrorMessage(item?.name)}
                            />
                          </Grid>))
              ))}

            </Grid>
            <Grid container xs={12} mb={'20px'}>
              <Grid item xs={6}>
                Is this extra product included at no charge in the cost of rental?
              </Grid>
<input checked={fixedCostValue==="yes"} type="radio" onChange={handleRadio} name="fixedCost" value="yes" /> Yes
<input checked={fixedCostValue==="no"} type="radio" onChange={handleRadio} name="fixedCost" value="no" />  No          
            </Grid>
{fixedCostValue!=="yes" &&(
<div>
            <Grid container xs={12} mb={'20px'}>
              <Grid item xs={6}>
              Does this extra product have a fixed price?
              </Grid>
<input checked={isFixedValue==="yes"} type="radio" onChange={handleRadio} name="isFixed" value="yes" /> Yes
<input checked={isFixedValue==="no"} type="radio" onChange={handleRadio} name="isFixed" value="no" />  No          
            </Grid>
            {isFixedValue!=="yes" &&(
            <Grid>
              <table className="tablerate">
                <tr>
                  <th>Rate</th>
                  <th>Base</th>
                  <th>Extra Day	</th>
                  <th>Hour</th>
                  <th colSpan={"6"}>LOR Adjustments</th>
                </tr>
                <tr>
                  <td>Plan</td>
                  <td>Rate</td>
                  <td>Rate</td>
                  <td>Rate</td>
                  <td>1st</td>
                  <td>2nd</td>
                  <td>3rd</td>
                  <td>4th</td>
                  <td>5th</td>
                  <td>6th</td>
                </tr>
                <tr>
                  {rateExtraDaily?.map((item) => (
                    item.name !== undefined ? (
                      <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                        {item.notify !== undefined ? <span className="red">{item.notify}</span> : ""}

                        {item.name === "dailylor1st" ? <span className="red">{additionbaselor(dailybaseRate, dailylor1st)}</span>
                          : item.name === "dailylor2nd" ? <span className="red">{additionbaselor(dailybaseRate, dailylor2nd)}</span>
                            : item.name === "dailylor3rd" ? <span className="red">{additionbaselor(dailybaseRate, dailylor3rd)}</span>
                              : item.name === "dailylor4th" ? <span className="red">{additionbaselor(dailybaseRate, dailylor4th)}</span>
                                : item.name === "dailylor5th" ? <span className="red">{additionbaselor(dailybaseRate, dailylor5th)}</span>
                                  : item.name === "dailylor6th" ? <span className="red">{additionbaselor(dailybaseRate, dailylor6th)}</span> : ""}

                      </td>)
                      : (<td>{item.label}</td>)
                  ))}
                </tr>

                <tr>
                  {rateExtraWeekend?.map((item) => (
                    item.name !== undefined ? (
                      <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                        {item.notify !== undefined ? <span className="red">{item.notify}</span> : ""}

                        {item.name === "weekendlor1st" ? <span className="red">{additionbaselor(weekendbaseRate, weekendlor1st)}</span>
                          : item.name === "weekendlor2nd" ? <span className="red">{additionbaselor(weekendbaseRate, weekendlor2nd)}</span>
                            : item.name === "weekendlor3rd" ? <span className="red">{additionbaselor(weekendbaseRate, weekendlor3rd)}</span>
                              : item.name === "weekendlor4th" ? <span className="red">{additionbaselor(weekendbaseRate, weekendlor4th)}</span>
                                : item.name === "weekendlor5th" ? <span className="red">{additionbaselor(weekendbaseRate, weekendlor5th)}</span>
                                  : item.name === "weekendlor6th" ? <span className="red">{additionbaselor(weekendbaseRate, weekendlor6th)}</span> : ""}

                      </td>)
                      : (<td>{item.label}</td>)
                  ))}
                </tr>
                <tr>
                  {rateExtraWeekly?.map((item) => (
                    item.name !== undefined ? (
                      <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />

                        {item.notify !== undefined ? <span className="red">{item.notify}</span> : ""}

                        {item.name === "weeklylor1st" ? <span className="red">{additionbaselor(weeklybaseRate, weeklylor1st)}</span>
                          : item.name === "weeklylor2nd" ? <span className="red">{additionbaselor(weeklybaseRate, weeklylor2nd)}</span>
                            : item.name === "weeklylor3rd" ? <span className="red">{additionbaselor(weeklybaseRate, weeklylor3rd)}</span>
                              : item.name === "weeklylor4th" ? <span className="red">{additionbaselor(weeklybaseRate, weeklylor4th)}</span> : ""}

                      </td>)
                      : (<td>{item.label}</td>)
                  ))}
                </tr>
                <tr>
                  {rateExtraMonthly?.map((item) => (
                    item.name !== undefined ? (
                      <td><EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        //label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </td>)
                      : (<td>{item.label}</td>)
                  ))}
                </tr>
              </table>
            </Grid>)}
            </div>)}
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
            {/* {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
} */}
          </form>
        </div>
      </div>
      <div>

      </div>


    </Container>
  );
};

export default CreateRateAdminExtraProductChargeComponent;
