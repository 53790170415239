import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleStereoTypeScreen,
  createVehicleStereoType,
  editVehicleStereoType,
  fetchVehicleStereoTypeById,
  uploadImage,
  fetchVehicleModelDD
} from "../../../../redux/actions/vehicles/vehicleStereoType";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleStereoTypeComponent from "../../../../component/Vehicles/VehicleStereoType/CreateVehicleStereoType";
import { useParams } from "react-router-dom";

const CreateVehicleStereoTypeContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleStereoType,
    editVehicleStereoType,
    isLoading,
    isVehicleStereoTypeCreateSuccess,
    isVehicleStereoTypeCreateError,
    isVehicleStereoTypeDetailSuccess,
    isVehicleStereoTypeDetailError,
    isVehicleStereoTypeEditSuccess,
    isVehicleStereoTypeEditError,
    isEdit,
    isView,
    initCreateVehicleStereoTypeScreen,
    formFieldValueMap,
    fetchVehicleStereoTypeById,
    fetchVehicleModelDD,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleStereoTypeScreen();
    //await fetchVehicleModelDD();
   if (isEdit) {
    await fetchVehicleStereoTypeById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleStereoTypeProps = {
    createVehicleStereoType,
    initCreateVehicleStereoTypeScreen,
    editVehicleStereoType,
    isLoading,
    isEdit,
    isVehicleStereoTypeDetailSuccess,
    isVehicleStereoTypeCreateSuccess,
    isVehicleStereoTypeEditSuccess,
    isVehicleStereoTypeEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isVehicleStereoTypeCreateSuccess || isVehicleStereoTypeEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleStereoType Updated Successfully"
        : "VehicleStereoType Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleStereoTypeCreateError ||
      isVehicleStereoTypeDetailError ||
      isVehicleStereoTypeEditError
    ) {
      let toastTitle = "Error while Creating Vehicle StereoType";
      if (isVehicleStereoTypeEditError) {
        toastTitle = "Error while updating Vehicle StereoType";
      } else if (isVehicleStereoTypeDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleStereoTypeComponent {...createVehicleStereoTypeProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleStereoTypeReducer(state, "isLoading"),
   
    isVehicleStereoTypeCreateSuccess: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleStereoTypeCreateSuccess"
    ),
    isVehicleStereoTypeCreateError: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleStereoTypeCreateError"
    ),
    isVehicleStereoTypeDetailSuccess: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleStereoTypeDetailSuccess"
    ),
    isVehicleStereoTypeDetailError: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleStereoTypeDetailError"
    ),
    isVehicleStereoTypeEditSuccess: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleStereoTypeEditSuccess"
    ),
    isVehicleStereoTypeEditError: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleStereoTypeEditError"
    ),
    imageUploadResponse: selectFromVehicleStereoTypeReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleStereoTypeReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleStereoTypeReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromVehicleStereoTypeReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleStereoTypeReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleStereoTypeReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleStereoTypeScreen: () => dispatch(initCreateVehicleStereoTypeScreen()),
    createVehicleStereoType: (payload) => dispatch(createVehicleStereoType(payload)),
    editVehicleStereoType: (payload) => dispatch(editVehicleStereoType(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleStereoTypeById: (payload) =>
      dispatch(fetchVehicleStereoTypeById(payload)),
    fetchVehicleModelDD: (payload) =>
      dispatch(fetchVehicleModelDD(payload)),
  };
};

const selectFromVehicleStereoTypeReducer = (state, path) => {
  return selectVehicleStereoTypeReducer(state)[path];
};

const selectVehicleStereoTypeReducer = ({ vehicleStereoTypeReducer }) => {
  return vehicleStereoTypeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleStereoTypeContainer);
