import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRentersLogInteractionsScreen,
  createRentersLogInteractions,
  editRentersLogInteractions,
  fetchRentersLogInteractionsById,
  uploadImage,
} from "../../../../redux/actions/Renters/RentersLogInteractions";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRentersLogInteractionsComponent from "../../../../component/Renters/RentersLogInteractions/CreateRentersLogInteractions";
import { useParams } from "react-router-dom";

const CreateRentersLogInteractionsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRentersLogInteractions,
    editRentersLogInteractions,
    isLoading,
    isRentersLogInteractionsCreateSuccess,
    isRentersLogInteractionsCreateError,
    isRentersLogInteractionsDetailSuccess,
    isRentersLogInteractionsDetailError,
    isRentersLogInteractionsEditSuccess,
    isRentersLogInteractionsEditError,
    isEdit,
    isView,
    initCreateRentersLogInteractionsScreen,
    formFieldValueMap,
    fetchRentersLogInteractionsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  } = props;

  const { id } = useParams
    ();
  const callAPIasync = async () => {
    initCreateRentersLogInteractionsScreen();
    if (isEdit) {
      await fetchRentersLogInteractionsById({ id: id });
    }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);



  const createRentersLogInteractionsProps = {
    createRentersLogInteractions,
    initCreateRentersLogInteractionsScreen,
    editRentersLogInteractions,
    isLoading,
    isEdit,
    isRentersLogInteractionsDetailSuccess,
    isRentersLogInteractionsCreateSuccess,
    isRentersLogInteractionsEditSuccess,
    isRentersLogInteractionsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRentersLogInteractionsCreateSuccess || isRentersLogInteractionsEditSuccess) {
      const toastTitle = isEdit
        ? "Renter Log Interaction Updated Successfully"
        : "Renter Log Interaction Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRentersLogInteractionsCreateError ||
      isRentersLogInteractionsDetailError ||
      isRentersLogInteractionsEditError
    ) {
      let toastTitle = "Error while Creating Renter Log Interaction";
      if (isRentersLogInteractionsEditError) {
        toastTitle = "Error while updating  Renter Log Interaction";
      } else if (isRentersLogInteractionsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRentersLogInteractionsComponent {...createRentersLogInteractionsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRentersLogInteractionsReducer(state, "isLoading"),

    isRentersLogInteractionsCreateSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsCreateSuccess"
    ),
    isRentersLogInteractionsCreateError: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsCreateError"
    ),
    isRentersLogInteractionsDetailSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsDetailSuccess"
    ),
    isRentersLogInteractionsDetailError: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsDetailError"
    ),
    isRentersLogInteractionsEditSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsEditSuccess"
    ),
    isRentersLogInteractionsEditError: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsEditError"
    ),
    imageUploadResponse: selectFromRentersLogInteractionsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRentersLogInteractionsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRentersLogInteractionsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRentersLogInteractionsReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRentersLogInteractionsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRentersLogInteractionsScreen: () => dispatch(initCreateRentersLogInteractionsScreen()),
    createRentersLogInteractions: (payload) => dispatch(createRentersLogInteractions(payload)),
    editRentersLogInteractions: (payload) => dispatch(editRentersLogInteractions(payload)),
    shouldBlockNavigation: (isFormDirty) => dispatch(setIsformDirty(isFormDirty)),
    uploadImage: (payload) => dispatch(uploadImage(payload)),
    fetchRentersLogInteractionsById: (payload) =>
      dispatch(fetchRentersLogInteractionsById(payload)),

  };
};

const selectFromRentersLogInteractionsReducer = (state, path) => {
  return selectRentersLogInteractionsReducer(state)[path];
};

const selectRentersLogInteractionsReducer = ({ RentersLogInteractionsReducer }) => {
  return RentersLogInteractionsReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRentersLogInteractionsContainer);
