

export const FETCH_HOLIDAYS_LIST = {
    START: 'FETCH_HOLIDAYS_LIST_START',
    SUCCESS: 'FETCH_HOLIDAYS_LIST_SUCCESS',
    ERROR: 'FETCH_HOLIDAYS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_HOLIDAYS_INIT="FETCH_HOLIDAYS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_HOLIDAYS_SCREEN =
'INIT_CREATE_HOLIDAYS_SCREEN';

export const CREATE_HOLIDAYS = {
START: 'CREATE_HOLIDAYS_START',
SUCCESS: 'CREATE_HOLIDAYS_SUCCESS',
ERROR: 'CREATE_HOLIDAYS_ERROR',
};

export const EDIT_HOLIDAYS = {
START: 'EDIT_HOLIDAYS_START',
SUCCESS: 'EDIT_HOLIDAYS_SUCCESS',
ERROR: 'EDIT_HOLIDAYS_ERROR',
};

export const FETCH_HOLIDAYS_DETAIL = {
  START: 'FETCH_HOLIDAYS_DETAIL_START',
  SUCCESS: 'FETCH_HOLIDAYS_DETAIL_SUCCESS',
  ERROR: 'FETCH_HOLIDAYS_DETAIL_ERROR',
};
export const EXPORT_HOLIDAYS = {
  START: 'EXPORT_HOLIDAYS_START',
  SUCCESS: 'EXPORT_HOLIDAYS_SUCCESS',
  ERROR: 'EXPORT_HOLIDAYS_ERROR',
};
export const HOLIDAYSDD = {
  START: 'HOLIDAYSDD_START',
  SUCCESS: 'HOLIDAYSDD_SUCCESS',
  ERROR: 'HOLIDAYSDD_ERROR',
};
export const HOLIDAYSDELETE = {
  START: 'HOLIDAYSDELETE_START',
  SUCCESS: 'HOLIDAYSDELETE_SUCCESS',
  ERROR: 'HOLIDAYSDELETE_ERROR',
};

