import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleAcType,
    initializeVehicleAcTypeScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleAcType
  } from "../../../redux/actions/vehicles/vehicleAcType";
import VehicleAcType from '../../../component/Vehicles/VehicleAcType';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleAcTypeScreen(props) {
    const {
        fetchVehicleAcType,
        initVehicleAcTypeScreen,
        showPageLevelLoader,
        vehicleAcTypeListview,
        paginationInfo,
        exportVehicleAcType,
        isVehicleAcTypeExportError,
        isVehicleAcTypeExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleAcTypeScreen();
        fetchVehicleAcType({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleAcType(props);
      };
      const handleCreateVehicleAcType =() =>{
      navigate(APP_URLS.VEHICLE_ACTYPE.CREATE);
    }
      const editVehicleAcTypeRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_ACTYPE.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleAcType = {
  handleCreateVehicleAcType,
  exportVehicleAcType,
        isVehicleAcTypeExportError,
        isVehicleAcTypeExportSuccess,
  editVehicleAcTypeRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleAcTypes: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleAcTypeListview?.columns,
    rows: vehicleAcTypeListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleAcType
    {...vehicleAcType}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleAcTypeScreen: () => dispatch(initializeVehicleAcTypeScreen()),
      fetchVehicleAcType: (payload) => dispatch(fetchVehicleAcType(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleAcType: (payload) => dispatch(exportVehicleAcType(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleAcTypeCreateSuccess: selectFromVehicleAcTypeReducer(
        state,
        "isVehicleAcTypeCreateSuccess"
      ),
      vehicleAcTypeListview: selectFromVehicleAcTypeReducer(
        state,
        "vehicleAcTypeListview"
      ),
      showPageLevelLoader: selectFromVehicleAcTypeReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleAcTypeReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleAcTypeReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleAcTypeReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleAcTypeExportError: selectFromVehicleAcTypeReducer(
        state,
        "isVehicleAcTypeExportError"
      ),
      isVehicleAcTypeExportSuccess: selectFromVehicleAcTypeReducer(
        state,
        "isVehicleAcTypeExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleAcTypeReducer = (state, path) => {
  return selectVehicleAcTypeReducer(state)[path];
};

const selectVehicleAcTypeReducer = ({ vehicleAcTypeReducer }) => {
  return vehicleAcTypeReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleAcTypeScreen);
