import React from 'react';
import { Calendar } from 'primereact/calendar';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

const DATE_TIME_MASK = '99/99/9999 99:99';
const DATE_MASK = '99/99/9999';

const EzControlledCalendarWithFloatingLabel = (props) => {
  const {
    control,
    dateFormat,
    name,
    rules,
    labelClassName,
    label,
    maxDate,
    errorMsg,
    isError,
    showIcon,
    showButtonBar,
    showTime,
    showSeconds,
    timeOnly,
    hideOnDateTimeSelect,
    hourFormat,
    onDateChange,
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className="p-float-label">
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field }) => {
            return (
              <Calendar
                {...field}
                maxDate={maxDate}
                timeOnly={timeOnly}
                hideOnDateTimeSelect={hideOnDateTimeSelect}
                hourFormat={hourFormat}
                showTime={showTime}
                showSeconds={showSeconds}
                id={field.name}
                value={field.value}
                onChange={(e) => {
                  if (onDateChange) {
                    onDateChange(e);
                  }
                  if(e.value !== null) {
                    field.onChange(e.value);
                  }
                }}
                dateFormat={dateFormat}
                mask={showTime ? DATE_TIME_MASK : DATE_MASK}
                showIcon={showIcon}
                showButtonBar={showButtonBar}
              />
            );
          }}
        />

        <label htmlFor={name} className={getLabelClassName()}>
          {rules?.required ? `${label}*` : label}
        </label>
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledCalendarWithFloatingLabel.defaultProps = {
  labelClassName: '',
  dateFormat: 'dd/mm/yy',
  showIcon: true,
  showTime: false,
  showSeconds: false,
  hourFormat: '12',
  timeOnly: false,
  hideOnDateTimeSelect: false,
  maxDate: new Date(),
  showButtonBar: false,
};

export default EzControlledCalendarWithFloatingLabel;
