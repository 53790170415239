import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminRateTypesScreen,
  createRateAdminRateTypes,
  editRateAdminRateTypes,
  fetchRateAdminRateTypesById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminRateTypes";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminRateTypesComponent from "../../../../component/RateAdmin/RateAdminRateTypes/CreateRateAdminRateTypes";
import { useParams } from "react-router-dom";

const CreateRateAdminRateTypesContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminRateTypes,
    editRateAdminRateTypes,
    isLoading,
    isRateAdminRateTypesCreateSuccess,
    isRateAdminRateTypesCreateError,
    isRateAdminRateTypesDetailSuccess,
    isRateAdminRateTypesDetailError,
    isRateAdminRateTypesEditSuccess,
    isRateAdminRateTypesEditError,
    isEdit,
    isView,
    initCreateRateAdminRateTypesScreen,
    formFieldValueMap,
    fetchRateAdminRateTypesById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRateAdminRateTypesScreen();
   if (isEdit) {
    await fetchRateAdminRateTypesById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminRateTypesProps = {
    createRateAdminRateTypes,
    initCreateRateAdminRateTypesScreen,
    editRateAdminRateTypes,
    isLoading,
    isEdit,
    isRateAdminRateTypesDetailSuccess,
    isRateAdminRateTypesCreateSuccess,
    isRateAdminRateTypesEditSuccess,
    isRateAdminRateTypesEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRateAdminRateTypesCreateSuccess || isRateAdminRateTypesEditSuccess) {
      const toastTitle = isEdit
        ? "Rate Type Updated Successfully"
        : "Rate Type Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminRateTypesCreateError ||
      isRateAdminRateTypesDetailError ||
      isRateAdminRateTypesEditError
    ) {
      let toastTitle = "Error while Creating Rate Types";
      if (isRateAdminRateTypesEditError) {
        toastTitle = "Error while updating  Rate Types";
      } else if (isRateAdminRateTypesDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminRateTypesComponent {...createRateAdminRateTypesProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminRateTypesReducer(state, "isLoading"),
   
    isRateAdminRateTypesCreateSuccess: selectFromRateAdminRateTypesReducer(
      state,
      "isRateAdminRateTypesCreateSuccess"
    ),
    isRateAdminRateTypesCreateError: selectFromRateAdminRateTypesReducer(
      state,
      "isRateAdminRateTypesCreateError"
    ),
    isRateAdminRateTypesDetailSuccess: selectFromRateAdminRateTypesReducer(
      state,
      "isRateAdminRateTypesDetailSuccess"
    ),
    isRateAdminRateTypesDetailError: selectFromRateAdminRateTypesReducer(
      state,
      "isRateAdminRateTypesDetailError"
    ),
    isRateAdminRateTypesEditSuccess: selectFromRateAdminRateTypesReducer(
      state,
      "isRateAdminRateTypesEditSuccess"
    ),
    isRateAdminRateTypesEditError: selectFromRateAdminRateTypesReducer(
      state,
      "isRateAdminRateTypesEditError"
    ),
    imageUploadResponse: selectFromRateAdminRateTypesReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminRateTypesReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminRateTypesReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminRateTypesReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminRateTypesReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminRateTypesReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRateAdminRateTypesReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminRateTypesScreen: () => dispatch(initCreateRateAdminRateTypesScreen()),
    createRateAdminRateTypes: (payload) => dispatch(createRateAdminRateTypes(payload)),
    editRateAdminRateTypes: (payload) => dispatch(editRateAdminRateTypes(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminRateTypesById: (payload) =>
      dispatch(fetchRateAdminRateTypesById(payload)),

  };
};

const selectFromRateAdminRateTypesReducer = (state, path) => {
  return selectRateAdminRateTypesReducer(state)[path];
};

const selectRateAdminRateTypesReducer = ({ rateadminRateTypesReducer }) => {
  return rateadminRateTypesReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminRateTypesContainer);
