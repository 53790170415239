 
export const basicDetail =
[
  {
    name:"dateFrom",
     "rule": {
        "required": true
      },
    label:"Date From",
    type:"date"
  },
  {
    name:"dateTo",
     "rule": {
        "required": true
      },
    label:"Date To",
    type:"date"
  },
  {
    name:"make",
     "rule": {
        "required": true
      },
    label:"Make",
    type:"dropdown"
  },
  {
    name:"model",
     "rule": {
        "required": true
      },
    label:"Model",
    type:"dropdown"
  },
  
  {
    name:"trim",
     "rule": {
        "required": true
      },
    label:"Trim",
    type:"dropdown"
  },
  {
    name:"fkSupplierId",
     "rule": {
        "required": true
      },
    label:"Dealership",
    type:"dropdown"
  },
  {
    name:"purchaseType",
     "rule": {
        "required": true
      },
    label:"Purchase Type",
    type:"dropdown"
  },
  {
    name:"estimatedDepreciation",
     "rule": {
        "required": true
      },
    label:"Estimated Depreciation",
    type:"text"
  },
  
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const purchaseTypeDD =
[
  { id:1, name:"Owned"},
  { id:2, name:"Financed"},
  { id:3, name:"Leased"}
]

export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
