import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleDealershipVehicleDealScreen,
  createVehicleDealershipVehicleDeal,
  editVehicleDealershipVehicleDeal,
  fetchVehicleDealershipVehicleDealById,
  uploadImage,
} from "../../../../redux/actions/vehicles/vehicleDealershipVehicleDeal";
import {
  fetchVehicleMakeDD
} from "../../../../redux/actions/vehicles/vehicleMake";
import {
  fetchVehicleModelDD
} from "../../../../redux/actions/vehicles/vehicleModel";
import {
  fetchVehicleTrimDD
} from "../../../../redux/actions/vehicles/vehicleTrim";
import {
  fetchVehicleStatusDD
} from "../../../../redux/actions/vehicles/vehicleVehicleStatus";
import {
  fetchVehicleDealershipsDD
} from "../../../../redux/actions/vehicles/vehicleDealerships";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleDealershipVehicleDealComponent from "../../../../component/Vehicles/VehicleDealershipVehicleDeal/CreateVehicleDealershipVehicleDeal";
import { useParams } from "react-router-dom";

const CreateVehicleDealershipVehicleDealContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleDealershipVehicleDeal,
    editVehicleDealershipVehicleDeal,
    isLoading,
    isVehicleDealershipVehicleDealCreateSuccess,
    isVehicleDealershipVehicleDealCreateError,
    isVehicleDealershipVehicleDealDetailSuccess,
    isVehicleDealershipVehicleDealDetailError,
    isVehicleDealershipVehicleDealEditSuccess,
    isVehicleDealershipVehicleDealEditError,
    isEdit,
    isView,
    initCreateVehicleDealershipVehicleDealScreen,
    formFieldValueMap,
    fetchVehicleDealershipVehicleDealById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    fetchVehicleMakeDD,
    fetchVehicleModelDD,
    fetchVehicleTrimDD,
    fetchVehicleStatusDD,
    fetchVehicleDealershipsDD,
    fetchVehicleCategoryDD,
    fetchLocationDD,
    locationDD,
    vehicleMakeDD,
    vehicleModelDD,
    vehicleTrimDD,
    vehicleStatusDD,
    vehicleDealershipsDD,
    vehicleCategoryDD,
        isVehicleOperationDDSuccess,
        isVehicleOperationDDError,
  } = props;

  const { id } = useParams();
  const callAPIasync = async() =>{
    initCreateVehicleDealershipVehicleDealScreen();
    await fetchVehicleTrimDD();
    await fetchVehicleMakeDD();
    await fetchVehicleModelDD();
    await fetchVehicleDealershipsDD();
    await fetchVehicleStatusDD();
    await fetchVehicleCategoryDD();
    await fetchLocationDD();
   if (isEdit) {
    await fetchVehicleDealershipVehicleDealById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleDealershipVehicleDealProps = {
    createVehicleDealershipVehicleDeal,
    initCreateVehicleDealershipVehicleDealScreen,
    editVehicleDealershipVehicleDeal,
    isLoading,
    isEdit,
    isVehicleDealershipVehicleDealDetailSuccess,
    isVehicleDealershipVehicleDealCreateSuccess,
    isVehicleDealershipVehicleDealEditSuccess,
    isVehicleDealershipVehicleDealEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleMakeDD,
    vehicleModelDD,
    vehicleTrimDD,
    vehicleStatusDD,
    vehicleDealershipsDD,
    vehicleCategoryDD,
    locationDD,
        isVehicleOperationDDSuccess,
        isVehicleOperationDDError,
  };
  const getToastProps = () => {
    if (isVehicleDealershipVehicleDealCreateSuccess || isVehicleDealershipVehicleDealEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleDealershipVehicleDeal Updated Successfully"
        : "VehicleDealershipVehicleDeal Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleDealershipVehicleDealCreateError ||
      isVehicleDealershipVehicleDealDetailError ||
      isVehicleDealershipVehicleDealEditError
    ) {
      let toastTitle = "Error while Creating Vehicle DealershipVehicleDeal";
      if (isVehicleDealershipVehicleDealEditError) {
        toastTitle = "Error while updating Vehicle DealershipVehicleDeal";
      } else if (isVehicleDealershipVehicleDealDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleDealershipVehicleDealComponent {...createVehicleDealershipVehicleDealProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleDealershipVehicleDealReducer(state, "isLoading"),
   
    isVehicleDealershipVehicleDealCreateSuccess: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleDealershipVehicleDealCreateSuccess"
    ),
    isVehicleDealershipVehicleDealCreateError: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleDealershipVehicleDealCreateError"
    ),
    isVehicleDealershipVehicleDealDetailSuccess: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleDealershipVehicleDealDetailSuccess"
    ),
    isVehicleDealershipVehicleDealDetailError: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleDealershipVehicleDealDetailError"
    ),
    isVehicleDealershipVehicleDealEditSuccess: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleDealershipVehicleDealEditSuccess"
    ),
    isVehicleDealershipVehicleDealEditError: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleDealershipVehicleDealEditError"
    ),
    imageUploadResponse: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isUploadImageError"
    ),
    vehicleModelDD: selectFromVehicleVehicleModelReducer(
      state,
      "vehicleModelDD"
    ),
    vehicleTrimDD: selectFromVehicleVehicleTrimReducer(
      state,
      "vehicleTrimDD"
    ),
    
    vehicleMakeDD: selectFromVehicleVehicleMakeReducer(
      state,
      "vehicleMakeDD"
    ),

    vehicleOperationDD: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "vehicleOperationDD"
    ),
    vehicleStatusDD: selectFromVehicleVehicleStatusReducer(
      state,
      "vehicleStatusDD"
    ),
    vehicleDealershipsDD: selectFromVehicleVehicleDealershipsReducer(
      state,
      "vehicleDealershipsDD"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    isVehicleOperationDDSuccess: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleOperationDDSuccess"
    ),
    isVehicleOperationDDError: selectFromVehicleDealershipVehicleDealReducer(
      state,
      "isVehicleOperationDDError"
    ),

    formFieldValueMap: selectFromVehicleDealershipVehicleDealReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleDealershipVehicleDealScreen: () => dispatch(initCreateVehicleDealershipVehicleDealScreen()),
    createVehicleDealershipVehicleDeal: (payload) => dispatch(createVehicleDealershipVehicleDeal(payload)),
    editVehicleDealershipVehicleDeal: (payload) => dispatch(editVehicleDealershipVehicleDeal(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleDealershipVehicleDealById: (payload) => dispatch(fetchVehicleDealershipVehicleDealById(payload)),
    fetchVehicleMakeDD: (payload) => dispatch(fetchVehicleMakeDD(payload)),
    fetchVehicleModelDD: (payload) => dispatch(fetchVehicleModelDD(payload)),
    fetchVehicleTrimDD: (payload) => dispatch(fetchVehicleTrimDD(payload)),
    fetchVehicleStatusDD: (payload) => dispatch(fetchVehicleStatusDD(payload)),
    fetchVehicleDealershipsDD: (payload) => dispatch(fetchVehicleDealershipsDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
  };
};

const selectFromVehicleDealershipVehicleDealReducer = (state, path) => {
  return selectVehicleDealershipVehicleDealReducer(state)[path];
};

const selectVehicleDealershipVehicleDealReducer = ({ vehicleDealershipVehicleDealReducer }) => {
  return vehicleDealershipVehicleDealReducer;
};

//MAKE
const selectFromVehicleVehicleMakeReducer = (state, path) => {
  return selectVehicleVehicleMakeReducer(state)[path];
};
const selectVehicleVehicleMakeReducer = ({ vehicleMakeReducer }) => {
  return vehicleMakeReducer;
};
//Model
const selectFromVehicleVehicleModelReducer = (state, path) => {
  return selectVehicleVehicleModelReducer(state)[path];
};
const selectVehicleVehicleModelReducer = ({ vehicleModelReducer }) => {
  return vehicleModelReducer;
};
//Trim
const selectFromVehicleVehicleTrimReducer = (state, path) => {
  return selectVehicleVehicleTrimReducer(state)[path];
};
const selectVehicleVehicleTrimReducer = ({ vehicleTrimReducer }) => {
  return vehicleTrimReducer;
};
//Status
const selectFromVehicleVehicleStatusReducer = (state, path) => {
  return selectVehicleVehicleStatusReducer(state)[path];
};
const selectVehicleVehicleStatusReducer = ({ vehicleVehicleStatusReducer }) => {
  return vehicleVehicleStatusReducer;
};
//Dealerships
const selectFromVehicleVehicleDealershipsReducer = (state, path) => {
  return selectVehicleVehicleDealershipsReducer(state)[path];
};
const selectVehicleVehicleDealershipsReducer = ({ vehicleDealershipsReducer }) => {
  return vehicleDealershipsReducer;
};

//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleDealershipVehicleDealContainer);
