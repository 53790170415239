import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleCategory,
    initializeVehicleCategoryScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleCategory
  } from "../../../redux/actions/vehicles/vehicleCategory";
import VehicleCategory from '../../../component/Vehicles/VehicleCategory';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleCategoryScreen(props) {
    const {
        fetchVehicleCategory,
        initVehicleCategoryScreen,
        showPageLevelLoader,
        vehicleCategoryListview,
        paginationInfo,
        exportVehicleCategory,
        isVehicleCategoryExportError,
        isVehicleCategoryExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleCategoryScreen();
        fetchVehicleCategory({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleCategory(props);
      };
      const handleCreateVehicleCategory =() =>{
      navigate(APP_URLS.VEHICLE_CATEGORY.CREATE);
    }
      const editVehicleCategoryRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_CATEGORY.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleCategory = {
  handleCreateVehicleCategory,
  exportVehicleCategory,
        isVehicleCategoryExportError,
        isVehicleCategoryExportSuccess,
  editVehicleCategoryRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleCategorys: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleCategoryListview?.columns,
    rows: vehicleCategoryListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleCategory
    {...vehicleCategory}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleCategoryScreen: () => dispatch(initializeVehicleCategoryScreen()),
      fetchVehicleCategory: (payload) => dispatch(fetchVehicleCategory(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleCategory: (payload) => dispatch(exportVehicleCategory(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleCategoryCreateSuccess: selectFromVehicleCategoryReducer(
        state,
        "isVehicleCategoryCreateSuccess"
      ),
      vehicleCategoryListview: selectFromVehicleCategoryReducer(
        state,
        "vehicleCategoryListview"
      ),
      showPageLevelLoader: selectFromVehicleCategoryReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleCategoryReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleCategoryReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleCategoryReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleCategoryExportError: selectFromVehicleCategoryReducer(
        state,
        "isVehicleCategoryExportError"
      ),
      isVehicleCategoryExportSuccess: selectFromVehicleCategoryReducer(
        state,
        "isVehicleCategoryExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleCategoryReducer = (state, path) => {
  return selectVehicleCategoryReducer(state)[path];
};

const selectVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleCategoryScreen);
