import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchReservationsCRXRezpowerReservations,
    initializeReservationsCRXRezpowerReservationsScreen,
    setDeleteConfirmationPopupVisibility,
    exportReservationsCRXRezpowerReservations
  } from "../../../redux/actions/Reservations/ReservationsCRXRezpowerReservations";
  import {
    fetchLocationDD
  } from "../../../redux/actions/location";
  import {
    fetchRateAdminNetworkDD,
    } from "../../../redux/actions/RateAdmin/rateadminRates";
import ReservationsCRXRezpowerReservations from '../../../component/Reservations/ReservationsCRXRezpowerReservations';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function ReservationsCRXRezpowerReservationsScreen(props) {
    const {
        fetchReservationsCRXRezpowerReservations,
        initReservationsCRXRezpowerReservationsScreen,
        showPageLevelLoader,
        ReservationsCRXRezpowerReservationsListview,
        paginationInfo,
        exportReservationsCRXRezpowerReservations,
        isReservationsCRXRezpowerReservationsExportError,
        isReservationsCRXRezpowerReservationsExportSuccess,
        isLoading,
        fetchLocationDD,
        fetchRateAdminNetworkDD,
        RateNetworkDD,
        locationDD,
      } = props;
const navigate = useNavigate ();

const callAPIasync = async () => {
  await fetchRateAdminNetworkDD();
  await fetchLocationDD();
 
 
}


useEffect(() => {
  callAPIasync()
}, []);
      useEffect(() => {
        initReservationsCRXRezpowerReservationsScreen();
        fetchReservationsCRXRezpowerReservations({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchReservationsCRXRezpowerReservations(newProps);
      };
      const handleCreateReservationsCRXRezpowerReservations =() =>{
      navigate(APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.CREATE);
    }
      const editReservationsCRXRezpowerReservationsRoute = (id) => {
        const path = generatePath(APP_URLS.RESERVATIONS_CRXREZPOWERRESERVATIONS.EDIT, {
          id,
        });
        navigate(path);
      };
const ReservationsCRXRezpowerReservationsProps = {
  handleCreateReservationsCRXRezpowerReservations,
  exportReservationsCRXRezpowerReservations,
        isReservationsCRXRezpowerReservationsExportError,
        isReservationsCRXRezpowerReservationsExportSuccess,
  editReservationsCRXRezpowerReservationsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchReservationsCRXRezpowerReservationss: loadLazyData,
  ...companyData,
  tableData: {
    columns: ReservationsCRXRezpowerReservationsListview?.columns,
    rows: ReservationsCRXRezpowerReservationsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
  RateNetworkDD,
        locationDD,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <ReservationsCRXRezpowerReservations
    {...ReservationsCRXRezpowerReservationsProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initReservationsCRXRezpowerReservationsScreen: () => dispatch(initializeReservationsCRXRezpowerReservationsScreen()),
      fetchReservationsCRXRezpowerReservations: (payload) => dispatch(fetchReservationsCRXRezpowerReservations(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      fetchRateAdminNetworkDD: (payload) => dispatch(fetchRateAdminNetworkDD(payload)),
      fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
      exportReservationsCRXRezpowerReservations: (payload) => dispatch(exportReservationsCRXRezpowerReservations(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isReservationsCRXRezpowerReservationsCreateSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "isReservationsCRXRezpowerReservationsCreateSuccess"
      ),
      ReservationsCRXRezpowerReservationsListview: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "ReservationsCRXRezpowerReservationsListview"
      ),
      showPageLevelLoader: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "isFetchingGridData"
      ),
      RateNetworkDD: selectFromRateAdminRatesReducer(
        state,
        "RateNetworkDD"
      ),
      locationDD: selectFromLocationReducer(
        state,
        "locationDD"
      ),
      isReservationsCRXRezpowerReservationsExportError: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "isReservationsCRXRezpowerReservationsExportError"
      ),
      isReservationsCRXRezpowerReservationsExportSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
        state,
        "isReservationsCRXRezpowerReservationsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromReservationsCRXRezpowerReservationsReducer = (state, path) => {
  return selectReservationsCRXRezpowerReservationsReducer(state)[path];
};

const selectReservationsCRXRezpowerReservationsReducer = ({ ReservationsCRXRezpowerReservationsReducer }) => {
  return ReservationsCRXRezpowerReservationsReducer;
};


// For Network
const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(mapStateToProps, mapDispatchToProps)(ReservationsCRXRezpowerReservationsScreen);
