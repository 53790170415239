import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATETYPES_LIST, 
  TOAST, 
  FETCH_RATEADMIN_RATETYPES_INIT,
  FETCH_RATEADMIN_RATETYPES_DETAIL,
  EXPORT_RATEADMIN_RATETYPES,
  CREATE_RATEADMIN_RATETYPES,
  EDIT_RATEADMIN_RATETYPES,
  INIT_CREATE_RATEADMIN_RATETYPES_SCREEN,
  RATEADMIN_RATETYPESDD
} from "../../../types/rateadminratetypes.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    rateAdminRateTypesListview: [],
    RateTypeDD:[],
    isRateAdminRateTypesDDSuccess: false,
    isRateAdminRateTypesDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRateAdminRateTypesCreateSuccess: false,
    isRateAdminRateTypesCreateError: false,
    isRateAdminRateTypesDetailSuccess: false,
    isRateAdminRateTypesDetailError: false,
    isRateAdminRateTypesEditSuccess: false,
    isRateAdminRateTypesEditError: false,
    isRateAdminRateTypesExportSuccess: false,
    isRateAdminRateTypesExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Type",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminRateTypesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_RATETYPES_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_RATETYPES_DETAIL.START:
      case EXPORT_RATEADMIN_RATETYPES.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_RATETYPES_LIST.START:
      case CREATE_RATEADMIN_RATETYPES.START:
        case EDIT_RATEADMIN_RATETYPES.START:
          case RATEADMIN_RATETYPESDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_RATETYPES_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          rateAdminRateTypesListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminRateTypesCreateSuccess: false,
          isRateAdminRateTypesCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          rateAdminRateTypesListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_RATETYPES_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminRateTypesListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminRateTypesDeletionSuccess: false,
          israteadminRateTypesDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_RATETYPES_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRateAdminRateTypesDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_RATETYPES_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateTypesDetailSuccess: false,
        isRateAdminRateTypesDetailError: true,
      };
    }

    //DD
    case RATEADMIN_RATETYPESDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RateTypeDD:temp,
        isRateAdminRateTypesDDSuccess: true,
        isRateAdminRateTypesDDError: false,
      };
    }
    case RATEADMIN_RATETYPESDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminRateTypesDDSuccess: false,
        isRateAdminRateTypesDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RATEADMIN_RATETYPES.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminRateTypesExportSuccess: false,
    isRateAdminRateTypesExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_RATETYPES.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"rate-types.xls");
   return {
    ...state,
    isRateAdminRateTypesExportSuccess: true,
    isRateAdminRateTypesExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_RATETYPES.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateTypesEditSuccess: false,
    isRateAdminRateTypesEditError: true,
  };
}

case EDIT_RATEADMIN_RATETYPES.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateTypesEditSuccess: true,
    isRateAdminRateTypesEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_RATETYPES_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_RATETYPES.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminRateTypesCreateSuccess: false,
    isRateAdminRateTypesCreateError: true,
  };
}
case CREATE_RATEADMIN_RATETYPES.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminRateTypesCreateSuccess: true,
    isRateAdminRateTypesCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminRateTypesCreateSuccess: false,
          isRateAdminRateTypesCreateError: false,
          isRateAdminRateTypesEditError: false,
          isRateAdminRateTypesEditSuccess: false,
          isRateAdminRateTypesDetailError: false
        };
      }
      default:
        return state;
    }
  };
  