import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_BODYSHOP_LIST, 
  TOAST, 
  FETCH_VEHICLE_BODYSHOP_INIT,
  FETCH_VEHICLE_BODYSHOP_DETAIL,
  EXPORT_VEHICLE_BODYSHOP,
  CREATE_VEHICLE_BODYSHOP,
  EDIT_VEHICLE_BODYSHOP,
  INIT_CREATE_VEHICLE_BODYSHOP_SCREEN,
  VEHICLE_BODYSHOPDD
} from "../../../types/vehiclebodyshop.type";



  const formFieldValueMap = {
    id:null,
    name:"",
    contactPerson:"",
    contactNumber:"",
    status:1,
    emailId:"",
    faxNumber:"",
    address:"",
    userId:"",
    notes:"",
    crtBy:"",
    crtTime:"",
    modBy:"",
    modTime:"",
    ip:""
  };
  
  const INITIAL_STATE = {
    vehicleBodyShopListview: [],
    vehicleBodyShopDD:[],
    isVehicleBodyShopDDSuccess: false,
    isVehicleBodyShopDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleBodyShopCreateSuccess: false,
    isVehicleBodyShopCreateError: false,
    isVehicleBodyShopDetailSuccess: false,
    isVehicleBodyShopDetailError: false,
    isVehicleBodyShopEditSuccess: false,
    isVehicleBodyShopEditError: false,
    isVehicleBodyShopExportSuccess: false,
    isVehicleBodyShopExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
        crtTime,
        id,
        modBy,
        modTime,
        name,
        contactPerson,
        contactNumber,
        status,
        emailId,
        faxNumber,
        address,
        userId,
        notes
      } = item;
  
      const transformedValues = {
        crtBy,
        crtTime,
        id,
        modBy,
        modTime,
        name,
        contactPerson,
        contactNumber,
        status,
        emailId,
        faxNumber,
        address,
        userId,
        notes
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Dealership Name",
        dataKey: "name",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Contact Person",
        dataKey: "contactPerson",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Contact Number",
        dataKey: "contactNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Address",
        dataKey: "address",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Email Id",
        dataKey: "emailId",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Fax Number",
        dataKey: "faxNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Notes",
        dataKey: "notes",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },


      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleBodyShopReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_BODYSHOP_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_BODYSHOP_DETAIL.START:
      case EXPORT_VEHICLE_BODYSHOP.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_BODYSHOP_LIST.START:
      case CREATE_VEHICLE_BODYSHOP.START:
        case EDIT_VEHICLE_BODYSHOP.START:
          case VEHICLE_BODYSHOPDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_BODYSHOP_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleBodyShopListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleBodyShopCreateSuccess: false,
          isVehicleBodyShopCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleBodyShopListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_BODYSHOP_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleBodyShopListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleBodyShopDeletionSuccess: false,
          isvehicleBodyShopDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_BODYSHOP_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleBodyShopDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_BODYSHOP_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleBodyShopDetailSuccess: false,
        isVehicleBodyShopDetailError: true,
      };
    }

    //DD
    case VEHICLE_BODYSHOPDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        vehicleBodyShopDD:temp,
        isVehicleBodyShopDDSuccess: true,
        isVehicleBodyShopDDError: false,
      };
    }
    case VEHICLE_BODYSHOPDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleBodyShopDDSuccess: false,
    isVehicleBodyShopDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_BODYSHOP.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleBodyShopExportSuccess: false,
    isVehicleBodyShopExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_BODYSHOP.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-body-shop.xls");
   return {
    ...state,
    isVehicleBodyShopExportSuccess: true,
    isVehicleBodyShopExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_BODYSHOP.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleBodyShopEditSuccess: false,
    isVehicleBodyShopEditError: true,
  };
}

case EDIT_VEHICLE_BODYSHOP.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleBodyShopEditSuccess: true,
    isVehicleBodyShopEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_BODYSHOP_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_BODYSHOP.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleBodyShopCreateSuccess: false,
    isVehicleBodyShopCreateError: true,
  };
}
case CREATE_VEHICLE_BODYSHOP.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleBodyShopCreateSuccess: true,
    isVehicleBodyShopCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleBodyShopCreateSuccess: false,
          isVehicleBodyShopCreateError: false,
          isVehicleBodyShopEditError: false,
          isVehicleBodyShopEditSuccess: false,
          isVehicleBodyShopDetailError: false
        };
      }
      default:
        return state;
    }
  };
  