import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleDealershipVehicleDeal,
    initializeVehicleDealershipVehicleDealScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleDealershipVehicleDeal
  } from "../../../redux/actions/vehicles/vehicleDealershipVehicleDeal";
import VehicleDealershipVehicleDeal from '../../../component/Vehicles/VehicleDealershipVehicleDeal';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleDealershipVehicleDealScreen(props) {
    const {
        fetchVehicleDealershipVehicleDeal,
        initVehicleDealershipVehicleDealScreen,
        showPageLevelLoader,
        vehicleDealershipVehicleDealListview,
        paginationInfo,
        exportVehicleDealershipVehicleDeal,
        isVehicleDealershipVehicleDealExportError,
        isVehicleDealershipVehicleDealExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleDealershipVehicleDealScreen();
        fetchVehicleDealershipVehicleDeal({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleDealershipVehicleDeal(props);
      };
      const handleCreateVehicleDealershipVehicleDeal =() =>{
      navigate(APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.CREATE);
    }
      const editVehicleDealershipVehicleDealRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_DEALERSHIPVEHICLEDEAL.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleDealershipVehicleDeal = {
  handleCreateVehicleDealershipVehicleDeal,
  exportVehicleDealershipVehicleDeal,
        isVehicleDealershipVehicleDealExportError,
        isVehicleDealershipVehicleDealExportSuccess,
  editVehicleDealershipVehicleDealRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleDealershipVehicleDeals: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleDealershipVehicleDealListview?.columns,
    rows: vehicleDealershipVehicleDealListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleDealershipVehicleDeal
    {...vehicleDealershipVehicleDeal}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleDealershipVehicleDealScreen: () => dispatch(initializeVehicleDealershipVehicleDealScreen()),
      fetchVehicleDealershipVehicleDeal: (payload) => dispatch(fetchVehicleDealershipVehicleDeal(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleDealershipVehicleDeal: (payload) => dispatch(exportVehicleDealershipVehicleDeal(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleDealershipVehicleDealCreateSuccess: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "isVehicleDealershipVehicleDealCreateSuccess"
      ),
      vehicleDealershipVehicleDealListview: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "vehicleDealershipVehicleDealListview"
      ),
      showPageLevelLoader: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleDealershipVehicleDealExportError: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "isVehicleDealershipVehicleDealExportError"
      ),
      isVehicleDealershipVehicleDealExportSuccess: selectFromVehicleDealershipVehicleDealReducer(
        state,
        "isVehicleDealershipVehicleDealExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleDealershipVehicleDealReducer = (state, path) => {
  return selectVehicleDealershipVehicleDealReducer(state)[path];
};

const selectVehicleDealershipVehicleDealReducer = ({ vehicleDealershipVehicleDealReducer }) => {
  return vehicleDealershipVehicleDealReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleDealershipVehicleDealScreen);
