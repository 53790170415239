import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRateAdminBlackoutsScreen,
  createRateAdminBlackouts,
  editRateAdminBlackouts,
  fetchRateAdminBlackoutsById,
  uploadImage,
} from "../../../../redux/actions/RateAdmin/rateadminBlackouts";

import {
  fetchRateAdminNetworkDD,
} from "../../../../redux/actions/RateAdmin/rateadminRates";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import {
  fetchLocationDD,
  fetchCategory
} from "../../../../redux/actions/location";
import {
  fetchRateAdminRateCodeDD
} from "../../../../redux/actions/RateAdmin/rateadminRateCode";

import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRateAdminBlackoutsComponent from "../../../../component/RateAdmin/RateAdminBlackouts/CreateRateAdminBlackouts";
import { useParams } from "react-router-dom";

const CreateRateAdminBlackoutsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRateAdminBlackouts,
    editRateAdminBlackouts,
    isLoading,
    isRateAdminBlackoutsCreateSuccess,
    isRateAdminBlackoutsCreateError,
    isRateAdminBlackoutsDetailSuccess,
    isRateAdminBlackoutsDetailError,
    isRateAdminBlackoutsEditSuccess,
    isRateAdminBlackoutsEditError,
    isEdit,
    isView,
    initCreateRateAdminBlackoutsScreen,
    formFieldValueMap,
    fetchRateAdminBlackoutsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        RateCodeDD,
        vehicleCategoryDD,
        fetchVehicleCategoryDD,
        fetchLocationDD,
  fetchCategory,

        fetchRateAdminRateCodeDD,
        fetchRateAdminNetworkDD,
        RateNetworkDD,
        categoryDD
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    await fetchVehicleCategoryDD();
    await fetchLocationDD();
    await fetchCategory();
    await fetchRateAdminRateCodeDD();
    await fetchRateAdminNetworkDD();
    initCreateRateAdminBlackoutsScreen();
   if (isEdit) {
    await fetchRateAdminBlackoutsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRateAdminBlackoutsProps = {
    createRateAdminBlackouts,
    initCreateRateAdminBlackoutsScreen,
    editRateAdminBlackouts,
    isLoading,
    isEdit,
    isRateAdminBlackoutsDetailSuccess,
    isRateAdminBlackoutsCreateSuccess,
    isRateAdminBlackoutsEditSuccess,
    isRateAdminBlackoutsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        RateCodeDD,
        vehicleCategoryDD,
        RateNetworkDD,
        categoryDD
  };
  const getToastProps = () => {
    if (isRateAdminBlackoutsCreateSuccess || isRateAdminBlackoutsEditSuccess) {
      const toastTitle = isEdit
        ? "Blackouts Updated Successfully"
        : "Blackouts Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRateAdminBlackoutsCreateError ||
      isRateAdminBlackoutsDetailError ||
      isRateAdminBlackoutsEditError
    ) {
      let toastTitle = "Error while Creating Rate Plan";
      if (isRateAdminBlackoutsEditError) {
        toastTitle = "Error while updating Rate Plan";
      } else if (isRateAdminBlackoutsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRateAdminBlackoutsComponent {...createRateAdminBlackoutsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRateAdminBlackoutsReducer(state, "isLoading"),
   
    isRateAdminBlackoutsCreateSuccess: selectFromRateAdminBlackoutsReducer(
      state,
      "isRateAdminBlackoutsCreateSuccess"
    ),
    isRateAdminBlackoutsCreateError: selectFromRateAdminBlackoutsReducer(
      state,
      "isRateAdminBlackoutsCreateError"
    ),
    isRateAdminBlackoutsDetailSuccess: selectFromRateAdminBlackoutsReducer(
      state,
      "isRateAdminBlackoutsDetailSuccess"
    ),
    isRateAdminBlackoutsDetailError: selectFromRateAdminBlackoutsReducer(
      state,
      "isRateAdminBlackoutsDetailError"
    ),
    isRateAdminBlackoutsEditSuccess: selectFromRateAdminBlackoutsReducer(
      state,
      "isRateAdminBlackoutsEditSuccess"
    ),
    isRateAdminBlackoutsEditError: selectFromRateAdminBlackoutsReducer(
      state,
      "isRateAdminBlackoutsEditError"
    ),
    imageUploadResponse: selectFromRateAdminBlackoutsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRateAdminBlackoutsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRateAdminBlackoutsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRateAdminBlackoutsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRateAdminBlackoutsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRateAdminBlackoutsReducer(
      state,
      "isVehicleModelDDError"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    categoryDD: selectFromLocationReducer(
      state,
      "categoryDD"
    ),
    
    RateCodeDD: selectFromRateCodeReducer(
      state,
      "RateCodeDD"
    ),
    RateNetworkDD: selectFromRateAdminRatesReducer(
      state,
      "RateNetworkDD"
    ),
    formFieldValueMap: selectFromRateAdminBlackoutsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRateAdminBlackoutsScreen: () => dispatch(initCreateRateAdminBlackoutsScreen()),
    createRateAdminBlackouts: (payload) => dispatch(createRateAdminBlackouts(payload)),
    editRateAdminBlackouts: (payload) => dispatch(editRateAdminBlackouts(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchCategory: (payload) => dispatch(fetchCategory(payload)),
    fetchRateAdminRateCodeDD: (payload) => dispatch(fetchRateAdminRateCodeDD(payload)),
    fetchRateAdminNetworkDD: (payload) => dispatch(fetchRateAdminNetworkDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRateAdminBlackoutsById: (payload) =>
      dispatch(fetchRateAdminBlackoutsById(payload)),

  };
};

const selectFromRateAdminBlackoutsReducer = (state, path) => {
  return selectRateAdminBlackoutsReducer(state)[path];
};

const selectRateAdminBlackoutsReducer = ({ rateadminBlackoutsReducer }) => {
  return rateadminBlackoutsReducer;
};
//For Network Rate form
const selectFromRateAdminRatesReducer = (state, path) => {
  return selectRateAdminRatesReducer(state)[path];
};

const selectRateAdminRatesReducer = ({ rateadminRatesReducer }) => {
  return rateadminRatesReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//RateCode
const selectFromRateCodeReducer = (state, path) => {
  return selectRateCodeReducer(state)[path];
};
const selectRateCodeReducer = ({ rateadminRateCodeReducer }) => {
  return rateadminRateCodeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRateAdminBlackoutsContainer);
