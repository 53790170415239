export const basicDetail =
[
  {
    name:"startDate",
     "rule": {
        "required": true
      },
    label:"Start Date",
    type:"date"
  },
  {
    name:"endDate",
     "rule": {
        "required": true
      },
    label:"End Date",
    type:"date"
  },
  {
    name:"type",
     "rule": {
        "required": true
      },
    label:"Type",
    type:"dropdown"
  },
  {
    name:"amount",
     "rule": {
        "required": true
      },
    label:"Amount",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const typeDD =
[
  { id:1, name:"Flat"},
  { id:2, name:"Percentage"}
]
export const paymentStatusDD =
[
  { id:1, name:"Paid"},
  { id:2, name:"Not Paid"},
  { id:6, name:"Paid not verified"},
  { id:4, name:"Paid on Reception"},
  { id:5, name:"Refunded"},
  { id:2, name:"Not Paid"}
]
export const cancelResonsDD =
[
  { id:1, name:"Change of plans"},
  { id:2, name:"Getting a better deal"},
  { id:3, name:"Related to terms & conditions"},
  { id:4, name:"Poor Support"},
  { id:5, name:"Due to reviews"},
  { id:6, name:"Other"},
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
  export const RentersRentersInfoBreadcrumb = [
    {label:"Dashboard", path:"/dashboard"},
    {label:"Registered Renters", path:"/registered-renters"},
    {label:"Renters Info", path:"/renters-info"},
    {label:"Revenue Agreements", path:"/revenue-agreements"},
    {label:"Non Revenue Agreements", path:"/non-revenue-agreements"},
    {label:"Vehicle Inquiries", path:"/vehicle-inquiries"},
    {label:"Renter Log Interactions", path:"/renter-log-interactions"},
    {label:"", path:"/renter-log-interactions"}
]