

export const FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST = {
    START: 'FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST_START',
    SUCCESS: 'FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST_SUCCESS',
    ERROR: 'FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_INIT="FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SCREEN =
'INIT_CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SCREEN';

export const CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT = {
START: 'CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_START',
SUCCESS: 'CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_SUCCESS',
ERROR: 'CREATE_RESERVATIONS_PREPAIDBOOKINGSREPORT_ERROR',
};

export const EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT = {
START: 'EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT_START',
SUCCESS: 'EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT_SUCCESS',
ERROR: 'EDIT_RESERVATIONS_PREPAIDBOOKINGSREPORT_ERROR',
};

export const FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL = {
  START: 'FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL_START',
  SUCCESS: 'FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL_SUCCESS',
  ERROR: 'FETCH_RESERVATIONS_PREPAIDBOOKINGSREPORT_DETAIL_ERROR',
};
export const EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT = {
  START: 'EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT_START',
  SUCCESS: 'EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT_SUCCESS',
  ERROR: 'EXPORT_RESERVATIONS_PREPAIDBOOKINGSREPORT_ERROR',
};
export const RESERVATIONS_PREPAIDBOOKINGSREPORTDD = {
  START: 'RESERVATIONS_PREPAIDBOOKINGSREPORTDD_START',
  SUCCESS: 'RESERVATIONS_PREPAIDBOOKINGSREPORTDD_SUCCESS',
  ERROR: 'RESERVATIONS_PREPAIDBOOKINGSREPORTDD_ERROR',
};
export const RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE = {
  START: 'RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE_START',
  SUCCESS: 'RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE_SUCCESS',
  ERROR: 'RESERVATIONS_PREPAIDBOOKINGSREPORTDELETE_ERROR',
};

