import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_RESERVATIONS_LIST, 
  TOAST, 
  FETCH_RESERVATIONS_RESERVATIONS_INIT,
  FETCH_RESERVATIONS_RESERVATIONS_DETAIL,
  EXPORT_RESERVATIONS_RESERVATIONS,
  CREATE_RESERVATIONS_RESERVATIONS,
  EDIT_RESERVATIONS_RESERVATIONS,
  INIT_CREATE_RESERVATIONS_RESERVATIONS_SCREEN,
  RESERVATIONS_RESERVATIONSDD,
  RESERVATIONS_RESERVATIONSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Reservations/Reservations.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    ReservationsReservationsListview: [],
    ReservationsReservationsDD:[],
    isReservationsReservationsDDSuccess: false,
    isReservationsReservationsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isReservationsReservationsCreateSuccess: false,
    isReservationsReservationsCreateError: false,
    isReservationsReservationsDetailSuccess: false,
    isReservationsReservationsDetailError: false,
    isReservationsReservationsEditSuccess: false,
    isReservationsReservationsEditError: false,
    isReservationsReservationsExportSuccess: false,
    isReservationsReservationsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isReservationsReservationsDeleteSuccess: false,
    isReservationsReservationsDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network Booking",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Network",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Locaiton",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Vehicle",
        dataKey: "vehicle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Booking Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Pickup Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },





      {
        colLabel: "Dropoff Date",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Days",
        dataKey: "days",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Total",
        dataKey: "total",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Currency",
        dataKey: "currency",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Name",
        dataKey: "name",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Payment Status",
        dataKey: "paymentStatus",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Type",
        dataKey: "rateType",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const ReservationsReservationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RESERVATIONS_RESERVATIONS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RESERVATIONS_RESERVATIONS_DETAIL.START:
      case EXPORT_RESERVATIONS_RESERVATIONS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RESERVATIONS_RESERVATIONS_LIST.START:
      case CREATE_RESERVATIONS_RESERVATIONS.START:
        case RESERVATIONS_RESERVATIONSDELETE.START:
        case EDIT_RESERVATIONS_RESERVATIONS.START:
          case RESERVATIONS_RESERVATIONSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RESERVATIONS_RESERVATIONS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          ReservationsReservationsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isReservationsReservationsCreateSuccess: false,
          isReservationsReservationsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          ReservationsReservationsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RESERVATIONS_RESERVATIONS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          ReservationsReservationsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isReservationsReservationsDeletionSuccess: false,
          isReservationsReservationsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RESERVATIONS_RESERVATIONS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReservationsReservationsDetailSuccess: true,
      };
    }
    case FETCH_RESERVATIONS_RESERVATIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsReservationsDetailSuccess: false,
        isReservationsReservationsDetailError: true,
      };
    }

    //DD
    case RESERVATIONS_RESERVATIONSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        ReservationsReservationsDD:temp,
        isReservationsReservationsDDSuccess: true,
        isReservationsReservationsDDError: false,
      };
    }
    case RESERVATIONS_RESERVATIONSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReservationsReservationsDDSuccess: false,
        isReservationsReservationsDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isReservationsReservationsDeleteSuccess: false,
    isReservationsReservationsDeleteError: false,
  };
}
case RESERVATIONS_RESERVATIONSDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isReservationsReservationsDeleteSuccess: true,
    isReservationsReservationsDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RESERVATIONS_RESERVATIONSDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isReservationsReservationsDeleteSuccess: false,
    isReservationsReservationsDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RESERVATIONS_RESERVATIONS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isReservationsReservationsExportSuccess: false,
    isReservationsReservationsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RESERVATIONS_RESERVATIONS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"reservations.xls");
   return {
    ...state,
    isReservationsReservationsExportSuccess: true,
    isReservationsReservationsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RESERVATIONS_RESERVATIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsReservationsEditSuccess: false,
    isReservationsReservationsEditError: true,
  };
}

case EDIT_RESERVATIONS_RESERVATIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsReservationsEditSuccess: true,
    isReservationsReservationsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RESERVATIONS_RESERVATIONS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RESERVATIONS_RESERVATIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isReservationsReservationsCreateSuccess: false,
    isReservationsReservationsCreateError: true,
  };
}
case CREATE_RESERVATIONS_RESERVATIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isReservationsReservationsCreateSuccess: true,
    isReservationsReservationsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isReservationsReservationsCreateSuccess: false,
          isReservationsReservationsCreateError: false,
          isReservationsReservationsEditError: false,
          isReservationsReservationsEditSuccess: false,
          isReservationsReservationsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  