import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminRateTypesServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATETYPES_INIT, 
  FETCH_RATEADMIN_RATETYPES_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_RATETYPES_SCREEN,
  CREATE_RATEADMIN_RATETYPES,
  EDIT_RATEADMIN_RATETYPES,
  TOAST,
  FETCH_RATEADMIN_RATETYPES_DETAIL,
  EXPORT_RATEADMIN_RATETYPES,
  RATEADMIN_RATETYPESDD
} from "../../../types/rateadminratetypes.type";
export const initializeRateAdminRateTypesScreen = () => ({ type: FETCH_RATEADMIN_RATETYPES_INIT });

export const rateadminRateTypesFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATETYPES_LIST.START,
  payload,
});

export const rateadminRateTypesFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_RATETYPES_LIST.SUCCESS,
  payload,
});

export const rateadminRateTypesFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATETYPES_LIST.ERROR,
  payload,
});

export const fetchRateAdminRateTypes = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateTypesFetchStart(payload));
    rateAdminRateTypesServices.fetchRateAdminRateTypes(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateTypesFetchSuccess(data));
      } else {
        dispatch(rateadminRateTypesFetchError(data));
      }
    });
  };
};



// DD
export const rateadminRateTypesDDFetchStart = (payload) => ({
  type: RATEADMIN_RATETYPESDD.START,
  payload,
});

export const rateadminRateTypesDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATETYPESDD.SUCCESS,
  payload,
});

export const rateadminRateTypesDDFetchError = (payload) => ({
  type: RATEADMIN_RATETYPESDD.ERROR,
  payload,
});

export const fetchRateAdminRateTypesDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateTypesDDFetchStart(payload));
    rateAdminRateTypesServices.fetchRateAdminRateTypesDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateTypesDDFetchSuccess(data));
      } else {
        dispatch(rateadminRateTypesDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminRateTypesDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATETYPES_DETAIL.START,
  payload,
});

export const rateadminRateTypesDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATETYPES_DETAIL.SUCCESS,
  payload,
});

export const rateadminRateTypesDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATETYPES_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminRateTypesById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateTypesDetailFetchStart(payload));

    rateAdminRateTypesServices
      .fetchRateAdminRateTypesById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateTypesDetailFetchSuccess(data));
        } else {
          dispatch(rateadminRateTypesDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminRateTypesServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminRateTypesScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_RATETYPES_SCREEN,
  payload,
});

export const initCreateRateAdminRateTypesScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateTypesScreenInitStart());
  };
};


export const createRateAdminRateTypesStart = () => ({
  type: CREATE_RATEADMIN_RATETYPES.START,
});

export const createRateAdminRateTypesSuccess = (payload) => ({
  type: CREATE_RATEADMIN_RATETYPES.SUCCESS,
  payload,
});

export const createRateAdminRateTypesError = (payload) => ({
  type: CREATE_RATEADMIN_RATETYPES.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminRateTypes = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateTypesStart());
    rateAdminRateTypesServices.createRateAdminRateTypes(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminRateTypesSuccess());
        goBack();
      } else {
        dispatch(createRateAdminRateTypesError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminRateTypesStart = (payload) => ({
  type: EDIT_RATEADMIN_RATETYPES.START,
  payload
});

export const editRateAdminRateTypesSuccess = (payload) => ({
  type: EDIT_RATEADMIN_RATETYPES.SUCCESS,
  payload,
});

export const editRateAdminRateTypesError = (payload) => ({
  type: EDIT_RATEADMIN_RATETYPES.ERROR,
  payload,
});

export const editRateAdminRateTypes = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminRateTypesStart());
    rateAdminRateTypesServices.editRateAdminRateTypes(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminRateTypesSuccess());
        goBack();
      } else {
        dispatch(editRateAdminRateTypesError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminRateTypesRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_RATETYPES.START,
  payload
});

export const exportRateAdminRateTypesRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_RATETYPES.SUCCESS,
  payload,
});

export const exportRateAdminRateTypesRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_RATETYPES.ERROR,
  payload,
});

export const exportRateAdminRateTypes = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminRateTypesRecordStart());
    rateAdminRateTypesServices.exportRateAdminRateTypesRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminRateTypesRecordSuccess(data));
      } else {
        dispatch(exportRateAdminRateTypesRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

