import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateSettingsSettingWeekendsScreen,
  createSettingsSettingWeekends,
  editSettingsSettingWeekends,
  fetchSettingsSettingWeekendsById,
  uploadImage,
} from "../../../../redux/actions/Settings/SettingsSettingWeekends";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateSettingsSettingWeekendsComponent from "../../../../component/Settings/SettingsSettingWeekends/CreateSettingsSettingWeekends";
import { useParams } from "react-router-dom";

const CreateSettingsSettingWeekendsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createSettingsSettingWeekends,
    editSettingsSettingWeekends,
    isLoading,
    isSettingsSettingWeekendsCreateSuccess,
    isSettingsSettingWeekendsCreateError,
    isSettingsSettingWeekendsDetailSuccess,
    isSettingsSettingWeekendsDetailError,
    isSettingsSettingWeekendsEditSuccess,
    isSettingsSettingWeekendsEditError,
    isEdit,
    isView,
    initCreateSettingsSettingWeekendsScreen,
    formFieldValueMap,
    fetchSettingsSettingWeekendsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateSettingsSettingWeekendsScreen();
   if (isEdit) {
    await fetchSettingsSettingWeekendsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createSettingsSettingWeekendsProps = {
    createSettingsSettingWeekends,
    initCreateSettingsSettingWeekendsScreen,
    editSettingsSettingWeekends,
    isLoading,
    isEdit,
    isSettingsSettingWeekendsDetailSuccess,
    isSettingsSettingWeekendsCreateSuccess,
    isSettingsSettingWeekendsEditSuccess,
    isSettingsSettingWeekendsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isSettingsSettingWeekendsCreateSuccess || isSettingsSettingWeekendsEditSuccess) {
      const toastTitle = isEdit
        ? "Setting Weekends Updated Successfully"
        : "Setting Weekends Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isSettingsSettingWeekendsCreateError ||
      isSettingsSettingWeekendsDetailError ||
      isSettingsSettingWeekendsEditError
    ) {
      let toastTitle = "Error while Creating Setting Weekends";
      if (isSettingsSettingWeekendsEditError) {
        toastTitle = "Error while updating  Setting Weekends";
      } else if (isSettingsSettingWeekendsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateSettingsSettingWeekendsComponent {...createSettingsSettingWeekendsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromSettingsSettingWeekendsReducer(state, "isLoading"),
   
    isSettingsSettingWeekendsCreateSuccess: selectFromSettingsSettingWeekendsReducer(
      state,
      "isSettingsSettingWeekendsCreateSuccess"
    ),
    isSettingsSettingWeekendsCreateError: selectFromSettingsSettingWeekendsReducer(
      state,
      "isSettingsSettingWeekendsCreateError"
    ),
    isSettingsSettingWeekendsDetailSuccess: selectFromSettingsSettingWeekendsReducer(
      state,
      "isSettingsSettingWeekendsDetailSuccess"
    ),
    isSettingsSettingWeekendsDetailError: selectFromSettingsSettingWeekendsReducer(
      state,
      "isSettingsSettingWeekendsDetailError"
    ),
    isSettingsSettingWeekendsEditSuccess: selectFromSettingsSettingWeekendsReducer(
      state,
      "isSettingsSettingWeekendsEditSuccess"
    ),
    isSettingsSettingWeekendsEditError: selectFromSettingsSettingWeekendsReducer(
      state,
      "isSettingsSettingWeekendsEditError"
    ),
    imageUploadResponse: selectFromSettingsSettingWeekendsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromSettingsSettingWeekendsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromSettingsSettingWeekendsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromSettingsSettingWeekendsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromSettingsSettingWeekendsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromSettingsSettingWeekendsReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromSettingsSettingWeekendsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateSettingsSettingWeekendsScreen: () => dispatch(initCreateSettingsSettingWeekendsScreen()),
    createSettingsSettingWeekends: (payload) => dispatch(createSettingsSettingWeekends(payload)),
    editSettingsSettingWeekends: (payload) => dispatch(editSettingsSettingWeekends(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchSettingsSettingWeekendsById: (payload) =>
      dispatch(fetchSettingsSettingWeekendsById(payload)),

  };
};

const selectFromSettingsSettingWeekendsReducer = (state, path) => {
  return selectSettingsSettingWeekendsReducer(state)[path];
};

const selectSettingsSettingWeekendsReducer = ({ SettingsSettingWeekendsReducer }) => {
  return SettingsSettingWeekendsReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSettingsSettingWeekendsContainer);
