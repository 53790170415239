import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_DEALERSHIPS_LIST, 
  TOAST, 
  FETCH_VEHICLE_DEALERSHIPS_INIT,
  FETCH_VEHICLE_DEALERSHIPS_DETAIL,
  EXPORT_VEHICLE_DEALERSHIPS,
  CREATE_VEHICLE_DEALERSHIPS,
  EDIT_VEHICLE_DEALERSHIPS,
  INIT_CREATE_VEHICLE_DEALERSHIPS_SCREEN,
  VEHICLE_DEALERSHIPSDD
} from "../../../types/vehicledealerships.type";


  const formFieldValueMap = {
    id:null,
    name:"",
    contactPerson:"",
    contactNumber:"",
    status:1,
    emailId:"",
    faxNumber:"",
    address:"",
    userId:"",
    notes:"",
    crtBy:"",
    crtTime:"",
    modBy:"",
    modTime:"",
    ip:""
  };
  
  const INITIAL_STATE = {
    vehicleDealershipsListview: [],
    vehicleDealershipsDD:[],
    isVehicleDealershipsDDSuccess: false,
    isVehicleDealershipsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleDealershipsCreateSuccess: false,
    isVehicleDealershipsCreateError: false,
    isVehicleDealershipsDetailSuccess: false,
    isVehicleDealershipsDetailError: false,
    isVehicleDealershipsEditSuccess: false,
    isVehicleDealershipsEditError: false,
    isVehicleDealershipsExportSuccess: false,
    isVehicleDealershipsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
id,
modBy,
modTime,
name,
contactPerson,
contactNumber,
status,
emailId,
faxNumber,
address,
userId,
notes

      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
id,
modBy,
modTime,
name,
contactPerson,
contactNumber,
status,
emailId,
faxNumber,
address,
userId,
notes
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Dealership Name",
        dataKey: "name",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Contact Person",
        dataKey: "contactPerson",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Contact Number",
        dataKey: "contactNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Address",
        dataKey: "address",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Email Id",
        dataKey: "emailId",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Fax Number",
        dataKey: "faxNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },

      {
        colLabel: "Notes",
        dataKey: "notes",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },


      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleDealershipsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_DEALERSHIPS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_DEALERSHIPS_DETAIL.START:
      case EXPORT_VEHICLE_DEALERSHIPS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_DEALERSHIPS_LIST.START:
      case CREATE_VEHICLE_DEALERSHIPS.START:
        case EDIT_VEHICLE_DEALERSHIPS.START:
          case VEHICLE_DEALERSHIPSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_DEALERSHIPS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleDealershipsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleDealershipsCreateSuccess: false,
          isVehicleDealershipsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleDealershipsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_DEALERSHIPS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleDealershipsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleDealershipsDeletionSuccess: false,
          isvehicleDealershipsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_DEALERSHIPS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleDealershipsDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_DEALERSHIPS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleDealershipsDetailSuccess: false,
        isVehicleDealershipsDetailError: true,
      };
    }

    //DD
    case VEHICLE_DEALERSHIPSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        vehicleDealershipsDD:temp,
        isVehicleDealershipsDDSuccess: true,
        isVehicleDealershipsDDError: false,
      };
    }
    case VEHICLE_DEALERSHIPSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleDealershipsDDSuccess: false,
    isVehicleDealershipsDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_DEALERSHIPS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleDealershipsExportSuccess: false,
    isVehicleDealershipsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_DEALERSHIPS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-make.xls");
   return {
    ...state,
    isVehicleDealershipsExportSuccess: true,
    isVehicleDealershipsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_DEALERSHIPS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleDealershipsEditSuccess: false,
    isVehicleDealershipsEditError: true,
  };
}

case EDIT_VEHICLE_DEALERSHIPS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleDealershipsEditSuccess: true,
    isVehicleDealershipsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_DEALERSHIPS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_DEALERSHIPS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleDealershipsCreateSuccess: false,
    isVehicleDealershipsCreateError: true,
  };
}
case CREATE_VEHICLE_DEALERSHIPS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleDealershipsCreateSuccess: true,
    isVehicleDealershipsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleDealershipsCreateSuccess: false,
          isVehicleDealershipsCreateError: false,
          isVehicleDealershipsEditError: false,
          isVehicleDealershipsEditSuccess: false,
          isVehicleDealershipsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  