import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import styleCss from './style.module.css'
import { useNavigate } from 'react-router-dom'
import PageTitle from '../../common/PageTitle';
import KPIData from './KPI';
import Widget from './Chart';
import Comingsoonpage from '../../common/Comingsoon';

export default function Dashboard() {
    const navigate = useNavigate();
    let token = localStorage.getItem("token");
    if (
        token==="" ||
        token== null ||
        token === undefined
      ) {
        return navigate("/#/")
      }
const heading={
    title:"Dashboard"
}

    return (
        <div>
            <PageTitle props={heading} />

           {/* <Container maxWidth="lg" className={styleCss.container}>
<KPIData />
<Box display={"flex"} className={styleCss.chartdata}>
<Box width={"33%"} className={styleCss.cbox}>
    <Typography variant='h5' className={styleCss.dtitle}>Most Reserved Cateogories</Typography>
    <Typography className={styleCss.dstitle}>Top three Cateogories Reserved</Typography>
    <Typography marginTop={"30x"} className={styleCss.dsmitle}fontWeight={"bold"}>June</Typography>
    <Widget />
</Box>
<Box width={"33%"} className={styleCss.cbox}>
<Typography variant='h5' className={styleCss.dtitle}>Most Reserved Cateogories</Typography>
    <Typography className={styleCss.dstitle}>Top three Cateogories Reserved</Typography>
    <Typography marginTop={"30x"} className={styleCss.dsmitle}fontWeight={"bold"}>May</Typography>
    <Widget />
</Box>
<Box width={"33%"} className={styleCss.cbox}>
<Typography variant='h5' className={styleCss.dtitle}>Most Reserved Cateogories</Typography>
    <Typography className={styleCss.dstitle}>Top three Cateogories Reserved</Typography>
    <Typography marginTop={"30x"} className={styleCss.dsmitle}fontWeight={"bold"}>April</Typography>
    <Widget />
</Box>
</Box>
            </Container> */}

<Comingsoonpage />
        </div>
    )
}
