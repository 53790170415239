import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchSettingsPaynowDiscounts,
    initializeSettingsPaynowDiscountsScreen,
    setDeleteConfirmationPopupVisibility,
    exportSettingsPaynowDiscounts
  } from "../../../redux/actions/Settings/SettingsPaynowDiscounts";
import SettingsPaynowDiscounts from '../../../component/Settings/SettingsPaynowDiscounts';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function SettingsPaynowDiscountsScreen(props) {
    const {
        fetchSettingsPaynowDiscounts,
        initSettingsPaynowDiscountsScreen,
        showPageLevelLoader,
        SettingsPaynowDiscountsListview,
        paginationInfo,
        exportSettingsPaynowDiscounts,
        isSettingsPaynowDiscountsExportError,
        isSettingsPaynowDiscountsExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initSettingsPaynowDiscountsScreen();
        fetchSettingsPaynowDiscounts({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props
        }
        fetchSettingsPaynowDiscounts(newProps);
      };
      const handleCreateSettingsPaynowDiscounts =() =>{
      navigate(APP_URLS.SETTINGS_PAYNOWDISCOUNTS.CREATE);
    }
      const editSettingsPaynowDiscountsRoute = (id) => {
        const path = generatePath(APP_URLS.SETTINGS_PAYNOWDISCOUNTS.EDIT, {
          id,
        });
        navigate(path);
      };
const SettingsPaynowDiscountsProps = {
  handleCreateSettingsPaynowDiscounts,
  exportSettingsPaynowDiscounts,
        isSettingsPaynowDiscountsExportError,
        isSettingsPaynowDiscountsExportSuccess,
  editSettingsPaynowDiscountsRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchSettingsPaynowDiscountss: loadLazyData,
  ...companyData,
  tableData: {
    columns: SettingsPaynowDiscountsListview?.columns,
    rows: SettingsPaynowDiscountsListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <SettingsPaynowDiscounts
    {...SettingsPaynowDiscountsProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initSettingsPaynowDiscountsScreen: () => dispatch(initializeSettingsPaynowDiscountsScreen()),
      fetchSettingsPaynowDiscounts: (payload) => dispatch(fetchSettingsPaynowDiscounts(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportSettingsPaynowDiscounts: (payload) => dispatch(exportSettingsPaynowDiscounts(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isSettingsPaynowDiscountsCreateSuccess: selectFromSettingsPaynowDiscountsReducer(
        state,
        "isSettingsPaynowDiscountsCreateSuccess"
      ),
      SettingsPaynowDiscountsListview: selectFromSettingsPaynowDiscountsReducer(
        state,
        "SettingsPaynowDiscountsListview"
      ),
      showPageLevelLoader: selectFromSettingsPaynowDiscountsReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromSettingsPaynowDiscountsReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromSettingsPaynowDiscountsReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromSettingsPaynowDiscountsReducer(
        state,
        "isFetchingGridData"
      ),
      isSettingsPaynowDiscountsExportError: selectFromSettingsPaynowDiscountsReducer(
        state,
        "isSettingsPaynowDiscountsExportError"
      ),
      isSettingsPaynowDiscountsExportSuccess: selectFromSettingsPaynowDiscountsReducer(
        state,
        "isSettingsPaynowDiscountsExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromSettingsPaynowDiscountsReducer = (state, path) => {
  return selectSettingsPaynowDiscountsReducer(state)[path];
};

const selectSettingsPaynowDiscountsReducer = ({ SettingsPaynowDiscountsReducer }) => {
  return SettingsPaynowDiscountsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(SettingsPaynowDiscountsScreen);
