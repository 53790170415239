

export const FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST = {
    START: 'FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST_START',
    SUCCESS: 'FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST_SUCCESS',
    ERROR: 'FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RESERVATIONS_DAILYMANIFESTREPORT_INIT="FETCH_RESERVATIONS_DAILYMANIFESTREPORT_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SCREEN =
'INIT_CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SCREEN';

export const CREATE_RESERVATIONS_DAILYMANIFESTREPORT = {
START: 'CREATE_RESERVATIONS_DAILYMANIFESTREPORT_START',
SUCCESS: 'CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SUCCESS',
ERROR: 'CREATE_RESERVATIONS_DAILYMANIFESTREPORT_ERROR',
};

export const EDIT_RESERVATIONS_DAILYMANIFESTREPORT = {
START: 'EDIT_RESERVATIONS_DAILYMANIFESTREPORT_START',
SUCCESS: 'EDIT_RESERVATIONS_DAILYMANIFESTREPORT_SUCCESS',
ERROR: 'EDIT_RESERVATIONS_DAILYMANIFESTREPORT_ERROR',
};

export const FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL = {
  START: 'FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL_START',
  SUCCESS: 'FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL_SUCCESS',
  ERROR: 'FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL_ERROR',
};
export const EXPORT_RESERVATIONS_DAILYMANIFESTREPORT = {
  START: 'EXPORT_RESERVATIONS_DAILYMANIFESTREPORT_START',
  SUCCESS: 'EXPORT_RESERVATIONS_DAILYMANIFESTREPORT_SUCCESS',
  ERROR: 'EXPORT_RESERVATIONS_DAILYMANIFESTREPORT_ERROR',
};
export const RESERVATIONS_DAILYMANIFESTREPORTDD = {
  START: 'RESERVATIONS_DAILYMANIFESTREPORTDD_START',
  SUCCESS: 'RESERVATIONS_DAILYMANIFESTREPORTDD_SUCCESS',
  ERROR: 'RESERVATIONS_DAILYMANIFESTREPORTDD_ERROR',
};
export const RESERVATIONS_DAILYMANIFESTREPORTDELETE = {
  START: 'RESERVATIONS_DAILYMANIFESTREPORTDELETE_START',
  SUCCESS: 'RESERVATIONS_DAILYMANIFESTREPORTDELETE_SUCCESS',
  ERROR: 'RESERVATIONS_DAILYMANIFESTREPORTDELETE_ERROR',
};

