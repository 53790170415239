import { createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import rootReducers from "../reducers/rootReducers";


const logger = (store) => (next) => (action) => {
  console.log("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  return result;
};

const store = createStore(rootReducers, applyMiddleware(thunk, logger));

export default store;
