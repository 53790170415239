import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { AppliesDD, basicDetail, editMode, hourslist, statusDD, } from "../CreateRateAdminBlackouts/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import { RateAdminBreadcrumb } from "../../RateAdminRateCode/CreateRateAdminRateCode/constant";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledMultiSelecttWithFloatingLabel from "../../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";

const CreateRateAdminBlackoutsComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRateAdminBlackoutsScreen,
    createRateAdminBlackouts,
    editRateAdminBlackouts,
    isLoading,
    isRateAdminBlackoutsCreateSuccess,
    isRateAdminBlackoutsCreateError,
    isRateAdminBlackoutsDetailSuccess,
    isRateAdminBlackoutsDetailError,
    isRateAdminBlackoutsEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
    locationDD,
    vehicleCategoryDD,
    RateCodeDD,
    RateNetworkDD,
    categoryDD
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    resetField,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });


  const navigateToRateAdminBlackoutsScreen = () => {
    window.location.replace("/#" + APP_URLS.RATEADMIN_BLACKOUTS.HOME)
  };
  useEffect(() => {

    if (isRateAdminBlackoutsCreateSuccess || isRateAdminBlackoutsEditSuccess) {
      reset();
    }
  }, [isRateAdminBlackoutsCreateSuccess]);
  useEffect(() => {
    initCreateRateAdminBlackoutsScreen();
  }, []);


  useEffect(() => {
    if (isRateAdminBlackoutsDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
      setPickupAllowed(formFieldValueMap?.pickupAllowed === 1 ? true : false);
      setSpanoverAllowed(formFieldValueMap?.spanoverAllowed === 1 ? true : false);
  }
  }, [isRateAdminBlackoutsDetailSuccess]);
  const [pickupAllowed, setPickupAllowed]=useState(false);
  const [spanoverAllowed, setSpanoverAllowed]=useState(false);
  
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if(name=="pickupAllowed"){
    setPickupAllowed(checked)
    }
    if(name=="spanoverAllowed"){
      setSpanoverAllowed(checked)
      }
  }
  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    newData["pickupAllowed"] = pickupAllowed===true ? 1: 0;
    newData["spanoverAllowed"] = spanoverAllowed===true ? 1: 0; 

    isEdit
      ? editRateAdminBlackouts({ id: data.id, data: newData })
      : createRateAdminBlackouts(newData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Blackouts</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Blackouts"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Blackouts"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToRateAdminBlackoutsScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RateAdminBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              {basicDetail?.map((item) => (
                item.name === "status" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={statusDD}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :
                 item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    />
                  </Grid>
                ):

                item.type === "hours" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={hourslist}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :
                item.name === "pickupAllowed" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCheckBoxWithLabel
                      name={item.name}
                      value={pickupAllowed}
                      id={item.name}
                      control={control}
                      isError={errors[item.label]}
                      label={item.label}
                      labelClassName={'ml-2'}
                      onChange={handleCheckbox}
                    />
                  </Grid>
                ) :
                item.name === "spanoverAllowed" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCheckBoxWithLabel
                      name={item.name}
                      value={spanoverAllowed}
                      id={item.name}
                      control={control}
                      isError={errors[item.label]}
                      label={item.label}
                      labelClassName={'ml-2'}
                      onChange={handleCheckbox}
                    />
                  </Grid>
                ) :
                  item.name === "locationId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledMultiSelecttWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={locationDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ) :
                    item.name === "categoryId" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledMultiSelecttWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={categoryDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :

                    item.name === "rateCodId" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledMultiSelecttWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={RateCodeDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :

                    item.name === "networkId" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledMultiSelecttWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={RateNetworkDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )  :
                          (
                            (<Grid item xs={3} key={item.name}>
                              <EzControlledInputWithFloatingLabel
                                name={item?.name}
                                control={control}
                                label={item?.label}
                                type={item?.type}
                                rules={item?.rule}
                                isError={errors[item?.name]}
                                errorMsg={getFormErrorMessage(item?.name)}
                              />
                            </Grid>))
              ))}

            </Grid>
        
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
            {/* {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
} */}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateRateAdminBlackoutsComponent;
