export const basicDetail =
[
  {
    name:"locationId",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"dropdown"
  },
  {
    name:"rateCodId",
     "rule": {
        "required": true
      },
    label:"Rate Code",
    type:"dropdown"
  },
  {
    name:"startDate",
     "rule": {
        "required": true
      },
    label:"Start Date",
    type:"date"
  },
  {
    name:"startTime",
     "rule": {
        "required": true
      },
    label:"Start Time",
    type:"hours"
  },
  {
    name:"endDate",
     "rule": {
        "required": true
      },
    label:"End Date",
    type:"date"
  },
  {
    name:"endTime",
     "rule": {
        "required": true
      },
    label:"End Time",
    type:"hours"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  },
  {
    name:"pickupAllowed",
     "rule": {
        "required": true
      },
    label:"Pickup Allowed",
    type:"checkbox"
  },
  {
    name:"spanoverAllowed",
     "rule": {
        "required": true
      },
    label:"Spanover Allowed",
    type:"checkbox"
  },
  {
    name:"networkId",
     "rule": {
        "required": false
      },
    label:"Network",
    type:"dropdown"
  },
  {
    name:"categoryId",
     "rule": {
        "required": false
      },
    label:"Classes",
    type:"dropdown"
  },
  {
    name:"blackoutMessage",
     "rule": {
        "required": false
      },
    label:"Blackout Message",
    type:"text"
  },
  
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const AppliesDD =
[
  { id:1, name:"Applies Per Day"},
  { id:2, name:"Applies By Percentage"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]

  export const hourslist =[
    {"id": "12:00AM", "name": "12:00 AM"},
  {"id": "12:30AM", "name": "12:30 AM"},
  {"id": "01:00AM", "name": "01:00 AM"},
  {"id": "01:30AM", "name": "01:30 AM"},
  {"id": "02:00AM", "name": "02:00 AM"},
  {"id": "02:30AM", "name": "02:30 AM"},
  {"id": "03:00AM", "name": "03:00 AM"},
  {"id": "03:30AM", "name": "03:30 AM"},
  {"id": "04:00AM", "name": "04:00 AM"},
  {"id": "04:30AM", "name": "04:30 AM"},
  {"id": "05:00AM", "name": "05:00 AM"},
  {"id": "05:30AM", "name": "05:30 AM"},
  {"id": "06:00AM", "name": "06:00 AM"},
  {"id": "06:30AM", "name": "06:30 AM"},
  {"id": "07:00AM", "name": "07:00 AM"},
  {"id": "07:30AM", "name": "07:30 AM"},
  {"id": "08:00AM", "name": "08:00 AM"},
  {"id": "08:30AM", "name": "08:30 AM"},
  {"id": "09:00AM", "name": "09:00 AM"},
  {"id": "09:30AM", "name": "09:30 AM"},
  {"id": "10:00AM", "name": "10:00 AM"},
  {"id": "10:30AM", "name": "10:30 AM"},
  {"id": "11:00AM", "name": "11:00 AM"},
  {"id": "11:30AM", "name": "11:30 AM"},
  {"id": "12:00PM", "name": "12:00 PM"},
  {"id": "12:30PM", "name": "12:30 PM"},
  {"id": "01:00PM", "name": "01:00 PM"},
  {"id": "01:30PM", "name": "01:30 PM"},
  {"id": "02:00PM", "name": "02:00 PM"},
  {"id": "02:30PM", "name": "02:30 PM"},
  {"id": "03:00PM", "name": "03:00 PM"},
  {"id": "03:30PM", "name": "03:30 PM"},
  {"id": "04:00PM", "name": "04:00 PM"},
  {"id": "04:30PM", "name": "04:30 PM"},
  {"id": "05:00PM", "name": "05:00 PM"},
  {"id": "05:30PM", "name": "05:30 PM"},
  {"id": "06:00PM", "name": "06:00 PM"},
  {"id": "06:30PM", "name": "06:30 PM"},
  {"id": "07:00PM", "name": "07:00 PM"},
  {"id": "07:30PM", "name": "07:30 PM"},
  {"id": "08:00PM", "name": "08:00 PM"},
  {"id": "08:00PM", "name": "08:30 PM"},
  {"id": "08:30PM", "name": "09:00 PM"},
  {"id": "09:30PM", "name": "09:30 PM"},
  {"id": "10:00PM", "name": "10:00 PM"},
  {"id": "10:30PM", "name": "10:30 PM"},
  {"id": "11:00PM", "name": "11:00 PM"},
  {"id": "11:30PM", "name": "11:30 PM"}
  ];