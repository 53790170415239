import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleTrim,
    initializeVehicleTrimScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleTrim
  } from "../../../redux/actions/vehicles/vehicleTrim";
import VehicleTrim from '../../../component/Vehicles/VehicleTrim';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleTrimScreen(props) {
    const {
        fetchVehicleTrim,
        initVehicleTrimScreen,
        showPageLevelLoader,
        vehicleTrimListview,
        paginationInfo,
        exportVehicleTrim,
        isVehicleTrimExportError,
        isVehicleTrimExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleTrimScreen();
        fetchVehicleTrim({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleTrim(props);
      };
      const handleCreateVehicleTrim =() =>{
      navigate(APP_URLS.VEHICLE_TRIM.CREATE);
    }
      const editVehicleTrimRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_TRIM.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleTrim = {
  handleCreateVehicleTrim,
  exportVehicleTrim,
        isVehicleTrimExportError,
        isVehicleTrimExportSuccess,
  editVehicleTrimRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleTrims: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleTrimListview?.columns,
    rows: vehicleTrimListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleTrim
    {...vehicleTrim}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleTrimScreen: () => dispatch(initializeVehicleTrimScreen()),
      fetchVehicleTrim: (payload) => dispatch(fetchVehicleTrim(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleTrim: (payload) => dispatch(exportVehicleTrim(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleTrimCreateSuccess: selectFromVehicleTrimReducer(
        state,
        "isVehicleTrimCreateSuccess"
      ),
      vehicleTrimListview: selectFromVehicleTrimReducer(
        state,
        "vehicleTrimListview"
      ),
      showPageLevelLoader: selectFromVehicleTrimReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleTrimReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleTrimReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleTrimReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleTrimExportError: selectFromVehicleTrimReducer(
        state,
        "isVehicleTrimExportError"
      ),
      isVehicleTrimExportSuccess: selectFromVehicleTrimReducer(
        state,
        "isVehicleTrimExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleTrimReducer = (state, path) => {
  return selectVehicleTrimReducer(state)[path];
};

const selectVehicleTrimReducer = ({ vehicleTrimReducer }) => {
  return vehicleTrimReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleTrimScreen);
