
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const CHANGEPASSWORD_INIT ="CHANGEPASSWORD_INITs";

export const CHANGEPASSWORD = {
START: 'CHANGEPASSWORD_START',
SUCCESS: 'CHANGEPASSWORD_SUCCESS',
ERROR: 'CHANGEPASSWORD_ERROR',
};
