import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST, 
  TOAST, 
  FETCH_RENTERS_NONREVENUEAGREEMENTS_INIT,
  FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL,
  EXPORT_RENTERS_NONREVENUEAGREEMENTS,
  CREATE_RENTERS_NONREVENUEAGREEMENTS,
  EDIT_RENTERS_NONREVENUEAGREEMENTS,
  INIT_CREATE_RENTERS_NONREVENUEAGREEMENTS_SCREEN,
  RENTERS_NONREVENUEAGREEMENTSDD,
  RENTERS_NONREVENUEAGREEMENTSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Renters/NonRevenueAgreements.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    RentersNonRevenueAgreementsListview: [],
    RentersNonRevenueAgreementsDD:[],
    isRentersNonRevenueAgreementsDDSuccess: false,
    isRentersNonRevenueAgreementsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRentersNonRevenueAgreementsCreateSuccess: false,
    isRentersNonRevenueAgreementsCreateError: false,
    isRentersNonRevenueAgreementsDetailSuccess: false,
    isRentersNonRevenueAgreementsDetailError: false,
    isRentersNonRevenueAgreementsEditSuccess: false,
    isRentersNonRevenueAgreementsEditError: false,
    isRentersNonRevenueAgreementsExportSuccess: false,
    isRentersNonRevenueAgreementsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isRentersNonRevenueAgreementsDeleteSuccess: false,
    isRentersNonRevenueAgreementsDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const RentersNonRevenueAgreementsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RENTERS_NONREVENUEAGREEMENTS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL.START:
      case EXPORT_RENTERS_NONREVENUEAGREEMENTS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST.START:
      case CREATE_RENTERS_NONREVENUEAGREEMENTS.START:
        case RENTERS_NONREVENUEAGREEMENTSDELETE.START:
        case EDIT_RENTERS_NONREVENUEAGREEMENTS.START:
          case RENTERS_NONREVENUEAGREEMENTSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          RentersNonRevenueAgreementsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRentersNonRevenueAgreementsCreateSuccess: false,
          isRentersNonRevenueAgreementsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          RentersNonRevenueAgreementsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          RentersNonRevenueAgreementsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isRentersNonRevenueAgreementsDeletionSuccess: false,
          isRentersNonRevenueAgreementsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRentersNonRevenueAgreementsDetailSuccess: true,
      };
    }
    case FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersNonRevenueAgreementsDetailSuccess: false,
        isRentersNonRevenueAgreementsDetailError: true,
      };
    }

    //DD
    case RENTERS_NONREVENUEAGREEMENTSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RentersNonRevenueAgreementsDD:temp,
        isRentersNonRevenueAgreementsDDSuccess: true,
        isRentersNonRevenueAgreementsDDError: false,
      };
    }
    case RENTERS_NONREVENUEAGREEMENTSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersNonRevenueAgreementsDDSuccess: false,
        isRentersNonRevenueAgreementsDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isRentersNonRevenueAgreementsDeleteSuccess: false,
    isRentersNonRevenueAgreementsDeleteError: false,
  };
}
case RENTERS_NONREVENUEAGREEMENTSDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isRentersNonRevenueAgreementsDeleteSuccess: true,
    isRentersNonRevenueAgreementsDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RENTERS_NONREVENUEAGREEMENTSDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRentersNonRevenueAgreementsDeleteSuccess: false,
    isRentersNonRevenueAgreementsDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RENTERS_NONREVENUEAGREEMENTS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRentersNonRevenueAgreementsExportSuccess: false,
    isRentersNonRevenueAgreementsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RENTERS_NONREVENUEAGREEMENTS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"revenue-agreements.xls");
   return {
    ...state,
    isRentersNonRevenueAgreementsExportSuccess: true,
    isRentersNonRevenueAgreementsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RENTERS_NONREVENUEAGREEMENTS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersNonRevenueAgreementsEditSuccess: false,
    isRentersNonRevenueAgreementsEditError: true,
  };
}

case EDIT_RENTERS_NONREVENUEAGREEMENTS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersNonRevenueAgreementsEditSuccess: true,
    isRentersNonRevenueAgreementsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RENTERS_NONREVENUEAGREEMENTS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RENTERS_NONREVENUEAGREEMENTS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersNonRevenueAgreementsCreateSuccess: false,
    isRentersNonRevenueAgreementsCreateError: true,
  };
}
case CREATE_RENTERS_NONREVENUEAGREEMENTS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersNonRevenueAgreementsCreateSuccess: true,
    isRentersNonRevenueAgreementsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRentersNonRevenueAgreementsCreateSuccess: false,
          isRentersNonRevenueAgreementsCreateError: false,
          isRentersNonRevenueAgreementsEditError: false,
          isRentersNonRevenueAgreementsEditSuccess: false,
          isRentersNonRevenueAgreementsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  