import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_SETTINGS_PAYNOWOPTIONS_LIST, 
  TOAST, 
  FETCH_SETTINGS_PAYNOWOPTIONS_INIT,
  FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL,
  EXPORT_SETTINGS_PAYNOWOPTIONS,
  CREATE_SETTINGS_PAYNOWOPTIONS,
  EDIT_SETTINGS_PAYNOWOPTIONS,
  INIT_CREATE_SETTINGS_PAYNOWOPTIONS_SCREEN,
  SETTINGS_PAYNOWOPTIONSDD,
  SETTINGS_PAYNOWOPTIONSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Settings/PaynowOptions.type";


  const formFieldValueMap = {
    id:null,
    status:1,
    locationId:"",
    startDate:"",
    startTime:"",
    endDate:"",
    endTime:"",
    payOptionCategoryList:[],
    modBy:"",
    modTime:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    SettingsPaynowOptionsListview: [],
    PaynowOptionsDD:[],
    isSettingsPaynowOptionsDDSuccess: false,
    isSettingsPaynowOptionsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isSettingsPaynowOptionsCreateSuccess: false,
    isSettingsPaynowOptionsCreateError: false,
    isSettingsPaynowOptionsDetailSuccess: false,
    isSettingsPaynowOptionsDetailError: false,
    isSettingsPaynowOptionsEditSuccess: false,
    isSettingsPaynowOptionsEditError: false,
    isSettingsPaynowOptionsExportSuccess: false,
    isSettingsPaynowOptionsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isSettingsPaynowOptionsDeleteSuccess: false,
    isSettingsPaynowOptionsDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
startDate,
endDate,
categoryCode,
id,
modBy,
modTime,
status,
locationTitle
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
startDate,
endDate,
categoryCode,
id,
modBy,
modTime,
status,
locationTitle
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location",
        dataKey: "locationTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
{
        colLabel: "Start Date",
        dataKey: "startDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "End Date",
        dataKey: "endDate",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Category",
        dataKey: "categoryCode",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const SettingsPaynowOptionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_SETTINGS_PAYNOWOPTIONS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL.START:
      case EXPORT_SETTINGS_PAYNOWOPTIONS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_SETTINGS_PAYNOWOPTIONS_LIST.START:
      case CREATE_SETTINGS_PAYNOWOPTIONS.START:
        case SETTINGS_PAYNOWOPTIONSDELETE.START:
        case EDIT_SETTINGS_PAYNOWOPTIONS.START:
          case SETTINGS_PAYNOWOPTIONSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_SETTINGS_PAYNOWOPTIONS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          SettingsPaynowOptionsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isSettingsPaynowOptionsCreateSuccess: false,
          isSettingsPaynowOptionsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          SettingsPaynowOptionsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_SETTINGS_PAYNOWOPTIONS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          SettingsPaynowOptionsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isSettingsPaynowOptionsDeletionSuccess: false,
          isSettingsPaynowOptionsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
              if(key==="endDate"){
                temp[key] = new Date(action.payload[key])
              }
              if(key==="startDate"){
                temp[key] = new Date(action.payload[key])
              }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isSettingsPaynowOptionsDetailSuccess: true,
      };
    }
    case FETCH_SETTINGS_PAYNOWOPTIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isSettingsPaynowOptionsDetailSuccess: false,
        isSettingsPaynowOptionsDetailError: true,
      };
    }

    //DD
    case SETTINGS_PAYNOWOPTIONSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        PaynowOptionsDD:temp,
        isSettingsPaynowOptionsDDSuccess: true,
        isSettingsPaynowOptionsDDError: false,
      };
    }
    case SETTINGS_PAYNOWOPTIONSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isSettingsPaynowOptionsDDSuccess: false,
        isSettingsPaynowOptionsDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isSettingsPaynowOptionsDeleteSuccess: false,
    isSettingsPaynowOptionsDeleteError: false,
  };
}
case SETTINGS_PAYNOWOPTIONSDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isSettingsPaynowOptionsDeleteSuccess: true,
    isSettingsPaynowOptionsDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case SETTINGS_PAYNOWOPTIONSDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isSettingsPaynowOptionsDeleteSuccess: false,
    isSettingsPaynowOptionsDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_SETTINGS_PAYNOWOPTIONS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isSettingsPaynowOptionsExportSuccess: false,
    isSettingsPaynowOptionsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_SETTINGS_PAYNOWOPTIONS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"paynow-options.xls");
   return {
    ...state,
    isSettingsPaynowOptionsExportSuccess: true,
    isSettingsPaynowOptionsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_SETTINGS_PAYNOWOPTIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isSettingsPaynowOptionsEditSuccess: false,
    isSettingsPaynowOptionsEditError: true,
  };
}

case EDIT_SETTINGS_PAYNOWOPTIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isSettingsPaynowOptionsEditSuccess: true,
    isSettingsPaynowOptionsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_SETTINGS_PAYNOWOPTIONS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_SETTINGS_PAYNOWOPTIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isSettingsPaynowOptionsCreateSuccess: false,
    isSettingsPaynowOptionsCreateError: true,
  };
}
case CREATE_SETTINGS_PAYNOWOPTIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isSettingsPaynowOptionsCreateSuccess: true,
    isSettingsPaynowOptionsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isSettingsPaynowOptionsCreateSuccess: false,
          isSettingsPaynowOptionsCreateError: false,
          isSettingsPaynowOptionsEditError: false,
          isSettingsPaynowOptionsEditSuccess: false,
          isSettingsPaynowOptionsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  