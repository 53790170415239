import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleVehicleOperationsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_VEHICLEOPERATIONS_INIT, 
  FETCH_VEHICLE_VEHICLEOPERATIONS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_VEHICLEOPERATIONS_SCREEN,
  CREATE_VEHICLE_VEHICLEOPERATIONS,
  EDIT_VEHICLE_VEHICLEOPERATIONS,
  TOAST,
  FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL,
  EXPORT_VEHICLE_VEHICLEOPERATIONS,
  VEHICLE_OPERATIONDD
} from "../../../types/vehiclecehicleoperations.type";
export const initializeVehicleVehicleOperationsScreen = () => ({ type: FETCH_VEHICLE_VEHICLEOPERATIONS_INIT });

export const vehicleVehicleOperationsFetchStart = (payload) => ({
  type: FETCH_VEHICLE_VEHICLEOPERATIONS_LIST.START,
  payload,
});

export const vehicleVehicleOperationsFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_VEHICLEOPERATIONS_LIST.SUCCESS,
  payload,
});

export const vehicleVehicleOperationsFetchError = (payload) => ({
  type: FETCH_VEHICLE_VEHICLEOPERATIONS_LIST.ERROR,
  payload,
});

export const fetchVehicleVehicleOperations = (payload) => {
  return (dispatch) => {
    dispatch(vehicleVehicleOperationsFetchStart(payload));
    vehicleVehicleOperationsServices.fetchVehicleVehicleOperations(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleVehicleOperationsFetchSuccess(data));
      } else {
        dispatch(vehicleVehicleOperationsFetchError(data));
      }
    });
  };
};



// DD
export const vehicleOperationDDFetchStart = (payload) => ({
  type: VEHICLE_OPERATIONDD.START,
  payload,
});

export const vehicleOperationDDFetchSuccess = (payload) => ({
  type:VEHICLE_OPERATIONDD.SUCCESS,
  payload,
});

export const vehicleOperationDDFetchError = (payload) => ({
  type: VEHICLE_OPERATIONDD.ERROR,
  payload,
});

export const fetchVehicleOperationDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleOperationDDFetchStart(payload));
    vehicleVehicleOperationsServices.fetchVehicleOperationDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleOperationDDFetchSuccess(data));
      } else {
        dispatch(vehicleOperationDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleVehicleOperationsDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL.START,
  payload,
});

export const vehicleVehicleOperationsDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL.SUCCESS,
  payload,
});

export const vehicleVehicleOperationsDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL.ERROR,
  payload,
});

export const fetchVehicleVehicleOperationsById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleVehicleOperationsDetailFetchStart(payload));

    vehicleVehicleOperationsServices
      .fetchVehicleVehicleOperationsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleVehicleOperationsDetailFetchSuccess(data));
        } else {
          dispatch(vehicleVehicleOperationsDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleVehicleOperationsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleVehicleOperationsScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_VEHICLEOPERATIONS_SCREEN,
  payload,
});

export const initCreateVehicleVehicleOperationsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleVehicleOperationsScreenInitStart());
  };
};


export const createVehicleVehicleOperationsStart = () => ({
  type: CREATE_VEHICLE_VEHICLEOPERATIONS.START,
});

export const createVehicleVehicleOperationsSuccess = (payload) => ({
  type: CREATE_VEHICLE_VEHICLEOPERATIONS.SUCCESS,
  payload,
});

export const createVehicleVehicleOperationsError = (payload) => ({
  type: CREATE_VEHICLE_VEHICLEOPERATIONS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleVehicleOperations = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleVehicleOperationsStart());
    vehicleVehicleOperationsServices.createVehicleVehicleOperations(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleVehicleOperationsSuccess());
        goBack();
      } else {
        dispatch(createVehicleVehicleOperationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleVehicleOperationsStart = (payload) => ({
  type: EDIT_VEHICLE_VEHICLEOPERATIONS.START,
  payload
});

export const editVehicleVehicleOperationsSuccess = (payload) => ({
  type: EDIT_VEHICLE_VEHICLEOPERATIONS.SUCCESS,
  payload,
});

export const editVehicleVehicleOperationsError = (payload) => ({
  type: EDIT_VEHICLE_VEHICLEOPERATIONS.ERROR,
  payload,
});

export const editVehicleVehicleOperations = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleVehicleOperationsStart());
    vehicleVehicleOperationsServices.editVehicleVehicleOperations(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleVehicleOperationsSuccess());
        goBack();
      } else {
        dispatch(editVehicleVehicleOperationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleVehicleOperationsRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_VEHICLEOPERATIONS.START,
  payload
});

export const exportVehicleVehicleOperationsRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_VEHICLEOPERATIONS.SUCCESS,
  payload,
});

export const exportVehicleVehicleOperationsRecordError = (payload) => ({
  type: EXPORT_VEHICLE_VEHICLEOPERATIONS.ERROR,
  payload,
});

export const exportVehicleVehicleOperations = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleVehicleOperationsRecordStart());
    vehicleVehicleOperationsServices.exportVehicleVehicleOperationsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleVehicleOperationsRecordSuccess(data));
      } else {
        dispatch(exportVehicleVehicleOperationsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

