import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminRateMirrorsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATEMIRRORS_INIT, 
  FETCH_RATEADMIN_RATEMIRRORS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_RATEMIRRORS_SCREEN,
  CREATE_RATEADMIN_RATEMIRRORS,
  EDIT_RATEADMIN_RATEMIRRORS,
  TOAST,
  FETCH_RATEADMIN_RATEMIRRORS_DETAIL,
  EXPORT_RATEADMIN_RATEMIRRORS,
  RATEADMIN_RATEMIRRORSDD,
  RATEADMIN_RATEMIRRORSDELETE
} from "../../../types/rateadminratemirrors.type";
export const initializeRateAdminRateMirrorsScreen = () => ({ type: FETCH_RATEADMIN_RATEMIRRORS_INIT });

export const rateadminRateMirrorsFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATEMIRRORS_LIST.START,
  payload,
});

export const rateadminRateMirrorsFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_RATEMIRRORS_LIST.SUCCESS,
  payload,
});

export const rateadminRateMirrorsFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATEMIRRORS_LIST.ERROR,
  payload,
});

export const fetchRateAdminRateMirrors = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateMirrorsFetchStart(payload));
    rateAdminRateMirrorsServices.fetchRateAdminRateMirrors(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateMirrorsFetchSuccess(data));
      } else {
        dispatch(rateadminRateMirrorsFetchError(data));
      }
    });
  };
};



// DD
export const rateadminRateMirrorsDDFetchStart = (payload) => ({
  type: RATEADMIN_RATEMIRRORSDD.START,
  payload,
});

export const rateadminRateMirrorsDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATEMIRRORSDD.SUCCESS,
  payload,
});

export const rateadminRateMirrorsDDFetchError = (payload) => ({
  type: RATEADMIN_RATEMIRRORSDD.ERROR,
  payload,
});

export const fetchRateAdminRateMirrorsDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateMirrorsDDFetchStart(payload));
    rateAdminRateMirrorsServices.fetchRateAdminRateMirrorsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateMirrorsDDFetchSuccess(data));
      } else {
        dispatch(rateadminRateMirrorsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminRateMirrorsDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATEMIRRORS_DETAIL.START,
  payload,
});

export const rateadminRateMirrorsDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATEMIRRORS_DETAIL.SUCCESS,
  payload,
});

export const rateadminRateMirrorsDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATEMIRRORS_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminRateMirrorsById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateMirrorsDetailFetchStart(payload));

    rateAdminRateMirrorsServices
      .fetchRateAdminRateMirrorsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateMirrorsDetailFetchSuccess(data));
        } else {
          dispatch(rateadminRateMirrorsDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminRateMirrorsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminRateMirrorsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_RATEMIRRORS_SCREEN,
  payload,
});

export const initCreateRateAdminRateMirrorsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateMirrorsScreenInitStart());
  };
};


export const createRateAdminRateMirrorsStart = () => ({
  type: CREATE_RATEADMIN_RATEMIRRORS.START,
});

export const createRateAdminRateMirrorsSuccess = (payload) => ({
  type: CREATE_RATEADMIN_RATEMIRRORS.SUCCESS,
  payload,
});

export const createRateAdminRateMirrorsError = (payload) => ({
  type: CREATE_RATEADMIN_RATEMIRRORS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminRateMirrors = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateMirrorsStart());
    rateAdminRateMirrorsServices.createRateAdminRateMirrors(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminRateMirrorsSuccess());
        goBack();
      } else {
        dispatch(createRateAdminRateMirrorsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminRateMirrorsStart = (payload) => ({
  type: EDIT_RATEADMIN_RATEMIRRORS.START,
  payload
});

export const editRateAdminRateMirrorsSuccess = (payload) => ({
  type: EDIT_RATEADMIN_RATEMIRRORS.SUCCESS,
  payload,
});

export const editRateAdminRateMirrorsError = (payload) => ({
  type: EDIT_RATEADMIN_RATEMIRRORS.ERROR,
  payload,
});

export const editRateAdminRateMirrors = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminRateMirrorsStart());
    rateAdminRateMirrorsServices.editRateAdminRateMirrors(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminRateMirrorsSuccess());
        goBack();
      } else {
        dispatch(editRateAdminRateMirrorsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminRateMirrorsRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_RATEMIRRORS.START,
  payload
});

export const exportRateAdminRateMirrorsRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_RATEMIRRORS.SUCCESS,
  payload,
});

export const exportRateAdminRateMirrorsRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_RATEMIRRORS.ERROR,
  payload,
});

export const exportRateAdminRateMirrors = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminRateMirrorsRecordStart());
    rateAdminRateMirrorsServices.exportRateAdminRateMirrorsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminRateMirrorsRecordSuccess(data));
      } else {
        dispatch(exportRateAdminRateMirrorsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const rateadminRateMirrorsDeleteStart = (payload) => ({
  type: RATEADMIN_RATEMIRRORSDELETE.START,
  payload,
});

export const rateadminRateMirrorsDeleteSuccess = (payload) => ({
  type: RATEADMIN_RATEMIRRORSDELETE.SUCCESS,
  payload,
});

export const rateadminRateMirrorsDeleteError = (payload) => ({
  type: RATEADMIN_RATEMIRRORSDELETE.ERROR,
  payload,
});

export const deleteRateMirrorsAdminRateMirrors = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateMirrorsDeleteStart(payload));

    rateAdminRateMirrorsServices
      .deleteRateAdminRateMirrors(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateMirrorsDeleteSuccess(data));
          dispatch(fetchRateAdminRateMirrors(payload?.params));
        } else {
          dispatch(rateadminRateMirrorsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/