import React, {  useEffect, useMemo, useState } from "react";
import { useForm} from "react-hook-form";

import { Button } from "primereact/button";
import "./styles.css";

import { useStyles } from '../style';
import { basicDetail } from "../CreatePassword/constant"
import { Box, Container, Grid,  } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import EzControlledPasswordWithFloatingLabel from "../../../common/EzForm/EZControlledPassword/WithFloatingLabel";

const CreatePasswordComponent = (props) => {
  const {
    changepassword,
    isLoading,
    isEdit,
    isCreatePasswordSuccess,
    initializePasswordScreen,
    isCreatePasswordError,
    formFieldValueMap
  } = props;
  console.log("check props of compoemnt", props);
 

  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

const password = watch("password");
const confirmPassword = watch("confirmPassword"); 
const [isConfirmPass, setIsConfirmPass] = useState(false)
  const onSubmit = (data) => {
    let  newData = data;
    newData["userId"] = localStorage.getItem("userId");
    if(confirmPassword===password){
    changepassword(newData);
    }
    else{
      setIsConfirmPass(true)
    }
  };
  useEffect(() => {
    setTimeout(() =>     setIsConfirmPass(false)
    , 1000);
  },[])
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="" style={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginBottom:"0px" }}>
          <div className="ml-3">
            <h4>Change Password</h4>
          </div>
     
        </div>
      </div>
    );
  };
 
  const getToastProps = () => {
    if (isCreatePasswordSuccess) {
      const toastTitle =  "Password Updated Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isConfirmPass===true
    ) {
      let toastTitle = "Confirm Password must be same as Password";
  

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  return (
    <Container maxWidth="lg">
      <EzToast {...getToastProps()} /> 
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
         
         <Grid container spacing={2}>
           {basicDetail?.map((item) => (
           
               <Grid item xs={3} key={item.name}>
                 <EzControlledPasswordWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
               </Grid>))}
               </Grid>
               <div style={{ margin: "0px 0px 15px 0px", maxWidth: "100px" }}>
               <Button
                        type="submit"
                        label={"Save"}
                        className="mt-2 p-button-raised"
                      />
                      </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreatePasswordComponent;
