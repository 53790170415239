import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRentersVehicleInquiryScreen,
  createRentersVehicleInquiry,
  editRentersVehicleInquiry,
  fetchRentersVehicleInquiryById,
  uploadImage,
} from "../../../../redux/actions/Renters/RentersVehicleInquiry";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRentersVehicleInquiryComponent from "../../../../component/Renters/RentersVehicleInquiry/CreateRentersVehicleInquiry";
import { useParams } from "react-router-dom";

const CreateRentersVehicleInquiryContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRentersVehicleInquiry,
    editRentersVehicleInquiry,
    isLoading,
    isRentersVehicleInquiryCreateSuccess,
    isRentersVehicleInquiryCreateError,
    isRentersVehicleInquiryDetailSuccess,
    isRentersVehicleInquiryDetailError,
    isRentersVehicleInquiryEditSuccess,
    isRentersVehicleInquiryEditError,
    isEdit,
    isView,
    initCreateRentersVehicleInquiryScreen,
    formFieldValueMap,
    fetchRentersVehicleInquiryById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRentersVehicleInquiryScreen();
   if (isEdit) {
    await fetchRentersVehicleInquiryById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRentersVehicleInquiryProps = {
    createRentersVehicleInquiry,
    initCreateRentersVehicleInquiryScreen,
    editRentersVehicleInquiry,
    isLoading,
    isEdit,
    isRentersVehicleInquiryDetailSuccess,
    isRentersVehicleInquiryCreateSuccess,
    isRentersVehicleInquiryEditSuccess,
    isRentersVehicleInquiryEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRentersVehicleInquiryCreateSuccess || isRentersVehicleInquiryEditSuccess) {
      const toastTitle = isEdit
        ? "Vehicle Inquiry Updated Successfully"
        : "Vehicle Inquiry Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRentersVehicleInquiryCreateError ||
      isRentersVehicleInquiryDetailError ||
      isRentersVehicleInquiryEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Inquiry";
      if (isRentersVehicleInquiryEditError) {
        toastTitle = "Error while updating  Vehicle Inquiry";
      } else if (isRentersVehicleInquiryDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRentersVehicleInquiryComponent {...createRentersVehicleInquiryProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRentersVehicleInquiryReducer(state, "isLoading"),
   
    isRentersVehicleInquiryCreateSuccess: selectFromRentersVehicleInquiryReducer(
      state,
      "isRentersVehicleInquiryCreateSuccess"
    ),
    isRentersVehicleInquiryCreateError: selectFromRentersVehicleInquiryReducer(
      state,
      "isRentersVehicleInquiryCreateError"
    ),
    isRentersVehicleInquiryDetailSuccess: selectFromRentersVehicleInquiryReducer(
      state,
      "isRentersVehicleInquiryDetailSuccess"
    ),
    isRentersVehicleInquiryDetailError: selectFromRentersVehicleInquiryReducer(
      state,
      "isRentersVehicleInquiryDetailError"
    ),
    isRentersVehicleInquiryEditSuccess: selectFromRentersVehicleInquiryReducer(
      state,
      "isRentersVehicleInquiryEditSuccess"
    ),
    isRentersVehicleInquiryEditError: selectFromRentersVehicleInquiryReducer(
      state,
      "isRentersVehicleInquiryEditError"
    ),
    imageUploadResponse: selectFromRentersVehicleInquiryReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRentersVehicleInquiryReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRentersVehicleInquiryReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRentersVehicleInquiryReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRentersVehicleInquiryReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRentersVehicleInquiryReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRentersVehicleInquiryReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRentersVehicleInquiryScreen: () => dispatch(initCreateRentersVehicleInquiryScreen()),
    createRentersVehicleInquiry: (payload) => dispatch(createRentersVehicleInquiry(payload)),
    editRentersVehicleInquiry: (payload) => dispatch(editRentersVehicleInquiry(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRentersVehicleInquiryById: (payload) =>
      dispatch(fetchRentersVehicleInquiryById(payload)),

  };
};

const selectFromRentersVehicleInquiryReducer = (state, path) => {
  return selectRentersVehicleInquiryReducer(state)[path];
};

const selectRentersVehicleInquiryReducer = ({ RentersVehicleInquiryReducer }) => {
  return RentersVehicleInquiryReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRentersVehicleInquiryContainer);
