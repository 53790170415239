import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleVehicleStatus,
    initializeVehicleVehicleStatusScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleVehicleStatus
  } from "../../../redux/actions/vehicles/vehicleVehicleStatus";
import VehicleVehicleStatus from '../../../component/Vehicles/VehicleVehicleStatus';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleVehicleStatusScreen(props) {
    const {
        fetchVehicleVehicleStatus,
        initVehicleVehicleStatusScreen,
        showPageLevelLoader,
        vehicleVehicleStatusListview,
        paginationInfo,
        exportVehicleVehicleStatus,
        isVehicleVehicleStatusExportError,
        isVehicleVehicleStatusExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleVehicleStatusScreen();
        fetchVehicleVehicleStatus({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleVehicleStatus(props);
      };
      const handleCreateVehicleVehicleStatus =() =>{
      navigate(APP_URLS.VEHICLE_VEHICLESTATUS.CREATE);
    }
      const editVehicleVehicleStatusRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_VEHICLESTATUS.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleVehicleStatus = {
  handleCreateVehicleVehicleStatus,
  exportVehicleVehicleStatus,
        isVehicleVehicleStatusExportError,
        isVehicleVehicleStatusExportSuccess,
  editVehicleVehicleStatusRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleVehicleStatuss: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleVehicleStatusListview?.columns,
    rows: vehicleVehicleStatusListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleVehicleStatus
    {...vehicleVehicleStatus}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleVehicleStatusScreen: () => dispatch(initializeVehicleVehicleStatusScreen()),
      fetchVehicleVehicleStatus: (payload) => dispatch(fetchVehicleVehicleStatus(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleVehicleStatus: (payload) => dispatch(exportVehicleVehicleStatus(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleVehicleStatusCreateSuccess: selectFromVehicleVehicleStatusReducer(
        state,
        "isVehicleVehicleStatusCreateSuccess"
      ),
      vehicleVehicleStatusListview: selectFromVehicleVehicleStatusReducer(
        state,
        "vehicleVehicleStatusListview"
      ),
      showPageLevelLoader: selectFromVehicleVehicleStatusReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleVehicleStatusReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleVehicleStatusReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleVehicleStatusReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleVehicleStatusExportError: selectFromVehicleVehicleStatusReducer(
        state,
        "isVehicleVehicleStatusExportError"
      ),
      isVehicleVehicleStatusExportSuccess: selectFromVehicleVehicleStatusReducer(
        state,
        "isVehicleVehicleStatusExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleVehicleStatusReducer = (state, path) => {
  return selectVehicleVehicleStatusReducer(state)[path];
};

const selectVehicleVehicleStatusReducer = ({ vehicleVehicleStatusReducer }) => {
  return vehicleVehicleStatusReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleVehicleStatusScreen);
