import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import EzTable from "../../../common/EzTable";
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import EzNotFound from "../../../common/EzNotFound";
import { Box, Container, Grid } from "@mui/material";
import Breadcrumb from "../../../common/Breadcrumb";
import { Button } from "primereact/button";
import { useStyles } from './style';
import EzControlledCalendarWithFloatingLabel from "../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";

import EzControlledMultiSelecttWithFloatingLabel from "../../../common/EzForm/EzControlledMultiSelect/WithFloatingLabel";

import { bookingTypeDD, listStatusDD } from "../ReservationsBookingsbyLocationReport/CreateReservationsBookingsbyLocationReport/constant";
import { ReservationsReservationsBreadcrumb } from "../ReservationsReservations/CreateReservationsReservations/constant";

const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const ReservationsBookingsbyLocationReport = (props) => {
  const classes = useStyles()
  const {
    fetchReservationsBookingsbyLocationReports,
    tableData,
    setDeleteConfirmationPopupVisibility,
    exportReservationsBookingsbyLocationReport,
    isPageLevelError,
    paginationInfo,
    isLoading,
    screenPermissions,
    handleCreateReservationsBookingsbyLocationReport,
    editReservationsBookingsbyLocationReportRoute,
    RateNetworkDD,
        locationDD,
  } = props;
  let customFields = {
    endDate:"",
    startDate:"",
    location:"",
    network:"",
    status:"",
    type:""
  }
  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return customFields;
    }, [customFields]),
  });
  const [filterData, setFilterData] = useState({})
  const [isCopy, setIsCopy] = useState(false)
  const convertToTSV = (data) => {
    const headers = data.columns.map(item => item.colLabel).join('\t');
    //const headers = Object.keys(data.rows[0]).join('\t');
    const rows = data.rows.map(row => Object.values(row).join('\t')).join('\n');
    return `${headers}\n${rows}`;
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const copyToClipboard = () => {
    const csv = convertToTSV(tableData);
    navigator.clipboard.writeText(csv)
      .then(() =>
        setIsCopy(true)
      )
      .catch(err =>
        setIsCopy(true)
      );
  };
  useEffect(() => {
    setTimeout(() => setIsCopy(false)
      , 1000);
  }, [isCopy]);
  const tableRef = useRef();
  const handlePrint = () => {
    const printContents = tableRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const renderPageHeaderSection = () => {
    return (


      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Bookings by Location Report</h4>
          </div>
          <div className="buttonarea">
            {/* <Button
             label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
             icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
             className={`p-button-text p-button-secondary`}
             onClick={() => {
               toggleFilterState(!areFiltersVisible);
             }}
           /> */}
            <Button
              label={"Copy"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={copyToClipboard}
            />
            <Button
              label={"Excel"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={exportReservationsBookingsbyLocationReport}
            />
            <Button
              label={"Print"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={handlePrint}
            />
            {/* <Button
              label={"Create Reservations Detail Report"}
              className={` p-button-raised`}
              style={{ borderRadius: "0px", }}
              onClick={handleCreateReservationsBookingsbyLocationReport}
            /> */}
          </div>
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderComplianceRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderComplianceRecords() && !shouldRenderFullPageError();
  const filters = {
    global: { value: null },
  };

  const getToastProps = () => {

    let toastTitle = ""
    if (
      isCopy
    ) {
      let toastTitle = "Data copied to clipboard!";
      if (!isCopy) {
        toastTitle = "Error while Data copied to clipboard!";
      }

      return {
        severity: isCopy ? TOAST_SEVERITY.SUCCESS : TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };
  const onSubmit = (data) => {
     setFilterData(data)
  }
  const tableProps = {
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Bookings by Location Report found.",
    filters,
    loadLazyData: fetchReservationsBookingsbyLocationReports,
    loading: isLoading,
    showMoreActions: false,
    sortField: null,
    setDeleteConfirmationPopupVisibility,
    onEditRecord: editReservationsBookingsbyLocationReportRoute,
    additinalFilter:filterData
  };
  return (
    <>
      <Container maxWidth="lg">
        <Breadcrumb list={ReservationsReservationsBreadcrumb} />
        <EzToast {...getToastProps()} />
        <div className="containerbox" className={classes.bg}>
          {renderPageHeaderSection()}

          <div className="form-demo">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <EzControlledCalendarWithFloatingLabel
                    id="startDate"
                    name="startDate"
                    {...register("startDate")}
                    showTime={false}
                    maxDate={new Date(2090, 0, 1)}
                    dateFormat="yy/mm/dd"
                    control={control}
                    isError={errors["startDate"]}
                    errorMsg={getFormErrorMessage("startDate")}
                    label="Start Date"
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  />
                </Grid>
                <Grid item xs={3}>
                  <EzControlledCalendarWithFloatingLabel
                    id="endDate"
                    name="endDate"
                    {...register("endDate")}
                    showTime={false}
                    maxDate={new Date(2090, 0, 1)}
                    dateFormat="yy/mm/dd"
                    control={control}
                    isError={errors["endDate"]}
                    errorMsg={getFormErrorMessage("endDate")}
                    label="End Date"
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  />
                </Grid>
              
                    <Grid item xs={2}>
                      <EzControlledMultiSelecttWithFloatingLabel
                        name="location"
                        control={control}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={locationDD}
                        isError={errors['location']}
                        errorMsg={getFormErrorMessage('location')}
                        label="Location"
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <EzControlledMultiSelecttWithFloatingLabel
                        name="network"
                        control={control}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={RateNetworkDD}
                        isError={errors['network']}
                        errorMsg={getFormErrorMessage('network')}
                        label="Network"
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  
                    <Grid item xs={2}>
                    <div style={{ margin: "0px 0px 0px 10px", maxWidth: "100px" }}>
                      <Button
                        type="button"
                        label={"Search"}
                        className="mt-2 p-button-raised"
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                    </Grid>
              </Grid>
            </form>
          </div>
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderComplianceRecords() && (
              <Box ref={tableRef} id="printable">
                <EzTable {...tableProps} />
              </Box>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No Bookings by Location Report records to show"}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
export default ReservationsBookingsbyLocationReport;

