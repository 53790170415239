
export const basicDetail =
[
  {
    name:"password",
     "rule": {
        "required": true
      },
    label:"Password"
  },{
    name:"confirmPassword",
     "rule": {
        "required": true
      },
    label:"Confirm Password"
  }
  
]