import React, { useEffect, useMemo, useState } from "react";
import { useForm} from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { basicDetail, editMode, statusDD, vehicleBreadcrumbD,} from "../CreateVehicleTrim/constant"
import { Box, Container, Grid } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";

const CreateVehicleTrimComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateVehicleTrimScreen,
    createVehicleTrim,
    editVehicleTrim,
    isLoading,
    isVehicleTrimCreateSuccess,
    isVehicleTrimCreateError,
    isVehicleTrimDetailSuccess,
    isVehicleTrimDetailError,
    isVehicleTrimEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  } = props;
  console.log("check props of compoemnt", props);
 

  const classes = useStyles()
 
  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

  const breadcrumbD = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/vehicle-make", label: "Vehicle Trim" }
  ]
   const navigateToVehicleTrimScreen = () => {
    window.location.replace("/#"+APP_URLS.VEHICLE_TRIM.HOME)
  };
  useEffect(() => {
    
    if (isVehicleTrimCreateSuccess || isVehicleTrimEditSuccess) {
      reset();
    }
  }, [isVehicleTrimCreateSuccess]);
  useEffect(() => {
    if (isVehicleTrimDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isVehicleTrimDetailSuccess]);
  useEffect(() => {
    initCreateVehicleTrimScreen();
  }, []);
  
  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    
    const stringifiedData = {
      ...newData,
    }

      isEdit
        ? editVehicleTrim({ id: data.id, data: stringifiedData })
        : createVehicleTrim(stringifiedData);
    
  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
};

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Vehicle Trim</h4>
          </div>
          <div className="buttonarea">
          <Button
            tooltip={"Back to Vehicle Trim"}
            tooltipOptions={{ position: "left" }}
            label={"Back to Vehicle Trim"}
            // icon={"pi pi-arrow-left"}
            className={`p-button `}
            style={{ borderRadius: "10px" }}
            onClick={navigateToVehicleTrimScreen}
          />
        </div>
        </div>
      </div>
    );
  };
 
  return (
    <Container maxWidth="lg">
      <Breadcrumb list={vehicleBreadcrumbD} />
      <EzToast {...getToastProps()} /> 
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
         
              <Grid container spacing={2}>
                {basicDetail?.map((item) => (
                  item.name === "status" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={statusDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ):
                  item.name === "modelId" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={vehicleModelDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  )
                  
                  
                  
                  :(
                  (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
                ))}

              </Grid>
           

            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Update" : "Save"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
            {/* {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
} */}
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateVehicleTrimComponent;
