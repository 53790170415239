import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    loginbutton:{
        background: theme.palette.background.bluebg
       },
       loginerror:{
        fontSize:"12px",
        textAlign:"center",
        color:"red",
        paddingTop:"10px",
        marginBottom:"0px"
       },
       loginsuccess:{
        fontSize:"12px",
        textAlign:"center",
        color:"green",
        paddingTop:"10px",
        marginBottom:"0px"
       },
       logout:{
        color:`#000000 !important`
       },
       buttonpan:{
        display:"flex"
       },
       forgot:{
        color:theme.palette.text.link +`!important`,
        fontSize:`12px !important`,
        textTransform:`capitalize !important`
       },
        back:{
        marginLeft:"20px  !important",
        
       }
       
}));