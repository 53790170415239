import {  arrayBufferDownloadExcel } from "../../../utils/download";
import { decorateEmptyGridValues } from "../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../types/uploadimage.type";
import {
  FETCH_HOLIDAYS_LIST, 
  TOAST, 
  FETCH_HOLIDAYS_INIT,
  FETCH_HOLIDAYS_DETAIL,
  EXPORT_HOLIDAYS,
  CREATE_HOLIDAYS,
  EDIT_HOLIDAYS,
  INIT_CREATE_HOLIDAYS_SCREEN,
  HOLIDAYSDD,
  HOLIDAYSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../types/Holidays/Holidays.type";
 
  const formFieldValueMap = {
  id:null,
  date: "",
  hourEnd: "",
  hourStart: "",
  ip: "",
  locationId: [],
  modBy: "",
  modTime: "",
  userId: 0
  };
  
  const INITIAL_STATE = {
    HolidaysListview: [],
    HolidaysDD:[],
    isHolidaysDDSuccess: false,
    isHolidaysDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isHolidaysCreateSuccess: false,
    isHolidaysCreateError: false,
    isHolidaysDetailSuccess: false,
    isHolidaysDetailError: false,
    isHolidaysEditSuccess: false,
    isHolidaysEditError: false,
    isHolidaysExportSuccess: false,
    isHolidaysExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isHolidaysDeleteSuccess: false,
    isHolidaysDeleteError: false,
  };

  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        locationTitles,
        date,
        hourEnd,
        hourStart,
        crtBy,
        crtTime,
        modBy,
        modTime,
      } = item;
  
      const transformedValues = {
        id,
        locationTitles,
        date,
        hourEnd,
        hourStart,
    crtBy,
    crtTime,
    modBy,
    modTime,
  
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location(s)",
        dataKey: "locationTitles",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },{
        colLabel: "Date",
        dataKey: "date",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Hour End",
        dataKey: "hourEnd",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Hour Start",
        dataKey: "hourStart",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const holidaysReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_HOLIDAYS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_HOLIDAYS_DETAIL.START:
      case EXPORT_HOLIDAYS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_HOLIDAYS_LIST.START:
      case CREATE_HOLIDAYS.START:
        case EDIT_HOLIDAYS.START:
          case HOLIDAYSDD.START:
          case HOLIDAYSDELETE.START:
        return { ...state, isLoading: true };
  
      case FETCH_HOLIDAYS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          HolidaysListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isHolidaysCreateSuccess: false,
          isHolidaysCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          HolidaysListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_HOLIDAYS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          HolidaysListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isHolidaysDeletionSuccess: false,
          isHolidaysDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_HOLIDAYS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];

              if(key==="date"){
                temp[key] = new Date(action.payload[key])
              }
      
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isHolidaysDetailSuccess: true,
      };
    }
    case FETCH_HOLIDAYS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isHolidaysDetailSuccess: false,
        isHolidaysDetailError: true,
      };
    }

    //DD
    case HOLIDAYSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        HolidaysDD:temp,
        isHolidaysDDSuccess: true,
        isHolidaysDDError: false,
      };
    }
    case HOLIDAYSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isHolidaysDDSuccess: false,
        isHolidaysDDError: true,
      };
    }
    //END DD
      
  //DELETE

  case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
    const shouldOpenDeleteConfirmationPopup = action.payload;
    return {
      ...state,
      shouldOpenDeleteConfirmationPopup,
      isHolidaysDeleteSuccess: false,
      isHolidaysDeleteError: false,
    };
  }
  case HOLIDAYSDELETE.SUCCESS: {
    const temp = action.payload;

    return {
      ...state,
      isLoading: false,
      RatesDD:temp,
      isHolidaysDeleteSuccess: true,
      isHolidaysDeleteError: false,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  case HOLIDAYSDELETE.ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      error,
      isRateAdminRatesDeleteSuccess: false,
      isRateAdminRatesDeleteError: true,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  //END DD
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_HOLIDAYS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isHolidaysExportSuccess: false,
    isHolidaysExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_HOLIDAYS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"holidays.xls");
   return {
    ...state,
    isHolidaysExportSuccess: true,
    isHolidaysExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_HOLIDAYS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isHolidaysEditSuccess: false,
    isHolidaysEditError: true,
  };
}

case EDIT_HOLIDAYS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isHolidaysEditSuccess: true,
    isHolidaysEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_HOLIDAYS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_HOLIDAYS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isHolidaysCreateSuccess: false,
    isHolidaysCreateError: true,
  };
}
case CREATE_HOLIDAYS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isHolidaysCreateSuccess: true,
    isHolidaysCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isHolidaysCreateSuccess: false,
          isHolidaysCreateError: false,
          isHolidaysEditError: false,
          isHolidaysEditSuccess: false,
          isHolidaysDetailError: false
        };
      }
      default:
        return state;
    }
  };
  