

export const FETCH_VEHICLE_MAKE_LIST = {
    START: 'FETCH_VEHICLE_MAKE_LIST_START',
    SUCCESS: 'FETCH_VEHICLE_MAKE_LIST_SUCCESS',
    ERROR: 'FETCH_VEHICLE_MAKE_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_VEHICLE_MAKE_INIT="FETCH_VEHICLE_MAKE_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_VEHICLE_MAKE_SCREEN =
'INIT_CREATE_VEHICLE_MAKE_SCREEN';

export const CREATE_VEHICLE_MAKE = {
START: 'CREATE_VEHICLE_MAKE_START',
SUCCESS: 'CREATE_VEHICLE_MAKE_SUCCESS',
ERROR: 'CREATE_VEHICLE_MAKE_ERROR',
};

export const EDIT_VEHICLE_MAKE = {
START: 'EDIT_VEHICLE_MAKE_START',
SUCCESS: 'EDIT_VEHICLE_MAKE_SUCCESS',
ERROR: 'EDIT_VEHICLE_MAKE_ERROR',
};

export const FETCH_VEHICLE_MAKE_DETAIL = {
  START: 'FETCH_VEHICLE_MAKE_DETAIL_START',
  SUCCESS: 'FETCH_VEHICLE_MAKE_DETAIL_SUCCESS',
  ERROR: 'FETCH_VEHICLE_MAKE_DETAIL_ERROR',
};
export const EXPORT_VEHICLE_MAKE = {
  START: 'EXPORT_VEHICLE_MAKE_START',
  SUCCESS: 'EXPORT_VEHICLE_MAKE_SUCCESS',
  ERROR: 'EXPORT_VEHICLE_MAKE_ERROR',
};
export const VEHICLE_MAKEDD = {
  START: 'VEHICLE_MAKEDD_START',
  SUCCESS: 'VEHICLE_MAKEDD_SUCCESS',
  ERROR: 'VEHICLE_MAKEDD_ERROR',
};