import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleBodyShopScreen,
  createVehicleBodyShop,
  editVehicleBodyShop,
  fetchVehicleBodyShopById,
  uploadImage,
} from "../../../../redux/actions/vehicles/vehicleBodyShop";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleBodyShopComponent from "../../../../component/Vehicles/VehicleBodyShop/CreateVehicleBodyShop";
import { useParams } from "react-router-dom";

const CreateVehicleBodyShopContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleBodyShop,
    editVehicleBodyShop,
    isLoading,
    isVehicleBodyShopCreateSuccess,
    isVehicleBodyShopCreateError,
    isVehicleBodyShopDetailSuccess,
    isVehicleBodyShopDetailError,
    isVehicleBodyShopEditSuccess,
    isVehicleBodyShopEditError,
    isEdit,
    isView,
    initCreateVehicleBodyShopScreen,
    formFieldValueMap,
    fetchVehicleBodyShopById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
  } = props;

  const { id } = useParams();
  const callAPIasync = async() =>{
    initCreateVehicleBodyShopScreen();
   if (isEdit) {
    await fetchVehicleBodyShopById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleBodyShopProps = {
    createVehicleBodyShop,
    initCreateVehicleBodyShopScreen,
    editVehicleBodyShop,
    isLoading,
    isEdit,
    isVehicleBodyShopDetailSuccess,
    isVehicleBodyShopCreateSuccess,
    isVehicleBodyShopEditSuccess,
    isVehicleBodyShopEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
  };
  const getToastProps = () => {
    if (isVehicleBodyShopCreateSuccess || isVehicleBodyShopEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleBodyShop Updated Successfully"
        : "VehicleBodyShop Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleBodyShopCreateError ||
      isVehicleBodyShopDetailError ||
      isVehicleBodyShopEditError
    ) {
      let toastTitle = "Error while Creating Vehicle BodyShop";
      if (isVehicleBodyShopEditError) {
        toastTitle = "Error while updating Vehicle BodyShop";
      } else if (isVehicleBodyShopDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleBodyShopComponent {...createVehicleBodyShopProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleBodyShopReducer(state, "isLoading"),
   
    isVehicleBodyShopCreateSuccess: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleBodyShopCreateSuccess"
    ),
    isVehicleBodyShopCreateError: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleBodyShopCreateError"
    ),
    isVehicleBodyShopDetailSuccess: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleBodyShopDetailSuccess"
    ),
    isVehicleBodyShopDetailError: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleBodyShopDetailError"
    ),
    isVehicleBodyShopEditSuccess: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleBodyShopEditSuccess"
    ),
    isVehicleBodyShopEditError: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleBodyShopEditError"
    ),
    imageUploadResponse: selectFromVehicleBodyShopReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleBodyShopReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleBodyShopReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromVehicleBodyShopReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleBodyShopReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleBodyShopReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleBodyShopScreen: () => dispatch(initCreateVehicleBodyShopScreen()),
    createVehicleBodyShop: (payload) => dispatch(createVehicleBodyShop(payload)),
    editVehicleBodyShop: (payload) => dispatch(editVehicleBodyShop(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleBodyShopById: (payload) =>
      dispatch(fetchVehicleBodyShopById(payload)),

    
  };
};

const selectFromVehicleBodyShopReducer = (state, path) => {
  return selectVehicleBodyShopReducer(state)[path];
};

const selectVehicleBodyShopReducer = ({ vehicleBodyShopReducer }) => {
  return vehicleBodyShopReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleBodyShopContainer);
