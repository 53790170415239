import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateSettingsPaynowDiscountsScreen,
  createSettingsPaynowDiscounts,
  editSettingsPaynowDiscounts,
  fetchSettingsPaynowDiscountsById,
  uploadImage,
} from "../../../../redux/actions/Settings/SettingsPaynowDiscounts";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateSettingsPaynowDiscountsComponent from "../../../../component/Settings/SettingsPaynowDiscounts/CreateSettingsPaynowDiscounts";
import { useParams } from "react-router-dom";

const CreateSettingsPaynowDiscountsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createSettingsPaynowDiscounts,
    editSettingsPaynowDiscounts,
    isLoading,
    isSettingsPaynowDiscountsCreateSuccess,
    isSettingsPaynowDiscountsCreateError,
    isSettingsPaynowDiscountsDetailSuccess,
    isSettingsPaynowDiscountsDetailError,
    isSettingsPaynowDiscountsEditSuccess,
    isSettingsPaynowDiscountsEditError,
    isEdit,
    isView,
    initCreateSettingsPaynowDiscountsScreen,
    formFieldValueMap,
    fetchSettingsPaynowDiscountsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateSettingsPaynowDiscountsScreen();
   if (isEdit) {
    await fetchSettingsPaynowDiscountsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createSettingsPaynowDiscountsProps = {
    createSettingsPaynowDiscounts,
    initCreateSettingsPaynowDiscountsScreen,
    editSettingsPaynowDiscounts,
    isLoading,
    isEdit,
    isSettingsPaynowDiscountsDetailSuccess,
    isSettingsPaynowDiscountsCreateSuccess,
    isSettingsPaynowDiscountsEditSuccess,
    isSettingsPaynowDiscountsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isSettingsPaynowDiscountsCreateSuccess || isSettingsPaynowDiscountsEditSuccess) {
      const toastTitle = isEdit
        ? "Paynow Discounts Updated Successfully"
        : "Paynow Discounts Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isSettingsPaynowDiscountsCreateError ||
      isSettingsPaynowDiscountsDetailError ||
      isSettingsPaynowDiscountsEditError
    ) {
      let toastTitle = "Error while Creating Paynow Discounts";
      if (isSettingsPaynowDiscountsEditError) {
        toastTitle = "Error while updating  Paynow Discounts";
      } else if (isSettingsPaynowDiscountsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateSettingsPaynowDiscountsComponent {...createSettingsPaynowDiscountsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromSettingsPaynowDiscountsReducer(state, "isLoading"),
   
    isSettingsPaynowDiscountsCreateSuccess: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isSettingsPaynowDiscountsCreateSuccess"
    ),
    isSettingsPaynowDiscountsCreateError: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isSettingsPaynowDiscountsCreateError"
    ),
    isSettingsPaynowDiscountsDetailSuccess: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isSettingsPaynowDiscountsDetailSuccess"
    ),
    isSettingsPaynowDiscountsDetailError: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isSettingsPaynowDiscountsDetailError"
    ),
    isSettingsPaynowDiscountsEditSuccess: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isSettingsPaynowDiscountsEditSuccess"
    ),
    isSettingsPaynowDiscountsEditError: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isSettingsPaynowDiscountsEditError"
    ),
    imageUploadResponse: selectFromSettingsPaynowDiscountsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromSettingsPaynowDiscountsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromSettingsPaynowDiscountsReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromSettingsPaynowDiscountsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateSettingsPaynowDiscountsScreen: () => dispatch(initCreateSettingsPaynowDiscountsScreen()),
    createSettingsPaynowDiscounts: (payload) => dispatch(createSettingsPaynowDiscounts(payload)),
    editSettingsPaynowDiscounts: (payload) => dispatch(editSettingsPaynowDiscounts(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchSettingsPaynowDiscountsById: (payload) =>
      dispatch(fetchSettingsPaynowDiscountsById(payload)),

  };
};

const selectFromSettingsPaynowDiscountsReducer = (state, path) => {
  return selectSettingsPaynowDiscountsReducer(state)[path];
};

const selectSettingsPaynowDiscountsReducer = ({ SettingsPaynowDiscountsReducer }) => {
  return SettingsPaynowDiscountsReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSettingsPaynowDiscountsContainer);
