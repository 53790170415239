import React from 'react'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useStyles } from './style';
export default function Breadcrumb({list}) {
    const classes = useStyles()
  return (
    <div className={classes.breadcrumb}>
        <ul>
        {list.map((item, idx)=>(
            idx < (list.length-1) ? 
            <li>
              <Link to={item?.path}>
              {item?.label}  <ArrowForwardIosIcon className={classes.bicon}/>
           </Link>
           </li>
           :
           <li>
           {item?.label} 
        </li>
        ))}
       </ul>
    </div>
  )
}
