import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminLorAdjustmentsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_LORADJUSTMENTS_INIT, 
  FETCH_RATEADMIN_LORADJUSTMENTS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_LORADJUSTMENTS_SCREEN,
  CREATE_RATEADMIN_LORADJUSTMENTS,
  EDIT_RATEADMIN_LORADJUSTMENTS,
  TOAST,
  FETCH_RATEADMIN_LORADJUSTMENTS_DETAIL,
  EXPORT_RATEADMIN_LORADJUSTMENTS,
  RATEADMIN_LORADJUSTMENTSDD
} from "../../../types/rateadminloradjustments.type";
export const initializeRateAdminLorAdjustmentsScreen = () => ({ type: FETCH_RATEADMIN_LORADJUSTMENTS_INIT });

export const rateadminLorAdjustmentsFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_LORADJUSTMENTS_LIST.START,
  payload,
});

export const rateadminLorAdjustmentsFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_LORADJUSTMENTS_LIST.SUCCESS,
  payload,
});

export const rateadminLorAdjustmentsFetchError = (payload) => ({
  type: FETCH_RATEADMIN_LORADJUSTMENTS_LIST.ERROR,
  payload,
});

export const fetchRateAdminLorAdjustments = (payload) => {
  return (dispatch) => {
    dispatch(rateadminLorAdjustmentsFetchStart(payload));
    rateAdminLorAdjustmentsServices.fetchRateAdminLorAdjustments(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminLorAdjustmentsFetchSuccess(data));
      } else {
        dispatch(rateadminLorAdjustmentsFetchError(data));
      }
    });
  };
};



// DD
export const rateadminLorAdjustmentsDDFetchStart = (payload) => ({
  type: RATEADMIN_LORADJUSTMENTSDD.START,
  payload,
});

export const rateadminLorAdjustmentsDDFetchSuccess = (payload) => ({
  type:RATEADMIN_LORADJUSTMENTSDD.SUCCESS,
  payload,
});

export const rateadminLorAdjustmentsDDFetchError = (payload) => ({
  type: RATEADMIN_LORADJUSTMENTSDD.ERROR,
  payload,
});

export const fetchRateAdminLorAdjustmentsDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminLorAdjustmentsDDFetchStart(payload));
    rateAdminLorAdjustmentsServices.fetchRateAdminLorAdjustmentsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminLorAdjustmentsDDFetchSuccess(data));
      } else {
        dispatch(rateadminLorAdjustmentsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminLorAdjustmentsDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_LORADJUSTMENTS_DETAIL.START,
  payload,
});

export const rateadminLorAdjustmentsDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_LORADJUSTMENTS_DETAIL.SUCCESS,
  payload,
});

export const rateadminLorAdjustmentsDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_LORADJUSTMENTS_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminLorAdjustmentsById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminLorAdjustmentsDetailFetchStart(payload));

    rateAdminLorAdjustmentsServices
      .fetchRateAdminLorAdjustmentsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminLorAdjustmentsDetailFetchSuccess(data));
        } else {
          dispatch(rateadminLorAdjustmentsDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminLorAdjustmentsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminLorAdjustmentsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_LORADJUSTMENTS_SCREEN,
  payload,
});

export const initCreateRateAdminLorAdjustmentsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminLorAdjustmentsScreenInitStart());
  };
};


export const createRateAdminLorAdjustmentsStart = () => ({
  type: CREATE_RATEADMIN_LORADJUSTMENTS.START,
});

export const createRateAdminLorAdjustmentsSuccess = (payload) => ({
  type: CREATE_RATEADMIN_LORADJUSTMENTS.SUCCESS,
  payload,
});

export const createRateAdminLorAdjustmentsError = (payload) => ({
  type: CREATE_RATEADMIN_LORADJUSTMENTS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminLorAdjustments = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminLorAdjustmentsStart());
    rateAdminLorAdjustmentsServices.createRateAdminLorAdjustments(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminLorAdjustmentsSuccess());
        goBack();
      } else {
        dispatch(createRateAdminLorAdjustmentsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminLorAdjustmentsStart = (payload) => ({
  type: EDIT_RATEADMIN_LORADJUSTMENTS.START,
  payload
});

export const editRateAdminLorAdjustmentsSuccess = (payload) => ({
  type: EDIT_RATEADMIN_LORADJUSTMENTS.SUCCESS,
  payload,
});

export const editRateAdminLorAdjustmentsError = (payload) => ({
  type: EDIT_RATEADMIN_LORADJUSTMENTS.ERROR,
  payload,
});

export const editRateAdminLorAdjustments = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminLorAdjustmentsStart());
    rateAdminLorAdjustmentsServices.editRateAdminLorAdjustments(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminLorAdjustmentsSuccess());
        goBack();
      } else {
        dispatch(editRateAdminLorAdjustmentsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminLorAdjustmentsRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_LORADJUSTMENTS.START,
  payload
});

export const exportRateAdminLorAdjustmentsRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_LORADJUSTMENTS.SUCCESS,
  payload,
});

export const exportRateAdminLorAdjustmentsRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_LORADJUSTMENTS.ERROR,
  payload,
});

export const exportRateAdminLorAdjustments = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminLorAdjustmentsRecordStart());
    rateAdminLorAdjustmentsServices.exportRateAdminLorAdjustmentsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminLorAdjustmentsRecordSuccess(data));
      } else {
        dispatch(exportRateAdminLorAdjustmentsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

