export const basicDetail =
[
  {
    name:"make",
     "rule": {
        "required": true
      },
    label:"Make",
    type:"dropdown"
  },
  {
    name:"model",
     "rule": {
        "required": true
      },
    label:"Model",
    type:"dropdown"
  },
  {
    name:"modelYear",
     "rule": {
        "required": false
      },
    label:"Model Year",
    type:"text"
  },
  {
    name:"trim",
     "rule": {
        "required": false
      },
    label:"Trim",
    type:"dropdown"
  },
  {
    name:"fkSupplierId",
     "rule": {
        "required": false
      },
    label:"Dealership",
    type:"dropdown"
  },
  {
    name:"purchaseType",
     "rule": {
        "required": false
      },
    label:"Purchase Type",
    type:"dropdown"
  },
  {
    name:"extColor",
     "rule": {
        "required": true
      },
    label:"Ext Color",
    type:"text"
  }, {
    name:"intColor",
     "rule": {
        "required": false
      },
    label:"Int Color",
    type:"text"
  },
  {
    name:"fkCategoryId",
     "rule": {
        "required": false
      },
    label:"Category",
    type:"dropdown"
  },{
    name:"fkLocationId",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"dropdown"
  },
  {
    name:"plateNumber",
     "rule": {
        "required": false
      },
    label:"Plate Number",
    type:"text"
  },
  {
    name:"vinNumber",
     "rule": {
        "required": true
      },
    label:"Vin Number",
    type:"text"
  },
  {
    name:"keyCode",
     "rule": {
        "required": false
      },
    label:"Key Code",
    type:"text"
  },
  {
    name:"vehicleNumber",
     "rule": {
        "required": false
      },
    label:"Vehicle Number",
    type:"text"
  },
  {
    name:"activeStatus",
     "rule": {
        "required": false
      },
    label:"Vehicle Status",
    type:"dropdown"
  },
  {
    name:"startingMileage",
     "rule": {
        "required": false
      },
    label:"Starting Mileage",
    type:"text"
  },
  {
    name:"maxMileageAllowed",
     "rule": {
        "required": false
      },
    label:"Max Mileage Allowed",
    type:"text"
  },
  {
    name:"maxMileageMonthly",
     "rule": {
        "required": false
      },
    label:"Max Mileage Allowed Monthly",
    type:"text"
  },
  {
    name:"purchaseDate",
     "rule": {
        "required": false
      },
    label:"Purchase Date",
    type:"date"
  },
  {
    name:"purchasePrice",
     "rule": {
        "required": false
      },
    label:"Purchase Price",
    type:"text"
  },
  {
    name:"purchaseRepairCost",
     "rule": {
        "required": false
      },
    label:"Licensing Cost",
    type:"text"
  },
  {
    name:"saleDate",
     "rule": {
        "required": false
      },
    label:"Estimated Sale Date",
    type:"date"
  },
  {
    name:"salePrice",
     "rule": {
        "required": false
      },
    label:"Estimated Sale Price",
    type:"text"
  },
  {
    name:"groundingDate",
     "rule": {
        "required": false
      },
    label:"Grounding Date",
    type:"date"
  },
  {
    name:"estimatedDepreciation",
     "rule": {
        "required": false
      },
    label:"Est. Depriciation (monthly)",
    type:"text"
  },
  {
    name:"actualDepreciation",
     "rule": {
        "required": false
      },
    label:"Actual Depriciation (monthly)",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": false
      },
    label:"Status",
    type:"dropdown"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const purchaseTypeDD =
[
  { id:1, name:"Owned"},
  { id:2, name:"Financed"},
  { id:3, name:"Leased"}
]

export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
