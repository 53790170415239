import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleModelServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_MODEL_INIT, 
  FETCH_VEHICLE_MODEL_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_MODEL_SCREEN,
  CREATE_VEHICLE_MODEL,
  EDIT_VEHICLE_MODEL,
  TOAST,
  FETCH_VEHICLE_MODEL_DETAIL,
  EXPORT_VEHICLE_MODEL,
  VEHICLE_MODELDD
} from "../../../types/vehiclemodel.type";
export const initializeVehicleModelScreen = () => ({ type: FETCH_VEHICLE_MODEL_INIT });

export const vehicleModelFetchStart = (payload) => ({
  type: FETCH_VEHICLE_MODEL_LIST.START,
  payload,
});

export const vehicleModelFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_MODEL_LIST.SUCCESS,
  payload,
});

export const vehicleModelFetchError = (payload) => ({
  type: FETCH_VEHICLE_MODEL_LIST.ERROR,
  payload,
});

export const fetchVehicleModel = (payload) => {
  return (dispatch) => {
    dispatch(vehicleModelFetchStart(payload));
    vehicleModelServices.fetchVehicleModel(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleModelFetchSuccess(data));
      } else {
        dispatch(vehicleModelFetchError(data));
      }
    });
  };
};



// DD
export const vehicleModelDDFetchStart = (payload) => ({
  type: VEHICLE_MODELDD.START,
  payload,
});

export const vehicleModelDDFetchSuccess = (payload) => ({
  type:VEHICLE_MODELDD.SUCCESS,
  payload,
});

export const vehicleModelDDFetchError = (payload) => ({
  type: VEHICLE_MODELDD.ERROR,
  payload,
});

export const fetchVehicleModelDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleModelDDFetchStart(payload));
    vehicleModelServices.fetchVehicleModelDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleModelDDFetchSuccess(data));
      } else {
        dispatch(vehicleModelDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleModelDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_MODEL_DETAIL.START,
  payload,
});

export const vehicleModelDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_MODEL_DETAIL.SUCCESS,
  payload,
});

export const vehicleModelDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_MODEL_DETAIL.ERROR,
  payload,
});

export const fetchVehicleModelById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleModelDetailFetchStart(payload));

    vehicleModelServices
      .fetchVehicleModelById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleModelDetailFetchSuccess(data));
        } else {
          dispatch(vehicleModelDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleModelServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleModelScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_MODEL_SCREEN,
  payload,
});

export const initCreateVehicleModelScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleModelScreenInitStart());
  };
};


export const createVehicleModelStart = () => ({
  type: CREATE_VEHICLE_MODEL.START,
});

export const createVehicleModelSuccess = (payload) => ({
  type: CREATE_VEHICLE_MODEL.SUCCESS,
  payload,
});

export const createVehicleModelError = (payload) => ({
  type: CREATE_VEHICLE_MODEL.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleModel = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleModelStart());
    vehicleModelServices.createVehicleModel(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleModelSuccess());
        goBack();
      } else {
        dispatch(createVehicleModelError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleModelStart = (payload) => ({
  type: EDIT_VEHICLE_MODEL.START,
  payload
});

export const editVehicleModelSuccess = (payload) => ({
  type: EDIT_VEHICLE_MODEL.SUCCESS,
  payload,
});

export const editVehicleModelError = (payload) => ({
  type: EDIT_VEHICLE_MODEL.ERROR,
  payload,
});

export const editVehicleModel = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleModelStart());
    vehicleModelServices.editVehicleModel(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleModelSuccess());
        goBack();
      } else {
        dispatch(editVehicleModelError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleModelRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_MODEL.START,
  payload
});

export const exportVehicleModelRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_MODEL.SUCCESS,
  payload,
});

export const exportVehicleModelRecordError = (payload) => ({
  type: EXPORT_VEHICLE_MODEL.ERROR,
  payload,
});

export const exportVehicleModel = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleModelRecordStart());
    vehicleModelServices.exportVehicleModelRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleModelRecordSuccess(data));
      } else {
        dispatch(exportVehicleModelRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

