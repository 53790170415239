

export const FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST = {
    START: 'FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST_START',
    SUCCESS: 'FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST_SUCCESS',
    ERROR: 'FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_INIT="FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SCREEN =
'INIT_CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SCREEN';

export const CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT = {
START: 'CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_START',
SUCCESS: 'CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SUCCESS',
ERROR: 'CREATE_RESERVATIONS_BOOKINGBYLOCATIONREPORT_ERROR',
};

export const EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT = {
START: 'EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT_START',
SUCCESS: 'EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SUCCESS',
ERROR: 'EDIT_RESERVATIONS_BOOKINGBYLOCATIONREPORT_ERROR',
};

export const FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL = {
  START: 'FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL_START',
  SUCCESS: 'FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL_SUCCESS',
  ERROR: 'FETCH_RESERVATIONS_BOOKINGBYLOCATIONREPORT_DETAIL_ERROR',
};
export const EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT = {
  START: 'EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT_START',
  SUCCESS: 'EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT_SUCCESS',
  ERROR: 'EXPORT_RESERVATIONS_BOOKINGBYLOCATIONREPORT_ERROR',
};
export const RESERVATIONS_BOOKINGBYLOCATIONREPORTDD = {
  START: 'RESERVATIONS_BOOKINGBYLOCATIONREPORTDD_START',
  SUCCESS: 'RESERVATIONS_BOOKINGBYLOCATIONREPORTDD_SUCCESS',
  ERROR: 'RESERVATIONS_BOOKINGBYLOCATIONREPORTDD_ERROR',
};
export const RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE = {
  START: 'RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE_START',
  SUCCESS: 'RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE_SUCCESS',
  ERROR: 'RESERVATIONS_BOOKINGBYLOCATIONREPORTDELETE_ERROR',
};

