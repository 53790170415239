import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { RentersNonRevenueAgreementsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_NONREVENUEAGREEMENTS_INIT, 
  FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RENTERS_NONREVENUEAGREEMENTS_SCREEN,
  CREATE_RENTERS_NONREVENUEAGREEMENTS,
  EDIT_RENTERS_NONREVENUEAGREEMENTS,
  TOAST,
  FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL,
  EXPORT_RENTERS_NONREVENUEAGREEMENTS,
  RENTERS_NONREVENUEAGREEMENTSDD,
  RENTERS_NONREVENUEAGREEMENTSDELETE
} from "../../../types/Renters/NonRevenueAgreements.type";
export const initializeRentersNonRevenueAgreementsScreen = () => ({ type: FETCH_RENTERS_NONREVENUEAGREEMENTS_INIT });

export const RentersNonRevenueAgreementsFetchStart = (payload) => ({
  type: FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST.START,
  payload,
});

export const RentersNonRevenueAgreementsFetchSuccess = (payload) => ({
  type:FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST.SUCCESS,
  payload,
});

export const RentersNonRevenueAgreementsFetchError = (payload) => ({
  type: FETCH_RENTERS_NONREVENUEAGREEMENTS_LIST.ERROR,
  payload,
});

export const fetchRentersNonRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(RentersNonRevenueAgreementsFetchStart(payload));
    RentersNonRevenueAgreementsServices.fetchRentersNonRevenueAgreements(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersNonRevenueAgreementsFetchSuccess(data));
      } else {
        dispatch(RentersNonRevenueAgreementsFetchError(data));
      }
    });
  };
};



// DD
export const RentersNonRevenueAgreementsDDFetchStart = (payload) => ({
  type: RENTERS_NONREVENUEAGREEMENTSDD.START,
  payload,
});

export const RentersNonRevenueAgreementsDDFetchSuccess = (payload) => ({
  type:RENTERS_NONREVENUEAGREEMENTSDD.SUCCESS,
  payload,
});

export const RentersNonRevenueAgreementsDDFetchError = (payload) => ({
  type: RENTERS_NONREVENUEAGREEMENTSDD.ERROR,
  payload,
});

export const fetchRentersNonRevenueAgreementsDD = (payload) => {
  return (dispatch) => {
    dispatch(RentersNonRevenueAgreementsDDFetchStart(payload));
    RentersNonRevenueAgreementsServices.fetchRentersNonRevenueAgreementsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersNonRevenueAgreementsDDFetchSuccess(data));
      } else {
        dispatch(RentersNonRevenueAgreementsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const RentersNonRevenueAgreementsDetailFetchStart = (payload) => ({
  type: FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL.START,
  payload,
});

export const RentersNonRevenueAgreementsDetailFetchSuccess = (payload) => ({
  type: FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL.SUCCESS,
  payload,
});

export const RentersNonRevenueAgreementsDetailFetchError = (payload) => ({
  type: FETCH_RENTERS_NONREVENUEAGREEMENTS_DETAIL.ERROR,
  payload,
});

export const fetchRentersNonRevenueAgreementsById = (payload) => {
  return (dispatch) => {
    dispatch(RentersNonRevenueAgreementsDetailFetchStart(payload));

    RentersNonRevenueAgreementsServices
      .fetchRentersNonRevenueAgreementsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersNonRevenueAgreementsDetailFetchSuccess(data));
        } else {
          dispatch(RentersNonRevenueAgreementsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    RentersNonRevenueAgreementsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRentersNonRevenueAgreementsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RENTERS_NONREVENUEAGREEMENTS_SCREEN,
  payload,
});

export const initCreateRentersNonRevenueAgreementsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRentersNonRevenueAgreementsScreenInitStart());
  };
};


export const createRentersNonRevenueAgreementsStart = () => ({
  type: CREATE_RENTERS_NONREVENUEAGREEMENTS.START,
});

export const createRentersNonRevenueAgreementsSuccess = (payload) => ({
  type: CREATE_RENTERS_NONREVENUEAGREEMENTS.SUCCESS,
  payload,
});

export const createRentersNonRevenueAgreementsError = (payload) => ({
  type: CREATE_RENTERS_NONREVENUEAGREEMENTS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRentersNonRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(createRentersNonRevenueAgreementsStart());
    RentersNonRevenueAgreementsServices.createRentersNonRevenueAgreements(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRentersNonRevenueAgreementsSuccess());
        goBack();
      } else {
        dispatch(createRentersNonRevenueAgreementsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRentersNonRevenueAgreementsStart = (payload) => ({
  type: EDIT_RENTERS_NONREVENUEAGREEMENTS.START,
  payload
});

export const editRentersNonRevenueAgreementsSuccess = (payload) => ({
  type: EDIT_RENTERS_NONREVENUEAGREEMENTS.SUCCESS,
  payload,
});

export const editRentersNonRevenueAgreementsError = (payload) => ({
  type: EDIT_RENTERS_NONREVENUEAGREEMENTS.ERROR,
  payload,
});

export const editRentersNonRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(editRentersNonRevenueAgreementsStart());
    RentersNonRevenueAgreementsServices.editRentersNonRevenueAgreements(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRentersNonRevenueAgreementsSuccess());
        goBack();
      } else {
        dispatch(editRentersNonRevenueAgreementsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRentersNonRevenueAgreementsRecordStart = (payload) => ({
  type: EXPORT_RENTERS_NONREVENUEAGREEMENTS.START,
  payload
});

export const exportRentersNonRevenueAgreementsRecordSuccess = (payload) => ({
  type: EXPORT_RENTERS_NONREVENUEAGREEMENTS.SUCCESS,
  payload,
});

export const exportRentersNonRevenueAgreementsRecordError = (payload) => ({
  type: EXPORT_RENTERS_NONREVENUEAGREEMENTS.ERROR,
  payload,
});

export const exportRentersNonRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(exportRentersNonRevenueAgreementsRecordStart());
    RentersNonRevenueAgreementsServices.exportRentersNonRevenueAgreementsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRentersNonRevenueAgreementsRecordSuccess(data));
      } else {
        dispatch(exportRentersNonRevenueAgreementsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const RentersNonRevenueAgreementsDeleteStart = (payload) => ({
  type: RENTERS_NONREVENUEAGREEMENTSDELETE.START,
  payload,
});

export const RentersNonRevenueAgreementsDeleteSuccess = (payload) => ({
  type: RENTERS_NONREVENUEAGREEMENTSDELETE.SUCCESS,
  payload,
});

export const RentersNonRevenueAgreementsDeleteError = (payload) => ({
  type: RENTERS_NONREVENUEAGREEMENTSDELETE.ERROR,
  payload,
});

export const deleteRentersNonRevenueAgreements = (payload) => {
  return (dispatch) => {
    dispatch(RentersNonRevenueAgreementsDeleteStart(payload));

    RentersNonRevenueAgreementsServices
      .deleteRentersNonRevenueAgreements(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersNonRevenueAgreementsDeleteSuccess(data));
          dispatch(fetchRentersNonRevenueAgreements(payload?.params));
        } else {
          dispatch(RentersNonRevenueAgreementsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/