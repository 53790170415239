import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleModelScreen,
  createVehicleModel,
  editVehicleModel,
  fetchVehicleModelById,
  uploadImage,
} from "../../../../redux/actions/vehicles/vehicleModel";
import {
  fetchVehicleMakeDD
} from "../../../../redux/actions/vehicles/vehicleMake";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleModelComponent from "../../../../component/Vehicles/VehicleModel/CreateVehicleModel";
import { useParams } from "react-router-dom";

const CreateVehicleModelContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleModel,
    editVehicleModel,
    isLoading,
    isVehicleModelCreateSuccess,
    isVehicleModelCreateError,
    isVehicleModelDetailSuccess,
    isVehicleModelDetailError,
    isVehicleModelEditSuccess,
    isVehicleModelEditError,
    isEdit,
    isView,
    initCreateVehicleModelScreen,
    formFieldValueMap,
    fetchVehicleModelById,
    fetchVehicleMakeDD,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    vehicleMakeDD,
        isVehicleMakeDDSuccess,
        isVehicleMakeDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleModelScreen();
    await fetchVehicleMakeDD();
   if (isEdit) {
    await fetchVehicleModelById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleModelProps = {
    createVehicleModel,
    initCreateVehicleModelScreen,
    editVehicleModel,
    isLoading,
    isEdit,
    isVehicleModelDetailSuccess,
    isVehicleModelCreateSuccess,
    isVehicleModelEditSuccess,
    isVehicleModelEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleMakeDD,
        isVehicleMakeDDSuccess,
        isVehicleMakeDDError,
  };
  const getToastProps = () => {
    if (isVehicleModelCreateSuccess || isVehicleModelEditSuccess) {
      const toastTitle = isEdit
        ? "VehicleModel Updated Successfully"
        : "VehicleModel Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleModelCreateError ||
      isVehicleModelDetailError ||
      isVehicleModelEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Model";
      if (isVehicleModelEditError) {
        toastTitle = "Error while updating Vehicle Model";
      } else if (isVehicleModelDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleModelComponent {...createVehicleModelProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleModelReducer(state, "isLoading"),
   
    isVehicleModelCreateSuccess: selectFromVehicleModelReducer(
      state,
      "isVehicleModelCreateSuccess"
    ),
    isVehicleModelCreateError: selectFromVehicleModelReducer(
      state,
      "isVehicleModelCreateError"
    ),
    isVehicleModelDetailSuccess: selectFromVehicleModelReducer(
      state,
      "isVehicleModelDetailSuccess"
    ),
    isVehicleModelDetailError: selectFromVehicleModelReducer(
      state,
      "isVehicleModelDetailError"
    ),
    isVehicleModelEditSuccess: selectFromVehicleModelReducer(
      state,
      "isVehicleModelEditSuccess"
    ),
    isVehicleModelEditError: selectFromVehicleModelReducer(
      state,
      "isVehicleModelEditError"
    ),
    imageUploadResponse: selectFromVehicleModelReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleModelReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleModelReducer(
      state,
      "isUploadImageError"
    ),


    vehicleMakeDD: selectFromVehicleVehicleMakeReducer(
      state,
      "vehicleMakeDD"
    ),
    isVehicleMakeDDSuccess: selectFromVehicleModelReducer(
      state,
      "isVehicleMakeDDSuccess"
    ),
    isVehicleMakeDDError: selectFromVehicleModelReducer(
      state,
      "isVehicleMakeDDError"
    ),

    formFieldValueMap: selectFromVehicleModelReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleModelScreen: () => dispatch(initCreateVehicleModelScreen()),
    createVehicleModel: (payload) => dispatch(createVehicleModel(payload)),
    editVehicleModel: (payload) => dispatch(editVehicleModel(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleModelById: (payload) =>
      dispatch(fetchVehicleModelById(payload)),
    fetchVehicleMakeDD: (payload) =>
      dispatch(fetchVehicleMakeDD(payload)),
  };
};

const selectFromVehicleModelReducer = (state, path) => {
  return selectVehicleModelReducer(state)[path];
};

const selectVehicleModelReducer = ({ vehicleModelReducer }) => {
  return vehicleModelReducer;
};


//MAKE
const selectFromVehicleVehicleMakeReducer = (state, path) => {
  return selectVehicleVehicleMakeReducer(state)[path];
};
const selectVehicleVehicleMakeReducer = ({ vehicleMakeReducer }) => {
  return vehicleMakeReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleModelContainer);
