import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getFormBaseValidationRule } from "../../utils/form/form-utils"
import EzControlledInputWithFloatingLabel from '../../common/EzForm/EzControlledInput/WithFloatingLabel';
import { Box, Button, Typography } from '@mui/material';
import styleCss from './style.module.css'
import { postApiLoginAsyn } from "../../services/PostAPI.js"
import { Navigate } from 'react-router-dom';
import EzControlledPasswordWithFloatingLabel from '../../common/EzForm/EZControlledPassword/WithFloatingLabel';
import { useStyles } from './style';
import Logoicon from '../../images/logo.png';
import { API_URL } from '../../utils/constant.js';
import { useQuery } from '../../utils/index.js';
export default function ResetPassword(props) {
  const classes = useStyles()
  const formFieldValueMap = {
    email: "",
    password: "",
  }
  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isForot, setIsForot] = useState(false);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  let query = useQuery();
  let paramValue = query.get('token');

  const handleBacktoLogin =() =>{
    window.location.replace('/');
  }
  const userPassword = (name, label) =>{
    return(
      <EzControlledPasswordWithFloatingLabel
      name={name}
        control={control}
        label={label}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        />
    )
  }


  const onSubmit = async (data) => {

    let sendData = {
      confirmPassword: data.confirmPassword,
      password: data.password,
      token:paramValue
    }
    let res = await postApiLoginAsyn(API_URL.resetpwd, sendData);
   
    if (res.message==="success") {
      setSuccess(true)
      setErrorMessage("Your password has been successfully updated. Please go to the login page to sign in.");
    setTimeout(()=>{
      window.location.replace('/');
    },3000)
    }
    else {
      setError(true);
      setErrorMessage(res?.message);
    }
  }

  return (
    <div id="loginwrap" className={styleCss.loginwrapper}>
    
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <Box className={styleCss.loginbox}>
          <Box className={styleCss.logoadmin}>
            <img src={Logoicon} alt='Rez Revolution' />
          </Box>
          <Box className={styleCss.logotext}>Reset Password

          <Box>
          {error === true ? (
                <Box className={classes.loginerror}>
                  {errorMessage} Please try again.
                </Box>
              ) : null}
                {success === true ? (
                <Box className={classes.loginsuccess}>
                  {errorMessage}
                </Box>
              ) : null}
          </Box>
          </Box>
         
          <Box sx={{ width: '100%' }}>
          <label className={styleCss.user_icon}><span class="pi pi-user"></span></label>
          {userPassword("password", "Enter Password")}
          </Box>
          <Box sx={{ width: '100%' }}>
          <label className={styleCss.user_icon}><span class="pi pi-lock"></span></label>
          {userPassword("confirmPassword", "Confirm Password")}
          </Box>
          <Box className={classes.buttonpan}>
           
            <Button
              variant="contained"
              color="secondary"
              className={classes.loginbutton}
              type="submit">Save</Button>
              <Button
          onClick={handleBacktoLogin}
             className={classes.forgot}
              type="button">Back to Login</Button>
         
        </Box>
        </Box>
      </form>
    
    </div>
  )
}