import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getFormBaseValidationRule } from "../../utils/form/form-utils"
import EzControlledInputWithFloatingLabel from '../../common/EzForm/EzControlledInput/WithFloatingLabel';
import { Box, Button, Typography } from '@mui/material';
import styleCss from './style.module.css'
import { getApiParam } from "../../services/PostAPI.js"
import { Navigate } from 'react-router-dom';
import EzControlledPasswordWithFloatingLabel from '../../common/EzForm/EZControlledPassword/WithFloatingLabel';
import { useStyles } from './style';
import Logoicon from '../../images/logo.png';
import { API_URL } from '../../utils/constant.js';

export default function Forgot(props) {
  const classes = useStyles()
  const formFieldValueMap = {
    email: "",
  }
  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };


  const genericInput = (name, label) => {
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={label}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  

  const onSubmit = async (data) => {
    let res = await getApiParam(API_URL.forgot, `?email=${data?.email}`);
    if (res.message !== "") {
        setSuccess(true)
        setSuccessMessage("We've sent an email to your registered email address for password reset.")
    }
    else {
      setError(true);
      setErrorMessage("Invalid Email");
      localStorage.removeItem("token");
      localStorage.removeItem("username")
    }
  }
  if (
    localStorage.getItem("token") !=="" &&
    localStorage.getItem("token") != null &&
    localStorage.getItem("token") !== undefined
  ) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <div id="loginwrap" className={styleCss.loginwrapper}>
    
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <Box className={styleCss.loginbox}>
          <Box className={styleCss.logoadmin}>
            <img src={Logoicon} alt='Rez Revolution' />
          </Box>
          <Box className={styleCss.logotext}>Forgot Password

          <Box>
          {error === true ? (
                <Box className={classes.loginerror}>
                  {errorMessage} Please enter valid email and try again.
                </Box>
              ) : null}
                {success === true ? (
                <Box className={classes.loginsuccess}>
                  {successMessage}
                </Box>
              ) : null}
          </Box>
          </Box>
         
          <Box sx={{ width: '100%' }}>
          <label className={styleCss.user_icon}><span class="pi pi-user"></span></label>
            {genericInput("email", "Enter Email")}
          </Box>
          <Box className={classes.buttonpan}>
           
            <Button
              variant="contained"
              color="secondary"
              className={classes.loginbutton}
              type="submit">Forgot</Button>
              <Button
              variant="outlined"
              className={classes.back}
              color="secondary"
              type="button" onClick={props?.backtologin}>Back</Button>
        
        </Box>
        </Box>
      </form>
    </div>
  )
}