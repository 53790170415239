import { TOAST_SEVERITY } from "../../../common/EzToast";

import { RESET_TOAST_NOTIFICATION } from "../../types/notification.type";

const INITIAL_STATE = {
  shouldShowToast : false,
}

const successToast = ({ toastTitle = 'Operation completed Successfully!' }) => ({
  shouldShowToast: true,
  toastTitle,
  severity: TOAST_SEVERITY.SUCCESS
});

const failureToast = ({ toastTitle = 'Operation Failed!' }) => ({
  shouldShowToast: true,
  toastTitle,
  severity: TOAST_SEVERITY.ERROR
});

const toastReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case RESET_TOAST_NOTIFICATION: {
      return INITIAL_STATE
    }
    default :
     return state
  }
}

export default toastReducer