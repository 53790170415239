import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_NETWORKS_LIST, 
  TOAST, 
  FETCH_RATEADMIN_NETWORKS_INIT,
  FETCH_RATEADMIN_NETWORKS_DETAIL,
  EXPORT_RATEADMIN_NETWORKS,
  CREATE_RATEADMIN_NETWORKS,
  EDIT_RATEADMIN_NETWORKS,
  INIT_CREATE_RATEADMIN_NETWORKS_SCREEN,
  RATEADMIN_NETWORKSDD
} from "../../../types/rateadminnetworks.type";



  const formFieldValueMap = {
    id:null,
    applicable:0,
    inclusiveRate:0,
    locationId:[],
    ip:"",
    status:0,
    title:"",
    userId:"",
  rateDayPercentage: 0,
  typeInsurance: "",
  url: "",
  vehicleCategoryId: [],
  rateDayPercentage_1_1:"",
  rateDayPercentage_1_2:"",
  rateDayPercentage_2_1:"",
  rateDayPercentage_2_2:"",
  typeInsurance_1:"",
  typeInsurance_2:"",
  
  };
  
  const INITIAL_STATE = {
    rateAdminNetworksListview: [],
    NetworksDD:[],
    isRateAdminNetworksDDSuccess: false,
    isRateAdminNetworksDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRateAdminNetworksCreateSuccess: false,
    isRateAdminNetworksCreateError: false,
    isRateAdminNetworksDetailSuccess: false,
    isRateAdminNetworksDetailError: false,
    isRateAdminNetworksEditSuccess: false,
    isRateAdminNetworksEditError: false,
    isRateAdminNetworksExportSuccess: false,
    isRateAdminNetworksExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title,
url
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title,
url
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Title",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },{
        colLabel: "Url",
        dataKey: "url",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const rateadminNetworksReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RATEADMIN_NETWORKS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RATEADMIN_NETWORKS_DETAIL.START:
      case EXPORT_RATEADMIN_NETWORKS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RATEADMIN_NETWORKS_LIST.START:
      case CREATE_RATEADMIN_NETWORKS.START:
        case EDIT_RATEADMIN_NETWORKS.START:
          case RATEADMIN_NETWORKSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RATEADMIN_NETWORKS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          rateAdminNetworksListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRateAdminNetworksCreateSuccess: false,
          isRateAdminNetworksCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          rateAdminNetworksListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RATEADMIN_NETWORKS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          rateAdminNetworksListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          israteadminNetworksDeletionSuccess: false,
          israteadminNetworksDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RATEADMIN_NETWORKS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];

              if(action.payload.applicable=="1"){
                if(action.payload.typeInsurance=="1"){
                  temp["rateDayPercentage_1_1"] = action.payload?.rateDayPercentage;                  
                }
                if(action.payload.typeInsurance=="2"){
                  temp["rateDayPercentage_1_2"] = action.payload?.rateDayPercentage;
                }
                temp[key] = action.payload[key];
              }
              if(action.payload.applicable=="2"){
                if(action.payload.typeInsurance=="1"){
                  temp["rateDayPercentage_2_1"] = action.payload?.rateDayPercentage;
                  
                }
                if(action.payload.typeInsurance=="2"){
                  temp["rateDayPercentage_2_2"] = action.payload?.rateDayPercentage;
                }
                temp[key] = action.payload[key];
              }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRateAdminNetworksDetailSuccess: true,
      };
    }
    case FETCH_RATEADMIN_NETWORKS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminNetworksDetailSuccess: false,
        isRateAdminNetworksDetailError: true,
      };
    }

    //DD
    case RATEADMIN_NETWORKSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        NetworksDD:temp,
        isRateAdminNetworksDDSuccess: true,
        isRateAdminNetworksDDError: false,
      };
    }
    case RATEADMIN_NETWORKSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRateAdminNetworksDDSuccess: false,
        isRateAdminNetworksDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RATEADMIN_NETWORKS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRateAdminNetworksExportSuccess: false,
    isRateAdminNetworksExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RATEADMIN_NETWORKS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"network.xls");
   return {
    ...state,
    isRateAdminNetworksExportSuccess: true,
    isRateAdminNetworksExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RATEADMIN_NETWORKS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminNetworksEditSuccess: false,
    isRateAdminNetworksEditError: true,
  };
}

case EDIT_RATEADMIN_NETWORKS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminNetworksEditSuccess: true,
    isRateAdminNetworksEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RATEADMIN_NETWORKS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RATEADMIN_NETWORKS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRateAdminNetworksCreateSuccess: false,
    isRateAdminNetworksCreateError: true,
  };
}
case CREATE_RATEADMIN_NETWORKS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRateAdminNetworksCreateSuccess: true,
    isRateAdminNetworksCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRateAdminNetworksCreateSuccess: false,
          isRateAdminNetworksCreateError: false,
          isRateAdminNetworksEditError: false,
          isRateAdminNetworksEditSuccess: false,
          isRateAdminNetworksDetailError: false
        };
      }
      default:
        return state;
    }
  };
  