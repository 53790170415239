import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRateAdminRateCode,
    initializeRateAdminRateCodeScreen,
    setDeleteConfirmationPopupVisibility,
    exportRateAdminRateCode
  } from "../../../redux/actions/RateAdmin/rateadminRateCode";
import RateAdminRateCode from '../../../component/RateAdmin/RateAdminRateCode';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RateAdminRateCodeScreen(props) {
    const {
        fetchRateAdminRateCode,
        initRateAdminRateCodeScreen,
        showPageLevelLoader,
        rateAdminRateCodeListview,
        paginationInfo,
        exportRateAdminRateCode,
        isRateAdminRateCodeExportError,
        isRateAdminRateCodeExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRateAdminRateCodeScreen();
        fetchRateAdminRateCode({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchRateAdminRateCode(props);
      };
      const handleCreateRateAdminRateCode =() =>{
      navigate(APP_URLS.RATEADMIN_RATE_CODE.CREATE);
    }
      const editRateAdminRateCodeRoute = (id) => {
        const path = generatePath(APP_URLS.RATEADMIN_RATE_CODE.EDIT, {
          id,
        });
        navigate(path);
      };
const rateAdminRateCode = {
  handleCreateRateAdminRateCode,
  exportRateAdminRateCode,
        isRateAdminRateCodeExportError,
        isRateAdminRateCodeExportSuccess,
  editRateAdminRateCodeRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRateAdminRateCodes: loadLazyData,
  ...companyData,
  tableData: {
    columns: rateAdminRateCodeListview?.columns,
    rows: rateAdminRateCodeListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RateAdminRateCode
    {...rateAdminRateCode}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRateAdminRateCodeScreen: () => dispatch(initializeRateAdminRateCodeScreen()),
      fetchRateAdminRateCode: (payload) => dispatch(fetchRateAdminRateCode(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRateAdminRateCode: (payload) => dispatch(exportRateAdminRateCode(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRateAdminRateCodeCreateSuccess: selectFromRateAdminRateCodeReducer(
        state,
        "isRateAdminRateCodeCreateSuccess"
      ),
      rateAdminRateCodeListview: selectFromRateAdminRateCodeReducer(
        state,
        "rateAdminRateCodeListview"
      ),
      showPageLevelLoader: selectFromRateAdminRateCodeReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRateAdminRateCodeReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRateAdminRateCodeReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRateAdminRateCodeReducer(
        state,
        "isFetchingGridData"
      ),
      isRateAdminRateCodeExportError: selectFromRateAdminRateCodeReducer(
        state,
        "isRateAdminRateCodeExportError"
      ),
      isRateAdminRateCodeExportSuccess: selectFromRateAdminRateCodeReducer(
        state,
        "isRateAdminRateCodeExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRateAdminRateCodeReducer = (state, path) => {
  return selectRateAdminRateCodeReducer(state)[path];
};

const selectRateAdminRateCodeReducer = ({ rateadminRateCodeReducer }) => {
  return rateadminRateCodeReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RateAdminRateCodeScreen);
