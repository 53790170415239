import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateReservationsCRXRezpowerReservationsScreen,
  createReservationsCRXRezpowerReservations,
  editReservationsCRXRezpowerReservations,
  fetchReservationsCRXRezpowerReservationsById,
  uploadImage,
} from "../../../../redux/actions/Reservations/ReservationsCRXRezpowerReservations";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateReservationsCRXRezpowerReservationsComponent from "../../../../component/Reservations/ReservationsCRXRezpowerReservations/CreateReservationsCRXRezpowerReservations";
import { useParams } from "react-router-dom";

const CreateReservationsCRXRezpowerReservationsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createReservationsCRXRezpowerReservations,
    editReservationsCRXRezpowerReservations,
    isLoading,
    isReservationsCRXRezpowerReservationsCreateSuccess,
    isReservationsCRXRezpowerReservationsCreateError,
    isReservationsCRXRezpowerReservationsDetailSuccess,
    isReservationsCRXRezpowerReservationsDetailError,
    isReservationsCRXRezpowerReservationsEditSuccess,
    isReservationsCRXRezpowerReservationsEditError,
    isEdit,
    isView,
    initCreateReservationsCRXRezpowerReservationsScreen,
    formFieldValueMap,
    fetchReservationsCRXRezpowerReservationsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        fetchLocationDD,
        locationDD,
        fetchVehicleCategoryDD,
vehicleCategoryDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateReservationsCRXRezpowerReservationsScreen();
    await fetchLocationDD();
    await fetchVehicleCategoryDD();
   if (isEdit) {
    await fetchReservationsCRXRezpowerReservationsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createReservationsCRXRezpowerReservationsProps = {
    createReservationsCRXRezpowerReservations,
    initCreateReservationsCRXRezpowerReservationsScreen,
    editReservationsCRXRezpowerReservations,
    isLoading,
    isEdit,
    isReservationsCRXRezpowerReservationsDetailSuccess,
    isReservationsCRXRezpowerReservationsCreateSuccess,
    isReservationsCRXRezpowerReservationsEditSuccess,
    isReservationsCRXRezpowerReservationsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
  };
  const getToastProps = () => {
    if (isReservationsCRXRezpowerReservationsCreateSuccess || isReservationsCRXRezpowerReservationsEditSuccess) {
      const toastTitle = isEdit
        ? "CRX Rezpower Reservations Updated Successfully"
        : "CRX Rezpower Reservations Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isReservationsCRXRezpowerReservationsCreateError ||
      isReservationsCRXRezpowerReservationsDetailError ||
      isReservationsCRXRezpowerReservationsEditError
    ) {
      let toastTitle = "Error while Creating Reservations";
      if (isReservationsCRXRezpowerReservationsEditError) {
        toastTitle = "Error while updating  Reservations";
      } else if (isReservationsCRXRezpowerReservationsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateReservationsCRXRezpowerReservationsComponent {...createReservationsCRXRezpowerReservationsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromReservationsCRXRezpowerReservationsReducer(state, "isLoading"),
   
    isReservationsCRXRezpowerReservationsCreateSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isReservationsCRXRezpowerReservationsCreateSuccess"
    ),
    isReservationsCRXRezpowerReservationsCreateError: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isReservationsCRXRezpowerReservationsCreateError"
    ),
    isReservationsCRXRezpowerReservationsDetailSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isReservationsCRXRezpowerReservationsDetailSuccess"
    ),
    isReservationsCRXRezpowerReservationsDetailError: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isReservationsCRXRezpowerReservationsDetailError"
    ),
    isReservationsCRXRezpowerReservationsEditSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isReservationsCRXRezpowerReservationsEditSuccess"
    ),
    isReservationsCRXRezpowerReservationsEditError: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isReservationsCRXRezpowerReservationsEditError"
    ),
    imageUploadResponse: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromReservationsCRXRezpowerReservationsReducer(
      state,
      "isVehicleModelDDError"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    formFieldValueMap: selectFromReservationsCRXRezpowerReservationsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateReservationsCRXRezpowerReservationsScreen: () => dispatch(initCreateReservationsCRXRezpowerReservationsScreen()),
    createReservationsCRXRezpowerReservations: (payload) => dispatch(createReservationsCRXRezpowerReservations(payload)),
    editReservationsCRXRezpowerReservations: (payload) => dispatch(editReservationsCRXRezpowerReservations(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchReservationsCRXRezpowerReservationsById: (payload) =>
      dispatch(fetchReservationsCRXRezpowerReservationsById(payload)),

  };
};

const selectFromReservationsCRXRezpowerReservationsReducer = (state, path) => {
  return selectReservationsCRXRezpowerReservationsReducer(state)[path];
};

const selectReservationsCRXRezpowerReservationsReducer = ({ ReservationsCRXRezpowerReservationsReducer }) => {
  return ReservationsCRXRezpowerReservationsReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservationsCRXRezpowerReservationsContainer);
