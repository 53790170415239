import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminExtraProductCategoryServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_INIT, 
  FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_SCREEN,
  CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY,
  EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY,
  TOAST,
  FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL,
  EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY,
  RATEADMIN_EXTRAPRODUCTCATEGORYDD
} from "../../../types/rateadminextraproductcategory.type";
export const initializeRateAdminExtraProductCategoryScreen = () => ({ type: FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_INIT });

export const rateadminExtraProductCategoryFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST.START,
  payload,
});

export const rateadminExtraProductCategoryFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST.SUCCESS,
  payload,
});

export const rateadminExtraProductCategoryFetchError = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_LIST.ERROR,
  payload,
});

export const fetchRateAdminExtraProductCategory = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductCategoryFetchStart(payload));
    rateAdminExtraProductCategoryServices.fetchRateAdminExtraProductCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminExtraProductCategoryFetchSuccess(data));
      } else {
        dispatch(rateadminExtraProductCategoryFetchError(data));
      }
    });
  };
};



// DD
export const rateadminExtraProductCategoryDDFetchStart = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCATEGORYDD.START,
  payload,
});

export const rateadminExtraProductCategoryDDFetchSuccess = (payload) => ({
  type:RATEADMIN_EXTRAPRODUCTCATEGORYDD.SUCCESS,
  payload,
});

export const rateadminExtraProductCategoryDDFetchError = (payload) => ({
  type: RATEADMIN_EXTRAPRODUCTCATEGORYDD.ERROR,
  payload,
});

export const fetchRateAdminExtraProductCategoryDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductCategoryDDFetchStart(payload));
    rateAdminExtraProductCategoryServices.fetchRateAdminExtraProductCategoryDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminExtraProductCategoryDDFetchSuccess(data));
      } else {
        dispatch(rateadminExtraProductCategoryDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminExtraProductCategoryDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL.START,
  payload,
});

export const rateadminExtraProductCategoryDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL.SUCCESS,
  payload,
});

export const rateadminExtraProductCategoryDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_EXTRAPRODUCTCATEGORY_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminExtraProductCategoryById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminExtraProductCategoryDetailFetchStart(payload));

    rateAdminExtraProductCategoryServices
      .fetchRateAdminExtraProductCategoryById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminExtraProductCategoryDetailFetchSuccess(data));
        } else {
          dispatch(rateadminExtraProductCategoryDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminExtraProductCategoryServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminExtraProductCategoryScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY_SCREEN,
  payload,
});

export const initCreateRateAdminExtraProductCategoryScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminExtraProductCategoryScreenInitStart());
  };
};


export const createRateAdminExtraProductCategoryStart = () => ({
  type: CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY.START,
});

export const createRateAdminExtraProductCategorySuccess = (payload) => ({
  type: CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY.SUCCESS,
  payload,
});

export const createRateAdminExtraProductCategoryError = (payload) => ({
  type: CREATE_RATEADMIN_EXTRAPRODUCTCATEGORY.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminExtraProductCategory = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminExtraProductCategoryStart());
    rateAdminExtraProductCategoryServices.createRateAdminExtraProductCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminExtraProductCategorySuccess());
        goBack();
      } else {
        dispatch(createRateAdminExtraProductCategoryError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminExtraProductCategoryStart = (payload) => ({
  type: EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY.START,
  payload
});

export const editRateAdminExtraProductCategorySuccess = (payload) => ({
  type: EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY.SUCCESS,
  payload,
});

export const editRateAdminExtraProductCategoryError = (payload) => ({
  type: EDIT_RATEADMIN_EXTRAPRODUCTCATEGORY.ERROR,
  payload,
});

export const editRateAdminExtraProductCategory = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminExtraProductCategoryStart());
    rateAdminExtraProductCategoryServices.editRateAdminExtraProductCategory(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminExtraProductCategorySuccess());
        goBack();
      } else {
        dispatch(editRateAdminExtraProductCategoryError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminExtraProductCategoryRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY.START,
  payload
});

export const exportRateAdminExtraProductCategoryRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY.SUCCESS,
  payload,
});

export const exportRateAdminExtraProductCategoryRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_EXTRAPRODUCTCATEGORY.ERROR,
  payload,
});

export const exportRateAdminExtraProductCategory = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminExtraProductCategoryRecordStart());
    rateAdminExtraProductCategoryServices.exportRateAdminExtraProductCategoryRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminExtraProductCategoryRecordSuccess(data));
      } else {
        dispatch(exportRateAdminExtraProductCategoryRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

