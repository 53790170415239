import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_LOGINTERACTIONS_LIST, 
  TOAST, 
  FETCH_RENTERS_LOGINTERACTIONS_INIT,
  FETCH_RENTERS_LOGINTERACTIONS_DETAIL,
  EXPORT_RENTERS_LOGINTERACTIONS,
  CREATE_RENTERS_LOGINTERACTIONS,
  EDIT_RENTERS_LOGINTERACTIONS,
  INIT_CREATE_RENTERS_LOGINTERACTIONS_SCREEN,
  RENTERS_LOGINTERACTIONSDD,
  RENTERS_LOGINTERACTIONSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Renters/LogInteractions.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    RentersLogInteractionsListview: [],
    RentersLogInteractionsDD:[],
    isRentersLogInteractionsDDSuccess: false,
    isRentersLogInteractionsDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRentersLogInteractionsCreateSuccess: false,
    isRentersLogInteractionsCreateError: false,
    isRentersLogInteractionsDetailSuccess: false,
    isRentersLogInteractionsDetailError: false,
    isRentersLogInteractionsEditSuccess: false,
    isRentersLogInteractionsEditError: false,
    isRentersLogInteractionsExportSuccess: false,
    isRentersLogInteractionsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isRentersLogInteractionsDeleteSuccess: false,
    isRentersLogInteractionsDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const RentersLogInteractionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RENTERS_LOGINTERACTIONS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RENTERS_LOGINTERACTIONS_DETAIL.START:
      case EXPORT_RENTERS_LOGINTERACTIONS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RENTERS_LOGINTERACTIONS_LIST.START:
      case CREATE_RENTERS_LOGINTERACTIONS.START:
        case RENTERS_LOGINTERACTIONSDELETE.START:
        case EDIT_RENTERS_LOGINTERACTIONS.START:
          case RENTERS_LOGINTERACTIONSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RENTERS_LOGINTERACTIONS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          RentersLogInteractionsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRentersLogInteractionsCreateSuccess: false,
          isRentersLogInteractionsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          RentersLogInteractionsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RENTERS_LOGINTERACTIONS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          RentersLogInteractionsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isRentersLogInteractionsDeletionSuccess: false,
          isRentersLogInteractionsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RENTERS_LOGINTERACTIONS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRentersLogInteractionsDetailSuccess: true,
      };
    }
    case FETCH_RENTERS_LOGINTERACTIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersLogInteractionsDetailSuccess: false,
        isRentersLogInteractionsDetailError: true,
      };
    }

    //DD
    case RENTERS_LOGINTERACTIONSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RentersLogInteractionsDD:temp,
        isRentersLogInteractionsDDSuccess: true,
        isRentersLogInteractionsDDError: false,
      };
    }
    case RENTERS_LOGINTERACTIONSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersLogInteractionsDDSuccess: false,
        isRentersLogInteractionsDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isRentersLogInteractionsDeleteSuccess: false,
    isRentersLogInteractionsDeleteError: false,
  };
}
case RENTERS_LOGINTERACTIONSDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isRentersLogInteractionsDeleteSuccess: true,
    isRentersLogInteractionsDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RENTERS_LOGINTERACTIONSDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRentersLogInteractionsDeleteSuccess: false,
    isRentersLogInteractionsDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RENTERS_LOGINTERACTIONS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRentersLogInteractionsExportSuccess: false,
    isRentersLogInteractionsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RENTERS_LOGINTERACTIONS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"renters-log-interactions.xls");
   return {
    ...state,
    isRentersLogInteractionsExportSuccess: true,
    isRentersLogInteractionsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RENTERS_LOGINTERACTIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersLogInteractionsEditSuccess: false,
    isRentersLogInteractionsEditError: true,
  };
}

case EDIT_RENTERS_LOGINTERACTIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersLogInteractionsEditSuccess: true,
    isRentersLogInteractionsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RENTERS_LOGINTERACTIONS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RENTERS_LOGINTERACTIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersLogInteractionsCreateSuccess: false,
    isRentersLogInteractionsCreateError: true,
  };
}
case CREATE_RENTERS_LOGINTERACTIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersLogInteractionsCreateSuccess: true,
    isRentersLogInteractionsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRentersLogInteractionsCreateSuccess: false,
          isRentersLogInteractionsCreateError: false,
          isRentersLogInteractionsEditError: false,
          isRentersLogInteractionsEditSuccess: false,
          isRentersLogInteractionsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  