import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchVehicleModel,
    initializeVehicleModelScreen,
    setDeleteConfirmationPopupVisibility,
    exportVehicleModel
  } from "../../../redux/actions/vehicles/vehicleModel";
import VehicleModel from '../../../component/Vehicles/VehicleModel';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function VehicleModelScreen(props) {
    const {
        fetchVehicleModel,
        initVehicleModelScreen,
        showPageLevelLoader,
        vehicleModelListview,
        paginationInfo,
        exportVehicleModel,
        isVehicleModelExportError,
        isVehicleModelExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initVehicleModelScreen();
        fetchVehicleModel({ sortField: 'id' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        fetchVehicleModel(props);
      };
      const handleCreateVehicleModel =() =>{
      navigate(APP_URLS.VEHICLE_MODEL.CREATE);
    }
      const editVehicleModelRoute = (id) => {
        const path = generatePath(APP_URLS.VEHICLE_MODEL.EDIT, {
          id,
        });
        navigate(path);
      };
const vehicleModel = {
  handleCreateVehicleModel,
  exportVehicleModel,
        isVehicleModelExportError,
        isVehicleModelExportSuccess,
  editVehicleModelRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchVehicleModels: loadLazyData,
  ...companyData,
  tableData: {
    columns: vehicleModelListview?.columns,
    rows: vehicleModelListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <VehicleModel
    {...vehicleModel}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initVehicleModelScreen: () => dispatch(initializeVehicleModelScreen()),
      fetchVehicleModel: (payload) => dispatch(fetchVehicleModel(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportVehicleModel: (payload) => dispatch(exportVehicleModel(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isVehicleModelCreateSuccess: selectFromVehicleModelReducer(
        state,
        "isVehicleModelCreateSuccess"
      ),
      vehicleModelListview: selectFromVehicleModelReducer(
        state,
        "vehicleModelListview"
      ),
      showPageLevelLoader: selectFromVehicleModelReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromVehicleModelReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromVehicleModelReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromVehicleModelReducer(
        state,
        "isFetchingGridData"
      ),
      isVehicleModelExportError: selectFromVehicleModelReducer(
        state,
        "isVehicleModelExportError"
      ),
      isVehicleModelExportSuccess: selectFromVehicleModelReducer(
        state,
        "isVehicleModelExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromVehicleModelReducer = (state, path) => {
  return selectVehicleModelReducer(state)[path];
};

const selectVehicleModelReducer = ({ vehicleModelReducer }) => {
  return vehicleModelReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleModelScreen);
