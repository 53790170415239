import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchRentersLogInteractions,
  initializeRentersLogInteractionsScreen,
  setDeleteConfirmationPopupVisibility,
  exportRentersLogInteractions
} from "../../../redux/actions/Renters/RentersLogInteractions";
import RentersLogInteractions from '../../../component/Renters/RentersLogInteractions';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RentersLogInteractionsScreen(props) {
  const {
    fetchRentersLogInteractions,
    initRentersLogInteractionsScreen,
    showPageLevelLoader,
    RentersLogInteractionsListview,
    paginationInfo,
    exportRentersLogInteractions,
    isRentersLogInteractionsExportError,
    isRentersLogInteractionsExportSuccess,
    isLoading,
  } = props;
  const navigate = useNavigate();
  useEffect(() => {
    initRentersLogInteractionsScreen();
    fetchRentersLogInteractions({ sortField: 'id', userId: localStorage.getItem("userId") });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLazyData = (props) => {
    let newProps = {
      ...props,
      userId: localStorage.getItem("userId")
    }
    fetchRentersLogInteractions(newProps);
  };
  const handleCreateRentersLogInteractions = () => {
    navigate(APP_URLS.RENTERS_LOGINTERACTIONS.CREATE);
  }
  const editRentersLogInteractionsRoute = (id) => {
    const path = generatePath(APP_URLS.RENTERS_LOGINTERACTIONS.EDIT, {
      id,
    });
    navigate(path);
  };
  const RentersLogInteractionsProps = {
    handleCreateRentersLogInteractions,
    exportRentersLogInteractions,
    isRentersLogInteractionsExportError,
    isRentersLogInteractionsExportSuccess,
    editRentersLogInteractionsRoute,
    ...companyData,
    screenPermissions: companyData?.screenPermissions,
    setDeleteConfirmationPopupVisibility,
    fetchRentersLogInteractionss: loadLazyData,
    ...companyData,
    tableData: {
      columns: RentersLogInteractionsListview?.columns,
      rows: RentersLogInteractionsListview?.rows,
    },
    paginationInfo: {
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };

  if (showPageLevelLoader) {
    loadingShow("block");
    return null;
  }
  loadingShow("none");
  return (
    <div>
      <RentersLogInteractions
        {...RentersLogInteractionsProps}
      />
    </div>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    initRentersLogInteractionsScreen: () => dispatch(initializeRentersLogInteractionsScreen()),
    fetchRentersLogInteractions: (payload) => dispatch(fetchRentersLogInteractions(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    exportRentersLogInteractions: (payload) => dispatch(exportRentersLogInteractions(payload)),
  };
};


//vehicleCagegoryReducer
const mapStateToProps = (state, ownProps) => {
  return {

    isRentersLogInteractionsCreateSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsCreateSuccess"
    ),
    RentersLogInteractionsListview: selectFromRentersLogInteractionsReducer(
      state,
      "RentersLogInteractionsListview"
    ),
    showPageLevelLoader: selectFromRentersLogInteractionsReducer(
      state,
      "showPageLevelLoader"
    ),
    paginationInfo: selectFromRentersLogInteractionsReducer(
      state,
      "paginationInfo"
    ),
    isPageLevelError: selectFromRentersLogInteractionsReducer(
      state,
      "isPageLevelError"
    ),
    isFetchingGridData: selectFromRentersLogInteractionsReducer(
      state,
      "isFetchingGridData"
    ),
    isRentersLogInteractionsExportError: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsExportError"
    ),
    isRentersLogInteractionsExportSuccess: selectFromRentersLogInteractionsReducer(
      state,
      "isRentersLogInteractionsExportSuccess"
    ),
    ...ownProps,
  };
};

const selectFromRentersLogInteractionsReducer = (state, path) => {
  return selectRentersLogInteractionsReducer(state)[path];
};

const selectRentersLogInteractionsReducer = ({ RentersLogInteractionsReducer }) => {
  return RentersLogInteractionsReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RentersLogInteractionsScreen);
