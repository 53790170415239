import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRentersRegisteredRentersScreen,
  createRentersRegisteredRenters,
  editRentersRegisteredRenters,
  fetchRentersRegisteredRentersById,
  uploadImage,
} from "../../../../redux/actions/Renters/RentersRegisteredRenters";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRentersRegisteredRentersComponent from "../../../../component/Renters/RentersRegisteredRenters/CreateRentersRegisteredRenters";
import { useParams } from "react-router-dom";

const CreateRentersRegisteredRentersContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRentersRegisteredRenters,
    editRentersRegisteredRenters,
    isLoading,
    isRentersRegisteredRentersCreateSuccess,
    isRentersRegisteredRentersCreateError,
    isRentersRegisteredRentersDetailSuccess,
    isRentersRegisteredRentersDetailError,
    isRentersRegisteredRentersEditSuccess,
    isRentersRegisteredRentersEditError,
    isEdit,
    isView,
    initCreateRentersRegisteredRentersScreen,
    formFieldValueMap,
    fetchRentersRegisteredRentersById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRentersRegisteredRentersScreen();
   if (isEdit) {
    await fetchRentersRegisteredRentersById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRentersRegisteredRentersProps = {
    createRentersRegisteredRenters,
    initCreateRentersRegisteredRentersScreen,
    editRentersRegisteredRenters,
    isLoading,
    isEdit,
    isRentersRegisteredRentersDetailSuccess,
    isRentersRegisteredRentersCreateSuccess,
    isRentersRegisteredRentersEditSuccess,
    isRentersRegisteredRentersEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isRentersRegisteredRentersCreateSuccess || isRentersRegisteredRentersEditSuccess) {
      const toastTitle = isEdit
        ? "Registered Renters Updated Successfully"
        : "Registered Renters Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRentersRegisteredRentersCreateError ||
      isRentersRegisteredRentersDetailError ||
      isRentersRegisteredRentersEditError
    ) {
      let toastTitle = "Error while Creating Registered Renters";
      if (isRentersRegisteredRentersEditError) {
        toastTitle = "Error while updating  Registered Renters";
      } else if (isRentersRegisteredRentersDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRentersRegisteredRentersComponent {...createRentersRegisteredRentersProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRentersRegisteredRentersReducer(state, "isLoading"),
   
    isRentersRegisteredRentersCreateSuccess: selectFromRentersRegisteredRentersReducer(
      state,
      "isRentersRegisteredRentersCreateSuccess"
    ),
    isRentersRegisteredRentersCreateError: selectFromRentersRegisteredRentersReducer(
      state,
      "isRentersRegisteredRentersCreateError"
    ),
    isRentersRegisteredRentersDetailSuccess: selectFromRentersRegisteredRentersReducer(
      state,
      "isRentersRegisteredRentersDetailSuccess"
    ),
    isRentersRegisteredRentersDetailError: selectFromRentersRegisteredRentersReducer(
      state,
      "isRentersRegisteredRentersDetailError"
    ),
    isRentersRegisteredRentersEditSuccess: selectFromRentersRegisteredRentersReducer(
      state,
      "isRentersRegisteredRentersEditSuccess"
    ),
    isRentersRegisteredRentersEditError: selectFromRentersRegisteredRentersReducer(
      state,
      "isRentersRegisteredRentersEditError"
    ),
    imageUploadResponse: selectFromRentersRegisteredRentersReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRentersRegisteredRentersReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRentersRegisteredRentersReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromRentersRegisteredRentersReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRentersRegisteredRentersReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRentersRegisteredRentersReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRentersRegisteredRentersReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRentersRegisteredRentersScreen: () => dispatch(initCreateRentersRegisteredRentersScreen()),
    createRentersRegisteredRenters: (payload) => dispatch(createRentersRegisteredRenters(payload)),
    editRentersRegisteredRenters: (payload) => dispatch(editRentersRegisteredRenters(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRentersRegisteredRentersById: (payload) =>
      dispatch(fetchRentersRegisteredRentersById(payload)),

  };
};

const selectFromRentersRegisteredRentersReducer = (state, path) => {
  return selectRentersRegisteredRentersReducer(state)[path];
};

const selectRentersRegisteredRentersReducer = ({ RentersRegisteredRentersReducer }) => {
  return RentersRegisteredRentersReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRentersRegisteredRentersContainer);
