import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateSettingsPaynowOptionsScreen,
  createSettingsPaynowOptions,
  editSettingsPaynowOptions,
  fetchSettingsPaynowOptionsById,
  uploadImage,
} from "../../../../redux/actions/Settings/SettingsPaynowOptions";
import {
  fetchLocationDD,
  fetchCategory
  
} from "../../../../redux/actions/location";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateSettingsPaynowOptionsComponent from "../../../../component/Settings/SettingsPaynowOptions/CreateSettingsPaynowOptions";
import { useParams } from "react-router-dom";

const CreateSettingsPaynowOptionsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createSettingsPaynowOptions,
    editSettingsPaynowOptions,
    isLoading,
    isSettingsPaynowOptionsCreateSuccess,
    isSettingsPaynowOptionsCreateError,
    isSettingsPaynowOptionsDetailSuccess,
    isSettingsPaynowOptionsDetailError,
    isSettingsPaynowOptionsEditSuccess,
    isSettingsPaynowOptionsEditError,
    isEdit,
    isView,
    initCreateSettingsPaynowOptionsScreen,
    formFieldValueMap,
    fetchSettingsPaynowOptionsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        fetchLocationDD,
        fetchCategory,
        categoryListview
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    await fetchLocationDD();
    await fetchCategory();
    initCreateSettingsPaynowOptionsScreen();
   if (isEdit) {
    await fetchSettingsPaynowOptionsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createSettingsPaynowOptionsProps = {
    createSettingsPaynowOptions,
    initCreateSettingsPaynowOptionsScreen,
    editSettingsPaynowOptions,
    isLoading,
    isEdit,
    isSettingsPaynowOptionsDetailSuccess,
    isSettingsPaynowOptionsCreateSuccess,
    isSettingsPaynowOptionsEditSuccess,
    isSettingsPaynowOptionsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        categoryListview
  };
  const getToastProps = () => {
    if (isSettingsPaynowOptionsCreateSuccess || isSettingsPaynowOptionsEditSuccess) {
      const toastTitle = isEdit
        ? "Paynow Options Updated Successfully"
        : "Paynow Options Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isSettingsPaynowOptionsCreateError ||
      isSettingsPaynowOptionsDetailError ||
      isSettingsPaynowOptionsEditError
    ) {
      let toastTitle = "Error while Creating Paynow Options";
      if (isSettingsPaynowOptionsEditError) {
        toastTitle = "Error while updating  Paynow Options";
      } else if (isSettingsPaynowOptionsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateSettingsPaynowOptionsComponent {...createSettingsPaynowOptionsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromSettingsPaynowOptionsReducer(state, "isLoading"),
   
    isSettingsPaynowOptionsCreateSuccess: selectFromSettingsPaynowOptionsReducer(
      state,
      "isSettingsPaynowOptionsCreateSuccess"
    ),
    isSettingsPaynowOptionsCreateError: selectFromSettingsPaynowOptionsReducer(
      state,
      "isSettingsPaynowOptionsCreateError"
    ),
    isSettingsPaynowOptionsDetailSuccess: selectFromSettingsPaynowOptionsReducer(
      state,
      "isSettingsPaynowOptionsDetailSuccess"
    ),
    isSettingsPaynowOptionsDetailError: selectFromSettingsPaynowOptionsReducer(
      state,
      "isSettingsPaynowOptionsDetailError"
    ),
    isSettingsPaynowOptionsEditSuccess: selectFromSettingsPaynowOptionsReducer(
      state,
      "isSettingsPaynowOptionsEditSuccess"
    ),
    isSettingsPaynowOptionsEditError: selectFromSettingsPaynowOptionsReducer(
      state,
      "isSettingsPaynowOptionsEditError"
    ),
    imageUploadResponse: selectFromSettingsPaynowOptionsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromSettingsPaynowOptionsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromSettingsPaynowOptionsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromSettingsPaynowOptionsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromSettingsPaynowOptionsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromSettingsPaynowOptionsReducer(
      state,
      "isVehicleModelDDError"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    categoryListview: selectFromLocationReducer(
      state,
      "categoryListview"
    ),    
    formFieldValueMap: selectFromSettingsPaynowOptionsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateSettingsPaynowOptionsScreen: () => dispatch(initCreateSettingsPaynowOptionsScreen()),
    createSettingsPaynowOptions: (payload) => dispatch(createSettingsPaynowOptions(payload)),
    editSettingsPaynowOptions: (payload) => dispatch(editSettingsPaynowOptions(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchCategory:(payload) => dispatch(fetchCategory(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchSettingsPaynowOptionsById: (payload) =>
      dispatch(fetchSettingsPaynowOptionsById(payload)),

  };
};

const selectFromSettingsPaynowOptionsReducer = (state, path) => {
  return selectSettingsPaynowOptionsReducer(state)[path];
};

const selectSettingsPaynowOptionsReducer = ({ SettingsPaynowOptionsReducer }) => {
  return SettingsPaynowOptionsReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSettingsPaynowOptionsContainer);
