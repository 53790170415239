import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchHolidays,
    initializeHolidaysScreen,
    setDeleteConfirmationPopupVisibility,
    exportHolidays,
    deleteHolidays
  } from "../../redux/actions/Holidays";
import Holidays from '../../component/Holidays';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../services/PostAPI';
import { APP_URLS } from '../../utils/constant';
function HolidaysScreen(props) {
    const {
        fetchHolidays,
        initHolidaysScreen,
        showPageLevelLoader,
        HolidaysListview,
        paginationInfo,
        exportHolidays,
        isHolidaysExportError,
        isHolidaysExportSuccess,
        isLoading,
        deleteHolidays,
        setDeleteConfirmationPopupVisibility,
        isHolidaysDeleteSuccess,
    isHolidaysDeleteError,
    shouldOpenDeleteConfirmationPopup
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initHolidaysScreen();
        fetchHolidays({ sortField: 'id', userId:localStorage.getItem("userId")});
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newPyload={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchHolidays(newPyload);
      };
      const handleCreateHolidays =() =>{
      navigate(APP_URLS.HOLIDAYS.CREATE);
    }
      const editHolidaysRoute = (id) => {
        const path = generatePath(APP_URLS.HOLIDAYS.EDIT, {
          id,
        });
        navigate(path);
      };
const HolidaysData = {
  handleCreateHolidays,
  deleteHolidays,
  isHolidaysDeleteSuccess,
    isHolidaysDeleteError,
    shouldOpenDeleteConfirmationPopup,
  exportHolidays,
        isHolidaysExportError,
        isHolidaysExportSuccess,
  editHolidaysRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchHolidayss: loadLazyData,
  ...companyData,
  tableData: {
    columns: HolidaysListview?.columns,
    rows: HolidaysListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,

};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <Holidays
    {...HolidaysData}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initHolidaysScreen: () => dispatch(initializeHolidaysScreen()),
      fetchHolidays: (payload) => dispatch(fetchHolidays(payload)),
      deleteHolidays: (payload) => dispatch(deleteHolidays(payload)),
      exportHolidays: (payload) => dispatch(exportHolidays(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
     };
  };
  

  const mapStateToProps = (state, ownProps) => {
    return {

      isHolidaysCreateSuccess: selectFromHolidaysReducer(
        state,
        "isHolidaysCreateSuccess"
      ),
      HolidaysListview: selectFromHolidaysReducer(
        state,
        "HolidaysListview"
      ),
      showPageLevelLoader: selectFromHolidaysReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromHolidaysReducer(
        state,
        "paginationInfo"
      ),
      shouldOpenDeleteConfirmationPopup:selectFromHolidaysReducer(
        state,
        "shouldOpenDeleteConfirmationPopup"
      ),
      isPageLevelError: selectFromHolidaysReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromHolidaysReducer(
        state,
        "isFetchingGridData"
      ),
      isHolidaysExportError: selectFromHolidaysReducer(
        state,
        "isHolidaysExportError"
      ),
    isHolidaysDeleteSuccess: selectFromHolidaysReducer(
      state,
      "isHolidaysDeleteSuccess"
    ),
    isHolidaysDeleteError: selectFromHolidaysReducer(
      state,
      "isHolidaysDeleteError"
    ),

      isHolidaysExportSuccess: selectFromHolidaysReducer(
        state,
        "isHolidaysExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromHolidaysReducer = (state, path) => {
  return selectHolidaysReducer(state)[path];
};

const selectHolidaysReducer = ({ holidaysReducer }) => {
  return holidaysReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(HolidaysScreen);
