import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminRatesServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATES_INIT, 
  FETCH_RATEADMIN_RATES_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_RATES_SCREEN,
  CREATE_RATEADMIN_RATES,
  EDIT_RATEADMIN_RATES,
  TOAST,
  FETCH_RATEADMIN_RATES_DETAIL,
  EXPORT_RATEADMIN_RATES,
  RATEADMIN_RATESDD,
  RATEADMIN_RATEOPTIONDD,
  RATEADMIN_RATENETWORKDD,
  RATEADMIN_RATEADJUSTMENT,
  FETCH_RATEADMIN_RATES_RATEADJUSTMENT,
  RATEADMIN_RATETEST
} from "../../../types/rateadminrates.type";
export const initializeRateAdminRatesScreen = () => ({ type: FETCH_RATEADMIN_RATES_INIT });

export const rateadminRatesFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATES_LIST.START,
  payload,
});

export const rateadminRatesFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_RATES_LIST.SUCCESS,
  payload,
});

export const rateadminRatesFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATES_LIST.ERROR,
  payload,
});

export const fetchRateAdminRates = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRatesFetchStart(payload));
    rateAdminRatesServices.fetchRateAdminRates(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRatesFetchSuccess(data));
      } else {
        dispatch(rateadminRatesFetchError(data));
      }
    });
  };
};

// RATEOPTIO NDD
export const rateadminNetworkDDFetchStart = (payload) => ({
  type: RATEADMIN_RATENETWORKDD.START,
  payload,
});

export const rateadminNetworkDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATENETWORKDD.SUCCESS,
  payload,
});

export const rateadminNetworkDDFetchError = (payload) => ({
  type: RATEADMIN_RATENETWORKDD.ERROR,
  payload,
});

export const fetchRateAdminNetworkDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminNetworkDDFetchStart(payload));
    rateAdminRatesServices.fetchRateAdminNetworkDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminNetworkDDFetchSuccess(data));
      } else {
        dispatch(rateadminNetworkDDFetchError(data));
      }
    });
  };
};

//END DD

// RATEOPTION DD
export const rateadminRateOptionsDDFetchStart = (payload) => ({
  type: RATEADMIN_RATEOPTIONDD.START,
  payload,
});

export const rateadminRateOptionsDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATEOPTIONDD.SUCCESS,
  payload,
});

export const rateadminRateOptionsDDFetchError = (payload) => ({
  type: RATEADMIN_RATEOPTIONDD.ERROR,
  payload,
});

export const fetchRateAdminRateOptionsDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateOptionsDDFetchStart(payload));
    rateAdminRatesServices.fetchRateAdminRateOptionsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRateOptionsDDFetchSuccess(data));
      } else {
        dispatch(rateadminRateOptionsDDFetchError(data));
      }
    });
  };
};
// DD RATE
export const rateadminRatesDDFetchStart = (payload) => ({
  type: RATEADMIN_RATESDD.START,
  payload,
});

export const rateadminRatesDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATESDD.SUCCESS,
  payload,
});

export const rateadminRatesDDFetchError = (payload) => ({
  type: RATEADMIN_RATESDD.ERROR,
  payload,
});

export const fetchRateAdminRatesDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRatesDDFetchStart(payload));
    rateAdminRatesServices.fetchRateAdminRatesDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRatesDDFetchSuccess(data));
      } else {
        dispatch(rateadminRatesDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminRatesDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATES_DETAIL.START,
  payload,
});

export const rateadminRatesDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATES_DETAIL.SUCCESS,
  payload,
});

export const rateadminRatesDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATES_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminRatesById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRatesDetailFetchStart(payload));

    rateAdminRatesServices
      .fetchRateAdminRatesById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRatesDetailFetchSuccess(data));
        } else {
          dispatch(rateadminRatesDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/

/*---------------START RATE ADJ------------------*/

export const rateadminRateAdjDetailsFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATES_RATEADJUSTMENT.START,
  payload,
});

export const rateadminRateAdjDetailsFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATES_RATEADJUSTMENT.SUCCESS,
  payload,
});

export const rateadminRateAdjDetailsFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATES_RATEADJUSTMENT.ERROR,
  payload,
});

export const fetchRateAdminRatesRateAdjDetails = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateAdjDetailsFetchStart(payload));

    rateAdminRatesServices
      .fetchRateAdminRateAdjustment(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateAdjDetailsFetchSuccess(data));
        } else {
          dispatch(rateadminRateAdjDetailsFetchError(data));
        }
      });
  };
};

/*----------------END----------------------------*/

/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminRatesServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminRatesScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_RATES_SCREEN,
  payload,
});

export const initCreateRateAdminRatesScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRatesScreenInitStart());
  };
};


export const createRateAdminRatesStart = () => ({
  type: CREATE_RATEADMIN_RATES.START,
});

export const createRateAdminRatesSuccess = (payload) => ({
  type: CREATE_RATEADMIN_RATES.SUCCESS,
  payload,
});

export const createRateAdminRatesError = (payload) => ({
  type: CREATE_RATEADMIN_RATES.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminRates = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRatesStart());
    rateAdminRatesServices.createRateAdminRates(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminRatesSuccess());
        goBack();
      } else {
        dispatch(createRateAdminRatesError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminRatesStart = (payload) => ({
  type: EDIT_RATEADMIN_RATES.START,
  payload
});

export const editRateAdminRatesSuccess = (payload) => ({
  type: EDIT_RATEADMIN_RATES.SUCCESS,
  payload,
});

export const editRateAdminRatesError = (payload) => ({
  type: EDIT_RATEADMIN_RATES.ERROR,
  payload,
});

export const editRateAdminRates = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminRatesStart());
    rateAdminRatesServices.editRateAdminRates(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminRatesSuccess());
        goBack();
      } else {
        dispatch(editRateAdminRatesError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminRatesRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_RATES.START,
  payload
});

export const exportRateAdminRatesRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_RATES.SUCCESS,
  payload,
});

export const exportRateAdminRatesRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_RATES.ERROR,
  payload,
});

export const exportRateAdminRates = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminRatesRecordStart());
    rateAdminRatesServices.exportRateAdminRatesRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminRatesRecordSuccess(data));
      } else {
        dispatch(exportRateAdminRatesRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};



// RATE ADJUSTMENT


export const createRateAdminRateAdjustmentStart = () => ({
  type: RATEADMIN_RATEADJUSTMENT.START,
});

export const createRateAdminRateAdjustmentSuccess = (payload) => ({
  type: RATEADMIN_RATEADJUSTMENT.SUCCESS,
  payload,
});

export const createRateAdminRateAdjustmentError = (payload) => ({
  type: RATEADMIN_RATEADJUSTMENT.ERROR,
  payload,
});

export const createRateAdminRateAdjustment = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRateAdjustmentStart());
    rateAdminRatesServices.createRateAdminRateAdjustment(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminRateAdjustmentSuccess());
        //goBack();
      } else {
        dispatch(createRateAdminRateAdjustmentError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE DELETE------------------*/

export const rateadminRateDeleteStart = (payload) => ({
  type: FETCH_RATEADMIN_RATES_RATEADJUSTMENT.START,
  payload,
});

export const rateadminRateDeleteSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATES_RATEADJUSTMENT.SUCCESS,
  payload,
});

export const rateadminRateDeleteError = (payload) => ({
  type: FETCH_RATEADMIN_RATES_RATEADJUSTMENT.ERROR,
  payload,
});

export const deleteRateAdminRates = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateDeleteStart(payload));

    rateAdminRatesServices
      .deleteRateAdminRates(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateDeleteSuccess(data));
          dispatch(fetchRateAdminRates(payload?.params));
        } else {
          dispatch(rateadminRateDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/



/*---------------START RATE TEST------------------*/

export const rateadminRateTestStart = (payload) => ({
  type: RATEADMIN_RATETEST.START,
  payload,
});

export const rateadminRateTestSuccess = (payload) => ({
  type: RATEADMIN_RATETEST.SUCCESS,
  payload,
});

export const rateadminRateTestError = (payload) => ({
  type: RATEADMIN_RATETEST.ERROR,
  payload,
});

export const TestRateAdminRates = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRateTestStart(payload));

    rateAdminRatesServices
      .fetchRateTest(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRateTestSuccess(data));
        } else {
          dispatch(rateadminRateTestError(data));
        }
      });
  };
};

/*----------------END----------------------------*/