import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchLocation,
    initializeLocationScreen,
    setDeleteConfirmationPopupVisibility,
    exportLocation
  } from "../../redux/actions/location";
import Location from '../../component/Location';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../services/PostAPI';
import { APP_URLS } from '../../utils/constant';
function LocationScreen(props) {
    const {
        fetchLocation,
        initLocationScreen,
        showPageLevelLoader,
        locationListview,
        paginationInfo,
        exportLocation,
        isLocationExportError,
        isLocationExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initLocationScreen();
        fetchLocation({ sortField: 'id',  userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newPyload={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchLocation(newPyload);
      };
      const handleCreateLocation =() =>{
        navigate(APP_URLS.LOCATION.CREATE);
    }
      const editLocationRoute = (id) => {
        const path = generatePath(APP_URLS.LOCATION.EDIT, {
          id,
        });
        navigate(path);
      };
const locaitonProps = {
  handleCreateLocation,
  exportLocation,
        isLocationExportError,
        isLocationExportSuccess,
  editLocationRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchLocations: loadLazyData,
  ...companyData,
  tableData: {
    columns: locationListview?.columns,
    rows: locationListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <Location
    {... locaitonProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initLocationScreen: () => dispatch(initializeLocationScreen()),
      fetchLocation: (payload) => dispatch(fetchLocation(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportLocation: (payload) => dispatch(exportLocation(payload)),
     };
  };
  
  const mapStateToProps = (state, ownProps) => {
    return {
      ...ownProps,
      locationListview: selectLocationsList(state.locationReducer),
      showPageLevelLoader: selectIsLoadingPage(state.locationReducer),
      paginationInfo: selectPaginationInfo(state.locationReducer),
      isPageLevelError: selectIsPageLevelError(state.locationReducer),
      isFetchingGridData: selectIsLoading(state.locationReducer),
      isLocationExportError: selectIsLocationExportError(
        state.locationReducer
      ),
      isLocationExportSuccess: selectIsLocationExportSuccess(
        state.locationReducer
      ),
    };
  };

// Connect with reducer 
const selectIsLocationExportError = (locationReducer) =>
  locationReducer.isLocationExportError;
const selectIsLocationExportSuccess = (locationReducer) =>
  locationReducer.isLocationExportSuccess;

const selectIsLoading = (locationReducer) => locationReducer.isLoading;
const selectPaginationInfo = (locationReducer) => locationReducer.paginationInfo;
const selectIsLoadingPage = (locationReducer) => locationReducer.isLoadingPage;
const selectIsPageLevelError = (locationReducer) =>  locationReducer.isPageLevelError;
const selectLocationsList = (locationReducer) =>  locationReducer.locationListview;

export default connect(mapStateToProps, mapDispatchToProps)(LocationScreen);
