import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { basicDetail, datagetbyid, driverageDD, editMode, statusDD, typeDD } from "../CreateReservationsBookingsbyLocationReport/constant"
import { Box, Container, Grid, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { hourslist } from "../../../Location/CreateLocation/constant";
import EzControlledInputAreaWithFloatingLabel from "../../../../common/EzForm/EzControlledInputArea/WithFloatingLabel";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import SpeedIcon from '@mui/icons-material/Speed';
import CardICON from '../../../../images/car-door.png'
import { ReservationsReservationsBreadcrumb } from "../../ReservationsReservations/CreateReservationsReservations/constant";
const CreateReservationsBookingsbyLocationReportComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateReservationsBookingsbyLocationReportScreen,
    createReservationsBookingsbyLocationReport,
    editReservationsBookingsbyLocationReport,
    isLoading,
    isReservationsBookingsbyLocationReportCreateSuccess,
    isReservationsBookingsbyLocationReportCreateError,
    isReservationsBookingsbyLocationReportDetailSuccess,
    isReservationsBookingsbyLocationReportDetailError,
    isReservationsBookingsbyLocationReportEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
    locationDD,
    vehicleCategoryDD
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  let searchfiled = {

  }
  const {

    handleSubmit: handleSubmit2,
    control: control2,
    register: register2,
    reset: resetForm2,
    formState: { errors: errors2, isDirty: isDirty2 },
    watch: watch2,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", searchfiled);
      return searchfiled;
    }, [searchfiled]),
  });
  // Payment Options
  let payOptionData = datagetbyid.PaymentOption;
  payOptionData = payOptionData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  // Damage Data
  let damageData = datagetbyid.DamageProtectionPlansReconsider;
  damageData = damageData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))

  // Additional Driver
  let additionalDriverData = datagetbyid.AdditionalDriver;
  additionalDriverData = additionalDriverData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))

  // GlassTires
  let glassTiresData = datagetbyid.GlassTires;
  glassTiresData = glassTiresData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  // GlassTires
  let equipmentData = datagetbyid.Equipment;
  equipmentData = equipmentData.map((item, idx) => ({
    ...item,
    isChecked:false
  }))
  // OutofProvince
  let outofProvinceData = datagetbyid.OutofProvince;
  outofProvinceData = outofProvinceData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  // ExtraMileage
  let extraMileageData = datagetbyid.ExtraMileage;
  extraMileageData = extraMileageData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  let otherData = datagetbyid.Other;
  const [amountTotal, setAmountTotal] = useState(otherData.reduce((sum, item) => sum + item.value, 0));

  const [other, setOther] = useState(otherData);
  const [paymentOption, setPaymentOption] = useState(payOptionData);
  const [damage, setDamage] = useState(damageData);
  const [additionalDriver, setAdditionalDriver] = useState(additionalDriverData);
  const [glassTires, setGlassTires] = useState(glassTiresData);
  const [equipment, setEquipment] = useState(equipmentData);
  const [outofProvince, setOutofProvince] = useState(outofProvinceData);
  const [extraMileage, setExtraMileage] = useState(extraMileageData);

  const otherDis = datagetbyid.Other.filter(item => item.name === "Pay now discount");

  const navigateToReservationsBookingsbyLocationReportScreen = () => {
    window.location.replace("/#" + APP_URLS.RESERVATIONS_BOOKINGBYLOCATIONREPORT.HOME)
  };

  const [inclusiveRate, setInclusiveRate] = useState(false)
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setInclusiveRate(checked)
  }

  useEffect(() => {

    if (isReservationsBookingsbyLocationReportCreateSuccess || isReservationsBookingsbyLocationReportEditSuccess) {
      reset();
    }
  }, [isReservationsBookingsbyLocationReportCreateSuccess]);
  useEffect(() => {
    if (isReservationsBookingsbyLocationReportDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isReservationsBookingsbyLocationReportDetailSuccess]);
  useEffect(() => {
    initCreateReservationsBookingsbyLocationReportScreen();
  }, []);

  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    const stringifiedData = {
      ...newData,
    }

    isEdit
      ? editReservationsBookingsbyLocationReport({ id: data.id, data: stringifiedData })
      : createReservationsBookingsbyLocationReport(stringifiedData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const getTotalCheckedValue = (items) => {
    return items
      .filter(item => item.isChecked) 
      .reduce((sum, item) => sum + item.value, 0); 
  };
  const handleTotalAmount = () => {
    const totalValue = other.reduce((sum, item) => sum + item.value, 0);
    const damValue = damage.find(item => item.isChecked === true)?.value || 0;
    const ofpValue = outofProvince.find(item => item.isChecked === true)?.value || 0;
    const adValue = additionalDriver.find(item => item.isChecked === true)?.value || 0;
    const gtValue = glassTires.find(item => item.isChecked === true)?.value || 0;
    const emValue = extraMileage.find(item => item.isChecked === true)?.value || 0;
    const eqpValue = getTotalCheckedValue(equipment);
    const finalTotal = (totalValue + damValue + ofpValue + adValue + gtValue + emValue + eqpValue).toFixed(2);
  
    setAmountTotal(finalTotal); 
  };
  
  useEffect(() => {
    handleTotalAmount();
  }, [other, damage, outofProvince, additionalDriver, glassTires, extraMileage, equipment]);
  

  
  const handleRadio = (e, i) => {
    let { name, value, checked } = e.target;
    if (name === "payOption") {
      const updatedData = paymentOption.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setPaymentOption(updatedData);
      console.log(updatedData)
      let dis = updatedData.find(item => item.name === "Pay Now");
      if (dis) {
        const updated = other.map(item => ({
          ...item,
          value:
            item.name === "Pay now discount"
              ? dis.isChecked
                ? otherDis[0].value
                : 0
              : item.value,
        }));
        setOther(updated);
        // const totalValue = updated.reduce((sum, item) => sum + item.value, 0);
        // setAmountTotal(totalValue)
      }
    }
    if (name === "damage") {
      const updatedData = damage.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setDamage(updatedData);
      
    }
    if (name === "outofProvince") {
      const updatedData = outofProvince.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setOutofProvince(updatedData);
    }
    
    if (name === "additionalDriver") {
      const updatedData = additionalDriver.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setAdditionalDriver(updatedData);
    }
    
    if (name === "glassTires") {
      const updatedData = glassTires.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setGlassTires(updatedData);
    }

    if (name === "equipment") {
      const updatedData = equipment.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item }
      ))
      setEquipment(updatedData);
    }
    
    if (name === "extraMileage") {
      const updatedData = extraMileage.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setExtraMileage(updatedData);
    }

  }

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Reservations Detail Report</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Reservations"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Reservations"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToReservationsBookingsbyLocationReportScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={ReservationsReservationsBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">

          <form onSubmit={handleSubmit2(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="location"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={locationDD}
                  isError={errors2['location']}
                  errorMsg={getFormErrorMessage('location')}
                  label="Location"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="vehicle"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={vehicleCategoryDD}
                  isError={errors2['vehicle']}
                  errorMsg={getFormErrorMessage('vehicle')}
                  label="Vehicle"
                  labelClassName={"ml-2"}
                />
              </Grid>

              <Grid item xs={2}>
                <EzControlledCalendarWithFloatingLabel
                  id="pickupDate"
                  name="pickupDate"
                  {...register2("startDate")}
                  showTime={false}
                  maxDate={new Date(2090, 0, 1)}
                  dateFormat="yy/mm/dd"
                  control={control2}
                  isError={errors2["pickupDate"]}
                  errorMsg={getFormErrorMessage("pickupDate")}
                  label="Pickup Date"
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="pickupTime"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={hourslist}
                  isError={errors2['pickupTime']}
                  errorMsg={getFormErrorMessage('pickupTime')}
                  label="Pickup Time"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledCalendarWithFloatingLabel
                  id="dropoffDate"
                  name="dropoffDate"
                  {...register2("dropoffDate")}
                  showTime={false}
                  maxDate={new Date(2090, 0, 1)}
                  dateFormat="yy/mm/dd"
                  control={control2}
                  isError={errors2["dropoffDate"]}
                  errorMsg={getFormErrorMessage("dropoffDate")}
                  label="Dropoff Date"
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="dropoffTime"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={hourslist}
                  isError={errors2['dropoffTime']}
                  errorMsg={getFormErrorMessage('dropoffTime')}
                  label="Dropoff Time"
                  labelClassName={"ml-2"}
                />
              </Grid>


              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="driverage"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={driverageDD}
                  isError={errors2['driverage']}
                  errorMsg={getFormErrorMessage('driverage')}
                  label="Driver's Age"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={3}>
                <div style={{ margin: "0px 0px 0px 10px", maxWidth: "100px" }}>
                  <Button
                    type="button"
                    label={"Search"}
                    className="mt-2 p-button-raised"
                    onClick={handleSubmit2(onSubmit)}
                  />
                </div>
              </Grid>
            </Grid>
          </form>

          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography className="headbgblue" variant="h6">
                  Selected Vehicle Information

                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box>
                      <img className="imgbox_renter" src="https://manager.rezrevolution.com/backend/web/images/ddcfa2f98e6278d8fd334d78689d4512-MVAR.jpg" />
                    </Box>
                    <p className="vantype">Mini-Vans</p>
                    <Box className="vandetails">
                      <ul>
                        <li>
                          <Diversity3Icon />
                          <span>7</span>
                        </li>
                        <li>
                          <BusinessCenterIcon />
                          <span>2</span>
                        </li>
                        <li>
                          <ManageAccountsIcon />
                          <span>A/T</span>
                        </li>
                        <li>
                          <img src={CardICON} />
                          <span>4</span>
                        </li>
                        <li>
                          <HeatPumpIcon />
                          <span>AC</span>
                        </li>
                        <li>
                          <SpeedIcon />
                          <span>LTD</span>
                        </li>

                      </ul>
                    </Box>



                  </Grid>
                  <Grid item xs={7} className="boxmidright">
                    <Grid container spacing={2}>
                      {paymentOption.map((item, index) => (
                        <Grid position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                          <input
                            checked={item.isChecked}
                            type="radio"
                            onChange={(e) => handleRadio(e, index)}
                            name="payOption"
                            value={item.value}
                          />
                          {item.name}
                          <span className="discount">
                            {item.name === "Pay Now" ? "Save $" + otherDis[0].value : "Pay at Pickup"}
                          </span>
                          <span className="valueoption">
                            ${item.value}
                          </span>
                        </Grid>
                      ))}

                    </Grid>

                    <table className="tbleforRenter">
                      <tbody>
                        {other.map((item, index) => (
                          <tr className="rowfont" key={"item" + item.id}>
                            <td colSpan={2}>{item.name}</td>
                            <td className="textr">${item.value}</td>
                          </tr>
                        ))}

                        <tr className="rowfont fontsize">
                          <td colSpan={2}>Total Amount (in CAD)</td>
                          <td className="textr">${amountTotal}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="totalamoutstikey">Total Amount (in CAD): ${amountTotal}</div>
                  </Grid>
                  <Grid container spacing={2}>
                  <Grid item xs={6} >
                  <div className="boxloop">
                   
                      <Typography className="heading">Damage Protection PlansReconsider?</Typography>
                      {damage.map((item, index) => (
                        <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                          <div className="name"  >
                            <label for={"damage_" + item.id}>
                              {item.name}</label>
                          </div>
                          <div className="value">
                            ${item.value}
                          </div>
                          <div className="radiobox">
                            <input
                              id={"damage_" + item.id}
                              checked={item.isChecked}
                              type="radio"
                              onChange={(e) => handleRadio(e, index)}
                              name="damage"
                              value={item.value}
                            /></div>
                        </Grid>
                      ))}

                   
                  </div>
                  <div className="boxloop">
                   
                      <Typography className="heading">Additional Driver(s)</Typography>
                      {additionalDriver.map((item, index) => (
                        <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                          <div className="name">
                            <label for={"additionalDriver_" + item.id}>
                              {item.name}</label>
                          </div>
                          <div className="value">
                            ${item.value}
                          </div>
                          <div className="radiobox">
                            <input
                              id={"additionalDriver_" + item.id}
                              checked={item.isChecked}
                              type="radio"
                              onChange={(e) => handleRadio(e, index)}
                              name="additionalDriver"
                              value={item.value}
                            /></div>
                        </Grid>
                      ))}

                   
                  </div>
                  <div className="boxloop">
                   
                   <Typography className="heading">Glass & Tires</Typography>
                   {glassTires.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"glassTires_" + item.id}>
                           {item.name}</label>
                       </div>
                       <div className="value">
                         ${item.value}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"glassTires_" + item.id}
                           checked={item.isChecked}
                           type="radio"
                           onChange={(e) => handleRadio(e, index)}
                           name="glassTires"
                           value={item.value}
                         /></div>
                     </Grid>
                   ))}

                
               </div>
               <div className="boxloop">
                   
                   <Typography className="heading">Equipment</Typography>
                   {equipment.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"equipment_" + item.id}>
                           {item.name}</label>
                       </div>
                       <div className="value">
                         ${item.value}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"equipment_" + item.id}
                           checked={item.isChecked}
                           type="checkbox"
                           onChange={(e) => handleRadio(e, index)}
                           name="equipment"
                           value={item.value}
                         /></div>
                     </Grid>
                   ))}

                
               </div>
               
               </Grid>
               <Grid item xs={6} >
               <div className="boxloop">
                   
                   <Typography className="heading">Out of Province</Typography>
                   {outofProvince.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"outofProvince_" + item.id}>
                           {item.name}</label>
                       </div>
                       <div className="value">
                         ${item.value}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"outofProvince_" + item.id}
                           checked={item.isChecked}
                           type="radio"
                           onChange={(e) => handleRadio(e, index)}
                           name="outofProvince"
                           value={item.value}
                         /></div>
                     </Grid>
                   ))}

                
               </div>

               <div className="boxloop">
                   
                   <Typography className="heading">Extra Mileage</Typography>
                   {extraMileage.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"extraMileage_" + item.id}>
                           {item.name}</label>
                       </div>
                       <div className="value">
                         ${item.value}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"extraMileage_" + item.id}
                           checked={item.isChecked}
                           type="radio"
                           onChange={(e) => handleRadio(e, index)}
                           name="extraMileage"
                           value={item.value}
                         /></div>
                     </Grid>
                   ))}

                
               </div>
              
               </Grid>
                  </Grid>
                 
                  
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography className="headbgblue" variant="h6">
                  Driver & Payment Information

                </Typography>
                <Grid container spacing={2} marginTop={"30px"}>
                  {basicDetail?.map((item) => (
                    item.name === "status" ? (
                      <Grid item xs={12} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={statusDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    ) :

                      item.type === "date" ? (
                        <Grid item xs={12} key={item.name}>
                          <EzControlledCalendarWithFloatingLabel
                            id={item.name}
                            name={item.name}
                            {...register(item.name)}
                            showTime={false}
                            maxDate={new Date(2090, 0, 1)}
                            dateFormat="yy/mm/dd"
                            control={control}
                            isError={errors[item.name]}
                            errorMsg={getFormErrorMessage(item.name)}
                            label={item.label}
                            rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                          />
                        </Grid>
                      ):item.type === "checkbox" ? (
                          <Grid item xs={12} key={item.name}>
                            <Typography className="copycard">
                            Car Rental Company Rules & Restrictions (e.g., insurance, driver age, mileage and geographic restrictions, shuttle info and additional charges)
                            </Typography>
                           <EzControlledCheckBoxWithLabel
                              name={item.name}
                              value={inclusiveRate}
                              id={item.name}
                              control={control}
                              isError={errors[item.label]}
                              label={item.label}
                              labelClassName={'ml-2'}
                              onChange={handleCheckbox}
                            />
                          </Grid>
                        ):item.box === "half" ? (
                          <Grid item xs={6} key={item.name}>
                          <EzControlledInputWithFloatingLabel
                            name={item?.name}
                            control={control}
                            label={item?.label}
                            type={item?.type}
                            rules={item?.rule}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                          />
                        </Grid>
                        )



                          : (
                            (<Grid item xs={12} key={item.name}>
                              <EzControlledInputWithFloatingLabel
                                name={item?.name}
                                control={control}
                                label={item?.label}
                                type={item?.type}
                                rules={item?.rule}
                                isError={errors[item?.name]}
                                errorMsg={getFormErrorMessage(item?.name)}
                              />
                            </Grid>))
                  ))}

                </Grid>
                <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
              </Grid>
            </Grid>


          
            {isEdit && (<Grid className="modsection" container spacing={2}>
              {editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                  <EzControlledInputWithFloatingLabel
                    name={item?.name}
                    control={control}
                    disabled={item?.disabled}
                    label={item?.label}
                    type={item?.type}
                    rules={item?.rule}
                    isError={errors[item?.name]}
                    errorMsg={getFormErrorMessage(item?.name)}
                  />
                </Grid>
              )
              )
              }
            </Grid>)
            }
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CreateReservationsBookingsbyLocationReportComponent;
