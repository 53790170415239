

export const FETCH_SETTINGS_SETTINGWEEKENDS_LIST = {
    START: 'FETCH_SETTINGS_SETTINGWEEKENDS_LIST_START',
    SUCCESS: 'FETCH_SETTINGS_SETTINGWEEKENDS_LIST_SUCCESS',
    ERROR: 'FETCH_SETTINGS_SETTINGWEEKENDS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_SETTINGS_SETTINGWEEKENDS_INIT="FETCH_SETTINGS_SETTINGWEEKENDS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_SETTINGS_SETTINGWEEKENDS_SCREEN =
'INIT_CREATE_SETTINGS_SETTINGWEEKENDS_SCREEN';

export const CREATE_SETTINGS_SETTINGWEEKENDS = {
START: 'CREATE_SETTINGS_SETTINGWEEKENDS_START',
SUCCESS: 'CREATE_SETTINGS_SETTINGWEEKENDS_SUCCESS',
ERROR: 'CREATE_SETTINGS_SETTINGWEEKENDS_ERROR',
};

export const EDIT_SETTINGS_SETTINGWEEKENDS = {
START: 'EDIT_SETTINGS_SETTINGWEEKENDS_START',
SUCCESS: 'EDIT_SETTINGS_SETTINGWEEKENDS_SUCCESS',
ERROR: 'EDIT_SETTINGS_SETTINGWEEKENDS_ERROR',
};

export const FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL = {
  START: 'FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL_START',
  SUCCESS: 'FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL_SUCCESS',
  ERROR: 'FETCH_SETTINGS_SETTINGWEEKENDS_DETAIL_ERROR',
};
export const EXPORT_SETTINGS_SETTINGWEEKENDS = {
  START: 'EXPORT_SETTINGS_SETTINGWEEKENDS_START',
  SUCCESS: 'EXPORT_SETTINGS_SETTINGWEEKENDS_SUCCESS',
  ERROR: 'EXPORT_SETTINGS_SETTINGWEEKENDS_ERROR',
};
export const SETTINGS_SETTINGWEEKENDSDD = {
  START: 'SETTINGS_SETTINGWEEKENDSDD_START',
  SUCCESS: 'SETTINGS_SETTINGWEEKENDSDD_SUCCESS',
  ERROR: 'SETTINGS_SETTINGWEEKENDSDD_ERROR',
};
export const SETTINGS_SETTINGWEEKENDSDELETE = {
  START: 'SETTINGS_SETTINGWEEKENDSDELETE_START',
  SUCCESS: 'SETTINGS_SETTINGWEEKENDSDELETE_SUCCESS',
  ERROR: 'SETTINGS_SETTINGWEEKENDSDELETE_ERROR',
};

