import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
 
   breadcrumb:{
    width:"100%",
    marginBottom:"20px",
    '& ul': {
        margin:0,
        padding:0,
        listStyle:"none",
        display:"flex",
        flexWrap: "wrap",
        gap: "10px",
        '& li': {
            color:theme.palette.text.menutext,
            fontSize:"12px",
            '& a': {
                color:theme.palette.text.menutext,
                textDecoration:"none",
                fontSize:"12px"
            }
        }
    }
   },
   bicon:{
     fontSize:"12px !important",
     margin:"0 10px"
   }
}));