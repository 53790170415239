import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { ReservationsDailyManifestReportServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_DAILYMANIFESTREPORT_INIT, 
  FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SCREEN,
  CREATE_RESERVATIONS_DAILYMANIFESTREPORT,
  EDIT_RESERVATIONS_DAILYMANIFESTREPORT,
  TOAST,
  FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL,
  EXPORT_RESERVATIONS_DAILYMANIFESTREPORT,
  RESERVATIONS_DAILYMANIFESTREPORTDD,
  RESERVATIONS_DAILYMANIFESTREPORTDELETE
} from "../../../types/Reservations/DailyManifestReport.type";
export const initializeReservationsDailyManifestReportScreen = () => ({ type: FETCH_RESERVATIONS_DAILYMANIFESTREPORT_INIT });

export const ReservationsDailyManifestReportFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST.START,
  payload,
});

export const ReservationsDailyManifestReportFetchSuccess = (payload) => ({
  type:FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST.SUCCESS,
  payload,
});

export const ReservationsDailyManifestReportFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_DAILYMANIFESTREPORT_LIST.ERROR,
  payload,
});

export const fetchReservationsDailyManifestReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDailyManifestReportFetchStart(payload));
    ReservationsDailyManifestReportServices.fetchReservationsDailyManifestReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsDailyManifestReportFetchSuccess(data));
      } else {
        dispatch(ReservationsDailyManifestReportFetchError(data));
      }
    });
  };
};



// DD
export const ReservationsDailyManifestReportDDFetchStart = (payload) => ({
  type: RESERVATIONS_DAILYMANIFESTREPORTDD.START,
  payload,
});

export const ReservationsDailyManifestReportDDFetchSuccess = (payload) => ({
  type:RESERVATIONS_DAILYMANIFESTREPORTDD.SUCCESS,
  payload,
});

export const ReservationsDailyManifestReportDDFetchError = (payload) => ({
  type: RESERVATIONS_DAILYMANIFESTREPORTDD.ERROR,
  payload,
});

export const fetchReservationsDailyManifestReportDD = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDailyManifestReportDDFetchStart(payload));
    ReservationsDailyManifestReportServices.fetchReservationsDailyManifestReportDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsDailyManifestReportDDFetchSuccess(data));
      } else {
        dispatch(ReservationsDailyManifestReportDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const ReservationsDailyManifestReportDetailFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL.START,
  payload,
});

export const ReservationsDailyManifestReportDetailFetchSuccess = (payload) => ({
  type: FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL.SUCCESS,
  payload,
});

export const ReservationsDailyManifestReportDetailFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_DAILYMANIFESTREPORT_DETAIL.ERROR,
  payload,
});

export const fetchReservationsDailyManifestReportById = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDailyManifestReportDetailFetchStart(payload));

    ReservationsDailyManifestReportServices
      .fetchReservationsDailyManifestReportById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsDailyManifestReportDetailFetchSuccess(data));
        } else {
          dispatch(ReservationsDailyManifestReportDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    ReservationsDailyManifestReportServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createReservationsDailyManifestReportScreenInitStart = (payload) => ({
  type: INIT_CREATE_RESERVATIONS_DAILYMANIFESTREPORT_SCREEN,
  payload,
});

export const initCreateReservationsDailyManifestReportScreen = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsDailyManifestReportScreenInitStart());
  };
};


export const createReservationsDailyManifestReportStart = () => ({
  type: CREATE_RESERVATIONS_DAILYMANIFESTREPORT.START,
});

export const createReservationsDailyManifestReportSuccess = (payload) => ({
  type: CREATE_RESERVATIONS_DAILYMANIFESTREPORT.SUCCESS,
  payload,
});

export const createReservationsDailyManifestReportError = (payload) => ({
  type: CREATE_RESERVATIONS_DAILYMANIFESTREPORT.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReservationsDailyManifestReport = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsDailyManifestReportStart());
    ReservationsDailyManifestReportServices.createReservationsDailyManifestReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReservationsDailyManifestReportSuccess());
        goBack();
      } else {
        dispatch(createReservationsDailyManifestReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editReservationsDailyManifestReportStart = (payload) => ({
  type: EDIT_RESERVATIONS_DAILYMANIFESTREPORT.START,
  payload
});

export const editReservationsDailyManifestReportSuccess = (payload) => ({
  type: EDIT_RESERVATIONS_DAILYMANIFESTREPORT.SUCCESS,
  payload,
});

export const editReservationsDailyManifestReportError = (payload) => ({
  type: EDIT_RESERVATIONS_DAILYMANIFESTREPORT.ERROR,
  payload,
});

export const editReservationsDailyManifestReport = (payload) => {
  return (dispatch) => {
    dispatch(editReservationsDailyManifestReportStart());
    ReservationsDailyManifestReportServices.editReservationsDailyManifestReport(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReservationsDailyManifestReportSuccess());
        goBack();
      } else {
        dispatch(editReservationsDailyManifestReportError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportReservationsDailyManifestReportRecordStart = (payload) => ({
  type: EXPORT_RESERVATIONS_DAILYMANIFESTREPORT.START,
  payload
});

export const exportReservationsDailyManifestReportRecordSuccess = (payload) => ({
  type: EXPORT_RESERVATIONS_DAILYMANIFESTREPORT.SUCCESS,
  payload,
});

export const exportReservationsDailyManifestReportRecordError = (payload) => ({
  type: EXPORT_RESERVATIONS_DAILYMANIFESTREPORT.ERROR,
  payload,
});

export const exportReservationsDailyManifestReport = (payload) => {
  return (dispatch) => {
    dispatch(exportReservationsDailyManifestReportRecordStart());
    ReservationsDailyManifestReportServices.exportReservationsDailyManifestReportRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportReservationsDailyManifestReportRecordSuccess(data));
      } else {
        dispatch(exportReservationsDailyManifestReportRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const ReservationsDailyManifestReportDeleteStart = (payload) => ({
  type: RESERVATIONS_DAILYMANIFESTREPORTDELETE.START,
  payload,
});

export const ReservationsDailyManifestReportDeleteSuccess = (payload) => ({
  type: RESERVATIONS_DAILYMANIFESTREPORTDELETE.SUCCESS,
  payload,
});

export const ReservationsDailyManifestReportDeleteError = (payload) => ({
  type: RESERVATIONS_DAILYMANIFESTREPORTDELETE.ERROR,
  payload,
});

export const deleteReservationsDailyManifestReport = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsDailyManifestReportDeleteStart(payload));

    ReservationsDailyManifestReportServices
      .deleteReservationsDailyManifestReport(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsDailyManifestReportDeleteSuccess(data));
          dispatch(fetchReservationsDailyManifestReport(payload?.params));
        } else {
          dispatch(ReservationsDailyManifestReportDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/