

export const FETCH_VEHICLE_TRIM_LIST = {
    START: 'FETCH_VEHICLE_TRIM_LIST_START',
    SUCCESS: 'FETCH_VEHICLE_TRIM_LIST_SUCCESS',
    ERROR: 'FETCH_VEHICLE_TRIM_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_VEHICLE_TRIM_INIT="FETCH_VEHICLE_TRIM_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_VEHICLE_TRIM_SCREEN =
'INIT_CREATE_VEHICLE_TRIM_SCREEN';

export const CREATE_VEHICLE_TRIM = {
START: 'CREATE_VEHICLE_TRIM_START',
SUCCESS: 'CREATE_VEHICLE_TRIM_SUCCESS',
ERROR: 'CREATE_VEHICLE_TRIM_ERROR',
};

export const EDIT_VEHICLE_TRIM = {
START: 'EDIT_VEHICLE_TRIM_START',
SUCCESS: 'EDIT_VEHICLE_TRIM_SUCCESS',
ERROR: 'EDIT_VEHICLE_TRIM_ERROR',
};

export const FETCH_VEHICLE_TRIM_DETAIL = {
  START: 'FETCH_VEHICLE_TRIM_DETAIL_START',
  SUCCESS: 'FETCH_VEHICLE_TRIM_DETAIL_SUCCESS',
  ERROR: 'FETCH_VEHICLE_TRIM_DETAIL_ERROR',
};
export const EXPORT_VEHICLE_TRIM = {
  START: 'EXPORT_VEHICLE_TRIM_START',
  SUCCESS: 'EXPORT_VEHICLE_TRIM_SUCCESS',
  ERROR: 'EXPORT_VEHICLE_TRIM_ERROR',
};
export const VEHICLE_TRIMDD = {
  START: 'VEHICLE_TRIMDD_START',
  SUCCESS: 'VEHICLE_TRIMDD_SUCCESS',
  ERROR: 'VEHICLE_TRIMDD_ERROR',
};