import React, { useContext } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { CustomThemeContext } from './CustomThemeProvider';


const useStyles = makeStyles(theme => ({
	themechange: {
		position:'fixed',
        bottom:'0px',
        right:'0px',
        zIndex:9999,
        background:'#fff'
	}
}));

export default function ThemeToggle() {
	const classes = useStyles();
	const { currentTheme, setTheme } = useContext(CustomThemeContext);

	const handleThemeChange = (event, newTheme) => {
        
        let theme = newTheme !=null ? newTheme : 'light'
			setTheme(theme);

	};

	return (
		<ToggleButtonGroup
			className={classes.themechange}
			value={currentTheme}
			exclusive
			size='small'
			onChange={handleThemeChange}>
			<ToggleButton className={classes.button} value='light'>
				Light
			</ToggleButton>
			<ToggleButton className={classes.button} value='dark'>
				Dark
			</ToggleButton>
		</ToggleButtonGroup>
	);
}