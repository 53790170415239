import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_VEHICLEOPERATIONS_LIST, 
  TOAST, 
  FETCH_VEHICLE_VEHICLEOPERATIONS_INIT,
  FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL,
  EXPORT_VEHICLE_VEHICLEOPERATIONS,
  CREATE_VEHICLE_VEHICLEOPERATIONS,
  EDIT_VEHICLE_VEHICLEOPERATIONS,
  INIT_CREATE_VEHICLE_VEHICLEOPERATIONS_SCREEN,
  VEHICLE_OPERATIONDD
} from "../../../types/vehiclecehicleoperations.type";



  const formFieldValueMap = {
    "activeStatus": "",
  "actualDepreciation": "",
  "crtBy": "",
  "crtTime": "",
  "estimatedDepreciation": "",
  "extColor": "",
  "fkCategoryId": "",
  "fkLocationId": "",
  "fkSupplierId": "",
  "groundingDate": "",
  id: null,
  "intColor": "",
  "ip": "",
  "keyCode": "",
  "make": "",
  "maxMileageAllowed": "",
  "maxMileageMonthly": "",
  "modBy": "",
  "modTime": "",
  "model": "",
  "modelYear": "",
  "plateNumber": "",
  "purchaseDate": "",
  "purchasePrice": "",
  "purchaseRepairCost": "",
  "purchaseType": "",
  "saleDate": "",
  "salePrice": "",
  "startingMileage": "",
  "status": "",
  "trim": "",
  "userId": "",
  "vehicleNumber": "",
  "vinNumber": ""
  };
  
  const INITIAL_STATE = {
    vehicleVehicleOperationsListview: [],
    vehicleOperationDD:[],
    isVehicleOperationDDSuccess: false,
    isVehicleOperationDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isVehicleVehicleOperationsCreateSuccess: false,
    isVehicleVehicleOperationsCreateError: false,
    isVehicleVehicleOperationsDetailSuccess: false,
    isVehicleVehicleOperationsDetailError: false,
    isVehicleVehicleOperationsEditSuccess: false,
    isVehicleVehicleOperationsEditError: false,
    isVehicleVehicleOperationsExportSuccess: false,
    isVehicleVehicleOperationsExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
        crtTime,
        ctTitle,
        extColor,
        id,
        locationTitle,
        makeTitle,
        modBy,
        modTime,
        modelTitle,
        plateNumber,
        vehicleNumber,
        vehicleStatus,
        vinNumber
      } = item;
  
      const transformedValues = {
        crtBy,
        crtTime,
        ctTitle,
        extColor,
        id,
        locationTitle,
        makeTitle,
        modBy,
        modTime,
        modelTitle,
        plateNumber,
        vehicleNumber,
        vehicleStatus,
        vinNumber
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Make",
        dataKey: "makeTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Model",
        dataKey: "modelTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Ext. Color",
        dataKey: "extColor",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Category",
        dataKey: "ctTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Location",
        dataKey: "locationTitle",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Plate#",
        dataKey: "plateNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Vehicle#",
        dataKey: "vehicleNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "VIN#",
        dataKey: "vinNumber",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const vehicleVehicleOperationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_VEHICLEOPERATIONS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL.START:
      case EXPORT_VEHICLE_VEHICLEOPERATIONS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_VEHICLE_VEHICLEOPERATIONS_LIST.START:
      case CREATE_VEHICLE_VEHICLEOPERATIONS.START:
        case EDIT_VEHICLE_VEHICLEOPERATIONS.START:
          case VEHICLE_OPERATIONDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_VEHICLE_VEHICLEOPERATIONS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          vehicleVehicleOperationsListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isVehicleVehicleOperationsCreateSuccess: false,
          isVehicleVehicleOperationsCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          vehicleVehicleOperationsListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_VEHICLE_VEHICLEOPERATIONS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          vehicleVehicleOperationsListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isvehicleVehicleOperationsDeletionSuccess: false,
          isvehicleVehicleOperationsDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
              temp["purchaseDate"] = new Date(action.payload?.purchaseDate);
              temp["saleDate"] = new Date(action.payload?.saleDate);
              temp["groundingDate"] = new Date(action.payload?.groundingDate);
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVehicleVehicleOperationsDetailSuccess: true,
      };
    }
    case FETCH_VEHICLE_VEHICLEOPERATIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleVehicleOperationsDetailSuccess: false,
        isVehicleVehicleOperationsDetailError: true,
      };
    }

    //DD
    case VEHICLE_OPERATIONDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        vehicleOperationDD:temp,
        isVehicleOperationDDSuccess: true,
        isVehicleOperationDDError: false,
      };
    }
    case VEHICLE_OPERATIONDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVehicleOperationDDSuccess: false,
    isVehicleOperationDDError: true,
      };
    }
    //END DD
    
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_VEHICLE_VEHICLEOPERATIONS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isVehicleVehicleOperationsExportSuccess: false,
    isVehicleVehicleOperationsExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_VEHICLE_VEHICLEOPERATIONS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"vehicle-make.xls");
   return {
    ...state,
    isVehicleVehicleOperationsExportSuccess: true,
    isVehicleVehicleOperationsExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_VEHICLE_VEHICLEOPERATIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleVehicleOperationsEditSuccess: false,
    isVehicleVehicleOperationsEditError: true,
  };
}

case EDIT_VEHICLE_VEHICLEOPERATIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleVehicleOperationsEditSuccess: true,
    isVehicleVehicleOperationsEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_VEHICLE_VEHICLEOPERATIONS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_VEHICLE_VEHICLEOPERATIONS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isVehicleVehicleOperationsCreateSuccess: false,
    isVehicleVehicleOperationsCreateError: true,
  };
}
case CREATE_VEHICLE_VEHICLEOPERATIONS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isVehicleVehicleOperationsCreateSuccess: true,
    isVehicleVehicleOperationsCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isVehicleVehicleOperationsCreateSuccess: false,
          isVehicleVehicleOperationsCreateError: false,
          isVehicleVehicleOperationsEditError: false,
          isVehicleVehicleOperationsEditSuccess: false,
          isVehicleVehicleOperationsDetailError: false
        };
      }
      default:
        return state;
    }
  };
  