import { Container, Typography } from '@mui/material'
import React from 'react'
import styleCss from './style.module.css'

export default function PageTitle(props) {
  return (
    <div className={styleCss.pagehead}>
    <Container maxWidth="lg">
        <Typography variant="h5" >{props?.title}</Typography>
    </Container>
    </div>
  )
}
