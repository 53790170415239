import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { rateAdminRatePlanServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RATEADMIN_RATEPLAN_INIT, 
  FETCH_RATEADMIN_RATEPLAN_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RATEADMIN_RATEPLAN_SCREEN,
  CREATE_RATEADMIN_RATEPLAN,
  EDIT_RATEADMIN_RATEPLAN,
  TOAST,
  FETCH_RATEADMIN_RATEPLAN_DETAIL,
  EXPORT_RATEADMIN_RATEPLAN,
  RATEADMIN_RATEPLANDD
} from "../../../types/rateadminrateplan.type";
export const initializeRateAdminRatePlanScreen = () => ({ type: FETCH_RATEADMIN_RATEPLAN_INIT });

export const rateadminRatePlanFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATEPLAN_LIST.START,
  payload,
});

export const rateadminRatePlanFetchSuccess = (payload) => ({
  type:FETCH_RATEADMIN_RATEPLAN_LIST.SUCCESS,
  payload,
});

export const rateadminRatePlanFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATEPLAN_LIST.ERROR,
  payload,
});

export const fetchRateAdminRatePlan = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRatePlanFetchStart(payload));
    rateAdminRatePlanServices.fetchRateAdminRatePlan(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRatePlanFetchSuccess(data));
      } else {
        dispatch(rateadminRatePlanFetchError(data));
      }
    });
  };
};



// DD
export const rateadminRatePlanDDFetchStart = (payload) => ({
  type: RATEADMIN_RATEPLANDD.START,
  payload,
});

export const rateadminRatePlanDDFetchSuccess = (payload) => ({
  type:RATEADMIN_RATEPLANDD.SUCCESS,
  payload,
});

export const rateadminRatePlanDDFetchError = (payload) => ({
  type: RATEADMIN_RATEPLANDD.ERROR,
  payload,
});

export const fetchRateAdminRatePlanDD = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRatePlanDDFetchStart(payload));
    rateAdminRatePlanServices.fetchRateAdminRatePlanDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(rateadminRatePlanDDFetchSuccess(data));
      } else {
        dispatch(rateadminRatePlanDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const rateadminRatePlanDetailFetchStart = (payload) => ({
  type: FETCH_RATEADMIN_RATEPLAN_DETAIL.START,
  payload,
});

export const rateadminRatePlanDetailFetchSuccess = (payload) => ({
  type: FETCH_RATEADMIN_RATEPLAN_DETAIL.SUCCESS,
  payload,
});

export const rateadminRatePlanDetailFetchError = (payload) => ({
  type: FETCH_RATEADMIN_RATEPLAN_DETAIL.ERROR,
  payload,
});

export const fetchRateAdminRatePlanById = (payload) => {
  return (dispatch) => {
    dispatch(rateadminRatePlanDetailFetchStart(payload));

    rateAdminRatePlanServices
      .fetchRateAdminRatePlanById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(rateadminRatePlanDetailFetchSuccess(data));
        } else {
          dispatch(rateadminRatePlanDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    rateAdminRatePlanServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRateAdminRatePlanScreenInitStart = (payload) => ({
  type: INIT_CREATE_RATEADMIN_RATEPLAN_SCREEN,
  payload,
});

export const initCreateRateAdminRatePlanScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRatePlanScreenInitStart());
  };
};


export const createRateAdminRatePlanStart = () => ({
  type: CREATE_RATEADMIN_RATEPLAN.START,
});

export const createRateAdminRatePlanSuccess = (payload) => ({
  type: CREATE_RATEADMIN_RATEPLAN.SUCCESS,
  payload,
});

export const createRateAdminRatePlanError = (payload) => ({
  type: CREATE_RATEADMIN_RATEPLAN.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRateAdminRatePlan = (payload) => {
  return (dispatch) => {
    dispatch(createRateAdminRatePlanStart());
    rateAdminRatePlanServices.createRateAdminRatePlan(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRateAdminRatePlanSuccess());
        goBack();
      } else {
        dispatch(createRateAdminRatePlanError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRateAdminRatePlanStart = (payload) => ({
  type: EDIT_RATEADMIN_RATEPLAN.START,
  payload
});

export const editRateAdminRatePlanSuccess = (payload) => ({
  type: EDIT_RATEADMIN_RATEPLAN.SUCCESS,
  payload,
});

export const editRateAdminRatePlanError = (payload) => ({
  type: EDIT_RATEADMIN_RATEPLAN.ERROR,
  payload,
});

export const editRateAdminRatePlan = (payload) => {
  return (dispatch) => {
    dispatch(editRateAdminRatePlanStart());
    rateAdminRatePlanServices.editRateAdminRatePlan(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRateAdminRatePlanSuccess());
        goBack();
      } else {
        dispatch(editRateAdminRatePlanError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRateAdminRatePlanRecordStart = (payload) => ({
  type: EXPORT_RATEADMIN_RATEPLAN.START,
  payload
});

export const exportRateAdminRatePlanRecordSuccess = (payload) => ({
  type: EXPORT_RATEADMIN_RATEPLAN.SUCCESS,
  payload,
});

export const exportRateAdminRatePlanRecordError = (payload) => ({
  type: EXPORT_RATEADMIN_RATEPLAN.ERROR,
  payload,
});

export const exportRateAdminRatePlan = (payload) => {
  return (dispatch) => {
    dispatch(exportRateAdminRatePlanRecordStart());
    rateAdminRatePlanServices.exportRateAdminRatePlanRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRateAdminRatePlanRecordSuccess(data));
      } else {
        dispatch(exportRateAdminRatePlanRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

