import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateReservationsReservationsScreen,
  createReservationsReservations,
  editReservationsReservations,
  fetchReservationsReservationsById,
  uploadImage,
} from "../../../../redux/actions/Reservations/ReservationsReservations";
import {
  fetchLocationDD
} from "../../../../redux/actions/location";
import {
  fetchVehicleCategoryDD
} from "../../../../redux/actions/vehicles/vehicleCategory";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateReservationsReservationsComponent from "../../../../component/Reservations/ReservationsReservations/CreateReservationsReservations";
import { useParams } from "react-router-dom";

const CreateReservationsReservationsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createReservationsReservations,
    editReservationsReservations,
    isLoading,
    isReservationsReservationsCreateSuccess,
    isReservationsReservationsCreateError,
    isReservationsReservationsDetailSuccess,
    isReservationsReservationsDetailError,
    isReservationsReservationsEditSuccess,
    isReservationsReservationsEditError,
    isEdit,
    isView,
    initCreateReservationsReservationsScreen,
    formFieldValueMap,
    fetchReservationsReservationsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        fetchLocationDD,
        locationDD,
        fetchVehicleCategoryDD,
vehicleCategoryDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateReservationsReservationsScreen();
    await fetchLocationDD();
    await fetchVehicleCategoryDD();
   if (isEdit) {
    await fetchReservationsReservationsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createReservationsReservationsProps = {
    createReservationsReservations,
    initCreateReservationsReservationsScreen,
    editReservationsReservations,
    isLoading,
    isEdit,
    isReservationsReservationsDetailSuccess,
    isReservationsReservationsCreateSuccess,
    isReservationsReservationsEditSuccess,
    isReservationsReservationsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        vehicleCategoryDD,
  };
  const getToastProps = () => {
    if (isReservationsReservationsCreateSuccess || isReservationsReservationsEditSuccess) {
      const toastTitle = isEdit
        ? "Reservations Updated Successfully"
        : "Reservations Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isReservationsReservationsCreateError ||
      isReservationsReservationsDetailError ||
      isReservationsReservationsEditError
    ) {
      let toastTitle = "Error while Creating Reservations";
      if (isReservationsReservationsEditError) {
        toastTitle = "Error while updating  Reservations";
      } else if (isReservationsReservationsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateReservationsReservationsComponent {...createReservationsReservationsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromReservationsReservationsReducer(state, "isLoading"),
   
    isReservationsReservationsCreateSuccess: selectFromReservationsReservationsReducer(
      state,
      "isReservationsReservationsCreateSuccess"
    ),
    isReservationsReservationsCreateError: selectFromReservationsReservationsReducer(
      state,
      "isReservationsReservationsCreateError"
    ),
    isReservationsReservationsDetailSuccess: selectFromReservationsReservationsReducer(
      state,
      "isReservationsReservationsDetailSuccess"
    ),
    isReservationsReservationsDetailError: selectFromReservationsReservationsReducer(
      state,
      "isReservationsReservationsDetailError"
    ),
    isReservationsReservationsEditSuccess: selectFromReservationsReservationsReducer(
      state,
      "isReservationsReservationsEditSuccess"
    ),
    isReservationsReservationsEditError: selectFromReservationsReservationsReducer(
      state,
      "isReservationsReservationsEditError"
    ),
    imageUploadResponse: selectFromReservationsReservationsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromReservationsReservationsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromReservationsReservationsReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromReservationsReservationsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromReservationsReservationsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromReservationsReservationsReducer(
      state,
      "isVehicleModelDDError"
    ),
    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleCategoryDD: selectFromVehicleVehicleCategoryReducer(
      state,
      "vehicleCategoryDD"
    ),
    formFieldValueMap: selectFromReservationsReservationsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateReservationsReservationsScreen: () => dispatch(initCreateReservationsReservationsScreen()),
    createReservationsReservations: (payload) => dispatch(createReservationsReservations(payload)),
    editReservationsReservations: (payload) => dispatch(editReservationsReservations(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    fetchVehicleCategoryDD: (payload) => dispatch(fetchVehicleCategoryDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchReservationsReservationsById: (payload) =>
      dispatch(fetchReservationsReservationsById(payload)),

  };
};

const selectFromReservationsReservationsReducer = (state, path) => {
  return selectReservationsReservationsReducer(state)[path];
};

const selectReservationsReservationsReducer = ({ ReservationsReservationsReducer }) => {
  return ReservationsReservationsReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
//Category
const selectFromVehicleVehicleCategoryReducer = (state, path) => {
  return selectVehicleVehicleCategoryReducer(state)[path];
};
const selectVehicleVehicleCategoryReducer = ({ vehicleCategoryReducer }) => {
  return vehicleCategoryReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservationsReservationsContainer);
