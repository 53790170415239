import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { APP_URLS } from "../../../utils/constant";
import {
  initCreateLocationScreen,
  createLocation,
  editLocation,
  fetchCategory,
  fetchLocationById,
} from "../../../redux/actions/location";
import { useEffect, useState } from "react";
import { setIsformDirty } from "../../../redux/actions/common/form";
import CreateLocationComponent from "../../../component/Location/CreateLocation";
import { useNavigate, useParams } from "react-router-dom";

const CreateLocationContainer = (props) => {
  
  console.log("checkprops on screen ", props);
  const {
    history,
    initCreateLocationScreen,
    createLocation,
    editLocation,
    isLoading,
    isCreateSuccess,
    isCreateError,
    isDetailSuccess,
    isDetailError,
    isEditSuccess,
    isEditError,
    isEdit,
    isView,
    formFieldValueMap,
    fetchLocationById,
    shouldBlockNavigation,
    fetchCategory,
    categoryListview
  } = props;

  const { id } = useParams();
  const callAPIasync = async() =>{
   await fetchCategory();
   if (isEdit) {
    await fetchLocationById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createLocationProps = {
    createLocation,
    editLocation,
    isLoading,
    isEdit,
    isDetailSuccess,
    isCreateSuccess,
    isEditSuccess,
    isEditError,
    formFieldValueMap,
    //ManageCardId,
    shouldBlockNavigation,
    fetchCategory,
    categoryListview
    };
  const getToastProps = () => {
    if (isCreateSuccess || isEditSuccess) {
      const toastTitle = isEdit
        ? "Location Updated Successfully"
        : "Location Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isCreateError ||
      isDetailError ||
      isEditError
    ) {
      let toastTitle = "Error while Creating Location";
      if (isEditError) {
        toastTitle = "Error while updating Location";
      } else if (isDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateLocationComponent {...createLocationProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromLocationReducer(state, "isLoading"),
   
    isCreateSuccess: selectFromLocationReducer(
      state,
      "isCreateSuccess"
    ),
    isCreateError: selectFromLocationReducer(
      state,
      "isCreateError"
    ),
    isDetailSuccess: selectFromLocationReducer(
      state,
      "isDetailSuccess"
    ),
    isDetailError: selectFromLocationReducer(
      state,
      "isDetailError"
    ),
    isEditSuccess: selectFromLocationReducer(
      state,
      "isEditSuccess"
    ),
    isEditError: selectFromLocationReducer(
      state,
      "isEditError"
    ),
    categoryListview: selectFromLocationReducer(
      state,
      "categoryListview"
    ),
    formFieldValueMap: selectFromLocationReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateLocationScreen: () => dispatch(initCreateLocationScreen()),
    fetchCategory: (payload) => dispatch(fetchCategory(payload)),
    createLocation: (payload) => dispatch(createLocation(payload)),
    editLocation: (payload) => dispatch(editLocation(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationById: (payload) =>
      dispatch(fetchLocationById(payload)),
  };
};

const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};

const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLocationContainer);
