import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateVehicleTransmissionTypeScreen,
  createVehicleTransmissionType,
  editVehicleTransmissionType,
  fetchVehicleTransmissionTypeById,
  uploadImage,
  fetchVehicleModelDD
} from "../../../../redux/actions/vehicles/vehicleTransmissionType";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateVehicleTransmissionTypeComponent from "../../../../component/Vehicles/VehicleTransmissionType/CreateVehicleTransmissionType";
import { useParams } from "react-router-dom";

const CreateVehicleTransmissionTypeContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createVehicleTransmissionType,
    editVehicleTransmissionType,
    isLoading,
    isVehicleTransmissionTypeCreateSuccess,
    isVehicleTransmissionTypeCreateError,
    isVehicleTransmissionTypeDetailSuccess,
    isVehicleTransmissionTypeDetailError,
    isVehicleTransmissionTypeEditSuccess,
    isVehicleTransmissionTypeEditError,
    isEdit,
    isView,
    initCreateVehicleTransmissionTypeScreen,
    formFieldValueMap,
    fetchVehicleTransmissionTypeById,
    fetchVehicleModelDD,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateVehicleTransmissionTypeScreen();
    await fetchVehicleModelDD();
   if (isEdit) {
    await fetchVehicleTransmissionTypeById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createVehicleTransmissionTypeProps = {
    createVehicleTransmissionType,
    initCreateVehicleTransmissionTypeScreen,
    editVehicleTransmissionType,
    isLoading,
    isEdit,
    isVehicleTransmissionTypeDetailSuccess,
    isVehicleTransmissionTypeCreateSuccess,
    isVehicleTransmissionTypeEditSuccess,
    isVehicleTransmissionTypeEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
  };
  const getToastProps = () => {
    if (isVehicleTransmissionTypeCreateSuccess || isVehicleTransmissionTypeEditSuccess) {
      const toastTitle = isEdit
        ? "Vehicle Transmission Type Updated Successfully"
        : "Vehicle Transmission Type Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isVehicleTransmissionTypeCreateError ||
      isVehicleTransmissionTypeDetailError ||
      isVehicleTransmissionTypeEditError
    ) {
      let toastTitle = "Error while Creating Vehicle Transmission Type";
      if (isVehicleTransmissionTypeEditError) {
        toastTitle = "Error while updating Vehicle Transmission Type";
      } else if (isVehicleTransmissionTypeDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVehicleTransmissionTypeComponent {...createVehicleTransmissionTypeProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromVehicleTransmissionTypeReducer(state, "isLoading"),
   
    isVehicleTransmissionTypeCreateSuccess: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleTransmissionTypeCreateSuccess"
    ),
    isVehicleTransmissionTypeCreateError: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleTransmissionTypeCreateError"
    ),
    isVehicleTransmissionTypeDetailSuccess: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleTransmissionTypeDetailSuccess"
    ),
    isVehicleTransmissionTypeDetailError: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleTransmissionTypeDetailError"
    ),
    isVehicleTransmissionTypeEditSuccess: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleTransmissionTypeEditSuccess"
    ),
    isVehicleTransmissionTypeEditError: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleTransmissionTypeEditError"
    ),
    imageUploadResponse: selectFromVehicleTransmissionTypeReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromVehicleTransmissionTypeReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromVehicleTransmissionTypeReducer(
      state,
      "isUploadImageError"
    ),


    vehicleModelDD: selectFromVehicleTransmissionTypeReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromVehicleTransmissionTypeReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromVehicleTransmissionTypeReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateVehicleTransmissionTypeScreen: () => dispatch(initCreateVehicleTransmissionTypeScreen()),
    createVehicleTransmissionType: (payload) => dispatch(createVehicleTransmissionType(payload)),
    editVehicleTransmissionType: (payload) => dispatch(editVehicleTransmissionType(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchVehicleTransmissionTypeById: (payload) =>
      dispatch(fetchVehicleTransmissionTypeById(payload)),
    fetchVehicleModelDD: (payload) =>
      dispatch(fetchVehicleModelDD(payload)),
  };
};

const selectFromVehicleTransmissionTypeReducer = (state, path) => {
  return selectVehicleTransmissionTypeReducer(state)[path];
};

const selectVehicleTransmissionTypeReducer = ({ vehicleTransmissionTypeReducer }) => {
  return vehicleTransmissionTypeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVehicleTransmissionTypeContainer);
