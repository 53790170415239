export const basicDetail =
[
  {
    name:"sourceType",
     "rule": {
        "required": true
      },
    label:"Source / Type of Customer",
    type:"dropdown"
  },
  {
    name:"vehicle",
     "rule": {
        "required": true
      },
    label:"Vehicle",
    type:"dropdown"
  },
  {
    name:"gasLevelOut",
     "rule": {
        "required": true
      },
    label:"Gas Level Out",
    type:"text"
  },
  {
    name:"status",
     "rule": {
        "required": true
      },
    label:"Status",
    type:"dropdown"
  },
  
  
]
export const onWalkIn =[
  {
    name:"emailId",
     "rule": {
        "required": true
      },
    label:"Email Id",
    type:"text"
  },
  {
    name:"location",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"dropdown"
  },
]

export const RenterInformation =[
  {
    name:"renterFirstName",
     "rule": {
        "required": false
      },
    label:"Renter First Name",
    type:"text"
  },
  {
    name:"renterLastName",
     "rule": {
        "required": false
      },
    label:"Renter Last Name",
    type:"text"
  },
  {
    name:"address",
     "rule": {
        "required": false
      },
    label:"Address",
    type:"text"
  },
  {
    name:"cityStateZip",
     "rule": {
        "required": false
      },
    label:"City / State / ZIP",
    type:"text"
  },
  {
    name:"phoneNumber",
     "rule": {
        "required": false
      },
    label:"Phone Number",
    type:"text"
  },
  {
    name:"licenseNumber",
     "rule": {
        "required": false
      },
    label:"License Number",
    type:"text"
  },
  {
    name:"dateofBirth",
     "rule": {
        "required": false
      },
    label:"Date of Birth",
    type:"date"
  },
  {
    name:"licenseState",
     "rule": {
        "required": false
      },
    label:"License State",
    type:"text"
  },
  {
    name:"licenseExpiry",
     "rule": {
        "required": false
      },
    label:"License Expiry",
    type:"date"
  },
  {
    name:"insuranceCompany",
     "rule": {
        "required": false
      },
    label:"Insurance Company",
    type:"text"
  },
]


export const AdditionalDriver1Information=[
  {
    name:"driverName1",
     "rule": {
        "required": false
      },
    label:"Driver Name",
    type:"text"
  },
  {
    name:"licenseNumber1",
     "rule": {
        "required": false
      },
    label:"License Number",
    type:"text"
  },
  {
    name:"dateofBirth1",
     "rule": {
        "required": false
      },
    label:"Date of Birth",
    type:"date"
  },
  {
    name:"licenseState1",
     "rule": {
        "required": false
      },
    label:"License State",
    type:"text"
  },
  {
    name:"licenseExpiry1",
     "rule": {
        "required": false
      },
    label:"License Expiry",
    type:"date"
  },
  {
    name:"insuranceCompany1",
     "rule": {
        "required": false
      },
    label:"Insurance Company",
    type:"text"
  },
]
export const AdditionalDriver2Information=[
  {
    name:"driverName2",
     "rule": {
        "required": false
      },
    label:"Driver Name",
    type:"text"
  },
  {
    name:"licenseNumber2",
     "rule": {
        "required": false
      },
    label:"License Number",
    type:"text"
  },
  {
    name:"dateofBirth2",
     "rule": {
        "required": false
      },
    label:"Date of Birth",
    type:"date"
  },
  {
    name:"licenseState2",
     "rule": {
        "required": false
      },
    label:"License State",
    type:"text"
  },
  {
    name:"licenseExpiry2",
     "rule": {
        "required": false
      },
    label:"License Expiry",
    type:"date"
  },
  {
    name:"insuranceCompany2",
     "rule": {
        "required": false
      },
    label:"Insurance Company",
    type:"text"
  },
]
export const AdditionalDriver3Information=[
  {
    name:"driverName3",
     "rule": {
        "required": false
      },
    label:"Driver Name",
    type:"text"
  },
  {
    name:"licenseNumber3",
     "rule": {
        "required": false
      },
    label:"License Number",
    type:"text"
  },
  {
    name:"dateofBirth3",
     "rule": {
        "required": false
      },
    label:"Date of Birth",
    type:"date"
  },
  {
    name:"licenseState3",
     "rule": {
        "required": false
      },
    label:"License State",
    type:"text"
  },
  {
    name:"licenseExpiry3",
     "rule": {
        "required": false
      },
    label:"License Expiry",
    type:"date"
  },
  {
    name:"insuranceCompany3",
     "rule": {
        "required": false
      },
    label:"Insurance Company",
    type:"text"
  },
]
export const ReservationInformation=[
  {
    name:"pickupDate",
     "rule": {
        "required": false
      },
    label:"Pickup Date",
    type:"date"
  },
  {
    name:"pickupTime",
     "rule": {
        "required": false
      },
    label:"Pickup Time",
    type:"dropdown"
  },
  {
    name:"dropoffDate",
     "rule": {
        "required": false
      },
    label:"Dropoff Date",
    type:"date"
  },
  {
    name:"dropoffTime",
     "rule": {
        "required": false
      },
    label:"Dropoff Time",
    type:"dropdown"
  },
  {
    name:"hourlyRate",
     "rule": {
        "required": false
      },
    label:"Hourly Rate",
    type:"text"
  },
  {
    name:"dailyRate",
     "rule": {
        "required": false
      },
    label:"Daily Rate",
    type:"text"
  },
  {
    name:"weeklyRate",
     "rule": {
        "required": false
      },
    label:"Weekly Rate",
    type:"text"
  },
  {
    name:"monthlyRate",
     "rule": {
        "required": false
      },
    label:"Monthly Rate",
    type:"text"
  }
]
export const ExtraProductServices=[
  {
    name:"category",
     "rule": {
        "required": false
      },
    label:"Category",
    type:"dropdown"
  },
  {
    name:"product",
     "rule": {
        "required": false
      },
    label:"Product",
    type:"dropdown"
  },
  {
    name:"rate",
     "rule": {
        "required": false
      },
    label:"Rate",
    type:"text"
  }
]
export const PaymentInformation=[
  {
    name:"cardholderName",
     "rule": {
        "required": false
      },
    label:"Cardholder Name",
    type:"text"
  },
  {
    name:"cardNumber",
     "rule": {
        "required": false
      },
    label:"Card Number",
    type:"text"
  },
  {
    name:"expiry",
     "rule": {
        "required": false
      },
    label:"Expiry (MM/YY)",
    type:"text"
  },
  {
    name:"cvv",
     "rule": {
        "required": false
      },
    label:"CVV",
    type:"text"
  },
  {
    name:"paymentStatus",
     "rule": {
        "required": false
      },
    label:"Payment Status",
    type:"dropdown"
  },
]


export const paymentStatusDD =
[
  { id:1, name:"Paid"},
  { id:2, name:"Not Paid"}
]
export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const typeDD =
[
  { id:1, name:"Flat"},
  { id:2, name:"Percentage"}
]

export const sourceTypeDD =[
  { id:1, name:"Online"},
  { id:2, name:"Walk In"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
  export const categoryDD =[
    { id:1, name:"Extra Equipment"},
    { id:2, name:"Loss Damage Waiver (LDW) Protect"},
    { id:3, name:"Travelling Out of Province or USA Fee"},
    { id:4, name:"Local Renters Pick and Drop Off Service"},
    { id:5, name:"Extra Mileage"},
    { id:6, name:"Glass & Tires Protection"},
    { id:7, name:"Additional Driver(s)"}
  ]
  
  export const lookingForExisting = [
    {
       name:"daily",
        "rule": {
           "required": true
         },
       label:"Daily",
       type:"text"
     },{
       name:"weekEnd",
        "rule": {
           "required": true
         },
       label:"Weekend",
       type:"text"
     },
     {
       name:"weekly",
        "rule": {
           "required": true
         },
       label:"Weekly",
       type:"text"
     },
     
     {
       name:"monthly",
        "rule": {
           "required": true
         },
       label:"Monthly",
       type:"text"
     },
   ]