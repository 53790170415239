import React, { useEffect, useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { AdditionalDriver1Information, AdditionalDriver2Information, AdditionalDriver3Information, basicDetail, categoryDD, editMode, ExtraProductServices, lookingForExisting, onWalkIn, PaymentInformation, paymentStatusDD, RenterInformation, ReservationInformation, sourceTypeDD, statusDD, typeDD } from "../CreateRentersRevenueAgreements/constant"
import { Box, Container, Grid, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate, useParams } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { RentersRentersInfoBreadcrumb } from "../../RentersRentersInfo/CreateRentersRentersInfo/constant";
import { hourslist } from "../../../Location/CreateLocation/constant";
import EzDialog from "../../../../common/EzDialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const CreateRentersRevenueAgreementsComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRentersRevenueAgreementsScreen,
    createRentersRevenueAgreements,
    editRentersRevenueAgreements,
    isLoading,
    isRentersRevenueAgreementsCreateSuccess,
    isRentersRevenueAgreementsDetailSuccess,
    isRentersRevenueAgreementsEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    vehicleModelDD,
    locationDD,
    fetchRentersRevenueAgreementsExistingData,
    RentersRevenueAgreementsExistingData
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  let formFieldValueMap2={
    endDate:"",
    endTime:"",
    rateId:"",
    startDate:"",
    startTime:""
  }
  const {
    handleSubmit: handleSubmit2,
    control:control2,
    reset: resetForm2,
    formState: { errors:errors2, isDirty:isDirty2 },
} = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap2;
    }, [formFieldValueMap2]),
  });
  const [showDialog, setShowDialog] = useState(false);
  const [rateAdjustmentList, setRateAdjustmentList] = useState([]);
  const onDialogHide = () => {
   setShowDialog(false);
 }
 const showExtraData = () => {
   setShowDialog(true);
 }
 
 const handleGetBYID =(id) =>{
  console.log("IDS", id)
 }

const sourceType = watch("sourceType");
const { fields, append, update } = useFieldArray({
  control,
  name: "extraProductServices"
});
const watchFieldArray = watch("extraProductServices");
const controlledFields = fields.map((field, index) => {
  return {
    ...field,
    ...watchFieldArray[index]
  };
});

const handleRemoveClick = (i) => {
  const updatedData = controlledFields.map((item, index) => (
    i === index ? { ...item, status: false } : { ...item }
  ))
  update(i, { status: false, ...updatedData[i] })
}
//Adding Item
const handleAddClick = () => {
append({
  id: 0,
  status: true,
  category: '',
  product: '',
  rate: '',
});
};

  const navigateToRentersRevenueAgreementsScreen = () => {
    window.location.replace("/#" + APP_URLS.RENTERS_REVENUEAGREEMENTS.HOME)
  };
  useEffect(() => {

    if (isRentersRevenueAgreementsCreateSuccess || isRentersRevenueAgreementsEditSuccess) {
      reset();
    }
  }, [isRentersRevenueAgreementsCreateSuccess]);
  useEffect(() => {
    if (isRentersRevenueAgreementsDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isRentersRevenueAgreementsDetailSuccess]);
  useEffect(() => {
    initCreateRentersRevenueAgreementsScreen();
  }, []);

  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");

    const stringifiedData = {
      ...newData,
    }
   console.log("BABA PAY", stringifiedData)
    isEdit
      ? editRentersRevenueAgreements({ id: data.id, data: stringifiedData })
      : createRentersRevenueAgreements(stringifiedData);

  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };
  const onRateSubmit =(data) =>{
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
   
let stringifiedData={
   ...newData
}
    !isEdit
    && fetchRentersRevenueAgreementsExistingData( stringifiedData )
  }
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const getFormErrorMessage2 = (name) => {
    return (
      errors2[name] && <small className="p-error">{errors2[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Revenue Agreements</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Revenue Agreements"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Revenue Agreements"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToRentersRevenueAgreementsScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={RentersRentersInfoBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          
            <Grid container spacing={2}>
              {basicDetail?.map((item) => (
                item.name === "status" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={statusDD}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :
                item.name === "sourceType" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={item?.name}
                      control={control}
                      rules={item?.rule}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={sourceTypeDD}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                      label={item?.label}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                ) :
                  item.type === "date" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledCalendarWithFloatingLabel
                        id={item.name}
                        name={item.name}
                        {...register(item.name)}
                        showTime={false}
                        maxDate={new Date(2090, 0, 1)}
                        dateFormat="yy/mm/dd"
                        control={control}
                        isError={errors[item.name]}
                        errorMsg={getFormErrorMessage(item.name)}
                        label={item.label}
                        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                      />
                    </Grid>
                  )
                    :
                    item.name === "vehicle" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={statusDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )
                    :  
                        (<Grid item xs={3} key={item.name}>
                          <EzControlledInputWithFloatingLabel
                            name={item?.name}
                            control={control}
                            label={item?.label}
                            type={item?.type}
                            rules={item?.rule}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                          />
                        </Grid>)
              ))}
</Grid>
{(sourceType=="2" || sourceType=="1") && (<>
<Grid container spacing={2}>
{sourceType=="2" && (
  
  <>
               <Grid item xs={3} >
                  <EzControlledInputWithFloatingLabel
                    name={'emailId'}
                    control={control}
                    label={'Email Id'}
                    type={'text'}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors['emailId']}
                    errorMsg={getFormErrorMessage('emailId')}
                  />
                  </Grid>
                  <Grid item xs={3}>
                 <Button
                      type="submit"
                      label={"Check if email is exist"}
                      className="mt-2 p-button-raised"
                    />
                </Grid>
                </>
)}
{sourceType=="1" && (
  
  <>
               <Grid item xs={3} >
               <EzControlledDropDowntWithFloatingLabel
                      name={'reservationId'}
                      control={control}
                      rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={locationDD}
                      isError={errors['reservationId']}
                      errorMsg={getFormErrorMessage('reservationId')}
                      label={'Reservation ID'}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                 <Button
                      type="button"
                      label={"OR Look for existing data"}
                      className="mt-2 p-button-raised"
                      onClick={showExtraData}
                    />
                </Grid>
                </>
)}
                  <Grid item xs={3}>
                    <EzControlledDropDowntWithFloatingLabel
                      name={'location'}
                      control={control}
                      rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                      optionLabel={"name"}
                      optionValue={"id"}
                      options={locationDD}
                      isError={errors['location']}
                      errorMsg={getFormErrorMessage('location')}
                      label={'Location'}
                      labelClassName={"ml-2"}
                    />
                  </Grid>
              
                  </Grid>
              
            
            <h4 className="pagetitlemirror">Renter Information </h4>
            <Grid container spacing={2}>
              {RenterInformation?.map((item) => (

                item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    />
                  </Grid>
                )

                  : (
                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
              ))}

            </Grid>
            <h4 className="pagetitlemirror">Additional Driver 1 Information </h4>
            <Grid container spacing={2}>
              {AdditionalDriver1Information?.map((item) => (

                item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    />
                  </Grid>
                )

                  : (
                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
              ))}

            </Grid>

            <h4 className="pagetitlemirror">Additional Driver 2 Information </h4>
            <Grid container spacing={2}>
              {AdditionalDriver2Information?.map((item) => (

                item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    />
                  </Grid>
                )

                  : (
                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
              ))}

            </Grid>
            <h4 className="pagetitlemirror">Additional Driver 3 Information </h4>
            <Grid container spacing={2}>
              {AdditionalDriver3Information?.map((item) => (

                item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    />
                  </Grid>
                )

                  : (
                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
              ))}

            </Grid>
            <h4 className="pagetitlemirror">Reservation Information</h4>
            <Grid container spacing={2}>
              {ReservationInformation?.map((item) => (

item.name === "pickupTime" ? (
  <Grid item xs={3} key={item.name}>
    <EzControlledDropDowntWithFloatingLabel
      name={item?.name}
      control={control}
      rules={item?.rule}
      optionLabel={"name"}
      optionValue={"id"}
      options={hourslist}
      isError={errors[item?.name]}
      errorMsg={getFormErrorMessage(item?.name)}
      label={item?.label}
      labelClassName={"ml-2"}
    />
  </Grid>
)
:
                    item.name === "dropoffTime" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={hourslist }
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )
:

                item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    />
                  </Grid>
                )

                  : (
                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
              ))}

            </Grid>



            
           
            <h4 className="pagetitlemirror">Extra Product Services

            <div style={{ margin: '0px', maxWidth: '70px' }} className="addbtn">
<Button
                      icon={'pi pi-plus'}
                      type="button"
                      onClick={handleAddClick}
                     
                      className="mt-2 p-button-raised"
                    />
                    </div>
            </h4>
  
                    
            {controlledFields.map((field, index) => {
                  return (
                    field.status !== false ?
<Grid container spacing={2} key={`item${index}`}>
<Grid item xs={3}>
    <EzControlledDropDowntWithFloatingLabel
      name={`extraProductServices.${index}.category`}
      control={control}
      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      optionLabel={"name"}
      optionValue={"id"}
      options={categoryDD}
      isError={errors?.extraProductServices?.[index]?.category}
      errorMsg={getFormErrorMessage(errors?.extraProductServices?.[index]?.category)}
      label={'Category'}
      labelClassName={"ml-2"}
    />
  </Grid>

  
<Grid item xs={3}>
  {field.category===1 ?
    (<EzControlledDropDowntWithFloatingLabel
      name={`extraProductServices.${index}.product`}
      control={control}
      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      optionLabel={"name"}
      optionValue={"id"}
      options={hourslist}
      isError={errors?.extraProductServices?.[index]?.product}
      errorMsg={getFormErrorMessage(errors?.extraProductServices?.[index]?.product)}
      label={'Product'}
      labelClassName={"ml-2"}
    />):
    field.category===2 ?
    (<EzControlledDropDowntWithFloatingLabel
    name={`extraProductServices.${index}.product`}
    control={control}
    rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
    optionLabel={"name"}
    optionValue={"id"}
    options={categoryDD}
    isError={errors?.extraProductServices?.[index]?.product}
    errorMsg={getFormErrorMessage(errors?.extraProductServices?.[index]?.product)}
    label={'Product'}
    labelClassName={"ml-2"}
  />):(<EzControlledDropDowntWithFloatingLabel
  name={`extraProductServices.${index}.product`}
  control={control}
  rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
  optionLabel={"name"}
  optionValue={"id"}
  options={hourslist}
  isError={errors?.extraProductServices?.[index]?.product}
  errorMsg={getFormErrorMessage(errors?.extraProductServices?.[index]?.product)}
  label={'Product'}
  labelClassName={"ml-2"}
/>)}
  </Grid>

  <Grid item xs={3}>

  <EzControlledInputWithFloatingLabel
                         name={`extraProductServices.${index}.rate`}
                        control={control}
                        label={'Rate'}
                        type={'text'}
                        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                        isError={errors?.extraProductServices?.[index]?.rate}
      errorMsg={getFormErrorMessage(errors?.extraProductServices?.[index]?.rate)}
                      />
    
  </Grid>
  <Grid className={classes.pt0} item xs={2} display={"flex"}>

<div style={{ margin: '15px 0px 0px 10px', maxWidth: '70px' }}>
<Button
  icon={'pi pi-minus'}
  type="button"
  onClick={()=>handleRemoveClick(index)}
  className="mt-2 p-button-raised"
  severity="danger"
/></div>
</Grid>
            </Grid>
:null)
})}

            <h4 className="pagetitlemirror">Payment Information
            </h4>
            <Grid container spacing={2}>
              {PaymentInformation?.map((item) => (
item.name === "paymentStatus" ? (
  <Grid item xs={3} key={item.name}>
    <EzControlledDropDowntWithFloatingLabel
      name={item?.name}
      control={control}
      rules={item?.rule}
      optionLabel={"name"}
      optionValue={"id"}
      options={paymentStatusDD}
      isError={errors[item?.name]}
      errorMsg={getFormErrorMessage(item?.name)}
      label={item?.label}
      labelClassName={"ml-2"}
    />
  </Grid>
) :
item.name === "pickupTime" ? (
  <Grid item xs={3} key={item.name}>
    <EzControlledDropDowntWithFloatingLabel
      name={item?.name}
      control={control}
      rules={item?.rule}
      optionLabel={"name"}
      optionValue={"id"}
      options={hourslist}
      isError={errors[item?.name]}
      errorMsg={getFormErrorMessage(item?.name)}
      label={item?.label}
      labelClassName={"ml-2"}
    />
  </Grid>
)
:
                    item.name === "dropoffTime" ? (
                      <Grid item xs={3} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={hourslist }
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    )
:

                item.type === "date" ? (
                  <Grid item xs={3} key={item.name}>
                    <EzControlledCalendarWithFloatingLabel
                      id={item.name}
                      name={item.name}
                      {...register(item.name)}
                      showTime={false}
                      maxDate={new Date(2090, 0, 1)}
                      dateFormat="yy/mm/dd"
                      control={control}
                      isError={errors[item.name]}
                      errorMsg={getFormErrorMessage(item.name)}
                      label={item.label}
                      rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
                    />
                  </Grid>
                )

                  : (
                    (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
              ))}
<Grid item xs={3} >

<Button
                      type="submit"
                      label={"Calculate Total Amount"}
                      className="mt-2 p-button-raised"
                      severity="warning"
                    />
</Grid>
            </Grid>

           </>)}
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
            {isEdit && (<Grid className="modsection" container spacing={2}>
              {editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                  <EzControlledInputWithFloatingLabel
                    name={item?.name}
                    control={control}
                    disabled={item?.disabled}
                    label={item?.label}
                    type={item?.type}
                    rules={item?.rule}
                    isError={errors[item?.name]}
                    errorMsg={getFormErrorMessage(item?.name)}
                  />
                </Grid>
              )
              )
              }
            </Grid>)
            }
          </form>

          {showDialog && (
              <EzDialog onHide={onDialogHide} maximizable={false} width={"70vw"} height={"275px"} header={"Looking for existing data"}>
                <form onSubmit={handleSubmit2(onRateSubmit)} className="p-fluid">
                <Grid container spacing={2} paddingTop={"20px"}>
                {lookingForExisting?.map((item) => (
   <Grid item xs={6} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control2}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors2[item?.name]}
                        errorMsg={getFormErrorMessage2(item?.name)}
                      />
                    </Grid>
))}
                </Grid>

               
                <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="button"
                        label={ "Cancel"}
                        className="mt-2 p-button-raised"
                        onClick={onDialogHide}
                      />
                    </div>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                         type="submit"
                        label={ "Check for Data"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
                </form>

                {RentersRevenueAgreementsExistingData.length > 0 && (
                   <Grid container spacing={2} paddingTop={"20px"}>
<Box id="printable">
  <Typography variant="h6" mt={"20px"}>Search Results</Typography>
<DataTable value={RentersRevenueAgreementsExistingData}>
<Column 
        field="name" 
        header="Name" 
        body={(rowData) => (
            <span onClick={() => handleGetBYID(rowData.id)}>{rowData.name}</span>
        )}
    ></Column>
                <Column field="resId" header="Res ID"
                 body={(rowData) => (
                  <span onClick={() => handleGetBYID(rowData.id)}>{rowData.resId}</span>
              )}
                ></Column>
                <Column field="locationTitle" header="Location"
                 body={(rowData) => (
                  <span onClick={() => handleGetBYID(rowData.id)}>{rowData.locationTitle}</span>
              )}
                ></Column>
                <Column field="emailID" header="Email ID"
                 body={(rowData) => (
                  <span onClick={() => handleGetBYID(rowData.id)}>{rowData.emailID}</span>
              )}
                ></Column>
                <Column field="firstLastName" header="First & Last Name"
                 body={(rowData) => (
                  <span onClick={() => handleGetBYID(rowData.id)}>{rowData.firstLastName}</span>
              )}
                ></Column>
                <Column field="checkoutDate" header="Checkout Date"
                 body={(rowData) => (
                  <span onClick={() => handleGetBYID(rowData.id)}>{rowData.checkoutDate}</span>
              )}
                ></Column>
                <Column field="checkinDate" header="Checkin Date"
                 body={(rowData) => (
                  <span onClick={() => handleGetBYID(rowData.id)}>{rowData.checkinDate}</span>
              )}
                ></Column>
            </DataTable>             
              </Box></Grid>
)}
                </EzDialog>

      )}
        </div>
      </div>
    </Container>
  );
};

export default CreateRentersRevenueAgreementsComponent;
