import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import {
  initCreateRentersNonRevenueAgreementsScreen,
  createRentersNonRevenueAgreements,
  editRentersNonRevenueAgreements,
  fetchRentersNonRevenueAgreementsById,
  uploadImage,
} from "../../../../redux/actions/Renters/RentersNonRevenueAgreements";
import {
  fetchLocationDD,
  
} from "../../../../redux/actions/location";
import { useEffect } from "react";
import { setIsformDirty } from "../../../../redux/actions/common/form";
import CreateRentersNonRevenueAgreementsComponent from "../../../../component/Renters/RentersNonRevenueAgreements/CreateRentersNonRevenueAgreements";
import { useParams } from "react-router-dom";

const CreateRentersNonRevenueAgreementsContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createRentersNonRevenueAgreements,
    editRentersNonRevenueAgreements,
    isLoading,
    isRentersNonRevenueAgreementsCreateSuccess,
    isRentersNonRevenueAgreementsCreateError,
    isRentersNonRevenueAgreementsDetailSuccess,
    isRentersNonRevenueAgreementsDetailError,
    isRentersNonRevenueAgreementsEditSuccess,
    isRentersNonRevenueAgreementsEditError,
    isEdit,
    isView,
    initCreateRentersNonRevenueAgreementsScreen,
    formFieldValueMap,
    fetchRentersNonRevenueAgreementsById,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    shouldBlockNavigation,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
        fetchLocationDD,
  } = props;

  const { id } = useParams
  ();
  const callAPIasync = async() =>{
    initCreateRentersNonRevenueAgreementsScreen();
    await fetchLocationDD();
   if (isEdit) {
    await fetchRentersNonRevenueAgreementsById({id:id});
  }
  }
  useEffect(() => {
    callAPIasync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

 

  const createRentersNonRevenueAgreementsProps = {
    createRentersNonRevenueAgreements,
    initCreateRentersNonRevenueAgreementsScreen,
    editRentersNonRevenueAgreements,
    isLoading,
    isEdit,
    isRentersNonRevenueAgreementsDetailSuccess,
    isRentersNonRevenueAgreementsCreateSuccess,
    isRentersNonRevenueAgreementsEditSuccess,
    isRentersNonRevenueAgreementsEditError,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
        isVehicleModelDDSuccess,
        isVehicleModelDDError,
        locationDD,
  };
  const getToastProps = () => {
    if (isRentersNonRevenueAgreementsCreateSuccess || isRentersNonRevenueAgreementsEditSuccess) {
      const toastTitle = isEdit
        ? "Non Revenue Agreements Updated Successfully"
        : "Non Revenue Agreements Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isRentersNonRevenueAgreementsCreateError ||
      isRentersNonRevenueAgreementsDetailError ||
      isRentersNonRevenueAgreementsEditError
    ) {
      let toastTitle = "Error while Creating Renters Info";
      if (isRentersNonRevenueAgreementsEditError) {
        toastTitle = "Error while updating  Renters Info";
      } else if (isRentersNonRevenueAgreementsDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateRentersNonRevenueAgreementsComponent {...createRentersNonRevenueAgreementsProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromRentersNonRevenueAgreementsReducer(state, "isLoading"),
   
    isRentersNonRevenueAgreementsCreateSuccess: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isRentersNonRevenueAgreementsCreateSuccess"
    ),
    isRentersNonRevenueAgreementsCreateError: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isRentersNonRevenueAgreementsCreateError"
    ),
    isRentersNonRevenueAgreementsDetailSuccess: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isRentersNonRevenueAgreementsDetailSuccess"
    ),
    isRentersNonRevenueAgreementsDetailError: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isRentersNonRevenueAgreementsDetailError"
    ),
    isRentersNonRevenueAgreementsEditSuccess: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isRentersNonRevenueAgreementsEditSuccess"
    ),
    isRentersNonRevenueAgreementsEditError: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isRentersNonRevenueAgreementsEditError"
    ),
    imageUploadResponse: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "imageUploadResponse"
    ),
    isUploadImageSuccess: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isUploadImageSuccess"
    ),
    isUploadImageError: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isUploadImageError"
    ),

    locationDD: selectFromLocationReducer(
      state,
      "locationDD"
    ),
    vehicleModelDD: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "vehicleModelDD"
    ),
    isVehicleModelDDSuccess: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isVehicleModelDDSuccess"
    ),
    isVehicleModelDDError: selectFromRentersNonRevenueAgreementsReducer(
      state,
      "isVehicleModelDDError"
    ),

    formFieldValueMap: selectFromRentersNonRevenueAgreementsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateRentersNonRevenueAgreementsScreen: () => dispatch(initCreateRentersNonRevenueAgreementsScreen()),
    createRentersNonRevenueAgreements: (payload) => dispatch(createRentersNonRevenueAgreements(payload)),
    editRentersNonRevenueAgreements: (payload) => dispatch(editRentersNonRevenueAgreements(payload)),
    shouldBlockNavigation: (isFormDirty) =>dispatch(setIsformDirty(isFormDirty)),
    fetchLocationDD: (payload) => dispatch(fetchLocationDD(payload)),
    uploadImage:(payload) => dispatch(uploadImage(payload)),
    fetchRentersNonRevenueAgreementsById: (payload) =>
      dispatch(fetchRentersNonRevenueAgreementsById(payload)),

  };
};

const selectFromRentersNonRevenueAgreementsReducer = (state, path) => {
  return selectRentersNonRevenueAgreementsReducer(state)[path];
};

const selectRentersNonRevenueAgreementsReducer = ({ RentersNonRevenueAgreementsReducer }) => {
  return RentersNonRevenueAgreementsReducer;
};
//Location
const selectFromLocationReducer = (state, path) => {
  return selectLocationReducer(state)[path];
};
const selectLocationReducer = ({ locationReducer }) => {
  return locationReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRentersNonRevenueAgreementsContainer);
