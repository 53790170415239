import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { vehicleAcTypeServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_VEHICLE_ACTYPE_INIT, 
  FETCH_VEHICLE_ACTYPE_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_VEHICLE_ACTYPE_SCREEN,
  CREATE_VEHICLE_ACTYPE,
  EDIT_VEHICLE_ACTYPE,
  TOAST,
  FETCH_VEHICLE_ACTYPE_DETAIL,
  EXPORT_VEHICLE_ACTYPE,
  VEHICLE_MODELDD
} from "../../../types/vehicleactype.type";
export const initializeVehicleAcTypeScreen = () => ({ type: FETCH_VEHICLE_ACTYPE_INIT });

export const vehicleAcTypeFetchStart = (payload) => ({
  type: FETCH_VEHICLE_ACTYPE_LIST.START,
  payload,
});

export const vehicleAcTypeFetchSuccess = (payload) => ({
  type:FETCH_VEHICLE_ACTYPE_LIST.SUCCESS,
  payload,
});

export const vehicleAcTypeFetchError = (payload) => ({
  type: FETCH_VEHICLE_ACTYPE_LIST.ERROR,
  payload,
});

export const fetchVehicleAcType = (payload) => {
  return (dispatch) => {
    dispatch(vehicleAcTypeFetchStart(payload));
    vehicleAcTypeServices.fetchVehicleAcType(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleAcTypeFetchSuccess(data));
      } else {
        dispatch(vehicleAcTypeFetchError(data));
      }
    });
  };
};



// DD
export const vehicleModelDDFetchStart = (payload) => ({
  type: VEHICLE_MODELDD.START,
  payload,
});

export const vehicleModelDDFetchSuccess = (payload) => ({
  type:VEHICLE_MODELDD.SUCCESS,
  payload,
});

export const vehicleModelDDFetchError = (payload) => ({
  type: VEHICLE_MODELDD.ERROR,
  payload,
});

export const fetchVehicleModelDD = (payload) => {
  return (dispatch) => {
    dispatch(vehicleModelDDFetchStart(payload));
    vehicleAcTypeServices.fetchVehicleModelDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(vehicleModelDDFetchSuccess(data));
      } else {
        dispatch(vehicleModelDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const vehicleAcTypeDetailFetchStart = (payload) => ({
  type: FETCH_VEHICLE_ACTYPE_DETAIL.START,
  payload,
});

export const vehicleAcTypeDetailFetchSuccess = (payload) => ({
  type: FETCH_VEHICLE_ACTYPE_DETAIL.SUCCESS,
  payload,
});

export const vehicleAcTypeDetailFetchError = (payload) => ({
  type: FETCH_VEHICLE_ACTYPE_DETAIL.ERROR,
  payload,
});

export const fetchVehicleAcTypeById = (payload) => {
  return (dispatch) => {
    dispatch(vehicleAcTypeDetailFetchStart(payload));

    vehicleAcTypeServices
      .fetchVehicleAcTypeById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(vehicleAcTypeDetailFetchSuccess(data));
        } else {
          dispatch(vehicleAcTypeDetailFetchError(data));
        }
      });
  };
};
/*
// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

*/
/*---------------Upload Image------------------------*/

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    vehicleAcTypeServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createVehicleAcTypeScreenInitStart = (payload) => ({
  type: INIT_CREATE_VEHICLE_ACTYPE_SCREEN,
  payload,
});

export const initCreateVehicleAcTypeScreen = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleAcTypeScreenInitStart());
  };
};


export const createVehicleAcTypeStart = () => ({
  type: CREATE_VEHICLE_ACTYPE.START,
});

export const createVehicleAcTypeSuccess = (payload) => ({
  type: CREATE_VEHICLE_ACTYPE.SUCCESS,
  payload,
});

export const createVehicleAcTypeError = (payload) => ({
  type: CREATE_VEHICLE_ACTYPE.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVehicleAcType = (payload) => {
  return (dispatch) => {
    dispatch(createVehicleAcTypeStart());
    vehicleAcTypeServices.createVehicleAcType(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVehicleAcTypeSuccess());
        goBack();
      } else {
        dispatch(createVehicleAcTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVehicleAcTypeStart = (payload) => ({
  type: EDIT_VEHICLE_ACTYPE.START,
  payload
});

export const editVehicleAcTypeSuccess = (payload) => ({
  type: EDIT_VEHICLE_ACTYPE.SUCCESS,
  payload,
});

export const editVehicleAcTypeError = (payload) => ({
  type: EDIT_VEHICLE_ACTYPE.ERROR,
  payload,
});

export const editVehicleAcType = (payload) => {
  return (dispatch) => {
    dispatch(editVehicleAcTypeStart());
    vehicleAcTypeServices.editVehicleAcType(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVehicleAcTypeSuccess());
        goBack();
      } else {
        dispatch(editVehicleAcTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportVehicleAcTypeRecordStart = (payload) => ({
  type: EXPORT_VEHICLE_ACTYPE.START,
  payload
});

export const exportVehicleAcTypeRecordSuccess = (payload) => ({
  type: EXPORT_VEHICLE_ACTYPE.SUCCESS,
  payload,
});

export const exportVehicleAcTypeRecordError = (payload) => ({
  type: EXPORT_VEHICLE_ACTYPE.ERROR,
  payload,
});

export const exportVehicleAcType = (payload) => {
  return (dispatch) => {
    dispatch(exportVehicleAcTypeRecordStart());
    vehicleAcTypeServices.exportVehicleAcTypeRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportVehicleAcTypeRecordSuccess(data));
      } else {
        dispatch(exportVehicleAcTypeRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

