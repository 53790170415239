import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
 import { changepasswordServices } from "../../../services";
import { goBack } from "../../../utils/gotoback";
import {
  TOAST,
  CHANGEPASSWORD_INIT,
  CHANGEPASSWORD
} from "../../types/changepassword.type";
export const initializePasswordScreen = () => ({ type: CHANGEPASSWORD_INIT });

export const changepasswordStart = () => ({
  type: CHANGEPASSWORD.START,
});

export const changepasswordSuccess = (payload) => ({
  type: CHANGEPASSWORD.SUCCESS,
  payload,
});

export const changepasswordError = (payload) => ({
  type: CHANGEPASSWORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const changepassword = (payload) => {
  return (dispatch) => {
    dispatch(changepasswordStart());
    changepasswordServices.createPassword(payload).then((data) => {
      if (!data.isError) {
        dispatch(changepasswordSuccess());
        goBack();
      } else {
        dispatch(changepasswordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
