
export const FETCH_LOCATION_LIST = {
    START: 'FETCH_LOCATION_LIST_START',
    SUCCESS: 'FETCH_LOCATION_LIST_SUCCESS',
    ERROR: 'FETCH_LOCATION_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_LOCATION_INIT="FETCH_LOCATION_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_LOCATION_SCREEN =
'INIT_CREATE_LOCATION_SCREEN';

export const CREATE_LOCATION = {
START: 'CREATE_LOCATION_START',
SUCCESS: 'CREATE_LOCATION_SUCCESS',
ERROR: 'CREATE_LOCATION_ERROR',
};

export const EDIT_LOCATION = {
START: 'EDIT_LOCATION_START',
SUCCESS: 'EDIT_LOCATION_SUCCESS',
ERROR: 'EDIT_LOCATION_ERROR',
};

export const FETCH_CATEGORY_LIST = {
  START: 'FETCH_CATEGORY_LIST_START',
  SUCCESS: 'FETCH_CATEGORY_LIST_SUCCESS',
  ERROR: 'FETCH_CATEGORY_LIST_ERROR',
};
export const FETCH_LOCATION_DETAIL = {
  START: 'FETCH_LOCATION_DETAIL_START',
  SUCCESS: 'FETCH_LOCATION_DETAIL_SUCCESS',
  ERROR: 'FETCH_LOCATION_DETAIL_ERROR',
};
export const EXPORT_LOCATION = {
  START: 'EXPORT_LOCATION_START',
  SUCCESS: 'EXPORT_LOCATION_SUCCESS',
  ERROR: 'EXPORT_LOCATION_ERROR',
};
export const LOCATIONDD = {
  START: 'VEHICLE_LOCATIONDD_START',
  SUCCESS: 'VEHICLE_LOCATIONDD_SUCCESS',
  ERROR: 'VEHICLE_LOCATIONDD_ERROR',
};